/* eslint-disable @typescript-eslint/no-explicit-any */
type HandlerFunction = (error: any) => void;

// decorator factory function
export default (handler: HandlerFunction): any => {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        // save a reference to the original method
        const originalMethod = descriptor.value;

        // rewrite original method with custom wrapper
        descriptor.value = function(...args: any[]) {
            try {
                const result = originalMethod.apply(this, args);

                // check if method is asynchronous
                if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
                    // return promise
                    return result.catch((error: any) => {
                        handler.call(null, error);
                    });
                }

                // return actual result
                return result;
            } catch (error) {
                handler.call(null, error);
            }
        };

        return descriptor;
    };
};
