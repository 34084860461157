import { ColumnApi, GridApi, GridOptions, GridReadyEvent, ColDef, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnVisibleEvent } from 'ag-grid-community';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { AppConstant } from 'app_constant';
import { AgGridComponentParams } from '../domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { dataStore } from 'features/common/stores';
import { accountStore } from 'features/account/stores';
/* eslint-disable */

export class AgGridService {
    private columnApi: ColumnApi | undefined;
    private gridApi: GridApi | undefined;

    private setColumnApi(columnApi: ColumnApi): void {
        this.columnApi = columnApi;
    }

    public setGridApi(gridApi: GridApi): void {
        this.gridApi = gridApi;
    }
    getNodes() {
        return this.gridApi;
    }

    getColumns() {
        return this.columnApi;
    }

    updateEditableProperty(columnKey: string, value: boolean): void {
        if (this.columnApi) {
            this.columnApi.getColumn(columnKey).getColDef().editable = value;
        }
    }

    getAllFilters(): any {
        let result;
        if (this.gridApi) {
            result = this.gridApi.getFilterModel();
        }
        return result;
    }

    setAllFilters(filters: any): void {
        if (this.gridApi) {
            this.gridApi.setFilterModel(filters);
            this.gridApi.onFilterChanged();
        }
    }

    clearAllFilters() {
        if (this.gridApi) {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        }
    }

    setColDef(columnKey: string, def: ColDef): void {
        if (this.columnApi) {
            this.columnApi.getColumn(columnKey).setColDef(def, def);
        }
    }

    updateOptionCellEditorValues<T>(cellValues: T[], columnKey: string, modelKey: Extract<keyof T, string>): void {
        if (this.columnApi) {
            this.columnApi.getColumn(columnKey).getColDef().cellEditorParams = {
                values: cellValues.map(item => item[modelKey])
            };
        }
    }

    addNewRowToGrid<T>(row: T, rowIndex: number) {
        this.gridApi?.updateRowData({ add: [row], addIndex: rowIndex });
    }

    private customizeGridConfig() {
        return {
            defaultColDef: {
                resizable: true,
                filter: true,
                sortable: true,
                accentedSort: true,
                comparator: (a, b) => typeof a === 'string' ? a.localeCompare(b) : (a > b ? 1 : (a < b ? -1 : 0))
            },
            rowSelection: 'single',
            rowDataChangeDetectionStrategy: ChangeDetectionStrategyType.DeepValueCheck,
            suppressScrollOnNewData: true,
            enableRangeSelection: true,
            rememberGroupStateWhenNewData: true,
            suppressAggFuncInHeader: true,
            // pagination: true,
            // paginationPageSize: AppConstant.GRID_DEFAULT_PAGINATION
        };
    }

    getGridConfig(props: AgGridComponentParams): GridOptions {
        const {
            rowData,
            columnDefs,
            onRowClicked,
            onCellClicked,
            onCellEditingStarted,
            onCellEditingStopped,
            onRangeSelectionChanged,
            onFilterChanged = this.onFilterChanged,
            aggFuncs,
            autoGroupColumnDef
        } = props;

        const statusBar = {
            statusPanels:  [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
                {
                  statusPanel: 'agTotalRowCountComponent',
                  align: 'center',
                },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
              ],
        };
        
        const excelStyle = [{
            id: 'decimal-format',
            numberFormat: {
                format: '###,##0.000'
            }},{
            id: 'comma-format',
            numberFormat: {
                format: '###,###.###'
            }},{
            id: 'work-wait',
            numberFormat: {
                format: '###,###'
            }}]

        const gridOptions: GridOptions = {
            columnDefs,
            rowData: rowData,
            excelStyles: excelStyle,
            onRowClicked,
            onCellClicked,
            onColumnMoved: this.onColumnMoved,
            onColumnPinned: this.onColumnPinned,
            onColumnResized: this.onColumnResized,
            onColumnVisible: this.onColumnVisible,
            onCellEditingStarted,
            onCellEditingStopped,
            onFilterChanged,
            onGridReady: this.handleGridReady,
            statusBar: statusBar,
            onRangeSelectionChanged,
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
            suppressCopyRowsToClipboard:true,
            aggFuncs : aggFuncs,
            autoGroupColumnDef: autoGroupColumnDef,
        };
        const config = this.customizeGridConfig();
        return { ...gridOptions, ...config };
    }

    onColumnMoved = (event: ColumnMovedEvent): void => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        if(moduleName != 'DispatchBoard')
        localStorageService.set(moduleName + '_' + userId, columnState);
    }

    onColumnPinned = (event: ColumnPinnedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        if(moduleName != 'DispatchBoard')
        localStorageService.set(moduleName + '_' + userId, columnState);
    }

    onColumnResized = (event: ColumnResizedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        if(moduleName != 'DispatchBoard')
        localStorageService.set(moduleName + '_' + userId, columnState)
    }
    
    onColumnVisible = (event: ColumnVisibleEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        if(moduleName != 'DispatchBoard')
        localStorageService.set(moduleName + '_' + userId, columnState)
    }

    onFilterChanged = (event): void => {
        const { api } = event;
        const filterModel = api.getFilterModel();
        const moduleName = dataStore.getCurrentModuleName();
        localStorageService.set(moduleName + '_filter', filterModel);
    }  
    handleGridReady = (event: GridReadyEvent): void => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        let moduleName = dataStore.getCurrentModuleName()
        var columnState =  localStorageService.get(moduleName + '_' + userId);
        try {
            if (columnState) {
                let columnArrangement = columnState as ColumnState[];
                columnApi.setColumnState(columnArrangement);
            }
        }
        catch (e) {
            localStorageService.remove(moduleName + '_' + userId);
            console.log('error in getting Column arrangement for module', e);
        }
        console.log(columnApi);
        this.setColumnApi(columnApi);
        this.setGridApi(api);
    };

    handlerRowSelected = (e):void => {
        console.log("row selected", e.rowIndex, e.type);
        e.preventDefault();
    }

    
    
    refreshGrid () {
        this.gridApi?.refreshCells({force: true});
    }

    getDisplayedRowCount() {
        return this.gridApi?.getRenderedNodes().length;
    }
}

export const agGridService = new AgGridService();
