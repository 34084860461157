import { TicketHeaderName, TicketFieldName } from './enum';
import { dateUtils } from 'shared/services/date-utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { dataStore } from 'features/common/stores';
import { commaFormat, isFloat } from '../reports_constants';

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

export const leaseOperatorColDef: ColDef[] = [
    {
        headerName: TicketHeaderName.TICKETDATE,
        field: TicketFieldName.TICKETDATE,
        width: 180,
        valueGetter: params => (params.data[TicketFieldName.TICKETDATE] ? dateUtils.getFormattedDateTime(params.data[TicketFieldName.TICKETDATE]) : '')
    },
    {
        headerName: TicketHeaderName.TICKETACCEPTEDDATE,
        field: TicketFieldName.TICKETACCEPTEDDATE,
        width: 130,
        valueGetter: params => (params.data[TicketFieldName.TICKETACCEPTEDDATE] ? dateUtils.getFormattedDateTime(params.data[TicketFieldName.TICKETACCEPTEDDATE]) : '')
    },
    {
        headerName: TicketHeaderName.LOADDATETIME,
        field: TicketFieldName.LOADDATETIME,
        width: 105,
        valueGetter: params => {
            if ((params.data[TicketFieldName.LOADDATETIME] === null) && (params.data[TicketFieldName.STARTDATETIME] === null)) {
                return '';
            } else {
                return params.data[TicketFieldName.TYPE] === 'Hourly' ? dateUtils.getFormattedDateTime(params.data[TicketFieldName.STARTDATETIME]) : dateUtils.getFormattedDateTime(params.data[TicketFieldName.LOADDATETIME]);
            }
        }
    },
    {
        headerName: TicketHeaderName.COMPLETEDDATETIME,
        field: TicketFieldName.COMPLETEDDATETIME,
        width: 105,
        valueGetter: params => (params.data[TicketFieldName.COMPLETEDDATETIME] ? dateUtils.getFormattedDateTime(params.data[TicketFieldName.COMPLETEDDATETIME]) : '')
    },
    {
        headerName: TicketHeaderName.TYPE,
        field: TicketFieldName.TYPE,
        width: 60,
        valueGetter: params => {
            if (params.data[TicketFieldName.TYPE] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TYPE];
            }
        }
    },
    {
        headerName: TicketHeaderName.TICKETNUMBER,
        field: TicketFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[TicketFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: TicketHeaderName.DRIVERNAME,
        field: TicketFieldName.DRIVERNAME,
        width: 115,
        valueGetter: params => {
            if (params.data[TicketFieldName.DRIVERNAME] === null || params.data[TicketFieldName.DRIVERNAME] === 'Dummy Test' || params.data[TicketFieldName.DRIVERNAME].trim() === 'SysUser') {
                return '';
            } else {
                return params.data[TicketFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: TicketHeaderName.REGION,
        field: TicketFieldName.REGION,
        width: 80,
        valueGetter: params => {
            if (params.data[TicketFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.REGION];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRAILER,
        field: TicketFieldName.TRAILER,
        width: 80,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRAILER] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRAILER];
            }
        }
    },
    {
        headerName: TicketHeaderName.ORIGIN,
        field: TicketFieldName.ORIGIN,
        width: 100,
        valueGetter: params => {
            if (params.data[TicketFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.ORIGIN];
            }
        }
    },
    {
        headerName: TicketHeaderName.DESTINATION,
        field: TicketFieldName.DESTINATION,
        width: 100,
        valueGetter: params => {
            if (params.data[TicketFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.DESTINATION];
            }
        }
    },
    {
        headerName: TicketHeaderName.Product,
        field: TicketFieldName.PRODUCT,
        width: 90,
        valueGetter: params => {
            if (params.data[TicketFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: TicketHeaderName.VOLUMEDELIVERED,
        field: TicketFieldName.VOLUMEDELIVERED,
        width: 100,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[TicketFieldName.VOLUMEDELIVERED] === null) {
                return '';
            } else {
                return roundOff(params.data[TicketFieldName.VOLUMEDELIVERED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WORKINGTIME,
        field: TicketFieldName.WORKINGTIME,
        colId: TicketFieldName.WORKINGTIME,
        width: 90,
        cellClass: params => {
            if(isFloat(params.data[TicketFieldName.WORKINGTIME])) return 'comma-format'
            else return 'work-wait'
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[TicketFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WORKINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WORKINGTIMEREASON,
        field: TicketFieldName.WORKINGTIMEREASON,
        width: 115,
        valueGetter: params => {
            if (params.data[TicketFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: TicketHeaderName.WAITINGTIME,
        field: TicketFieldName.WAITINGTIME,
        colId: TicketFieldName.WAITINGTIME,
        width: 80,
        cellClass: params => {
            if(isFloat(params.data[TicketFieldName.WAITINGTIME])) return 'comma-format'
            else return 'work-wait'
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[TicketFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WAITINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WAITINGTIMEREASON,
        field: TicketFieldName.WAITINGTIMEREASON,
        width: 115,
        valueGetter: params => {
            if (params.data[TicketFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: TicketHeaderName.COMMENT,
        field: TicketFieldName.COMMENT,
        colId: TicketFieldName.COMMENT,
        width: 100,
        valueGetter: params => {
            if (params.data[TicketFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.COMMENT];
            }
        }
    },
    {
        headerName: TicketHeaderName.SHIPPERTICKET,
        field: TicketFieldName.SHIPPERTICKET,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.SHIPPERTICKET] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.SHIPPERTICKET];
            }
        }
    },
    {
        headerName: TicketHeaderName.RELATEDVOLTICKET,
        field: TicketFieldName.RELATEDVOLUMETICKET,
        width: 125,
        valueGetter: params => {
            if (params.data[TicketFieldName.RELATEDVOLUMETICKET] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.RELATEDVOLUMETICKET];
            }
        }
    },
    {
        headerName: TicketHeaderName.ROADBAN,
        field: TicketFieldName.ROADBAN,
        width: 95,
        valueGetter: params => {
            if (params.data[TicketFieldName.ROADBAN] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.ROADBAN];
            }
        }
    },

    {
        headerName: TicketHeaderName.VIA,
        field: TicketFieldName.VIA,
        width: 90,
        valueGetter: params => {
            if (params.data[TicketFieldName.VIA] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.VIA];
            }
        }
    },
    {
        headerName: TicketHeaderName.STATUS,
        colId: TicketFieldName.STATUS,
        field: TicketFieldName.STATUS,
        width: 100,
        valueGetter: params => {
            if (params.data[TicketFieldName.STATUS] === null) {
                return '';
            } else if (params.data[TicketFieldName.STATUS] === 'Assigned') {
                return 'Dispatched';
            } else {
                return params.data[TicketFieldName.STATUS];
            }
        }
    },
    {
        field: TicketFieldName.APPROVERCOMMENT,
        headerName: TicketHeaderName.APPROVERCOMMENT,
        colId: TicketFieldName.APPROVERCOMMENT,
        width: 160,
        valueGetter: params => {
            if (params.data[TicketFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.APPROVERCOMMENT];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRAILERMAPPEDTOCOMPANY,
        field: TicketFieldName.TRAILERMAPPEDTOCOMPANY,
        width: 135,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY];
            }
        }
    },
    { field: TicketFieldName.ISAPPROVED, hide: true }
];
