import { Config } from 'config';
import { OperatorResponse } from 'features/data_management/domains/location-management/model';
import { restApiService } from 'shared/services';
import { RestApi } from 'shared/types/shared';

export class ApportionmentDataService {
    constructor(private apiService: RestApi) {}

    getApportionmentData(reqbody) {
        return this.apiService.postWithToken<{ Data: any }>(Config.getApportionmentData, reqbody);
    }

    async createUpdateApportionmentData(reqbody) {
        const { data } = await this.apiService.postWithToken<OperatorResponse[]>(
            Config.createUpdateApportionmentData,
            reqbody
        );
        return data['Data'];
    }
}
export const apportionmentDataService = new ApportionmentDataService(restApiService);
