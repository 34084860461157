import React, { PureComponent } from 'react';
import './_register-container.scss';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { EmailValidationPopUpComponent } from './email-validation-popup-component';
import { CommonUtils } from 'shared/services/common-utils';
import { AppConstant } from 'app_constant';
import { SignUpHeaderComponent } from './sign-up-header';
import { accountStore } from '../stores';
import { Button } from 'react-bootstrap';
import { APPROVED, PENDING, AWAITING_ACTIVATION,PLAINSUSEREMAILADDRESS } from '../account_constant';

interface EmailEntryComponentState {
    email: string;
    invalidEmail: string;
    showPopUp: boolean;
    userStatus: string;
}

@inject('accountStore')
@observer
export class EmailEntryComponent extends PureComponent<RouteComponentProps, EmailEntryComponentState> {
    errorMessageClassName: string = '';
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            email: '',
            invalidEmail: '',
            showPopUp: false,
            userStatus: 'PENDING'
        }
    }

    render(): React.ReactNode {
        const { showPopUp, userStatus, invalidEmail } = this.state;
        return (
            <div className="register-container">
                <div className="register-container__content">
                    <SignUpHeaderComponent />
                    <div><label htmlFor="emailid">Email</label></div>
                    <div>
                        <input
                            className={invalidEmail}
                            type="text"
                            name="emailid"
                            id="emailid"
                            placeholder="Email Address"
                            onBlur={this.handleBlur}
                        />
                        {invalidEmail && <div className="register-container-error-message">{AppConstant.INVALID_EMAIL_ADDRESS}</div>}
                    </div>
                    <div>
                        <Button className="mt-3 mb-3 custom-btn" onClick={this.handleClick}>
                            Next
                        </Button>
                    </div>
                    <EmailValidationPopUpComponent
                        onHide={this.handlePopUpClose}
                        userStatus={userStatus}
                        onLogin={this.handleLogin}
                        show={showPopUp}
                    ></EmailValidationPopUpComponent>
                </div>
            </div>

        );
    }

    private handleBlur = (event) => {
        this.setState({ email: event.target.value });
    }

    private handlePopUpClose = (): void => {
        this.setState({ showPopUp: false });
    };

    private handleClick = (): void => {
        const { email } = this.state;
        if (this.isEmailValid(email)  ) {
        if((email.indexOf(PLAINSUSEREMAILADDRESS)>0))
        {
            this.setState({ showPopUp: true, userStatus: AppConstant.PLAINSUSEREMAILADDRESS });
        }
        else{
         accountStore.getSignUpUserDetails(email).then((res) => {
                const userDetails = res.data['Data'];
                if (userDetails && [APPROVED, PENDING, AWAITING_ACTIVATION].includes(userDetails.StatusCode)) {
                    this.setState({ showPopUp: true, userStatus: userDetails.StatusCode });
                }
                else {
                    const redirectUri = `/signup?email=${encodeURIComponent(email)}`;
                    this.props.history.push(redirectUri);
                }
            });
        }
        }

    };

    private handleLogin = (): void => {
        accountStore.login().then(() => {
            const redirectRoute = {
                pathname: '/',
                state: this.props.location.state
            };
            this.props.history.push(redirectRoute);
        });
    };

    isEmailValid(email: string) {
        const isValid = CommonUtils.validateEmail(email);
        if (!isValid) {
            this.setState({ invalidEmail: "register-container-invalid-email" });
            return false;
        }
        else {
            this.setState({ invalidEmail: '' });
            return true;
        }
    }
}
