export enum TicketHeaderName {
    SELECT = 'SELECT',
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'MODIFIED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    TYPE = 'TYPE',
    STATUS = 'STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'MODIFIED DATE',
    TICKETDATE = 'TICKET CREATION DATE',
    TRAILER = 'TRAILER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    TRACTORMISMATCH = 'TRACTOR MAPPING MATCHED',
    POWERUNITIDENTIFIER = 'POWER UNIT IDENTIFIER',
    TRACTORMAPPEDTOCOMPANY = 'TRACTOR MAPPED TO COMPANY',
    TRACTORUSED = 'TRACTOR',
    COMPLETEDDATE = 'COMPLETED DATE TIME',
    STARTDATETIME = 'START DATE TIME',
    ENDDATETIME = 'END DATE TIME',
    REGION = 'REGION',
    TICKETSOURCE = 'TICKET SOURCE'
}

export enum TicketFieldName {
    ID = 'id',
    PRODUCT = 'product',
    SHIPPERTICKET = 'shipperTicket',
    LASTPMTSTATUS = 'lastPmtStatus',
    RELATEDVOLUMETICKET = 'relatedVolumeTicket',
    WORKINGTIME = 'workingTime',
    LASTUPDATEDBY = 'updatedBy',
    COMMENT = 'comment',
    LEASEOPERATOR = 'leaseOperator',
    DRIVERNAME = 'driverName',
    TYPE = 'type',
    STATUS = 'status',
    TICKETNUMBER = 'ticketNumber',
    ORIGIN = 'origin',
    DESTINATION = 'destination',
    WORKINGTIMEREASON = 'workingTimeReason',
    WAITINGTIMEREASON = 'waitingTimeReason',
    WAITINGTIME = 'waitingTime',
    VOLUMEDELIVERED = 'volumeDelivered',
    UPDATEDDATE = 'updatedDate',
    TICKETDATE = 'ticketDate',
    TRAILER = 'trailer',
    ISAPPROVED = 'isApproved',
    APPROVERCOMMENT = 'approverComment',
    LASTPDSSTATUS = 'lastPDSStatus',
    LASTTICKETSTATUS = 'lastTicketStatus',
    TRAILERSTATUS = 'trailerStatus',
    TRAILERMAPPEDTOCOMPANY = 'trailerMappedToCompany',
    TRACTORMAPPEDTOCOMPANY = 'tractorMappedToCompany',
    POWERUNITIDENTIFIER = 'powerUnitIdentifier',
    COMPLETEDDATE = 'completedDateTime',
    LOADDATETIME = 'loadDateTime',
    STARTDATE = 'StartDateTime',
    ENDDATE = 'endDate',
    REGION = 'Region',
    TICKETSOURCE = 'TicketSource'
}

export enum TicketStatus {
    NEW = 'New',
    APPROVED = 'Approved',
    NOTAPPROVED = 'Not Approved',
    HOLD = 'Hold',
    CANCELED = 'Canceled'
}

export enum TicketVolOrHourly {
    VOLUME = 'Volume',
    HOURLY = 'Hourly'
}

export const Reason = [
    {
        ID: 1,
        AXID: 5637144576,
        Name: 'Additonal Equipment Required',
        Version: 3
    },
    {
        ID: 2,
        AXID: 5637144577,
        Name: 'Butane Transfer',
        Version: 1
    },
    {
        ID: 3,
        AXID: 5637144578,
        Name: 'Chain Up',
        Version: 1
    },
    {
        ID: 4,
        AXID: 5637144579,
        Name: 'Chain Up & Slow Loading',
        Version: 1
    },
    {
        ID: 5,
        AXID: 5637144580,
        Name: 'Condensate Transfer',
        Version: 1
    },
    {
        ID: 6,
        AXID: 5637144581,
        Name: 'Crude Transfer',
        Version: 1
    },
    {
        ID: 7,
        AXID: 5637144582,
        Name: 'Emulsion transfer',
        Version: 1
    },
    {
        ID: 8,
        AXID: 5637144583,
        Name: 'Extra Pumping Time',
        Version: 1
    },
    {
        ID: 9,
        AXID: 5637144584,
        Name: 'High Tanked loading',
        Version: 1
    },
    {
        ID: 10,
        AXID: 5637144585,
        Name: 'High Tanked Unloading',
        Version: 1
    },
    {
        ID: 11,
        AXID: 5637144586,
        Name: 'Hourly Haul Only',
        Version: 1
    },
    {
        ID: 12,
        AXID: 5637144587,
        Name: 'Load Re-directed',
        Version: 1
    },
    {
        ID: 13,
        AXID: 5637144588,
        Name: 'Low Tanked loading',
        Version: 1
    },
    {
        ID: 14,
        AXID: 5637144589,
        Name: 'NGL Transfer',
        Version: 1
    },
    {
        ID: 15,
        AXID: 5637144590,
        Name: 'No Load',
        Version: 1
    },
    {
        ID: 16,
        AXID: 5637144591,
        Name: 'Rejected Load',
        Version: 1
    },
    {
        ID: 17,
        AXID: 5637144592,
        Name: 'Rejected Load - Wet',
        Version: 1
    },
    {
        ID: 18,
        AXID: 5637144593,
        Name: 'Road Ban',
        Version: 1
    },
    {
        ID: 19,
        AXID: 5637144594,
        Name: 'Short Load Adjustment',
        Version: 1
    },
    {
        ID: 20,
        AXID: 5637144595,
        Name: 'Slow loading',
        Version: 1
    },
    {
        ID: 21,
        AXID: 5637144596,
        Name: 'Slow Loading & Unloading',
        Version: 1
    },
    {
        ID: 22,
        AXID: 5637144597,
        Name: 'Slow Loading, Gassy Oil',
        Version: 1
    },
    {
        ID: 23,
        AXID: 5637144598,
        Name: 'Slow unloading',
        Version: 1
    },
    {
        ID: 24,
        AXID: 5637144599,
        Name: 'Slow Unloading, Gassy Oil',
        Version: 1
    },
    {
        ID: 25,
        AXID: 5637144600,
        Name: 'Split load - Extra Time',
        Version: 1
    },
    {
        ID: 26,
        AXID: 5637144601,
        Name: 'Travel Time',
        Version: 1
    },
    {
        ID: 27,
        AXID: 5637144602,
        Name: 'Waiting to Load',
        Version: 1
    },
    {
        ID: 28,
        AXID: 5637144603,
        Name: 'Waiting to unload',
        Version: 1
    },
    {
        ID: 29,
        AXID: 5637144604,
        Name: 'Water Transfer',
        Version: 1
    },
    {
        ID: 30,
        AXID: 5637146105,
        Name: 'Chain Up & Slow Unloading',
        Version: 1
    },
    {
        ID: 31,
        AXID: 5637146106,
        Name: 'Waiting to Load & Unload',
        Version: 1
    }
];
