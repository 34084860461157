import { loaderSubject } from '.';
export class LoaderService {
    showLoader() {
        loaderSubject.next(true);
    }

    hideLoader() {
        loaderSubject.next(false);
    }
}

export const loaderService = new LoaderService();
