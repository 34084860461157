export enum LocationMapingMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Location maping data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    DELETEDSUCCESS = 'Deleted Successfully',
    DELETEDERROR = 'Failed to delete records',
    NO_ROW_SELECTED = 'No record selected'
}

export enum LocationMapingButtons {
    ADDNEWROW = 'Add New Row',
    EXPORTTOEXCEL = 'Export To Excel',
    SAVE = 'Save'
}

export enum LocationMapingHeaderName {
    ID = 'ID',
    LOCATIONEXT = 'EXTERNAL LOCNAME',
    LOCATIONAX = 'LOCATION AX',
    LOCATIONRA = 'LOCATION RA',
    LOCATIONLSD = 'EXTERNAL LOCLSD',
    LOCATIONCODE = 'EXTERNAL LOCCODE',
    DATASOURCE = 'DATA SOURCE',
    COMMENT = 'COMMENTS',
    DELETE = 'DELETE',
    TRUCKEDBYLOCATIONS = 'TRUCKED BY LOCATIONS',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE'
}

export enum LocationMapingFieldName {
    ID = 'Id',
    LOCATIONEXT = 'SourceLocation',
    SOURCELOCATIONCODE = 'SourceLocCode',
    SOURCELOCLSD = 'SourceLocLSD',
    AXID = 'AXID',
    LOCATIONAX = 'AX_Location',
    RAID = 'RAID',
    LOCATIONRA = 'RA_Location',
    DATASOURCE = 'FuelType',
    COMMENT = 'Comments',
    DELETE = 'IsDeleted',
    TRUCKEDBYLOCATIONS = 'TruckedByLocations',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate'
}
