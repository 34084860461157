import { TrailerMaintenanceStatus } from './enum';

let stateTransitions = new Map();
stateTransitions.set(TrailerMaintenanceStatus.BOOKED, [
    TrailerMaintenanceStatus.BOOKED,
    TrailerMaintenanceStatus.SHOPASSIGNED,
    TrailerMaintenanceStatus.CANCELLED,
    TrailerMaintenanceStatus.PARKED,
    TrailerMaintenanceStatus.PARKEDOUTOFSERVICE
]);
stateTransitions.set(TrailerMaintenanceStatus.SHOPASSIGNED, [
    TrailerMaintenanceStatus.SHOPASSIGNED,
    TrailerMaintenanceStatus.INMAINTENANCE,
    TrailerMaintenanceStatus.CANCELLED,
    TrailerMaintenanceStatus.PARKED,
    TrailerMaintenanceStatus.PARKEDOUTOFSERVICE
]);
stateTransitions.set(TrailerMaintenanceStatus.INMAINTENANCE, [
    TrailerMaintenanceStatus.INMAINTENANCE,
    TrailerMaintenanceStatus.SHOPASSIGNED,
    TrailerMaintenanceStatus.MAINTENANCECOMPLETE,
    TrailerMaintenanceStatus.PARKED,
    TrailerMaintenanceStatus.PARKEDOUTOFSERVICE,
    TrailerMaintenanceStatus.PENDINGPAPERWORK
]);
stateTransitions.set(TrailerMaintenanceStatus.PENDINGPAPERWORK, [
    TrailerMaintenanceStatus.INMAINTENANCE,
    TrailerMaintenanceStatus.PENDINGPAPERWORK,
    TrailerMaintenanceStatus.MAINTENANCECOMPLETE,
]);
stateTransitions.set(TrailerMaintenanceStatus.MAINTENANCECOMPLETE, [TrailerMaintenanceStatus.MAINTENANCECOMPLETE]);
stateTransitions.set(TrailerMaintenanceStatus.CANCELLED, [TrailerMaintenanceStatus.CANCELLED]);
stateTransitions.set(TrailerMaintenanceStatus.PARKED, [
    TrailerMaintenanceStatus.PARKED,
    TrailerMaintenanceStatus.SHOPASSIGNED
]);
stateTransitions.set(TrailerMaintenanceStatus.PARKEDOUTOFSERVICE, [
    TrailerMaintenanceStatus.PARKEDOUTOFSERVICE,
    TrailerMaintenanceStatus.SHOPASSIGNED
]);

export const transact = (prevState: string, currentState: string): boolean => {
    let canMoveFromPrevToCurrenttate = false;
    for (let [key, value] of stateTransitions) {
        if (key == prevState) {
            canMoveFromPrevToCurrenttate = value.includes(currentState);
            break;
        }
    }
    return canMoveFromPrevToCurrenttate;
};
