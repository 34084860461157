
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { TrailerResponse } from '../model/model';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';

interface DispatchPlanProps {
    selectedTrailerId: number;
    trailerNumber: string;
    trailers: TrailerResponse[]
    onHide: () => void;
    onSelectionChange: (trailerNumber: string) => void;
}

interface DispatchPlanState {
    selectedTrailerId: number;
    selectedtrailerNumber: string;
}

@observer
export class DispatchPlanTrailerComponent extends Component<DispatchPlanProps, DispatchPlanState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedTrailerId: 0,
            selectedtrailerNumber: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedTrailerId != this.state.selectedTrailerId) {
            this.setState({ selectedTrailerId: this.props.selectedTrailerId, selectedtrailerNumber: this.state.selectedtrailerNumber });
        }
    }

    mapDriverToDriverOption = trailers => DataAdapterService.getOptionValues(trailers, 'TrailerNumber', 'ID', false);

    getSelectedShopOption = (id, trailers) => {
        let selectedShopObj = trailers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { trailers } = this.props;
        const { selectedTrailerId, selectedtrailerNumber } = this.state;
        const shopOptions = this.mapDriverToDriverOption(trailers);
        const selectedShopOption = this.getSelectedShopOption(selectedTrailerId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Trailer Name</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Trailer"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignShop}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => {
        this.setState({ selectedtrailerNumber: option.label, selectedTrailerId: option.value });
    }

    assignShop = () => {
        this.props.onSelectionChange(this.state.selectedtrailerNumber);
        this.props.onHide();
    };
}
