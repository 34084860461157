import { action, observable, toJS } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import { AccountStore } from '../../../account/stores/account-store';
import _ from 'lodash';
import { CommunicationDataService } from '../services/communication-data-service';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { plainsRoles } from '../communication-constants';
import { MESSAGE_COL_DEF } from '../models/communication-col-def';

export class CommunicationDataStore {
    userID = 0;
    userRole = '';
    @observable directMessages: any[] = [];
    @observable directMsg: any[] = [];
    @observable isUpdated: boolean = false;
    constructor(
        private communicationDataService: CommunicationDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userRole = this.getPriorityroles();
    }

    getPriorityroles = () => {
        const roles = toJS(this.accountStore.userRoles);
        let flag = 0;
        let userRole;
        roles.forEach(role => {
            if (plainsRoles.includes(role)) {
                flag = 1;
                userRole = role;
            }
        });
        if (flag == 0) {
            roles.forEach(role => {
                if (['ptsmaintenance', 'ptstruckingadmin'].includes(role)) {
                    flag = 1;
                    userRole = role;
                }
            });
        }
        if (flag == 0) {
            roles.forEach(role => {
                if (role !== 'ptsdriver') {
                    userRole = role;
                }
            });
        }
        return userRole;
    };

    @action
    handleCheckboxClick(message: any, isRead: boolean): void {
        const unReadMessages = message.Data.filter(
            data => data.isRead !== isRead && !data.ReceiverRole.includes('ptsdriver')
        );
        const ID = unReadMessages.map(msg => msg.MessageID);
        this.isUpdated = true;
        this.communicationDataService.updateReadMessage({ MessageID: ID, Type: 'Direct', IsRead: isRead });
    }

    getMessagesColDef() {
        return MESSAGE_COL_DEF;
    }

    async getMessages() {
        const itemMap = {};
        this.directMsg = [];
        const messages = await this.communicationDataService.getAllMessages({
            UserID: this.userID,
            Role: this.userRole
        });
        if (messages) {
            this.directMsg = messages.Direct;
            this.directMsg.forEach((item, ind) => {
                const key = item.Type === 'Direct' ? item.ConvId : item.MessageID;
                if (!itemMap.hasOwnProperty(key)) {
                    itemMap[key] = {
                        ConvId: item.ConvId,
                        CreateDate: item.CreatedDate,
                        Message: item.Message,
                        Subject: item.Subject,
                        MessageType: item.Type,
                        To:
                            item.SenderID === this.userID ||
                            item.SenderRole === this.userRole ||
                            plainsRoles.includes(item.SenderRole)
                                ? item.Receiver
                                : item.Sender,
                        From:
                            item.SenderID === this.userID ||
                            item.SenderRole === this.userRole ||
                            plainsRoles.includes(item.SenderRole)
                                ? item.Sender
                                : item.Receiver,
                        IsRead: item.IsRead,
                        IsSent: item.IsSent,
                        Data: [item]
                    };
                } else {
                    itemMap[key].Data.push(item);
                }
            });
            const variantKeys = Object.keys(itemMap);
            this.directMessages = [];
            variantKeys.forEach(key => {
                this.directMessages.push(itemMap[key]);
            });
        }
    }
}
