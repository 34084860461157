import { DailyRecordHeaderName, DailyRecordFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { commaFormat } from '../reports_constants';

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

export const dailyReport: ColDef[] = [
    {
        headerName: DailyRecordHeaderName.TICKETDATE,
        field: DailyRecordFieldName.TICKETDATE,
        width: 180,
        valueGetter: (value): string => (value.data[DailyRecordFieldName.TICKETDATE] ?
             momenttimezone
                .tz(moment.utc(value.data[DailyRecordFieldName.TICKETDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT) : '')
    },
    {
        headerName: DailyRecordHeaderName.TICKETACCEPTEDDATE,
        field: DailyRecordFieldName.TICKETACCEPTEDDATE,
        width: 130,
        valueGetter: (value): string => (value.data[DailyRecordFieldName.TICKETACCEPTEDDATE] ? momenttimezone
            .tz(moment.utc(value.data[DailyRecordFieldName.TICKETACCEPTEDDATE]), AppConstant.MST_TIME_FORMATTER)
            .format(AppConstant.DATE_TIME_FORMAT) : '')
    },
    {
        headerName: DailyRecordHeaderName.LOADDATETIME,
        field: DailyRecordFieldName.LOADDATETIME,
        width: 100,
        valueGetter: (value): string => (value.data[DailyRecordFieldName.LOADDATETIME] ?
             momenttimezone
                .tz(moment.utc(value.data[DailyRecordFieldName.LOADDATETIME]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT) : '')
    },
    {
        headerName: DailyRecordHeaderName.COMPLETEDDATETIME,
        field: DailyRecordFieldName.COMPLETEDDATETIME,
        width: 105,
        valueGetter: (value): string => (value.data[DailyRecordFieldName.COMPLETEDDATETIME] ? dateUtils.getFormattedDateTimeLocal(value.data[DailyRecordFieldName.COMPLETEDDATETIME]) : '')
    },
    {
        headerName: DailyRecordHeaderName.TICKETNUMBER,
        field: DailyRecordFieldName.TICKETNUMBER,
        width: 85,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.DRIVERNAME,
        field: DailyRecordFieldName.DRIVERNAME,
        width: 125,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.DRIVERNAME] === null || params.data[DailyRecordFieldName.DRIVERNAME] === 'Dummy Test' || params.data[DailyRecordFieldName.DRIVERNAME].trim() === 'SysUser') {
                return '';
            } else {
                return params.data[DailyRecordFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.REGION,
        field: DailyRecordFieldName.REGION,
        width: 75,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.REGION];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.POWERUNIT,
        field: DailyRecordFieldName.POWERUNIT,
        width: 105,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.POWERUNIT] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.POWERUNIT];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.CARRIER,
        field: DailyRecordFieldName.CARRIER,
        width: 150,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.CARRIER] === null || params.data[DailyRecordFieldName.CARRIER] === 'Dummy Test') {
                return '';
            } else {
                return params.data[DailyRecordFieldName.CARRIER];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.CONSIGNOR,
        field: DailyRecordFieldName.CONSIGNOR,
        width: 105,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.CONSIGNEE,
        field: DailyRecordFieldName.CONSIGNEE,
        width: 100,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.PRODUCT,
        field: DailyRecordFieldName.PRODUCT,
        width: 90,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.ESTIMATEVOL,
        field: DailyRecordFieldName.ESTIMATEVOL,
        width: 95,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.ESTIMATEVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[DailyRecordFieldName.ESTIMATEVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: DailyRecordHeaderName.ACTUALVOL,
        field: DailyRecordFieldName.ACTUALVOL,
        width: 80,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.ACTUALVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[DailyRecordFieldName.ACTUALVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },

    {
        headerName: DailyRecordHeaderName.TRAILER,
        field: DailyRecordFieldName.TRAILER,
        width: 80,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.TRAILER] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.TRAILER];
            }
        }
    },
    {
        headerName: DailyRecordHeaderName.STATUS,
        field: DailyRecordFieldName.STATUS,
        width: 80,
        valueGetter: params => {
            if (params.data[DailyRecordFieldName.STATUS] === null) {
                return '';
            } else {
                return params.data[DailyRecordFieldName.STATUS];
            }
        }
    }
];
