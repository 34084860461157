export enum FilterKeyForReferenceData {
    InspectionTicketHistory = 'InspectionTicketHistory',
    TrailerBookingMaintenance = 'TrailerBookingMaintenance',
    UserApproval = 'UserApproval',
    Select = 'Select'
}

export enum UserApprovalMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'User sign up request data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    FAILED_DELETE = 'Failed to deactivate User',
    TABLET_DELETE = 'User(s) Deactivated Successfully',
    PERMANENT_DELETE = 'User(s) Deleted Permanently',
    FAILED_DELETE_SP = 'Failed to delete User',
}

export enum UserApprovalHeaderName {
    ID = 'ID',
    Email = 'Email',
    Select = 'Select',
    FirstName = 'First Name',
    LastName = 'Last Name',
    PhoneNumber = 'Phone Number',
    CarrierName = 'Carrier Company',
    DriverType = 'User Type',
    UserType = 'User Role',
    ADStatus = 'AD Status',
    Facilities = 'Facilities',
    MappedCarrierName = 'Mapped Carrier Company',
    PSAMappedCarrierName = 'PSA Mapped Carrier Name',
    PlainsTruckingSystem = 'Plains Trucking System',
    PlainsSchedulingApp = 'Plains Scheduling App',
    PSAStatusField = 'PSA Status',
    CreatedDate = 'Created Date',
    ModifiedDate = 'Modified Date',
    ModifiedByUserName = 'Modified By',
    Delete = 'Deactivate'
}
export enum UserApprovalFieldName {
    ID = 'ID',
    Email = 'LoginName',
    FirstName = 'FirstName',
    LastName = 'LastName',
    PhoneNumber = 'CellPhoneNo',    
    CarrierName = 'CarrierName',
    DriverType = 'DriverType',
    UserType = 'UserType',
    ADStatus = 'ADStatus',
    Facilities = 'FacilityName',
    MappedCarrierName = 'MappedCarrierName',
    PSAMappedCarrierName = 'PSAMappedCarrierName',
    PlainsTruckingSystem = 'StatusCode',
    PlainsSchedulingApp = 'PSAStatusCode',
    PSAStatusField = 'PSAStatusCode',
    CreatedDate = 'CreatedDate',
    ModifiedDate = 'ModifiedDate',
    ModifiedByUserName = 'ModifiedByUserName',
    Select = 'Select',
    Delete = 'IsDeleted'
}

export enum UserApprovalStatus {
    BOOKED = 'Booked',
    SHOPASSIGNED = 'Shop Assigned',
    INMAINTENANCE = 'In Maintenance',
    MAINTENANCECOMPLETE = 'Maintenance Complete'
}
