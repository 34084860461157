/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import moment from 'moment';
import { accountStore } from 'features/account/stores';
import { LocationDataStore } from 'features/data_management/stores/location-data-store';
import { LocationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { inject } from 'mobx-react';
import { locationStore } from 'features/data_management/stores';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (consignorAXID, consigneeAXID, consignorRAID, consigneeRAID, loadSize, region) => void;
    msg;
    region;
    locationDataSerivice: LocationDataService;
    location: any;
    raConfig: any;
}
export class LocationPopUp extends React.Component<ConfirmProps> {
    state = {
        consignorPlaceholder: this.props.location[0]?.Consignor ? this.props.location[0].Consignor : "Select Consignor",
        consigneePlaceholder: this.props.location[0]?.Consignee ? this.props.location[0].Consignee : "Select Consignee",
        consignorRAPlaceholder: this.props.location[0]?.RAConsignor ? this.props.location[0].RAConsignor : "Select RA Consignor",
        consigneeRAPlaceholder: this.props.location[0]?.RAConsignee ? this.props.location[0].RAConsignee : "Select RA Consignee",
        loadsize: this.props.location[0]?.CurrentLoadsize,
        regionPlaceholder: this.props.location[0]?.Region ? this.props.location[0].Region : "Select Region",
    };
    selectedConsignor: any = this.props.location[0]?.Consignor;
    selectedConsignee: any = this.props.location[0]?.Consignee;
    selectedRAConsignor: any = this.props.location[0]?.RAConsignor;
    selectedRAConsignee: any = this.props.location[0]?.RAConsignee;
    selectedRegion: any = this.props.location[0]?.Region;

    public render(): React.ReactNode {
        const { onClose, msg, region, location, raConfig } = this.props;
        const axloc = msg.filter(s => s.SourceSystem !== 'RA');
        const operator = Array.from(new Set(axloc.map((item: any) =>
            item.Description
        )));
        const oploc = msg.filter(s => s.SourceSystem === 'RA');
        const RAoperator = Array.from(new Set(oploc.map((item: any) =>
            item.Description)));
        const regionData = Array.from(new Set(region.map((item: any) => item.Name)));
        const configFlag: any = raConfig.filter((item: any) => item.Code === 'RAIntegration');
        return (
            <div>
                <div className="location" style={{ minHeight: '460px' }}>
                    <Row>
                        <Col>
                            <div>
                                Select Consignor:{' '} {configFlag[0].Value !== 'RA' && <b style={{ color: 'red' }}>*</b>}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedConsignor}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorPlaceholder}
                                        options={operator ? operator : msg}
                                        onChange={this.handleConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                Select Consignee:{' '} {configFlag[0].Value !== 'RA' && <b style={{ color: 'red' }}>*</b>}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedConsignee}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consigneePlaceholder}
                                        options={operator ? operator : msg}
                                        onChange={this.handleConsigneeType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                Select RA Consignor:{' '} {configFlag[0].Value === 'RA' && <b style={{ color: 'red' }}>*</b>}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedRAConsignor}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorRAPlaceholder}
                                        options={RAoperator ? RAoperator : msg}
                                        onChange={this.handleRAConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                Select RA Consignee:{' '} {configFlag[0].Value === 'RA' && <b style={{ color: 'red' }}>*</b>}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedRAConsignee}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consigneeRAPlaceholder}
                                        options={RAoperator ? RAoperator : msg}
                                        onChange={this.handleRAConsigneeType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                Enter Load Size:{' '} <b style={{ color: 'red' }}>*</b>
                                <div className="location__consignee">
                                    <input
                                        className={`form-control`}
                                        placeholder="Enter Load Size"
                                        onChange={this.handleChange}
                                        value={this.state.loadsize}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div >
                                Select Region:{' '} <b style={{ color: 'red' }}>*</b>
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedRegion}
                                        placeholder={this.state.regionPlaceholder}
                                        options={regionData ? regionData : region}
                                        onChange={this.handleRegionType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={configFlag[0].Value === 'RA' ?
                                    !this.selectedRAConsignee ||
                                    !this.selectedRAConsignor ||
                                    this.selectedRAConsignee === this.selectedRAConsignor ||
                                    !this.state.loadsize ||
                                    !this.selectedRegion :
                                    !this.selectedConsignee ||
                                    !this.selectedConsignor ||
                                    this.selectedConsignee === this.selectedConsignor ||
                                    !this.state.loadsize ||
                                    !this.selectedRegion
                                }
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>

                </div>
            </div>
        );
    }

    handleRegionType = option => {
        this.selectedRegion = option;
        this.setState({ regionPlaceholder: option })
    };

    handleConsignorType = option => {
        this.selectedConsignor = option;
        this.setState({ consignorPlaceholder: option })
    };

    handleConsigneeType = option => {
        this.selectedConsignee = option;
        this.setState({ consigneePlaceholder: option })
    };

    handleRAConsignorType = option => {
        this.selectedRAConsignor = option;
        this.setState({ consignorRAPlaceholder: option })
    };

    handleRAConsigneeType = option => {
        this.selectedRAConsignee = option;
        this.setState({ consigneeRAPlaceholder: option })
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ loadsize: value });
    };

    handleClick = () => {
        const { region } = this.props;
        const regionId = region.findIndex(a => a.Name == this.state.regionPlaceholder);
        let regionCode = region[regionId].ID
        this.props.onSuccessClick(this.selectedConsignor, this.selectedConsignee, this.selectedRAConsignor, this.selectedRAConsignee, this.state.loadsize, regionCode)
        this.props.onClose();
    };
}
