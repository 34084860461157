import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_carrier-facility-popup.scss';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AllCarrierCompany } from '../model/model';
import { userApprovalStore } from '../stores';
import { Checkbox } from '../../../shared/components/checkbox/checkbox';
import MultiSelect from '@kenshooui/react-multi-select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { UserRole, UserTypes } from '../user_approval_constant';
import { UserApprovalFieldName } from '../domains/enum';
import { localStorageService } from 'shared/services/local-storage-service';
import { AppConstant } from 'app_constant';
import { accountStore } from 'features/account/stores';

interface CarrierFacilityProps {
    onHide: () => void;
    listCarrier: AllCarrierCompany[];
    listFacility: { ID: number; Text: string }[];
    listPSACarrier: AllCarrierCompany[];
    userInfo: any;
    saveUser: (data: any) => void;
}

interface CarrierFacilityState {
    currentSelctedCarrier: any;
    currentSelectedFacility: any;
    carrierMenu: any;
    filterText: '';
    selectedItems: any;
}

@observer
export class CarrierFacilityComponent extends Component<CarrierFacilityProps, CarrierFacilityState> {
    userInfo: any;
    constructor(props) {
        super(props);
        this.state = {
            carrierMenu: [],
            currentSelctedCarrier: this.setSelectedCarrierState(),
            currentSelectedFacility: this.setSelectedFacilityState(),
            filterText: '',
            selectedItems: []
        };
        this.userInfo = localStorageService.get(AppConstant.USER_INFO)
        this.handleCarrierSelect = this.handleCarrierSelect.bind(this);
    }

    componentWillUnmount() {
        this.setState({ currentSelectedFacility: [], currentSelctedCarrier: [], carrierMenu: [] });
    }

    filterCarrier = e => {
        this.setState({ filterText: e.target.value });
    };

    setSelectedFacilityState = () => {
        const userInfo = userApprovalStore.currentSelectedUser;
        const facility: number[] = [];
        if (userInfo.Facility) {
            userInfo.Facility.forEach(element => {
                facility.push(element.ID);
            });
        }
        return facility;
    };

    setSelectedCarrierState = () => {
        const userInfo = userApprovalStore.currentSelectedUser;
        const carriers: number[] = [];
        if (!userApprovalStore.isPSA) {
            if (userInfo.CarrierCompany) {
                
                userInfo.CarrierCompany.forEach(element => {
                    carriers.push(element.ID);
                });
            }
        } else {
            if (userInfo.PSACarrier) {
                userInfo.PSACarrier.forEach(element => {
                    carriers.push(element.ID);
                });
            }
        }
        console.log(carriers)
        return carriers;
    };

    getSelectedCarrier = () => {
        const carriers: { ID: number, Name: string }[] = [];
        const { carrierMenu: menu, currentSelctedCarrier } = this.state;
        if (menu.length > 0) {
            for (let i = 0, l = menu.length; i < l; i++) {
                if (menu[i].selected) {
                    carriers.push({ ID: menu[i].value.ID, Name: menu[i].value.Name});
                }
            }
        } else {
            currentSelctedCarrier.forEach(element => {
                console.log(element)
                carriers.push({ ID: element.ID, Name: element.Name });
            });
        }
console.log(carriers)
        return carriers;
    };

    getSelectedFacility = () => {
        const facility: { ID: number }[] = [];
        const { currentSelectedFacility } = this.state;
        currentSelectedFacility.forEach(element => {
            facility.push({ ID: element });
        });
        return facility;
    };

    checkCarrierSelected = id => {
        const { userInfo } = this.props;
        let exist = false;
        if (!userApprovalStore.isPSA) {
            if (userInfo.CarrierCompany) {
                const carrier = userInfo.CarrierCompany.find(x => x.ID === id);
                if (carrier) {
                    exist = true;
                }
            }
        } else {
            if (userInfo.PSACarrier) {
                const carrier = userInfo.PSACarrier.find(x => x.ID === id);
                if (carrier) {
                    exist = true;
                }
            }
        }
        return exist;
    };

    checkFacilitySelected = (id, userInfo) => {
        let exist = false;
        if (userInfo.Facility) {
            const facility = userInfo.Facility.find(x => x.ID >= id && x.ID <= id);
            if (facility) {
                exist = true;
            }
        }
        return exist;
    };

    handlefacilityCheck = (value: boolean, name: number) => {
        const { currentSelectedFacility } = this.state;
        if (!value) {
            const index = currentSelectedFacility.findIndex(x => x >= (name as number) && x <= (name as number));
            if (index >= 0) {
                currentSelectedFacility.splice(index, 1);
            }
        } else {
            currentSelectedFacility.push(name as number);
        }
        this.setState({ currentSelectedFacility: currentSelectedFacility });
    };

    handleCarrierSelect(selectedItems) {
        this.setState({ selectedItems });
        const carriers: { ID: number, Name: string }[] = [];
        if (selectedItems.length > 0) {
            for (let i = 0, l = selectedItems.length; i < l; i++) {
                carriers.push({ID: selectedItems[i].id, Name:selectedItems[i].label });
            }
        }
        this.setState({ filterText: '' });
        this.setState({ currentSelctedCarrier: carriers });
        console.log(carriers)
    }
    searchRenderer = () => {
        return (
            <div className="searchMultiple">
                <input
                    value={this.state.filterText}
                    type="text"
                    autoComplete="off"
                    onChange={this.filterCarrier}
                    placeholder="Search"
                    className={'searchMultiple'}
                ></input>
            </div>
        );
    };
    render(): React.ReactNode {
        const { userInfo } = this.props;
        const { currentSelctedCarrier, filterText, selectedItems } = this.state;
        const selectedValue: string[] = [];
        if (currentSelctedCarrier.length > 0) {
            currentSelctedCarrier.forEach(element => {
                selectedValue.push(element);
            });
        }
        const carrierList = userApprovalStore.isPSA ? this.props.listPSACarrier : this.props.listCarrier;
        const filteredCarrierList = carrierList.filter(x =>
            x.CompanyName.toLowerCase().includes(filterText.toLowerCase())
        );
        let mapValueList: any =  [];
        if (this.userInfo?.isPSA && accountStore.userRoles.includes('dispatcher') && !accountStore.userName.includes('@plains.com')) {
            mapValueList = filteredCarrierList.map(X => (
                <option key={X.ID} value={X.ID} disabled = {X.CompanyName !== this.userInfo.PSACarrierCompany}>
                    {X.CompanyName}
                </option>
            ));
        } else {
            mapValueList = filteredCarrierList.map(X => (
                <option key={X.ID} value={X.ID} disabled = {false}>
                    {X.CompanyName}
                </option>
            ));
        }
        
        const filterMapList = mapValueList.map(x => x.props);
        const itemCarrierList = filterMapList.map(({ value, children, disabled }) => ({ id: value, label: children, disabled: disabled }));
        return (
            <div className="carrier-facility-popup__parent">
                <div>
                    <label className="selectbox">Carrier</label>
                    <MultiSelect
                        items={itemCarrierList}
                        wrapperClassName={'SelMapped'}
                        showSelectedItems={false}
                        searchRenderer={this.searchRenderer}
                        showSelectAll={false}
                        selectedItems={selectedItems}
                        onChange={this.handleCarrierSelect}
                    ></MultiSelect>
                </div>

                {userApprovalStore.isPSA && (
                    <div>
                        <label className="selectbox">Facility</label>
                        <div className="searchboxScroll">
                            {this.props.listFacility.map(X => (
                                <div key={X.ID} className="facilityItem">
                                    <Checkbox
                                        onChange={value => this.handlefacilityCheck(value, X.ID)}
                                        value={this.checkFacilitySelected(X.ID, userInfo)}
                                    />
                                    <label className="labelFacility">{X.Text}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="shop-assignment-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={userApprovalStore.showModal ? this.saveUser: this.editCarrier } 
                        disabled = {!userApprovalStore.isPSA ? this.state.currentSelctedCarrier.length <= 0 : this.state.currentSelctedCarrier.length <= 0 || this.state.currentSelectedFacility.length <= 0}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }
    editCarrier = () => {
        // userApprovalStore.setUserInfo(userApprovalStore.backupUserInfo);
        // userApprovalStore.cellValueChangeMap = {};
        console.log('editcarrier',userApprovalStore.showModal,userApprovalStore.isPSA)
        const { userInfo } = this.props;
        const carrierCompany = this.getSelectedCarrier();
        userInfo.CarrierCompany = carrierCompany;
        userApprovalStore.updateCarrierCompany(
            JSON.parse(JSON.stringify(userInfo)),
            carrierCompany[0],
            UserApprovalFieldName.MappedCarrierName);
        userApprovalStore.updateUserDetails();
        this.props.onHide();
    }
    saveUser = () => {
        console.log('saveUser',userApprovalStore.showModal,userApprovalStore.isPSA)
        const { userInfo } = this.props;
        const carrierCompany = this.getSelectedCarrier();
        const facility = this.getSelectedFacility();
        if (userApprovalStore.isPSA) {
            userInfo.PSACarrier = carrierCompany;
        } else {
            userInfo.CarrierCompany = carrierCompany;
        }
        userInfo.Facility = facility;
        const confirmService = new ConfirmService();
        if (!userApprovalStore.isPSA) {
            if(UserTypes.includes(userInfo.UserType)){
                confirmService.showUserRolePopup(role => {
                    console.log(role);
                    userApprovalStore.approvedRole = role;
                    if (role === 'Driver') {
                        userInfo.IsCarrierCompany = false;
                        userInfo.IsOwnerOperator = false;
                        userInfo.IsCarrierCompanyAdmin = false;
                        userInfo.IsCarrierCompanyScheduler = false;
                        userInfo.IsDriver = true;
                        userInfo.IsThirdPartyUser = false;
                    } else if (role === 'Owner/Driver') {
                        userInfo.IsCarrierCompany = false;
                        userInfo.IsOwnerOperator = true;
                        userInfo.IsCarrierCompanyAdmin = false;
                        userInfo.IsCarrierCompanyScheduler = false;
                        userInfo.IsDriver = true;
                        userInfo.IsThirdPartyUser = false;
                    } else if (role === 'Carrier Company Admin') {
                        console.log('Admin');
                        userInfo.IsCarrierCompany = false;
                        userInfo.IsOwnerOperator = false;
                        userInfo.IsCarrierCompanyAdmin = true;
                        userInfo.IsCarrierCompanyScheduler = false;
                        userInfo.IsDriver = false;
                        userInfo.IsThirdPartyUser = false;
                    } else if (role === 'Carrier Company Scheduler') {
                        console.log('Scheduler');
                        userInfo.IsCarrierCompany = false;
                        userInfo.IsOwnerOperator = false;
                        userInfo.IsCarrierCompanyAdmin = false;
                        userInfo.IsCarrierCompanyScheduler = true;
                        userInfo.IsDriver = false;
                        userInfo.IsThirdPartyUser = false;
                    } else if (role === 'Thirdparty Dispatcher') {
                        userInfo.IsCarrierCompany = false;
                        userInfo.IsOwnerOperator = false;
                        userInfo.IsCarrierCompanyAdmin = false;
                        userInfo.IsCarrierCompanyScheduler = false;
                        userInfo.IsDriver = false;
                        userInfo.IsThirdPartyUser = true;
                    }
                    console.log(userInfo);
                    this.props.saveUser(userInfo);
                },
                UserRole, 
                userInfo.UserType
            );
        } else {
            this.props.saveUser(userInfo);
        }
        } else {
            this.props.saveUser(userInfo);
        }
        this.props.onHide();
    };
}
