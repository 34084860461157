import { ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { RegionType, RegionMappingType, Region, CompanyMappingType } from '../model/model';
import { Company_Region_Type } from '../mapping_portal_constant';

export class MappingDataService {
    constructor(private restApi: RestApiService) {}

    public getRegionTypes(): Promise<ResponseDataType<RegionType[]>> {
        return this.restApi.postWithToken<RegionType[]>(Config.getBaseValueByClass, { CODE: Company_Region_Type });
    }

    public getCompanyRegionMapping(companyRequestdata): Promise<ResponseDataType<RegionMappingType[]>> {
        return this.restApi.postWithToken<RegionMappingType[]>(Config.getCompanyRegionMapping, companyRequestdata);
    }

    public saveRegion(data): Promise<ResponseDataType<Region>> {
        return this.restApi.postWithToken<Region>(Config.saveCompanyRegionMapping, data);
    }

    public getCompanyTrailerMapping(companyRequestdata): Promise<ResponseDataType<CompanyMappingType[]>> {
        return this.restApi.postWithToken<CompanyMappingType[]>(
            Config.getCarrierCompanyMappingDriverTrailer,
            companyRequestdata
        );
    }

    public saveCompanyMapping(data): Promise<ResponseDataType<Region>> {
        return this.restApi.postWithToken<Region>(Config.saveCompanyMapping, data);
    }
}

export const mappingDataService = new MappingDataService(restApiService);
