import { ColDef } from "ag-grid-community";
import { KeyfobFieldName, KeyfobHeaderName } from "./keyfobs.enum";
import { dataStore } from "features/common/stores";
import { CheckBoxCellRenderer } from "../components/cell-renderers/keyfob-checkbox-cell-renderer";
import { dateUtils } from "shared/services/date-utils";

export const KeyFobColDef: ColDef[] = [
    {
        headerName: KeyfobHeaderName.ID,
        field: KeyfobFieldName.ID
    },
    {
        headerName: KeyfobHeaderName.KEYFOBNUMBER,
        field: KeyfobFieldName.KEYFOBNUMBER,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[KeyfobFieldName.KEYFOBNUMBER] === null || params.data[KeyfobFieldName.KEYFOBNUMBER] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.KEYFOBNUMBER]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.CARRIERCOMPANY,
        field: KeyfobFieldName.CARRIERCOMPANY,
        cellEditor: 'agSelectCellEditor',
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[KeyfobFieldName.CARRIERCOMPANY] === null || params.data[KeyfobFieldName.CARRIERCOMPANY] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.CARRIERCOMPANY]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.LOCATION,
        field: KeyfobFieldName.LOCATION,
        cellEditor: 'agSelectCellEditor',
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[KeyfobFieldName.LOCATION] === null || params.data[KeyfobFieldName.LOCATION] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.LOCATION]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.ASSIGNEDDATE,
        field: KeyfobFieldName.ASSIGNEDDATE,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[KeyfobFieldName.ASSIGNEDDATE] === null || params.data[KeyfobFieldName.ASSIGNEDDATE] === '') {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[KeyfobFieldName.ASSIGNEDDATE])
            }
        }
    },
    {
        headerName: KeyfobHeaderName.STATUS,
        field: KeyfobFieldName.STATUS,
        cellEditor: 'agSelectCellEditor',
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[KeyfobFieldName.STATUS] === null || params.data[KeyfobFieldName.STATUS] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.STATUS]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.CREATEDBY,
        field: KeyfobFieldName.CREATEDBY,
        valueGetter: params => {
            if (params.data[KeyfobFieldName.CREATEDBY] === null || params.data[KeyfobFieldName.CREATEDBY] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.CREATEDBY]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.CREATEDDATE,
        field: KeyfobFieldName.CREATEDDATE,
        valueGetter: params => {
            if (params.data[KeyfobFieldName.CREATEDDATE] === null || params.data[KeyfobFieldName.CREATEDDATE] === '') {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[KeyfobFieldName.CREATEDDATE])
            }
        }
    },
    {
        headerName: KeyfobHeaderName.MODIFIEDBY,
        field: KeyfobFieldName.MODIFIEDBY,
        valueGetter: params => {
            if (params.data[KeyfobFieldName.MODIFIEDBY] === null || params.data[KeyfobFieldName.MODIFIEDBY] === '') {
                return '';
            } else {
                return params.data[KeyfobFieldName.MODIFIEDBY]
            }
        }
    },
    {
        headerName: KeyfobHeaderName.MODIFIEDDATE,
        field: KeyfobFieldName.MODIFIEDDATE,
        valueGetter: params => {
            if (params.data[KeyfobFieldName.MODIFIEDDATE] === null || params.data[KeyfobFieldName.MODIFIEDDATE] === '') {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[KeyfobFieldName.MODIFIEDDATE])
            }
        }
    },
    {
        headerName: KeyfobHeaderName.DELETE,
        field: KeyfobFieldName.DELETE,
        cellRendererFramework: CheckBoxCellRenderer
    }

]