import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListSelect from './list-select';
import { ConfigValue } from './message';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { UiService } from 'shared/services/ui-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { MappingDataStore } from '../store/mapping-store';
import { dataStore } from '../../common/stores/index';
import { inject, observer } from 'mobx-react';
import { CommonUtils } from 'shared/services/common-utils';
import { localStorageService } from '../../../shared/services/local-storage-service';

import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';
import _ from 'lodash';
import { AppConstant } from 'app_constant';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface MappingManagemetState {
    regionID: number;
    showLoader: boolean;
    dictNotMappedCompany: { ID: number; Text: string }[];
    dictMappedCompany: { ID: number; Text: string }[];
    dictNotMappedTrailer: { ID: number; Text: string }[];
    dictMappedTrailer: { ID: number; Text: string }[];
    dictNotMappedConsignorConsignee: { ID: number; Text: string }[];
    dictMappedConsignorConsignee: { ID: number; Text: string }[];
    dictMappedDriver: { ID: number; Text: string }[],
    dictNotMappedDriver: { ID: number; Text: string }[],
    selectedRegion: number;
}
interface MappingManagemetProps {
    mappingDataStore?: MappingDataStore;
}

@inject('mappingDataStore')
@observer
export class MappingManagementComponent extends React.Component<MappingManagemetProps, MappingManagemetState, any> {
    constructor(props) {
        super(props);

        this.state = {
            regionID: 0,
            showLoader: false,
            dictNotMappedCompany: [],
            dictMappedCompany: [],
            dictNotMappedTrailer: [],
            dictMappedTrailer: [],
            dictMappedConsignorConsignee: [],
            dictNotMappedConsignorConsignee: [],
            dictMappedDriver: [],
            dictNotMappedDriver: [],
            selectedRegion: 0
        };
        this.saveRegion = this.saveRegion.bind(this);
        dataStore.setModuleName('RegionMapping');
    }
    render(): React.ReactNode {
        return <div>{this.renderGrid('')}</div>;
    }

    componentWillUnmount() {
        localStorageService.remove('region');
    }

    componentDidMount() {
        const { mappingDataStore } = this.props;
        mappingDataStore?.setIsUpdated(false);
        mappingDataStore?.GetMasterData().then(() => {
            if (mappingDataStore !== undefined && mappingDataStore?.baseValueDTO.length > 0) {
                this.setState({ regionID: mappingDataStore?.baseValueDTO[0].ID as number });
                if (mappingDataStore) {
                    mappingDataStore.selectedRegionID = mappingDataStore?.baseValueDTO[0].ID as number;
                }
                this.getData(mappingDataStore?.baseValueDTO[0].ID as number);
            }
        });
    }

    GetMasterData = async () => {
        const { mappingDataStore } = this.props;
        mappingDataStore?.GetMasterData();
    };

    regionChange = (FilterID: any) => {
        this.setState({selectedRegion: FilterID})
        const { mappingDataStore } = this.props;
        if (mappingDataStore?.isUpdated) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => this.getData(FilterID),
                '',
                'Unsaved changes on the region will be lost. Are you sure you want to navigate away?'
            );
        }
        else {
            this.getData(FilterID)
        }
        mappingDataStore?.setIsUpdated(false);
    };

    addCompanyItemToList = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objToSave.lstCompany = [];
        }
        items.forEach(item => {
            mappingDataStore?.objToSave.lstCompany.push(item.ID);
        });
    };

    setIsIUpdated = (value: boolean) => {
        const { mappingDataStore } = this.props;
        mappingDataStore?.setIsUpdated(value);
    }

    addTrailerItemToList = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objToSave.lstTrailer = [];
        }
        items.forEach(item => {
            mappingDataStore?.objToSave.lstTrailer.push(item.ID);
        });
    };

    addDriverItemToList = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objToSave.lstDrivers = [];
        }
        items.forEach(item => {
            mappingDataStore?.objToSave.lstDrivers.push(item.ID);
        });
    };


    addConsignorConsigneeItemToList = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objToSave.lstConsignorConsignee = [];
        }
        items.forEach(item => {
            mappingDataStore?.objToSave.lstConsignorConsignee.push(item.ID);
        });
    };

    getData = async (FilterID: any) => {
        const { mappingDataStore } = this.props;
        this.setState({ showLoader: true });
        this.setState({ regionID: FilterID });
        if (mappingDataStore) {
            mappingDataStore.selectedRegionID = FilterID;
        }
        this.GetMasterData().then(() => {
            const CompanyRequestdata = { regionID: FilterID };
            if (mappingDataStore !== undefined) {
                mappingDataStore.getRegionMappingData(CompanyRequestdata).then(() => {
                    const dictNotMappedCompany = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.NotMappedCompany
                    );
                    const dictMappedCompany = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.MappedCompany
                    );
                    const dictNotMappedTrailer = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.NotMappedTrailer
                    );
                    const dictMappedTrailer = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.MappedTrailer
                    );
                    const dictNotMappedDriver = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.NotMappedDriver
                    );
                    const dictMappedDriver = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.MappedDriver
                    );
                    const dictNotMappedConsignorConsignee = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.NotMappedConsignorConsignee
                    );
                    const dictMappedConsignorConsignee = CommonUtils.dictionaryToObj(
                        mappingDataStore?.regionMappingData.MappedConsignorConsignee
                    );
                    mappingDataStore.objToSave.lstCompany = [];
                    mappingDataStore.objToSave.lstTrailer = [];
                    mappingDataStore.objToSave.lstConsignorConsignee = [];
                    for (const key in mappingDataStore?.regionMappingData.MappedCompany) {
                        mappingDataStore.objToSave.lstCompany.push((key as unknown) as number);
                    }
                    for (const key in mappingDataStore?.regionMappingData.MappedTrailer) {
                        mappingDataStore?.objToSave.lstTrailer.push((key as unknown) as number);
                    }
                    for (const key in mappingDataStore?.regionMappingData.MappedDriver) {
                        mappingDataStore.objToSave.lstDrivers.push((key as unknown) as number);
                    }
                    for (const key in mappingDataStore?.regionMappingData.MappedConsignorConsignee) {
                        mappingDataStore?.objToSave.lstConsignorConsignee.push((key as unknown) as number);
                    }
                    this.setState({ dictNotMappedCompany: dictNotMappedCompany });
                    this.setState({ dictMappedCompany: dictMappedCompany });
                    this.setState({ dictNotMappedTrailer: dictNotMappedTrailer });
                    this.setState({ dictMappedTrailer: dictMappedTrailer });
                    this.setState({ dictNotMappedDriver : dictNotMappedDriver});
                    this.setState({ dictMappedDriver : dictMappedDriver});
                    this.setState({ dictNotMappedConsignorConsignee: dictNotMappedConsignorConsignee });
                    this.setState({ dictMappedConsignorConsignee: dictMappedConsignorConsignee });
                    this.setState({ showLoader: false });
                });
            }
        });
    };

    saveRegion = async () => {
        this.setState({ showLoader: true });
        const { mappingDataStore } = this.props;
        if (this.state.regionID > 0 && mappingDataStore !== undefined) {
            mappingDataStore.objToSave.regionID = this.state.regionID;
            mappingDataStore?.saveRegion(mappingDataStore.objToSave).then(() => {
                this.getData(this.state.regionID);
                mappingDataStore?.setIsUpdated(false);
            });
        }
    };
    exportCSV = () => {
        const { dictMappedCompany, dictMappedTrailer ,dictMappedConsignorConsignee,dictMappedDriver } = this.state
        let dictMappedCompanyJson1 = JSON.stringify(dictMappedCompany)
        let dictMappedConsignorConsigneeJson1 = JSON.stringify(dictMappedConsignorConsignee)
        let dictMappedDriverJson1 = JSON.stringify(dictMappedDriver)
        let dictMappedTrailerJson1 = JSON.stringify(dictMappedTrailer)
        //Renaming the field values in the jsons
        let carrierCOmpanyStr = dictMappedCompanyJson1.replace(/\"Text\":/g, "\"Mapped Carrier Company\":");
        let consignorConsigneeStr = dictMappedConsignorConsigneeJson1.replace(/\"Text\":/g, "\"Mapped Consignor Consginee\":");
        let driverStr = dictMappedDriverJson1.replace(/\"Text\":/g, "\"Mapped Driver\":");
        let trailerStr = dictMappedTrailerJson1.replace(/\"Text\":/g, "\"Mapped Trailer\":");
        let dictMappedCompanyJson = JSON.parse(carrierCOmpanyStr);
        let dictMappedTrailerJson = JSON.parse(trailerStr)
        let dictMappedConsignorConsigneeJson = JSON.parse(consignorConsigneeStr);
        let dictMappedDriverJson = JSON.parse(driverStr);
        //removing the non-required fields
        const fields = [
            'id',
            'ID',
            'label'
        ];
        for (let i = 0; i < dictMappedCompanyJson.length; i++) {
            for (const key in dictMappedCompanyJson[i]) {
                if (fields.indexOf(key) !== -1) delete dictMappedCompanyJson[i][key];
            }
        }
        for (let i = 0; i < dictMappedTrailerJson.length; i++) {
            for (const key in dictMappedTrailerJson[i]) {
                if (fields.indexOf(key) !== -1) delete dictMappedTrailerJson[i][key];
            }
        }
        for (let i = 0; i < dictMappedConsignorConsigneeJson.length; i++) {
            for (const key in dictMappedConsignorConsigneeJson[i]) {
                if (fields.indexOf(key) !== -1) delete dictMappedConsignorConsigneeJson[i][key];
            }
        }
        for (let i = 0; i < dictMappedDriverJson.length; i++) {
            for (const key in dictMappedDriverJson[i]) {
                if (fields.indexOf(key) !== -1) delete dictMappedDriverJson[i][key];
            }
        }
        const mappedCompanyArray = Object.entries(dictMappedCompanyJson).map(([key, value]) => ({ value }))
        const mappedTrailerArray = Object.entries(dictMappedTrailerJson).map(([key, value]) => ({ value }))
        const mappedConsignerConsgineeArray = Object.entries(dictMappedConsignorConsigneeJson).map(([key, value]) => ({ value }))
        const mappedDriverArray = Object.entries(dictMappedDriverJson).map(([key, value]) => ({ value }))
        var excelDataArray: any = []
        var excelDataArraySecond: any = []
        //Adding empty field to merge the arrays if one array is greater than the other
        if (mappedCompanyArray.length >= mappedTrailerArray.length) {
            for (let v = 0; v < mappedCompanyArray.length; v++) {
                var updatedDic: any = mappedCompanyArray[v].value
                if (mappedTrailerArray.length > v) {
                    let mapEmptyObjectToArray: any = mappedTrailerArray[v].value
                    updatedDic["Mapped Trailer"] = mapEmptyObjectToArray["Mapped Trailer"]
                } else {
                    updatedDic["Mapped Trailer"] = ""
                }
                excelDataArray.push(updatedDic)
            }
        } else if (mappedCompanyArray.length < mappedTrailerArray.length) {
            for (let v = 0; v < mappedTrailerArray.length; v++) {
                var updatedDic: any = mappedTrailerArray[v].value
                if (mappedCompanyArray.length > v) {
                    let mapEmptyObjectToArray: any = mappedCompanyArray[v].value
                    updatedDic["Mapped Carrier Company"] = mapEmptyObjectToArray["Mapped Carrier Company"]
                } else {
                    updatedDic["Mapped Carrier Company"] = ""
                }
                excelDataArray.push(updatedDic)
            }
        }
        if (mappedConsignerConsgineeArray.length >= mappedDriverArray.length) {
            for (let v = 0; v < mappedConsignerConsgineeArray.length; v++) {
                var updatedDic: any = mappedConsignerConsgineeArray[v].value
                if (mappedDriverArray.length > v) {
                    let mapEmptyObjectToArray: any = mappedDriverArray[v].value
                    updatedDic["Mapped Driver"] = mapEmptyObjectToArray["Mapped Driver"]
                } else {
                    updatedDic["Mapped Driver"] = ""
                }
                excelDataArraySecond.push(updatedDic)
            }
        } else if (mappedConsignerConsgineeArray.length < mappedDriverArray.length) {
            for (let v = 0; v < mappedDriverArray.length; v++) {
                var updatedDic: any = mappedDriverArray[v].value
                if (mappedConsignerConsgineeArray.length > v) {
                    let mapEmptyObjectToArray: any = mappedConsignerConsgineeArray[v].value
                    updatedDic["Mapped Consignor Consginee"] = mapEmptyObjectToArray["Mapped Consignor Consginee"]
                } else {
                    updatedDic["Mapped Consignor Consginee"] = ""
                }
                excelDataArraySecond.push(updatedDic)
            }
        }
        
        let data: any = [];
        let finalArray: any = [];
        if(excelDataArray.length <= excelDataArraySecond.length) {
            for (let v = 0; v < excelDataArraySecond.length; v++) {
                 if(excelDataArray[v] !=undefined && excelDataArray[v].value != "") {
                    let objC : any = { ...excelDataArray[v], ...excelDataArraySecond[v] }
                    finalArray.push(objC)
                 }else {
                    let objA = {"Mapped Carrier Company" :"" }
                    let objB = {"Mapped Trailer" :"" }
                    let objMerged : any = { ...objA, ...objB, ...excelDataArraySecond[v] }
                    finalArray.push(objMerged)
                 }
            }
        }else if(excelDataArray.length > excelDataArraySecond.length) {
            for (let v = 0; v < excelDataArray.length; v++) {
                 if(excelDataArraySecond[v] !=undefined && excelDataArraySecond[v].value != "") {
                    let objC : any = { ...excelDataArray[v], ...excelDataArraySecond[v] }
                    finalArray.push(objC)
                 }else {
                    let objA = {"Mapped Driver" :"" }
                    let objB = {"Mapped Consignor Consginee" :"" }
                    let objMerged : any = { ...excelDataArray[v], ...objA, ...objB }
                    finalArray.push(objMerged)
                 }
            }
        }
        finalArray.forEach(x => {
            data.push(x.data);
        });
        const ws = XLSX.utils.json_to_sheet(finalArray);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
    };
    renderItem() {
        const { mappingDataStore } = this.props;
        return !mappingDataStore?.menuLoader &&
            mappingDataStore?.baseValueDTO &&
            mappingDataStore?.baseValueDTO.length > 0 ? (
                <Container>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="master-data__action">
                                        <Row>
                                            <Col className="mt-3 mt-sm-0">
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    disabled={false}
                                                    onClick={this.exportCSV}
                                                >
                                                    Export to Excel
                                                </CustomButton>
                                            </Col>
                                        </Row>
                                        {dataStore.checkOperationAccess('Save') && (
                                            <Row> 
                                                 <Col className="mt-3 mt-sm-0">
                                                     <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        disabled={false}
                                                        onClick={this.saveRegion}
                                                         >
                                                             {' '}
                                                             Save
                                                     </CustomButton>
                                                </Col>
                                            </Row>
                                        )}
                                  <CustomButton type={CustomButtonType.Submit} onClick={this.handleRefresh}> Refresh </CustomButton>              
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col>
                                    <Nav className="ml-3 d-none d-sm-block">
                                        {mappingDataStore?.baseValueDTO.map(X => {
                                            const f1 = a => t => this.regionChange(X.ID);
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <NavLink
                                                    className={`header__link ${
                                                        this.state.regionID == X.ID ? 'header-active' : 'lnkInactive'
                                                        }`}
                                                    onClick={f1(X.ID)}
                                                    to={`#`}
                                                >
                                                    {X.Name}
                                                    {ConfigValue.Region}
                                                </NavLink>
                                            );
                                        })}
                                    </Nav>
                                </Col>
                            </Row>
                        </Container>
                        <hr></hr>
                    </div>
                </Container>
            ) : (
                <div></div>
            );
    }

    handleRefresh = () => {
        const { mappingDataStore } = this.props;
        if(mappingDataStore?.isUpdated === false) {
        mappingDataStore?.GetMasterData().then(() => {
            if (mappingDataStore !== undefined && mappingDataStore?.baseValueDTO.length > 0) {
                this.getData(this.state.selectedRegion === 0 ? mappingDataStore?.baseValueDTO[0].ID : this.state.selectedRegion  as number);
            }
        }); }
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    mappingDataStore?.GetMasterData().then(() => {
                        if (mappingDataStore !== undefined && mappingDataStore?.baseValueDTO.length > 0) {
                            this.getData(this.state.selectedRegion === 0 ? mappingDataStore?.baseValueDTO[0].ID : this.state.selectedRegion  as number);
                        }
                    })
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
        mappingDataStore?.setIsUpdated(false);
    }

    renderGrid(url): React.ReactNode {
        const {
            showLoader,
            dictNotMappedCompany,
            dictMappedCompany,
            dictNotMappedTrailer,
            dictMappedTrailer,
            dictMappedDriver,
            dictNotMappedDriver,
            dictMappedConsignorConsignee,
            dictNotMappedConsignorConsignee
        } = this.state;
        const toastService = new UiService();
        if (showLoader) {
            toastService.loaderService.showLoader();
        } else {
            toastService.loaderService.hideLoader();
        }
        return (
            <>
                {this.renderItem()}

                <Collapisble title="RELATIONSHIP MAPPING">
                    <Container>
                        <div className={'pdiv'}>
                            {showLoader ? (
                                <div></div>
                            ) : (
                                    <>
                                        <div style={{ display: 'flex' }}>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <ListSelect
                                                            filterBy={'companyfilter'}
                                                            filterMapped={'companyfilterMapped'}
                                                            headerHeading={'COMPANY'}
                                                            selectall={false}
                                                            notMapped={dictNotMappedCompany}
                                                            mapped={dictMappedCompany}
                                                            btnRemove={'btnRemoveCompany'}
                                                            btnAdd={'btnAddCompany'}
                                                            id={'lstCompany'}
                                                            handleItems={this.addCompanyItemToList}
                                                            setIsUpdated={this.setIsIUpdated}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <ListSelect
                                                            filterBy={'trailerfilter'}
                                                            filterMapped={'trailerFilterMapped'}
                                                            headerHeading={'TRAILER'}
                                                            mapped={dictMappedTrailer}
                                                            notMapped={dictNotMappedTrailer}
                                                            btnRemove={'btnRemoveTrailer'}
                                                            btnAdd={'btnAddTrailer'}
                                                            id={'lstTrailer'}
                                                            handleItems={this.addTrailerItemToList}
                                                            setIsUpdated={this.setIsIUpdated}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ListSelect
                                                            filterBy={'consignorconsigneefilter'}
                                                            filterMapped={'consignorconsigneefilterMapped'}
                                                            headerHeading={'CONSIGNOR-CONSGINEE'}
                                                            selectall={false}
                                                            notMapped={dictNotMappedConsignorConsignee}
                                                            mapped={dictMappedConsignorConsignee}
                                                            btnRemove={'btnRemoveConsignorConsignee'}
                                                            btnAdd={'btnAddConsignorConsignee'}
                                                            id={'lstConsignorConsignee'}
                                                            handleItems={this.addConsignorConsigneeItemToList}
                                                            setIsUpdated={this.setIsIUpdated}
                                                        />
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col>
                                                        <ListSelect
                                                            filterBy={'driverFilter'}
                                                            filterMapped={'driverfilterMapped'}
                                                            headerHeading={'DRIVER'}
                                                            mapped={dictMappedDriver}
                                                            notMapped={dictNotMappedDriver}
                                                            btnRemove={'btnRemoveDriver'}
                                                            btnAdd={'btnAddDriver'}
                                                            handleItems={this.addDriverItemToList}
                                                        />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </Container>

                                            {/* <div className="v1"></div>*/}
                                        </div>
                                        
                                    </>
                                )}
                        </div>
                    </Container>
                </Collapisble>
            </>
        );
    }
}
