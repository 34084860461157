import React, { PureComponent } from 'react';
import './_alpha-numeric-cell-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

interface AlphaNumericCellRendererState {
    value: string;
}

interface AlphaNumericCellRendererParams extends ICellRendererParams {
    onChange: (data, value) => void;
    isReadonly: (data, value) => boolean;
}

export class AlphaNumericCellRenderer extends PureComponent<AlphaNumericCellRendererParams, AlphaNumericCellRendererState> {
    constructor(props: AlphaNumericCellRendererParams) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    static defaultProps = {
        isReadonly: (data, value) => false
    };

    handleKeyDown = (event) => {
        var charCode = (event.which) ? event.which : event.KeyCode;
        //allow for letter,number,spacebar,comma,period,dash,backspace
        if (!((charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 91)
            || (charCode > 96 && charCode < 123) || charCode == 0 || (charCode > 187 && charCode < 191) || charCode == 32
            || charCode == 8 || charCode == 222)) {
            event.preventDefault();
            return;
        }
    }

    handleChange = (event): void => {
        this.setState({ value: event.target.value });
    };

    handleBlur = (): void => {
        const { onChange } = this.props;
        onChange(this.props.data, this.state.value);
    };
    commaFormat = (number) => {
        return number.toLocaleString('en-US')
    }
    render(): React.ReactNode {
        const { value } = this.state;
        const { isReadonly, data } = this.props;
        const readonly = isReadonly(data, value);
        if (readonly) {
            return <div>{value}</div>;
        }
        return (
            <div className="alpha-numeric-cell">
                <input type="text" value={this.commaFormat(value)}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                    readOnly={isReadonly(data, value)} />
            </div>
        );
    }
}
