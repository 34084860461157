/* eslint-disable react/jsx-key */
import * as React from 'react';
import './customerReportSchedulePopup.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../../../shared/components/input/input';
import { CustomButtonType, CustomButton } from '../../../shared/components/custom-button/custom-button';
import { CommonUtils } from 'shared/services/common-utils';
import { AppConstant } from 'app_constant';
import { accountStore } from 'features/account/stores';
import { CCUserRoles, plainsRoles } from 'features/dispatch/communication/communication-constants';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { customerReportStatus, reportticketStatus } from '../reports_constants';
// import DatePicker from 'react-datetime';
import moment from 'moment';
import '../../../shared/components/confirm/_confirm.scss';
import DatePicker from "react-datepicker";
import { dateUtils } from 'shared/services/date-utils';
import Select from 'react-select';


var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
    return current.isAfter(yesterday);
};
const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      border: '1px solid lightgray',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#4a4a4a',
      padding: '0px',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: '#4a4a4a',
      padding: '0px',
    }),
  };
export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (data) => void;
    mail: boolean;
    msg;
    reply;
    status;
    fields;
    selectedLeaseOperator;
    selectedConsignee;
    emailConfiguration;
    locationDropdown;
    module;
}
export const frequencyData = ['Daily', 'Weekly', 'Monthly', 'Adhoc'];
export const weekData = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const type = ['Volume', 'Hourly', 'All'];
export const reportType = ['Cumulative', 'SingleDay'];
export const status = customerReportStatus;
export class CustomerReportScheduleEmailPopup extends React.Component<ConfirmProps> {
    emailConfigurationData = this.props.emailConfiguration ? this.props.emailConfiguration : [];
    startDate: any;
    startMonth: any = moment(new Date());
    dailyTime = this.emailConfigurationData.length > 0 && this.props.emailConfiguration[0].ScheduledTime!== '' && this.props.emailConfiguration[0].Frequency === 'Daily'
        ? moment.utc(new Date().setHours(this.props.emailConfiguration[0].ScheduledTime.split(':')[0],this.props.emailConfiguration[0].ScheduledTime.split(':')[1])).format('HH:mm') : '';
    weeklyTime = this.emailConfigurationData.length > 0 && this.props.emailConfiguration[0].ScheduledTime!== '' && this.props.emailConfiguration[0].Frequency === 'Weekly'
        ? moment.utc(new Date().setHours(this.props.emailConfiguration[0].ScheduledTime.split(':')[0],this.props.emailConfiguration[0].ScheduledTime.split(':')[1])).format('HH:mm') : '';
    state = {
        startDateTime: 
            this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].Frequency !== ''
                ? this.emailConfigurationData[0].Frequency === 'Weekly'
                    ? this.toDateObject(this.emailConfigurationData[0].ScheduledTime)
                    : null
                : null,
        emailId: this.emailConfigurationData.length > 0 ? this.emailConfigurationData[0].EMailAddress : '',
        invalidEmail: '',
        message: '',
        isReply: false,
        reply: false,
        startDate: 
            this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].Frequency !== ''
                ? this.emailConfigurationData[0].Frequency === 'Daily'
                    ? this.toDateObject(this.emailConfigurationData[0].ScheduledTime)
                    : null
                : null,
        selectedFrequency:
            this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].Frequency !== ''
                ? this.emailConfigurationData[0].Frequency
                : frequencyData[0],
        selectedWeek:
            this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].WeekDay !== ''
                ? this.emailConfigurationData[0].WeekDay
                : weekData[0],
        fieldName: '',
        filteredFields: this.props.fields,
        filteredStatus: this.props.status,
        selectedFields:
            this.emailConfigurationData.length > 0
                ? this.emailConfigurationData[0].FieldForReport.split(',')
                : ([] as string[]),
        selectedType:  this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].TicketType !== ''
        ? this.emailConfigurationData[0].TicketType
        : type[2],
        selectedStatus:
            this.emailConfigurationData.length > 0
                ? this.emailConfigurationData[0].Status.replace("Canceled","Cancelled").split(',')
                : ([] as string[]),
        selectedLeaseOperator: this.props.selectedLeaseOperator ? this.props.selectedLeaseOperator : [],
        selectedConsignee: this.props.selectedConsignee ? this.props.selectedConsignee : [],
        selectedReportType: this.emailConfigurationData.length > 0 && this.emailConfigurationData[0].ReportType !== ''
        ? this.emailConfigurationData[0].ReportType
        : reportType[0],
    };
    toDateObject(time) {
        if(time && time != null) {
        let pieces: any = time.split(':');
        let date1 = new Date(
            moment()
                .toISOString()
                .slice(0, 10) + ' ' +
                pieces[0] +
                ':' +
                pieces[1] +
                ':00'
        );
        console.log(date1);

        return date1;
        } else {
            return null;
        }


    }
    public render(): React.ReactNode {
        const { onClose, mail, msg, reply, locationDropdown, module } = this.props;
        return (
            <div className="confirmPopup" style={{width: '620px'}}>
                <div className="view-details-form">
                {module == 'ScheduleReport' && (
                        <>
                            <Row>
                                <div className="locations-label mt-6"> Consignor Name </div>
                                <div className="locations-dropdown">
                                    <Select
                                        styles={customSelectStyles}
                                        value={this.state.selectedLeaseOperator}
                                        placeholder="Select Consignor"
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        options={locationDropdown}
                                        onChange={this.handleConsignorChange}
                                        isMulti={true}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="locations-label mt-11"> Consignee Name </div>
                                <div className="locations-dropdown">
                                    <Select
                                        styles={customSelectStyles}
                                        value={this.state.selectedConsignee}
                                        placeholder="Select Consignee"
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        options={locationDropdown}
                                        onChange={this.handleConsigneeChange}
                                        isMulti={true}
                                    />
                                </div>
                            </Row>
                        </>
                    )}
                <Row>
                    <div className="confirmPopup__action">Enter Email Address</div>
                    <div className="entryField">
                        <input
                            className={`email-input ${this.state.invalidEmail}`}
                            placeholder="Enter Email ID"
                            onChange={this.handleChange}
                            value={this.state.emailId}
                        />
                        {this.state.invalidEmail && (
                            <div className="email-error">{AppConstant.INVALID_EMAIL_ADDRESS}</div>
                        )}
                        {!this.state.invalidEmail && (
                            <div className="email-error" style={{ width: 500 }}>{AppConstant.EMAIL_ADDRESS_FORMAT}</div>
                        )}
                    </div>
                </Row>
                <Row>
                    <div className="confirmPopup__action mt-11">Enter Frequency</div>
                    <div className="entryField">
                        <select
                            name="frequency"
                            value={this.state.selectedFrequency}
                            onChange={e => this.handleChangefrequency(e.currentTarget.value)}
                            className="frequency-select-style"
                            style={{ paddingLeft: 5 }}
                        >
                            {frequencyData.map((frequency, i) => (
                                <option key={frequency} value={frequency}>
                                    {frequency}
                                </option>
                            ))}
                        </select>
                    </div>
                </Row>
                {this.state.selectedFrequency === 'Daily' && (
                    <Row>
                    <div className="confirmPopup__action mt-11">Select Report Type</div>
                    <div className="entryField">
                        <select
                            name="type"
                            value={this.state.selectedReportType}
                            onChange={e => this.handleSelectReportType(e.currentTarget.value)}
                            className="frequency-select-style"
                            style={{ paddingLeft: 5 }}
                        >
                            {reportType.map((item, i) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    </Row>
                )}
                <Row>
                    <div className="confirmPopup__action mt-11">Select Type</div>
                    <div className="entryField">
                        <select
                            name="type"
                            value={this.state.selectedType}
                            onChange={e => this.handleSelectType(e.currentTarget.value)}
                            className="frequency-select-style"
                            style={{ paddingLeft: 5 }}
                        >
                            {type.map((item, i) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </Row>
                <Row>
                    <div className="confirmPopup__action mt-11">Select Status</div>
                    <div className="searchSelectionFieldList"
                         style={{marginTop: 4}}>
                        <input
                            type="text"
                            autoComplete="off"
                            placeholder="Search status"
                            onChange={event => this.handleStatusSearch(event.currentTarget.value)}
                            className="search-field-input"
                        ></input>
                        <div className="fieldsList">
                            <div className="select-all-fieldsItem">
                                <input
                                    type="checkbox"
                                    style={{ width: 15, height: 15, margin: 5 }}
                                    onChange={checked => {
                                        this.onStatusSelection(checked, 'Select All');
                                    }}
                                    multiple={false}
                                    checked={this.state.selectedStatus.length === this.props.status.length}
                                ></input>
                                Select All
                                        </div>
                            {this.state.filteredStatus.map(item => {
                                return (
                                    <div className="fieldsItem">
                                        <input
                                            type="checkbox"
                                            style={{ width: 15, height: 15, margin: 5 }}
                                            onChange={checked => {
                                                this.onStatusSelection(checked, item);
                                            }}
                                            multiple={false}
                                            checked={this.state.selectedStatus.includes(item)}
                                        ></input>
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Row>
                {this.state.selectedFrequency === 'Daily' && (
                    <Row>
                        <div className="confirmPopup__action mt-11">Select Time</div>
                        <div className="entryField"
                             style={{marginTop: 4}}>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date: Date) => {
                                    this.setState({ startDate: date })
                                    this.handleDateChangeforDaily(date)
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                className="time-select-style"
                                dateFormat="HH:mm"
                                timeIntervals={30}
                            />
                        </div>
                    </Row>
                )}
                {this.state.selectedFrequency === 'Weekly' && (
                    <Row>
                        <div className="confirmPopup__action mt-11"style ={{marginRight: 15, marginLeft: 5}}>Select Week</div>
                        <Col>
                            <div className="weekEntryField" style={{ marginLeft: -30, paddingLeft: 5, paddingRight: 100 }}>
                                <select
                                    name="week"
                                    value={this.state.selectedWeek}
                                    onChange={e => this.handleChangeWeek(e.currentTarget.value)}
                                    className="week-select-style"
                                    style={{ marginLeft: -5, paddingLeft: 5 }}
                                >
                                    {weekData.map((week, i) => (
                                        <option key={week} value={week}>
                                            {week}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        
                        <Row>
                            <Col>
                                <div className="timeEntryField" style ={{marginLeft: -200,paddingLeft: 10, paddingRight: 140}}>
                                    <DatePicker
                                        selected={this.state.startDateTime}
                                        onChange={(date) => {
                                            this.setState({ startDateTime: date })
                                            this.handleTimeChangeForWeekly(date)
                                        }
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeFormat="HH:mm"
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Row>
                )}
                <Row>
                    <div className="confirmPopup__action mt-11">Select fields for reports</div>
                    <div className="searchSelectionFieldList"
                         style={{marginTop: 4}}>
                        <input
                            type="text"
                            autoComplete="off"
                            placeholder="Search fields"
                            onChange={event => this.handleFieldsSearch(event.currentTarget.value)}
                            className="search-field-input"
                        ></input>
                        <div className="fieldsList">
                            <div className="select-all-fieldsItem">
                                <input
                                    type="checkbox"
                                    style={{ width: 15, height: 15, margin: 5 }}
                                    onChange={checked => {
                                        this.onFieldSelection(checked, 'Select All');
                                    }}
                                    multiple={false}
                                    checked={this.state.selectedFields.length === this.props.fields.length}
                                ></input>
                                Select All
                                        </div>
                            {this.state.filteredFields.map(item => {
                                if(item === 'Comments') {
                                    if(this.state.selectedType !== 'Hourly')
                                return (
                                    <div className="fieldsItem">
                                        <input
                                            type="checkbox"
                                            style={{ width: 15, height: 15, margin: 5 }}
                                            onChange={checked => {
                                                this.onFieldSelection(checked, item);
                                            }}
                                            multiple={false}
                                            checked={this.state.selectedFields.includes(item)}
                                        ></input>
                                        {item}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="fieldsItem">
                                        <input
                                            type="checkbox"
                                            style={{ width: 15, height: 15, margin: 5 }}
                                            onChange={checked => {
                                                this.onFieldSelection(checked, item);
                                            }}
                                            multiple={false}
                                            checked={this.state.selectedFields.includes(item)}
                                        ></input>
                                        {item}
                                    </div>
                                );
                            }
                            })}
                        </div>
                    </div>
                </Row>
            </div>
            <div className='submit-button'>
                <Row>
                    <div className="confirm__action mt-10">
                        <CustomButton
                            type={CustomButtonType.Ok}
                            onClick={this.handleClick}
                            disabled={this.state.emailId === '' || this.state.selectedFields.length <= 0 || (this.state.selectedFrequency === 'Daily' && !this.state.startDate) || (this.state.selectedFrequency === 'Weekly' && !this.state.startDateTime)|| this.state.selectedType === '' || this.state.selectedStatus.length <= 0 || (this.state.selectedLeaseOperator.length == 0 && this.state.selectedConsignee.length == 0)}
                        >
                            Confirm
                            </CustomButton>
                        <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                            Cancel
                        </CustomButton>
                    </div>
                </Row>
            </div>
            </div>
        );
    }

    private handleDateChangeforDaily(date) {
        this.dailyTime = dateUtils.getUTCFormattedTime(date); 
    }

    private handleTimeChangeForWeekly(date) {
        this.weeklyTime = dateUtils.getUTCFormattedTime(date); 
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ emailId: value });
        this.setState({ invalidEmail: '' });
    };
    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    isEmailValid(email: string) {
        const isValid = CommonUtils.validateMultipleEmail(email);
        if (!isValid) {
            this.setState({ invalidEmail: 'invalid-email' });
            return false;
        } else {
            this.setState({ invalidEmail: '' });
            return true;
        }
    }

    handleBlur = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ message: value });
    };

    handleClick = () => {
        if (this.isEmailValid(this.state.emailId)) {
            let str = this.state.selectedType !== 'Hourly' ? [...this.state.selectedFields] : [...this.state.selectedFields. filter(e => e !== 'Comments' )];
            str[str.indexOf('Via')] = 'Road Via';
            str[str.indexOf('NetWeight')] = 'Net Weight';
            str[str.indexOf('OpenMeterReading')] = 'Open Meter Reading';
                str[str.indexOf('CloseMeterReading')] = 'Close Meter Reading'
                str[str.indexOf('Via')] = 'Road Via'
                str[str.indexOf('NetWeight')] = 'Net Weight'
                str[str.indexOf('OpenMeterReading')] = 'Open Meter Reading'
                str[str.indexOf('CloseMeterReading')] = 'Close Meter Reading'
                str[str.indexOf('AuthorizedBy')] = 'Authorized By'
                str[str.indexOf('MeterNumber')] = 'Meter Number'
                str[str.indexOf('MeterFactor')] = 'Meter Factor'
                str[str.indexOf('CreatedBy')] = 'Created By'
                str[str.indexOf('CompletedDate')] = 'Completed Date'
                str[str.indexOf('CompletedTime')] = 'Completed Time'
                str[str.indexOf('TicketTime')] = 'Ticket Time'
                str[str.indexOf('LeaseOperatorName')] = 'Carrier Company'
                str[str.indexOf('UomID')] = 'Units'
                str[str.indexOf('RunTickeScaleTicketNumber')] = 'Run Ticket/Scale Ticket'
                str[str.indexOf('StartDateTime')] = 'Start Time'
                str[str.indexOf('CompletedDateTime')] = 'Completed Time'
                str[str.indexOf('TicketDate')] = 'Ticket Time'
                str[str.indexOf('RoadBan')] = 'Road Ban'
                str[str.indexOf('RelatedVolumeTicket')] = 'Related Volume Ticket'
                str[str.indexOf('WorkingTime')] = 'Working Time'
                str[str.indexOf('ApproverComment')] = 'Approver Comment'
                str[str.indexOf('DriverName')] = 'Driver Name'
                str[str.indexOf('TicketType')] = 'Type'
                str[str.indexOf('TicketStatus')] = 'Status'
                str[str.indexOf('TicketNumber')] = 'Ticket Number'
                str[str.indexOf('Origin')] = 'Consignor'
                str[str.indexOf('Destination')] = 'Consignee'
                str[str.indexOf('VolumeDelivered')] = 'Volume Delivered'
                str[str.indexOf('WorkingTimeReason')] = 'Working Time Reason'
                str[str.indexOf('TicketCreationDate')] = 'Ticket Creation Date'
                str[str.indexOf('ShipperTicketBOL')] = 'Shipper Ticket/BOL'
                str[str.indexOf('LastPMTStatus')] = 'Last PMT Status'
                str[str.indexOf('UpdatedBy')] = 'Updated By'
                str[str.indexOf('WaitingTime')] = 'Waiting Time'
                str[str.indexOf('WaitingTimeReason')] = 'Waiting Time Reason'
                str[str.indexOf('UpdatedDate')] = 'Updated Date'
                str[str.indexOf('ConsignorName')] = 'Consignor Name'
                str[str.indexOf('ConsignorAddress')] = 'Consignor Address'
                str[str.indexOf('PowerUnitIdentifier')] = 'Power Unit Identifier'
                str[str.indexOf('StartDate')] = 'Start Date'
                str[str.indexOf('EndDate')] = 'End Date'
                str[str.indexOf('EstimatedVolume')] = 'Estimated Volume'
                str[str.indexOf('ConsignorERPPhoneNumber')] = 'Consignor ERP PhoneNumber'
                str[str.indexOf('LoadDate')] = 'Load Date'
                str[str.indexOf('LoadTime')] = 'Load Time'
                str[str.indexOf('GrossWeight')] = 'Gross Weight'
                str[str.indexOf('TareWeight')] = 'Tare Weight'
            let data: any;
            const selectedStatus = this.state.selectedStatus.toString().replace("Cancelled","Canceled");            
            if (this.state.selectedFrequency === 'Daily') {
                data = {
                    EMailAddress: this.state.emailId,
                    Frequency: this.state.selectedFrequency,
                    ID: this.emailConfigurationData[0]? this.emailConfigurationData[0].ID : 0,
                    FieldForReport: this.state.selectedType !== 'Hourly' ? this.state.selectedFields.toString() : this.state.selectedFields. filter(e => e !== 'Comments' ).toString(),
                    FieldForExcel: str.toString(),
                    ScheduledTime: this.dailyTime,
                    ConsignorName: this.state.selectedLeaseOperator.map((item: any) => item.value).toString(),
                    ConsigneeName: this.state.selectedConsignee.map((item: any) => item.value).toString(),
                    TicketType: this.state.selectedType,
                    Status: selectedStatus,
                    IsSingleDay: this.state.selectedReportType === 'SingleDay' ? true : false
                };
            } else if (this.state.selectedFrequency === 'Weekly') {
                data = {
                    EMailAddress: this.state.emailId,
                    Frequency: this.state.selectedFrequency,
                    FieldForReport: this.state.selectedType !== 'Hourly' ? this.state.selectedFields.toString() : this.state.selectedFields. filter(e => e !== 'Comments' ).toString(),
                    ID: this.emailConfigurationData[0]? this.emailConfigurationData[0].ID : 0,
                    FieldForExcel: str.toString(),
                    WeekDay: this.state.selectedWeek ? this.state.selectedWeek : 'Sunday',
                    ScheduledTime: this.weeklyTime,
                    ConsignorName: this.state.selectedLeaseOperator.map((item: any) => item.value).toString(),
                    ConsigneeName: this.state.selectedConsignee.map((item: any) => item.value).toString(),
                    TicketType: this.state.selectedType,
                    Status: selectedStatus
                };
            } else if (this.state.selectedFrequency === 'Monthly') {
                data = {
                    EMailAddress: this.state.emailId,
                    Frequency: this.state.selectedFrequency,
                    ID: this.emailConfigurationData[0]? this.emailConfigurationData[0].ID : 0,
                    FieldForReport: this.state.selectedType !== 'Hourly' ? this.state.selectedFields.toString() : this.state.selectedFields. filter(e => e !== 'Comments' ).toString(),
                    FieldForExcel: str.toString(),
                    ConsignorName: this.state.selectedLeaseOperator.map((item: any) => item.value).toString(),
                    ConsigneeName: this.state.selectedConsignee.map((item: any) => item.value).toString(),
                    ScheduledTime: null,
                    ScheduledDate: null,
                    TicketType: this.state.selectedType,
                    Status: selectedStatus
                };
            } else {
                data = {
                    EMailAddress: this.state.emailId,
                    Frequency: this.state.selectedFrequency,
                    ID: this.emailConfigurationData[0]? this.emailConfigurationData[0].ID : 0,
                    FieldForReport: this.state.selectedType !== 'Hourly' ? this.state.selectedFields.toString() : this.state.selectedFields. filter(e => e !== 'Comments' ).toString(),
                    FieldForExcel: str.toString(),
                    ScheduledTime: this.state.startDate,
                    ConsignorName: this.state.selectedLeaseOperator.map((item: any) => item.value).toString(),
                    ConsigneeName: this.state.selectedConsignee.map((item: any) => item.value).toString(),
                    TicketType: this.state.selectedType,
                    Status: selectedStatus
                };
            }
            this.props.onSuccessClick(data);
            this.props.onClose();
        }
    };

    handleChangefrequency = value => {
        this.setState({
            selectedFrequency: value
        });
    };

    handleSelectType = value => {
        this.setState({
            selectedType: value
        });
    };

    handleSelectReportType = value => {
        this.setState({
            selectedReportType: value
        });
    };

    handleSelectStatus = value => {
        const selectedStatus = [] as any;
        if (value) {
            value.map((item) => {
                selectedStatus.push(item.label)
                if(selectedStatus.includes('All') && value.length>1){
                    value.forEach((ele, index) => {
                        if (ele.value == 'All') {
                        selectedStatus.push(ele.value)
                    }
                    else {
                        selectedStatus.sort().splice(0)
                    }
                    });
                }
            })
        }
        this.setState({
            selectedStatus: selectedStatus
        });
    };

    handleChangeWeek = value => {
        this.setState({
            selectedWeek: value
        });
    };

    handleConsignorChange = value => {
        this.setState({
            selectedLeaseOperator: value != null ? value : []
        });
    };

    handleConsigneeChange = value => {
        this.setState({
            selectedConsignee: value != null ? value : []
        });
    };

    handleFieldsSearch = value => {
        const { filteredFields } = this.state;
        console.log(value);
        const responseObject = this.props.fields;
        if (value && value !== '') {
            let list = [] as any;
            list =
                value !== ''
                    ? this.filterObjectByValue(responseObject, option =>
                        option.toLowerCase().includes(value.toLowerCase())
                    )
                    : responseObject;
            const newData = Object.keys(list).map(i => list[i]);
            this.setState({
                filteredFields: newData
            });
        } else {
            this.setState({
                filteredFields: this.props.fields
            });
        }
    };

    handleStatusSearch = value => {
        const { filteredStatus } = this.state;
        console.log(value);
        const responseObject = this.props.status;
        if (value && value !== '') {
            let list = [] as any;
            list =
                value !== ''
                    ? this.filterObjectByValue(responseObject, option =>
                        option.toLowerCase().includes(value.toLowerCase())
                    )
                    : responseObject;
            const newData = Object.keys(list).map(i => list[i]);
            this.setState({
                filteredStatus: newData
            });
        } else {
            this.setState({
                filteredStatus: this.props.status
            });
        }
    };

    filterObjectByValue = (obj, predicate) => {
        return Object.keys(obj)
            .filter(key => predicate(obj[key]))
            .reduce((res, key) => ((res[key] = obj[key]), res), {});
    };

    onFieldSelection = (checked, item) => {
        const selectedItems = this.state.selectedFields;
        if (checked) {
            if (item !== 'Select All') {
                if (!selectedItems.includes(item)) {
                    selectedItems.push(item);
                    this.setState({
                        selectedFields: selectedItems
                    });
                } else {
                    const selectedItemsAfterDeletion = selectedItems.filter(field => field !== item);
                    this.setState({
                        selectedFields: selectedItemsAfterDeletion
                    });
                }
            } else {
                if (selectedItems.length === this.props.fields.length) {
                    this.setState({
                        selectedFields: []
                    });

                } else {
                    this.setState({
                        selectedFields: this.props.fields
                    });
                }
            }
        }
    };

    onStatusSelection = (checked, item) => {
        const selectedItems = this.state.selectedStatus;
        if (checked) {
            if (item !== 'Select All') {
                if (!selectedItems.includes(item)) {
                    selectedItems.push(item);
                    this.setState({
                        selectedStatus: selectedItems
                    });
                } else {
                    const selectedItemsAfterDeletion = selectedItems.filter(status => status !== item);
                    this.setState({
                        selectedStatus: selectedItemsAfterDeletion
                    });
                }
            } else {
                if (selectedItems.length === this.props.status.length) {
                    this.setState({
                        selectedStatus: []
                    });

                } else {
                    this.setState({
                        selectedStatus: this.props.status
                    });
                }
            }
        }
    };
}
