import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { RejectedLoadDataStore } from '../stores/rejected-load-data-store';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { GridOptions } from 'ag-grid-community';
import { dataStore } from 'features/common/stores';
import { rejectedLoadStore } from '../stores';
import { ReportsButton, TitlePDF } from '../domains/enum';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AppConstant } from 'app_constant';
import { localStorageService } from 'shared/services/local-storage-service';
import { agGridService } from 'features/common/services/ag-grid-service';
import { FilterChangedEvent } from 'ag-grid-community';
import _ from 'lodash';
import Select from 'react-select';
import { RejectedLoadsStatus } from '../reports_constants';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface RejectedLoadProps {
    rejectedLoadStore: RejectedLoadDataStore;
}
@observer
export class RejectedLoadsComponent extends Component<RejectedLoadProps> {
    rejectedLoadData: any[] = [];
    selectedOption: any = [];

    constructor(props) {
        super(props);
        dataStore.setModuleName('RejectedLoads');
        this.state = {
            count: 0,
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowData: null,
            rowfield: {}
        };
    }

    componentDidUpdate() {
        const filters: any = localStorageService.get('RejectedLoads_filter');
        if (
            filters &&
            !_.isEqual(JSON.stringify(filters), JSON.stringify(agGridService.getAllFilters())) &&
            Object.keys(filters).length > 0
        ) {
            agGridService.setAllFilters(filters);
        }
    }

    private exportCsv = () => {
        const csvData: any = this.props.rejectedLoadStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        for (let i = 0; i < data.length; i++) {
            data[i]['TicketDate'] =
                data[i]['TicketDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
        }
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.RejectedLoads);
    };
    data() {
        console.log(this.rejectedLoadData);
        return this.rejectedLoadData;
    }
    async componentDidMount() {
        const { rejectedLoadStore } = this.props;
        rejectedLoadStore.init();
    }

    render() {
        const { rejectedLoadStore } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                <ClearButtonComponent disabled={this.props.rejectedLoadStore.isDisabled()} handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <ReactDayPickerInput
                                _dataStore={this.props.rejectedLoadStore}
                                showFirstDay={true}
                                // showOneDay={true}
                            ></ReactDayPickerInput>
                            <div className='form-inline padding-bottom'>
                                Status: {' '}
                                <div className = 'reportmanagement__type'>
                                <Select
                                    value = {rejectedLoadStore.selectedStatus}
                                    closeMenuOnSelect = {false}
                                    closeMenuOnScroll = {true}
                                    options = {RejectedLoadsStatus}
                                    onChange = {this.handleStatusChange}
                                    isMulti = {true}
                                />
                                </div>
                            </div>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    handleStatusChange = option =>{
        const { rejectedLoadStore } = this.props;
        let status: any = [];
        if(option && option.length > 0) {
            status = Array.from(new Set(option.map((item: any) => item.value)));
            if( status.includes('all') && option.length > 1 ) {
                option.forEach(( ele, index ) => {
                    if (ele.value == 'all') option.splice( index, 1 );
                });
            }
            rejectedLoadStore.selectedStatus = option;
        } else {
            rejectedLoadStore.selectedStatus = [{ value: 'all', label: 'All' }];
            status  = Array.from(new Set(RejectedLoadsStatus.map((item: any) => item.value)));
        }
        rejectedLoadStore.statusFiltered(status);
        localStorageService.set('RejectedLoads_Dropdown', [{'TicketStatus': rejectedLoadStore.selectedStatus}])
    }


    handleRefresh = () => {
        const { rejectedLoadStore } = this.props;
            rejectedLoadStore.isRefreshed = true;
            if(rejectedLoadStore.start && rejectedLoadStore.end){
            rejectedLoadStore.getRangeResult(rejectedLoadStore.start,rejectedLoadStore.end);
        }
            else {
            rejectedLoadStore.loadViewModel();
            }
    }

    private getGridConfig(): GridOptions {
        const {
            rejectedLoadStore: { DuplicateList, agGridService }
        } = this.props;
        const rowData = DuplicateList;
        const columnDefs = rejectedLoadStore.getColDef();
        const onFilterChanged = this.handleFilterChange
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }

    handleFilterChange = (event: FilterChangedEvent): void => {
        localStorageService.set('RejectedLoads_filter', agGridService.getAllFilters());
        if(this.props.rejectedLoadStore.clearSelection == true){
            this.props.rejectedLoadStore.selectedStatus = [{ value: 'all', label: 'All' }];
            this.props.rejectedLoadStore.rejectedLoad = this.props.rejectedLoadStore.backupRejectedLoad;
            this.props.rejectedLoadStore.clearSelection = false;
        }
    };
}
