import { LocationFieldName, LocationHeaderName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';
import { CheckBoxCellRenderer } from 'features/data_management/components/cell-renderers/location-checkbox-cell-renderer';
import { locationStore } from 'features/data_management/stores';
import { RestrictedFlagCellRenderer } from './restricted-flag-cell-renderer';
import { CommonUtils } from 'shared/services/common-utils';
const commaFormat = (number) => {
    if(number != null) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const locationColDef: ColDef[] = [
    { headerName: LocationHeaderName.ID, field: LocationFieldName.ID, hide: true, sort : 'desc' },
    {
        headerName: LocationHeaderName.DELETE,
        field: LocationHeaderName.DELETE,
        width: 75,
        cellRendererFramework: CheckBoxCellRenderer
    },
    {
        headerName: LocationHeaderName.CONSIGNOR,
        field: LocationFieldName.CONSIGNOR,
        width: 100,
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNORNAME,
        field: LocationFieldName.CONSIGNORNAME,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNORNAME] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNORNAME];
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNORPHONE,
        field: LocationFieldName.CONSIGNORPHONE,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNORPHONE] === null) {
                return '';
            } else {
                if (params.data[LocationFieldName.CONSIGNORPHONE]) {
                    return params.data[LocationFieldName.CONSIGNORPHONE].toString().replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
                } else return ''
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNORADDRESS,
        field: LocationFieldName.CONSIGNORADDRESS,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNORADDRESS] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNORADDRESS];
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNEE,
        field: LocationFieldName.CONSIGNEE,
        colId: LocationFieldName.CONSIGNEE,
        width: 100,
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNORRA,
        field: LocationFieldName.CONSIGNORRA,
        colId: LocationFieldName.CONSIGNORRA,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNORRA] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNORRA];
            }
        }
    },
    {
        headerName: LocationHeaderName.CONSIGNEERA,
        field: LocationFieldName.CONSIGNEERA,
        colId: LocationFieldName.CONSIGNEERA,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.CONSIGNEERA] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CONSIGNEERA];
            }
        }
    },
    {
        headerName: LocationHeaderName.SHORTNAME,
        field: LocationFieldName.SHORTNAME,
        colId: LocationFieldName.SHORTNAME,
        width: 120,
        editable: () => (dataStore.checkOperationAccess('LoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('LoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.SHORTNAME] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.SHORTNAME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
    },
    {
        headerName: LocationHeaderName.REGION,
        field: LocationFieldName.REGION,
        width: 75,
        valueGetter: params => {
            if (params.data[LocationFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.REGION];
            }
        }
    },
    {
        headerName: LocationHeaderName.CURRENTLOADSIZE,
        field: LocationFieldName.CURRENTLOADSIZE,
        colId: LocationFieldName.CURRENTLOADSIZE,
        width: 120,
        cellClass: 'work-wait',
        valueGetter: params => {
            if (params.data[LocationFieldName.CURRENTLOADSIZE] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.CURRENTLOADSIZE];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
    },
    {
        headerName: LocationHeaderName.PLANNEDLOADSIZE,
        field: LocationFieldName.PLANNEDLOADSIZE,
        colId: LocationFieldName.PLANNEDLOADSIZE,
        width: 120,
        editable: () => (dataStore.checkOperationAccess('LoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('LoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.PLANNEDLOADSIZE] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.PLANNEDLOADSIZE];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
    },
    {
        headerName: LocationHeaderName.RESTRICTEDLOADSIZE,
        field: LocationFieldName.RESTRICTEDLOADSIZE,
        colId: LocationFieldName.RESTRICTEDLOADSIZE,
        width: 120,
        editable: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('RestrictedLoadSize') ? ['cell-editable','work-wait'] : 'work-wait'),
        valueGetter: params => {
            if (params.data[LocationFieldName.RESTRICTEDLOADSIZE] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.RESTRICTEDLOADSIZE];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
    },
    {
        headerName: LocationHeaderName.RESTRICTEDFLAG,
        field: LocationFieldName.RESTRICTEDFLAG,
        width: 105,
        cellRendererFramework: RestrictedFlagCellRenderer,
        valueGetter: params => {
            if (params.data[LocationFieldName.RESTRICTEDFLAG] === null) {
                return false;
            } else {
                return params.data[LocationFieldName.RESTRICTEDFLAG];
            }
        }
    },
    {
        headerName: LocationHeaderName.MODIFIEDBY,
        field: LocationFieldName.MODIFIEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[LocationFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[LocationFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: LocationHeaderName.MODIFIEDDATE,
        field: LocationFieldName.MODIFIEDDATE,
        colId: LocationFieldName.MODIFIEDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[LocationFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[LocationFieldName.MODIFIEDDATE]);
            }
        }
    }
];
