import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (value) => void;
}
export class ApportionmentApplyPopUp extends React.Component<ConfirmProps> {
    state = {
        value: '',
        invalidEmail: ''
    };
    public render(): React.ReactNode {
        const { onClose } = this.props;
        return (
            <div className="confirm">
                <div className="confirm__action mt-10">Please Note: Apportionment can only be applied once and apportionment values will be locked for that month and cannot be reversed.</div>
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick}>
                    Apply Apportionment
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleClick = () => {
            this.props.onSuccessClick(this.state.value);
            this.props.onClose();
    };
}
