import React, { PureComponent } from 'react';
import './_register-container.scss';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { SignUpHeaderComponent } from './sign-up-header';
import { SignUpFormComponent } from './sign-up-form-component';
import { accountStore } from '../stores';
import { CommonUtils } from 'shared/services/common-utils';
import { AppConstant } from 'app_constant';
import { NON_PLAINS, SIGNUP_ERROR, DUPLICATE_ERROR, PLAINS, DISPATCHER, DRIVER } from '../account_constant';
import * as Yup from 'yup';
import { SignUp } from '../models/signup';
import { EmailVerificationPopUpComponent } from './email-verification-popup-component';
import { uiService } from 'shared/services/ui-service';
import queryString from 'query-string';
import { dataStore } from 'features/common/stores';

interface SignUpContainerState {
    showPopUp: boolean;
    userEmail: string;
}

@inject('accountStore')
@observer
export class SignUpContainerComponent extends PureComponent<RouteComponentProps, SignUpContainerState> {
    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
            userEmail: ''
        }
        if (accountStore.isLoggedIn && !dataStore.checkOperationAccessWithModule('UserSignup', 'UserApproval')) {
            this.props.history.push('/');
        }
    }

    getUserEmail = () => {
        const urlQueryStrings = this.props.location.search;
        if (urlQueryStrings) {
            const email = queryString.parse(urlQueryStrings).email as string;
            this.setState({ userEmail: email });
        }
    }

    componentDidMount() {
        this.getUserEmail();
    }

    initValues = {
        LoginName: '',
        FirstName: '',
        LastName: '',
        CellPhoneNo: '',
        CarrierName: '',
        UserType: '',
        UserRole: '',
        Facility: [],
        CarrierCompany: [],
        IsPlains: false,
        IsDriver: false,
        IsLeaseOperator: false,
        IsDispatcher: false,
        PSACarrier: '',
    }

    phoneRegEx = CommonUtils.getCellPhoneRegex();
    validationSchema = Yup.object().shape({
        LoginName: Yup.string().required(AppConstant.EMAIL_REQUIRED).email(),
        FirstName: Yup.string().min(1, AppConstant.MIN_LENGTH_REQUIRED)
            .max(50, AppConstant.MAX_LENGTH_REQUIRED).required(AppConstant.FIRST_NAME_REQUIRED),
        LastName: Yup.string().min(1, AppConstant.MIN_LENGTH_REQUIRED)
            .max(50, AppConstant.MAX_LENGTH_REQUIRED).required(AppConstant.LAST_NAME_REQUIRED),
        CellPhoneNo: Yup.string().matches(this.phoneRegEx, { message: AppConstant.INVALID_PHONE_NUMBER })
            .required(AppConstant.PHONE_NUMBER_REQUIRED),
        CarrierName: Yup.string().when('UserType', {
            is:PLAINS,
            then: Yup.string().required(AppConstant.CARRIER_NAME_REQUIRED)
        }).when('UserType', {
            is:  NON_PLAINS,
            then: Yup.string().when('UserRole', {
                is: DISPATCHER,
                then: Yup.string().required(AppConstant.CARRIER_NAME_REQUIRED)
            })
        }),
        UserType: Yup.string().required(AppConstant.USER_TYPE_REQUIRED),
        UserRole: Yup.string().required(AppConstant.USER_ROLE_REQUIRED),
        Facility: Yup.array().when('UserType', {
            is: NON_PLAINS,
            then: Yup.array()
                .min(1, AppConstant.FACILITY_REQUIRED)
                .of(
                    Yup.object().shape({
                        ID: Yup.string().required(),
                        Name: Yup.string().required()
                    })
                )
        }),
        PSACarrier: Yup.string().when('UserType', {
            is:  NON_PLAINS,
            then: Yup.string().when('UserRole', {
                is: DRIVER,
                then: Yup.string().required(AppConstant.PSACARRIER_REQUIRED)
            })
        }),
    });

    handleSubmit = (signUp: SignUp): void => {
        const { loaderService: { showLoader, hideLoader }, toastService: { error } } = uiService;
        showLoader();
        accountStore.signup(signUp)
            .then(() => {
                this.setState({ showPopUp: true });
                hideLoader();
            }).catch(function (err) {
                const { response } = err;
                if (response) {
                    const { data: { Message } } = response;
                    if (Message.includes("already exists"))
                        error(DUPLICATE_ERROR)
                    else {
                        error(SIGNUP_ERROR);
                    }
                    hideLoader();
                }
            });
    };

    handlePopUpClose = (): void => {
        this.setState({ showPopUp: false });
        this.props.history.push('/');
    };

    render(): React.ReactNode {
        const { showPopUp, userEmail } = this.state;
        this.initValues.LoginName = userEmail;
        return (
            <div className="register-container">
                <div className="register-container__content">
                    <SignUpHeaderComponent />
                    <SignUpFormComponent initialValues={this.initValues}
                        validationSchema={this.validationSchema}
                        phoneRegex={this.phoneRegEx}
                        handleSubmit={this.handleSubmit} />
                </div>
                <EmailVerificationPopUpComponent
                    onHide={this.handlePopUpClose}
                    show={showPopUp}
                ></EmailVerificationPopUpComponent>
            </div>
        );
    }
}
