import { action, computed, observable, toJS } from 'mobx';
import Catch from 'shared/decorators/catch-decorator';
import Loader from 'shared/decorators/loader-decorator';
import { errorHandler } from 'shared/handlers/error-handler';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { UiService, uiService } from 'shared/services/ui-service';
import { pastDueReport } from '../domains/pastDueCerifification-report-col-def';
import { ReportMessage, PastDueCertificationReportFieldName } from '../domains/enum';
import { accountStore } from 'features/account/stores';
import { dataStore } from 'features/common/stores';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import moment from 'moment';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { AccountStore } from 'features/account/stores/account-store';
import { PastDueCertificationReportDataService } from '../services/pastDueCertificationReport-data-service';
import { MaintenanceDropdown, OperationsDropdown, OverrideDropdown, PastDueDateResponse } from '../domains/model';
import { CCUserRoles } from '../operations_constants';
import { localStorageService } from 'shared/services/local-storage-service';
import { CommonUtils } from 'shared/services/common-utils';

export class PastDueCertificationReportDataStore {
    @observable pastDueReports: PastDueDateResponse[] = [];
    backupPastDueReport: PastDueDateResponse[] = [];
    operationsReviewDropdown: any[] = [];
    maintenanceReviewDropdown: any[] = [];
    overrideFlag: any[] = [];
    overrideDropdown: any[] = [];
    @observable cellValueChangeMap = {};
    @observable filters: any = {};
    userID = 0;
    userName = '';
    isApproverPopUpVisible = false;
    isRefreshed: boolean = false;
    approvedCommentTicketModel: any;
    approvedColumn: any;
    selectedRow: any = [];
    @observable isClearanceCommentPopUpVisible = false;
    // @observable volumeSum:any ={
    //     volumeDelivered : 0,
    //     actVolume : 0
    // }
    constructor(
        public pastDueCertificationReportDataService: PastDueCertificationReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}
    init(): void {
    //    this.pastDueModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }
    @computed
    get OperatorList(): PastDueDateResponse[] {
        return toJS(this.pastDueReports);
    }
    @Loader
    @Catch(() => errorHandler(ReportMessage.PASTDUE_ERROR_MESSAGE))
    async pastDueModel(): Promise<void> {
        const {
            pastDueData
        } = await this.pastDueCertificationReportDataService.getPastDueModel();
        const pastDue: PastDueDateResponse[] = pastDueData['Data'].filter(a => !a.IsDeleted && !a.ClearanceFlag);
        if (pastDue) {
            for (const key in pastDue) { 
                pastDue[key].Overrideflag = 
                pastDue[key].Overrideflag == true ? 'Yes' : 'No'
                console.log(pastDue[key].Overrideflag)
                if(pastDue[key].PhoneNumber != null)
                pastDue[key].PhoneNumber = pastDue[key].PhoneNumber.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
            }
        }
        this.setPastDueList(pastDue);
        this.backupPastDueReport = pastDue;
        this.addValuesInCellDropdowns();
        }
    getColDef() {
        return pastDueReport;
    }
    @action
    setBackupPastDueReport(pastDueReports: PastDueDateResponse[]): void {
        this.backupPastDueReport = pastDueReports;
    }
    @action
    setPastDueList(pastDueReports: PastDueDateResponse[]): void {
        this.pastDueReports = pastDueReports;
    }
    @action
    resetPastDueList(): void {
        this.setPastDueList(this.backupPastDueReport);
        this.cellValueChangeMap = {};
    }
    updateInspectionHistoryComment(item: PastDueDateResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.TrailerID, key, item[key], value);
        if (popup && item[key] != null) item[key] = item[key] + '\n' + value;
        else item[key] = value;
        this.updateRow(item);
    }
    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }
    updatePastDueReportRequest(updatedRows: PastDueDateResponse[]): PastDueDateResponse[] {
        let requestList: PastDueDateResponse[] = [];
        updatedRows.forEach(item => {
            const { ID,TrailerID,TrailerNumber,TicketNumber,DriverID,DriverName,
                PhoneNumber,CarrierCompanyID,CompanyName,CertificateOverdue,
                CertificateOverdueDate,OpsReviewDate, OpsReview, OpsReviewComments, MaintenanceReview,MaintenanceReviewDate,
                 MaintenanceComments,Overrideflag,OverrideBy,OverrideDate,OverrideByName,OpsReviewBy,
                 OpsReviewByName, MaintenanceReviewBy,MaintenanceReviewByName, 
                ReasontoOverride, ClearanceFlag, ClearanceComments } = item;
                
                if(item.Overrideflag=="No"){
                    item.Overrideflag= false;
                }
                if(item.Overrideflag=="Yes"){
                    item.Overrideflag= true;
                }
                if (
                        dataStore.checkOperationAccess('OverrideEdit') &&
                        (this.cellValueChangeMap[item.TrailerID]['Overrideflag'] ||
                            this.cellValueChangeMap[item.TrailerID]['ReasontoOverride'])
                    ) {
                        item.OverrideBy= this.userID;
                        item.OverrideByName= this.userName;
                        item.OverrideDate = moment()
                        .toDate()
                        .toISOString();;    
                    }
                if (
                    dataStore.checkOperationAccess('OperationsReviewEdit') &&
                    (this.cellValueChangeMap[item.TrailerID]['OpsReviewComments'] ||
                        this.cellValueChangeMap[item.TrailerID]['OpsReview'])
                ) {
                    item.OpsReviewBy=this.userID;
                    item.OpsReviewByName=this.userName;
                    item.OpsReviewDate = moment()
                    .toDate()
                    .toISOString();
                  }
                if (
                    dataStore.checkOperationAccess('MaintenanceReviewEdit') &&
                    (this.cellValueChangeMap[item.TrailerID]['MaintenanceComments'] ||
                        this.cellValueChangeMap[item.TrailerID]['MaintenanceReview'])
                ) {
                    item.MaintenanceReviewBy=this.userID;
                    item.MaintenanceReviewByName=this.userName;
                    item.MaintenanceReviewDate = moment()
                    .toDate()
                    .toISOString();
                }
                if (
                    dataStore.checkOperationAccess('ClearanceCommentEdit') &&
                    (this.cellValueChangeMap[item.TrailerID]['ClearanceComments'] ||
                        this.cellValueChangeMap[item.TrailerID]['ClearanceFlag'])
                ) {
                    item.ClearanceBy=this.userID;
                    item.ClearanceDate = moment()
                    .toDate()
                    .toISOString();
                }
                requestList = [
                    ...requestList,
                    {
                        ID: ID,
                        TrailerID: TrailerID,
                        TrailerNumber: TrailerNumber,
                        TicketNumber: TicketNumber,
                        DriverID: DriverID,
                        DriverName: DriverName,
                        PhoneNumber: PhoneNumber,
                        CarrierCompanyID: CarrierCompanyID,
                        CompanyName: CompanyName,
                        CertificateOverdue: CertificateOverdue,
                        CertificateOverdueDate: CertificateOverdueDate,
                        OpsReview: OpsReview,
                        OpsReviewComments: OpsReviewComments,
                        OpsReviewDate: item.OpsReviewDate,
                        MaintenanceReview: MaintenanceReview,
                        MaintenanceComments: MaintenanceComments,
                        MaintenanceReviewDate: item.MaintenanceReviewDate,
                        Overrideflag:item.Overrideflag,
                        OverrideBy: item.OverrideBy,
                        OverrideDate: item.OverrideDate,
                        ReasontoOverride: ReasontoOverride,
                        OverrideByName: item.OverrideByName,
                        OpsReviewBy: item.OpsReviewBy,
                        OpsReviewByName: item.OpsReviewByName,
                        MaintenanceReviewBy: item.MaintenanceReviewBy,
                        MaintenanceReviewByName: item.MaintenanceReviewByName,
                        ClearanceDate: item.ClearanceDate,
                        ClearanceBy: item.ClearanceBy,
                        ClearanceFlag: ClearanceFlag,
                        ClearanceComments: ClearanceComments
                    }
                ];
        });
        return updatedRows;
    }
    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };
    isDisabled = (): boolean => {
        if (this.pastDueReports.length == 0) {
            return true;
        } else {
            return false;
        }
    };
    @action
    showTicketApproverPopUp() {
        this.isApproverPopUpVisible = true;
    }
    @action
    hideTicketApproverPopUp() {
        this.isApproverPopUpVisible = false;
        this.isClearanceCommentPopUpVisible = false;
    }
    @action
    showClearanceCommentPopup() {
        this.isClearanceCommentPopUpVisible = true;
    }
    getAllFilters = () => {
        this.filters = this.agGridService.getAllFilters();
    };
    @action
    reset(): void {
        this.setPastDueList([]);
    }
    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.FAILED_SUBMIT))
    async createUpdatePastOverdueforTrailers(): Promise<void> {
        // this.getAllFilters();
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.pastDueReports.filter(a => 
            this.getUpdatedRowIDs().includes(a.TrailerID.toString()));
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updatePastDueReportRequest(updatedRowIDs);
        let errorInSave = false;
        const response = await this.pastDueCertificationReportDataService.createUpdatePastOverdueforTrailers(requestBody);
        this.getPastDueReports();
        // this.pastDueModel();
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.pastDueReports.length; i++) {
                    const updatedtickethistory = this.pastDueReports[i];
                    if (updatedtickethistory.TrailerID === parseInt(errorMessage.id)) {
                        const backupTicketInspectionIndex = this.backupPastDueReport.findIndex(
                            a => a.TrailerID === parseInt(errorMessage.id)
                        );
                        this.mapEditableColumns(
                            updatedtickethistory,
                            this.backupPastDueReport[backupTicketInspectionIndex]
                        );
                        break;
                    }
                }
            });
        } else {
            this.uiService.toastService.success(ReportMessage.SAVE);
        }
        this.cellValueChangeMap = {};
    }
    @action
    updateRow = (selectedRowData: PastDueDateResponse): void => {
        this.pastDueReports.forEach(pastDueReport => {             
            if (pastDueReport.TicketNumber === selectedRowData.TicketNumber &&
                pastDueReport.DriverID === selectedRowData.DriverID &&
                pastDueReport.TrailerID === selectedRowData.TrailerID &&
                pastDueReport.CompanyName === selectedRowData.CompanyName
                ) {
                if (!_.isEqual(pastDueReport, selectedRowData)) {
                    if (
                        pastDueReport.MaintenanceReview !== selectedRowData.MaintenanceReview &&
                        selectedRowData.MaintenanceReview == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'MaintenanceComments');
                    }
                    if (
                        pastDueReport.OpsReview != selectedRowData.OpsReview &&
                        selectedRowData.OpsReview == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'OpsReviewComments');
                    }
                    if (
                        pastDueReport.Overrideflag != selectedRowData.Overrideflag &&
                        selectedRowData.Overrideflag == "Yes" 
                    ) {
                        this.triggerModal(selectedRowData, 'ReasontoOverride');
                    }
                    this.mapEditableColumns(pastDueReport, selectedRowData);
                    this.agGridService.refreshGrid();
                }
            }
        });
    };
    mapEditableColumns(currentItem: PastDueDateResponse, updatedItem: PastDueDateResponse) {
       if(currentItem){
        currentItem.MaintenanceReview = updatedItem.MaintenanceReview;
        currentItem.MaintenanceComments = updatedItem.MaintenanceComments;
        currentItem.OpsReview = updatedItem.OpsReview;
        currentItem.OpsReviewComments = updatedItem.OpsReviewComments;
        currentItem.MaintenanceReviewDate = updatedItem.MaintenanceReviewDate;
        currentItem.OpsReviewDate = updatedItem.OpsReviewDate;
        currentItem.Overrideflag = updatedItem.Overrideflag;
        currentItem.ReasontoOverride = updatedItem.ReasontoOverride;
        currentItem.ClearanceFlag = updatedItem.ClearanceFlag;
        currentItem.ClearanceComments = updatedItem.ClearanceComments;
       }
    }
    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        console.log("Inside Setvalue change map")
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue)) {
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        }
         else {
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
        }
    }
    triggerModal(item, col) {
        this.approvedCommentTicketModel = item;
        this.approvedColumn = col;
        this.showTicketApproverPopUp();
    }
    updateComment(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateInspectionHistoryComment(
            this.approvedCommentTicketModel,
            approverComment,
            this.approvedColumn,
            true
        );
    }
    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(
            this.operationsReviewDropdown,
            PastDueCertificationReportFieldName.OPERATIONSREVIEW,
            'label'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.maintenanceReviewDropdown,
            PastDueCertificationReportFieldName.MAINTENANCEREVIEW,
            'label'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.overrideDropdown,
            PastDueCertificationReportFieldName.OVERRIDEFLAG,
            'label'
        );
        this.accessCheck(PastDueCertificationReportFieldName.OVERRIDEFLAG, 'OverrideEdit');
        this.accessCheck(PastDueCertificationReportFieldName.MAINTENANCEREVIEW, 'MaintenanceReviewEdit');
        this.accessCheck(PastDueCertificationReportFieldName.OPERATIONSREVIEW, 'OperationsReviewEdit');
    }
    accessCheck(columnKey: string, propertyName: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);
        this.agGridService.updateEditableProperty(columnKey, access);
        return access;
    }
    // statusFiltered(statusList) {
    //     const reports: any = this.dailyBackupReport.filter(element => {
    //         return statusList.includes(element.Status);
    //     });
    //     this.pastDueReports = reports;
    //     if (this.pastDueReports.length == 0) {
    //         uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
    //     }
    // }
    @computed
    get DuplicateList(): PastDueDateResponse[] {
        return toJS(this.pastDueReports);
    }
    async getDriverTrailerData() {
        const { pastDueData } = await this.pastDueCertificationReportDataService.getPastDueModel();
        const trailerInfo: PastDueDateResponse[] = pastDueData['Data'];
        this.operationsReviewDropdown = OperationsDropdown;
        this.maintenanceReviewDropdown = MaintenanceDropdown;
        this.overrideDropdown=OverrideDropdown;
        this.addValuesInCellDropdowns();
    }
    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.PASTDUE_ERROR_MESSAGE);
    })
    async getPastDueReports() {
        //  this.pastDueReports = [];
        const reqbody: any = {};
        const pastDueReports = await this.pastDueCertificationReportDataService.getPastDueReport(reqbody);
        if (pastDueReports) {
                for (const key in pastDueReports) { 
                   pastDueReports[key].Overrideflag = 
                   pastDueReports[key].Overrideflag == true ? 'Yes' : 'No'
                    console.log(pastDueReports[key].Overrideflag)
                    if(pastDueReports[key].PhoneNumber != null)
                    pastDueReports[key].PhoneNumber = pastDueReports[key].PhoneNumber.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
                }
            }
        this.pastDueReports = pastDueReports.filter(a => !a.ClearanceFlag);
        this.backupPastDueReport = pastDueReports.filter(a => !a.ClearanceFlag);
        // this.getVolumeSum(this.dailyReports);
        // if (this.dailyReports.length == 0) {
        //     errorHandler(ReportMessage.NO_RECORDS);
        // }
    }
    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.PASTDUE_ERROR_MESSAGE);
    })
    // async getRangeResult(startDate, endDate) {
    //     const reqbody: DateRange = {
    //         StartDate: startDate,
    //         EndDate: endDate
    //     };
    //     if (this.checkCCLogin()) {
    //         reqbody.CCUID = this.userID;
    //     }
    //     this.pastDueReports = [];
        // const dailyReports = await this.dailyreportDataService.getDailyReportwithParams(reqbody);
        // this.dailyReports = dailyReports;
        // this.setBackupReport(this.dailyReports);
        // this.getVolumeSum(this.dailyReports);
        // if (this.dailyReports.length == 0) {
        //     errorHandler(ReportMessage.NO_RECORDS);
        // }
    // }
    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }
    @action
    async handleCheckboxClick(data: any, value: any, colName: string): Promise<void> {
        const row = data.ID;
        const initValue = data[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case 'CLEARANCE FLAG' :
                data.ClearanceFlag = true;
                break;
            default:
                break;
        }
        this.approvedCommentTicketModel = data;
        this.approvedColumn = 'ClearanceComments';
        this.showClearanceCommentPopup();
        // this.triggerModal(data, 'ClearanceComments');
    }
}
