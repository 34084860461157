import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (value) => void;
}
export class ApportionmentPopUp extends React.Component<ConfirmProps> {
    state = {
        value: '',
        invalidEmail: ''
    };
    public render(): React.ReactNode {
        const { onClose } = this.props;
        return (
            <div className="confirm">
                <div className="confirm__action mt-10">Enter Apportionment % value :</div>
                <Row className="mb-2">
                    <Col>
                        <div className="input">
                            <input
                                className={`form-control ${this.state.invalidEmail}`}
                                placeholder="Enter % value"
                                onChange={this.handleChange}
                                value={this.state.value}
                            />
                            {this.state.invalidEmail && <div className="email-error">{'Enter a % value'}</div>}
                        </div>
                    </Col>
                </Row>
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick}>
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleChange = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ value: value });
    };

    handleClick = () => {
        if (this.state.value != '') {
            this.props.onSuccessClick(this.state.value);
            this.props.onClose();
        } else {
            this.setState({ invalidEmail: 'invalid-email' });
        }
    };
}
