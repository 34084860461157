import * as React from 'react';

import Select, { components, ActionMeta, ValueType } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Option } from '../../types/shared';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCaretDown);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="caret-down" />;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

export interface SelectProps {
    onChange: (option: ValueType<SelectOptionType> | Option) => void;
    options: Option[];
    placeholder: string;
    values: Option[] | Option;
    isMulti?: boolean;
    isSearchable?: boolean;
}

export type SelectOptionType = Option[] | Option;

export class ReactSelect extends React.Component<SelectProps> {
    public render(): React.ReactNode {
        const { options, placeholder, values, isMulti = true, isSearchable = false } = this.props;
        return (
            <div className="select">
                <Select
                    value={values}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    onChange={this.handleChange}
                    options={options}
                    components={{ DropdownIndicator }}
                    isSearchable={isSearchable}
                />
            </div>
        );
    }

    private handleChange = (values: ValueType<SelectOptionType>): void => {
        const { onChange } = this.props;
        onChange(values);
    };
}
