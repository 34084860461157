import { TabletHeaderName, TabletFieldName } from './tablet-enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { AlphaNumericCellRenderer } from '../components/cell-renderers-tablet/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';
import { tabletStore } from '../stores';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { CheckBoxCellRenderer } from '../components/cell-renderers/tablet-checkbox-cell-renderer';

const modifiedDate = {
    headerName: TabletHeaderName.MODIFIEDDATE,
    field: TabletFieldName.MODIFIEDDATE,
    colId: TabletFieldName.MODIFIEDDATE,
    width: 80,
    valueGetter: params => {
        if (params.data[TabletFieldName.MODIFIEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TabletFieldName.MODIFIEDDATE]);
        }
    }
};
const manufacture = {
    headerName: TabletHeaderName.MANUFACTURE,
    field: TabletFieldName.MANUFACTURE,
    colId: TabletFieldName.MANUFACTURE,
    width: 90,
    cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
    editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
    valueGetter: params => {
        if (params.data[TabletFieldName.MANUFACTURE] === null) {
            return '';
        } else {
            return params.data[TabletFieldName.MANUFACTURE];
        }
    }
};
const createdDate = {
    headerName: TabletHeaderName.CREATEDDATE,
    field: TabletFieldName.CREATEDDATE,
    colId: TabletFieldName.CREATEDDATE,
    width: 105,
    valueGetter: params => {
        if (params.data[TabletFieldName.CREATEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TabletFieldName.CREATEDDATE]);
        }
    }
};

export const tabletColDef: ColDef[] = [
    { headerName: TabletHeaderName.ID, width: 80, field: TabletFieldName.ID },
    manufacture,
    {
        headerName: TabletHeaderName.CARRIERCOMPANY,
        field: TabletFieldName.CARRIERCOMPANY,
        width: 125,
        cellEditor: 'agSelectCellEditor',
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[TabletFieldName.ID] <= 0 ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[TabletFieldName.ID] <= 0 ? true : false),
        valueGetter: params => {
            if (params.data[TabletFieldName.CARRIERCOMPANY] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.CARRIERCOMPANY];
            }
        }
    },
    {
        headerName: TabletHeaderName.TABLETOS,
        field: TabletFieldName.TABLETOS,
        width: 85,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TabletFieldName.TABLETOS] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.TABLETOS];
            }
        }
    },
    {
        headerName: TabletHeaderName.TABLETIMEI,
        field: TabletFieldName.TABLETIMEI,
        width: 90,
        cellRendererFramework: NumericCellRenderer,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellRendererParams: {
            onChange: (item, value) => tabletStore.updateIMEI(item, value, TabletFieldName.TABLETIMEI)
        },
        valueGetter: params => {
            if (params.data[TabletFieldName.TABLETIMEI] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.TABLETIMEI];
            }
        }
    },
    {
        headerName: TabletHeaderName.ANDROIDID,
        field: TabletFieldName.ANDROIDID,
        width: 90,
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[TabletFieldName.ID] <= 0 ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[TabletFieldName.ID] <= 0 ? true : false),
        valueGetter: params => {
            if (params.data[TabletFieldName.ANDROIDID] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.ANDROIDID];
            }
        }
    },
    {
        headerName: TabletHeaderName.KNOXSERVICE,
        field: TabletFieldName.KNOXSERVICE,
        width: 70,
        valueGetter: params => {
            return params.data[TabletFieldName.KNOXSERVICE] === true ? 'Enabled' : 'Disabled';
        }
    },
    {
        headerName: TabletHeaderName.LOCATIONSERVICE,
        field: TabletFieldName.LOCATIONSERVICE,
        width: 80,
        valueGetter: params => {
            return params.data[TabletFieldName.LOCATIONSERVICE] === true ? 'Enabled' : 'Disabled';
        }
    },
    {
        headerName: TabletHeaderName.ASSIGNEDTO,
        field: TabletFieldName.ASSIGNEDTO,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TabletFieldName.ASSIGNEDTO] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.ASSIGNEDTO];
            }
        }
    },
    {
        headerName: TabletHeaderName.LASTUSER,
        field: TabletFieldName.LASTUSER,
        width: 85,
        valueGetter: params => {
            if (params.data[TabletFieldName.LASTUSER] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.LASTUSER];
            }
        }
    },
    {
        headerName: TabletHeaderName.LASTLOGINDATE,
        field: TabletFieldName.LASTLOGINDATE,
        width: 90,
        valueGetter: params => {
            if (params.data[TabletFieldName.LASTLOGINDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TabletFieldName.LASTLOGINDATE]);
            }
        }
    },
    {
        headerName: TabletHeaderName.MODIFIEDBY,
        field: TabletFieldName.MODIFIEDBYUSER,
        width: 95,
        valueGetter: params => {
            if (params.data[TabletFieldName.MODIFIEDBYUSER] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.MODIFIEDBYUSER];
            }
        }
    },
    { headerName: TabletHeaderName.MODIFIEDBY, field: TabletFieldName.MODIFIEDBY, hide: true },
    modifiedDate,
    {
        headerName: TabletHeaderName.CREATEDBYUSER,
        field: TabletFieldName.CREATEDBYUSER,
        width: 90,
        valueGetter: params => {
            if (params.data[TabletFieldName.CREATEDBYUSER] === null) {
                return '';
            } else {
                return params.data[TabletFieldName.CREATEDBYUSER];
            }
        }
    },
    createdDate,
    {
        headerName: TabletHeaderName.DELETE,
        field: TabletFieldName.CHECKBOX,
        width: 65,
        cellRendererFramework: CheckBoxCellRenderer
    }
];
