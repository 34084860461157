import { UserApprovalResponse } from './userApprovalResponse';
import { UserApprovalDataStore } from '../stores/user-approval-data-store';
import { ICellRendererParams } from 'ag-grid-community';

export interface UserApprovalViewModel {
    userApprovalInfo: UserApprovalResponse[];
    facilities: {};
    carrierCompany: AllCarrierCompany[];
    psaCarrier: {};
}

export interface UserApprovalCellRendererParams extends ICellRendererParams {
    Store: UserApprovalDataStore;
}

export interface Facility {
    Version: number;
    UpdatedDate: string;
    ID: number;
    Name: string;
}

export interface CarrierCompany {
    Version: number;
    UpdatedDate: string;
    ID: number;
    Name: string;
}

export interface PSACarrier {
    Version: number;
    UpdatedDate: string;
    ID: number;
    Name: string;
}

export interface AllCarrierCompany {
    ID: number;
    CompanyName: string;
}

export const UserTypeDropdown = [
    { value: 'Plains', label: 'Plains' },
    { value: 'Non Plains Driver', label: 'Non Plains Driver' }
];