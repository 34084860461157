import React from 'react';
import './_error-page.scss';
import error from '../../../assets/error.png';

interface ErrorPageProps {
    primaryText: string;
    secondaryText: string;
}

class ErrorPage extends React.Component<ErrorPageProps, {}> {
    render() {
        const primaryText = this.props.primaryText || 'Oops, Page Not Found';
        const secondaryText = this.props.secondaryText || 'Page you are looking for does not exist';
        return (
            <div className="error-page">
                <div>
                    <img src={error} />
                </div>
                <div className="error-page__primary">{primaryText}</div>
                <div className="error-page__secondary">{secondaryText}</div>
            </div>
        );
    }
}

export default ErrorPage;
