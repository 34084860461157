import React, { PureComponent } from 'react';
import { dispatchPlanDataStore } from '../stores';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from '../../../../features/common/stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class DispatchPlanRadioCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState,{}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data.SELECTED ? props.data.SELECTED : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }

    handleRadioButtonChange() {
        this.setState({ value: !this.state.value });
        dispatchPlanDataStore.selectedDispatchPlanRowData(this.props.data, this.state.value, this.props.column.getColId());
    }

    render() {
        const disableArray = ['Cancelled','Manually Deleted ', 'Apportionment Deleted', 'System Deleted'];
        return (
            <>
                <input
                    type="checkbox"
                    disabled={!dataStore.checkOperationAccess('Save')}
                    checked={this.state.value}
                    onChange={this.handleRadioButtonChange}
                />
            </>
        );
    }
}