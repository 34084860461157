/* eslint-disable */
import React, { PureComponent } from 'react';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import './hazardAssessment-component.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { HazardAssessmentHistoryResponse } from '../model/hazardAssessment-history-model';
import { dateUtils } from 'shared/services/date-utils';
import { HazardAssessmentReport } from '../model/hazardAssessmentReport-model';

interface HazardAssessmentReportComponentProps {
    hazard: any;
}
@observer
export class HazardAssessmentReportComponent extends PureComponent<
HazardAssessmentReportComponentProps> {
    constructor(props) {
        super(props);
    }
    public render(): React.ReactNode {
        return (
            <div className={'hazard-data_scroll1'}>
                        <React.Fragment>
                            <div className="Uimargin">
                                <Collapisble title="Hazard ID Report" collapsedpros={true}>
                                    {this.renderUI()}
                                </Collapisble>
                        </div>
                        </React.Fragment>
            </div>
        );
    }
    private renderUI(): React.ReactNode {
        return (
            <Container fluid={true}>
                <Row className="mt-3">
                    <Col>
                         <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Level of Risk</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        disabled
                                        id="d1"
                                        name={'rad' + this.props.hazard.RiskLevel}
                                        checked={this.props.hazard.RiskLevel === 'Low'}
                                    />
                                    <label htmlFor="d1"> Low</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        disabled
                                        id="d2"
                                        name={'rad' + this.props.hazard.RiskLevel}
                                        checked={this.props.hazard.RiskLevel === 'Medium'}
                                    />
                                    <label htmlFor="d2"> Medium</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        disabled
                                        type="radio"
                                        id="d3"
                                        name={'rad' + this.props.hazard.RiskLevel}
                                        checked={this.props.hazard.RiskLevel === 'High'}
                                    />
                                    <label htmlFor="d3"> High</label>
                                </div>
                            </div>
                        </fieldset> 
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Date</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.CreatedDate}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Related Ticket</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.VolumeorHourlyTicketNumber}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Weather</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.Weather}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Location</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {/* {hazard.Location} */}
                                {this.props.hazard.Consignor === null ? this.props.hazard.Consignee : this.props.hazard.Consignor}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Customer Name</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.CustomerName}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldLargescss'}>
                            <legend className={'hazard-data__filter-legend'} >Description</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.Description}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldLargescss'}>
                            <legend className={'hazard-data__filter-legend'} >Possible Causes</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.EventDetails}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldLargescss'}>
                            <legend className={'hazard-data__filter-legend'} >Corrective Actions Taken or Recommendations</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.Recommendations}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                    <fieldset className={'border hazardAssessmentFieldscss'}>
                            <legend className={'hazard-data__filter-legend'} >Driver Name</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                {this.props.hazard.FirstName}{' '}{this.props.hazard.LastName}
                                </div>
                            </div>
                    </fieldset>
                    </Col>
                </Row>
            </Container>
        );
    }
}
