import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { EmailConfigurationResponse } from '../domains/model';
import { OperatorResponse } from 'features/data_management/domains/location-management/model';

export class ScheduledReportDataService {
    constructor(private restApi: RestApiService) {}

    async getScheduledReport(reqbody): Promise<EmailConfigurationResponse[]> {
        const { data } = await this.restApi.postWithToken<EmailConfigurationResponse[]>(
            Config.getEmailConfiguration,
            reqbody,
            {}
        );
        return data['Data'];
    }

    async getOperator(reqbody): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getOperator, reqbody, {});
        return data['Data'];
    }

    async createCustomerReport(reqbody): Promise<void> {
        const { data } = await this.restApi.postWithToken<void>(Config.createCustomerReport, reqbody, {});
        return data['Data'];
    }
}

export const scheduledReportDataService = new ScheduledReportDataService(restApiService);
