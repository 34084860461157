export interface DispatchPlanResponse {
    SELECTED: any;
    LoadNumber: string;
    CustomerName: string;
    ContractNumber: string;
    Product: string;
    LoadStatus: string;
    OriginID: number;
    DestinationID: number;
    Origin: string;
    Destination: string;
    NominationMonth: number;
    NominationId: number;
    DriverID: number;
    DriverName: string;
    Priority: number;
    TrailerNumber: string;
    EstimatedVolume: number;
    CompletedVolume: number;
    PlannedDateTime: string;
    DispatchedDate: string;
    DriverComments: string;
    DispatcherComments: string;
    CreatedBy: string;
    CreatedDate: string;
    ModifiedDate: string;
    ModifiedBy: string;
    Dispatched: string;
    IsDeletedDispatchedLoad: boolean;
    IsNewOrigin: number;
    IsNewDestination: number;
    ID: number;
    RegionID: string;
    DataSource: string;
    Region: string;
    DispatcherNotes: string;
    DispatchType: string;
    CarrierName: string;
    CarrierID:string;
    CarrierComments : string;
    selectedType: string;
    ACheckDate: string;
    ACheckColor: number;
}

export interface TrailerResponse {
    TrailerNumber: string;
    Status: string;
}

export const PriorityType = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 }
];

export interface DispatchPlanViewModel {
    trailerData: TrailerResponse[];
}

export interface DriverResponse {
    DriverName: string;
    DriverID: number;
    ID: number;
}

export interface CompanyResponse {
    ID: number;
    AccountNumber: number;
    CompanyName: string;
    CreatedDate?: Date;
}

export interface UpdateDispatchPlanResponse {
    LoadNumber: string;
    DriverID: number;
    TrailerNumber: string;
    PlannedDateTime: string;
    DispatcherComments?: string;
    ButtonName?: string;
    ModifiedBy?: number;
    Priority?: number;
    DispatcherNotes: string;
    IsAdhoc?: boolean;
    CarrierID?: number;
    DispatchType?: string;
    CarrierComments?: string;
}

export interface RegionType {
    name: string;
    id: string;
}

export interface Region {
    BaseClassID: string;
    ID: number;
    Name: string;
    Code: string;
    Value: unknown;
}
