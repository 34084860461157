import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_shop-assignment-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AssignedShopDetailsComponent } from './assigned-shop-details-component';
import { TrailerMaintenanceStatus } from '../domains/enum';
import { ShopInfo } from '../model/model';
import { defaultShopInfo, defaultSelectedShop } from '../maintenance_portal_constant';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import { AppConstant } from 'app_constant';
import moment from 'moment';

interface ShopAssignmentProps {
    selectedShopID: number;
    shops: ShopInfo[];
    status: string;
    shopAssignedDate: string;
    onHide: () => void;
    onSelectionChange: (shopId: number) => void;
}

interface ShopAssignmentState {
    selectedShopID: number;
    shopAssignedDate: string;
    isInternalChange: boolean;
}

@observer
export class ShopAssignmentComponent extends Component<ShopAssignmentProps, ShopAssignmentState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedShopID: this.props.selectedShopID,
            shopAssignedDate: this.props.shopAssignedDate,
            isInternalChange: false
        };
    }

    componentDidUpdate() {
        if (this.props.selectedShopID != this.state.selectedShopID && !this.state.isInternalChange) {
            this.setState({ selectedShopID: this.props.selectedShopID, shopAssignedDate: this.props.shopAssignedDate });
        }
    }

    getSelectedShopInfo = (id, shops) => {
        let selectedShop = shops.filter(s => s.ID === id)[0];
        if (selectedShop && !this.state.isInternalChange)
        {
            selectedShop.ModifiedDate = this.state.shopAssignedDate==undefined ?"":
              moment.utc(new Date(this.state.shopAssignedDate)).format(
                AppConstant.DATE_TIME_FORMAT
            );
        }
        if (selectedShop && this.state.isInternalChange)
        {
            selectedShop.ModifiedDate ="";
        }  return selectedShop ? selectedShop : defaultShopInfo;
    };

    mapShopInfoToShopOption = shops => DataAdapterService.getOptionValues(shops, 'ShopName', 'ID', true);

    getSelectedShopOption = (id, shops) => {
        let selectedShopObj = shops.filter(s => parseInt(s.value) === id)[0];
        selectedShopObj = selectedShopObj ? selectedShopObj : defaultSelectedShop;
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { status, shops } = this.props;
        const { selectedShopID } = this.state;
        const isDisabled = status ? !(status === TrailerMaintenanceStatus.SHOPASSIGNED) : true;
        const dropdownClassName = isDisabled ? 'shop-assignment-popup__type disabled' : 'shop-assignment-popup__type';
        const shopOptions = this.mapShopInfoToShopOption(shops);
        const selectedShopOption = this.getSelectedShopOption(selectedShopID, shopOptions);
        return (
            <div className="shop-assignment-popup__parent">
                <label className="shop-assignment-popup__label">Shop Name</label>
                <div className={dropdownClassName}>
                    <ReactSelect
                        values={selectedShopOption}
                        placeholder="SelectShop"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                        isSearchable={true}
                    />
                </div>

                <AssignedShopDetailsComponent shopInfo={this.getSelectedShopInfo(selectedShopID, shops)} />

                <div className="shop-assignment-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} disabled={isDisabled} onClick={this.assignShop}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => this.setState({ selectedShopID: parseInt(option.value), isInternalChange: true });

    assignShop = () => {
        this.props.onSelectionChange(this.state.selectedShopID);
        this.props.onHide();
    };
}
