import React, { Component } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { trailerStore } from '../../stores';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import './_numeric-cell-renderer.scss';
import { accountStore } from 'features/account/stores';
import { dataStore } from 'features/common/stores';

interface CapacityCellRendererState {
    value: string;
}

export class CapacityCellRenderer extends Component<ICellRendererParams, CapacityCellRendererState> {
    constructor(props: ICellRendererParams) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    handleChange = (number: any): void => {
        this.setState({ value: number.currentTarget.value });
    };

    handleKeyDown = (event) => {
        var charCode = (event.which) ? event.which : event.KeyCode;
        //allow for letter,number,spacebar,comma,period,dash,backspace
        if (!((charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 91)
            || (charCode > 96 && charCode < 123) || charCode == 0 || (charCode > 187 && charCode < 191) || charCode == 32
            || charCode == 8 || charCode == 222)) {
            event.preventDefault();
            return;
        }
    }

    handleBlur = (): void => {
        if(this.props.column.getColId() === 'WaterCapacityLead'){
            trailerStore.setLeadCapacity(this.props.data, this.state.value, this.props.column.getColId());
        } else {
            trailerStore.setPupCapacity(this.props.data, this.state.value, this.props.column.getColId());
        }
    };

    isDisabled = () => {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        if (currentUserRoles.includes('ptsmaintenance') || currentUserRoles.includes('ptstruckingmanager') || currentUserRoles.includes('ptssysadmin')) {
            access = true;
        }
        return access;
    }
    commaFormat = (number) => {
        return number.toLocaleString('en-US')
    }
    render(): React.ReactNode {
        const { value } = this.state;
        const readonly = !this.isDisabled();
        if (readonly) {
            return <div>{this.commaFormat(value)}</div>;
        }
        return (
            <div className="alpha-numeric-cell">
                <input type="text" value={this.commaFormat(value)}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                    readOnly={readonly} />
            </div>
        );
    }
}
