export enum FilterKeyForReferenceData {
    InspectionTicketHistory = 'InspectionTicketHistory',
    TrailerBookingMaintenance = 'TrailerBookingMaintenance',
    Select = 'Select'
}

export enum TrailerMaintenanceButtons {
    SAVE = 'Save Maintenance',
    RESET = 'Reset'
}

export enum TrailerMaintenanceMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Maintenance data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    ERROR_LOADING_SHOPS = 'Shops list could not be loaded',
    NO_BOOKINGS = 'No Bookings Found'
}

export enum TrailerMaintenanceHeaderName {
    ID = 'Maintenance ID',
    TRAILERID = 'Trailer ID',
    TRAILERNUMBER = 'Trailer Number',
    STATUS = 'Status',
    MAINTENANCETYPE = 'Booking Type',
    SHOPID = 'Shop ID',
    BOOKED = 'Booking Date',
    SHOPASSIGNEDDATE = 'Shop Assigned Date',
    LASTNAME = 'Last Name',
    FIRSTNAME = 'First Name',
    INMAINTENANCEDATE = 'In Shop Date',
    MAINTENANCECOMPLETEDATE = 'Maintenance Complete Date',
    BOOKINGCANCELLEDDATE = 'Booking Cancelled Date',
    ASSIGNEDSHOP = 'Assigned Shop',
    SHOPNAME ='Shop Name',
    COMPANYNAME = 'Carrier Company',
    SHOPASSIGNEDBY = 'Shop Assigned By',
    MAINTENANCEASSIGNEDBY = 'In Shop Assigned By',
    MAINTENANCECOMPLETEDBY = 'Completed By',
    BOOKINGCANCELLEDBY = 'Booking Cancelled By',
    PARKEDASSIGNEDBY = 'Parked Assigned By',
    PARKEDOUTOFSERVICEASSIGNEDBY = 'Parked Out Of Service Assigned By',
    PARKEDASSIGNEDDATE = 'Parked Assigned Date',
    PARKEDOUTOFSERVICEASSIGNEDDATE = 'Parked Out Of Service Assigned Date',
    COMMENT = 'Comments',
    DESCRIPTIONOFMAINTENANCEREQUESTED = 'Description of Maintenance Requested',
    INSPECTIONTICKETNUMBER = 'Inspection Ticket Number',
    REMAININGMAINTENANCEKMS = 'Remaining Kms for maintenance',
    MAINTENANCEDUEDATE = 'Maintenance Due by date',
    PENDINGPAPERWORKDATE = 'Pending Paperwork Date',
    PENDINGPAPERWORKASSIGNEDBY = 'Pending Paperwork Assigned By',
    TRAILERTYPE = 'Trailer Type',
    Acheck= 'A-check',
    CVIP= 'CVIP',
    V= 'V',
    K= 'K',
    I= 'I',
    P= 'P',
    UC= 'U/C',
    OTES = 'OTES'
}

export enum TrailerMaintenanceFieldName {
    ID = 'ID',
    TRAILERID = 'TrailerID',
    SHOPID = 'ShopID',
    TRAILERNUMBER = 'TrailerNumber',
    STATUS = 'Status',
    MAINTENANCETYPE = 'MaintenanceType',
    BOOKED = 'Booked',
    SHOPASSIGNEDDATE = 'ShopAssignedDate',
    LASTNAME = 'DriverLastName',
    FIRSTNAME = 'DriverFirstName',
    INMAINTENANCEDATE = 'InMaintenanceDate',
    MAINTENANCECOMPLETEDATE = 'MaintenanceCompleteDate',
    BOOKINGCANCELLEDDATE = 'BookingCancelledDate',
    ASSIGNEDSHOP = 'AssignedShop',
    SHOPNAME ='ShopName',
    COMPANYNAME = 'CompanyName',
    SHOPASSIGNEDBY = 'ShopAssignedByFullName',
    MAINTENANCEASSIGNEDBY = 'MaintenanceAssignedByFullName',
    MAINTENANCECOMPLETEDBY = 'MaintenanceCompletedByFullName',
    BOOKINGCANCELLEDBY = 'BookingCancelledByFullName',
    PARKEDASSIGNEDBY = 'ParkedAssignedByFullName',
    PARKEDOUTOFSERVICEASSIGNEDBY = 'ParkedOutOfServiceAssignedByFullName',
    PARKEDASSIGNEDDATE = 'ParkedDate',
    PARKEDOUTOFSERVICEASSIGNEDDATE = 'ParkedOutOfServiceDate',
    PENDINGPAPERWORKDATE = 'PendingPaperworkDate',
    PENDINGPAPERWORKASSIGNEDBY = 'PendingPaperworkAssignedByFullName',
    COMMENT = 'Comments',
    DESCRIPTIONOFMAINTENANCEREQUESTED = 'MaintenanceDescriptionPTI',
    INSPECTIONTICKETNUMBER = 'InspectionTicketNumber',
    REMAININGMAINTENANCEKMS = 'RemainingKM',
    MAINTENANCEDUEDATE = 'DueDate',
    TRAILERTYPE = 'TrailerType',
    Acheck= 'Acheck_Value',
    CVIP = 'CVIP_Value',
    V = 'V_Value',
    K = 'K_Value',
    I = 'I_Value',
    P = 'P_Value',
    UC = 'UC_Value',
    OTES = 'OTES_Value'
}

export enum TrailerMaintenanceStatus {
    BOOKED = 'Booked',
    SHOPASSIGNED = 'Shop Assigned',
    INMAINTENANCE = 'In Shop',
    PENDINGPAPERWORK = 'Pending Paperwork',
    MAINTENANCECOMPLETE = 'Maintenance Complete',
    CANCELLED = 'Cancelled',
    PARKED = 'Parked',
    PARKEDOUTOFSERVICE = 'Parked Out Of Service'
}
