import { ShopFieldName, ShopHeaderName } from './shop-enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from '../../common/stores/index';

export const shopColDef: ColDef[] = [
    {
        headerName: ShopHeaderName.ID,
        field: ShopFieldName.ID,
        hide: true
    },
    {
        headerName: ShopHeaderName.SHOPNAME,
        field: ShopFieldName.SHOPNAME,
        width: 95,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.SHOPNAME] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.SHOPNAME];
            }
        }
    },
    {
        headerName: ShopHeaderName.ADDRESS,
        field: ShopFieldName.ADDRESS,
        width: 110,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.ADDRESS] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.ADDRESS];
            }
        }
    },
    {
        headerName: ShopHeaderName.CITY,
        field: ShopFieldName.CITY,
        width: 55,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.CITY] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.CITY];
            }
        }
    },
    {
        headerName: ShopHeaderName.PROVINCE,
        field: ShopFieldName.PROVINCE,
        width: 80,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.PROVINCE] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.PROVINCE];
            }
        }
    },
    {
        headerName: ShopHeaderName.POSTALCODE,
        field: ShopFieldName.POSTALCODE,
        width: 65,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.POSTALCODE] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.POSTALCODE];
            }
        }
    },
    {
        headerName: ShopHeaderName.CONTACTNUMBER,
        field: ShopFieldName.CONTACTNUMBER,
        width: 75,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[ShopFieldName.CONTACTNUMBER] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.CONTACTNUMBER];
            }
        }
    },
    {
        headerName: ShopHeaderName.STATUS,
        field: ShopFieldName.STATUS,
        width: 65,
        editable: (params): boolean => {
            return params.data.ID > 0 && dataStore.checkOperationAccess('Save');
        },
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        cellEditor: 'agSelectCellEditor',
        valueGetter: params => {
            if (params.data[ShopFieldName.STATUS] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.STATUS];
            }
        }
    },
    {
        headerName: ShopHeaderName.STATUSID,
        field: ShopFieldName.STATUSID,
        width: 90,
        hide: true
    },
    {
        headerName: ShopHeaderName.CREATEDBYNAME,
        field: ShopFieldName.CREATEDBYNAME,
        width: 90,
        valueGetter: params => {
            if (params.data[ShopFieldName.CREATEDBYNAME] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.CREATEDBYNAME];
            }
        }
    },
    {
        headerName: ShopHeaderName.CREATEDDATE,
        field: ShopFieldName.CREATEDDATE,
        width: 105,
        valueGetter: params => {
            if (params.data[ShopFieldName.CREATEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[ShopFieldName.CREATEDDATE]);
            }
        }
    },
    {
        headerName: ShopHeaderName.MODIFIEDBYNAME,
        field: ShopFieldName.MODIFIEDBYNAME,
        width: 95,
        valueGetter: params => {
            if (params.data[ShopFieldName.MODIFIEDBYNAME] === null) {
                return '';
            } else {
                return params.data[ShopFieldName.MODIFIEDBYNAME];
            }
        }
    },
    {
        headerName: ShopHeaderName.MODIFIEDDATE,
        field: ShopFieldName.MODIFIEDDATE,
        width: 105,
        valueGetter: params => {
            if (params.data[ShopFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[ShopFieldName.MODIFIEDDATE]);
            }
        }
    },
    {
        headerName: ShopHeaderName.LAT,
        field: ShopFieldName.LAT,
        hide: true
    },
    {
        headerName: ShopHeaderName.LONG,
        field: ShopFieldName.LONG,
        hide: true
    },
    {
        headerName: ShopHeaderName.MODIFIEDBYID,
        field: ShopFieldName.MODIFIEDBYID,
        hide: true
    },
    {
        headerName: ShopHeaderName.CREATEDBYID,
        field: ShopFieldName.CREATEDBYID,
        hide: true
    },
    {
        headerName: ShopHeaderName.SEARCHBY,
        field: ShopFieldName.SEARCHBY,
        hide: true
    },
    {
        headerName: ShopHeaderName.SEARCHBYVALUE,
        field: ShopFieldName.SEARCHBYVALUE,
        hide: true
    }
];
