import { ShopResponse } from '../domains/shop-model'
import { ShopHeaderName } from "../domains/shop-enum"
import { CommonUtils } from 'shared/services/common-utils';

export class ShopValidationService {
    validateUpdateRequest(updatedList: ShopResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.ShopName)) {
                errorMessage = `${ShopHeaderName.SHOPNAME} cannot be empty`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.Province) && !CommonUtils.checkForAlphaNumeric(item.Province)) {
                errorMessage = `${ShopHeaderName.PROVINCE} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.PostalCode) && !CommonUtils.checkForAlphaNumeric(item.PostalCode)) {
                errorMessage = `${ShopHeaderName.POSTALCODE} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.City) && !CommonUtils.checkForAlphaNumeric(item.City)) {
                errorMessage = `${ShopHeaderName.CITY} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.ContactNumber) && !CommonUtils.checkForPhoneNumberSyntax(item.ContactNumber)) {
                errorMessage = `${ShopHeaderName.CONTACTNUMBER} must contain 10 digits`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(100, item.City)) {
                errorMessage = `${ShopHeaderName.CITY} is allowed upto 100 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(20, item.PostalCode)) {
                errorMessage = `${ShopHeaderName.POSTALCODE} is allowed upto 20 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(20, item.Province)) {
                errorMessage = `${ShopHeaderName.PROVINCE} is allowed upto 20 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(150, item.ShopName)) {
                errorMessage = `${ShopHeaderName.SHOPNAME} is allowed upto 150 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(350, item.Address)) {
                errorMessage = `${ShopHeaderName.ADDRESS} is allowed upto 350 characters`;
                return;
            }
        });
        return errorMessage;
    }
}

export const shopValidationService = new ShopValidationService();