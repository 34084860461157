import { action, observable } from 'mobx';
import Loader from '../../../shared/decorators/loader-decorator';
import { InspectionDataService } from '../services/inspection-data-service';
import { InspectionDefect } from '../model/inspectionDefect';
import { InspectionDefectRequest } from '../model/inspectionDefectRequest';
import { PrepostChecklist } from '../model/prepostchecklist';
import { OTESReportViewModel } from '../model/model';

export class InspectionDataStore {
    @observable inspectionDefects: InspectionDefect[] = [];
    @observable prepostChecklist: PrepostChecklist[] = [];
    @observable otesReport: OTESReportViewModel = {
        otesID: '',
        trailerName: '',
        createdBy: '',
        createdDate: '',
        modifiedDate: '',
        tankOwner: '',
        trailerNumber: '',
        signature: '',
        otesStatus: 0,
        procedures: []
    };
    constructor(private inspectionService: InspectionDataService) {}

    init(reqBody: InspectionDefectRequest): void {
        // this.getDefectsByInspectionNumber(reqBody);
        //this.getPrepostChecklist();
        this.getPrepostChecklistdata(reqBody);
    }

    @Loader
    @action
    async getDefectsByInspectionNumber(reqBody: InspectionDefectRequest): Promise<void> {
        try {
            await this.inspectionService
                .getInspectionDefects(reqBody)
                .then(response => {
                    this.inspectionDefects = response;
                })
                .catch(function(error) {
                    console.log('error in getting defects', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getPrepostChecklistdata(reqBody: InspectionDefectRequest): Promise<void> {
        try {
            await this.inspectionService
                .getInspectionDefectsList(reqBody)
                .then(response => {
                    this.prepostChecklist = response;
                })
                .catch(function(error) {
                    console.log('error in getting checklist', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getPrepostChecklist(): Promise<void> {
        try {
            await this.inspectionService
                .getPrepostChecklist()
                .then(response => {
                    this.prepostChecklist = response;
                })
                .catch(function(error) {
                    console.log('error in getting checklist', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getOTESReport(reqbody){
        try{
            const response: any = await this.inspectionService.getOTESRepoort(reqbody);
            const otesReport = response.data['Data'];
            if(otesReport.length > 0){
                this.otesReport.createdDate = otesReport[0].CreatedDate;
                this.otesReport.tankOwner = otesReport[0].TankOwner;
                this.otesReport.trailerNumber = otesReport[0].TrailerNo;
                this.otesReport.createdBy = otesReport[0].CreatedBy;
                this.otesReport.modifiedDate = otesReport[0].ModifiedDate;
                this.otesReport.signature = otesReport[0].ImageUrl;
                this.otesReport.otesStatus = otesReport[0].OTESStatus;
                this.otesReport.procedures = [];
                otesReport.forEach(procedure => {
                    this.otesReport.procedures.push({
                        QuestionID: procedure.QuestionID,
                        QuestionName: procedure.QuestionName,
                        QuestionStatus: procedure.QuestionStatus
                    })
                } )
            } 
        } catch (e) {
            console.log('catch err', e);
            return [];
        }

    }
}
