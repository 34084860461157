import { GridOptions } from 'ag-grid-community';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { toJS } from 'mobx';
import { commaFormat, MasterTicketstatusType } from '../reports_constants';
import moment from 'moment';
import { AppConstant } from 'app_constant';
import { dataStore } from 'features/common/stores';
import { localStorageService } from 'shared/services/local-storage-service';

export interface ViewDispatchDetailsPopup {
    masterTicketReportStore;
    getDispatchDetailsGridConfig: GridOptions;
    isDisabled: boolean;
    handleRefresh: () => void;
    handleCancel: () => void;
}
export const ViewDispatchDetailsPopup = (props: ViewDispatchDetailsPopup) => {
    const {
        masterTicketReportStore,
        getDispatchDetailsGridConfig,
        isDisabled,
        handleRefresh,
        handleCancel,
    } = props;

    dataStore.setModuleName('DispatchDetails')
    let dropdown: any = localStorageService.get('MasterTicketReport_Dropdown')
    const ticketType = dropdown[0].TicketType.label
    const selectedStatus = toJS(dropdown[0].TicketStatus.map(val => val.value)).filter(element => element != 'all');
    const status = (selectedStatus.length == 0 || selectedStatus.length == MasterTicketstatusType.length) ? 'All' : selectedStatus.join(', ').toString().replace('Assigned','Dispatched');
    const startDate = moment(masterTicketReportStore.startDate).format('MM-DD-YYYY')
    const endDate = moment(masterTicketReportStore.endDate).format('MM-DD-YYYY')

    const handleClearFilter = () => {
        return masterTicketReportStore.agGridService.clearAllFilters();
    }
    
    const exportExcel = () => {
        const allColumns: any = masterTicketReportStore.agGridService.getColumns()?.getAllDisplayedColumns();
        return masterTicketReportStore.agGridService
            .getNodes()
            ?.exportDataAsExcel({ fileName: dataStore.getCurrentModuleName(), columnKeys: allColumns });
    }

    return (
        <div className="modal" id="load-modal">
            <div id="load-modal-content">
                <div className="modal-body" id="load-modal-body">
                    <div className="container-header">
                        <p style={{fontSize: 18}}><b>DISPATCH DETAILS:</b></p>
                        <div>
                            <CustomButton type={CustomButtonType.Submit} onClick={exportExcel}>
                                {' '}
                                Export to excel{' '}
                            </CustomButton>{' '}
                            <CustomButton type={CustomButtonType.Submit} onClick={handleClearFilter} disabled={isDisabled}>
                                Clear Filters
                            </CustomButton>{' '}
                            <CustomButton type={CustomButtonType.Submit} onClick={handleRefresh}>
                            Refresh
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={handleCancel}>
                                {' '}
                                Back{' '}
                            </CustomButton>{' '}
                        </div>
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Selected Date Range : From </b>
                                                <p style={{paddingLeft: 5, paddingRight: 10}}>{startDate}</p>
                                                <b> To </b>
                                                <p style={{paddingLeft: 5, paddingRight: 10}}>{endDate}</p>
                                            </p>
                                        </div>{' '}
                                    </Col></Row>
                                    <Row>
                                    <Col md={2} className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Ticket Type : </b>
                                                {ticketType}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new" style={{textAlign: 'left'}}>
                                            <p>
                                                <b> Status: </b>
                                                <div>{status}</div>
                                            </p>
                                        </div>{' '}
                                    </Col>{' '}
                                </Row>
                                
                            </Col>
                        </Row>{' '}
                    </Container>
                    <br />
                    <AgGridComponent gridConfig={getDispatchDetailsGridConfig} />
                </div>{' '}
            </div>{' '}
        </div>
    );
};
