import { ColDef } from 'ag-grid-community';
import { ApportionmentCheckBoxSelection } from './apportionment-checkbox-selection';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';

export const APPORTIONMENT_COL_DEF: ColDef[] = [
    {
        headerName: '',
        field: 'DELETE',
        colId: 'DELETE',
        width: 30,
        cellRendererFramework: ApportionmentCheckBoxSelection
    },
    {
        headerName: 'Facility Description',
        field: 'ToFacilityDescription',
        width: 140
    },
    {
        headerName: 'Product',
        field: 'Product',
        width: 80
    },
    {
        headerName: 'Facility Code',
        field: 'FacilityCode',
        width: 110
    },
    {
        headerName: 'Apportionment %',
        field: 'Apportionment',
        width: 130
    }
];
