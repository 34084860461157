export enum ShopSaveLabel {
    SAVE = 'Save Shop Data',
}

export enum ShopFieldName {
    ID = 'ID',
    SHOPNAME = 'ShopName',
    ADDRESS = 'Address',
    PROVINCE = 'Province',
    POSTALCODE = 'PostalCode',
    CITY = 'City',
    CONTACTNUMBER = 'ContactNumber',
    LAT = 'Lattitude',
    LONG = 'Longitude',
    CREATEDBYNAME = 'CreatedByUser',
    MODIFIEDBYNAME = 'ModifyByUser',
    CREATEDBYID = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDDATE = 'ModifyDate',
    STATUS = 'Status',
    STATUSID = 'StatusID',
    MODIFIEDBYID = 'ModifiedBy',
    SEARCHBY = 'SearchBy',
    SEARCHBYVALUE = 'SearchByValue'
}

export enum ShopHeaderName {
    ID = 'ID',
    SHOPNAME = 'Shop Name',
    ADDRESS = 'Shop Address',
    PROVINCE = 'Province',
    POSTALCODE = 'Postal Code',
    CITY = 'City',
    CONTACTNUMBER = 'Contact Number',
    LAT = 'Lattitude',
    LONG = 'Longitude',
    CREATEDBYNAME = 'Created By',
    MODIFIEDBYNAME = 'Modified By',
    CREATEDBYID = 'CreatedByID',
    CREATEDDATE = 'Created Date',
    MODIFIEDDATE = 'Modified Date',
    STATUS = 'Status',
    STATUSID = 'StatusID',
    MODIFIEDBYID = 'ModifiedByID',
    SEARCHBY = 'SearchBy',
    SEARCHBYVALUE = 'SearchByValue'
}

export enum ShopMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Shop data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes'
}