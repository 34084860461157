import { ColDef } from 'ag-grid-community';
import { TicketHeaderName, TicketFieldName, TicketVolOrHourly } from './ticket-enum';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';

export class TicketManagementConstant {
    static SELECT_OPTION_VALUES = [{ value: 'VolumeTicketMaintenance', label: 'Ticket Approval' }];

    static TICKET_FETCH_FAILED = 'Not able to get ticket details';

    static TICKET_SAVE_FAILED = 'Not able to save ticket detail';

    static TICKET_SAVED_SUCCESSFULLY = 'Ticket saved successfully';

    static APPROVER_COMMENT_REQUIRED = 'Approver Comment is required';

    static CARRIER_COMMENT_REQUIRED = 'Carrier Comment is required';

    static TICKET_STATUS = 'TKT_APPROVAL_STATUS';

    static NO_TICKETS = 'No Tickets Found';
}
export const printDisclaimers = {
    accuracyDisclaimer:
      '"I hereby declare that the contents of this consignment are fully and accurately described above by the proper shipping name, are properly classified and packaged, have dangerous goods safety marks properly affixed or displayed on them, and are in all respects in proper condition for transport according to the transportation of dangerous good regulations."',
    regulationsDisclaimer:
      'If these commodities, technology or software were exported from the United States, they were done so in accordance with the export Administration Regulations. Diversion contrary to U.S. law is prohibited.'
  };
  export const printMessages = {
    selectCopiesMsg: 'Please select number of copies.',
    noPrinterConnectedMsg:
      'No printer connected. Would you like to navigate to the App Information screen to connect a printer?'
  };
  export const printLabels = {
    COMMENTS: 'Comments',
    CREATED: 'Created',
    STATUS: 'Status',
    COMPLETEDTIME: 'Completed Time',
    CONSIGNOR_ERP_PHONE_NUMBER: 'Consignor Emergency Response Plan Phone #',
    ADDRESS: 'Address',
    DESCRIPTION: 'Dependable Transportation Services',
    INQUIRIES: 'Inquiries',
    TRAILER_MILEAGE: 'Mileage',
    COMPANY_NAME: 'Plains Midstream Canada',
    ADDRESS_LINE_1: '1400, 607-8th AVE S.W.',
    ADDRESS_LINE_2: 'Calgary AB T2P 0A7',
    PHONE_NUMBER: 'PH- (403) 298 - 2100',
    TICKET: 'Ticket',
    CARRIER: 'Carrier',
    DRIVER: 'Driver',
    TRAILER_NUMBER: 'Trailer',
    POWERUNIT_IDENTIFIER: 'Powerunit Identifier',
    AXLES: 'Axles',
    CONSIGNOR_ID_NAME: 'Consignor',
    CONSIGNOR: 'Consignor',
    CONSIGNOR_FIELD: 'Field',
    CONSIGNOR_LSD: 'Consignor LSD',
    CONSIGNOR_ADDRESS: 'Consignor Address',
    CONSIGNOR_24_PHONE: '24Hr Ph#',
    CONSIGNOR_24_PHONE2: '24Hr Ph# 2',
    CONSIGNEE_ID_NAME: 'Consignee',
    CONSIGNEE: 'Consignee',
    CONSIGNEE_FIELD: 'Field',
    CONSIGNEE_LSD: 'Consignee LSD',
    SHIPPER_TICKET_NUMBER: 'Shipper Ticket Number',
    PRODUCT_LOADED: 'Product Loaded',
    CONSIGNOR_ERP_PLAN_NUMBER: 'Consignor Emergency Response Plan #',
    ESTIMATED_VOLUME: 'Estimated Volume',
    ACTUAL_VOLUME: 'Actual Volume',
    UNITS: 'Units',
    EMPTY_RES: 'Empty Residue Last Contained',
    LOAD_TEMP: 'Load Temperature',
    LOAD_TIME: 'Load Time',
    UNLOAD_TEMP: 'Unload Temperature',
    GROSSWEIGHT: 'Gross Weight',
    ROAD_BAN: 'Road Ban',
    ROAD_VIA: 'Road Via',
    DENSITY: 'Density',
    B_S_W: 'B.S.&W',
    HAZARD_ID: 'Hazard Id',
    CONSIGNOR_NAME: 'Consignor Name',
    AUTHORIZED_BY_PRINT: 'Authorized by (Print)',
    AUTHORIZED_BY_SIGN: 'Authorized by (Sign)',
    DRIVER_SIGNATURE: 'Driver Signature',
    RELATED_VOLUME_TICKET: 'Related Volume Ticket #',
    WORK_TIME: 'Work Time',
    WORKING_TIME_REASON: 'Working Time Reason',
    WAIT_TIME: 'Wait Time',
    WAITING_TIME_REASON: 'Waiting Time Reason',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    AUTHORIZED_BY: 'Authorized By',
    TRACTOR_IDENTIFIER: 'Tractor Identifier',
    CREATED_BY: 'Created By',
    TARE_WEIGHT: 'Tare Weight',
    NET_WEIGHT: 'Net Weight',
    SPLIT_LOAD: 'Split Load',
    RUN_SCALE_TICKET_NUMBER: 'Run Ticket Scale Ticket #',
    OPEN_METER_READING: 'Open Meter Reading',
    CLOSE_METER_READING: 'Close Meter Reading',
    METER_NUMBER: 'Meter Number',
    METER_FACTOR: 'Meter Factor',
    TANK_NUMBER: 'Tank Number',
    TANK_HEIGHT: 'Tank Height',
    GROSS_VOLUME: 'Gross Volume',
    OBS_TEMP: 'Observed Temperature',
    OBS_GRAVITY: 'Observed Gravity',
    TANK_SIZE: 'Tank Size'
  };
  export const VolumeLabelKeys = {
    ticketNumber: printLabels.TICKET,
    ticketStatus: printLabels.STATUS,
    carrier: printLabels.CARRIER,
    driver: printLabels.DRIVER,
    consignorIdName: printLabels.CONSIGNOR_ID_NAME,
    consignorField: printLabels.CONSIGNOR_FIELD,
    consignorLsd: printLabels.CONSIGNOR_LSD,
    consignor: printLabels.CONSIGNOR,
    consigneeIdName: printLabels.CONSIGNEE_ID_NAME,
    consigneeField: printLabels.CONSIGNEE_FIELD,
    consigneeLsd: printLabels.CONSIGNEE_LSD,
    consignee: printLabels.CONSIGNEE,
    trailerNumber: printLabels.TRAILER_NUMBER,
    axles: printLabels.AXLES,
    trailerMileage: printLabels.TRAILER_MILEAGE,
    consignorAddress: printLabels.CONSIGNOR_ADDRESS,
    consignor24HrPhone: printLabels.CONSIGNOR_24_PHONE,
    consignor24HrPhone2: printLabels.CONSIGNOR_24_PHONE2,
    shipperTicketBOLNumber: printLabels.SHIPPER_TICKET_NUMBER,
    tractorIdentifier: printLabels.TRACTOR_IDENTIFIER,
    estimatedVolume: printLabels.ESTIMATED_VOLUME,
    consignorERPPlanNumber: printLabels.CONSIGNOR_ERP_PLAN_NUMBER,
    units: printLabels.UNITS,
    actualVolume: printLabels.ACTUAL_VOLUME,
    consignorERPPhoneNumber: printLabels.CONSIGNOR_ERP_PHONE_NUMBER,
    emptyResidueLastContained: printLabels.EMPTY_RES,
    loadTemperature: printLabels.LOAD_TEMP,
    loadDateTime: printLabels.LOAD_TIME,
    unloadTemperature: printLabels.UNLOAD_TEMP,
    grossWeight: printLabels.GROSSWEIGHT,
    tarWeight: printLabels.TARE_WEIGHT,
    roadBan: printLabels.ROAD_BAN,
    roadVia: printLabels.ROAD_VIA,
    density: printLabels.DENSITY,
    bsw: printLabels.B_S_W,
    hazardId: printLabels.HAZARD_ID,
    inquiry: printLabels.INQUIRIES,
    consignorName: printLabels.CONSIGNOR_NAME,
    splitLoad: printLabels.SPLIT_LOAD,
    runTickeScaleTicketNumber: printLabels.RUN_SCALE_TICKET_NUMBER,
    openMeterReading: printLabels.OPEN_METER_READING,
    closeMeterReading: printLabels.CLOSE_METER_READING,
    meterNumber: printLabels.METER_NUMBER,
    meterFactor: printLabels.METER_FACTOR,
    tankNumber: printLabels.TANK_NUMBER,
    tankSize: printLabels.TANK_SIZE,
    tankHeight: printLabels.TANK_HEIGHT,
    grossVolume: printLabels.GROSS_VOLUME,
    obsTemp: printLabels.OBS_TEMP,
    obsGravity: printLabels.OBS_GRAVITY,
    productLoaded: printLabels.PRODUCT_LOADED,
    netWeight: printLabels.NET_WEIGHT,
    createdDate: printLabels.CREATED,
    comments: printLabels.COMMENTS
  };
  
  export const HourlyLabelKeys = {
    ticketNumber: printLabels.TICKET,
    ticketStatus: printLabels.STATUS,
    carrier: printLabels.CARRIER,
    driver: printLabels.DRIVER,
    consignorIdName: printLabels.CONSIGNOR_ID_NAME,
    consignorField: printLabels.CONSIGNOR_FIELD,
    consignorLsd: printLabels.CONSIGNOR_LSD,
    consignor: printLabels.CONSIGNOR,
    consigneeIdName: printLabels.CONSIGNEE_ID_NAME,
    consigneeField: printLabels.CONSIGNEE_FIELD,
    consigneeLsd: printLabels.CONSIGNEE_LSD,
    consignee: printLabels.CONSIGNEE,
    relatedVolumeTicket: printLabels.RELATED_VOLUME_TICKET,
    trailerNumber: printLabels.TRAILER_NUMBER,
    axles: printLabels.AXLES,
    trailerMileage: printLabels.TRAILER_MILEAGE,
    consignorAddress: printLabels.CONSIGNOR_ADDRESS,
    consignor24HrPhone: printLabels.CONSIGNOR_24_PHONE,
    consignor24HrPhone2: printLabels.CONSIGNOR_24_PHONE2,
    shipperTicketBOLNumber: printLabels.SHIPPER_TICKET_NUMBER,
    tractorIdentifier: printLabels.TRACTOR_IDENTIFIER,
    estimatedVolume: printLabels.ESTIMATED_VOLUME,
    consignorERPPlanNumber: printLabels.CONSIGNOR_ERP_PLAN_NUMBER,
    units: printLabels.UNITS,
    actualVolume: printLabels.ACTUAL_VOLUME,
    consignorERPPhoneNumber: printLabels.CONSIGNOR_ERP_PHONE_NUMBER,
    emptyResidueLastContained: printLabels.EMPTY_RES,
    hazardId: printLabels.HAZARD_ID,
    inquiry: printLabels.INQUIRIES,
    consignorName: printLabels.CONSIGNOR_NAME,
    productLoaded: printLabels.PRODUCT_LOADED,
    workTime: printLabels.WORK_TIME,
    workingTimeReason: printLabels.WORKING_TIME_REASON,
    timeWaiting: printLabels.WAIT_TIME,
    waitingTimeReason: printLabels.WAITING_TIME_REASON,
    createdBy: printLabels.CREATED_BY,
    createdDate: printLabels.CREATED,
    startDate: printLabels.START_DATE,
    endDate: printLabels.END_DATE,
    authorizedBy: printLabels.AUTHORIZED_BY,
    comments: printLabels.COMMENTS
  };
  export const TOTAL_NO_OF_COPY_BUTTON = 4;
  export const PRINT_MESSAGE = 'Printing Ticket...';