import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';
import { locationStore } from 'features/data_management/stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class RestrictedFlagCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }
    
    handleRadioButtonChange() {
        this.setState({ value: !this.props.value });
        locationStore.handleRestrictedFlag(this.props.data);
    };
      
      render() {
        return ( 
            <input 
            onChange={this.handleRadioButtonChange}
            type="checkbox" 
            disabled = {!dataStore.checkOperationAccess('RestrictedLoadSize')}
            checked={this.props.value}/> 
         )
      }
}