export enum TractorSaveLabel {
    SAVE = 'Save Tractor Data'
}

export enum TractorMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Tractor data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    FAILED_DELETE = 'Failed to delete tractor',
    TRACTOR_DELETE = 'Deleted Successfully'
}

export enum TractorHeaderName {
    ID = 'ID',
    VINNUMBER = 'VIN Number',
    PLATE = 'Plate',
    COMPANYNAME = 'Carrier Company',
    COMPLIANCEREVIEW = 'Compliance Review',
    CREATEDBYUSER = 'Created By',
    CREATEDDATE = 'Created Date',
    POWERUNITIDENTIFIER = 'Power Unit Identifier',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    DELETE = 'Delete'
}

export enum TractorFieldName {
    ID = 'ID',
    VIN = 'VIN',
    PLATE = 'RegistrationNo',
    COMPANYNAME = 'CarrierCompany',
    COMPLIANCEREVIEW = 'ComplianceReviewStatus',
    COMPLIANCEREVIEWID = 'ComplianceReviewStatusID',
    MODIFIEDBY = 'ModifiedBy',
    CREATEDBY = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    DELETE = 'Delete',
    CREATEDBYUSER = 'CreatedByUser',
    MODIFIEDBYUser = 'ModifiedByUser',
    MODIFYDATE = 'ModifyDate',
    VERSION = 'Version',
    CHECKBOX = 'Checkbox'
}
