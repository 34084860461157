import { PastDueCertificationReportHeaderName, PastDueCertificationReportFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { pastDueCertificationReportStore } from '../stores';
import { PastDueClearnceCellRenderer } from '../components/pastDue-clearance-cell-renderer';

const getColorCodeForOverride = (flagValue:boolean | string) => {
    let cellColor = 'default'
   
        if (flagValue == "Yes" ) cellColor = 'red';
    else cellColor = 'default';
    return cellColor;
};

export const pastDueReport: ColDef[] = [
    {
        headerName: PastDueCertificationReportHeaderName.TRAILERNUMBER,
        field: PastDueCertificationReportFieldName.TRAILERNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.TRAILERSTATUS,
        field: PastDueCertificationReportFieldName.TRAILERSTATUS,
        width: 80,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.TRAILERSTATUS] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.TRAILERSTATUS];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.TICKETNUMBER,
        field: PastDueCertificationReportFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.DRIVERNAME,
        field: PastDueCertificationReportFieldName.DRIVERNAME,
        width: 115,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.DRIVERNAME] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.PHONENUMBER,
        field: PastDueCertificationReportFieldName.PHONENUMBER,
        width: 90,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.PHONENUMBER] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.PHONENUMBER];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.CARRIERCOMPANY,
        field: PastDueCertificationReportFieldName.CARRIERCOMPANY,
        width: 150,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.CARRIERCOMPANY] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.CARRIERCOMPANY];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.CERTIFICATIONOVERDUE,
        field: PastDueCertificationReportFieldName.CERTIFICATIONOVERDUE,
        width: 120,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.CERTIFICATIONOVERDUE] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.CERTIFICATIONOVERDUE];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.CERTIFICATIONOVERDUEDATE,
        field: PastDueCertificationReportFieldName.CERTIFICATIONOVERDUEDATE,
        width: 125,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.CERTIFICATIONOVERDUEDATE] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.CERTIFICATIONOVERDUEDATE];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.MAINTENANCEREVIEW,
        field: PastDueCertificationReportFieldName.MAINTENANCEREVIEW,
        width: 115,
        cellEditor: 'agSelectCellEditor',
        colId: PastDueCertificationReportFieldName.MAINTENANCEREVIEW,
        editable: () => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEW] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEW];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.MAINTENANCEREVIEWCOMMENTS,
        field: PastDueCertificationReportFieldName.MAINTENANCEREVIEWCOMMENTS,
        colId: PastDueCertificationReportFieldName.MAINTENANCEREVIEWCOMMENTS,
        width: 150,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? false : true),
            onChange: (item, value) =>
            pastDueCertificationReportStore.updateInspectionHistoryComment(
                    item,
                    value,
                    PastDueCertificationReportFieldName.MAINTENANCEREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.MAINTENANCEREVIEWEDBY,
        field: PastDueCertificationReportFieldName.MAINTENANCEREVIEWEDBY,
        width: 115,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWEDBY] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWEDBY];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.MAINTENANCEREVIEWDATE,
        field: PastDueCertificationReportFieldName.MAINTENANCEREVIEWDATE,
        width: 115,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTimeLocal(params.data[PastDueCertificationReportFieldName.MAINTENANCEREVIEWDATE]) ;
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OPERATIONSREVIEW,
        field: PastDueCertificationReportFieldName.OPERATIONSREVIEW,
        width: 110,
        cellEditor: 'agSelectCellEditor',
        colId: PastDueCertificationReportFieldName.OPERATIONSREVIEW,
        editable: () => (dataStore.checkOperationAccess('OperationsReviewEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('OperationsReviewEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEW] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEW];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OPERATIONSREVIEWCOMMENTS,
        field: PastDueCertificationReportFieldName.OPERATIONSREVIEWCOMMENTS,
        colId: PastDueCertificationReportFieldName.OPERATIONSREVIEWCOMMENTS,
        width: 150,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('OperationsReviewEdit') ? false : true),
            onChange: (item, value) =>
            pastDueCertificationReportStore.updateInspectionHistoryComment(
                    item,
                    value,
                    PastDueCertificationReportFieldName.OPERATIONSREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OPERATIONSREVIEWEDBY,
        field: PastDueCertificationReportFieldName.OPERATIONSREVIEWEDBY,
        width: 115,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWEDBY] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWEDBY];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OPERATIONSREVIEWDATE,
        field: PastDueCertificationReportFieldName.OPERATIONSREVIEWDATE,
        width: 110,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTimeLocal(params.data[PastDueCertificationReportFieldName.OPERATIONSREVIEWDATE]) ;
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OVERRIDEFLAG,
        field: PastDueCertificationReportFieldName.OVERRIDEFLAG,
        width: 90,
        cellEditor: 'agSelectCellEditor',
        colId: PastDueCertificationReportFieldName.OVERRIDEFLAG,
        editable: () => (dataStore.checkOperationAccess('OverrideEdit') ? true : false),
        //cellClass: () => (dataStore.checkOperationAccess('OverrideEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OVERRIDEFLAG] === true) {
                return 'Yes';
            }
            if (params.data[PastDueCertificationReportFieldName.OVERRIDEFLAG] === false) {
                return 'No';
            }
             else {
                return params.data[PastDueCertificationReportFieldName.OVERRIDEFLAG];
            }
        },
        cellClass: params => {
            let flagValue = params.data[PastDueCertificationReportFieldName.OVERRIDEFLAG];
            if(flagValue === 'Yes' && dataStore.checkOperationAccess('OverrideEdit')) { 
                let cellBgColor = getColorCodeForOverride(flagValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
            }
            if(flagValue === 'No' && dataStore.checkOperationAccess('OverrideEdit') ) { 
                    return 'cell-editable'
            }
            return params.data[PastDueCertificationReportFieldName.OVERRIDEFLAG]; 
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OVERRIDEBY,
        field: PastDueCertificationReportFieldName.OVERRIDEBY,
        width: 110,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OVERRIDEBY] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.OVERRIDEBY];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.OVERRIDEDATE,
        field: PastDueCertificationReportFieldName.OVERRIDEDATE,
        width: 130,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.OVERRIDEDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTimeLocal(params.data[PastDueCertificationReportFieldName.OVERRIDEDATE]);
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.REASONFOROVERRIDE,
        field: PastDueCertificationReportFieldName.REASONFOROVERRIDE,
        colId: PastDueCertificationReportFieldName.REASONFOROVERRIDE,
        width: 110,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('OverrideEdit') ? false : true),
            onChange: (item, value) =>
            pastDueCertificationReportStore.updateInspectionHistoryComment(
                    item,
                    value,
                    PastDueCertificationReportFieldName.REASONFOROVERRIDE
                )
        },
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.REASONFOROVERRIDE] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.REASONFOROVERRIDE];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.CLEARANCEFLAG,
        field: PastDueCertificationReportHeaderName.CLEARANCEFLAG,
        width: 105,
        cellRendererFramework: PastDueClearnceCellRenderer,
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.CLEARANCEFLAG] === null) {
                return false;
            } else {
                return params.data[PastDueCertificationReportFieldName.CLEARANCEFLAG];
            }
        }
    },
    {
        headerName: PastDueCertificationReportHeaderName.CLEARANCECOMMENTS,
        field: PastDueCertificationReportFieldName.CLEARANCECOMMENTS,
        colId: PastDueCertificationReportFieldName.CLEARANCECOMMENTS,
        width: 105,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('ClearanceCommentEdit') ? false : true),
            onChange: (item, value) =>
            pastDueCertificationReportStore.updateInspectionHistoryComment(
                    item,
                    value,
                    PastDueCertificationReportFieldName.CLEARANCECOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[PastDueCertificationReportFieldName.CLEARANCECOMMENTS] === null) {
                return '';
            } else {
                return params.data[PastDueCertificationReportFieldName.CLEARANCECOMMENTS];
            }
        }
    }
];
