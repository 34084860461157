/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (consignorAXID) => void;
    msg;
}
export class DriverPopup extends React.Component<ConfirmProps> {
    state = {
        consignorPlaceholder: 'Select Driver',
        consignor: ''
    };
    selectedLocation: any;

    public render(): React.ReactNode {
        const { onClose, msg } = this.props;
        const operator = Array.from(new Set(msg.map((item: any) => item.Description)));
        return (
            <div>
                <div className="location">
                    <Row>
                        <Col>
                            <div>
                                Select Driver:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedLocation}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorPlaceholder}
                                        options={operator ? operator : msg}
                                        onChange={this.handleConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={!this.selectedLocation}
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }

    handleChange = event => {
        this.setState({ consignor: event.target.value });
    };

    handleConsignorType = option => {
        this.selectedLocation = option;
        console.log(option);
        this.setState({ consignorPlaceholder: option });
    };

    handleClick = () => {
        this.props.onSuccessClick(this.state.consignorPlaceholder);
        this.props.onClose();
    };
}
