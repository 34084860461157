import { HourlyReportHeaderName, HourlyReportFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
export const hourlyReportColDef: ColDef[] = [
    {
        headerName: HourlyReportHeaderName.TICKETDATE,
        field: HourlyReportFieldName.TICKETDATE,
        valueGetter: (value): string =>
            momenttimezone
                .tz(moment.utc(value.data[HourlyReportFieldName.TICKETDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT)
    },
    {
        headerName: HourlyReportHeaderName.TICKETNUMBER,
        field: HourlyReportFieldName.TICKETNUMBER,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.LEASEOPERATOR,
        field: HourlyReportFieldName.LEASEOPERATOR,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.LEASEOPERATOR] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.LEASEOPERATOR];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.DRIVERNAME,
        field: HourlyReportFieldName.DRIVERNAME,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.DRIVERNAME] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.REGION,
        field: HourlyReportFieldName.REGION,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.REGION];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.TRAILER,
        field: HourlyReportFieldName.TRAILER,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.TRAILER] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.TRAILER];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.CONSIGNOR,
        field: HourlyReportFieldName.CONSIGNOR,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.CONSIGNEE,
        field: HourlyReportFieldName.CONSIGNEE,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.PRODUCT,
        field: HourlyReportFieldName.PRODUCT,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.VOLUMEDELIVERED,
        field: HourlyReportFieldName.VOLUMEDELIVERED,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.VOLUMEDELIVERED] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.VOLUMEDELIVERED];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.WORKINGTIME,
        field: HourlyReportFieldName.WORKINGTIME,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.WORKINGTIME];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.WORKINGTIMEREASON,
        field: HourlyReportFieldName.WORKINGTIMEREASON,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.WAITINGTIME,
        field: HourlyReportFieldName.WAITINGTIME,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.WAITINGTIME];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.WAITINGTIMEREASON,
        field: HourlyReportFieldName.WAITINGTIMEREASON,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.RELATEDVOLUME,
        field: HourlyReportFieldName.RELATEDVOLUME,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.RELATEDVOLUME] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.RELATEDVOLUME];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.COMMENTS,
        field: HourlyReportFieldName.COMMENTS,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.COMMENTS] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.COMMENTS];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.STATUS,
        field: HourlyReportFieldName.STATUS,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.STATUS] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.STATUS];
            }
        }
    },
    {
        headerName: HourlyReportHeaderName.APPROVERCOMMENT,
        field: HourlyReportFieldName.APPROVERCOMMENT,
        valueGetter: params => {
            if (params.data[HourlyReportFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[HourlyReportFieldName.APPROVERCOMMENT];
            }
        }
    }
];
