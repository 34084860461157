import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions } from 'ag-grid-community';
import Select, { components, ActionMeta, ValueType } from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { DailyReportDataStore } from '../stores/dailyreport-data-store';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import { commaFormat, statusType } from '../reports_constants';
import jsPDF from 'jspdf';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface DailyReportProps {
    dailyReportStore: DailyReportDataStore;
}

@observer
export class DailyReportComponent extends Component<DailyReportProps> {
    startDate: any = moment();
    endDate: any;
    from: any = moment().toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    selectedOption: any = [{ value: 'all', label: 'All' }];
    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        };
        dataStore.setModuleName('DailyReport');
    }

    componentDidMount() {
        const { dailyReportStore } = this.props;
        dailyReportStore.init();
    }
    getTickets = () => {
        const { dailyReportStore } = this.props;
        dailyReportStore.getRangeResult(
            this.startDate.toISOString().slice(0, 10),
            this.endDate.toISOString().slice(0, 10)
        );
    };

    render(): React.ReactNode {
        const {
            dailyReportStore: { isDisabled }, dailyReportStore
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.exportPdf}
                                        disabled={isDisabled()}
                                    >
                                        {ReportsButton.PDF}
                                    </CustomButton>
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true} disabled={isDisabled()}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <ReactDayPickerInput
                                _dataStore={this.props.dailyReportStore}
                                showOneDay={true}
                            ></ReactDayPickerInput>
                            <div className="form-inline padding-bottom">
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={dailyReportStore.selectedOption}
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        placeholder="SelectStatus"
                                        options={statusType}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                        <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Estimated Volume Sum : {commaFormat(this.props.dailyReportStore.volumeSum.volumeDelivered)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Actual Volume Sum : {commaFormat(this.props.dailyReportStore.volumeSum.actVolume)}
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('DailyReport_filter');
        this.props.dailyReportStore.agGridService.setAllFilters(filters);
    }

    handleDataTypeChange = option => {
        const { dailyReportStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            status = Array.from(new Set(option.map((item: any) => item.value)));
            if (status.includes('all') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'all') option.splice(index, 1);
                });
            }
            dailyReportStore.selectedOption = option;
            this.setState({selectedOption : option});
        } else {
            dailyReportStore.selectedOption = [{ value: 'all', label: 'All' }];
            this.setState({selectedOption : [{ value: 'all', label: 'All' }]});
            status = Array.from(new Set(statusType.map((item: any) => item.value)));
        }
        dailyReportStore.statusFiltered(status);
    };

    handleRefresh = () => {
        const { dailyReportStore } = this.props;
        dailyReportStore.isRefreshed = true;
        if(dailyReportStore.selectedOption.length === 1 && dailyReportStore.selectedOption[0].value === 'all') {
            if(dailyReportStore.startDate && dailyReportStore.endDate){
            dailyReportStore.getRangeResult(
                dailyReportStore.startDate,
                dailyReportStore.endDate
            );
            }
            else {
            dailyReportStore.getDailyReports();
            }
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    if(dailyReportStore.startDate && dailyReportStore.endDate){
            dailyReportStore.getRangeResult(
                dailyReportStore.startDate,
                dailyReportStore.endDate
            );
            }
            else {
            dailyReportStore.getDailyReports();
            }
                },
                '',
                'Status selection will be lost. Do you want to refresh?'
            );
        }
    }
    createHeaderCell(col) {
        let headerCell: any = {};

        let headerName: any = col.colDef.headerName;

        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }

    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }

    private exportPdf = (): void => {
        const {
            dailyReportStore: { agGridService }
        } = this.props;

        const data: any = agGridService.getNodes();
        const columns: any = agGridService.getColumns();
        let columnsToExport: any = [];
        let columnHeader: any = [];
        let rowsToExport: any = [];
        columns?.getAllDisplayedColumns().forEach(col => {
            console.log(col);
            if (!['Power_Unit_Identifier', 'Region'].includes(col.getColId())) {
                let headerCell: any = this.createHeaderCell(col);
                columnsToExport.push(headerCell);
                columnHeader.push(headerCell.text);
            }
        });
        data.forEachNodeAfterFilterAndSort(node => {
            console.log(node);
            let colsToBeFormatted: any = ['ESTIMATED VOLUME', 'ACTUAL VOLUME']
            let rowToExport = columnsToExport.map(({ colId, text }) => {
                let cellValue = colsToBeFormatted.includes(text) ? commaFormat(data.getValue(colId, node)) : data.getValue(colId, node);
                let tableCell = this.createTableCell(cellValue, colId);
                console.log(tableCell);
                return tableCell;
            });
            rowsToExport.push(rowToExport);
        });
        console.log(columnsToExport, rowsToExport, columnHeader);
        // data.rowModel.rowsToDisplay.forEach((x, _ind) => {
        //     this.data.push(x.data);
        // });
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = TitlePDF.DailyReport;
        const theme: any = 'grid';
        const content = {
            theme: theme,
            startY: 50,
            head: [columnHeader],
            body: rowsToExport
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content);
        doc.save(TitlePDF.DailyReport + '.pdf');
    };
    
    private exportCsv = () => {
        const csvData: any = this.props.dailyReportStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        for (let i = 0; i < data.length; i++) {

        data[i]['Load_Date'] =
        data[i]['Load_Date'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['Load_Date']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['TicketDate'] =
        data[i]['TicketDate'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['CompletedDate'] =
        data[i]['CompletedDate'] === null
                      ? ''
                      : momenttimezone
                            .tz(moment.utc(data[i]['CompletedDate']), AppConstant.MST_TIME_FORMATTER)
                            .format(AppConstant.DATE_TIME_FORMAT);        
        }
        let str = JSON.stringify(data);
        str = str.replace(/\"TicketDate\":/g, '"Ticket Creation Date":');
        str = str.replace(/\"Load_Date\":/g, '"Load Date Time":');
        str = str.replace(/\"CompletedDate\":/g, '"Completed Date Time":');
        str = str.replace(/\"Driver_Name\":/g, '"Driver Name":');
        str = str.replace(/\"CompanyName\":/g, '"Carrier Company":');
        str = str.replace(/\"Power_Unit_Identifier\":/g, '"Power Unit Identifier":');
        str = str.replace(/\"Estimated_Vol\":/g, '"Estimated Volume":');
        str = str.replace(/\"Actual_Vol\":/g, '"Actual Volume":');
        const ws = XLSX.utils.json_to_sheet(JSON.parse(str));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.DailyReport);
    };

    private getGridConfig(): GridOptions {
        const {
            dailyReportStore: { DuplicateList, agGridService }
        } = this.props;
        const { dailyReportStore } = this.props;

        const rowData = DuplicateList;
        const columnDefs = dailyReportStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.dailyReportStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName()+'_filter', this.props.dailyReportStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        
        this.props.dailyReportStore.getVolumeSum(data);
    };
}
