import { ToastService } from '../components/custom-toast/toast-service';
import { LoaderService } from '../components/loader/loader-service';
import { ConfirmService } from '../components/confirm/confirm-service';

export class UiService {
    toastService = new ToastService();
    loaderService = new LoaderService();
    confirmService = new ConfirmService();
}

export const uiService = new UiService();
