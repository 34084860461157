import React, { Component } from 'react';
import { CompanyDataStore } from '../stores/company-data-store';
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import './_master-data.scss';
import { CompanyManagementComponent } from 'features/mapping/components/company-managment-component';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { companyStore } from '../stores';
import { dataStore } from 'features/common/stores';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { MappingDataStore } from 'features/mapping/store/mapping-store';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
/* eslint-disable */
interface CompanyMasterDataProps {
    companyStore: CompanyDataStore;
    mappingDataStore?: MappingDataStore;
}

interface CompanyMasterDataState {
    selectedID: number;
    selectedCompanyName: string;
    child?: any;
}
@inject('mappingDataStore')
@observer
export class CompanyMasterDataComponent extends Component<CompanyMasterDataProps, CompanyMasterDataState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedID: 0,
            selectedCompanyName: ''
        };
        this.saveRegion = this.saveRegion.bind(this);
        dataStore.setModuleName('CompanyManagement');
    }
    childRef = React.createRef<CompanyManagementComponent>();
    getData = selectedID => {
        if (!this.childRef.current) {
            return;
        }
        this.childRef.current.getData(selectedID);
    };

    componentDidMount() {
        this.props.companyStore.init();
    }

    render(): React.ReactNode {
        const { selectedID } = this.state;
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="master-data__action">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                    </Col>
                                </Row>
                                {dataStore.checkOperationAccess('Save') && (
                                    <Row>
                                        <Col className="mt-3 mt-sm-0">
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                disabled={this.state.selectedID === 0 && Object.keys(companyStore.cellValueChangeMap).length === 0}
                                                onClick={this.saveRegion}
                                            >
                                                {' '}
                                                Save
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={Object.keys(companyStore.cellValueChangeMap).length === 0}
                                            >
                                                Reset
                                            </CustomButton>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>

                    {selectedID > 0 && (
                        <Row>
                            <Col>{this.renderMappingComponent()}</Col>
                        </Row>
                    )}
                </Container>
            </div>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('CompanyManagement_filter');
        this.props.companyStore.agGridService.setAllFilters(filters);
    }
    
    handleRefresh = () => {
        const { companyStore, mappingDataStore } = this.props;
        if(Object.keys(companyStore.cellValueChangeMap).length === 0 && mappingDataStore?.isMappingEdited === false) {
            companyStore.init();
            if(mappingDataStore) mappingDataStore.isMappingEdited = false
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    companyStore.init();
                    if(mappingDataStore) mappingDataStore.isMappingEdited = false
                    companyStore.cellValueChangeMap = {};
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    saveRegion = async () => {
        const { mappingDataStore, companyStore } = this.props;
        if (this.state.selectedID > 0 && mappingDataStore !== undefined) {
            mappingDataStore.objCompanyMappingToSave.BaseID = this.state.selectedID;
            if (!companyStore.userID || companyStore.userID === 0 ) {
                await companyStore.accountStore.getLoggedInUserDetailsIfUserIdZero(companyStore.accountStore.userName).then(() => {
                    companyStore.userID = companyStore.accountStore.getUserID();
                });
                if (!companyStore.userID || companyStore.userID === 0 ) {
                    return;
                }
            }
            mappingDataStore.objCompanyMappingToSave.CreatedBy = companyStore.userID;

            mappingDataStore?.saveCompanyMapping(mappingDataStore.objCompanyMappingToSave).then(() => {
                this.getData(this.state.selectedID);
                mappingDataStore?.setIsUpdated(false);
            });
        }
        if(mappingDataStore) mappingDataStore.isMappingEdited = false
        if(Object.keys(companyStore.cellValueChangeMap).length > 0) {
            companyStore.saveVendorData();
        }
    };

    handleReset = (): void => {
        const { companyStore } = this.props;
        companyStore.reset();
    };

    renderMappingComponent(): React.ReactNode {
        if (this.state.selectedID > 0 && Object.keys(companyStore.cellValueChangeMap).length === 0) {
            this.getData(this.state.selectedID);
            window.scrollTo(0, 500);
        }
        return (
            <CompanyManagementComponent
                ref={this.childRef}
                userId={this.props.companyStore.userID}
                companyID={this.state.selectedID}
                companyName={this.state.selectedCompanyName}
            />
        );
    }
    onCellclicked = (event: CellClickedEvent): void => {
        const { CompanyName, ID } = event.node.data;
        const { mappingDataStore, companyStore } = this.props;
        let raColumns = ['RAAccountNumber', 'RACompanyName']
        if(!raColumns.includes(event.column.getColId())){
            this.setState({ selectedID: ID, selectedCompanyName: CompanyName });
            if(Object.keys(companyStore.cellValueChangeMap).length > 0) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () => {
                        this.saveRegion();
                        // companyStore.saveVendorData();
                        companyStore.cellValueChangeMap = {};
                    },
                    '',
                    AppConstant.CARRIER_SAVE_CONFIRMATION
                );
            }
            if(mappingDataStore?.isMappingEdited === true) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () => {
                        this.saveRegion();
                        // companyStore.saveVendorData();
                        companyStore.cellValueChangeMap = {};
                    },
                    '',
                    AppConstant.CARRIER_SAVE_CONFIRMATION
                );
            }
        } else if(raColumns.includes(event.column.getColId())){
            companyStore.getRAVendorDetails(event.node.data, event.column.getColId())
        }
    };

    private getGridConfig(): GridOptions {
        const {
            companyStore: { companyList, agGridService, getColDef }
        } = this.props;
        const rowData = companyList;
        const columnDefs = getColDef();
        const onCellClicked = this.onCellclicked;

        const companyMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked
        };
        return agGridService.getGridConfig(companyMaintenanceGridParams);
    }
}
