import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { PastDueDateResponse, PastDueViewModel } from '../domains/model';

export class PastDueCertificationReportDataService {
    constructor(private restApi: RestApiService) {}
    async getPastDueResponse(): Promise<ResponseDataType<PastDueDateResponse[]>> {
        return this.restApi.postWithToken<PastDueDateResponse[]>(Config.getPastDueForTrailers);
    }
    async getPastDueModel(): Promise<PastDueViewModel> {
        const pastDuePromise = this.getPastDueResponse();
        const [{ data: pastDueData }] = await Promise.all([
            pastDuePromise
        ]);
        return {
            pastDueData
        };
    }
    async createUpdatePastOverdueforTrailers(reqbody: PastDueDateResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.createUpdatePastOverdueforTrailers, reqbody, {});
        return data['Data'];
    }
     async getPastDueReport(reqbody): Promise<PastDueDateResponse[]> {
        const { data } = await this.restApi.postWithToken<PastDueDateResponse[]>(
            Config.getPastDueForTrailers,
            reqbody,
            {}
        );
        return data['Data'];
    }
}

export const pastDueCertificationReportDataService = new PastDueCertificationReportDataService(restApiService);
