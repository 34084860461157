import { TrailerResponse } from "../domains/model";
import { TrailerFieldName } from "../domains/enum";
import { AppConstant } from "app_constant";
import { CommonUtils } from "shared/services/common-utils";

export class TrailerValidationService {
    
    validateUpdateRequest(updatedList: TrailerResponse[], initialList: TrailerResponse[]) {
        
        let errorMessage = '';
        let regex = /^[0-9]*\.?([0-9]{1,3})$/;
        updatedList.forEach(item => {
            if(CommonUtils.checkIfFieldIsNotEmpty(item.WaterCapacityLead) && !regex.test(item.WaterCapacityLead.replace(',',''))) {
                errorMessage = `Invalid ${TrailerFieldName.WATERCAPACITYLEAD}. Please enter only integer or decimal values (upto 3 decimal places)`;
                    return;
            }
            if(CommonUtils.checkIfFieldIsNotEmpty(item.WaterCapacityPup) && !regex.test(item.WaterCapacityPup.replace(',',''))) {
                errorMessage = `Invalid ${TrailerFieldName.WATERCAPACITYPUP}. Please enter only integer or decimal values (upto 3 decimal places)`;
                    return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[TrailerFieldName.TRACKERNUMBER])) {
                const isTrackerNumberDuplicate = initialList.find(a => a.TrackerNumber == item.TrackerNumber && a.TrailerNumber != item.TrailerNumber);
                if (isTrackerNumberDuplicate) {
                    errorMessage = `${TrailerFieldName.TRACKERNUMBER} must be unique`;
                    return;
                }
                if (CommonUtils.checkFieldMaxLength(20, item[TrailerFieldName.TRACKERNUMBER])) {
                    errorMessage = `${TrailerFieldName.TRACKERNUMBER}  is allowed upto 20 characters`;
                    return;
                }
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[TrailerFieldName.VIN])) {
                const isVinDuplicate = initialList.find(a => a.VIN == item.VIN && a.TrailerNumber != item.TrailerNumber);
                if (isVinDuplicate) {
                    errorMessage = `${TrailerFieldName.VIN} must be unique`;
                    return;
                }
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[TrailerFieldName.YEAR])) {
                const isYearAllowed = item.Year >= AppConstant.minYearAllowed.toString() && item.Year <= AppConstant.currentYear.toString();
                if (!isYearAllowed) {
                    errorMessage = `${TrailerFieldName.YEAR} must be between ${AppConstant.minYearAllowed} and ${AppConstant.currentYear}`;
                    return;
                }
            }
        });
        return errorMessage;
    }
}

export const trailerValidationService = new TrailerValidationService();