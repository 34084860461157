import * as React from 'react';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import './_confirm.scss';

export interface ConfirmProps {
    onSuccessClick: (email) => void;
    onClose: () => void;
    data;
    selectedRole;
}
export class UserRolePopup extends React.Component<ConfirmProps> {
    signedUpRole = (this.props.selectedRole) ? (this.props.selectedRole === 'Owner') ? 'Owner/Driver': (this.props.selectedRole === 'CarrierOwner') ? 'Carrier Company Admin' : this.props.selectedRole : '';
    state = {
        role: this.signedUpRole ? this.signedUpRole: ''
    };
    role = '';
    public render(): React.ReactNode {
        const { onClose, data } = this.props;
        return (
            <div className="email">
                <div>Select Role</div>
                {data.map(X => {
                    const f1 = a => t => this.selectRole(X);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <>
                            <div
                                className= {this.state.role === X.Name ? "roleactive" : "roleInactive"}
                                style={{
                                    padding: '10px 10px'
                                }}
                                onClick={f1(X)}
                            >{X.Name}
                            </div>
                        </>
                    );
                })}

                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleSend}>
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Close
                    </CustomButton>
                </div>
            </div>
        );
    }
    selectRole(selectedRole) {
        this.role = selectedRole.Name;
        this.setState({ role: selectedRole.Name });
    }

    private handleSend = () => {
        if (this.state.role !== '') {
            this.props.onSuccessClick(this.state.role);
            this.props.onClose();
        }
    };
}
