import { restApiService } from 'shared/services';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { RejectedLoadResponse, RejectedDateRange } from '../domains/model';
import { TabletResponse } from 'features/data_management/domains/tablet-modal-response';
import { OperatorResponse } from 'features/data_management/domains/location-management/model';
import { ConfigResponseModel, ResponseDataType } from 'shared/types/shared';
import { CarrierCompany, TrailerResponse } from 'features/data_management/domains/model';
import { TicketManagementConstant } from 'features/operations-portal/constants/ticket-management-constant';

export class SearchTicketsDataService {
    constructor(private restApi: RestApiService) {}

    async getSearchTickets(reqbody: any): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.getSearchTickets, reqbody, {});
        return data['Data'];
    }

    async getCarrierCompanyData(): Promise<ResponseDataType<CarrierCompany[]>> {
        const { data } = await this.restApi.postWithToken<CarrierCompany[]>(Config.getCarrierCompanyData);
        return data['Data'];
    }

    async getLocation( reqbody = {}): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getLocationMappingInfo, reqbody, {});
        return data['Data'];
    }

    async getOperator( reqbody = {}): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getOperator, reqbody, {});
        return data['Data'];
    }
    async getTrailerResponse(): Promise<ResponseDataType<TrailerResponse[]>> {
        const { data } = await this.restApi.postWithToken<TrailerResponse[]>(Config.getTrailer, { Version: 1 });
        return data['Data'];
    }
    async updateSearchTickets(reqbody): Promise<void> {
        const { data } = await this.restApi.post<void>(Config.updateSearchTickets, reqbody, {});
        return data['Data'];
    }

    async getTicketStatus() {
        const { data } = await this.restApi.postWithToken<void>(Config.getBaseValueByClass, {
            CODE: TicketManagementConstant.TICKET_STATUS
        });
        return data['Data'];
    }

    async getDriverData() {
        const { data } = await this.restApi.postWithToken<void>(Config.getDrivers)
        return data['Data'];
    }
}

export const searchTicketsDataService = new SearchTicketsDataService(restApiService);
