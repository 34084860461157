import React from 'react';
import './_modal-popup.scss';
import { Button, Modal } from 'react-bootstrap';

interface EmailVerificationPopUpProps {
    onHide: () => void;
    show: boolean;
}

export const EmailVerificationPopUpComponent = (props: EmailVerificationPopUpProps) => {
    const { onHide, show } = props;

    return (
        <Modal size="lg" onHide={onHide} show={show}>
            <Modal.Body>
                <div className="modal-popup-header">Registration Successful</div>
                <div>Email verification link has been sent to the registered email address.
                    Please verify email to complete the registeration process.</div>
                <Button className="mt-3 mb-3" onClick={onHide}>
                    OK
                </Button>
                <div className="modal-popup-italic">Please check in the spam folder if email is not noticeable</div>
            </Modal.Body>
        </Modal>
    );
};
