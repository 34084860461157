import React, { Component } from 'react';
import { GridOptions, CellEditingStoppedEvent, CellEditingStartedEvent, CellClickedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from 'features/common/stores';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { locationColDef } from '../domains/location-management/location-col-def';
import { LocationButtons, LocationFieldName } from '../domains/location-management/enum';
import { LocationDataStore } from '../stores/location-data-store';
import { locationStore } from '../stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { agGridService } from 'features/common/services/ag-grid-service';
import { AppConstant } from 'app_constant';
/* eslint-disable */
interface LocationMasterDataProps {
    locationStore: LocationDataStore;
}

@observer
export class LocationMasterDataComponent extends Component<LocationMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('LocationManagement');
    }
    componentDidMount() {
        const { locationStore } = this.props;
        locationStore.init();
    }

    componentWillUnmount() {
        const { locationStore } = this.props;
        locationStore.reset();
    }

    render(): React.ReactNode {
        const {
            locationStore: { isSaveDisabled, isDeleteEnabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {LocationButtons.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                {LocationButtons.RESET}
                                            </CustomButton>
                                            <CustomButton type={CustomButtonType.Submit} onClick={this.handleAddRow}>
                                                {LocationButtons.ADDNEWROW}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {LocationButtons.DELETEITEMS}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.uploadLocationExcel}
                                            >
                                                {LocationButtons.UPLOAD}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }
    exportCsv() {
        agGridService.getNodes()?.exportDataAsCsv({ fileName: dataStore.getCurrentModuleName() });
    }
    componentDidUpdate() {
        const filters = localStorageService.get('LocationManagement_filter');
        if (
            this.props.locationStore.LocationData.length > 0 &&
            agGridService.getColumns()?.getColumn(LocationFieldName.CURRENTLOADSIZE)
        )
            this.props.locationStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        const { locationStore } = this.props;
        locationStore.updateLoadSize();
    };

    private uploadLocationExcel = (): void => {
        const { locationStore } = this.props;
        locationStore.uploadLocationExcel();
    };

    private handleReset = (): void => {
        const { locationStore } = this.props;
        locationStore.reset();
    };

    private handleAddRow = (): void => {
        const { locationStore } = this.props;
        locationStore.addRow();
    };

    private deleteitems = (): void => {
        const { locationStore } = this.props;
        locationStore.deleteRows();
    };

    handleRefresh = () => {
        const { locationStore } = this.props;
        locationStore.isRefreshed = true;
        if(Object.keys(locationStore.cellValueChangeMap).length === 0 && 
            Object.keys(locationStore.dataToBeDeletedID).length === 0) {
            locationStore.cellValueChangeMap = {};
            locationStore.dataToBeDeletedID = [];
            locationStore.getLocationData(); 
            locationStore.updateRow(locationStore.selectedRow)
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    locationStore.cellValueChangeMap = {};
                    locationStore.dataToBeDeletedID = [];
                    locationStore.getLocationData();
                    locationStore.updateRow(locationStore.selectedRow)
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            locationStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            this.props.locationStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            locationStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    onCellClicked = async (event: CellClickedEvent): Promise<void> => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        if (dataStore.checkOperationAccess('Save') && (col == LocationFieldName.CONSIGNEERA || col == LocationFieldName.CONSIGNORRA)){
            locationStore.showRAPopUp(row, col, value, event.data);
        }
    }

    private getGridConfig(): GridOptions {
        const {
            locationStore: { LocationData, agGridService }
        } = this.props;
        const { locationStore } = this.props;
        const rowData = LocationData;
        const columnDefs = locationStore.getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellClicked = this.onCellClicked;

        const LocationManagementGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked
        };
        return agGridService.getGridConfig(LocationManagementGridParams);
    }
}
