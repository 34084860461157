import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';

const openDetail = (id: any, ticketNo:any) => {
    window.open('/otes?id=' + id+'&otesno='+ticketNo, '_blank');
};

export const OTESHistoryCellRenderer = (props: ICellRendererParams) => {    
    return (

            <span style={{color:"blue"}}
            onClick={()=>openDetail(props.data.ID,props.data.OTESID)}>
                {props.data.OTESID ==undefined?'':props.data.OTESID }</span>
    );
};
