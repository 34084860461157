export class AppConstant {
    static currentYear = new Date().getFullYear();
    static minYearAllowed = AppConstant.currentYear - 40;
    static AUTHTOKEN = 'AUTH_TOKEN';
    static LOGINTIME = 'LOGIN_TIME';
    static USERID = 'USER_ID';
    static PLAINSUSEREMAILADDRESS = 'PLAINSUSEREMAILADDRESS';
    static DEFAULT_USER_DETAILS = {
        userName: '',
        roles: [],
        value: '',
        displayName: '',
        expiry: '',
        isLoggedIn: false
    };
    static USER_INFO = 'USER_INFO';
    static GRID_DEFAULT_PAGINATION = 20;
    static DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
    static MST_TIME_FORMATTER='America/Denver';
    static DATE_FORMAT = 'MM/DD/YYYY';
    static API_DATE_FORMAt = 'YYYY-MM-DDTHH:mm:ss';
    static INVALID_EMAIL_ADDRESS = 'Please enter valid email address';
    static EMAIL_ADDRESS_FORMAT = "Please enter email addresses separated by comma(,)";
    static EMAIL_REQUIRED = 'Email is required';
    static FIRST_NAME_REQUIRED = 'First Name is required';
    static PHONE_NUMBER_REQUIRED = 'Phone Number is required';
    static MIN_LENGTH_REQUIRED = 'Minimum length allowed is 1';
    static MAX_LENGTH_REQUIRED = 'Maximum length allowed is 50';
    static LAST_NAME_REQUIRED = 'Last Name is required';
    static INVALID_PHONE_NUMBER = 'Please enter valid number';
    static CARRIER_NAME_REQUIRED = 'Carrier Name is required';
    static USER_TYPE_REQUIRED = 'User Type is required';
    static USER_ROLE_REQUIRED = 'User Role is required';
    static FACILITY_REQUIRED = 'Please select atleast 1 facility';
    static PSACARRIER_REQUIRED = 'Please select carrier';
    static PSA_USER_ROLE = 'PSA_USER_ROLE';
    static PTS_USER_ROLE = 'USER_ROLE';
    static USER_TYPE = 'USER_TYPE';
    static DEFAULT_SELECT_OPTION = { label: 'Select', value: '' };
    static UNAUTHORIZED_USER = 'You are not authorized to access this application';
    static UPLOAD_FILE ='Please upload file';
    static REFRESH_CONFIRMATION = 'Unsaved changes / selection will be lost. Do you want to refresh ?';
    static ADD_TICKETS = 'Separate multiple tickets by pressing Enter to a maximum of 10 tickets';
    static MAX_TICKETS = 'Maximum 10 tickets can be searched at a time.';
    static DUPLICATE_TICKETS = 'Ticket already added.';
    static RESIZE_LOADS = 'Are you sure you want to resize the loads for this nomination?';
    static RA_CONFIG_TYPE = 'RA_CONFIG_TYPE';
    static CARRIER_SAVE_CONFIRMATION = 'Unsaved changes will be lost. Do you want to save the changes ?';
}
