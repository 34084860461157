import React, { Component } from 'react';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Col, Form, Button } from 'react-bootstrap';
import { SignUp } from '../models/signup';
import { NON_PLAINS, DRIVER, OWNER, CARRIERCOMPANY, ADMIN, DISPATCHER } from '../account_constant';
import { BaseConfigValue, Facility, PSACarrier } from 'shared/types/shared';
import { ReactFromSelect } from 'shared/components/react-form-select/react-form-select';
import { inject, observer } from 'mobx-react';
import { accountStore } from '../stores';

interface SignUpFormComponentProps {
    initialValues: any;
    validationSchema: any;
    phoneRegex: RegExp;
    handleSubmit: (signUp: SignUp) => void;
}

interface SignUpFormComponentState {
    userType: string;
    userTypes: BaseConfigValue[];
    userRoles: BaseConfigValue[];
    facilities: Facility[];
    psaCarrier: PSACarrier[];
    userRole: string;
}

@inject('accountStore')
@observer
export class SignUpFormComponent extends Component<SignUpFormComponentProps, SignUpFormComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            userType: "",
            userTypes: [],
            userRoles: [],
            facilities: [],
            psaCarrier: [],
            userRole: "",
        }
    }

    componentDidMount() {
        if (this.state.userTypes.length == 0) {
            accountStore.getUserTypes().then(res => {
                this.setState({ userTypes: res.data['Data'] });
            })
        }
    }

    render(): React.ReactNode {
        return this.renderForm();
    }

    renderForm(): React.ReactNode {
        const { validationSchema, initialValues } = this.props;
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
                render={this.renderFormFields}
            />
        );
    }

    handleSelectionChange(event, formikHandleChange) {
        formikHandleChange(event);
        const { selector, value } = event.selectedOptionProp;
        if (selector == 'UserType') {
            this.setState(
                { userType: value.Name },
                () => {
                    if(this.state.userType === NON_PLAINS){
                    const promise =accountStore.getPsaUserRoles()
                    promise.then((response) => { this.setState({ userRoles: response.data['Data'] }) });}
                    else{
                        let userRoles: BaseConfigValue[] = [];
                        const promise =accountStore.getPtsUserRoles()
                        promise.then((response) => {
                                response.data['Data'].map((item) => {
                                    if(item.Name && item.Name === DRIVER) {
                                        userRoles[0] = item
                                    } else if(item.Name && item.Name === OWNER) {
                                        userRoles[1] = item
                                    } else if(item.Name && item.Name === CARRIERCOMPANY){
                                        userRoles[2] = item
                                    } else {
                                        userRoles[3] = item
                                    }
                                })
                            this.setState({ userRoles: userRoles });
                        }); 
                    }
                    if (this.state.userType === NON_PLAINS) {
                        let facilityList: Facility[] = [];
                        accountStore.getFacilities().then(res => {
                            for (const key in res) {
                                facilityList.push({ ID: key, Name: res[key] });
                            }
                            this.setState({ facilities: facilityList });
                        });
                    }
                });
        }
        if (selector == 'UserRole') {
            this.setState(
                { userRole: value.Name },
                () => {
                    if (this.state.userRole === DRIVER) {
                        let psaCarrierList: PSACarrier[] = [];
                        accountStore.getPSACarrier().then(res => {
                            for (const key in res) {
                                psaCarrierList.push({ ID: key, Name: res[key] });
                            }
                            this.setState({ psaCarrier: psaCarrierList });
                        });
                    }
                });
        }
    }

    getDisplayValueForUserRole = (role) => {
        if (this.state.userType === NON_PLAINS) {
            return role;
        } else {
            if (role === DRIVER) {
                return `${DRIVER} - You are DRIVING the tractor for a Carrier Company`;
            } else if (role === OWNER){
                return `Owner – You are the OWNER of the Carrier Company`;
            } else if (role === CARRIERCOMPANY) {
                return `${ADMIN} – You are an ADMINISTRATOR at the Carrier Company`;
            } else if (role === DISPATCHER) {
                return `${DISPATCHER} – You are THIRDPARTY Dispatcher at the Carrier Company`;
            } else {
                return role;
            }
        }
    }

    renderFormFields = ({ errors, touched, isSubmitting, values, handleChange, handleBlur }): React.ReactNode => {
        const formattedPhoneNumber = values.CellPhoneNo.replace(this.props.phoneRegex, "($1) $2 $3");
        return (
            <FormikForm>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="LoginName"
                        placeholder="Email Address"
                        type="text"
                        disabled={true}
                        className={`form-control ${(errors.LoginName && touched.LoginName ? ' is-invalid' : '')}`}
                    />
                    <ErrorMessage name="LoginName" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="FirstName"
                        placeholder="First Name"
                        type="text"
                        className={`form-control ${(errors.FirstName && touched.FirstName ? ' is-invalid' : '')}`}
                    />
                    <ErrorMessage name="FirstName" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="LastName"
                        placeholder="Last Name"
                        type="text"
                        className={`form-control ${(errors.LastName && touched.LastName ? ' is-invalid' : '')}`}
                    />
                    <ErrorMessage name="LastName" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="CellPhoneNo"
                        placeholder="Phone Number"
                        type="text"
                        value={formattedPhoneNumber}
                        className={`form-control ${(errors.CellPhoneNo && touched.CellPhoneNo ? ' is-invalid' : '')}`}
                    />
                    <ErrorMessage name="CellPhoneNo" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                    <ReactFromSelect
                        id="UserType"
                        name="UserType"
                        onBlur={handleBlur}
                        options={this.state.userTypes}
                        placeholder="Will you be pulling a Plains Trailer?"
                        onChange={event => { this.handleSelectionChange(event, handleChange); values.Facility = []; }}
                        mappingFunc={(option) => ({ selectedValue: option.Name, displayValue: option.Name == 'Plains' ? 'Yes' : 'No'})}
                        className={`form-control ${(errors.UserType && touched.UserType ? ' is-invalid' : '')}`}
                    />
                    <ErrorMessage name="UserType" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                    {
                        this.state.userType !== "" && <ReactFromSelect
                            id="UserRole"
                            name="UserRole"
                            onBlur={handleBlur}
                            options={this.state.userRoles}
                            placeholder="Select User Role"
                            onChange={event => this.handleSelectionChange(event, handleChange)}
                            mappingFunc={(option) => ({ selectedValue: option.Name, displayValue: this.getDisplayValueForUserRole(option.Name) })}
                            className={`form-control  ${(errors.UserRole && touched.UserRole ? ' is-invalid' : '')}`}
                        />
                    }
                    <ErrorMessage name="UserRole" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                    {this.state.userType == NON_PLAINS &&
                        <ReactFromSelect
                            jsonValue={true}
                            id="Facility"
                            name="Facility"
                            multiple={true}
                            options={this.state.facilities}
                            onBlur={handleBlur}
                            placeholder="Select Facility"
                            onChange={handleChange}
                            mappingFunc={(option) => ({ selectedValue: option.ID, displayValue: option.Name })}
                            className={`form-control  ${(errors.Facility && touched.Facility ? ' is-invalid' : '')}`}
                        />}
                    <ErrorMessage name="Facility" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group controlId="validationFormik01">
                    {this.state.userType == NON_PLAINS && this.state.userRole == DRIVER &&
                        <ReactFromSelect
                            id="PSACarrier"
                            name="PSACarrier"
                            multiple={false}
                            options={this.state.psaCarrier}
                            onBlur={handleBlur}
                            placeholder="Select Carrier"
                            onChange={handleChange}
                            mappingFunc={(option) => ({ selectedValue: option.ID, displayValue: option.Name })}
                            className={`form-control  ${(errors.PSACarrier && touched.PSACarrier ? ' is-invalid' : '')}`}
                        />}
                    <ErrorMessage name="PSACarrier" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    {!(this.state.userType == NON_PLAINS && this.state.userRole == DRIVER) && <Field
                        name="CarrierName"
                        placeholder="Carrier Name"
                        type="text"
                        className={`form-control ${(errors.CarrierName && touched.CarrierName ? ' is-invalid' : '')}`}
                    />}
                    <ErrorMessage name="CarrierName" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group className="div-center-align" as={Col} controlId="validationFormik01">
                    <Button  className="div-button-center-align" type="submit" disabled={isSubmitting}>
                        SIGN UP
                    </Button>
                </Form.Group>
            </FormikForm >
        );
    }

    handleSubmit = (signUp: SignUp, action): void => {
        action.setSubmitting(false);
        this.props.handleSubmit(signUp);
    };
}
