import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DayPicker from 'react-day-picker';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';

interface SchedulePopupProps extends React.Props<any>  {
    openDialog: boolean;
    onCloseDialog;
    onDayPickerClick;
    selectedDays;
    handleConfirmButton;
    handleCloseButton;
    isDisabled;
}
interface DescriptionPopupProps extends React.Props<any>  {
    open: boolean;
    onClose;
    onChange;
    description;
    confirmButtonClick;
    cancelButtonClick;
    isDisabled;
}
export const SchedulePopup = (props: SchedulePopupProps) => {
    return (
        <Dialog
            open={props.openDialog}
            onClose={props.onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-title">{'Date of Maintainence Requested: '}</DialogContentText>
            <DayPicker onDayClick={props.onDayPickerClick} selectedDays={props.selectedDays? props.selectedDays: []} disabledDays = {{before: new Date()}} />
            </DialogContent>
            <DialogActions>
                <CustomButton type={CustomButtonType.Ok} onClick={props.handleConfirmButton} disabled={props.isDisabled}>
                    Confirm
                </CustomButton>
                <CustomButton type={CustomButtonType.Cancel} onClick={props.handleCloseButton}>
                    Cancel
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};
export const DescriptionPopup = (props: DescriptionPopupProps) => {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Description of Maintainence Requested: '}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="filled-basic"
                        label="Enter Description"
                        variant="filled"
                        fullWidth
                        multiline
                        onChange={props.onChange}
                    />
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        type={CustomButtonType.Ok}
                        onClick={props.confirmButtonClick}
                        disabled={props.isDisabled}
                    >
                        Book Maintainence
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={props.cancelButtonClick}>
                        Cancel
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
