import { RestApiService } from '../../../shared/services/rest-api-service';
import { Config } from 'config';
import { HazardAssessmentRequest } from '../model/hazardAssessmentReport-model';
import { ResponseDataType } from 'shared/types/shared';
import { HazardAssessmentHistoryResponse } from '../model/hazardAssessment-history-model';
export class HazardAssessmentDataService {
    constructor(private restApi: RestApiService) { }


    async getHazardAssessmentReport(reqBody) {
        try {
            const data =  await this.restApi.postWithToken(
                Config.getHazardAssessmentReport,
                reqBody
            );
            return data;
        } catch (e) {
            return [];
        }
    }
}