import React, { Component } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Col, Container, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import { DispatchBoardDataStore } from '../store/dispatch-board-store';
import './style.scss';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import WeekDayHeader from './week-day-header';
import moment from 'moment';
import { dateUtils } from 'shared/services/date-utils';
import { observable } from 'mobx';
import Select from 'react-select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { DispatchBoardDataRequest } from '../model/model';
import { dispatchBoardDataStore } from '../store';
import { dataStore } from 'features/common/stores';
import { dispatchBoardColDef } from './dispatch-board-popup-col-def';
interface DispatchDetailsPopupProps {
    dispatchBoardDataStore: DispatchBoardDataStore;
    month: any;
    year: any;
    region: any;
    companyData: any;
    locationData: any;
}

@inject('dispatchBoardDataStore')
@observer
export class DispatchDetailsPopupComponent extends Component<DispatchDetailsPopupProps> {
    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        };
        dataStore.setModuleName('DispatchBoard');
    }
    @observable selectedLocation: any = [];
    @observable selectedCompany: any = [];
    @observable showLoader: boolean = false;

    render(): React.ReactNode {
        const { companyData, locationData, month, year, region } = this.props;
        return (
            <Container fluid>
                <div>
                    <Row></Row>
                    <Row>
                        <div className="react-select-container">
                            Carrier Company :
                            <div className="select-carrier" style={{ minWidth: '220px' }}>
                                <Select
                                    value={this.selectedCompany}
                                    placeholder="Select Carrier Company"
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={true}
                                    options={companyData.filter(company => company.value != ' All')}
                                    onChange={this.handleCarrierCompanyeChange}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        <div className="react-select-container">
                            Location :
                            <div className="select-carrier" style={{ minWidth: '200px' }}>
                                <Select
                                    value={this.selectedLocation}
                                    placeholder="Select Location"
                                    closeMenuOnSelect={false}
                                    closeMenuOnScroll={true}
                                    options={locationData.filter(location => location.value != ' All')}
                                    onChange={this.handleLocationChange}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        {this.showLoader == true && (
                            <div className="alert-overlay">
                                <div style={{ margin: 'auto' }} className="dots-1"></div>{' '}
                            </div>
                        )}
                        <CustomButton
                            type={CustomButtonType.Submit}
                            disabled={this.selectedCompany.length == 0 && this.selectedLocation.length == 0}
                            onClick={() =>
                                this.getDispatchPopupData(
                                    this.selectedCompany,
                                    this.selectedLocation,
                                    month,
                                    year,
                                    region
                                )
                            }
                        >
                            {' '}
                            Search{' '}
                        </CustomButton>
                        <CustomButton
                            type={CustomButtonType.Submit}
                            disabled={this.selectedCompany.length == 0 && this.selectedLocation.length == 0}
                            onClick={() =>
                                this.getDispatchPopupData(
                                    this.selectedCompany,
                                    this.selectedLocation,
                                    month,
                                    year,
                                    region
                                )
                            }
                        >
                            {' '}
                            Refresh{' '}
                        </CustomButton>
                        <ClearButtonComponent disabled={this.isClearAllDisabled()}></ClearButtonComponent>
                    </Row>
                    <Row>
                        <Col>
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('DispatchBoard_Detail_Filters');
        this.props.dispatchBoardDataStore.agGridService.setAllFilters(filters);
    }

    isClearAllDisabled = (): boolean => {
        if (this.props.dispatchBoardDataStore.driverInfo.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    generateDate = date => {
        const { month, year } = this.props;
        return moment(new Date(year, month, date).toISOString()).format('MM/DD/YYYY');
    };

    isSameDate = (date, header) => {
        const { month, year } = this.props;
        return (
            dateUtils.getFormattedMSTDateOnly(date) === dateUtils.getFormattedMSTDateOnly(new Date(year, month, header))
        );
    };

    handleCarrierCompanyeChange = option => {
        if (option && option.length > 0) {
            this.selectedCompany = option;
        } else {
            this.selectedCompany = [];
        }
    };

    handleLocationChange = option => {
        if (option && option.length > 0) {
            this.selectedLocation = option;
        } else {
            this.selectedLocation = [];
        }
    };
    private getDispatchPopupData(selectedCompany, selectedLocation, selectedMonth, selectedYear, selectedRegion) {
        const locations = selectedLocation.map(location => location.value);
        const companies = selectedCompany.map(company => company.ID);
        const requestData: DispatchBoardDataRequest = {
            Type: 'Internal',
            CC: selectedCompany.length == 0 ? 'All' : companies.toString(),
            LocationID: selectedLocation.length == 0 ? 'All' : locations.toString(),
            Month: moment.months()[selectedMonth],
            StartDate: moment(new Date(selectedYear, selectedMonth, 1)).format('YYYY-MM-DD'),
            EndDate: moment(new Date(selectedYear, selectedMonth + 1, 0)).format('YYYY-MM-DD'),
            Year: selectedYear,
            RegionID: selectedRegion
        };
        this.showLoader = true;
        dispatchBoardDataStore.getDispatchBoardDetails(requestData).then(() => {
            this.setState({
                dispatchDetails: this.props.dispatchBoardDataStore.dispatchBoardData,
                openDispatchDetails: true
            });
            this.showLoader = false;
        });
    }
    private getGridConfig(): GridOptions {
        const {
            dispatchBoardDataStore: { driverInfo, agGridService, selectedMonth, selectedYear }
        } = this.props;
        const numOfDays = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        const rowData = driverInfo;
        const columnDefs = dispatchBoardColDef.filter(coldef => coldef.colId < numOfDays);
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            autoGroupColumnDef: {
                headerName: 'Carrier Company',
                field: 'CompanyName',
                width: 85,
                cellRendererParams: {
                    filter: true
                },
                rememberGroupStateWhenNewData: true
            }
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }
}
