import React, { Component } from 'react';
import { SELECT_TYPE_MESSAGE, NO_DATA_AVAILABLE } from '../default-page_constant';
import defaultpageicon from '../../../assets/defaultpageicon.png';
import './_defaultpage.scss';

export class DefaultPageComponent extends Component {
    render(): React.ReactNode {
        return (
            <div className="defaultpage__parent_container">
                <div className="defaultpage__defaultpage">
                    <img className="defaultpage__defaulticon" src={defaultpageicon} alt="DefaultPage" />
                    <label className="defaultpage__nodatafound">{NO_DATA_AVAILABLE}</label>
                    <label className="defaultpage__selectTypeMessage">{SELECT_TYPE_MESSAGE}</label>
                </div>
            </div>
        );
    }
}
