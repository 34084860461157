import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { DateRange, LeaseOperatorResponse } from '../domains/model';
import { uiService } from 'shared/services/ui-service';
import { leaseOperatorColDef } from '../domains/lease-operator-col-def';
import { ReportMessage } from '../domains/enum';
import { LeaseOperatorDataService } from '../services/leaseoperator-data-service';
import { CCUserRoles, ticketStatus } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';

export class LeaseOperatorStore {
    @observable leaseoperatorReports: LeaseOperatorResponse[] = [];
    userID = 0;
    userName = '';
    @observable dailyBackupReport: LeaseOperatorResponse[] = [];
    leaseoperator: any[] = [];
    leaseOperatorName: any[] = [];
    @observable selectedOption: any = [{ value: 'all', label: 'All' }];
    @observable selectLeaseOperator: any = !this.checkCCLogin() ? [{ value: 'All', label: 'All' }] : [{ value: ' ', label: ' ' }];
    fileName: any;
    data: any[] = [];
    usermail: any = '';
    isRefreshed: boolean = false;
    @observable volumeSum:any = {
        volDeliveredSum : 0,
        totalWaitingTime : 0,
        totalWorkingTime : 0
    }
    @observable errorFlag: boolean = false;
    @observable clearSelection: boolean = false;
    userInfo: any;
    startDate: any;
    endDate: any;
    constructor(
        private leaseoperatorDataService: LeaseOperatorDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.usermail = this.accountStore.userName;
        this.userName = this.accountStore.displayName;
        this.startDate = moment()
            .startOf('month')
            .add(1, 'days')
            .toISOString()
            .slice(0, 10);
        this.endDate = moment()
            .toISOString()
            .slice(0, 10);
        this.getleaseoperatorReports();
    }

    getColDef() {
        return leaseOperatorColDef;
    }

    leaseOperatorFiltered(name) {
       let selectedStatus = Array.from(new Set(this.selectedOption.map((item: any) => item.value)));
       if(selectedStatus.includes('Driver Dispatched')) selectedStatus.push('Dispatch_Pending');
        if(name.length > 0) { 
            name = name[0].value
            if(name == 'All'){
                let reports: any =[];
                if(!selectedStatus.includes('all')){
                    reports = this.dailyBackupReport.filter(element => {
                        return selectedStatus.includes(element.TicketStatus);
                    });
                    this.leaseoperatorReports = reports;
                } else this.leaseoperatorReports = this.dailyBackupReport
    
            }
            else {
                let reports: any =[];
                if (!selectedStatus.includes('all')) {
                    reports = this.dailyBackupReport.filter(element => {
                        console.log(toJS(element),selectedStatus)
                        return name == element.LeaseOperatorName && selectedStatus.includes(element.TicketStatus);
                    });
                } else {
                     reports = this.dailyBackupReport.filter(element => {
                        return name == element.LeaseOperatorName
                    });
                }
                this.leaseoperatorReports = reports;
                if (this.leaseoperatorReports.length == 0) {
                    uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
                }
            }
        } else {
            this.leaseoperatorReports = this.dailyBackupReport;
        }
    }
    statusFiltered(statusList, name) {
        let reports: any = [];
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(ticketStatus.map((item: any) => item.value)));
        }
        const selectedName = name[0].label
        if (!(selectedName == 'All')) {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedName == element.LeaseOperatorName;
            });
        } else {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus);
            });
        }
        this.leaseoperatorReports = reports;
        if (this.leaseoperatorReports.length == 0) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.LEASE_ERROR_MESSAGE))
    async getleaseoperatorReports() {
        let dropdown: any = localStorageService.get('LeaseOperatorReport_Dropdown')
        this.leaseOperatorName = []
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            if(!this.checkCCLogin())
            this.selectLeaseOperator = dropdown[0].CarrierCompany
        } else {
            localStorageService.set('LeaseOperatorReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'CarrierCompany': [{ value: 'All', label: 'All' }]}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            if(!this.checkCCLogin())
            this.selectLeaseOperator = [{ value: 'All', label: 'All' }]
        }
        const reqbody: any = {};
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const leaseoperatorReports = await this.leaseoperatorDataService.getDailyReportwithParams(reqbody);
        this.leaseoperator = Array.from(new Set(leaseoperatorReports.map((item: any) => item.LeaseOperatorName)));
        if(this.leaseoperator.length > 0) this.leaseOperatorName.push({ value: 'All', label: 'All' });
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        if(this.checkCCLogin()){
            if(this.leaseOperatorName.length >= 2)
                this.selectLeaseOperator = [this.leaseOperatorName[1]]
            this.leaseOperatorName = this.selectLeaseOperator
        }
        this.leaseoperatorReports = leaseoperatorReports;
        this.setBackupReport(this.leaseoperatorReports);
        if (this.leaseoperatorReports.length == 0) {
            this.errorFlag = true;
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.errorFlag = false;
        this.isRefreshed = false;
        if (this.leaseoperatorReports.length !== 0) {
        this.leaseOperatorFiltered(this.selectLeaseOperator)
        }
    }
    @computed
    get DuplicateList(): LeaseOperatorResponse[] {
        return toJS(this.leaseoperatorReports);
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.LEASE_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        let dropdown: any = localStorageService.get('LeaseOperatorReport_Dropdown')
        this.leaseOperatorName = []
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            if(!this.checkCCLogin())
            this.selectLeaseOperator = dropdown[0].CarrierCompany
        } else {
            localStorageService.set('LeaseOperatorReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'CarrierCompany': [{ value: 'All', label: 'All' }]}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            if(!this.checkCCLogin())
            this.selectLeaseOperator = [{ value: 'All', label: 'All' }]
        }
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        this.startDate = startDate;
        this.endDate = endDate;
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
            
        }
        const leaseoperatorReports = await this.leaseoperatorDataService.getDailyReportwithParams(reqbody);
        this.leaseoperator = Array.from(new Set(leaseoperatorReports.map((item: any) => item.LeaseOperatorName)));
        if(this.leaseoperator.length > 0) this.leaseOperatorName.push({ value: 'All', label: 'All' });
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        if(this.checkCCLogin()){
            if(this.leaseOperatorName.length >= 2){
                this.selectLeaseOperator = [this.leaseOperatorName[1]]
            }
            this.leaseOperatorName = this.selectLeaseOperator;
        }
        this.leaseoperatorReports = leaseoperatorReports;
        this.setBackupReport(this.leaseoperatorReports);
        if (this.leaseoperatorReports.length == 0) {
            this.errorFlag = true;
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.errorFlag = false;
        this.isRefreshed = false;
        if (this.leaseoperatorReports.length !== 0) {
            this.leaseOperatorFiltered(this.selectLeaseOperator)
        }
    }
    @action
    setBackupReport(reports: LeaseOperatorResponse[]): void {
        this.dailyBackupReport = reports;
    }

    isDisabled = (): boolean => {
        if (this.leaseoperatorReports.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    @Catch(() => {
        errorHandler(ReportMessage.UPLOAD_ERROR);
    })
    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.UPLOAD_ERROR);
    })
    async sendreport(reqbody) {
        const sendreport = await this.leaseoperatorDataService.sendReport(reqbody);
        uiService.toastService.success('Report sent');
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    getVolumeSum(data){
        let volDelivered = 0, waitingtime = 0, workingTime = 0;
        for (let report in data) {
            volDelivered +=  data[report].TicketType === 'Volume' ? data[report].VolumeDelivered : 0;
            waitingtime += data[report].WaitingTime;
            workingTime += data[report].WorkingTime
        }
        this.volumeSum.volDeliveredSum = volDelivered.toFixed(3);
        this.volumeSum.totalWaitingTime = waitingtime.toFixed(2);
        this.volumeSum.totalWorkingTime = workingTime.toFixed(2);
    }
}
