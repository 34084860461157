import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { TabletResponse } from '../domains/tablet-modal-response';
import { CarrierCompany } from '../domains/model';
import { ResponseDataType } from 'shared/types/shared';

export class TabletDataService {
    constructor(private restApi: RestApiService) { }

    async getTabletData(): Promise<TabletResponse[]> {
        const { data } = await this.restApi.postWithToken<TabletResponse[]>(Config.getTabletInfoList);
        return data['Data'];
    }

    async updateTablets(reqbody: TabletResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateTabletInfoList, reqbody, {});
        return data['Data'];
    }

    async getCarrierCompanyData(): Promise<ResponseDataType<CarrierCompany[]>> {
        const { data } = await this.restApi.postWithToken<CarrierCompany[]>(Config.getCarrierCompanyData);
        return data['Data'];
    }
}

export const tabletDataService = new TabletDataService(restApiService);
