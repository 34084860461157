import * as React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faTrash, faSearch, faEdit, faTimes);

export interface ConfirmProps {
    onClose: () => void;
    data;
}
export interface EdoState {
    dataSource: any, responseData: any
}
export class BroadCastPopUp extends React.Component<ConfirmProps, EdoState> {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.data,
            responseData: [],
        };
    }
    public render(): React.ReactNode {
        const { onClose, data } = this.props;
        return (
            <div className="email">
                {data[0].Subject && data[0].Subject !== '' && <div> Broadcast Subject: {data[0].Subject}</div>}
                <div>Broadcast Recipients</div>
                    <div className="d-flex justify-content-between" style={{ backgroundColor: '#ffffff' }}>
                            <div className="p-2" style={{ width: '120%', backgroundColor: '#F6F6F6' }}>
                                <div className=" d-flex justify-content-between search-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Search"
                                        onChange={event => this.handleSearch(event.currentTarget.value)}
                                        className="search-input-textField"
                                    ></input>
                                    <FontAwesomeIcon icon="search" className="search-icon" />
                        </div>
                    </div>
                </div>
                {this.state.dataSource.map(X => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <>
                            <div
                                style={{
                                    padding: '10px 0px',
                                    backgroundColor: 'whitesmoke',
                                    borderBottom: '1px solid grey'
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <span className="spanWidth30"></span>
                                    <span style={{ width: '220px' }}>
                                        <div>{X.Receiver}</div>
                                        {/* <Row> Subject: {X.Subject}</Row> */}
                                        {/* <div> Message: {X.Message}</div> */}
                                    </span>
                                    <span className="spanWidth30">
                                        <Row>Delivered</Row>
                                        <Row>
                                            {X.IsSent || X.IsSent == null ? (
                                                <i
                                                    className="fa fa-check-circle"
                                                    style={{ color: 'green', fontSize: '24px' }}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fa fa-times-circle"
                                                    style={{ color: 'red', fontSize: '24px' }}
                                                ></i>
                                            )}
                                        </Row>
                                    </span>
                                    <span className="spanWidth30">
                                        <Row>Read</Row>
                                        <Row>
                                            {X.IsRead ? (
                                                <i
                                                    className="fa fa-check-circle"
                                                    style={{ color: 'green', fontSize: '24px' }}
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fa fa-times-circle"
                                                    style={{ color: 'red', fontSize: '24px' }}
                                                ></i>
                                            )}
                                        </Row>
                                    </span>
                                </div>
                            </div>
                        </>
                    );
                })}
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Close
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleSearch = value => {
        const { onClose, data } = this.props;
        const { dataSource } = this.state;
            var filteredObj = data.filter(x => x.Receiver.toLowerCase().includes(value.toLowerCase()))
            this.setState({
                dataSource: filteredObj
            });
    };
}
