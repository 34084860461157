import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { hazardAssessmentDataStore, hazardAssessmentHistoryStore } from '../stores';
import { accountStore } from 'features/account/stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { HazardAssessmentHistoryFieldName } from '../model/hazardAssessment-history-enum';
import { action } from 'mobx';
import { dataStore } from 'features/common/stores';

const handleResolvedStatus = (isResolved: boolean, userData: any) => {
        const confirmService = new ConfirmService();
        if (isResolved == false ||  isResolved == null) {
        confirmService.showConfirmDialog(
            () => 
            hazardAssessmentHistoryStore.updateResolved(userData),
            '',
            'Are you sure you want to Resolve? This is uneditable going forward'
        );
    }
    else{
        hazardAssessmentHistoryStore.loadViewModel();
    }
};

export const CurrentStatusCellRenderer = (props: ICellRendererParams) => {
    const { data } = props;
    let disabled = false;
    let checked = false;
   
    let resolved = (data.Resolved);
    if (props.colDef.field === HazardAssessmentHistoryFieldName.RESOLVED) {
        disabled = dataStore.checkOperationAccess('HazardAssessmentResolvedEdit')? resolved === true : true;
        checked = props.data.Resolved === true;
    } 
    return (
        <div className="current-status">
            <input
                type="checkbox"
                id="chk"
                name="chk"
                onClick={(e) => handleResolvedStatus(resolved,props.data)}
                value={data}
                checked={checked}
                disabled={disabled}
                
            />
        </div>
    );
};
