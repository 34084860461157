import { FilterKeyForReferenceData } from '../domains/enum';
import React from 'react';
import { DriverMasterDataComponent } from './driver-master-data-component';
import { TractorMasterDataComponent } from './tractor-master-data-component';
import { ShopMasterDataComponent } from './shop-master-data-component';
import { CompanyMasterDataComponent } from './company-master-data-component';
import { PrinterMasterDataComponent } from './printer-master-data-component';
import { TabletMasterDataComponent } from './tablet-master-data-component';
import { MappingManagementComponent } from 'features/mapping/components/mapping-managment-component';
import { trailerStore, tabletStore, printerStore, companyStore, shopStore, tractorStore, driverStore, locationStore, locationMapingStore, keyFobstore } from '../stores';
import { TrailerMasterDataComponent } from './trailer-master-data-component';
import { DefaultPageComponent } from 'features/common/components/default-page-component';
import { LocationMasterDataComponent } from './location-master-data-component';
import { LocationMapingMasterDataComponent } from './location-maping-master-data-component';
import { KeyfobMasterDataComponent } from './keyfobs-master-data-component';

export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        FilterKeyForReferenceData.Select,
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.LocationManagement,
        () => (
            <React.Fragment>
                <LocationMasterDataComponent locationStore={locationStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.LocationMaping,
        () => (
            <React.Fragment>
                <LocationMapingMasterDataComponent locationMapingStore={locationMapingStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.TrailerManagement,
        () => (
            <React.Fragment>
                <TrailerMasterDataComponent trailerStore={trailerStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.DriverManagement,
        () => (
            <React.Fragment>
                <DriverMasterDataComponent driverStore={driverStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.TractorManagement,
        () => (
            <React.Fragment>
                <TractorMasterDataComponent tractorStore={tractorStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.ShopManagement,
        () => (
            <React.Fragment>
                <ShopMasterDataComponent shopDataStore={shopStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.CompanyManagement,
        () => (
            <React.Fragment>
                <CompanyMasterDataComponent companyStore={companyStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.PrinterManagement,
        () => (
            <React.Fragment>
                <PrinterMasterDataComponent printerStore={printerStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.TabletManagement,
        () => (
            <React.Fragment>
                <TabletMasterDataComponent tabletDataStore={tabletStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.KeyFobManagement,
        () => (
            <React.Fragment>
                <KeyfobMasterDataComponent keyfobsStore={keyFobstore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.RegionMapping,
        () => (
            <React.Fragment>
                <MappingManagementComponent />
            </React.Fragment>
        )
    ]
]);
