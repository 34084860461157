import React, { Component } from 'react';
import { Document, Page, Text,Image, View, StyleSheet } from '@react-pdf/renderer';
import { printDisclaimers, printLabels } from '../constants/ticket-management-constant';
import { TicketVolOrHourly } from '../constants/ticket-enum';
import logo from '../../../assets/pmc-logo.png'
import { dateUtils } from 'shared/services/date-utils';
import { TicketModel } from '../model/ticket-response-model';
import { CommonUtils } from 'shared/services/common-utils';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    section: {
        margin: 10,
        padding: 15,
        flexGrow: 1
    },
    pdfContainer: {
        fontSize: 9,
        fontWeight: 'light',
        padding: 0,
        marginTop: 20,
        marginLeft: 50,
        marginRight: 50
    },
    logo:{
        maxHeight: 50,
        width: 180,
        marginLeft: 150,
        paddingBottom: 3,
        paddingTop: 3,
    },
    detailsFooter: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5
    },
    detailsFooterWithNoPadding: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5
    },
    combinedRows: {
        display: 'flex',
        flexDirection: 'column',
        padding: 3
    },
    printViewContainer: {
        justifyContent: 'space-between',
        paddingBottom: 6
    },
    content: {
        minWidth: 220,
        maxWidth: 220,
        border: 1,
        borderStyle: 'solid',
        padding: 3
    },
    contentright: {
        fontWeight: 'light',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        border: 1,
        borderStyle: 'solid',
        padding: 3,
    },
    printViewAddress: {
        alignContent: 'flex-start',
        fontWeight: 'light',
        paddingBottom: 2,
        paddingTop: 2,
        paddingLeft: 5
    },
    disclaimerText: {
        fontWeight: 'light',
        maxWidth: 440,
        paddingLeft: 5,
        paddingTop: 5,
    },
    contentStart: {
        fontWeight: 'light',
        paddingRight: 50,
        minWidth: 130,
        maxWidth: 130,
        border: 1,
        borderRadius: 1,
        padding: 3,
        paddingLeft: 5
    },
    contentOne: {
        fontWeight: 'light',
        paddingRight: 50,
        minWidth: 110,
        border: 1,
        borderRadius: 1,
        padding: 3,
    },
    contentTwo: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 110,
        minWidth: 110,
        border: 1,
        borderRadius: 1,
        flex: 1,
        flexDirection: 'row',
        padding: 3,
    },
    contentThree: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 120,
        border: 1,
        borderRadius: 1,
        flex: 1,
        flexDirection: 'row',
        padding: 3,
        paddingLeft: 5
    },
    contentFour: {
        fontWeight: 'light',
        maxWidth: 100,
        border: 1,
        borderRadius: 1,
        flex: 1,
        padding: 3,
        flexDirection: 'row',
        paddingLeft: 5
    },
    contentEnd: {
        fontWeight: 'light',
        maxWidth: 133,
        minWidth: 133,
        width: 133,
        border: 1,
        borderRadius: 1,
        padding: 3,
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 5
    },
    contentMiddle: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 177,
        minWidth: 177,
        border: 1,
        borderRadius: 1,
        padding: 3,
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 5
    },
});
interface PDFComponentProps {
    selectedTickets: TicketModel;
}
const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};
const commaFormat = (number) => {
    return number != null && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
const phoneFormat = phoneNumber => {
    return phoneNumber!= null && phoneNumber.toString().replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3")
}
export class MyDocument extends Component<PDFComponentProps> {
    constructor(props: Readonly<PDFComponentProps>) {
        super(props);
    }
    render(): React.ReactNode {
        const { selectedTickets } = this.props
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.pdfContainer}>
                        <View>
                            <View style={styles.detailsFooter}>
                                <View>
                                    <Text style={styles.printViewAddress}>{printLabels.COMPANY_NAME}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.ADDRESS_LINE_1}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.ADDRESS_LINE_2}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.PHONE_NUMBER}</Text>
                                </View>
                                <View style={styles.logo}>
                                    <Image src={logo} />
                                </View>
                            </View>
                        </View>
                        {selectedTickets && selectedTickets.type === TicketVolOrHourly.HOURLY ? (
                            <View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.TICKET} : {selectedTickets?.ticketNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.RELATED_VOLUME_TICKET} : {selectedTickets?.relatedVolumeTicket}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CARRIER} : {selectedTickets?.leaseOperator}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.DRIVER} : {selectedTickets?.driverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR} : {selectedTickets?.origin}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNEE} : {selectedTickets?.destination}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNOR_FIELD} : {selectedTickets?.consignorField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNOR_LSD} : {selectedTickets?.consignorLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ID_NAME} : {(selectedTickets?.consignor !== selectedTickets?.origin || selectedTickets?.consignor ===  'New Location')?  selectedTickets?.consignor : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNEE_FIELD} : {selectedTickets?.consigneeField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNEE_LSD} : {selectedTickets?.consigneeLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNEE_ID_NAME} : {(selectedTickets?.consignee !== selectedTickets?.destination || selectedTickets?.consignee === 'New Location') ? selectedTickets?.consignee : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.TRAILER_NUMBER} : {selectedTickets?.trailer}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.AXLES} : {selectedTickets?.axles}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.TRAILER_MILEAGE} : {selectedTickets?.trailerMileage}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.UNITS} : {selectedTickets?.uomID}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_24_PHONE} : {phoneFormat(selectedTickets?.consignor24Phone)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_NAME} : {selectedTickets?.consignorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ADDRESS} : {selectedTickets?.consignorAddress}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.SHIPPER_TICKET_NUMBER} : {selectedTickets?.shipperTicket}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.TRACTOR_IDENTIFIER} : {selectedTickets?.powerUnitIdentifier}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.START_DATE} : {dateUtils.getFormattedDateTime(selectedTickets?.StartDateTime)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.END_DATE} : {dateUtils.getFormattedDateTime(selectedTickets?.endDate)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.ESTIMATED_VOLUME} : {commaFormat(roundOff(selectedTickets?.estimatedVolume))}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.ACTUAL_VOLUME} : {commaFormat(roundOff(selectedTickets?.volumeDelivered))}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PLAN_NUMBER} : {selectedTickets?.consignorERPPlanNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PHONE_NUMBER} : {phoneFormat(selectedTickets?.consignorERPPhoneNumber)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.PRODUCT_LOADED} : {selectedTickets?.product}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.HAZARD_ID} : {selectedTickets?.hazardID}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.WORK_TIME} : {commaFormat(selectedTickets?.workingTime)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.WORKING_TIME_REASON} : {selectedTickets?.workingTimeReason}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.WAIT_TIME} : {commaFormat(selectedTickets?.waitingTime)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.WAITING_TIME_REASON} : {selectedTickets?.waitingTimeReason}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.EMPTY_RES} : {selectedTickets?.emptyResidueLastContained}

                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.COMMENTS} : {selectedTickets?.comment}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CREATED} : {dateUtils.getFormattedDateTime(selectedTickets?.ticketDate)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CREATED_BY} : {selectedTickets?.driverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.INQUIRIES} : {selectedTickets?.inquiryID}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.STATUS} : {selectedTickets?.status}
                                    </Text>
                                </View>
                            </View>) : (<View>
                                {/********* * Volume ticket pdf format *****************************************/}
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.TICKET} : {selectedTickets?.ticketNumber}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.STATUS} : {selectedTickets?.status}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.RUN_SCALE_TICKET_NUMBER} : {selectedTickets?.runTickeScaleTicketNumber}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CARRIER} : {selectedTickets?.leaseOperator}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.DRIVER} : {selectedTickets?.driverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR} : {selectedTickets?.origin}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNEE} : {selectedTickets?.destination}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNOR_FIELD} : {selectedTickets?.consignorField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNOR_LSD} : {selectedTickets?.consignorLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ID_NAME} : {(selectedTickets?.consignor !== selectedTickets?.origin || selectedTickets?.consignor ===  'New Location')?  selectedTickets?.consignor : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNEE_FIELD} : {selectedTickets?.consigneeField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNEE_LSD} : {selectedTickets?.consigneeLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNEE_ID_NAME} : {(selectedTickets?.consignee !== selectedTickets?.destination || selectedTickets?.consignee ===  'New Location')?  selectedTickets?.consignee : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PLAN_NUMBER} : {selectedTickets?.consignorERPPlanNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PHONE_NUMBER} : {phoneFormat(selectedTickets?.consignorERPPhoneNumber)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentOne}>
                                        {' '}
                                        {printLabels.TRAILER_NUMBER} : {selectedTickets?.trailer}
                                    </Text>
                                    <Text style={styles.contentTwo}>
                                        {' '}
                                        {printLabels.AXLES} : {selectedTickets?.axles}
                                    </Text>
                                    <Text style={styles.contentThree}>
                                        {' '}
                                        {printLabels.TRAILER_MILEAGE} : {selectedTickets?.trailerMileage}
                                    </Text>
                                    <Text style={styles.contentFour}>
                                        {' '}
                                        {printLabels.UNITS} : {selectedTickets?.uomID}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.PRODUCT_LOADED} : {selectedTickets?.product}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.COMMENTS} : {selectedTickets?.comment}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_NAME} : {selectedTickets?.consignorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ADDRESS} : {selectedTickets?.consignorAddress}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_24_PHONE} : {phoneFormat(selectedTickets?.consignor24Phone)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_24_PHONE2} : {phoneFormat(selectedTickets?.consignor24Phone2)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.SHIPPER_TICKET_NUMBER} : {selectedTickets?.shipperTicket}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.TRACTOR_IDENTIFIER} : {selectedTickets?.powerUnitIdentifier}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.ESTIMATED_VOLUME} : {commaFormat(roundOff(selectedTickets?.estimatedVolume))}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.ACTUAL_VOLUME} : {commaFormat(roundOff(selectedTickets?.volumeDelivered))}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentOne}>
                                        {' '}
                                        {printLabels.LOAD_TEMP} : {selectedTickets?.loadTemperature}
                                    </Text>
                                    <Text style={styles.contentTwo}>
                                        {' '}
                                        {printLabels.LOAD_TIME} : {dateUtils.getFormattedDateTime(selectedTickets?.loadDateTime)}
                                    </Text>
                                    <Text style={styles.contentThree}>
                                        {' '}
                                        {printLabels.UNLOAD_TEMP} : {selectedTickets?.unLoadTemperature}
                                    </Text>
                                    <Text style={styles.contentFour}>
                                        {' '}
                                        {printLabels.GROSSWEIGHT} : {selectedTickets?.grossWeight}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentOne}>
                                        {' '}
                                        {printLabels.ROAD_VIA} : {selectedTickets?.via}
                                    </Text>
                                    <Text style={styles.contentTwo}>
                                        {' '}
                                        {printLabels.ROAD_BAN} : {selectedTickets?.roadBan}
                                    </Text>
                                    <Text style={styles.contentThree}>
                                        {' '}
                                        {printLabels.DENSITY} : {selectedTickets?.density}
                                    </Text>
                                    <Text style={styles.contentFour}>
                                        {' '}
                                        {printLabels.B_S_W} : {selectedTickets?.BSW}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.HAZARD_ID} : {selectedTickets?.hazardID}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.INQUIRIES} : {selectedTickets?.inquiryID}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.SPLIT_LOAD} : {(selectedTickets?.splitLoad !== null ? selectedTickets?.splitLoad === true ? 'True' : 'False' : '')}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.GROSS_VOLUME} : {selectedTickets?.grossVolume}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.TANK_HEIGHT} : {selectedTickets?.tankHeight}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TARE_WEIGHT} : {selectedTickets?.tareWeight}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.OPEN_METER_READING} : {selectedTickets?.openMeterReading}
                                    </Text>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CLOSE_METER_READING} : {selectedTickets?.closeMeterReading}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.METER_NUMBER} : {selectedTickets?.meterNumber}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.METER_FACTOR} : {selectedTickets?.meterFactor}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TANK_NUMBER} : {selectedTickets?.tankNumber}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.EMPTY_RES} : {selectedTickets?.emptyResidueLastContained}

                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.NET_WEIGHT} : {selectedTickets?.netWeight}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TANK_SIZE} : {selectedTickets?.tankSize}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.OBS_TEMP} : {selectedTickets?.obsTemp}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.OBS_GRAVITY} : {selectedTickets?.obsGravity}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CREATED} : {dateUtils.getFormattedDateTime(selectedTickets?.ticketDate)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CREATED_BY} : {selectedTickets?.driverName}
                                    </Text>
                                </View>


                            </View>)}
                        <View>
                            <Text style={styles.disclaimerText}>{printDisclaimers.accuracyDisclaimer}</Text>
                        </View>
                        <View>
                            <Text style={styles.printViewAddress}>{printLabels.CONSIGNOR_NAME} -</Text>
                            <Text style={styles.printViewAddress}>{printLabels.AUTHORIZED_BY_PRINT} -</Text>
                            <Text style={styles.printViewAddress}>{printLabels.AUTHORIZED_BY_SIGN} -</Text>
                            <Text style={styles.printViewAddress}>
                                {printLabels.DRIVER_SIGNATURE} {': '}
                                {'__________________________'}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.disclaimerText}>{printDisclaimers.regulationsDisclaimer}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

}
