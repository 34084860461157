import React, { Component } from 'react';
import { ShopAssignmentPopUpComponent } from './shop-assignment-popup-component';
import { TrailerMaintenanceDataStore } from '../stores/trailer-maintenance-data-store';
import {
    GridApi,
    GridOptions,
    RowClickedEvent,
    CellEditingStartedEvent,
    CellEditingStoppedEvent
} from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { TrailerMaintenanceFieldName, TrailerMaintenanceButtons } from '../domains/enum';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_trailer-maintenance-data.scss';
import { transact } from '../domains/stateTransitions';
import { AgGridComponentParams } from 'features/common/domain/model';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { dataStore } from '../../common/stores/index';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { ReactDayPickerInput } from '../../../shared/components/daypicketInput/react-daypickerInputTraileMaintence';
import { localStorageService } from 'shared/services/local-storage-service';
import { Modal, Button } from 'react-bootstrap';
import {MaintenanceUpdatePopUp } from './maintenance-update-popup-component'
import { toJS } from 'mobx';
import moment from 'moment';
import { TicketApproverCommentPopup } from 'features/operations-portal/components/ticket-approver-comment-popup';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';

interface TrailerMaintenanceProps {
    trailerMaintenanceStore: TrailerMaintenanceDataStore;
}

interface TrailerMaintenanceState {
    selectedShopID: number;
    status: string;
    shopAssignedDate: string;
    selectedID: number;
    bookingDate: string;
    trailerType: string;
}

@observer
export class TrailerMaintenanceComponent extends Component<TrailerMaintenanceProps, TrailerMaintenanceState> {
    api: GridApi | undefined;
    private rowID = 0;
    constructor(props) {
        super(props);
        this.state = {
            selectedShopID: 0,
            status: '',
            shopAssignedDate: '',
            selectedID: 0,
            bookingDate: '',
            trailerType: ''
        };
        dataStore.setModuleName('TrailerBookingMaintenance');
    }

    componentDidMount() {
        this.props.trailerMaintenanceStore.init();
    }

    componentWillUnmount() {
        this.props.trailerMaintenanceStore.reset();
    }
    handleApproverCommentVisibility = () => {
        this.props.trailerMaintenanceStore.hideTicketApproverPopUp();
    };

    handleApproverCommentSubmission = (comment: string) => {
        this.props.trailerMaintenanceStore.updateComment(comment);
    };

    render(): React.ReactNode {
        const {
            trailerMaintenanceStore: { isApproverPopUpVisible, shops, isSaveDisabled, showModal, isDisabled, showModalMaintenance }
        } = this.props;
        const {
            status,
            selectedShopID,
            shopAssignedDate,
        } = this.state;
        return (
            <Container fluid>
                <>
                    <TicketApproverCommentPopup
                        onApproverCommentSubmission={this.handleApproverCommentSubmission}
                        onApproverCommentVisibility={this.handleApproverCommentVisibility}
                        onApproverCommentCancel={this.handleApproverCommentVisibility}
                        showPopup={isApproverPopUpVisible}
                    />
                    <Row>
                        <Col>
                            <div className="trailer-maintenance-data__action">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        {dataStore.checkOperationAccess('Save') && (
                                            <>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleSave}
                                                    disabled={isSaveDisabled()}
                                                >
                                                    {TrailerMaintenanceButtons.SAVE}
                                                </CustomButton>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleReset}
                                                    disabled={isSaveDisabled()}
                                                >
                                                    {TrailerMaintenanceButtons.RESET}
                                                </CustomButton>
                                            </>
                                        )}
                                        <ClearButtonComponent
                                            handleRefresh = {this.handleRefresh}
                                            isExportCSV={true}
                                            disabled={isDisabled()}
                                        ></ClearButtonComponent>
                                    </Col>
                                </Row>
                            </div>
                            <ReactDayPickerInput
                                _dataStore={this.props.trailerMaintenanceStore}
                                isDisabled={false}
                            ></ReactDayPickerInput>
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ShopAssignmentPopUpComponent
                                shops={shops}
                                selectedShopID={selectedShopID}
                                onHide={this.handlePopUpClose}
                                onSelectionChange={this.handleShopChange}
                                status={status}
                                show={showModal}
                                shopAssignedDate={shopAssignedDate}
                            ></ShopAssignmentPopUpComponent>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MaintenanceUpdatePopUp
                                onHide={this.handleMaintenancePopUpClose}
                                status={status}
                                trailerType={this.state.trailerType}
                                show={showModalMaintenance}
                                onCancelUpadte={this.handleCancel}
                                onConfirmUpdate = {this.handleUpdate}
                                selectedID={this.state.selectedID}
                                bookingDate= {this.state.bookingDate}
                            ></MaintenanceUpdatePopUp>
                        </Col>
                    </Row>
                </>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('TrailerBookingMaintenance_filter');
        if (
            this.props.trailerMaintenanceStore.trailerMaintenanceList.length > 0 &&
            this.props.trailerMaintenanceStore.agGridService.getColumns()?.getColumn('MaintenanceAssignedByFullName')
        ) {
            this.props.trailerMaintenanceStore.agGridService.setAllFilters(filters);
        }
    }

    handleUpdate = (ID :number, questData: any , completedDate: Date): void => {
        const currentDate = new Date();
        const hr = currentDate.getHours()
        const min = currentDate.getMinutes()
        completedDate.setHours(hr);
        completedDate.setMinutes(min);

        const maintenanceDateFormated = ((moment(completedDate).utc()).toISOString()).substring(0,19);
        let aItem = this.props.trailerMaintenanceStore.trailerMaintenanceInfo.filter((item) => (item['ID'] == ID));
        
        aItem[0]["Acheck_Value"] = questData["Acheck_Value"];
        aItem[0]["CVIP_Value"] = questData["CVIP_Value"];
        aItem[0]["V_Value"] = questData["V_Value"];
        aItem[0]["K_Value"] = questData["K_Value"];
        aItem[0]["I_Value"] = questData["I_Value"];
        aItem[0]["P_Value"] = questData["P_Value"];
        aItem[0]["UC_Value"] = questData["UC_Value"];
        aItem[0]["OTES_Value"] = questData["OTES_Value"];

        aItem[0]["Acheck"] = questData["AcheckSelected"];
        aItem[0]["CVIP"] = questData["CVIPSelected"];
        aItem[0]["V"] = questData["VSelected"];
        aItem[0]["K"] = questData["KSelected"];
        aItem[0]["I"] = questData["ISelected"];
        aItem[0]["P"] = questData["PSelected"];
        aItem[0]["UC"] = questData["UCSelected"];
        aItem[0]["OTES"] = questData["OTESSelected"];
        aItem[0]["MaintenanceCompleteDate"] = maintenanceDateFormated;
        this.handleSave();
    }

    handleCancel = (): void => {
            this.handleReset()
    }

    handleRefresh = () => {
        const { trailerMaintenanceStore } = this.props;
        trailerMaintenanceStore.isRefreshed = true;
        if(Object.keys(trailerMaintenanceStore.cellValueChangeMap).length === 0) {
            if(trailerMaintenanceStore.startDate && trailerMaintenanceStore.endDate){
            trailerMaintenanceStore.getRangeResult(trailerMaintenanceStore.startDate,trailerMaintenanceStore.endDate);
            trailerMaintenanceStore.cellValueChangeMap = {}
        }
            else {
            trailerMaintenanceStore.init();
            trailerMaintenanceStore.cellValueChangeMap = {}
            }
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    if(trailerMaintenanceStore.startDate && trailerMaintenanceStore.endDate){
                        trailerMaintenanceStore.getRangeResult(trailerMaintenanceStore.startDate,trailerMaintenanceStore.endDate);
                        trailerMaintenanceStore.cellValueChangeMap = {}
                    }
                        else {
                        trailerMaintenanceStore.init();
                        trailerMaintenanceStore.cellValueChangeMap = {}
                        }
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    private handleShopChange = (newSelectedShopID: number): void => {
        const {
            trailerMaintenanceStore: { cellValueChangeMap }
        } = this.props;
        const initValue = this.state.selectedShopID;
        const row = this.rowID;
        if (!(row in cellValueChangeMap)) {
            cellValueChangeMap[row] = {};
        }
        cellValueChangeMap[row]['AssignedShop'] = {
            initValue: initValue,
            currentValue: newSelectedShopID
        };
    };

    private handlePopUpClose = (): void => {
        const { trailerMaintenanceStore } = this.props;
        trailerMaintenanceStore.setShowModal();
    };

    private handleMaintenancePopUpClose = (): void => {
        const { trailerMaintenanceStore } = this.props;
        trailerMaintenanceStore.setShowModalMaintenance();
    };

    private handleSave = (): void => {
        const { trailerMaintenanceStore } = this.props;
        trailerMaintenanceStore.updateTrailerMaintenances();
    };

    private handleReset = (): void => {
        const { trailerMaintenanceStore } = this.props;
        trailerMaintenanceStore.resetTrailerMaintenanceList();
    };

    onRowClicked = (event: RowClickedEvent): void => {
        const {
            trailerMaintenanceStore: { cellValueChangeMap }
        } = this.props;
        const { Status, ShopID, ShopAssignedDate, ID, TrailerType } = event.node.data;
        this.rowID = ID;
        const currentShopValue = cellValueChangeMap[ID] ? cellValueChangeMap[ID]?.AssignedShop?.currentValue : 0;
        const selectedShopId = currentShopValue > 0 ? currentShopValue : ShopID;
        this.setState({
            selectedShopID: selectedShopId,
            status: Status,
            trailerType: TrailerType,
            shopAssignedDate: ShopAssignedDate
        });
    };

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            trailerMaintenanceStore: { cellValueChangeMap, getAllFilters }
        } = this.props;
        getAllFilters();
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (isNotEmpty) {
            return;
        }
        if (!(row in cellValueChangeMap)) {
            cellValueChangeMap[row] = {};
        }
        cellValueChangeMap[row][col] = { initValue: value, currentValue: value };
        this.setState({selectedID: event.data.ID })
        this.setState({bookingDate: event.data.Booked})
        this.setState({trailerType: event.data.trailerType})
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            trailerMaintenanceStore: { cellValueChangeMap, uiService, updateRow, setAllFilters }
        } = this.props;
        const initValue = cellValueChangeMap[row][col]['initValue'];

        if (col === TrailerMaintenanceFieldName.STATUS && !transact(initValue, value)) {
            cellValueChangeMap[row][col]['currentValue'] = initValue;
            uiService.toastService.error(`Status cannot be changed from ${initValue} to ${value}`);
            return;
        } else {
            if(value == 'Maintenance Complete') {
                const { trailerMaintenanceStore } = this.props;
                trailerMaintenanceStore.setShowModalMaintenance();
            }

            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
        }
        setAllFilters();
    };

    private getGridConfig(): GridOptions {
        const {
            trailerMaintenanceStore: { trailerMaintenanceList, agGridService }
        } = this.props;
        const { trailerMaintenanceStore } = this.props;
        const columnDefs = trailerMaintenanceStore.getColDef();
        const onRowClicked = this.onRowClicked;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const rowData = trailerMaintenanceList;
        const trailerMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onRowClicked,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(trailerMaintenanceGridParams);
    }
}
