import { HazardAssessmentStatus } from './hazardAssessment-history-enum';

let stateTransitions = new Map();
stateTransitions.set(HazardAssessmentStatus.NEW, [
    HazardAssessmentStatus.NEW,
    HazardAssessmentStatus.REVIEWED,
    HazardAssessmentStatus.REVIEWED_ISSUE
]);
stateTransitions.set(HazardAssessmentStatus.REVIEWED, [
    HazardAssessmentStatus.REVIEWED,
    HazardAssessmentStatus.REVIEWED_ISSUE
]);
stateTransitions.set(HazardAssessmentStatus.REVIEWED_ISSUE, [
    HazardAssessmentStatus.REVIEWED,
    HazardAssessmentStatus.REVIEWED_ISSUE
]);
export const transact = (prevState: string, currentState: string): boolean => {
    let canMoveFromPrevToCurrentstate = false;
    for (let [key, value] of stateTransitions) {
        if (key == prevState) {
            canMoveFromPrevToCurrentstate = value.includes(currentState);
             break;
        }
    }
    return canMoveFromPrevToCurrentstate;
};
