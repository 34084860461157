
import { RestApi, ConfigResponseModel } from 'shared/types/shared';
import { restApiService } from 'shared/services';
import { TicketService } from '../model/ticket-data-service-model';
import { ticketDataAdapterService } from './ticket-data-adapter-service';
import { Config } from 'config';
import { TicketManagementConstant } from '../constants/ticket-management-constant';
import { TicketViewModel } from '../model/ticket-view-model';
import { DateRange } from 'shared/components/daypicketInput/react-daypickerInput';
import { dateUtils } from 'shared/services/date-utils';
import { TicketRequestModel, TicketResponseModel } from '../model/ticket-response-model';

export class TicketDataService implements TicketService {
    constructor(private apiService: RestApi) {}

    getAllTickets() {
        return this.apiService.postWithToken<{ Data: TicketResponseModel[] }>(Config.getTicket, {});
    }

    getAllTicketsWithDateParams(reqbody: DateRange) {
        return this.apiService.postWithToken<{ Data: TicketResponseModel[] }>(Config.getTicket, reqbody, {});
    }

   private getTicketStatus() {
        return this.apiService.post<{ Data: ConfigResponseModel[] }>(Config.getBaseValueByClass, {
            CODE: TicketManagementConstant.TICKET_STATUS
        });
    }

    async getTicketViewModel(): Promise<TicketViewModel> {
        const ticketPromise = this.getAllTickets();
        const ticketStatusPromise = this.getTicketStatus();

        const [
            {
                data: { Data: ticketResponseModelList }
            },
            {
                data: { Data: ticketStatusModelList }
            }
        ] = await Promise.all([ticketPromise, ticketStatusPromise]);
        const ticketList = ticketDataAdapterService.transformTicketResponseToTicketModel(ticketResponseModelList);
        const ticketStatusList = ticketDataAdapterService.transformConfigResponseModelToConfigModel(
            ticketStatusModelList
        );
        return {
            ticketList,
            ticketStatusList
        };
    }

    async getTicketViewModelWithDateParams(reqbody: DateRange): Promise<TicketViewModel> {
        const ticketPromise = this.getAllTicketsWithDateParams(reqbody);
        const ticketStatusPromise = this.getTicketStatus();

        const [
            {
                data: { Data: ticketResponseModelList }
            },
            {
                data: { Data: ticketStatusModelList }
            }
        ] = await Promise.all([ticketPromise, ticketStatusPromise]);
        // In DB LoadDateTime and StartDateTime are not in utc, so converting the dates into utc and then checking the dateRange condition to fix Date issue.
        //let filteredTicketListWithDateRange = ticketResponseModelList ? (ticketResponseModelList.filter((item) => ((item.LoadDateTime && dateUtils.isDateSameorGreaterThanGivenDate(dateUtils.getFormattedDateTime(item.LoadDateTime), reqbody.StartDate) && dateUtils.isDateSameorGreaterThanGivenDate(reqbody.EndDate, dateUtils.getFormattedDateTime(item.LoadDateTime)) ) || (item.StartDateTime && dateUtils.isDateSameorGreaterThanGivenDate(dateUtils.getFormattedDateTime(item.StartDateTime), reqbody.StartDate) && dateUtils.isDateSameorGreaterThanGivenDate(reqbody.EndDate, dateUtils.getFormattedDateTime(item.StartDateTime)))))): [];
        const ticketList = ticketDataAdapterService.transformTicketResponseToTicketModel(ticketResponseModelList);
        const ticketStatusList = ticketDataAdapterService.transformConfigResponseModelToConfigModel(
            ticketStatusModelList
        );
        return {
            ticketList,
            ticketStatusList
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveTicket(data: TicketRequestModel[]) {
        await this.apiService.postWithToken<{}>(Config.updateTicketStatus, data);
    }
}

export const ticketDataService = new TicketDataService(restApiService);
