import { StorageService } from '../types/shared';

export class LocalStorageService implements StorageService {
    get<T>(key: string): T | undefined {
        const item = localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : undefined;
    }
    set<T>(key: string, data: T): void {
        localStorage.setItem(key, JSON.stringify(data));
    }
    remove(key: string): void {
        localStorage.removeItem(key);
    }
}

export const localStorageService = new LocalStorageService();
