import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions, RangeSelectionChangedEvent } from 'ag-grid-community';
import Select from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { MasterTicketReportDataStore } from '../stores/master-ticket-report-data-store';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import { commaFormat, MasterTicketstatusType, ticketType } from '../reports_constants';
import jsPDF from 'jspdf';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from './master-ticket-pdf-preview-component';
import { uiService } from 'shared/services/ui-service';
import { ViewDispatchDetailsPopup } from './view-dispatch-details-popup';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface MasterTicketReportProps {
    masterTicketReportStore: MasterTicketReportDataStore;
}
interface MasterTicketReportState {
    selectedStatus: any;
    startDate: string;
    selectedTicketType: any;
}
@observer
export class MasterTicketReportComponent extends Component<MasterTicketReportProps, MasterTicketReportState> {
    startDate: any = moment();
    endDate: any;
    from: any = moment().toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    blobURL: any = [];
    masterTicketCSV: any;
    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: [{ value: 'all', label: 'All' }],
            selectedTicketType: { value: 'All', label: 'All' },
            startDate: ''
        };
        dataStore.setModuleName('MasterTicketReport');
    }

    componentDidMount() {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.init();
    }
    getTickets = () => {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.getRangeResult(
            this.startDate.toISOString().slice(0, 10),
            this.endDate.toISOString().slice(0, 10)
        );
    };


    render() {
        const {
            masterTicketReportStore: { isDisabled, isDownloadEnabled, showDispatchDetailsPopup, selectedTicketNumber }, masterTicketReportStore
        } = this.props;
        let selectedTickets = masterTicketReportStore.selectedticketsList;
        if(dataStore.getCurrentModuleName() == 'MasterTicketReport') {
        this.masterTicketCSV = masterTicketReportStore.agGridService.getNodes();
        masterTicketReportStore.masterGrid = this.masterTicketCSV
        }
        return (
            <Container fluid>
                {showDispatchDetailsPopup === true &&
                <ViewDispatchDetailsPopup
                masterTicketReportStore = {masterTicketReportStore}
                getDispatchDetailsGridConfig = {this.getDispatchDetailsGridConfig()}
                isDisabled={isDisabled()}
                handleRefresh={this.handleDispatchDetailsRefresh}
                handleCancel={this.handleCancel}
                />}
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                <CustomButton type={CustomButtonType.Submit} onClick={this.exportExcel} disabled={isDisabled()}>
                                    {ReportsButton.CSV}
                                </CustomButton> 
                                <CustomButton type={CustomButtonType.Submit} onClick={this.viewDispatchDetails} disabled={false}>
                                    View Dispatch Details
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={this.handleClear} disabled={false}>
                                    Clear Filters
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={this.handleRefresh} disabled={false}>
                                    Refresh
                                </CustomButton>
                                    
                                    <CustomButton type={CustomButtonType.Submit} 
                                        disabled={masterTicketReportStore.selectedticketsList.length === 0 || 
                                            masterTicketReportStore.selectedticketsList.length > 10 }
                                        onClick={()=>{
                                        masterTicketReportStore.isDownloadEnabled = true
                                    }}> GENERATE PDF </CustomButton> 

                                    {isDownloadEnabled && 
                                    <CustomButton type={CustomButtonType.Submit} 
                                    onClick={()=>{
                                    this.handlePDFDownloadMulti();
                                    this.blobURL = []
                                    masterTicketReportStore.isDownloadEnabled = false
                                    }}>DOWNLOAD
                                            {selectedTickets.map((ticket)=> 
                                             <PDFDownloadLink
                                                document={<MyDocument selectedTickets={ticket}/>}
                                                fileName={ticket.TicketNumber + '.pdf'}
                                                style={{
                                                    display: 'none',
                                                    textDecoration: 'none',
                                                    padding: '8px',
                                                    color: '#ffffff',
                                                    backgroundColor: '#00B1F6',
                                                    borderRadius: '5px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                            {({ blob, url, loading, error }) => {
                                                    if(url!== null && !this.blobURL.includes(url)){
                                                        this.blobURL.push({URL: url, ticketNumber: ticket.TicketNumber})
                                                    }
                                                        return loading? 'LOADING...' : 'DOWNLOAD'
                                                
                                                }}
                                            </PDFDownloadLink>)}
                                            </CustomButton>}
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <ReactDayPickerInput
                                _dataStore={masterTicketReportStore}
                                isDisabled={false}
                                showFirstDay={true}
                            ></ReactDayPickerInput>
                            <div className="form-inline padding-bottom">
                            Ticket Type:{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={masterTicketReportStore.selectedTicketType}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleTicketType}
                                        options={ticketType}
                                    /> </div>
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={masterTicketReportStore.selectedOption}
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        options={MasterTicketstatusType}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={true}
                                    /> </div>
                                </div>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                        <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Estimated Volume Sum : {commaFormat(this.props.masterTicketReportStore.volumeSum.volumeDelivered)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Actual Volume Sum : {commaFormat(this.props.masterTicketReportStore.volumeSum.actVolume)}
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        if(this.props.masterTicketReportStore.showDispatchDetailsPopup == false) {
            const filters = localStorageService.get('MasterTicketReport_filter');
            this.props.masterTicketReportStore.agGridService.setAllFilters(filters);
        }
    }

    componentWillUnmount() {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.isDownloadEnabled = false;
    }

    handlePDFDownloadMulti = () => {
            this.blobURL.map((url) => {
                fetch(url.URL).then(response => {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.target="_blank"
                        alink.download = url.ticketNumber;
                        alink.click();
                    })
                })
            })
    }
    exportExcel = () => {
        const allColumns: any = this.props.masterTicketReportStore.masterGrid.rowModel.gridApi.columnController.columnApi.getAllDisplayedColumns();
        allColumns.map((col, index) => {
            if (col.getColId() === 'SELECTED') {
                allColumns.splice(index, 1);
            }
        });
        this.props.masterTicketReportStore.masterGrid?.exportDataAsExcel({ fileName: dataStore.getCurrentModuleName(), columnKeys: allColumns });
    }

    handleDataTypeChange = option => {
        const { masterTicketReportStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            status = Array.from(new Set(option.map((item: any) => item.value)));
            if (status.includes('all') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'all') option.splice(index, 1);
                });
            }
            masterTicketReportStore.selectedOption = option;
            this.setState({selectedStatus : option});
        } else {
            masterTicketReportStore.selectedOption = [{ value: 'all', label: 'All' }];
            this.setState({selectedStatus : [{ value: 'all', label: 'All' }]});
            status = Array.from(new Set(MasterTicketstatusType.map((item: any) => item.value)));
        }
        masterTicketReportStore.statusFiltered(status, masterTicketReportStore.selectedType);
        let dropdown: any = localStorageService.get('MasterTicketReport_Dropdown')
        localStorageService.set('MasterTicketReport_Dropdown', [{'TicketStatus': masterTicketReportStore.selectedOption, 'TicketType': dropdown[0].TicketType}])

    };

    handleTicketType = option => {
        const { masterTicketReportStore } = this.props;
        let type: any = [];
        if (option && option.length > 0) {
            type = Array.from(new Set(option.map((item: any) => item.value)));
            if (type.includes('all') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'all') option.splice(index, 1);
                });
            }
            masterTicketReportStore.selectedTicketType = option;
            this.setState({selectedTicketType : option});
        } else {
            masterTicketReportStore.selectedTicketType = { value: 'All', label: 'All' };
            this.setState({selectedTicketType : { value: 'All', label: 'All' }});
            type = Array.from(new Set(MasterTicketstatusType.map((item: any) => item.value)));
        }
        this.setState({selectedTicketType: option});
        this.setState((masterTicketReportStore.selectedTicketType = option))
        masterTicketReportStore.typeFiltered(option.value, masterTicketReportStore.selectedStatus)
        let dropdown: any = localStorageService.get('MasterTicketReport_Dropdown')
        localStorageService.set('MasterTicketReport_Dropdown', [{'TicketStatus': dropdown[0].TicketStatus, 'TicketType': option}])

    }

    handleRefresh = () => {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.handleRefresh(masterTicketReportStore.startDate,masterTicketReportStore.endDate)
    }

    handleClear = () => {
        const { masterTicketReportStore } = this.props;
        this.masterTicketCSV?.rowModel.gridApi.setFilterModel(null);localStorageService.set('MasterTicketReport_filter',{})
        this.props.masterTicketReportStore.selectedOption = [{ value: 'all', label: 'All' }]
        this.props.masterTicketReportStore.selectedTicketType = { value: 'All', label: 'All' }
        this.props.masterTicketReportStore.selectedStatus = ['all']
        this.props.masterTicketReportStore.selectedType = 'All'
        localStorageService.set('MasterTicketReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'TicketType': { value: 'All', label: 'All' }}])
        masterTicketReportStore.MasterTicketReports = masterTicketReportStore.MasterTicketBackupReport;
    }

    viewDispatchDetails = () => {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.showDispatchDetailsPopup = true;
        masterTicketReportStore.getDispatchDetails()
    }
    
    handleDispatchDetailsRefresh = () => {
        const { masterTicketReportStore } = this.props;
        masterTicketReportStore.getDispatchDetails()
    }

    handleCancel = () => {
        this.props.masterTicketReportStore.showDispatchDetailsPopup = false;
        this.props.masterTicketReportStore.DispatchDetails = [];
        dataStore.setModuleName('MasterTicketReport');
        this.props.masterTicketReportStore.agGridService.setGridApi(this.masterTicketCSV?.rowModel.gridApi)
        this.props.masterTicketReportStore.showDispatchDetailsPopup = false;
        this.masterTicketCSV?.rowModel.gridApi.refreshCells();
    };
    createHeaderCell(col) {
        let headerCell: any = {};

        let headerName: any = col.colDef.headerName;

        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }

    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }

    private exportPdf = (): void => {
        const {
            masterTicketReportStore: { agGridService }
        } = this.props;

        const data: any = agGridService.getNodes();
        const columns: any = agGridService.getColumns();
        let columnsToExport: any = [];
        let columnHeader: any = [];
        let rowsToExport: any = [];
        columns?.getAllDisplayedColumns().forEach(col => {
            console.log(col);
            if (!['Power_Unit_Identifier', 'Region', 'SELECT'].includes(col.getColId())) {
                let headerCell: any = this.createHeaderCell(col);
                columnsToExport.push(headerCell);
                columnHeader.push(headerCell.text);
            }
        });
        data.forEachNodeAfterFilterAndSort(node => {
            console.log(node);
            let rowToExport = columnsToExport.map(({ colId }) => {
                let cellValue = data.getValue(colId, node);
                let tableCell = this.createTableCell(cellValue, colId);
                console.log(tableCell);
                return tableCell;
            });
            rowsToExport.push(rowToExport);
        });
        console.log(columnsToExport, rowsToExport, columnHeader);
        // data.rowModel.rowsToDisplay.forEach((x, _ind) => {
        //     this.data.push(x.data);
        // });
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = TitlePDF.MasterTicketReport;
        const theme: any = 'grid';
        const content = {
            theme: theme,
            startY: 50,
            head: [columnHeader],
            body: rowsToExport
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content);
        doc.save(TitlePDF.MasterTicketReport + '.pdf');
    };
    
    private exportCsv = () => {
        const csvData: any = this.props.masterTicketReportStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        for (let i = 0; i < data.length; i++) {

        data[i]['Load_Date'] =
        data[i]['Load_Date'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['Load_Date']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['TicketDate'] =
        data[i]['TicketDate'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['CompletedDate'] =
        data[i]['CompletedDate'] === null
                      ? ''
                      : momenttimezone
                            .tz(moment.utc(data[i]['CompletedDate']), AppConstant.MST_TIME_FORMATTER)
                            .format(AppConstant.DATE_TIME_FORMAT);        
        }
        let str = JSON.stringify(data);
        str = str.replace(/\"TicketDate\":/g, '"Ticket Creation Date":');
        str = str.replace(/\"Load_Date\":/g, '"Load Date Time":');
        str = str.replace(/\"CompletedDate\":/g, '"Completed Date Time":');
        str = str.replace(/\"Driver_Name\":/g, '"Driver Name":');
        str = str.replace(/\"CompanyName\":/g, '"Carrier Company":');
        str = str.replace(/\"Power_Unit_Identifier\":/g, '"Power Unit Identifier":');
        str = str.replace(/\"Estimated_Vol\":/g, '"Estimated Volume":');
        str = str.replace(/\"Actual_Vol\":/g, '"Actual Volume":');
        const ws = XLSX.utils.json_to_sheet(JSON.parse(str));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.MasterTicketReport);
    };

    private getGridConfig(): GridOptions {
        const {
            masterTicketReportStore: { DuplicateList, agGridService }
        } = this.props;
        const { masterTicketReportStore } = this.props;

        const rowData =  DuplicateList;
        const columnDefs = masterTicketReportStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const onCellClicked = this.onCellclicked;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked,
            onFilterChanged,
            onRangeSelectionChanged
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    private getDispatchDetailsGridConfig(): GridOptions {
        const {
            masterTicketReportStore: { DispatchDetailsList, agGridService }
        } = this.props;
        const { masterTicketReportStore } = this.props;

        const rowData =  DispatchDetailsList;
        const columnDefs = masterTicketReportStore.getDispatchDetailsColDef();
        const onFilterChanged = this.onDispatchFilterChanged;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }
    
    onCellclicked = event => {
        const csvData: any = this.props.masterTicketReportStore.agGridService.getNodes();
        if (event.column.getColId() !== 'SELECTED') {
            this.props.masterTicketReportStore.MasterTicketBackupReport.forEach((x, index) => {
                if (Object.keys(x).includes('SELECTED')) {
                    delete x.SELECTED
                    this.props.masterTicketReportStore.selectedticketsList = []
                    this.props.masterTicketReportStore.isDownloadEnabled = false
                }
            });
            csvData?.rowModel.gridApi.refreshCells();
        }
    }

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.masterTicketReportStore.agGridService.getNodes();
        const checkArray: any = [];
    
        if(e.started === false && e.finished === true) {
            var cellRanges:any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index )=> {
                if(index >= startRowIndex && index <= endRowIndex && cellRanges[0].startColumn.colId === "SELECTED") {
                    this.props.masterTicketReportStore.selectedTickets(x.data, false, "SELECTED");
                    x.setDataValue('SELECTED', true);
                    checkArray.push('true');
                } else {
                    checkArray.push(x.data['SELECTED'] ? 'true': 'false');
                }
            });
            if(this.props.masterTicketReportStore.selectedticketsList.length > 10){
                uiService.toastService.error("Please select maximum of 10 tickets to download at a time")
            }
            csvData?.rowModel.gridApi.refreshCells();

        }
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.masterTicketReportStore.agGridService.getNodes();
        localStorageService.set('MasterTicketReport_filter', this.props.masterTicketReportStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.masterTicketReportStore.getVolumeSum(data);
    };

    onDispatchFilterChanged = (event: FilterChangedEvent): void => {
        localStorageService.set('DispatchDetails_filter', this.props.masterTicketReportStore.agGridService.getAllFilters());
    };
}
