
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import '../../dispatch/dispatch-plan-management/components/_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { CarrierCompany } from '../domains/model';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';

interface TrailerManagementProps {
    selectedCarrierId: number;
    companyName: string;
    carriers: CarrierCompany[]
    onHide: () => void;
    onSelectionChange: (companyName: string) => void;
}

interface TrailerManagementState {
    selectedCarrierId: number;
    selectedCompanyName: string;
}

@observer
export class TrailerManagementCarrierComponent extends Component<TrailerManagementProps, TrailerManagementState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedCarrierId: 0,
            selectedCompanyName: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedCarrierId != this.state.selectedCarrierId) {
            this.setState({ selectedCarrierId: this.props.selectedCarrierId, selectedCompanyName: this.state.selectedCompanyName });
        }
    }

    mapCarrierToCarrierOption = carriers => DataAdapterService.getOptionValues(carriers, 'CompanyName', 'ID', false);

    getSelectedShopOption = (id, carriers) => {
        let selectedShopObj = carriers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { carriers } = this.props;
        const { selectedCarrierId, selectedCompanyName } = this.state;
        const shopOptions = this.mapCarrierToCarrierOption(carriers);
        const selectedShopOption = this.getSelectedShopOption(selectedCarrierId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Carrier Company</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Carrier Company"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignCarrier}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => {
        this.setState({ selectedCompanyName: option.label, selectedCarrierId: option.value });
    }

    assignCarrier = () => {
        this.props.onSelectionChange(this.state.selectedCompanyName);
        this.props.onHide();
    };
}
