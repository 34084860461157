import React from 'react';
import { Modal } from 'react-bootstrap';
import { CarrierFacilityComponent } from './carrier-facility-component';
import './_carrier-facility-popup.scss';
import { AllCarrierCompany } from '../model/model';

interface CarrierFacilityPopUpProps {
    onHide: () => void;
    listCarrier: AllCarrierCompany[];
    listPSACarrier: AllCarrierCompany[];
    listFacility: { ID: number; Text: string }[];
    userInfo: any;
    saveUser: (data: any) => void;
    show: boolean;
}

export const CarrierFacilityPopUpComponent = (props: CarrierFacilityPopUpProps) => {
    const { onHide, listCarrier, listFacility, userInfo, saveUser, show, listPSACarrier } = props;
    return (
        <Modal size="xl" onHide={onHide} show={show}>
            <Modal.Body>
                <CarrierFacilityComponent
                    onHide={onHide}
                    listCarrier={listCarrier}
                    listFacility={listFacility}
                    userInfo={userInfo}
                    saveUser={saveUser}
                    listPSACarrier={listPSACarrier}
                ></CarrierFacilityComponent>
            </Modal.Body>
        </Modal>
    );
};
