import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { TrailerDataService } from '../services/trailer-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { TrailerResponse, OwnershipType, CategoryType, TankSpecification, TrailerStatus, Maintenance, DriplessAdapterSide, OpticalPlug, Skully, TriDriveCompatible, Pumps, MaintenanceFleet, Region, CarrierCompany, RegionType, Scrubber } from '../domains/model';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { trailerColDef } from '../domains/trailer-col-def';
import { TrailerMessage, TrailerFieldName, DriplessAdapterSideTypes, OpticalPlugTypes, SkullyTypes, TriDriveCompatibleTypes, PumpsTypes, MaintenanceFleetTypes, RegionData } from '../domains/enum';
import { TrailerValidationService } from '../services/trailer-validation-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { DONT_REHIRE, INACTIVE } from '../data_constant';
import { dataStore } from '../../common/stores/index';
import { trailerMaintenanceStore } from '../../maintenance-portal/stores/index'
import moment from 'moment';
import { TrailerMaintenanceStatus } from '../../maintenance-portal/domains/enum';
export class TrailerDataStore {
    @observable trailerInfo: TrailerResponse[] = [];
    backupTrailerList: TrailerResponse[] = [];
    trailerStatuses: TrailerStatus[] = [];
    ownershipTypes: OwnershipType[] = [];
    categoryTypes: CategoryType[] = [];
    tankSpecifications: TankSpecification[] = [];
    driplessAdapterSide: DriplessAdapterSide[] = [];
    maintenanceFleet: MaintenanceFleet[] = [];
    opticalPlug: OpticalPlug[] = [];
    skully: Skully[] = [];
    scrubber: Scrubber[] = [];
    triDriveCompatible: TriDriveCompatible[] = [];
    pumps: Pumps[] = [];
    carrierCompanyData: CarrierCompany[] = [];
    regionData: RegionType[] = [];
    @observable cellValueChangeMap = {};
    manufactureTextLength = 60;
    vinTextLength = 30;
    registerTextLength = 10;
    selectedRow: any = [];
    isRefreshed: boolean = false;
    userID = 0;
    userName = '';
    @observable trailerNumber: any;
    @observable trailerId: any;
    maintenanceStatuses: any[] = [];
    maintenanceTypes: any[] = [];
    @observable showCarrierModal = false;
    approvedCommentTicketModel: any;
    approvedDriverModel: any;
    approvedColumn: any;
    raConfig: any = [];
    constructor(
        private trailerDataService: TrailerDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private trailerValidationService: TrailerValidationService
    ) {}

    async init(): Promise<void> {
        this.raConfig = await this.trailerDataService.getRAConfig();
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return trailerColDef;
    }

    @Loader
    @Catch(() => errorHandler(TrailerMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const {
            trailerData,
            tankSpecs,
            trailerStatuses,
            ownershipTypes,
            categoryTypes,
            carrierCompanyData,
            regionData
        } = await this.trailerDataService.getTrailerViewModel();

        const trailers: TrailerResponse[] = trailerData['Data'].filter(a => !a.IsDeleted);
        trailers.forEach(trailor => {
            if (trailor.TankSpecificationValue)
                trailor.TankSpecificationValue = parseInt(trailor.TankSpecificationValue.toString());
        });
        this.tankSpecifications = tankSpecs['Data'];
        this.trailerStatuses = trailerStatuses['Data'].filter(a => a.Code != DONT_REHIRE && a.Code != INACTIVE);
        this.ownershipTypes = ownershipTypes['Data'];
        this.categoryTypes = categoryTypes['Data'];
        this.driplessAdapterSide = DriplessAdapterSideTypes;
        this.maintenanceFleet = MaintenanceFleetTypes;
        this.opticalPlug = OpticalPlugTypes;
        this.skully = SkullyTypes;
        this.scrubber = SkullyTypes;
        this.triDriveCompatible = TriDriveCompatibleTypes;
        this.pumps = PumpsTypes;

        const raFlag = this.raConfig.filter((item: any) => item.Code === 'RAIntegration');

        if (raFlag[0]?.Value === 'RA') {
            this.carrierCompanyData = carrierCompanyData['Data'].filter(a => !a.IsDeleted && a.RACompanyName != null);
            this.carrierCompanyData.map(data => {
                data.CompanyName = data.RACompanyName ? data.RACompanyName : '';
                data.AccountNumber = data.RAAccountNumber;
            })
        } else {
            this.carrierCompanyData = carrierCompanyData['Data'].filter(a => !a.IsDeleted)
        }
        this.carrierCompanyData = [...[{CompanyName: 'Blank', ID: 0, Name: 'Blank'}], ...this.carrierCompanyData];
        this.regionData = regionData['Data'].filter((item) => !item.Name.includes('Central'));
        const {
            maintenanceTypes,
            maintenanceStatuses
        } = await this.trailerDataService.getTrailerMaintenanceViewModel();
        this.maintenanceStatuses = maintenanceStatuses['Data'];
        this.maintenanceTypes = maintenanceTypes['Data'];
        this.setTrailerList(trailers);
        this.setBackupTrailerList(trailers);
        this.addValuesInCellDropdowns();
        this.isRefreshed = false;
    }

    @action
    setYear(trailer: TrailerResponse, value: string, col: string): void {
        this.setValueInChangeMap(trailer.ID, col, trailer.Year, value);
        trailer.Year = value;
        this.updateRow(trailer);
    }

    @action
    setLeadCapacity(trailer: TrailerResponse, value: string, col: string): void {
        this.setValueInChangeMap(trailer.ID, col, trailer.Year, value);
        trailer.WaterCapacityLead = value;
        this.updateRow(trailer);
    }

    @action
    showCarrierPopUp() {
        this.showCarrierModal = true;
    }

    @action
    hideCarrierPopUp() {
        this.showCarrierModal = false;
    }

    updateCarrier(carrier: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        if((this.approvedCommentTicketModel.MaintenanceFleet == 'Yes' || this.approvedCommentTicketModel.MaintenanceFleet == true) && carrier != 'Blank'){
            this.uiService.toastService.error("Please set Maintenance Fleet to No before mapping a carrier company")
            return;
        } 
        this.updateCarrierInPopUp(this.approvedCommentTicketModel, carrier, this.approvedColumn, true);
        this.updateRegionInPopUp(this.approvedCommentTicketModel, carrier, TrailerFieldName.REGION, true);
    }
    
    @action
    updateRegionInPopUp(item: TrailerResponse, value: string, key: string, popup?: boolean) {
        const selectedCarrier = this.carrierCompanyData.filter((carrier) => carrier.CompanyName === value && value !== 'Blank');
        const region = selectedCarrier.length > 0 ? selectedCarrier[0].Name : item.RegionName;
        this.setValueInChangeMap(item.ID, key, item[key], region);
        item[key] = region;
        this.updateRow(item);
    }

    @action
    updateCarrierInPopUp(item: TrailerResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateMaintenance(item: TrailerResponse, value: string, key: string, popup?: boolean) {
        this.cellValueChangeMap[item.ID][key]['currentValue'] = value;
        item[key] = value;
        this.updateRow(item);
    }

    @action
    setPupCapacity(trailer: TrailerResponse, value: string, col: string): void {
        this.setValueInChangeMap(trailer.ID, col, trailer.Year, value);
        trailer.WaterCapacityPup = value;
        this.updateRow(trailer);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    setAlphaNumeric(trailer: TrailerResponse, value: string, colName: string): void {
        const row = trailer.ID;
        const initValue = trailer[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case TrailerFieldName.TRACKERNUMBER:
                trailer.TrackerNumber = value;
                break;
            case TrailerFieldName.VIN:
                trailer.VIN = value;
                break;
            case TrailerFieldName.REGISTRATION:
                trailer.Registration = value;
                break;
            case TrailerFieldName.MANUFACTURER:
                trailer.Manufacturer = value;
                break;
            default:
                break;
        }
        this.updateRow(trailer);
    }

    setBackupTrailerList(trailers: TrailerResponse[]) {
        this.backupTrailerList = trailers;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(this.categoryTypes, TrailerFieldName.CATEGORYTYPE, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.ownershipTypes, TrailerFieldName.OWNERSHIPTYPE, 'Name');
        this.agGridService.updateOptionCellEditorValues(
            this.tankSpecifications,
            TrailerFieldName.TANKSPECIFICATION,
            'Value'
        );
        this.agGridService.updateOptionCellEditorValues(this.trailerStatuses, TrailerFieldName.STATUS, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.driplessAdapterSide, TrailerFieldName.DRIPLESSADAPTERSIDE, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.opticalPlug, TrailerFieldName.OPTICALPLUG, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.skully, TrailerFieldName.SKULLY, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.scrubber, TrailerFieldName.SCRUBBER, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.triDriveCompatible, TrailerFieldName.TRIDRIVECOMPATIBLE, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.triDriveCompatible, TrailerFieldName.HOSERACK, 'Name')
        this.agGridService.updateOptionCellEditorValues(this.pumps, TrailerFieldName.PUMPS, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.maintenanceFleet, TrailerFieldName.MAINTENANCEFLEET, 'Name');
        this.agGridService.updateOptionCellEditorValues(this.carrierCompanyData, TrailerFieldName.CARRIER, 'CompanyName');
        this.agGridService.updateOptionCellEditorValues(this.regionData, TrailerFieldName.REGION, 'Name');
        this.accessCheck(TrailerFieldName.STATUS, 'StatusEdit');
        this.accessCheck(TrailerFieldName.OWNERSHIPTYPE, 'OwnerShipTypeEdit');
        this.accessCheck(TrailerFieldName.CATEGORYTYPE, 'CategoryTypeEdit');
        this.accessCheck(TrailerFieldName.TANKSPECIFICATION, 'TankSpecificationEdit');
        this.accessCheck(TrailerFieldName.MAINTENANCEFLEET, 'MaintenanceFleetEdit');
        this.accessCheck(TrailerFieldName.CARRIER, 'CarrierEdit');
    }

    accessCheck(columnKey: string, propertyName?: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }

        if (propertyName == undefined && columnKey == 'TrackerNumber') {
            propertyName = 'TruckerNoEdit';
        }
        if (propertyName == undefined && columnKey == 'Year') {
            propertyName = 'YearEdit';
        }
        if (propertyName == undefined && columnKey == 'Manufacturer') {
            propertyName = 'ManufacturerEdit';
        }
        if (propertyName == undefined && columnKey == 'VIN') {
            propertyName = 'VINEdit';
        }
        if (propertyName == undefined && columnKey == 'Registration') {
            propertyName = 'RegistrationEdit';
        }

        const access = dataStore.checkOperationAccess(propertyName);

        this.agGridService.updateEditableProperty(columnKey, access == undefined ? false : access);
        return access;
    }

    @computed
    get trailerList(): TrailerResponse[] {
        return toJS(this.trailerInfo);
    }

    @action
    setTrailerList(trailerInfo: TrailerResponse[]): void {
        this.trailerInfo = trailerInfo;
    }

    private updateIDFieldBasedOnName(trailer: TrailerResponse) {
        trailer.StatusID = this.trailerStatuses.find(a => a.Name == trailer.Status)?.ID;
        trailer.CategoryTypeID = this.categoryTypes.find(a => a.Name == trailer.CategoryType)?.ID;
        trailer.OwnershipTypeID = this.ownershipTypes.find(a => a.Name == trailer.OwnershipType)?.ID;
        trailer.TankSpecificationID = this.tankSpecifications.find(a => a.Value == trailer.TankSpecificationValue)?.ID;
    }

    @action
    reset(): void {
        this.setTrailerList([]);
    }

    @action
    resetTrailerList(): void {
        this.setTrailerList(this.backupTrailerList);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: TrailerResponse): void => {
        const updatedTrailerIndex = this.trailerInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.trailerInfo[updatedTrailerIndex], selectedRowData) || this.isRefreshed) {
            this.updateIDFieldBasedOnName(selectedRowData);
            this.mapEditableColumns(this.trailerInfo[updatedTrailerIndex], selectedRowData);
        }
    };

    @Loader
    @action
    @Catch(() => errorHandler(TrailerMessage.FAILED_SUBMIT))
    async updateTrailers(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.trailerInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateTrailerRequest(updatedRowIDs);
        const validationMessage = this.trailerValidationService.validateUpdateRequest(
            requestBody,
            this.backupTrailerList
        );
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        let errorInSave = false;
        const response = await this.trailerDataService.updateTrailers(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].Version) {
                    const objIndex = this.trailerInfo.findIndex(a => a.TrailerNumber === key);
                    this.trailerInfo[objIndex].ModifiedDate = response[key].UpdatedDate;
                    this.trailerInfo[objIndex].Version = response[key].Version;
                    this.trailerInfo[objIndex].ModifiedBy = this.userID;
                    this.trailerInfo[objIndex].ModifiedByUserName = this.userName;
                    for (let i = 0; i < this.backupTrailerList.length; i++) {
                        const backupItem = this.backupTrailerList[i];
                        if (backupItem.TrailerNumber === key) {
                            this.mapEditableColumns(backupItem, this.trailerInfo[objIndex]);
                            break;
                        }
                    }
                } else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: response[key] }];
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.trailerInfo.length; i++) {
                    const updatedtrailer = this.trailerInfo[i];
                    if (updatedtrailer.TrailerNumber === errorMessage.id) {
                        const backupTrailerIndex = this.backupTrailerList.findIndex(
                            a => a.TrailerNumber === errorMessage.id
                        );
                        this.mapEditableColumns(updatedtrailer, this.backupTrailerList[backupTrailerIndex]);
                        break;
                    }
                }
            });
        } else {
            this.uiService.toastService.success(TrailerMessage.SAVE);
        }
        this.init();
    }

    updateTrailerRequest(updatedRows: TrailerResponse[]): TrailerResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.OpticalPlug = (item.OpticalPlug === 'Yes' || item.OpticalPlug === true) ? true : ((item.OpticalPlug === 'No' || item.OpticalPlug === false) ? false : null)
            item.Skully = (item.Skully === 'Yes' || item.Skully === true) ? true : ((item.Skully === 'No' || item.Skully === false) ? false : null);
            item.Scrubber = (item.Scrubber === 'Yes' || item.Scrubber === true) ? true : ((item.Scrubber === 'No' || item.Scrubber === false) ? false : null);
            item.TriDriveCompatible = (item.TriDriveCompatible === 'Yes' || item.TriDriveCompatible === true) ? true : ((item.TriDriveCompatible === 'No' || item.TriDriveCompatible === false)? false : null);
            item.NumberOfPumps = (item.NumberOfPumps === 'N/A') ? -1 : item.NumberOfPumps;
            const selectedCarrier = this.carrierCompanyData.filter((carrier) => carrier.CompanyName === item.CompanyName && item.CompanyName !== 'Blank');
            item.CarrierCompany = selectedCarrier.length > 0 ? selectedCarrier[0].ID : (item.CarrierCompany === 0 || item.CompanyName === 'Blank') ? null : item.CarrierCompany;
            item.CompanyName = item.CompanyName === 'Blank' ? null : item.CompanyName;
            item.MaintenanceFleet = (item.MaintenanceFleet === 'Yes' || item.MaintenanceFleet === true) ? true : ((item.MaintenanceFleet === 'No' || item.MaintenanceFleet === false)? false : null);
            item.HoseRack = (item.HoseRack === 'Yes' || item.HoseRack === true) ? true : ((item.HoseRack === 'No' || item.HoseRack === false)? false : null);
            const selectedRegion = this.regionData.filter((region) => region.Name === item.RegionName );
            item.RegionID = selectedRegion.length > 0 ? selectedRegion[0].ID : item.RegionID
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    mapEditableColumns(currentItem: TrailerResponse, updatedItem: TrailerResponse) {
        if (currentItem) {
            if(this.isRefreshed) {
                currentItem.CheckBox = false;
            }
            else {
            currentItem.StatusID = updatedItem.StatusID;
            currentItem.TankSpecificationID = updatedItem.TankSpecificationID;
            currentItem.CategoryTypeID = updatedItem.CategoryTypeID;
            currentItem.OwnershipTypeID = updatedItem.OwnershipTypeID;
            currentItem.CategoryType = updatedItem.CategoryType;
            currentItem.OwnershipType = updatedItem.OwnershipType;
            currentItem.WaterCapacityLead = updatedItem.WaterCapacityLead;
            currentItem.WaterCapacityPup = updatedItem.WaterCapacityPup;
            currentItem.DriplessAdapter = updatedItem.DriplessAdapter;
            currentItem.OpticalPlug = updatedItem.OpticalPlug;
            currentItem.Skully = updatedItem.Skully;
            currentItem.Scrubber = updatedItem.Scrubber;
            currentItem.TriDriveCompatible = updatedItem.TriDriveCompatible;
            currentItem.NumberOfPumps = updatedItem.NumberOfPumps;
            currentItem.TankSpecificationValue = updatedItem.TankSpecificationValue;
            currentItem.Status = updatedItem.Status;
            currentItem.VIN = updatedItem.VIN;
            currentItem.Registration = updatedItem.Registration;
            currentItem.Manufacturer = updatedItem.Manufacturer;
            currentItem.Year = updatedItem.Year;
            currentItem.TrackerNumber = updatedItem.TrackerNumber;
            currentItem.ModifiedDate = updatedItem.ModifiedDate;
            currentItem.Version = updatedItem.Version;
            currentItem.ModifiedByUserName = updatedItem.ModifiedByUserName;
            currentItem.ModifiedBy = updatedItem.ModifiedBy;
            currentItem.MaintenanceFleet = updatedItem.MaintenanceFleet;
            currentItem.CompanyName = updatedItem.CompanyName;
            currentItem.RegionName = updatedItem.RegionName;
            currentItem.HoseRack = updatedItem.HoseRack;
            }
        }
    }
    selectTrailerToCreateMaintenance(maintenance: TrailerResponse, value: any, colName: string): void {
        this.trailerNumber = maintenance.TrailerNumber;
        this.trailerId = maintenance.ID
        this.selectedRow = maintenance
    }

    private getStatusIDBasedOnStatusName(name: string): number | undefined {
        return this.maintenanceStatuses.find(a => a.Name == name)?.ID;
    }

    private getMaintenanceTypeIDBasedOnTypeName(name: string): number | undefined {
        return this.maintenanceTypes.find(a => a.Name == name)?.ID;
    }

    @Loader
    @action
    @Catch(() => errorHandler(TrailerMessage.FAILED_CREATE_MAINTENANCE))
    async createScheduledMaintenance(selectedDay: string, description: string): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        if (!this.accountStore.getUserID()|| this.accountStore.getUserID() === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.createScheduledTrailerMaintenanceRequest(selectedDay, description)
        const response = await this.trailerDataService.createScheduledTrailerMaintenances(requestBody);
        if (response && errorSummary.length == 0) {
            this.uiService.toastService.success(TrailerMessage.SAVE);
        }
        this.trailerNumber = ''
    }

    createScheduledTrailerMaintenanceRequest(selectedDay: string, description: string) {
        const userID = this.accountStore.getUserID();
        const userInfo = this.accountStore.getUserInfo()
        console.log(JSON.stringify(userInfo));
        const BookedDate = moment(new Date(selectedDay).toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        const currentDate = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        const bookedStatusId = this.getStatusIDBasedOnStatusName(TrailerMaintenanceStatus.BOOKED);
        const scheduledMaintenanceTypeID = this.getMaintenanceTypeIDBasedOnTypeName('Scheduled');
        const requestList: Maintenance =
                {
                    TrailerNumber: this.trailerNumber,
                    StatusUpdateDate: BookedDate,
                    CreatedDate: currentDate,
                    MaintenanceDate: BookedDate,
                    CreatedBy: userID,
                    TruckNumber: null,
                    MaintenanceTypeID: scheduledMaintenanceTypeID ? parseInt(scheduledMaintenanceTypeID.toString()) : 0,
                    StatusID: bookedStatusId ? parseInt(bookedStatusId.toString()): 0,
                    DriverID: null,
                    ShopID: null,
                    Booked: BookedDate,
                    MaintenanceDescriptionPTI: description,
                    InspectionTicketNumber: null,
                    DeviceID: null,
                    TicketSource: 'PDS'
                }
        return requestList;
    }

    handleOTESRequiredClick = async (data: any, value: any) => {
        let errorSummary: ToastMessage[] = [];
        const requestBody = this.updateTrailerRequest([data]);
        data.OTESTrigger = !value;
        let errorInSave = false;
        const response = await this.trailerDataService.updateTrailers(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].Version) {
                    const objIndex = this.trailerInfo.findIndex(a => a.TrailerNumber === key);
                    this.trailerInfo[objIndex].ModifiedDate = response[key].UpdatedDate;
                    this.trailerInfo[objIndex].Version = response[key].Version;
                    this.trailerInfo[objIndex].ModifiedBy = this.userID;
                    this.trailerInfo[objIndex].ModifiedByUserName = this.userName;
                    for (let i = 0; i < this.backupTrailerList.length; i++) {
                        const backupItem = this.backupTrailerList[i];
                        if (backupItem.TrailerNumber === key) {
                            this.mapEditableColumns(backupItem, this.trailerInfo[objIndex]);
                            break;
                        }
                    }
                } else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: response[key] }];
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.trailerInfo.length; i++) {
                    const updatedtrailer = this.trailerInfo[i];
                    if (updatedtrailer.TrailerNumber === errorMessage.id) {
                        const backupTrailerIndex = this.backupTrailerList.findIndex(
                            a => a.TrailerNumber === errorMessage.id
                        );
                        this.mapEditableColumns(updatedtrailer, this.backupTrailerList[backupTrailerIndex]);
                        break;
                    }
                }
            });
        } else {
            this.uiService.toastService.success(TrailerMessage.SAVE);
        }
        this.init();
    }
}

