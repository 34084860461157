import moment from 'moment';
import React from 'react';
import { dispatchScreenDataStore } from '../stores';

const WeekDayHeader = ({ date }) => {

  const headerDate = moment(new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, date).toISOString()).format('MM/DD/YYYY')
  const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const dayOfWeek = daysOfWeek[new Date(headerDate).getDay()];
  return (<>
    <div>
      <span>{dayOfWeek} </span>
    </div>
    <div>
      <span>{String(date).padStart(2, "0")} </span>
    </div></>
  );
};

export default WeekDayHeader;
