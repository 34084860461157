import React, { Component } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
interface PlainsVolumeProps {
    onPlainsVolumeSubmission: (plainsRatio: string) => void;
    onPlainsVolumeVisibility: () => void;
}

const validationSchema = Yup.object().shape({
    PlainsNominationVolume: Yup.string().required('Plains % Ratio is Required')
});

export class CrudeRatioPlains extends Component<PlainsVolumeProps> {
    render(): React.ReactNode {
        return this.renderForm();
    }

    renderForm(): React.ReactNode {
        return (
            <div className="ticket-container__approval-comment">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        PlainsNominationVolume: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSubmit}
                    render={this.renderFormFields}
                />
            </div>
        );
    }

    renderFormFields = ({ errors, touched, isSubmitting, values, handleChange, handleBlur }): React.ReactNode => {
        return (
            <FormikForm>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="PlainsNominationVolume"
                        component="input"
                        className={
                            'form-control' + (errors.PlainsNominationVolume && touched.PlainsNominationVolume ? ' is-invalid' : '')
                        }
                    />
                    <ErrorMessage name="PlainsNominationVolume" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        Submit
                    </button>
                </Form.Group>
            </FormikForm>
        );
    };

    handleSubmit = model => {
        this.props.onPlainsVolumeVisibility();
        this.props.onPlainsVolumeSubmission(model.PlainsNominationVolume);
    };
}
