/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (consignorAXID) => void;
    msg;
}
export class NominationLocationPopUp extends React.Component<ConfirmProps> {
    state = {
        consignorPlaceholder: 'Select Location',
        consignorField: '',
        consignorLSD: '',
        consignor: ''
    };
    selectedLocation: any;
    otherLocation: any;

    public render(): React.ReactNode {
        const { onClose, msg } = this.props;
        const operator: any = Array.from(new Set(msg.map((item: any) => item.AX_Location))).filter((item: any) => item !== null );
        operator.push('New Location');
        if (operator[0] === null) operator.splice(operator[0], 1);
        return (
            <div>
                <div className="location">
                    <Row>
                        <Col>
                            <div>
                                Select Location:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedLocation}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorPlaceholder}
                                        options={operator ? operator : msg}
                                        onChange={this.handleConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {this.otherLocation && (
                        <>
                            <Row>
                                <Col>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Field"
                                                onChange={this.handleFieldChange}
                                                value={this.state.consignorField}
                                            ></input>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="LSD: ll-ss-ttt-rrWm"
                                                onChange={this.handleLSDChange}
                                                value={this.state.consignorLSD}
                                            ></input>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Consignor"
                                                onChange={this.handleChange}
                                                value={this.state.consignor}
                                            ></input>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    <Row>
                        <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={
                                    !this.selectedLocation ||
                                    (this.selectedLocation === 'New Location' &&
                                        this.state.consignorLSD == '' &&
                                        this.state.consignorField == '' &&
                                        this.state.consignor == '')
                                }
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
    handleFieldChange = event => {
        this.setState({ consignorField: event.target.value });
    };

    handleLSDChange = event => {
        this.setState({ consignorLSD: event.target.value });
    };

    handleChange = event => {
        this.setState({ consignor: event.target.value });
    };

    handleConsignorType = option => {
        this.selectedLocation = option;
        if (this.selectedLocation === 'New Location') this.otherLocation = true;
        else this.otherLocation = false;
        console.log(option);
        this.setState({ consignorPlaceholder: option });
    };

    handleClick = () => {
        if (this.state.consignorPlaceholder === 'New Location')
            this.props.onSuccessClick(
                    this.state.consignorField +
                    ' ' +
                    this.state.consignorLSD +
                    ' ' +
                    this.state.consignor
            );
        else this.props.onSuccessClick(this.state.consignorPlaceholder);
        this.props.onClose();
    };
}
