import React, { Component } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TicketManagementConstant } from '../constants/ticket-management-constant';
interface ApproverCommentProps {
    onApproverCommentSubmission: (approverComment: string) => void;
    onApproverCommentVisibility: () => void;
    onApproverCommentCancel: () => void;
    cancelOption?: boolean;
    modalTitle?: string;
}

const validationSchema = (modalTitle) => Yup.object().shape({
    approverComment: Yup.string().required(modalTitle == 'Carrier' ? TicketManagementConstant.CARRIER_COMMENT_REQUIRED : TicketManagementConstant.APPROVER_COMMENT_REQUIRED)
});

export class ApproverComment extends Component<ApproverCommentProps> {
    render(): React.ReactNode {
        return this.renderForm();
    }

    renderForm(): React.ReactNode {
        return (
            <div className="ticket-container__approval-comment">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        approverComment: ''
                    }}
                    validationSchema={validationSchema(this.props.modalTitle)}
                    onSubmit={this.handleSubmit}
                    onReset={this.handleReset}
                    render={this.renderFormFields}
                />
            </div>
        );
    }

    renderFormFields = ({ errors, touched, isSubmitting, values, handleChange, handleBlur }): React.ReactNode => {
        return (
            <FormikForm>
                <Form.Group as={Col} controlId="validationFormik01">
                    <Field
                        name="approverComment"
                        component="textarea"
                        className={
                            'form-control' + (errors.approverComment && touched.approverComment ? ' is-invalid' : '')
                        }
                    />
                    <ErrorMessage name="approverComment" component="div" className="invalid-feedback" />
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik01">
                    {this.props.cancelOption && (
                        <button type="reset" className="btn btn-danger marginRight">
                            Cancel
                        </button>
                    )}
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        Submit
                    </button>
                </Form.Group>
            </FormikForm>
        );
    };

    handleSubmit = model => {
        this.props.onApproverCommentVisibility();
        this.props.onApproverCommentSubmission(model.approverComment);
    };

    handleReset = () => {
        this.props.onApproverCommentCancel();
    };
}
