import React, { Component } from 'react';
import { TabletDataStore } from '../stores/tablet-data-store';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { tabletStore } from '../stores';
import { dataStore } from '../../common/stores/index';
import { ADD_NEW_ROW, DELETE_ITEM, RESET } from '../data_constant';
import { TabletSaveLabel } from '../domains/tablet-enum';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface TabletMasterDataProps {
    tabletDataStore: TabletDataStore;
}

@observer
export class TabletMasterDataComponent extends Component<TabletMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('TabletManagement');
    }
    componentDidMount() {
        this.props.tabletDataStore.init();
    }

    componentWillUnmount() {
        this.props.tabletDataStore.reset();
    }

    render(): React.ReactNode {
        const {
            tabletDataStore: { isSaveDisabled, isResetEnabled, isDeleteEnabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {TabletSaveLabel.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled() && isResetEnabled()}
                                            >
                                                {RESET}
                                            </CustomButton>
                                            <CustomButton type={CustomButtonType.Submit} onClick={this.addNewRow}>
                                                {ADD_NEW_ROW}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {DELETE_ITEM}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('TabletManagement_filter');
        this.props.tabletDataStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        this.props.tabletDataStore.updateTablet();
    };

    private handleReset = (): void => {
        this.props.tabletDataStore.resetTabletList();
    };

    private addNewRow = (): void => {
        this.props.tabletDataStore.addRow();
    };

    private deleteitems = (): void => {
        const {
            tabletDataStore: { DeleteRows }
        } = this.props;

        DeleteRows();
    };
    
    handleRefresh = () => {
        const { tabletDataStore } = this.props;
        if(Object.keys(tabletDataStore.cellValueChangeMap).length === 0) {
            tabletDataStore.init(); 
            tabletDataStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    tabletDataStore.init();
                    tabletDataStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    exportexcel = () => {
        const csvData: any = this.props.tabletDataStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        const fields = [
            'CarrierCompanyID',
            'UnplannedVolumeTicketCounter',
            'TicketCounterYear',
            'CreatedBy',
            'ModifiedBy',
            'InspectionTicketCounter',
            'UnplannedHourlyTicketCounter'
        ];
        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                if (fields.indexOf(key) !== -1) delete data[i][key];
            }
        }
        let str = JSON.stringify(data);
        str = str.replace(/\"Manufacturer\":/g, '"SimCard #":');
        str = str.replace(/\"TabletOS\":/g, '"Serial No.":');
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
    };

    private getGridConfig(): GridOptions {
        const {
            tabletDataStore: { tabletInfo, agGridService, getColDef }
        } = this.props;

        const rowData = tabletInfo;
        const columnDefs = getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;

        const tabletGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(tabletGridParams);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            tabletDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            tabletStore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                tabletDataStore: { cellValueChangeMap, updateRow }
            } = this.props;
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
        } catch (err) {
            console.log(err);
        }
    };
}
