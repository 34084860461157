import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { InspectionDataStore } from '../stores/inspection-data-store';
import { Modal, Button } from 'react-bootstrap';
import { InspectionDefectListComponent } from './inspection-defect-list';
import { CommonUtils } from 'shared/services/common-utils';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { Container, Row, Col } from 'react-bootstrap';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import logo from 'assets/pmc-logo.png';
import './otes-component.scss'
import moment from 'moment';
import { AppConstant } from 'app_constant';
import { dateUtils } from 'shared/services/date-utils';

interface InspectionUpComponentProps {
    inspectionDataStore: InspectionDataStore;
    inspectionNumber: number;
    showchild: boolean;
    onHide: () => void;
    version?: number;
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    detailsHeader: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 0 16px'
    },
    detailsFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '0 16px 10px'
    },
    printViewContainer: {
        justifyContent: 'space-between',
        paddingBottom: 6
    },
    printViewText: {
        alignContent: 'flex-start',
        fontWeight: 'light',
        paddingBottom: 2,
        paddingTop: 2,
        paddingLeft: 5
    },
    content: {
        minWidth: 220,
        maxWidth: 220,
        minHeight: 105,
        border: 1,
        borderStyle: 'solid',
        padding: 3
    },
    contentright: {
        fontWeight: 'light',
        minWidth: 220,
        maxWidth: 220,
        minHeight: 105,
        width: 220,
        border: 1,
        borderStyle: 'solid',
        padding: 3,
    },
    image: {
        padding: '0 16px'
    },
    signature: {
        padding: '16px'
    }
})

@inject('inspectionDataStore')
@observer
export class OTESHistoryComponent extends Component<InspectionUpComponentProps> {
    constructor(props) {
        super(props);

        this.props.inspectionDataStore.getOTESReport({
            OTESID: CommonUtils.getQueryStringParam('otesno')
        });
        this.state = {
            showmodal: false
        };
    }


    render(): React.ReactNode {
        const { showchild, inspectionDataStore } = this.props;
        let ticketid = CommonUtils.getQueryStringParam('id');
        let ticketNumber = CommonUtils.getQueryStringParam('otesno');
        let titletext = 'OTES Number ' + (ticketNumber == undefined ? '0' : ticketNumber);
        if (ticketid !== undefined && ticketid > 0) {
            return (
                <div className="Uimargin">
                    <span className="ticket_number">{titletext}</span>
                    <Document>
                        <Container className='page'>
                            <View style={styles.pdfContainer}>
                                <View>
                                    <View style={styles.detailsHeader}>
                                        <View style={styles.image}>
                                            <img style={{ maxWidth: '125px', maxHeight: '125px' }} src={logo} alt="Logo" />
                                        </View>
                                        <View>
                                            <div style={{ lineHeight: '16px' }}>OFF-TRUCK EMERGENCY SHUTDOWN SYSTEM</div>
                                            <div style={{ lineHeight: '16px' }}>DAILY/MONTHLY TEST & REPORT</div>
                                        </View>

                                    </View>
                                    <View style={styles.detailsFooter}>
                                        <Text style={styles.content}>
                                            {' '}
                                            DATE/TIME :  <div>
                                                {dateUtils.getFormattedDateTime(
                                                    inspectionDataStore.otesReport.modifiedDate!= null ?
                                                    inspectionDataStore.otesReport?.modifiedDate : inspectionDataStore.otesReport?.createdDate)}
                                                </div>
                                        </Text>
                                        <Text style={styles.contentright}>
                                            {' '}
                                            UNIT # :  <div>{inspectionDataStore.otesReport?.trailerNumber}</div>
                                        </Text>
                                    </View>
                                    <View style={styles.detailsFooter}>
                                        <Text style={styles.content}>
                                            {' '}
                                            TANK OWNER :  <div>{inspectionDataStore.otesReport?.tankOwner}</div>
                                        </Text>
                                        <Text style={styles.contentright}>
                                            {' '}
                                            NAME OF PERSON PERFORMING TEST :  <div>{inspectionDataStore.otesReport?.createdBy}</div>
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.printViewText}>{' '}
                                        PROCEDURES : </Text>
                                </View>
                                <View>
                                    {inspectionDataStore.otesReport?.procedures.map((procedure, ind) => { return (
                                        <View style={styles.detailsFooter}>
                                        <input
                                            disabled
                                            type="checkbox"
                                            checked={procedure.QuestionStatus == 1 ? true : false}
                                            name="pdf" />
                                        <Text style={styles.printViewText}>{' '}
                                        {procedure.QuestionName} </Text>
                                    </View>
                                    )})}
                                    <View style={styles.detailsFooter}>
                                        <Text style={styles.printViewText}>{' '}
                                            TEST RESULTS : </Text>
                                    </View>
                                    <View>
                                        <View style={styles.detailsFooter}>
                                            <input
                                                disabled
                                                type="checkbox"
                                                checked={inspectionDataStore.otesReport?.otesStatus === 1 ? true : false}
                                                name="pdf" />
                                            <Text style={styles.printViewText}>{' '}
                                                PASS - Return to service </Text>
                                        </View>
                                        <View style={styles.detailsFooter}>
                                            <input
                                                disabled
                                                type="checkbox"
                                                checked={inspectionDataStore.otesReport?.otesStatus === 0 ? true : false}
                                                name="pdf" />
                                            <Text style={styles.printViewText}>{' '}
                                                FAIL - Removed from Service </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.signature}>
                                <Text style={styles.printViewText}>{' '}
                                            SIGNATURE : </Text>
                                    <img src={inspectionDataStore.otesReport?.signature} width="200" height="100"></img>
                                </View>
                            </View>
                        </Container>
                    </Document>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
