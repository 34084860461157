import moment from 'moment';
import { dispatchBoardDataStore } from '../store';
import React from 'react';

const WeekDayHeader = ({ date }) => {

const headerDate = moment(new Date(dispatchBoardDataStore.selectedYear, dispatchBoardDataStore.selectedMonth, date).toISOString()).format('MM/DD/YYYY')
const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const dayOfWeek = daysOfWeek[new Date(headerDate).getDay()];

  return (
    <div>
      <span>{String(date).padStart(2, "0")} {dayOfWeek}</span>
    </div>
  );
};

export default WeekDayHeader;
