import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions } from 'ag-grid-community';
import crypto from 'crypto';
import Select, { components, ActionMeta, ValueType } from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { DailyReportDataStore } from '../stores/dailyreport-data-store';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import { commaFormat, ticketStatus } from '../reports_constants';
import jsPDF from 'jspdf';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { LeaseOperatorStore } from '../stores/leaseoperator-data-store';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
/* eslint-disable */
interface DailyReportProps {
    leaseoperatorStore: LeaseOperatorStore;
}

@observer
export class LeaseOperatorComponent extends Component<DailyReportProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .startOf('month')
        .add(1, 'days')
        .toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    // filename: string = '';
    constructor(props) {
        super(props);
        dataStore.setModuleName('LeaseOperatorReport');
    }

    componentDidMount() {
        const { leaseoperatorStore } = this.props;
        leaseoperatorStore.init();
    }
    getTickets = () => {
        const { leaseoperatorStore } = this.props;
        leaseoperatorStore.selectLeaseOperator = [];
        leaseoperatorStore.selectedOption = [{ value: 'all', label: 'All'}]
        leaseoperatorStore.getRangeResult(
            this.startDate.toISOString().slice(0, 10),
            this.endDate.toISOString().slice(0, 10)
        );
    };

    render(): React.ReactNode {
        const {
            leaseoperatorStore: { isDisabled }, leaseoperatorStore
        } = this.props;
        console.log(leaseoperatorStore.selectLeaseOperator);
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') &&
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.sendrepo}
                                        disabled={
                                            leaseoperatorStore.selectLeaseOperator[0] && leaseoperatorStore.selectLeaseOperator[0].label != 'All'
                                                ? false
                                                : true
                                        }
                                    >
                                        Send Report
                                    </CustomButton>}
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true} disabled={isDisabled()}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <ReactDayPickerInput
                                _dataStore={this.props.leaseoperatorStore}
                                isDisabled={false}
                                showFirstDay={true}
                            ></ReactDayPickerInput>
                            <div className="form-inline padding-bottom">
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={leaseoperatorStore.selectedOption}
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        placeholder="Select"
                                        options={ticketStatus}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={true}
                                    />
                                </div>{' '}
                                Carrier Company :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={leaseoperatorStore.selectLeaseOperator}
                                        placeholder="SelectCompany"
                                        options={this.props.leaseoperatorStore.leaseOperatorName}
                                        isDisabled={this.props.leaseoperatorStore.leaseoperatorReports.length === 0}
                                        onChange={this.handleLeaseOperatorChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                        <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Volume Delivered Sum : {commaFormat(this.props.leaseoperatorStore.volumeSum.volDeliveredSum)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Waiting Time : {commaFormat(this.props.leaseoperatorStore.volumeSum.totalWaitingTime)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Working Time : {commaFormat(this.props.leaseoperatorStore.volumeSum.totalWorkingTime)}
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('LeaseOperatorReport_filter');
        this.props.leaseoperatorStore.agGridService.setAllFilters(filters);
    }

    private sendrepo = (): void => {
        const { leaseoperatorStore } = this.props;
        const csvData: any = leaseoperatorStore.agGridService.getNodes();
        const rows: any = [];
        csvData.rowModel.rowsToDisplay.forEach(x => {
            rows.push(x.data);
        });
        let data = JSON.parse(JSON.stringify(rows));
        const fileName = 'leaseoperator' + new Date().getTime() + '.xlsx';
        const fields = [
            'LeaseOperatorName'
        ]
        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                if (fields.indexOf(key) !== -1) delete data[i][key];
            }
            data[i]['TicketDate'] =
                data[i]['TicketDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
            data[i]['AcceptedDate'] =
                data[i]['AcceptedDate'] === null
                ? ''
                : momenttimezone
                    .tz(moment.utc(data[i]['AcceptedDate']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            data[i]['LoadDATETIME'] =
                data[i]['LoadDATETIME'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['LoadDATETIME']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
            data[i]['StartDate'] =
                data[i]['StartDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['StartDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);            
            data[i]['CompletedDate'] =
            data[i]['CompletedDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['CompletedDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
            
        }
        const roundOff = value => {
            return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
        };
        let report: any;
        for (report in data) {
            data[report].VolumeDelivered = commaFormat(roundOff(data[report].VolumeDelivered))
            data[report].TicketStatus = data[report].TicketStatus
        }
        const confirmService = new ConfirmService();
        const fieldNames = "DriverName,TicketType,TicketStatus,TicketNumber,Origin,Destination,WorkingTime,WorkingTimeReason,WaitingTime,WaitingTimeReason,TicketDate,AcceptedDate,Trailer,Product,VolumeDelivered,ShipperTicketBOL,RoadBan,Via,RelatedVolumeTicket,ApproverComment,Comments,TrailerMappedToCompany,CompletedDate,LoadDATETIME,StartDate,Region"
        const excelFields = "Driver Name,Ticket Type,Status,Ticket Number,Origin,Destination,Working Time,Working Time Reason,Waiting Time,Waiting Time Reason,Ticket Created Date/Ticket Planned Date,Ticket Accepted Date,Trailer,Product,Volume Delivered,Shipper Ticket BOL,Road Ban,Road Via,Related Volume Ticket,Approver Comment,Comments,Trailer Mapped To Company,Completed Date Time,Start/Load Date Time,Start Date,Region"
        console.log(leaseoperatorStore.selectLeaseOperator);
        confirmService.showEmailPopup(email => {
            const reqbody = {
                ExcelFields: excelFields,
                Fields: fieldNames,
                FromEmailID: this.props.leaseoperatorStore.usermail,
                FileName: fileName,
                ToEmailID: email,
                FromDate: leaseoperatorStore.startDate,
                ToDate: leaseoperatorStore.endDate,
                LeaseOperatorName: leaseoperatorStore.selectLeaseOperator[0].value,
                CCReportjson: data
            };
            leaseoperatorStore.sendreport(reqbody);
        });
    };

    handleDataTypeChange = option => {
        const { leaseoperatorStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            status = Array.from(new Set(option.map((item: any) => item.value)));
            if (status.includes('all') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'all') option.splice(index, 1);
                });
            }
            this.setState((leaseoperatorStore.selectedOption = option));
        } else {
            this.setState((leaseoperatorStore.selectedOption = [{ value: 'all', label: 'All' }]));
            status = Array.from(new Set(ticketStatus.map((item: any) => item.value)));
        }
        if (status.includes('Driver Dispatched')) status.push('Dispatch_Pending');
        leaseoperatorStore.statusFiltered(status, leaseoperatorStore.selectLeaseOperator);
        let dropdown: any = localStorageService.get('LeaseOperatorReport_Dropdown')
        localStorageService.set('LeaseOperatorReport_Dropdown', [{'TicketStatus': leaseoperatorStore.selectedOption, 'CarrierCompany': dropdown[0].CarrierCompany}])
    };
    handleLeaseOperatorChange = option => {
        const { leaseoperatorStore } = this.props;
        let name: any = [];
        const selectoption: any = [];
        if (option) {
            selectoption.push(option);
            this.setState((leaseoperatorStore.selectLeaseOperator = selectoption));
            if (option.value == 'Select') {
                name = [];
            } else {
                name.push(option);
            }
        }
        this.props.leaseoperatorStore.leaseOperatorFiltered(name);
        let dropdown: any = localStorageService.get('LeaseOperatorReport_Dropdown')
        localStorageService.set('LeaseOperatorReport_Dropdown', [{'TicketStatus': dropdown[0].TicketStatus, 'CarrierCompany': leaseoperatorStore.selectLeaseOperator}])
    };

    handleRefresh = () => {
        const { leaseoperatorStore } = this.props;
        leaseoperatorStore.isRefreshed = true;
        if(leaseoperatorStore.startDate && leaseoperatorStore.endDate){
            leaseoperatorStore.getRangeResult(leaseoperatorStore.startDate,leaseoperatorStore.endDate);
        }
        else {
        leaseoperatorStore.getleaseoperatorReports();
        }
    }
        
    

    private exportCsv = (): void => {
        const {
            leaseoperatorStore: { agGridService }
        } = this.props;
        
        agGridService.getNodes()?.exportDataAsCsv({ fileName: TitlePDF.LeaseOperator });
    };

    private getGridConfig(): GridOptions {
        const {
            leaseoperatorStore: { DuplicateList, agGridService }
        } = this.props;
        const { leaseoperatorStore } = this.props;

        const rowData = DuplicateList;
        const columnDefs = leaseoperatorStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const LeaseOperatorGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(LeaseOperatorGridParams);
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.leaseoperatorStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName() + '_filter', this.props.leaseoperatorStore.agGridService.getAllFilters());
        if(this.props.leaseoperatorStore.clearSelection == true) {
            this.props.leaseoperatorStore.selectedOption = [{ value: 'all', label: 'All' }]
            if(this.props.leaseoperatorStore.checkCCLogin() == false){
            this.props.leaseoperatorStore.selectLeaseOperator = [{ value: 'All', label: 'All' }]
            localStorageService.set('LeaseOperatorReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'CarrierCompany': [{ value: 'All', label: 'All' }]}])
            } else {
                localStorageService.set('LeaseOperatorReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'CarrierCompany': [{ value: this.props.leaseoperatorStore.selectLeaseOperator[0].value, label: this.props.leaseoperatorStore.selectLeaseOperator[0].label}]}])
            }
            this.props.leaseoperatorStore.leaseoperatorReports = this.props.leaseoperatorStore.dailyBackupReport;
            this.props.leaseoperatorStore.clearSelection = false;
        }
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        
        this.props.leaseoperatorStore.getVolumeSum(data);
    };
}
