import { DefaultPageComponent } from 'features/common/components/default-page-component';
import React from 'react';
import { HazardAssessmentTicketHistoryComponent } from './components/hazardAssessment-history-component';
import { PastDueCertificationReportComponent } from './components/pastDueCeritification-report-component';
import { TicketContainer } from './components/ticket-container';
import { OperationsComponentName } from './constants/constant';
import { hazardAssessmentHistoryStore, pastDueCertificationReportStore, ticketStore } from './stores';

export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        OperationsComponentName.Select,
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        OperationsComponentName.TicketApproval,
        () => (
            <React.Fragment>
                <TicketContainer ticketStore={ticketStore} />
            </React.Fragment>
        )
    ],
    
    [
        OperationsComponentName.HazardAssessmentHistory,
        () => (
            <div>
                <HazardAssessmentTicketHistoryComponent _dataStore={hazardAssessmentHistoryStore} />
            </div>
        )
    ],
    [
        OperationsComponentName.PastDueCertificationReport,
        () => (
            <div>
                <PastDueCertificationReportComponent pastDueCertificationReportDataStore={pastDueCertificationReportStore} />
            </div>
        )
    ]

]);
