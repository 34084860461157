import { SearchTicketHeaderName, SearchTicketFieldName } from './enum';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { TicketStatus } from 'features/operations-portal/constants/ticket-enum';
import { commaFormat, isFloat } from '../reports_constants';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/ticket-approval-cell-renderer';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { searchTicketsStore } from '../stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { EditNominationCellRenderer } from 'shared/components/cell-renderers/edit-nomination-renderer';

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

const getLastPmtValue = value => {
    if (value === TicketStatus.CANCELED) {
        return 'Canceled by Driver';
    }
    return value;
};
export const searchTicketsColDef: ColDef[] = [
    {
        headerName: SearchTicketHeaderName.TICKETNUMBER,
        field: SearchTicketFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TICKETNUMBER] === null && params.data[SearchTicketFieldName.LOADNUMBER] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.LOADNUMBER] || params.data[SearchTicketFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.MANUALTICKETNUMBER,
        field: SearchTicketFieldName.MANUALTICKETNUMBER,
        width: 125,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.MANUALTICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.MANUALTICKETNUMBER];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TYPE,
        field: SearchTicketFieldName.TYPE,
        width: 60,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TYPE] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TYPE];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TICKETCREATIONORPLANNEDDATE,
        field: SearchTicketFieldName.TICKETDATE,
        width: 205,
        valueGetter: params => (params.data[SearchTicketFieldName.TICKETDATE] ? dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.TICKETDATE]) : '')
    },
    {
        headerName: SearchTicketHeaderName.TICKETACCEPTEDDATE,
        field: SearchTicketFieldName.TICKETACCEPTEDDATE,
        width: 130,
        valueGetter: params => (params.data[SearchTicketFieldName.TICKETACCEPTEDDATE] ? dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.TICKETACCEPTEDDATE]) : '')
    },
    {
        headerName: SearchTicketHeaderName.LOADDATETIME,
        field: SearchTicketFieldName.LOADDATETIME,
        width: 105,
        valueGetter: params => {
            if ((params.data[SearchTicketFieldName.LOADDATETIME] === null && params.data[SearchTicketFieldName.STARTDATE] === null)) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TYPE] === 'Hourly' ?
                    dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.STARTDATE]) :
                    dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.LOADDATETIME]);
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.ENDDATE,
        field: SearchTicketFieldName.ENDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.ENDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.ENDDATE])
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.COMPLETEDDATE,
        field: SearchTicketFieldName.COMPLETEDDATE,
        width: 105,
        // In response Completed Date is coming in UTC Format so converting the value in MM/DD/YYYY HH:mm format
        valueGetter: params => (params.data[SearchTicketFieldName.COMPLETEDDATE] ? dateUtils.getFormattedDateTimeLocal(params.data[SearchTicketFieldName.COMPLETEDDATE]) : '')
    },
    {
        headerName: SearchTicketHeaderName.DATASOURCE,
        field: SearchTicketFieldName.DATASOURCE,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DATASOURCE] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DATASOURCE];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.CUSTOMERNAME,
        field: SearchTicketFieldName.CUSTOMERNAME,
        colId: SearchTicketFieldName.CUSTOMERNAME,
        width: 95,
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' && params.data[SearchTicketFieldName.TICKETNUMBER]?.startsWith('VB') ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' && params.data[SearchTicketFieldName.TICKETNUMBER]?.startsWith('VB')  ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.CUSTOMERNAME] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.CUSTOMERNAME];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.LOADSTATUS,
        field: SearchTicketFieldName.LOADSTATUS,
        width: 75,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LOADSTATUS] === null) {
                return '';
            } else if (params.data[SearchTicketFieldName.LOADSTATUS] === 'Assigned') {
                return 'Dispatched';
            } else {
                return params.data[SearchTicketFieldName.LOADSTATUS];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.LOADTYPE,
        field: SearchTicketFieldName.LOADTYPE,
        width: 65,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LOADTYPE] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.LOADTYPE];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.ORIGIN,
        field: SearchTicketFieldName.ORIGIN,
        width: 100,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.ORIGIN];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.DESTINATION,
        field: SearchTicketFieldName.DESTINATION,
        width: 100,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DESTINATION];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.LEASEOPERATOR,
        field: SearchTicketFieldName.LEASEOPERATOR,
        width: 145,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LEASEOPERATOR] === null || params.data[SearchTicketFieldName.LEASEOPERATOR] === 'Dummy Test') {
                return '';
            } else {
                return params.data[SearchTicketFieldName.LEASEOPERATOR];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.DRIVERNAME,
        field: SearchTicketFieldName.DRIVERNAME,
        width: 115,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DRIVERNAME] === null || params.data[SearchTicketFieldName.DRIVERNAME] === 'Dummy Test' || params.data[SearchTicketFieldName.DRIVERNAME]?.trim() === 'SysUser') {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.CARRIERNAME,
        field: SearchTicketFieldName.CARRIERNAME,
        width: 120,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.CARRIERNAME] === null || params.data[SearchTicketFieldName.CARRIERNAME] === 'Dummy Test') {
                return '';
            } else {
                return params.data[SearchTicketFieldName.CARRIERNAME];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.REGION,
        field: SearchTicketFieldName.REGION,
        width: 75,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.REGION];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRAILERNUMBER,
        field: SearchTicketFieldName.TRAILERNUMBER,
        width: 80,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.PRIORITY,
        field: SearchTicketFieldName.PRIORITY,
        colId: SearchTicketFieldName.PRIORITY,
        width: 85,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.PRIORITY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.PRIORITY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.Product,
        field: SearchTicketFieldName.PRODUCT,
        width: 85,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.ESTIMATEVOL,
        field: SearchTicketFieldName.ESTIMATEVOL,
        width: 100,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.ESTIMATEVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[SearchTicketFieldName.ESTIMATEVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.ACTUALVOL,
        field: SearchTicketFieldName.ACTUALVOL,
        width: 80,
        filter: 'agNumberColumnFilter',
        cellEditorFramework: EditNominationCellRenderer,
        cellEditorParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('Save') ? false : true),
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.ACTUALVOL)
        },
        cellClass: () => {
            if(dataStore.checkOperationAccess('Save'))
                 return ['cell-editable', 'decimal-format']; 
            else return 'decimal-format'
        },
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.ACTUALVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[SearchTicketFieldName.ACTUALVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.PENDING,
        field: SearchTicketFieldName.PENDING,
        width: 85,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.PENDING] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[SearchTicketFieldName.PENDING]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.DISPATCHED,
        field: SearchTicketFieldName.DISPATCHED,
        width: 105,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DISPATCHED] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[SearchTicketFieldName.DISPATCHED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.COMPLETED,
        field: SearchTicketFieldName.COMPLETED,
        width: 105,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.COMPLETED] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[SearchTicketFieldName.COMPLETED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.PLANNEDDATEANDTIME,
        field: SearchTicketFieldName.PLANNEDDATEANDTIME,
        colId: SearchTicketFieldName.PLANNEDDATEANDTIME,
        width: 105,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.PLANNEDDATEANDTIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[SearchTicketFieldName.PLANNEDDATEANDTIME])//moment(new Date(params.data[SearchTicketFieldName.PLANNEDDATEANDTIME])).format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.NOMINATIONMONTH,
        field: SearchTicketFieldName.NOMINATIONMONTH,
        width: 105,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.NOMINATIONMONTH] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.NOMINATIONMONTH];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.NOMINATIONYEAR,
        field: SearchTicketFieldName.NOMINATIONYEAR,
        width: 105,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.NOMINATIONYEAR] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.NOMINATIONYEAR];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.DISPATCHERCOMMENTS,
        field: SearchTicketFieldName.DISPATCHERCOMMENTS,
        colId: SearchTicketFieldName.DISPATCHERCOMMENTS,
        width: 105,
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? true : false),
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, 'DispatcherComments'),
            isReadonly: (item, value) => {
                return (
                    !(dataStore.checkOperationAccess((RoleBasedActionName.Save)) && item[SearchTicketFieldName.TYPE] === 'Volume')
                );
            }
        },
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DISPATCHERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DISPATCHERCOMMENTS];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.DISPATCHERNOTES,
        field: SearchTicketFieldName.DISPATCHERNOTES,
        colId: SearchTicketFieldName.DISPATCHERNOTES,
        width: 105,
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? true : false),
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, 'DispatcherNotes'),
            isReadonly: (item, value) => {
                return (
                    !(dataStore.checkOperationAccess((RoleBasedActionName.Save)) && item[SearchTicketFieldName.TYPE] === 'Volume')
                );
            }
        },
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DISPATCHERNOTES] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DISPATCHERNOTES];
            }
        }
    },
    // {
    //     headerName: SearchTicketHeaderName.DRIVERCOMMENTS,
    //     field: SearchTicketFieldName.DRIVERCOMMENTS,
    //     colId: SearchTicketFieldName.DRIVERCOMMENTS,
    //     width: 100,
    //     cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
    //     editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
    //     cellEditorFramework: TextAreaCellRenderer,
    //     cellEditorParams: {
    //         onChange: (item, value) =>
    //             searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.DRIVERCOMMENTS)
    //     },
    //     valueGetter: params => {
    //         if (params.data[SearchTicketFieldName.DRIVERCOMMENTS] === null) {
    //             return '';
    //         } else {
    //             return params.data[SearchTicketFieldName.DRIVERCOMMENTS];
    //         }
    //     }
    // },
    {
        headerName: SearchTicketHeaderName.WORKINGTIME,
        field: SearchTicketFieldName.WORKINGTIME,
        colId: SearchTicketFieldName.WORKINGTIME,
        width: 90,
        cellRendererFramework: NumericCellRenderer,
        cellRendererParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.WORKINGTIME),
            isReadonly: (item, value) => {
                return (
                    !(dataStore.checkOperationAccess(RoleBasedActionName.Save) && item[SearchTicketFieldName.TYPE] === 'Hourly')
                );
            },
        },
        cellClass: params => {
            const { data } = params;
            if (isFloat(data[SearchTicketFieldName.WORKINGTIME])) {
                if (data.type == dataStore.checkOperationAccess(RoleBasedActionName.Save) && data[SearchTicketFieldName.TYPE] === 'Hourly')
                    return ['cell-editable', 'comma-format']
                else return 'comma-format'
            }
            else {
                if (data.type == dataStore.checkOperationAccess(RoleBasedActionName.Save) && data[SearchTicketFieldName.TYPE] === 'Hourly')
                    return ['cell-editable', 'work-wait']
                else return 'work-wait'
            }
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.WORKINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.WORKINGTIMEREASON,
        field: SearchTicketFieldName.WORKINGTIMEREASON,
        colId: SearchTicketFieldName.WORKINGTIMEREASON,
        width: 110,
        cellEditor: 'agSelectCellEditor',
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Hourly' ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Hourly' ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.WAITINGTIME,
        field: SearchTicketFieldName.WAITINGTIME,
        colId: SearchTicketFieldName.WAITINGTIME,
        width: 80,
        cellRendererFramework: NumericCellRenderer,
        cellRendererParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.WAITINGTIME),
            isReadonly: (item, value) => {
                return (
                    !(dataStore.checkOperationAccess(RoleBasedActionName.Save) && item[SearchTicketFieldName.TYPE] === 'Hourly')
                );
            },
        },
        cellClass: params => {
            const { data } = params;
            if (isFloat(data[SearchTicketFieldName.WAITINGTIME])) {
                if (data.type == dataStore.checkOperationAccess(RoleBasedActionName.Save) && params.data[SearchTicketFieldName.TYPE] === 'Hourly')
                    return ['cell-editable', 'comma-format']
                else return 'comma-format'
            }
            else {
                if (data.type == dataStore.checkOperationAccess(RoleBasedActionName.Save) && params.data[SearchTicketFieldName.TYPE] === 'Hourly')
                    return ['cell-editable', 'work-wait']
                else return 'work-wait'
            }
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.WAITINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: SearchTicketHeaderName.WAITINGTIMEREASON,
        field: SearchTicketFieldName.WAITINGTIMEREASON,
        colId: SearchTicketFieldName.WAITINGTIMEREASON,
        width: 115,
        cellEditor: 'agSelectCellEditor',
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Hourly'? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Hourly' ? true : false),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.COMMENT,
        field: SearchTicketFieldName.COMMENT,
        colId: SearchTicketFieldName.COMMENT,
        width: 100,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.COMMENT)
        },
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.COMMENT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.SHIPPERNAME,
        field: SearchTicketFieldName.SHIPPERNAME,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.SHIPPERNAME] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.SHIPPERNAME];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.SHIPPERTICKET,
        field: SearchTicketFieldName.SHIPPERTICKET,
        cellClass: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? 'cell-editable' : ''),
        editable: (params) => (dataStore.checkOperationAccess('Save') && params.data[SearchTicketFieldName.TYPE] === 'Volume' ? true : false),
        width: 105,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.SHIPPERTICKET] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.SHIPPERTICKET];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.RELATEDVOLTICKET,
        field: SearchTicketFieldName.RELATEDVOLUMETICKET,
        width: 125,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.RELATEDVOLUMETICKET] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.RELATEDVOLUMETICKET];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.ROADBAN,
        field: SearchTicketFieldName.ROADBAN,
        width: 95,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.ROADBAN] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.ROADBAN];
            }
        }
    },

    {
        headerName: SearchTicketHeaderName.VIA,
        field: SearchTicketFieldName.VIA,
        width: 90,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.VIA] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.VIA];
            }
        }
    },
    {
        field: SearchTicketFieldName.APPROVERCOMMENT,
        headerName: SearchTicketHeaderName.APPROVERCOMMENT,
        colId: SearchTicketFieldName.APPROVERCOMMENT,
        width: 95,
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                searchTicketsStore.updateCarrierCompanyInPopUp(item, value, SearchTicketFieldName.APPROVERCOMMENT),
            isReadonly: (item, value) => {
                return (
                    !(dataStore.checkOperationAccess((RoleBasedActionName.Save)))
                );
            }
        },
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.APPROVERCOMMENT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.BSW,
        field: SearchTicketFieldName.BSW,
        colId: SearchTicketFieldName.BSW,
        width: 60,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.BSW] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.BSW];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.DENSITY,
        field: SearchTicketFieldName.DENSITY,
        colId: SearchTicketFieldName.DENSITY,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.DENSITY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.DENSITY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.GROSSWEIGHT,
        field: SearchTicketFieldName.GROSSWEIGHT,
        colId: SearchTicketFieldName.GROSSWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.GROSSWEIGHT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.GROSSWEIGHT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TAREWEIGHT,
        field: SearchTicketFieldName.TAREWEIGHT,
        colId: SearchTicketFieldName.TAREWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TAREWEIGHT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TAREWEIGHT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.NETWEIGHT,
        field: SearchTicketFieldName.NETWEIGHT,
        colId: SearchTicketFieldName.NETWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.NETWEIGHT] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.NETWEIGHT];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.METERFACTOR,
        field: SearchTicketFieldName.METERFACTOR,
        colId: SearchTicketFieldName.METERFACTOR,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.METERFACTOR] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.METERFACTOR];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.METERNUMBER,
        field: SearchTicketFieldName.METERNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.METERNUMBER] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.METERNUMBER];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.OPENMETERREADING,
        field: SearchTicketFieldName.OPENMETERREADING,
        width: 110,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.OPENMETERREADING] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.OPENMETERREADING];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.CLOSEMETERREADING,
        field: SearchTicketFieldName.CLOSEMETERREADING,
        width: 115,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.CLOSEMETERREADING] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.CLOSEMETERREADING];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.POWERUNITID,
        field: SearchTicketFieldName.POWERUNITID,
        width: 105,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.POWERUNITID] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.POWERUNITID];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.AXLES,
        field: SearchTicketFieldName.AXLES,
        width: 85,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.AXLES] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.AXLES];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.LASTPTISTATUS,
        field: SearchTicketFieldName.LASTPMTSTATUS,
        width: 90,
        valueFormatter: (params: ValueFormatterParams): string => (params.value ? getLastPmtValue(params.value) : ''),
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LASTPMTSTATUS] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.LASTPMTSTATUS];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRAILERSTATUS,
        field: SearchTicketFieldName.TRAILERSTATUS,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TRAILERSTATUS] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TRAILERSTATUS];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRACTORUSED,
        field: SearchTicketFieldName.POWERUNITIDENTIFIER,
        width: 90,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.POWERUNITIDENTIFIER] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.POWERUNITIDENTIFIER];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRAILERMAPPEDTOCOMPANY,
        field: SearchTicketFieldName.TRAILERMAPPEDTOCOMPANY,
        width: 135,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TRAILERMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TRAILERMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.MISMATCH,
        width: 140,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LEASEOPERATOR] !== params.data[SearchTicketFieldName.TRAILERMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRACTORMAPPEDTOCOMPANY,
        field: SearchTicketFieldName.TRACTORMAPPEDTOCOMPANY,
        width: 140,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TRACTORMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.TRACTORMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TRACTORMISMATCH,
        width: 145,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.LEASEOPERATOR] !== params.data[SearchTicketFieldName.TRACTORMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.SYNCEDTORA,
        field: SearchTicketFieldName.SYNCEDTORA,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.SYNCEDTORA]) {
                return 'Yes';
            } else {
                return 'No';
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.TICKETSOURCE,
        field: SearchTicketFieldName.TICKETSOURCE,
        width: 80,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.TICKETSOURCE] === 'PDS') {
                return 'PDS';
            } else {
                return 'PMT';
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.STATUS,
        field: SearchTicketFieldName.STATUS,
        width: 75,
        cellEditor: 'agSelectCellEditor',
        editable: params =>
            params.data.TicketType == 'Hourly' &&
            params.data.TicketStatus !== 'Approved' &&
            params.data.TicketStatus !== TicketStatus.CANCELED,
        cellClass: params => {
            const { data } = params;
            return data.TicketType == 'Hourly' && data.TicketStatus !== 'Approved'  &&
                data.TicketStatus !== TicketStatus.CANCELED &&
                dataStore.checkOperationAccess(RoleBasedActionName.Save)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.STATUS] === null) {
                return '';
            } else if (params.data[SearchTicketFieldName.STATUS] === 'Assigned') {
                return 'Dispatched';
            } else {
                return params.data[SearchTicketFieldName.STATUS];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.CREATEDBY,
        field: SearchTicketFieldName.CREATEDBY,
        colId: SearchTicketFieldName.CREATEDBY,
        width: 85,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.CREATEDBY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.CREATEDDATE,
        field: SearchTicketFieldName.CREATEDDATE,
        colId: SearchTicketFieldName.CREATEDDATE,
        width: 85,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.CREATEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                    .tz(moment.utc(params.data[SearchTicketFieldName.CREATEDDATE]), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.MODIFIEDBY,
        field: SearchTicketFieldName.MODIFIEDBY,
        colId: SearchTicketFieldName.MODIFIEDBY,
        width: 90,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[SearchTicketFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: SearchTicketHeaderName.MODIFIEDDATE,
        field: SearchTicketFieldName.MODIFIEDDATE,
        colId: SearchTicketFieldName.MODIFIEDDATE,
        width: 90,
        valueGetter: params => {
            if (params.data[SearchTicketFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                    .tz(moment.utc(params.data[SearchTicketFieldName.MODIFIEDDATE]), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    }
];
