import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import DatePicker from 'react-datetime';
// import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Confirm } from './confirm';
library.add(faTrash, faCheckCircle);

export interface ConfirmProps {
    onSuccessClick: (type, volume, loadType, manualtype?) => void;
    onClose: () => void;
    product,
    isApplied;
}

export class GenerateLoadPopup extends React.Component<ConfirmProps> {
    selectedOption: any;
    fromDate;
    fromOneDay;
    from;
    date;
    endDate: any;
    toDate: any;
    fileInput: any;
    startDate: any = '';
    data: any = [];
    currentYear: any = new Date().getFullYear();
    state = {
        loadType: '',
        volume: '',
        invalidEmail: '',
        flag: false,
        popUpMsg: '',
        plainsvolume: '',
        nonplainsvolume: ''
    };
    loadType: any;
    type: any = '';
    manualType: any = '';
    constructor(props) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
    }
    public render(): React.ReactNode {
        const { onClose } = this.props;
        return (
            <div>
                <div className="newloadpopup __uploadingWidth" style={{ position: 'relative' }}>
                    {!this.state.flag && (
                        <div>
                            <div className="mt-10" style={{ fontWeight: 'bold', fontSize: '18px', padding: '10px' }}>
                                Generate Load Type:
                            </div>
                            {this.props.product.DataSource === 'Crude' && (
                                <Row style={{ paddingLeft: '16px' }}>
                                    <div style={{ padding: '0px 15px 10px', fontSize: '16px', margin: '0px' }}>
                                        Plains Ratio % :{' '}
                                        {this.props.product.ShipperName == 'Plains Midstream Canada ULC'
                                            ? (this.props.product.VolumeRatio * 100).toFixed(3)
                                            : (
                                                  (this.props.product.TotalRatio - this.props.product.VolumeRatio) *
                                                  100
                                              ).toFixed(3)}
                                        %
                                    </div>
                                    <div style={{ padding: '0px 15px 10px', fontSize: '16px', margin: '0px' }}>
                                        Others Ratio % :{' '}
                                        {this.props.product.ShipperName == 'Plains Midstream Canada ULC'
                                            ? (
                                                  (this.props.product.TotalRatio - this.props.product.VolumeRatio) *
                                                  100
                                              ).toFixed(3)
                                            : (this.props.product.VolumeRatio * 100).toFixed(3)}
                                        %
                                    </div>
                                </Row>
                            )}
                            <div onChange={this.handleChange} style={{ fontSize: '16px', margin: '0px' }}>
                                <div style={{ padding: '5px' }}>
                                    <input type="radio" value="opt1" name="type" style={{ padding: '0px 10px' }} />
                                    Are you resizing the loads ?
                                </div>
                                <div style={{ padding: '5px' }}>
                                    <input type="radio" value="opt2" name="type" style={{ padding: '0px 10px' }} />
                                    Are you adding or removing manual orders ?
                                </div>
                                <div style={{ padding: '5px' }}>
                                    {    //Crude - plains - not apportioned                                      
                                        (this.props.product.DataSource === 'Crude' && 
                                        this.props.product.ShipperName == 'Plains Midstream Canada ULC' &&                                          
                                        !this.props.isApplied) 
                                        ||
                                        //Crude - others - 100%
                                        (this.props.product.DataSource === 'Crude' && 
                                        this.props.product.ShipperName !== 'Plains Midstream Canada ULC' && 
                                        this.props.product.VolumeRatio < 1 && 
                                        !this.props.isApplied)
                                        ? <input type="radio" disabled value="opt3" name="type" style={{ padding: '0px 10px' }} />
                                        : <input type="radio" value="opt3" name="type" style={{ padding: '0px 10px' }} />
                                    }                                    
                                    Are you adding/removing loads that does not impact Vols to be trucked ?
                                </div>
                                <div style={{ padding: '5px' }}>
                                    <input type="radio" value="opt4" name="type" style={{ padding: '0px 10px' }} />
                                    Are you removing loads that impact Vols to be trucked ?
                                </div>
                            </div>
                            <div className="confirm__action mt-10">
                                <CustomButton
                                    type={CustomButtonType.Ok}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.loadType == ''}
                                >
                                    OK
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>
                    )}
                    {this.state.flag && (
                        <div>
                            {(this.loadType === 'opt2' || this.loadType === 'opt3') && (
                                <Row>
                                    <div
                                        onChange={this.setType}
                                        style={{ padding: '0px 15px 10px', fontSize: '16px', margin: '0px' }}
                                    >
                                        <input
                                            type="radio"
                                            value="ADD"
                                            name="type"
                                            className={`${this.state.invalidEmail}`}
                                            style={{ padding: '0px 10px', marginLeft: '0px ' }}
                                        />{' '}
                                        Add Loads
                                        <input
                                            type="radio"
                                            value="DELETE"
                                            name="type"
                                            className={`${this.state.invalidEmail}`}
                                            style={{ padding: '0px 10px' }}
                                        />{' '}
                                        Delete Loads
                                    </div>
                                </Row>
                            )}
                            {this.state.invalidEmail &&
                                ((this.loadType === 'opt2' && this.type == '') ||
                                    (this.loadType === 'opt3' && this.type == '')) && (
                                    <div className="email-error">Please Select the load type </div>
                                )}
                            {this.props.product.DataSource === 'Crude' && this.loadType === 'opt2' && (
                                <>
                                    <Row>
                                        <div style={{ padding: '0px 15px 10px', fontSize: '16px', margin: '0px' }}>
                                            Volume % Plains :{' '}
                                            {this.props.product.ShipperName == 'Plains Midstream Canada ULC'
                                                ? (this.props.product.VolumeRatio * 100).toFixed(3)
                                                : (
                                                      (this.props.product.TotalRatio - this.props.product.VolumeRatio) *
                                                      100
                                                  ).toFixed(3)}
                                            %
                                        </div>
                                    </Row>

                                    <Row>
                                        <div>
                                            <label
                                                style={{ padding: '0px 15px 10px', fontSize: '16px', margin: '0px' }}
                                            >
                                                {' '}
                                                ShipperType :{' '}
                                            </label>
                                        </div>
                                        <div
                                            onChange={this.setManualType}
                                            style={{ padding: '0px 10px 10px', fontSize: '16px', margin: '0px' }}
                                        >
                                            <input
                                                type="radio"
                                                value="Plains"
                                                name="manualtype"
                                                className={`${this.state.invalidEmail}`}
                                                style={{ padding: '0px 10px', marginLeft: '0px ' }}
                                            />{' '}
                                            Plains
                                            <input
                                                type="radio"
                                                value="Others"
                                                name="manualtype"
                                                className={`${this.state.invalidEmail}`}
                                                style={{ padding: '0px 10px' }}
                                            />{' '}
                                            Others
                                            <input
                                                type="radio"
                                                value="Plains/Others"
                                                name="manualtype"
                                                className={`${this.state.invalidEmail}`}
                                                style={{ padding: '0px 10px' }}
                                            />{' '}
                                            Plains/Others
                                        </div>
                                    </Row>
                                    {this.state.invalidEmail && this.manualType == '' && (
                                        <div className="email-error">Please Select the Shipper type </div>
                                    )}
                                </>
                            )}
                            <>
                                <div className="mt-10">Load Volume</div>
                                <Row className="mb-2">
                                    <Col>
                                        <div className="input">
                                            <input
                                                type="number"
                                                min={1}
                                                max={99999}
                                                className={`form-control ${this.state.invalidEmail}`}
                                                placeholder="Enter Load Volume"
                                                onChange={this.handleVolume}
                                                value={this.state.volume}
                                            />
                                            {this.state.invalidEmail &&
                                                (this.state.volume == '' || parseFloat(this.state.volume) == 0) && (
                                                    <div className="email-error">Please Enter the volume </div>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                            </>

                            <div className="confirm__action mt-10">
                                <CustomButton type={CustomButtonType.Ok} onClick={this.handleSave}>
                                    Save
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>
                    )}
                    {this.state.popUpMsg !== '' && (
                        <div
                            style={{
                                minHeight: '200px',
                                position: 'absolute',
                                margin: '150px 0 0 75px'
                            }}
                        >
                            <Confirm
                                onClose={() => {
                                    this.setState({ popUpMsg: '' });
                                }}
                                primaryText=""
                                secondaryText={this.state.popUpMsg}
                                onSuccessClick={this.handleContinue}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handleChange = event => {
        this.loadType = event.target.value;
        this.setState({ loadType: event.target.value });
        console.log(this.loadType);
    };

    isValidInput = (value) => {
        let valid = true
        if(parseFloat(value) >= 999999) valid = false
        let decimal = value.split('.')[1]
        if(decimal !== undefined && decimal.length > 3) valid = false
        return valid;
    }

    handleVolume = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        if(this.isValidInput(value)) {
        this.setState({volume: parseFloat(value)})
        this.setState({ invalidEmail: '' });
        } else event.preventDefault()
    };

    handlePlainsVolume = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ plainsvolume: value.length < 7 ? parseFloat(value) : parseFloat(value.slice(0, 6)) });
        this.setState({ invalidEmail: '' });
    };
    handleNonPlainsVolume = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ nonplainsvolume: value.length < 7 ? parseFloat(value) : parseFloat(value.slice(0, 6)) });
        this.setState({ invalidEmail: '' });
    };

    handleSubmit = () => {
        if (this.loadType === 'opt2' || this.loadType === 'opt3' || this.loadType === 'opt4')
            this.setState({ flag: true });
        else if (this.loadType === 'opt1') {
            this.props.onSuccessClick(this.loadType, 0, '');
            this.props.onClose();
        }
    };

    setType = async event => {
        this.type = event.target.value;
    };

    setManualType = event => {
        this.manualType = event.target.value;
    };

    handleContinue = () => {
        if (
            this.props.product.DataSource !== 'Crude' ||
            (this.props.product.DataSource === 'Crude' && this.loadType !== 'opt2')
        ) {
            this.props.onSuccessClick(this.loadType, parseFloat(this.state.volume), this.type);
        } else {
            this.props.onSuccessClick(this.loadType, parseFloat(this.state.volume), this.type, this.manualType);
        }
        this.props.onClose();
    };

    handleSave = () => {
        if (
            this.props.product.DataSource !== 'Crude' &&
            this.state.volume !== '' &&
            parseFloat(this.state.volume) > 0 &&
            ((this.loadType === 'opt2' && this.type !== '') ||
                (this.loadType === 'opt3' && this.type !== '') ||
                this.loadType === 'opt1' ||
                this.loadType === 'opt4')
        ) {
            let msg = '';
            if (this.loadType === 'opt3') {
                msg = 'Change in loads does not impact Vols to be trucked. Do you want to continue ?';
            } else if (this.loadType == 'opt4') {
                msg = 'Change in loads impact Vols to be trucked. Do you want to continue ?';
            } else if (this.loadType == 'opt2') {
                msg = 'Change in manual loads. Do you want to continue ?';
            }
            this.setState({ popUpMsg: msg });
        } else if (
            this.props.product.DataSource === 'Crude' &&
            this.state.volume !== '' &&
            parseFloat(this.state.volume) > 0 &&
            ((this.loadType === 'opt2' && this.type !== '' && this.manualType !== '') ||
                (this.loadType === 'opt3' && this.type !== '') ||
                this.loadType === 'opt1' ||
                this.loadType === 'opt4')
        ) {
            let msg = '';
            if (this.loadType === 'opt3') {
                msg = 'Change in loads does not impact Vols to be trucked. Do you want to continue ?';
            } else if (this.loadType == 'opt4') {
                msg = 'Change in loads impact Vols to be trucked. Do you want to continue ?';
            } else if (this.loadType == 'opt2') {
                msg = 'Change in manual loads. Do you want to continue ?';
            }
            this.setState({ popUpMsg: msg });
        } else {
            this.setState({ invalidEmail: 'invalid-email' });
        }
    };
}
