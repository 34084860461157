import { GridOptions } from 'ag-grid-community';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import React from 'react';
import './_nomination-screen.scss';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { CrudeRatioPlains } from './crudeRatio-Plains';
import { toJS } from 'mobx';
import { agGridService } from 'features/common/services/ag-grid-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
import { nominationDataService } from '../services/nomination-data-service';
import { uiService } from 'shared/services/ui-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { dataStore } from 'features/common/stores';
import { commaFormat, schedulerRoles } from '../nomination-constants';
import { accountStore } from 'features/account/stores';

export interface NominationLoadsPopup {
    nominationDataStore;
    getLoadGridConfig: GridOptions;
    selectButtonText: string;
    checkSupplierRole: boolean;
    selectedMonth: string;
    selectedYear: string;
    selectedRegion: string;
    activeDrivers: any;
    NominationSum: number;
    VTTSum: number;
    TOVSum: number;
    CompletedSum: number;
    Completedpercentage: number;
    isDisabled: boolean;
    isSaveDisabled: boolean;
    planLoadsDisabled: boolean;
    selectAllDisabled: boolean;
    handleNewRecord: () => void;
    handleRegister: () => void;
    handleReset: () => void;
    handleEdit: () => void;
    handleSelectAll: () => void;
    exportExcel: () => void;
    handleCancel: () => void;
}
export const NominationLoadsPopup = (props: NominationLoadsPopup) => {
    const {
        nominationDataStore,
        getLoadGridConfig,
        selectButtonText,
        checkSupplierRole,
        selectedMonth,
        selectedYear,
        selectedRegion,
        activeDrivers,
        NominationSum,
        VTTSum,
        TOVSum,
        CompletedSum,
        Completedpercentage,
        isDisabled,
        isSaveDisabled,
        planLoadsDisabled,
        selectAllDisabled,
        handleNewRecord,
        handleRegister,
        handleReset,
        handleEdit,
        handleSelectAll,
        exportExcel,
        handleCancel
    } = props;

    const filteredData: any = nominationDataStore.nominationLoadData;
    const filters: any = agGridService.getAllFilters();
    const dataLoad = filteredData.filter(dat =>
        Object.keys(filters).every(k => {
            if (k === 'ag-Grid-AutoColumn') return filters[k].values.includes(dat['CustomerName']);
            else return filters[k].values.includes(dat[k]);
        })
    );
    function getSum(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }
    async function refreshLoads() {
        let nominationLoadData: any = [];
        nominationDataStore.navigated = true;
        nominationDataStore.selected = true;
        const reqbody = {
            CustomerName: nominationDataStore.selectedData.CustomerName,
            ContractNumber: nominationDataStore.selectedData.ContractNumber,
            OriginID: nominationDataStore.selectedData.OriginID,
            DestinationID: nominationDataStore.selectedData.DestinationID,
            NominationMonth: nominationDataStore.selectedData.NominationMonth,
            NominationYear: nominationDataStore.selectedData.NominationYear,
            AX_Origin:
                nominationDataStore.selectedData.OriginID === null ? nominationDataStore.selectedData.AX_Origin : '',
            AX_Destination:
                nominationDataStore.selectedData.DestinationID === null
                    ? nominationDataStore.selectedData.AX_Destination
                    : '',
            ToCode: nominationDataStore.selectedData.ToCode,
            Product: nominationDataStore.selectedData.Product,
            DataSource: nominationDataStore.selectedData.DataSource
        };
        uiService.loaderService.showLoader();
        const response = await nominationDataService.getNominationLoadData(reqbody);
        uiService.loaderService.hideLoader();
        nominationLoadData = response.data['Data'].filter(element => {
            return element.CustomerName == nominationDataStore.selectedData.CustomerName;
        });
        let count = 0;
        const loadDeleted: any = [];
        nominationDataStore.selectedData.Pending = getSum(nominationLoadData, 'Pending');
        nominationDataStore.selectedData.Dispatched = getSum(nominationLoadData, 'Dispatched');
        nominationDataStore.selectedData.Completed = getSum(nominationLoadData, 'Completed');
        count = nominationDataStore.selectedData.ApportionmentVolume
            ? parseInt(nominationDataStore.selectedData.ApportionmentVolume)
            : 0;
        nominationLoadData.forEach((loaddata, ind) => {
            loaddata.IsChild = true;
            loaddata.IsSelected = false;
            loaddata.IsLoad = true;
            loaddata.IsInvalid = false;
            loaddata.CurrentNomination = null;
            loaddata.DataSource = nominationDataStore.selectedData.DataSource;
            loaddata.Id = parseInt(ind) + 1;
            loaddata.Pending = loaddata.Pending ? loaddata.Pending : 0.0;
            loaddata.Dispatched = loaddata.Dispatched ? loaddata.Dispatched : 0.0;
            loaddata.Completed = loaddata.Completed ? loaddata.Completed : 0.0;
        });
        const load = toJS(nominationDataStore.selectedData);
        nominationLoadData.unshift(load);
        nominationLoadData = nominationDataStore.orderedLoadDataBasedOnStatus(nominationLoadData);
        nominationDataStore.nominationLoadData = nominationLoadData;
        nominationDataStore.setBackupnominationData(nominationLoadData);
        nominationDataStore.navigated = true;
        nominationDataStore.isLoadPopupVisible = true;
        nominationDataStore.cellValueChangeMap = {};
        let data: any = nominationDataStore.nominationLoadData;
        data.map((item, index) => {
            nominationDataStore.dataToBeDeleted = [];
            item.DELETE = false;
            nominationDataStore.selectButtonText = 'Select All';
        });
        nominationDataStore.nominationLoadData = data;
        nominationDataStore.agGridService.setAllFilters(localStorageService.get('NominationLoad_filter'));
    }
    function handleRefresh() {
        if (nominationDataStore.isLoadRowEdited === false && nominationDataStore.dataToBeDeleted.length === 0) {
            refreshLoads();
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    refreshLoads();
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
        nominationDataStore.isLoadRowEdited = false;
        refreshLoads();
    }
    function getSumLoad(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && x['IsLoad'] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }

    function checkSchedulerRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (schedulerRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    const getTOV = () => {};
    const VTT_Sum =
        VTTSum != 0
            ? VTTSum
            : (nominationDataStore.selectedData?.VolsToBeTrucked !== null && nominationDataStore.selectedData?.VolsToBeTrucked > 0)
            ? nominationDataStore.selectedData?.VolsToBeTrucked.toFixed(3)
            : '0.000';
    const Completed_Sum: any = CompletedSum < 0 ? '0.000' : CompletedSum != 0 ? CompletedSum : getSumLoad(filteredData, 'Completed').toFixed(3);
    const TOV_Sum =
        TOVSum < 0 ? '0.000' :
        TOVSum != 0
            ? TOVSum
            : nominationDataStore.selectedData?.PendingVolume ||
              nominationDataStore.selectedData?.DispatchedVolume ||
              nominationDataStore.selectedData?.CompletedVolume
            ? (
                  nominationDataStore.selectedData?.PendingVolume +
                  nominationDataStore.selectedData?.DispatchedVolume +
                  nominationDataStore.selectedData?.CompletedVolume
              ).toFixed(3)
            : '0.000';
    return (
        <div className="modal" id="load-modal">
            <div id="load-modal-content">
                <div className="modal-body" id="load-modal-body">
                    <div className="container-header">
                        <p>
                            {' '}
                            <b>
                                {' '}
                                {selectedMonth} {selectedYear}{' '}
                            </b>{' '}
                            - {selectedRegion} Region{' '}
                        </p>
                        <div>
                            {dataStore.checkOperationAccess('Save') && (
                                <>
                                    {!checkSchedulerRole() && 
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={handleNewRecord}
                                        disabled={
                                            nominationDataStore.isEditDisabled() || checkSupplierRole || isDisabled
                                        }
                                    >
                                        {' '}
                                        Load Adjustments{' '}
                                    </CustomButton>}{' '}
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={handleRegister}
                                        disabled={nominationDataStore.isSaveDisabled() || isSaveDisabled}
                                    >
                                        {' '}
                                        Save{' '}
                                    </CustomButton>{' '}
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={handleReset}
                                        disabled={nominationDataStore.isSaveDisabled() || isSaveDisabled}
                                    >
                                        {' '}
                                        Discard{' '}
                                    </CustomButton>{' '}
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={handleEdit}
                                        disabled={planLoadsDisabled}
                                    >
                                        {' '}
                                        Plan Loads{' '}
                                    </CustomButton>{' '}
                                </>
                            )}
                            <ClearButtonComponent handleRefresh={handleRefresh}></ClearButtonComponent>{' '}
                            {dataStore.checkOperationAccess('Save') && (
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={handleSelectAll}
                                    disabled={selectAllDisabled}
                                >
                                    {' '}
                                    {selectButtonText}{' '}
                                </CustomButton>
                            )}{' '}
                            <CustomButton type={CustomButtonType.Submit} onClick={exportExcel}>
                                {' '}
                                Export to excel{' '}
                            </CustomButton>{' '}
                            <CustomButton type={CustomButtonType.Submit} onClick={handleCancel}>
                                {' '}
                                Back{' '}
                            </CustomButton>{' '}
                        </div>
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="mt-3">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Customer Name: </b>
                                                {nominationDataStore.selectedData.CustomerName}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Contract Number: </b>
                                                {nominationDataStore.selectedData.ContractNumber === 'Dummy' ||
                                                nominationDataStore.selectedData.ContractNumber?.startsWith(
                                                    'Contract'
                                                ) ||
                                                nominationDataStore.selectedData.CustomerName === 'New Location'
                                                    ? ''
                                                    : nominationDataStore.selectedData.ContractNumber}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Product: </b>
                                                {nominationDataStore.selectedData.CustomerName === 'New Location'
                                                    ? ''
                                                    : nominationDataStore.selectedData.Product}
                                            </p>
                                        </div>{' '}
                                    </Col>{' '}
                                </Row>
                                <Row>
                                    <Col className="mt-3">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Origin: </b>
                                                {nominationDataStore.selectedData.AX_Origin}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-3">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Destination: </b>
                                                {nominationDataStore.selectedData.AX_Destination}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-6"> </Col>{' '}
                                </Row>{' '}
                                <br />
                                <Row>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Initial Nomination: </b>
                                                {nominationDataStore.selectedData.NominationVolume
                                                    ? commaFormat(nominationDataStore.selectedData.NominationVolume.toFixed(3))
                                                    : '0.000'}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Current Nomination: </b>
                                                {NominationSum != 0
                                                    ? commaFormat(NominationSum)
                                                    : nominationDataStore.selectedData?.CurrentNomination !== null
                                                    ? commaFormat(nominationDataStore.selectedData?.CurrentNomination.toFixed(3))
                                                    : '0.000'}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Apportionment: </b>
                                                {nominationDataStore.selectedData.ApportionmentVolume
                                                    ? commaFormat(nominationDataStore.selectedData.ApportionmentVolume.toFixed(3))
                                                    : '0.000'}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Vols to be trucked: </b>
                                                {commaFormat(VTT_Sum)}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Total Order Volume: </b>
                                                {TOVSum != 0
                                                    ? commaFormat(TOVSum)
                                                    : nominationDataStore.selectedData.PendingVolume ||
                                                      nominationDataStore.selectedData.DispatchedVolume ||
                                                      nominationDataStore.selectedData.CompletedVolume
                                                    ? commaFormat((
                                                          nominationDataStore.selectedData.PendingVolume +
                                                          nominationDataStore.selectedData.DispatchedVolume +
                                                          nominationDataStore.selectedData.CompletedVolume
                                                      ).toFixed(3))
                                                    : '0.000'}
                                            </p>
                                        </div>{' '}
                                    </Col>
                                    <Col className="mt-2">
                                        <div className="react-select-inner-container-new">
                                            <p>
                                                <b> Completed %: </b>
                                                {VTT_Sum !== '0.000'
                                                    ? commaFormat(((Completed_Sum * 100) / VTT_Sum).toFixed(3))
                                                    : TOV_Sum !== '0.000'
                                                    ? commaFormat(((Completed_Sum * 100) / TOV_Sum).toFixed(3))
                                                    : '0.000'}
                                            </p>
                                        </div>{' '}
                                    </Col>{' '}
                                </Row>
                            </Col>
                        </Row>{' '}
                    </Container>
                    <br />
                    <AgGridComponent gridConfig={getLoadGridConfig} />
                    <div className="customer-report">
                        <Row>
                            <Col className="mt-3 mt-sm-0">
                                CurrentNomination Sum :{' '}
                                {NominationSum != 0
                                    ? commaFormat(NominationSum)
                                    : nominationDataStore.selectedData?.CurrentNomination !== null
                                    ? commaFormat(nominationDataStore.selectedData?.CurrentNomination.toFixed(3))
                                    : '0.000'}
                            </Col>
                            <Col className="mt-3 mt-sm-0">VTT Sum : {commaFormat(VTT_Sum)}</Col>
                            <Col className="mt-3 mt-sm-0">
                                Total Order Volume Sum :{' '}
                                {TOVSum != 0
                                    ? commaFormat(TOVSum)
                                    : nominationDataStore.selectedData.PendingVolume ||
                                      nominationDataStore.selectedData.DispatchedVolume ||
                                      nominationDataStore.selectedData.CompletedVolume
                                    ? commaFormat((
                                          nominationDataStore.selectedData.PendingVolume +
                                          nominationDataStore.selectedData.DispatchedVolume +
                                          nominationDataStore.selectedData.CompletedVolume
                                      ).toFixed(3))
                                    : '0.000'}
                            </Col>
                            <Col className="mt-3 mt-sm-0">Completed Volume Sum : {commaFormat(Completed_Sum)}</Col>
                            <Col className="mt-3 mt-sm-0">
                                Completed Volume % :{' '}
                                {VTT_Sum !== '0.000'
                                    ? commaFormat(((Completed_Sum * 100) / VTT_Sum).toFixed(3))
                                    : TOV_Sum !== '0.000'
                                    ? commaFormat(((Completed_Sum * 100) / TOV_Sum).toFixed(3))
                                    : '0.000'}
                            </Col>
                        </Row>
                    </div>{' '}
                </div>{' '}
            </div>{' '}
        </div>
    );
};
