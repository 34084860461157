import React from "react";

interface ReactFromSelectProps {
    id: string;
    name: string;
    options: any[];
    className: string;
    multiple?: boolean;
    jsonValue?: boolean;
    placeholder: string;
    onBlur: (event) => void;
    onChange: (event) => void;
    mappingFunc: (option: any) => ({ selectedValue: string, displayValue: string });
}

export const ReactFromSelect = (props: ReactFromSelectProps) => {
    const { id, name, options, className, multiple, jsonValue, placeholder, onBlur, onChange, mappingFunc } = props;
    const onChangeWrapper = (event) => {
        const { target: { options, selectedIndex } } = event;
        if (selectedIndex < 0) {
            onChange(event);
            return;
        }
        const objStr = options[selectedIndex].getAttribute('data-val');
        const obj = JSON.parse(objStr);
        onChange({
            ...event,
            selectedOptionProp: {
                value: obj,
                selector: name
            }
        });
    }

    const generateOption = (options) => {
        return options.map(opt => {
            const oprime = mappingFunc(opt);
            const serializedObj = JSON.stringify(opt);
            return (
                <option key={oprime.selectedValue} data-val={serializedObj} value={jsonValue ? serializedObj : oprime.selectedValue}>
                    {oprime.displayValue}
                </option>
            );
        });
    }

    return id === 'Facility' ? (
        <>
        <div
            style={{
                fontSize: '13px',
                fontWeight: 400,
                color: '#495057',
                paddingBottom: '6px',
                paddingLeft: '16px'
            }}
        >
            {placeholder}
        </div>
        <select
            id={id}
            name={name}
            onBlur={onBlur}
            multiple={multiple}
            onChange={onChangeWrapper}
            className={className}
        >
            {generateOption(options)}
        </select>
        </>
    ) : (
        <select
            id={id}
            name={name}
            onBlur={onBlur}
            multiple={multiple}
            onChange={onChangeWrapper}
            className={className}
        >
            <option value="" disabled selected>{placeholder}</option>
            {generateOption(options)}
        </select >
    );
}

ReactFromSelect.defaultProps = {
    multiple: false,
    jsonValue: false
}