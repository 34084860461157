import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { UpdateDispatchPlanResponse } from 'features/dispatch/dispatch-plan-management/model/model';
import { AppConstant } from 'app_constant';

export class DispatchScreenDataService {
    constructor(private restApi: RestApiService) {}

    public getDispatchBoardMapping(reqBody) {
        return this.restApi.postWithToken (Config.getDispatchBoardMapping, reqBody)
    }

    public getDriverBoardMapping(reqBody) {
        return this.restApi.postWithToken (Config.getDriverBoardMapping, reqBody)
    }

    public getDriverCCTicketDetails(reqBody) {
        return this.restApi.postWithToken (Config.getDriverCCTicketDetails, reqBody)
    }

    public getPendingTicketDetails(reqBody) {
        return this.restApi.postWithToken (Config.getPendingTicketDetails, reqBody)
    }

    public updateDispatchBoardMapping(reqBody) {
        return this.restApi.postWithToken (Config.createUpdateDispatchBoardMapping, reqBody)
    }

    public updateDriverBoardMapping(reqBody) {
        return this.restApi.postWithToken (Config.createUpdateCapacityPlan, reqBody)
    }

    async updateDispatchPlanStatus(reqbody): Promise<UpdateDispatchPlanResponse[]> {
        const { data } = await this.restApi.post<any>(Config.updateDispatchPlanStatus, reqbody, {});
        return data;
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }

    public getAssignedTicketDetails(reqBody) {
        return this.restApi.postWithToken (Config.getAssignedTicketDetails, reqBody)
    }
}

export const dispatchScreenDataService = new DispatchScreenDataService(restApiService);
