import { AppConstant } from 'app_constant';
import { reportticketStatus } from 'features/reports/reports_constants';
import { toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select, { ActionMeta } from 'react-select';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import DatePicker from 'react-datetime';
// import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { dateUtils } from '../../../shared/services/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ConfirmService } from './confirm-service';
import { Confirm } from './confirm';
import zIndex from '@material-ui/core/styles/zIndex';
import { NominationStore, nominationStore } from 'features/dispatch/nomination/stores/nominatation-data-store';
import { dispatchPlanDataService } from 'features/dispatch/dispatch-plan-management/services/dispatchPlan-data-service';
import { NominationDataRequest } from 'features/dispatch/nomination/models/model';
import { nominationDataService } from 'features/dispatch/nomination/services/nomination-data-service';
import { accountStore } from 'features/account/stores';
import { suppliersRoles } from 'features/dispatch/nomination/nomination-constants';
library.add(faTrash, faCheckCircle);

const options = [
    { value: 'AWAITINGACTIVATION', label: 'Awaiting Activation' },
    { value: 'APPROVED', label: 'Approved' },
    { value: 'INACTIVE', label: 'Inactive' }
];
export interface ConfirmProps {
    onAddClick: (email) => void;
    onDeleteClick: (email) => void;
    onClose: () => void;
    data;
    selectedRole;
    userInfo
}

export class AdUpdatePopup extends React.Component<ConfirmProps> {
        signedUpRole = (this.props.selectedRole) ? (this.props.selectedRole === 'Owner') ? 'Owner/Driver': (this.props.selectedRole === 'CarrierOwner') ? 'Carrier Company Admin' : this.props.selectedRole : '';
        state = {
            role: this.signedUpRole ? this.signedUpRole: '',
            userName: this.props.userInfo ? this.props.userInfo.LoginName : ''
        };
        role = '';
        public render(): React.ReactNode {
            const { onClose, data } = this.props;
            return (
                <div className="email">
                    <div className="confirm__action mt-10"><b>{this.state.userName}</b></div>
                    <div>Select Role</div>
                    {data.map(X => {
                        const f1 = a => t => this.selectRole(X);
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <>
                                <div
                                    className= {this.state.role === X.Name ? "roleactive" : "roleInactive"}
                                    style={{
                                        padding: '10px 10px'
                                    }}
                                    onClick={f1(X)}
                                >{X.Name}
                                </div>
                            </>
                        );
                    })}
    
                    <div className="confirm__action mt-10">
                        <CustomButton type={CustomButtonType.Ok} disabled={this.signedUpRole === this.state.role} onClick={this.handleAdd}>
                            Update
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                            Close
                        </CustomButton>
                    </div>
                </div>
            );
        }
        selectRole(selectedRole) {
            this.role = selectedRole.Name;
            this.setState({ role: selectedRole.Name });
        }
    
        private handleAdd= () => {
            if (this.state.role !== '') {
                this.props.onAddClick(this.state.role);
                this.props.onClose();
            }
        };

        private handleDelete= () => {
            if (this.state.role !== '') {
                this.props.onDeleteClick(this.state.role);
                this.props.onClose();
            }
        };
    }
    