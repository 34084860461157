import { AppConstant } from 'app_constant';
import { reportticketStatus } from 'features/reports/reports_constants';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select, { ActionMeta } from 'react-select';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import DatePicker from 'react-datetime';
// import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { dateUtils } from '../../../shared/services/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ConfirmService } from './confirm-service';
import { Confirm } from './confirm';
import zIndex from '@material-ui/core/styles/zIndex';
import { NominationStore, nominationStore } from 'features/dispatch/nomination/stores/nominatation-data-store';
import { dispatchPlanDataService } from 'features/dispatch/dispatch-plan-management/services/dispatchPlan-data-service';
import { NominationDataRequest } from 'features/dispatch/nomination/models/model';
import { nominationDataService } from 'features/dispatch/nomination/services/nomination-data-service';
import { accountStore } from 'features/account/stores';
import { schedulerRoles, suppliersRoles } from 'features/dispatch/nomination/nomination-constants';
library.add(faTrash, faCheckCircle);

export interface ConfirmProps {
    onSuccessClick: (recurrence, driver, company, date, selectedLoads, dispatchType) => void;
    onClose: () => void;
    startDate;
    endDate;
    loads;
    msg;
    companyInfo;
    selectedLoads;
}
const options = [
    { value: 'daily', label: 'Daily' },
    { value: 'adhoc', label: 'AdHoc' }
];

export class LoadPopup extends React.Component<ConfirmProps> {
    selectedOption: any;
    selectedDriver: any;
    selectedCompany: any;
    selectedDriverID: any;
    selectedCompanyID: any;
    fromDate;
    fromOneDay;
    from;
    date;
    endDate: any;
    toDate: any;
    fileInput: any;
    startDate: any = '';
    driverInfo: any = [];
    @observable data: any = [{ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false }];
    currentYear: any = new Date().getFullYear();
    state = {
        popUpMsg: '',
        startDate: '',
        currentMonth: new Date().getMonth() + 1,
        currentYear: new Date().getFullYear(),
        invalidEmail: '',
        invalidLoadData: '',
        excessLoad: '',
        data: [{ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '',IsError: false, IsEdit: false }],
        remainingCount: this.props.loads,
        driverPlaceholder: "Select Driver",
        companyPlaceholder: "Select Carrier",
        showDriverError: false,
        driverNames: [],
        companyNames: [],
        showLoader: false,
        dispatchType: 'Driver'
    };
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    public render(): React.ReactNode {
        const { onClose, startDate, loads, msg, companyInfo } = this.props;
        const driver = Array.from(new Set(msg.map((item: any) => item.DriverName)));
        const selectStyles = {
            control: (styles) => ({ ...styles, border: '1px solid #1eb2f3' }),
            placeholder: (styles) => ({ ...styles, color: '#000000', fontWeight: '400', marginRight: 0, fontSize: '10.3px' })
        }
        const defaultDate = moment(startDate).add(1, 'days');
        const companyDropdown: any = Array.from(new Set(companyInfo.map((item: any) => item.CompanyName)))
        return (
            <>
                <div>
                    <div className="loadpopup __uploadingWidth" style={{ position: 'relative', padding: '15px', width: '620px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginTop: '10px'}}>Select Dispatch Type : </div>
                        <div style={{marginTop: '12px'}}>
                            <input type="radio" checked = {this.state.dispatchType === 'Driver'} onClick={this.handleRadioButtonChange} id='Driver' value ='Driver'/>
                            <div style={{display: 'inline-block'}}><label htmlFor = {'Driver'} style={{fontSize: '15px'}}>Driver</label></div>
                        
                            <input type="radio" checked = {this.state.dispatchType === 'Carrier'} onClick={this.handleRadioButtonChange} id='Carrier' value ='Carrier'/>
                            <div style={{display: 'inline-block'}}><label htmlFor = {'Carrier'} style={{fontSize: '15px'}}>Carrier</label></div>
                    </div></div>
                        <Row className="mb-2">
                            <Col>
                                <div className="form-inline padding-bottom">
                                    Recurrence :{' '}
                                    <div className="reportmanagement__type">
                                        <Select
                                            value={this.selectedOption}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder="Select Status"
                                            options={options}
                                            onChange={this.handleDataTypeChange}
                                            isMulti={false}
                                        />
                                    </div>{' '}
                                </div>
                            </Col>
                            {this.selectedOption?.value !== 'adhoc' && (
                                <Col>
                                    <div className="form-inline padding-bottom">
                                        Select Date:{' '}
                                        <span className="InputFromTo-from">
                                            <DatePicker
                                                inputProps={{
                                                    style: { width: 250 },
                                                    placeholder: 'Select Start Date Time'
                                                }}
                                                value={this.startDate}
                                                initialViewDate={defaultDate}
                                                isValidDate={this.valid}
                                                dateFormat="MM-DD-YYYY"
                                                timeFormat="hh:mm A"
                                                onChange={val => this.handleChange(val)}
                                            />
                                            {/* <DayPickerInput
                                        ref={el => (this.fromDate = el)}
                                        value={this.from}
                                        inputProps={{ readOnly: true }}
                                        format="MM/dd/yyyy"
                                        formatDate={(value, _format, _locale) => {
                                            return moment(value).format(AppConstant.DATE_FORMAT);
                                        }}
                                        placeholder="Start Date"
                                        dayPickerProps={{
                                            selectedDays: this.from,
                                            onDayClick: (date, modifiers) => {
                                                if (!modifiers.disabled) {
                                                    this.startDate = date;
                                                    this.from = date;
                                                    this.fromOneDay = date;
                                                }
                                            }
                                        }}
                                    /> */}
                                        </span>{' '}
                                    </div>
                                </Col>
                            )}
                        </Row>
                        {this.state.invalidEmail && this.selectedOption?.value !== 'adhoc' && (
                            <div className="email-error">Please select both Recurrence and StartDate</div>
                        )}

                        {this.selectedOption?.value === 'adhoc' && (
                            <>
                                <>Total loads : {loads}</>
                                <div>Remaining loads to be scheduled : {this.state.remainingCount} </div>
                                {/* <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <CustomButton
                                    type={CustomButtonType.Ok}
                                    onClick={this.editSchedule}
                                    disabled={this.state.remainingCount <= 0}
                                >
                                    Schedule
                                </CustomButton>
                            </div> */}

                                {this.data.length > 0 && (
                                        <Row style={{ padding: '5px', paddingBottom: '0px' }}>
                                        <Col md={3}> No.of Loads</Col>
                                        <Col md={3}>Planned Date</Col>
                                        {!this.checkSupplierRole() && !this.checkSchedulerRole() &&
                                        <Col md={4}>&emsp;&ensp;{this.state.dispatchType} (optional)</Col>}
                                    </Row>
                                )}
                            </>
                        )}

                        {this.selectedOption?.value === 'adhoc' &&
                            this.data.map((X, ind) => {
                                return (
                                    <>
                                        <Row style={{padding: '5px', paddingBottom: '0px'}}>
                                            <Col md={2}>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={999999}
                                                    value={X.loads ? X.loads : ''}
                                                    autoComplete="off"
                                                    placeholder=""
                                                    onChange={event =>
                                                        this.handleSchedule(X, event.currentTarget.value, 'loads')
                                                    }
                                                    style={{
                                                        width: '90px',
                                                        height: '37px',
                                                        border: !X.IsError ? '1px solid #1eb2f3' : '1px solid red',
                                                        borderRadius: 5,
                                                        textAlign: 'center',
                                                        fontSize: 15
                                                    }}
                                                ></input>
                                            </Col>
                                            <Col md={4}>
                                                {/* <DatePicker
                                                ref={el => (this.toDate = el)}
                                                isValidDate={this.valid}
                                                value={X.date}
                                                dateFormat="MM-DD-YYYY"
                                                timeFormat="hh:mm A"
                                                onChange={selectedDay => this.handleSchedule(X, selectedDay, 'date')}
                                                inputProps={{
                                                    style: {
                                                        width: '130px',
                                                        height: '35px',
                                                        border: '1px solid #1eb2f3',
                                                        borderRadius: 5,
                                                        textAlign: 'center',
                                                        fontSize: 10
                                                    },
                                                    placeholder: 'mm-dd-yyyy'
                                                }}
                                            /> */}
                                                <DatePicker
                                                    inputProps={{
                                                        style: {
                                                            width: '180px',
                                                            height: '37px',
                                                            border: !X.IsError ? '1px solid #1eb2f3' : '1px solid red',
                                                            borderRadius: 5,
                                                            textAlign: 'center',
                                                            fontSize: 15
                                                        },
                                                        placeholder: 'mm-dd-yyyy',
                                                        value: X.date!= '' ? moment(X.date).format("MM-DD-YYYY hh:mm A"): ''
                                                    }}
                                                    value={X.date}
                                                    initialViewDate={defaultDate}
                                                    isValidDate={this.valid}
                                                    dateFormat="MM-DD-YYYY"
                                                    timeFormat="hh:mm A"
                                                    onChange={val => this.handleSchedule(X, val, 'date')}
                                                />
                                            </Col>
                                            {!this.checkSupplierRole() && !this.checkSchedulerRole() && <>
                                            <Col md={4}>
                                                <div style={{width: '240px'}}>
                                                    {this.state.dispatchType === 'Driver' ? <Select
                                                        styles={selectStyles}
                                                        value={X.driverID? X.driverID : ''}
                                                        closeMenuOnSelect={true}
                                                        closeMenuOnScroll={true}
                                                        placeholder={X.driverName ? X.driverName : 'Select Driver'}
                                                        options={this.state.driverNames}
                                                        onMenuOpen={()=>{this.handleDropdownChange(X)}}
                                                        onChange={val => this.handleSchedule(X, val, 'driver')}
                                                        getOptionLabel={option => option}
                                                        getOptionValue={option => option}
                                                    /> : <Select
                                                    styles={selectStyles}
                                                    value={X.carrierID? X.carrierID : ''}
                                                    closeMenuOnSelect={true}
                                                    closeMenuOnScroll={true}
                                                    placeholder={X.companyName ? X.companyName : 'Select Carrier'}
                                                    options={companyDropdown}
                                                    onChange={val => this.handleSchedule(X, val, 'carrier')}
                                                    getOptionLabel={option => option}
                                                    getOptionValue={option => option}
                                                />}
                                                </div>
                                            </Col><Col md={1}></Col></> }
                                            <Col md={1}>
                                                <div className="delete-icon">
                                                    <FontAwesomeIcon
                                                        icon="trash"
                                                        size="sm"
                                                        onClick={() => {
                                                            this.deleteItem(ind);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                );
                            })}
                        {this.state.invalidLoadData && this.selectedOption?.value === 'adhoc' && (
                            <div className="email-error">
                                Please enter No. of Loads and Planned Date
                            </div>
                        )}
                        {this.state.excessLoad && this.selectedOption?.value === 'adhoc' && (
                            <div className="email-error">Number of loads scheduled is more than the total loads</div>
                        )}
                        {this.state.showDriverError &&(
                            <div className="email-error">Select Planned Date to see list of Active Drivers</div>
                        )}
                        {this.selectedOption?.value === 'adhoc' && !this.checkSupplierRole() && !this.checkSchedulerRole() && (
                            <div style={{fontSize: '14px', display: 'block', color: 'red'}}>
                            * Any loads that do not have an assigned {this.state.dispatchType} will be planned and the {this.state.dispatchType} can be assigned in the Dispatch Plan at a later date.
                            </div>
                        )}
                        {this.state.showLoader === true &&
                        <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> </div> }
                        {this.state.popUpMsg !== '' && (
                            <div
                                style={{
                                    minHeight: '200px',
                                    position: 'absolute',
                                    margin: '150px 0 0 75px',
                                    zIndex: 1
                                }}
                            >
                                <Confirm
                                    onClose={() => {
                                        this.setState({ popUpMsg: '' });
                                    }}
                                    primaryText=""
                                    secondaryText={this.state.popUpMsg}
                                    onSuccessClick={this.handleSave}
                                />
                            </div>
                        )}
                        {!this.checkSupplierRole() && !this.checkSchedulerRole() && this.selectedOption?.value !== 'adhoc' &&
                        <Row>
                        <Col>
                            <div style={{zIndex: 0}}>
                                Select {this.state.dispatchType}(optional):{' '}
                                <div className="location__consignee">
                                    {this.state.dispatchType === 'Driver' ? <Select
                                        value={this.selectedDriver}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.driverPlaceholder}
                                        options={driver ? driver : msg}
                                        onChange={this.handleDriverChange}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    /> : <Select
                                    value={this.selectedCompany}
                                    closeMenuOnSelect={true}
                                    closeMenuOnScroll={true}
                                    placeholder={this.state.companyPlaceholder}
                                    options={companyDropdown ? companyDropdown : []}
                                    onChange={this.handleCompanyChange}
                                    getOptionLabel={option => option}
                                    getOptionValue={option => option}
                                />}
                                </div>
                            </div>
                        </Col>
                        </Row>}
                        <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleSubmit}
                                disabled={
                                    this.state.invalidLoadData !== '' ||
                                    this.state.excessLoad !== '' ||
                                    (this.data.length == 0 && this.selectedOption?.value === 'adhoc')
                                }
                            >
                                {this.selectedDriver ? 'Dispatch' : this.selectedCompany ? 'Assign' : 'Save'}
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    handleRadioButtonChange = event => {
        this.selectedDriver = ''
        this.selectedCompany = ''
        this.data.map((X)=>{
            X.driverID = ''
            X.carrierID = ''
        })
        this.setState({driverNames: []})
        this.setState({driverPlaceholder: 'Select Driver'})
        this.setState({companyPlaceholder: 'Select Carrier'})
        this.setState({dispatchType: event.target.value})
    }

    handleDriverChange = option => {
        this.selectedDriver = option;
        this.setState({ driverPlaceholder: option})
    };

    handleCompanyChange = option => {
        this.selectedCompany = option;
        this.setState({ companyPlaceholder: option})
    };

    valid = current => {
        return current >= this.props.startDate && current <= this.props.endDate;
    };

    handleChange(date) {
        console.log(toJS(date));
        this.startDate = date;
        this.setState({ invalidEmail: '' });
        this.setState({
            startDate: date
        });
    }
    handleDropdownChange = async (X) => {
        if(X.date == ''){
            this.setState({
                showDriverError : true,
                driverNames: []
            })
        } else {
            const month: any = moment(new Date(X.date)).format('MM')
            const year: any = moment(new Date(X.date)).format('YYYY')
            const reqbody = {
                PlannedDate: moment(new Date(X.date)).format('YYYY-MM-DD'),
                RegionID: 'All',
                Month: moment.months()[month - 1],
                Year: year
            };
            this.setState({showLoader : true})
            const driverData: any = await dispatchPlanDataService.getDriverResponse(reqbody);
            this.setState({showLoader : false})
            this.setState({driverNames: Array.from(new Set(driverData['Data'].map((item: any) => item.DriverName)))})
            this.setState({showDriverError : false})
        }
    }
    handleSchedule = async(X, value, type) => {
        const { msg,companyInfo } = this.props;
        this.setState({ invalidLoadData: '', excessLoad: '' });
        if (type == 'loads') {
            X.loads = value.length < 7 ? parseInt(value) : parseInt(value.slice(0, 6));
            console.log(this.data);
        } else if (type === 'date') {
            X.date = value;
            X.driverID = ''
            X.driverName = ''
            X.companyID = ''
            X.companyName = ''
        } else if (type === 'driver') {
            const driverName = msg.findIndex(a => a.DriverName == value);
            X.driverID = msg[driverName].DriverID;
            X.driverName = msg[driverName].DriverName
        } else if (type === 'carrier') {
            const carrierName = companyInfo.findIndex(a => a.CompanyName == value);
            X.companyID = companyInfo[carrierName].ID;
            X.companyName = companyInfo[carrierName].CompanyName
        }
        let count = 0;
        this.data.forEach(X => {
            if (X.loads && X.loads !== '') count += parseInt(X.loads);
        });
        this.setState({ remainingCount: this.props.loads - count });
        if (this.props.loads - count < 0) {
            this.setState({ excessLoad: 'invalid-email' });
        } else {
            X.IsError = false;
            this.setState({ excessLoad: '' });
        }
        if (X.loads && X.loads !== '' && X.date !== '') {
            X.Edit = true;
            if (this.props.loads - count > 0 && !X.Edit) {
                this.data.push({ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false });
                X.Edit = true;
            } else {
                const newData = this.data.filter(x => {
                    return (!x.loads || x.loads == '') && x.date == '';
                });
                if (this.props.loads - count > 0 && newData.length == 0) {
                    this.data.push({ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false });
                }
            }
        }
        this.setState({
            data: this.data
        });
    };

    deleteItem = ind => {
        this.setState({ invalidLoadData: '' });
        this.data.splice(ind, 1);
        console.log(this.data);
        let count = 0;
        this.data.forEach(X => {
            if (X.loads && X.loads !== '') count += parseInt(X.loads);
        });
        this.setState({ remainingCount: this.props.loads - count });
        if (this.data.length > 0) {
            if (this.props.loads - count < 0) {
                this.setState({ excessLoad: 'invalid-email' });
                this.data[this.data.length - 1].IsError = true;
            } else {
                this.data[this.data.length - 1].IsError = false;
                this.setState({ excessLoad: '' });
            }
            const newData = this.data.filter(x => {
                return (!x.loads || x.loads == '') && x.date == '';
            });
            if (this.props.loads - count > 0 && newData.length == 0) {
                this.data.push({ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false });
            }
        } else {
            this.data.push({ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false });
        }
        this.setState({
            data: this.data
        });
    };

    editSchedule() {
        this.data.push({ loads: '', date: '', driverID: '', driverName: '', companyID: '', companyName: '', IsError: false, IsEdit: false });
        this.setState({
            data: this.data
        });
    }

    checkSupplierRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (suppliersRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    checkSchedulerRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (schedulerRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    handleDataTypeChange = selectedOption => {
        this.selectedOption = selectedOption;
        this.setState({ invalidEmail: '', showDriverError: false });
    };
    handleSubmit = async () => {
        const { msg, companyInfo } = this.props;
        if(this.selectedDriver){
            const driverName = msg.findIndex(a => a.DriverName == this.state.driverPlaceholder);
        this.selectedDriverID = msg[driverName].DriverID 
    
    }
        if(this.selectedCompany) {
            const companyName = companyInfo.findIndex(a => a.CompanyName == this.state.companyPlaceholder);
            this.selectedCompanyID = companyInfo[companyName].ID
        }
        if (this.selectedOption && this.selectedOption.value !== 'adhoc' && this.startDate !== '') {
            let msg = 'All the selected Loads will be Planned. Do you want to continue ?';
            this.setState({ popUpMsg: msg });
            // this.props.onSuccessClick(this.selectedOption, moment(this.startDate).format('YYYY-MM-DDTHH:mm:ss'));
            // this.props.onClose();
        } else if (this.selectedOption && this.selectedOption.value == 'adhoc') {
            console.log(this.selectedOption, this.data);
            let flg = 0;
            this.data.forEach(x => {
                if ((!x.loads || x.loads === '' || x.date === '') && !(!x.loads || (x.loads === '' && x.date === ''))) {
                    x.IsError = true;
                    flg = 1;
                }
            });
            if (flg == 1) {
                this.setState({ invalidLoadData: 'invalid-email' });
            } else {
                this.setState({ invalidLoadData: '' });
                let msg = '';
                if (this.state.remainingCount > 0) {
                    msg =
                        'There are ' + this.state.remainingCount + ' Remaining to be Planned. Do you want to continue ?';
                } else {
                    msg = 'All the selected Loads will be Planned. Do you want to continue ?';
                }
                this.setState({ popUpMsg: msg });
            }
        } else {
            this.setState({ invalidEmail: 'invalid-email' });
        }
    };

    handleSave = () => {
        if (this.selectedOption && this.selectedOption.value == 'adhoc') {
            this.setState({ popUpMsg: '' });
            this.props.onSuccessClick(
                this.selectedOption,
                this.selectedDriverID,
                this.selectedCompanyID,
                this.data.filter(x => {
                    return x.Edit;
                }),this.props.selectedLoads, this.state.dispatchType
            );
            this.props.onClose();
        } else {
            this.props.onSuccessClick(this.selectedOption, this.selectedDriverID, this.selectedCompanyID, moment.utc(this.startDate).format('YYYY-MM-DDTHH:mm:ss'),this.props.selectedLoads, this.state.dispatchType);
            this.props.onClose();
        }
    };
}
