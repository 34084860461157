import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import {
    TrailerResponse,
    CategoryType,
    TrailerStatus,
    OwnershipType,
    TankSpecification,
    TrailerViewModel,
    Maintenance,
    CarrierCompany,
    RegionType
} from '../domains/model';
import { TRAILER_CATEGORY_TYPE, TRAILER_STATUS, TRAILER_OWNERSHIPTYPE, TANK_SPECIFICATION, USER_ROLE } from '../data_constant';
import { MaintenanceStatus, MaintenanceType } from '../../maintenance-portal/model/model';
import { TRAILER_MAINTENANCE_STATUS, TRAILER_MAINTENANCE_TYPE } from '../../maintenance-portal/maintenance_portal_constant';
import { Company_Region_Type } from 'features/mapping/mapping_portal_constant';
import { AppConstant } from 'app_constant';

export class TrailerDataService {
    constructor(private restApi: RestApiService) {}

    private getTrailerData(): Promise<ResponseDataType<TrailerResponse[]>> {
        return this.restApi.post<TrailerResponse[]>(Config.getTrailer, { Version: 1 });
    }

    private getCategoryTypes(): Promise<ResponseDataType<CategoryType[]>> {
        return this.restApi.post<CategoryType[]>(Config.getBaseValueByClass, { CODE: TRAILER_CATEGORY_TYPE });
    }

    private getTrailerStatuses(): Promise<ResponseDataType<TrailerStatus[]>> {
        return this.restApi.post<TrailerStatus[]>(Config.getBaseValueByClass, { CODE: TRAILER_STATUS });
    }

    private getOwnershipTypes(): Promise<ResponseDataType<OwnershipType[]>> {
        return this.restApi.post<OwnershipType[]>(Config.getBaseValueByClass, { CODE: TRAILER_OWNERSHIPTYPE });
    }

    private getTankSpecifications(): Promise<ResponseDataType<TankSpecification[]>> {
        return this.restApi.post<TankSpecification[]>(Config.getBaseValueByClass, { CODE: TANK_SPECIFICATION });
    }

    private getUserroles(): Promise<ResponseDataType<TankSpecification[]>> {
        return this.restApi.post<TankSpecification[]>(Config.getBaseValueByClass, { CODE: USER_ROLE });
    }

    private getCarrierCompanyData(): Promise<ResponseDataType<CarrierCompany[]>> {
        return this.restApi.postWithToken<CarrierCompany[]>(Config.getCarrierCompanyData);
    }

    async getRegionTypes(): Promise<ResponseDataType<RegionType[]>> {
        return this.restApi.postWithToken<RegionType[]>(Config.getBaseValueByClass, { CODE: Company_Region_Type });
    }

    async getTrailerViewModel(): Promise<TrailerViewModel> {
        const trailerDataPromise = this.getTrailerData();
        const categoryTypePromise = this.getCategoryTypes();
        const ownershipTypesPromise = this.getOwnershipTypes();
        const tankSpecPromise = this.getTankSpecifications();
        const trailerStatusPromise = this.getTrailerStatuses();
        const carrierCompanyInfoPromise = this.getCarrierCompanyData();
        const regionInfoPromise = this.getRegionTypes();
        const userRole = this.getUserroles()
        const [
            { data: trailerData },
            { data: tankSpecs },
            { data: trailerStatuses },
            { data: ownershipTypes },
            { data: categoryTypes },
            { data: carrierCompanyData},
            { data: regionData }
        ] = await Promise.all([
            trailerDataPromise,
            tankSpecPromise,
            trailerStatusPromise,
            ownershipTypesPromise,
            categoryTypePromise,
            carrierCompanyInfoPromise,
            regionInfoPromise
        ]);
        return {
            trailerData,
            tankSpecs,
            trailerStatuses,
            ownershipTypes,
            categoryTypes,
            carrierCompanyData,
            regionData
        };
    }

    async updateTrailers(reqbody: TrailerResponse[]): Promise<any> {
        const { data } = await this.restApi.post<any>(Config.updateTrailers, reqbody, {});
        return data['Data'];
    }

    async createScheduledTrailerMaintenances(reqbody: Maintenance): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.createTrailerMaintenance, reqbody, {});
        return data['Data'];
    }
    
    private getMaintenanceStatuses(): Promise<ResponseDataType<MaintenanceStatus[]>> {
        return this.restApi.postWithToken<MaintenanceStatus[]>(Config.getBaseValueByClass, { CODE: TRAILER_MAINTENANCE_STATUS });
    }

    private getMaintenanceTypes(): Promise<ResponseDataType<MaintenanceType[]>> {
        return this.restApi.postWithToken<MaintenanceType[]>(Config.getBaseValueByClass, { CODE: TRAILER_MAINTENANCE_TYPE });
    }

    async getTrailerMaintenanceViewModel() {
        const maintenanceTypesPromise = this.getMaintenanceTypes();
        const maintenanceStatusPromise = this.getMaintenanceStatuses();
        const [
            { data: maintenanceTypes },
            { data: maintenanceStatuses }
        ] = await Promise.all([maintenanceTypesPromise, maintenanceStatusPromise]);
        return {
            maintenanceTypes: maintenanceTypes,
            maintenanceStatuses: maintenanceStatuses
        };
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }
}

export const trailerDataService = new TrailerDataService(restApiService);
