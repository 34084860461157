import React, { PureComponent } from "react";
import './_dispatcher-approval.scss'
import { RouteComponentProps } from "react-router-dom";
import { Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import queryString, { stringify } from 'query-string';
import { AccountStore } from '../stores/account-store';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AWAITING_ACTIVATION, APPROVED, REJECTED, USER_APPROVAL_CONFIRMATION, USER_APPROVAL_SUCCESS, USER_APPROVAL_FAIL, USER_DISAPPROVAL_SUCCESS } from "../account_constant";
import { UiService } from '../../../shared/services/ui-service';
import {Input} from '../../../shared/components/input/input';

interface UserRequestState {
    isValidUser: boolean;
    firstName: string;
    lastName: string;
    carrierName: string;
    facilities: string;
    isLoading: boolean;
    actionAlreadyTaken: boolean;
    actionTaken: boolean;
    showRejectBox: boolean;
    rejectReason: string;
}

interface LoginContainerProps {
    accountStore: AccountStore;
}

@inject('accountStore')
@observer
export class DispatcherApprovalComponent extends PureComponent<RouteComponentProps & LoginContainerProps, UserRequestState> {
    constructor(props) {
        super(props);
        this.state = {
            isValidUser: false,
            firstName: '',
            lastName: '',
            carrierName: '',
            facilities: '',
            isLoading: true,
            actionAlreadyTaken: false,
            actionTaken: false,
            showRejectBox: false,
            rejectReason: '',
        }
    }

    takeConfirmation = (status) => {
        if (status === APPROVED) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => this.takeAction(status),
                '',
                USER_APPROVAL_CONFIRMATION
            );
        } else {
            this.takeAction(status);
        }
    }

    onTextChange = (event) => {
        this.setState({rejectReason: event.target.value})
    }

    takeAction = (status) => {
        const toastService = new UiService();
        const { rejectReason } = this.state;
        const urlQueryStrings = this.props.location.search;
        const queryValues = queryString.parse(urlQueryStrings);

        queryValues.StatusCode = status;
        queryValues.Comments = rejectReason;
        this.props.accountStore.updateUserStatusByDispatcher(queryValues).then(() => {
            toastService.toastService.success(status === APPROVED ? USER_APPROVAL_SUCCESS : USER_DISAPPROVAL_SUCCESS);
            this.setState({ actionTaken: true });
        }).catch(() => {
            toastService.toastService.success(USER_APPROVAL_FAIL);
        })
    }

    getUserDetail = () => {
        const urlQueryStrings = this.props.location.search;
        const { accountStore } = this.props;
        if (urlQueryStrings) {
            const isLoggedin = accountStore.isLoggedIn;
            if (!isLoggedin) {
                accountStore.login().then(() => {
                    this.getUserDetailWithLoginID();
                });
            }
            if (accountStore.isLoggedIn) {
                this.getUserDetailWithLoginID();
            }
        }
    }

    getUserDetailWithLoginID() {
        const urlQueryStrings = this.props.location.search;
        const { accountStore } = this.props;
        const queryValues = queryString.parse(urlQueryStrings);
        accountStore.getUserDetailForDispatcher(queryValues).then(res => {
            this.setState({ firstName: res.FirstName });
            this.setState({ lastName: res.LastName });
            this.setState({ carrierName: res.PSAMappedCarrierName });
            this.setState({ facilities: res.FacilityNames });
            this.setState({ isLoading: false });
            this.setState({ isValidUser: true });
            this.setState({ actionAlreadyTaken: res.StatusCode === AWAITING_ACTIVATION || res.StatusCode === REJECTED });
        }).catch(ex => {
            this.setState({ isLoading: false });
            this.setState({ isValidUser: false });
        });
    }

    componentDidMount() {
        this.getUserDetail();
    }

    render(): React.ReactNode {
        return (
            <div className="user-request">
                <div className="user-request_content">
                    <div> {this.renderMessage()}</div>
                </div>
            </div>
        );
    }


    renderMessage = () => {
        const { isValidUser, firstName, lastName, carrierName, facilities, isLoading, actionAlreadyTaken, actionTaken, showRejectBox, rejectReason } = this.state;
        if (isValidUser && !isLoading && !actionTaken && !actionAlreadyTaken) {
            if (!showRejectBox) {
                return (
                    <div>
                        <div className="user-request_header">User Request</div>
                        <div style={{ border: '1px solid hsla(0, 0%, 59.2%, 0.23)', marginBottom: '10px' }}>
                        </div>
                        <div className="label-value-pair">
                            <label className="label-text"> Name  </label>
                            <label className="label-value"> {firstName + ' ' + lastName}</label>
                        </div>
                        <div className="label-value-pair">
                            <label className="label-text"> Carrier </label>
                            <label className="label-value"> {carrierName}</label>
                        </div>
                        <div className="label-value-pair">
                            <label className="label-text"> Facility </label>
                            <label className="label-value"> {facilities}</label>
                        </div>
                        <div>
                            <Button className="mt-3 mb-3 button-accept" style={{ margin: '5px' }} onClick={() => { this.takeConfirmation(APPROVED) }}>
                                Accept
                    </Button>
                            <Button className="mt-3 mb-3 button-reject" style={{ margin: '5px' }} onClick={() => { this.setState({showRejectBox: true}) }}>
                                Reject
                    </Button>
                        </div>
                    </div>
                );
            }
            else {
                return(
                <div>
                    <div className="user-request_header">User Request</div>
                    <div style={{ border: '1px solid hsla(0, 0%, 59.2%, 0.23)', marginBottom: '10px' }} />
                    <div>
                    <textarea
                            autoComplete="off"
                            placeholder="Reason for reject"
                            onChange={this.onTextChange}
                            className="filterclass"
                        ></textarea>
                    </div>
                    <div>
                        <Button className="mt-3 mb-3" disabled={rejectReason.length === 0}  style={{ margin: '5px' }} onClick={() => { this.takeAction(REJECTED) }}>
                            Reject
                    </Button>
                        <Button className="mt-3 mb-3" style={{ margin: '5px' }} onClick={() => { this.setState({showRejectBox: false}) }}>
                            Cancel
                    </Button>
                    </div>
                </div>
                )
            }
        } else {
            if (actionAlreadyTaken) {
                return <div className="user-loading" >Action already has been taken on this request</div>;
            }
            if (actionTaken) {
                return <div>
                    <div className="user-request_header">User Request</div>
                    <div>Your action has been submitted successfully</div>
                    <div className="user-request_footer">
                        <div>Thanks</div>
                        <div>Plains Midstream Canada</div>
                    </div>
                </div>
            } else {
                return <div className={isLoading ? "user-loading" : "user-request_invalid"}>{isLoading ? '...Please Wait' : 'Invalid Link'}</div>;
            }
        }
    }
}