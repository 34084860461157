import React from 'react';
import { Modal } from 'react-bootstrap';
import { DispatchPlanCarrierComponent } from './dispatchPlan-carrier-component';
import { CompanyResponse } from '../model/model';

interface DispatchPlanPopUpProps {
    onHide: () => void;
    show: boolean;
    carrierName?: any;
    selectedCarrierId: number;
    carriers: CompanyResponse[]
    onSelectionChange: (carrierID: any, buttonName: string) => void;
}

export const DispatchPlanCarrierPopupComponent = (props: DispatchPlanPopUpProps) => {
    const { carrierName, onHide, show, onSelectionChange, selectedCarrierId, carriers } = props;
    return (
        <Modal size="lg" onHide={onHide} backdrop= 'static' show={show} animation={false}>
            <Modal.Body>
                <DispatchPlanCarrierComponent
                    selectedCarrierId={selectedCarrierId}
                    carrierName={carrierName}
                    onHide={onHide}
                    carriers={carriers}
                    onSelectionChange={onSelectionChange}
                ></DispatchPlanCarrierComponent>
            </Modal.Body>
        </Modal>
    );
};