import { action, observable } from 'mobx';
import Loader from '../../../../shared/decorators/loader-decorator';
import { CapacityPlanDataService } from '../services/capacityPlan-data-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { AccountStore } from '../../../account/stores/account-store';
import dataSource from "../components/capacity-dataSource.json";
import { RegionMappingDataRequest, RegionMappingTypes } from 'features/mapping/model/model';
export class CapacityPlanDataStore {
    userID = 0;
    userName = '';
    @observable userInfo: any = {};
    @observable mappedCCID;
    @observable dataExist = false;
    @observable menuLoader = false;
    @observable selectedRegionID = 0;
    @observable dispatchBoardData = [] as any;
    @observable regionMappingData: RegionMappingTypes = {
        NotMappedCompany: {},
        MappedCompany: {},
        MappedTrailer: {},
        NotMappedTrailer: {}
    };
    @observable capacityPlanningData = [] as any
    constructor(private capacityPlanDataService: CapacityPlanDataService, public accountStore: AccountStore) {
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
        this.mappedCCID = this.accountStore.getUserInfo().carrierCompanyId;
    }

    getRegionMapJson(regionMappingArray) {
        const mappingRegion: RegionMappingTypes = {
            NotMappedCompany: {},
            MappedCompany: {},
            MappedTrailer: {},
            NotMappedTrailer: {}
        };
        if (regionMappingArray.length > 0) {
            regionMappingArray.forEach(key => {
                if (mappingRegion.hasOwnProperty(key.Mapping)) {
                    mappingRegion[key.Mapping][key.ID] = key.Name;
                }
            });
        }
        return mappingRegion;
    }
    
    @Loader
    @action
    async getCapacityPlanningData(reqBody): Promise<void> {
        try {
            console.log('reqBody' + reqBody);
            this.dispatchBoardData = [];
            const result = await this.capacityPlanDataService
                .getCapacityPlanData(reqBody)
                
                .catch(function(error) {
                    console.log('error in getting capacity planning data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }
    
    @Loader
    @action
    async getCompanyRegionMapping(reqBody: RegionMappingDataRequest): Promise<void> {
        try {
            this.dataExist = false;
            const result = await this.capacityPlanDataService
                .getCompanyRegionMapping(reqBody)
                .then(response => {
                    this.regionMappingData = this.getRegionMapJson(response.data['Data']);
                    this.menuLoader = false;
                    this.dataExist = true;
                })
                .catch(function(error) {
                    console.log('error in getting mapping data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }
    @Loader
    @action
    async GetMasterData(reqbody): Promise<void> {
        try {
            console.log(JSON.stringify(reqbody));
            this.userInfo = this.accountStore.getUserInfo();
            await this.capacityPlanDataService
                .getCapacityPlanData({...reqbody})
                .then(response => {
                    this.capacityPlanningData = response;
                })
                .catch(function (error) {
                    console.log('error in getting capacity data', error);
                });
        }
        catch (e) {
            console.log('error in getting capacity', e);
        }
    }

    @Loader
    @action
    async AssignVacationPlan(reqBody): Promise<void> {
        try {
            console.log("reqBody" + reqBody);
            const result = await this.capacityPlanDataService
                .assignLeave(reqBody)
                .then(response => {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log('error in updating capacity data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }
}
