import React from 'react';
import { Modal } from 'react-bootstrap';
import { TrailerManagementCarrierComponent } from './trailer-management-carrier-component'
import { CarrierCompany } from '../domains/model';
import { KeyFobManagementLocationComponent } from './keyfob-management-location-component';
import { KeyFobAssignDateComponent } from './keyfob-management-assignedDate-component';

interface KeyFobManagementPopUpProps {
    onHide: () => void;
    show: boolean;
    showCCModal: boolean;
    showLocModal: boolean;
    startDate?: any;
    companyName?: any;
    selectedCarrierId: number;
    carriers: CarrierCompany[]
    onSelectionChange: (carrierID: any) => void;
}

export const KeyFobManagementPopupComponent = (props: KeyFobManagementPopUpProps) => {
    const { companyName, onHide, show, onSelectionChange, selectedCarrierId, carriers, startDate, showCCModal, showLocModal } = props;
    return (<>
        <Modal size="lg" onHide={onHide} backdrop='static' show={show} animation={false}>
            <Modal.Body>
                <KeyFobAssignDateComponent
                    startDate={startDate}
                    onHide={onHide}
                    onSelectionChange={onSelectionChange}
                ></KeyFobAssignDateComponent>
            </Modal.Body>
        </Modal>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showCCModal} animation={false}>
            <Modal.Body>
                <TrailerManagementCarrierComponent
                    selectedCarrierId={selectedCarrierId}
                    companyName={companyName}
                    onHide={onHide}
                    carriers={carriers}
                    onSelectionChange={onSelectionChange}
                ></TrailerManagementCarrierComponent>
            </Modal.Body>
        </Modal>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showLocModal} animation={false}>
            <Modal.Body>
                <KeyFobManagementLocationComponent
                    selectedLocationId={selectedCarrierId}
                    LocationName={companyName}
                    onHide={onHide}
                    locations={carriers}
                    onSelectionChange={onSelectionChange}
                ></KeyFobManagementLocationComponent>
            </Modal.Body>
        </Modal></>
    );
};