import * as React from 'react';
import './_custom-toast.scss';
import className from 'classnames';

export interface CustomToastProps {
    text: string;
    errors: ToastMessage[];
    type: CustomToastType;
}

export enum CustomToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info'
}

export interface ToastMessage {
    id: string;
    description: string;
}

export class CustomToast extends React.Component<CustomToastProps> {
    public render(): React.ReactNode {
        return <React.Fragment>{this.renderToast()}</React.Fragment>;
    }

    renderToast(): React.ReactNode {
        const { errors, text, type } = this.props;
        const rootType = `custom-toast-${type}`;
        const rootClasses = className('custom-toast', {
            [rootType]: true
        });
        const statusIndicator = CustomToastType.SUCCESS === type ? 'fa-check-circle' : (CustomToastType.INFO === type ?'fa-info-circle': 'fa-times-circle');
        const status = className('fa', {
            'fa-2x': true,
            [statusIndicator]: true
        });
        const listItems = errors.map((msg) =>
            <li> <div className="custom-toast-li-item-text">{msg.id} - {msg.description}</div></li>
        );
        return (
            <div className={rootClasses}>
                <div className={`custom-toast-${type}`}>
                    <i className={status}></i>
                </div>
                <ul>{listItems}</ul>
                <div className="custom-toast-text">{text}</div>
                <div className="custom-toast-close">
                    <i className="fa fa-remove fa-lg"></i>
                </div>
            </div>
        );
    }
}
