import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import {
    TractorResponse,
    TractorViewModel,
    DeleteRequest,
    ComplianceStatus,
    CarrierCompany
} from '../domains/tractor-modal';
import { TRAILER_MAINTENANCE_STATUS, TKT_STATUS_TYPE } from 'features/maintenance-portal/maintenance_portal_constant';
import { AppConstant } from 'app_constant';

export class TractorDataService {
    constructor(private restApi: RestApiService) {}

    private getTractorsData(): Promise<ResponseDataType<TractorResponse[]>> {
        return this.restApi.postWithToken<TractorResponse[]>(Config.getTractors);
    }

    async getTractorViewModel(): Promise<TractorViewModel> {
        const tractorDataPromise = this.getTractorsData();
        const tractorStatusPromise = this.getComplianceStatuses();
        const carrierCompanyPromise = this.getCarrierCompanyData();
        const [{ data: tractorData }, { data: tractorStatus }, { data: companyData }] = await Promise.all([
            tractorDataPromise,
            tractorStatusPromise,
            carrierCompanyPromise
        ]);
        return {
            tractorData,
            tractorStatus,
            companyData
        };
    }

    private getComplianceStatuses(): Promise<ResponseDataType<ComplianceStatus[]>> {
        return this.restApi.postWithToken<ComplianceStatus[]>(Config.getBaseValueByClass, {
            CODE: TKT_STATUS_TYPE
        });
    }

    private getCarrierCompanyData(): Promise<ResponseDataType<CarrierCompany[]>> {
        return this.restApi.postWithToken<CarrierCompany[]>(Config.getCarrierCompanyData);
    }

    async updateTractor(reqbody: TractorResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateTractor, reqbody, {});
        return data['Data'];
    }

    async createTractor(reqbody: TractorResponse): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.createTractor, reqbody, {});
        return data['Data'];
    }

    async deleteTractor(reqbody: DeleteRequest): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.deleteTractor, reqbody, {});
        return data['Data'];
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }
}
export const tractorDataService = new TractorDataService(restApiService);
