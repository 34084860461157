import React from 'react';
import { Modal } from 'react-bootstrap';
import { TrailerManagementCarrierComponent } from './trailer-management-carrier-component'
import { CarrierCompany } from '../domains/model';

interface TrailerManagementPopUpProps {
    onHide: () => void;
    show: boolean;
    companyName?: any;
    selectedCarrierId: number;
    carriers: CarrierCompany[]
    onSelectionChange: (carrierID: any) => void;
}

export const TrailerManagementCarrierPopupComponent = (props: TrailerManagementPopUpProps) => {
    const { companyName, onHide, show, onSelectionChange, selectedCarrierId, carriers } = props;
    return (
        <Modal size="lg" onHide={onHide} backdrop= 'static' show={show} animation={false}>
            <Modal.Body>
                <TrailerManagementCarrierComponent
                    selectedCarrierId={selectedCarrierId}
                    companyName={companyName}
                    onHide={onHide}
                    carriers={carriers}
                    onSelectionChange={onSelectionChange}
                ></TrailerManagementCarrierComponent>
            </Modal.Body>
        </Modal>
    );
};