import React from 'react';
import './_assigned-shop-item.scss';
import { Row, Col } from 'react-bootstrap';
import { ShopInfo } from '../model/model';

interface AssignedShopDetailsComponentProps {
    shopInfo: ShopInfo;
}

export const AssignedShopDetailsComponent = (selectedShop: AssignedShopDetailsComponentProps) => {
    const { ShopName, ShopAddress, ContactNumber, City, Province, PostalCode, ModifiedDate } = selectedShop.shopInfo || {};
    return (
        <div className="assigned-shop-item">
            <Row>
                <Col>Shop Name- </Col>
                <Col className="assigned-shop-item__content">{ShopName}</Col>
            </Row>
            <Row>
                <Col>Shop Address- </Col>
                <Col className="assigned-shop-item__content">
                    {(ShopAddress === null || ShopAddress === '' || ShopAddress === 'null') ? '' : ShopAddress}
                    {(City === null || City === '' || City === 'null') ? '' : ", " + City}
                    {(Province === null || Province === '' || Province === 'null') ? '' : ", " + Province}
                    {(PostalCode === null || PostalCode === '' || PostalCode === 'null') ? '' : ", " + PostalCode}
                </Col>
            </Row>
            <Row>
                <Col>Contact- </Col>
                <Col className="assigned-shop-item__content">{ContactNumber}</Col>
            </Row>
            <Row>
                <Col>Shop Assigned Date- </Col>
                <Col className="assigned-shop-item__content">{ModifiedDate}</Col>
            </Row>
        </div>
    );
};
