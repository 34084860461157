import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { ASSINGED_SHOP } from '../../maintenance_portal_constant';
import { trailerMaintenanceStore } from '../../stores';
import { dataStore } from '../../../common/stores/index';

const handleShopAssignment = () => {
    trailerMaintenanceStore.setShowModal();
};

export const CurrentStatusCellRenderer = (props: ICellRendererParams) => {
    const { value } = props;
    const hasEditAccess = dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance');
    return (
        <div className="current-status">
            {value === ASSINGED_SHOP ? (
                <div className="current-status__indicator current-status__assigned" />
            ) : (
                    <div className="current-status__indicator current-status__not-assigned" />
                )}
            { hasEditAccess && (
                <div className="current-status__text" onClick={handleShopAssignment}>
                    {value}
                </div>
            )}
            {!hasEditAccess && (<div className="current-status__text" >
                {value}
            </div>)}
        </div>
    );
};
