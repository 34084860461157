/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import moment from 'moment';
import { accountStore } from 'features/account/stores';
import { LocationDataStore } from 'features/data_management/stores/location-data-store';
import { LocationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { inject } from 'mobx-react';
import { locationStore } from 'features/data_management/stores';
import { Confirm } from './confirm';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (buttonName) => void;
}
export class ConfirmBulkAssignPopUp extends React.Component<ConfirmProps> {
    public render(): React.ReactNode {
        const { onClose } = this.props;
        return (
            <Dialog
                open={true}
            >
                <DialogContent>
                    <DialogContentText style={{ paddingLeft : 30}}>
                        Would you also like to dispatch these loads?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleSaveClick}>
                        I will Dispatch these later
                            </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleDispatchClick}>
                        Dispatch Loads Now
                            </CustomButton>
                </DialogActions>
            </Dialog>
        );
    }

    handleDispatchClick = () => {
        this.props.onSuccessClick("DispatchLoad")
        this.props.onClose();
    };

    handleSaveClick = () => {
        this.props.onSuccessClick("SaveData")
        this.props.onClose();
    };
}
