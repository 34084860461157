export enum InspectionHistoryHeaderName {
    ID = 'Inspection Number',
    TRAILORNUMBER = 'Trailer Number',
    TRACTORNUMBER = 'Tractor Number',
    FIRSTNAME = 'First Name',
    LASTNAME = 'Last Name',
    TICKETNUMBER = 'Ticket Number',
    OTESNO = 'OTES Number',
    MAINTENANCEREVIEW = 'Maintenance Review',
    MAINTENANCEREVIEWCOMMENTS = 'Maintenance Review Comments',
    MAINTENANCEREVIEWDATE = 'Maintenance Reviewed Date',
    MAINTENANCEREVIEWBY = 'Maintenance Reviewed By',
    COMPLIANCEREVIEW = 'Compliance Review',
    COMPLIANCEREVIEWCOMMENTS = 'Compliance Review Comments',
    COMPLIANCEREVIEWDATE = 'Compliance Reviewed Date',
    COMPLIANCEREVIEWBY = 'Compliance Reviewed By',
    INSPECTIONDATE = 'Inspection Date',
    CRITICALITEMS = 'Critical Items',
    LASTSAVEDATE = 'Modified Date',
    MAJORDEFECTS = 'Major Defects',
    MINORDEFECTS = 'Minor Defects',
    TRAILERHUBMETERREADING = 'Trailer Hub Meter Reading',
    MODIFIEDBYUSER = 'Modified By',
    COMMENT = 'Comment'
}

export enum InspectionHistorySaveLabel {
    SAVE = 'Save Inspection Data',
}



export enum InspectionHistoryFieldName {
    ID = 'ID',
    TRAILORNUMBER = 'TrailerNumber',
    TRACTORNUMBER = 'TractorNumber',
    FIRSTNAME = 'FirstName',
    LASTNAME = 'LastName',
    TICKETNUMBER = 'TicketNumber',
    OTESNO = 'OTESID',
    MAINTENANCEREVIEW = 'MaintenanceReviewStatus',
    MAINTENANCEREVIEWCOMMENTS = 'MaintenanceReviewComments',
    MAINTENANCEREVIEWDATE = 'MaintenanceReviewedDate',
    MAINTENANCEREVIEWBY = 'MaintenanceReviewedByUser',
    COMPLIANCEREVIEW = 'ComplianceReviewStatus',
    COMPLIANCEREVIEWCOMMENTS = 'ComplianceReviewComments',
    COMPLIANCEREVIEWDATE = 'ComplianceReviewedDate',
    COMPLIANCEREVIEWBY = 'ComplianceReviewedByUser',
    INSPECTIONDATE = 'InspectionDate',
    CRITICALITEMS = 'CriticalItems',
    LASTSAVEDATE = 'LastSavedDate',
    MAJORDEFECTS = 'MajorDefects',
    MINORDEFECTS = 'MinorDefects',
    TRAILERHUBMETERREADING = 'TrailerHubMeterReading',
    MODIFIEDBYUSER = 'ModifiedByUser',
    COMMENT = 'OtherComments'
}


export enum InspectionHistoryMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Inspection History data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    NO_TICKETS = 'No Tickets Found',
}