import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { HourlyReportResponse, DateRange } from '../domains/model';

export class HourlyReportDataService {
    constructor(private restApi: RestApiService) {}

    async getHourlyReport(): Promise<HourlyReportResponse[]> {
        const { data } = await this.restApi.postWithToken<HourlyReportResponse[]>(Config.getHourlyReport);
        return data['Data'];
    }
    async getHourlyReportwithparams(reqbody: DateRange): Promise<HourlyReportResponse[]> {
        const { data } = await this.restApi.postWithToken<HourlyReportResponse[]>(Config.getHourlyReport, reqbody, {});
        return data['Data'];
    }
}

export const hourlyreportDataService = new HourlyReportDataService(restApiService);
