import { GridApi, ColumnApi } from 'ag-grid-community';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { AppConstant } from 'app_constant';

export class TicketGridService {
    public gridApi: GridApi | undefined;
    private columnApi: ColumnApi | undefined;

    updateOptionCellEditorValues<T>(items: T[], columnKey: string, modelKey: Extract<keyof T, string>): void {
        if (this.columnApi) {
            this.columnApi.getColumn(columnKey).getColDef().cellEditorParams = {
                values: items.map(uom => uom[modelKey])
            };
        }
    }

    setGridApi(gridApi: GridApi): void {
        this.gridApi = gridApi;
    }

    setColumnApi(columnApi: ColumnApi): void {
        this.columnApi = columnApi;
    }

    getData() {
        return this.gridApi;
    }

    getColumns() {
        return this.columnApi;
    }

    getAllFilters(): any {
        let result;
        if (this.gridApi) {
            result = this.gridApi.getFilterModel();
        }
        return result;
    }

    clearAllFilters() {
        if (this.gridApi) {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        }
    }

    setAllFilters(filters: any): void {
        if (this.gridApi) {
            this.gridApi.setFilterModel(filters);
            this.gridApi.onFilterChanged();
        }
    }

    getTicketGridOptions() {
        return {
            excelStyles: [{
                id: 'decimal-format',
                numberFormat: {
                    format: '###,##0.000'
                }},{
                id: 'comma-format',
                numberFormat: {
                    format: '###,###.###'
                }},{
                id: 'work-wait',
                numberFormat: {
                    format: '###,###'
                }}],
            enableSorting: true,
            enableFilter: true,
            enableColResize: true,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            rowDataChangeDetectionStrategy: ChangeDetectionStrategyType.IdentityCheck,
            suppressScrollOnNewData: true,
            enableRangeSelection: true,
        };
    }
}

export const ticketGridService = new TicketGridService();
