import { RestApiService } from 'shared/services/rest-api-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { authenticationService } from 'shared/services/authentication-service';
import { accountSubscription } from 'shared/services/account-subscription-service';
import { HazardAssessmentDataStore } from './hazardAssessment-data-store';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';
import { HazardAssessmentTicketHistoryDataStore } from './hazardAssessmentTicketHistoryDataStore';
import { hazardAssessmentTicketHistoryDataService } from '../services/hazardAssessment-ticket-history-data-service';
import { TicketStore } from './ticket-store';
import { ticketDataService } from '../services/ticket-data-service';
import { HazardAssessmentDataService } from '../services/hazardAssessment-data-service';
import { TrailerMaintenanceDataStore } from 'features/maintenance-portal/stores/trailer-maintenance-data-store';
import { trailerMaintenaceDataService } from 'features/maintenance-portal/services/maintenance-data-service';
import { uiService } from 'shared/services/ui-service';
import { PastDueCertificationReportDataStore } from './pastDueCertificationReport-data-store';
import { pastDueCertificationReportDataService } from '../services/pastDueCertificationReport-data-service';


export const hazardAssessmentDataStore = new HazardAssessmentDataStore(
    new HazardAssessmentDataService(new RestApiService(localStorageService, accountSubscription, authenticationService))
);

export const trailerMaintenanceStore = new TrailerMaintenanceDataStore(
    trailerMaintenaceDataService,
    agGridService,
    uiService,
    accountStore
);

export const hazardAssessmentHistoryStore = new HazardAssessmentTicketHistoryDataStore(
    hazardAssessmentTicketHistoryDataService,
    agGridService,
    uiService,
    accountStore
);
export const pastDueCertificationReportStore = new PastDueCertificationReportDataStore(
    pastDueCertificationReportDataService,
    agGridService,
    uiService,
    accountStore
);



export const ticketStore = new TicketStore(ticketDataService, accountStore, uiService);

