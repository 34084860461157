// import { CustomerReportHeaderName, CustomerReportFieldName, TicketVolOrHourly, TicketStatus } from '../constants/ticket-enum';
import { ScheduledReportHeaderName, ScheduledReportFieldName } from './enum';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { ScheduledReportRadioCellRenderer } from '../components/scheduled-report-radio-selection';
import { CheckBoxPauseOrStopCellRenderer } from '../components/schedule-report-checkbox-cell-renderer';

export const ScheduledreportColDef: ColDef[] = [
    {
        headerName: 'SELECT',
        field: 'SELECTED',
        colId: 'SELECTED',
        width: 80,
        cellRendererFramework: ScheduledReportRadioCellRenderer
    },
    {
        headerName: ScheduledReportHeaderName.ID,
        field: ScheduledReportFieldName.ID,
        width: 95,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.ID] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.ID];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.REPORTYPE,
        field: ScheduledReportFieldName.REPORTYPE,
        width: 115,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.REPORTYPE] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.REPORTYPE];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.CONSIGNOR,
        field: ScheduledReportFieldName.CONSIGNOR,
        width: 100,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.CONSIGNEE,
        field: ScheduledReportFieldName.CONSIGNEE,
        width: 100,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.CREATEDBY,
        field: ScheduledReportFieldName.CREATEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.CREATEDBY];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.CREATEDDATE,
        field: ScheduledReportFieldName.CREATEDDATE,
        width: 120,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.CREATEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[ScheduledReportFieldName.CREATEDDATE]);
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.MODIFIEDBY,
        field: ScheduledReportFieldName.MODIFIEDBY,
        width: 110,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[ScheduledReportFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.MODIFIEDDATE,
        field: ScheduledReportFieldName.MODIFIEDDATE,
        width: 120,
        valueGetter: params => {
            if (params.data[ScheduledReportFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[ScheduledReportFieldName.MODIFIEDDATE]);
            }
        }
    },
    {
        headerName: ScheduledReportHeaderName.PAUSESTOP,
        field: ScheduledReportFieldName.PAUSESTOP,
        colId: ScheduledReportFieldName.PAUSESTOP,
        width: 105,
        cellRendererFramework: CheckBoxPauseOrStopCellRenderer
    },
];
