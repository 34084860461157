import { ColDef } from 'ag-grid-community';
import { AppConstant } from 'app_constant';
import { HourlyReportFieldName, HourlyReportHeaderName } from 'features/reports/domains/enum';
import { AddCustomerButton } from '../components/add-customer-button';
import { NominationCheckBoxSelection } from '../components/nomination-checkbox-selection';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { NominationSelectionRadio } from '../components/nomination-selection-radio';
import { accountStore } from 'features/account/stores';
import { commaFormat, loadStatus, suppliersRoles } from '../nomination-constants';
import { dateUtils } from 'shared/services/date-utils';
import { nominationStore } from '../stores/nominatation-data-store';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { dataStore } from 'features/common/stores';

const checkSupplierRole = () => {
    let access = false;
    const currentUserRoles = accountStore.userRoles;
    currentUserRoles.forEach(userRole => {
        if (!access) {
            if (suppliersRoles.includes(userRole)) {
                access = true;
            }
        }
    });
    return access;
};

const status = ['Manually Deleted', 'Apportionment Deleted', 'Completed', 'System Deleted'];

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

export const NOMINATION_COL_DEF: ColDef[] = [
    {
        valueGetter: params => {
            if (!params.data['IsChild'] || params.data['customer'] !== 'add-customer') {
                if (params.data['IsExpanded']) {
                    return '-';
                } else {
                    return '+';
                }
            } else {
                return '';
            }
        },
        // cellRendererFramework: AddCustomerButton,
        colSpan: function(params) {
            if (params.data.customer === 'add-customer') {
                return 10;
            } else {
                return 1;
            }
        },
        cellClass: 'fontSize'
    },
    // { field: '', cellRendererFramework: NominationSelectionRadio, maxWidth: 60 },

    {
        headerName: 'Customer Name',
        field: 'CustomerName',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'ContractNumber',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'Product',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'AX_Origin',
        cellEditor: 'agSelectCellEditor',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'AX_Destination',
        cellEditor: 'agSelectCellEditor',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'NominationVolume',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'CurrentNomination',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'PendingVolume',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'DispatchedVolume',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Customer Name',
        field: 'CompletedVolume',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Comment',
        field: 'Comment',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Dispatcher Comment',
        field: 'DispatcherComment',
        editable: params => {
            if (params.data['IsEdit']) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (params.data['IsEdit']) {
                return 'cell-editable';
            } else return 'false';
        }
    },
    {
        headerName: 'Created By',
        field: 'CreatedBy'
    },
    {
        headerName: 'Created Date',
        field: 'CreatedDate'
    },
    {
        headerName: 'Modified By',
        field: 'ModifiedBy'
    },
    {
        headerName: 'Modifie dDate',
        field: 'ModifiedDate'
    }
];

export const LOAD_COL_DEF: ColDef[] = [
    {
        headerName: '',
        field: 'DELETE',
        width: 30,
        cellRendererFramework: NominationCheckBoxSelection,
        colId: 'DELETE',
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Ticket Number',
        field: 'LoadNumber',
        colId: 'loadNmae',
        width: 80,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Manual Ticket Number',
        field: 'ManualTicketNumber',
        width: 110,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        field: 'Pending',
        width: 75,
        editable: params => {
            if (
                params.data['IsEdit'] &&
                params.data['DataSource'] === 'Crude' &&
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsAdjustDeleted'] &&
                (params.data.LoadStatus === null || params.data['LoadStatus'] === 'Pending') &&
                !checkSupplierRole()
            )
                return true;
            else return false;
        },
        cellClass: params => {
            if (
                params.data['IsEdit'] &&
                params.data['DataSource'] === 'Crude' &&
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsAdjustDeleted'] &&
                !params.data['IsInvalid'] &&
                !params.data['IsDeletedDispatchedLoad'] &&
                (params.data.LoadStatus === null || params.data['LoadStatus'] === 'Pending') &&
                !checkSupplierRole()
            )
                return ['cell-editable','decimal-format'];
            else if (
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            )
                return ['cell-disabled','decimal-format'];
            else if (params.data['IsInvalid']) return ['cell-yellow','decimal-format'];
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return ['cell-blue','decimal-format'];
            else return 'decimal-format';
        },
        valueGetter: params => {
            if (
                (params.data['Pending'] === null && params.data['IsLoad'] && !params.data['IsEdit']) ||
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            ) {
                return '-';
            } else {
                return params.data['Pending'] && params.data['Pending'] > 0
                    ? roundOff(parseFloat(params.data['Pending']))
                    : '0.000';
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        field: 'Dispatched',
        width: 100,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return ['cell-disabled','decimal-format'];
            else if (params.data['IsInvalid']) return ['cell-yellow','decimal-format'];
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return ['cell-blue','decimal-format'];
            else return 'decimal-format';
        },
        valueGetter: params => {
            if (
                (params.data['Pending'] === null && params.data['IsLoad'] && !params.data['IsEdit']) ||
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            ) {
                return '-';
            } else {
                return params.data['Dispatched'] && params.data['Dispatched'] > 0
                    ? roundOff(parseFloat(params.data['Dispatched']))
                    : '0.000';
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        field: 'Completed',
        width: 90,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return ['cell-disabled','decimal-format'];
            else if (params.data['IsInvalid']) return ['cell-yellow','decimal-format'];
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return ['cell-blue','decimal-format'];
            else return 'decimal-format';
        },
        valueGetter: params => {
            if (
                (params.data['Pending'] === null && params.data['IsLoad'] && !params.data['IsEdit']) ||
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            ) {
                return '-';
            } else {
                return params.data['Completed'] && params.data['Completed'] > 0
                    ? roundOff(parseFloat(params.data['Completed']))
                    : '0.000';
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: 'Planned Date Time',
        field: 'PlannedDateTime',
        width: 105,
        valueGetter: params => {
            if (params.data['PlannedDateTime'] === null || !params.data['IsLoad']) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data['PlannedDateTime']);
            }
        },
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Status',
        field: 'LoadStatus',
        width: 70,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
                return params.data['LoadStatus'] 
        },
    },
    {
        headerName: 'Dispatch Type',
        field: 'DispatchType',
        colId: 'DispatchType',
        width: 80,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['DispatchType'] === null) {
                return '';
            } else {
                return params.data['DispatchType'];
            }
        },
    },
    {
        headerName: 'Driver Name',
        field: 'DriverName',
        colId: 'DriverName',
        width: 100,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['DriverName'] === null || params.data['DriverName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['DriverName'];
            }
        },
    },
    {
        headerName: 'Last Driver Name',
        field: 'LastDriverName',
        colId: 'LastDriverName',
        width: 100,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['LastDriverName'] === null || params.data['LastDriverName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['LastDriverName'];
            }
        },
    },
    {
        headerName: 'Carrier Name',
        field: 'CarrierName',
        colId: 'CarrierName',
        width: 105,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['CarrierName'] === null || params.data['CarrierName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['DispatchType'] == 'Carrier' ? params.data['CarrierName']: ''
            }
        },
    },
    {
        headerName: 'Last Carrier Name',
        field: 'LastCarrierName',
        colId: 'LastCarrierName',
        width: 105,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['LastCarrierName'] === null || params.data['LastCarrierName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['DispatchType'] == 'Carrier' ? params.data['LastCarrierName']: ''
            }
        },
    },
    {
        headerName: 'Dispatcher Comments',
        field: 'DispatcherComments',
        cellEditorFramework: TextAreaCellRenderer,
        width: 155,
        editable: params => {
            if (
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsAdjustDeleted'] &&
                (params.data.LoadStatus === null || loadStatus.includes(params.data['LoadStatus'])) &&
                !checkSupplierRole() && dataStore.checkOperationAccess('Save')
            )
                return true;
            else return false;
        },
        cellEditorParams: {
            onChange: (item, value) => {
                nominationStore.cellValueChangeMap[item.Id]['DispatcherComments']['currentValue'] = value;
                nominationStore.updateLoadRow(item);
            }
        },
        cellClass: params => {
            if (
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsInvalid'] &&
                !params.data['IsDeletedDispatchedLoad'] &&
                !params.data['IsAdjustDeleted'] &&
                (params.data.LoadStatus === null || loadStatus.includes(params.data['LoadStatus'])) &&
                !checkSupplierRole() && dataStore.checkOperationAccess('Save')
            )
                return 'cell-editable';
            else if (
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            )
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Carrier Comments',
        field: 'CarrierComments',
        colId: 'CarrierComments',
        width: 140,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['CarrierComments'] === null) {
                return '';
            } else {
                return params.data['CarrierComments']
            }
        },
    },
    {
        headerName: 'Shipper Name',
        field: 'ShipperName',
        width: 110,
        cellEditor: 'agSelectCellEditor',
        editable: params => {
            if (
                params.data['IsEdit'] &&
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsAdjustDeleted'] &&
                (params.data.LoadStatus === null || params.data['LoadStatus'] === 'Pending') &&
                !checkSupplierRole()
            ) {
                return true;
            } else return false;
        },
        cellClass: params => {
            if (
                params.data['IsEdit'] &&
                params.data['IsLoad'] &&
                !params.data['IsDeleted'] &&
                !params.data['IsApportionmentDeleted'] &&
                !params.data['IsInvalid'] &&
                !params.data['IsDeletedDispatchedLoad'] &&
                !params.data['IsAdjustDeleted'] &&
                (params.data.LoadStatus === null || params.data['LoadStatus'] === 'Pending') &&
                !checkSupplierRole()
            ) {
                return 'cell-editable';
            } else if (
                params.data['IsDeleted'] ||
                params.data['IsApportionmentDeleted'] ||
                params.data['IsAdjustDeleted']
            )
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        },
        valueGetter: params => {
            if (params.data['IsLoad'] && params.data['ShipperName'] && params.data['LoadType'] !== 'System Load') {
                return params.data['ShipperName'];
            } else {
                return null;
            }
        }
    },
    {
        headerName: 'Load Type',
        field: 'LoadType',
        width: 90,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Created By',
        width: 95,
        field: 'CreatedBy',
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Created Date',
        field: 'CreatedDate',
        width: 105,
        valueGetter: params => {
            if (params.data['CreatedDate'] === null) {
                return '';
            } else {
                return momenttimezone
                    .tz(moment.utc(params.data['CreatedDate']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        headerName: 'Modified By',
        field: 'ModifiedBy',
        width: 100,
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    },
    {
        field: 'ModifiedDate',
        headerName: 'Modified Date',
        width: 120,
        valueGetter: params => {
            if (params.data['ModifiedDate'] === null) {
                return '';
            } else {
                return momenttimezone
                    .tz(moment.utc(params.data['ModifiedDate']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        cellClass: params => {
            if (params.data['IsDeleted'] || params.data['IsApportionmentDeleted'] || params.data['IsAdjustDeleted'])
                return 'cell-disabled';
            else if (params.data['IsInvalid']) return 'cell-yellow';
            else if (params.data['IsDeletedDispatchedLoad'] && !status.includes(params.data['LoadStatus']))
                return 'cell-blue';
            else return '';
        }
    }
];
