export const operationsSelectOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'TicketApproval', label: 'Ticket Approval' },
    { value: 'PastDueCertificationReport', label: 'Past Due Certification Report' },
    { value: 'HazardAssessmentHistory', label: 'Hazard ID Management' }
];

export enum OperationsComponentName {
    Select = 'Select',
    TicketApproval = 'TicketApproval',
    PastDueCertificationReport = 'PastDueCertificationReport',
    HazardAssessmentHistory = 'HazardAssessmentHistory'
}
