import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_dispatch-plan-date-component.scss';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import moment from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { AppConstant } from 'app_constant';
import momenttimezone from 'moment-timezone';
import { observable } from 'mobx';

interface DispatchPlanProps {
    startDate: any;
    onHide: () => void;
    onSelectionChange: (date) => void;
}

interface DispatchPlanState {
    startDate: any;
}
var yesterday = moment().subtract( 1, 'day' );
var valid = function( current ){
    return current.isAfter( yesterday );
};
@observer
export class DispatchPlanDateComponent extends Component<DispatchPlanProps, DispatchPlanState> {
    startDate: any = '';
    isEdited: boolean = false;
    @observable showPicker: boolean = false;
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
        };
    }

    render(): React.ReactNode {
        const { startDate } = this.state;
        const defaultDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 22, 59);
        return (
            <div className="form-inline padding-bottom">
                Planned Date & Time:{' '} 
                {momenttimezone.tz(moment.utc(this.props.startDate), AppConstant.MST_TIME_FORMATTER).format("MM-DD-YYYY hh:mm A")}
                {this.showPicker === true &&
                 <div style={{display: 'flex', marginTop: '10px' }}>
                Select Date:{' '}
                <span className="InputFromTo-from" style={{paddingLeft: 10}}>
                    <DatePicker
                        inputProps={{
                            style: { width: 180, height: 30 },
                            placeholder: 'Select Planned Date Time'
                        }}
                        dateFormat="MM-DD-YYYY"
                        timeFormat="hh:mm A"
                        value={this.startDate}
                        initialViewDate={defaultDate}
                        onChange={val => this.handleChange(val)}
                        isValidDate={valid}
                    />
                </span></div>}{' '}
                <div className="shop-assignment-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <div style={{ paddingLeft: 70}} >
                    <CustomButton type={CustomButtonType.Ok} onClick={()=>{
                    this.showPicker = true;
                }}> EDIT </CustomButton>
                    </div>
                    <div style={{ paddingLeft: 100}}>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignShop}>
                        Ok
                    </CustomButton>
                    </div>
                </div>
            </div>
        );
    }

    handleChange(date) {
        this.isEdited = true;
        this.startDate = date;
        this.setState({
            startDate: date
        });
    }

    assignShop = () => {
        if(this.isEdited == true)
        this.props.onSelectionChange(moment.utc(this.state.startDate).format('YYYY-MM-DDTHH:mm:ss'));
        this.props.onHide();
    };
}
