import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { apportionmentStore } from '../stores/apportionment-data-store';
import { dataStore } from 'features/common/stores';
interface AlphaNumericCellRendererState {
    value: boolean;
}
export class ApportionmentCheckBoxSelection extends PureComponent<
    ICellRendererParams,
    AlphaNumericCellRendererState,
    {}
> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange() {
        this.setState({ value: !this.state.value });
        console.log('Row Index' + this.state.value);
        apportionmentStore.handleCheckboxClick(this.props.data, this.state.value, this.props.column.getColId());
    }

    render() {
        console.log(this.state.value);
        return (
            <>
                <input
                    type="checkbox"
                    disabled={!dataStore.checkOperationAccess('Save')}
                    checked={this.state.value}
                    onChange={this.handleCheckboxChange}
                />
            </>
        );
    }
}
