import { TabletResponse } from '../domains/tablet-modal-response'
import { TabletFieldName, TabletHeaderName } from "../domains/tablet-enum"
import { CommonUtils } from 'shared/services/common-utils';

export class TabletValidationService {
    validateUpdateRequest(updatedList: TabletResponse[], initialList: TabletResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.TabletOS)) {
                errorMessage = `${TabletHeaderName.TABLETOS} is required `;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.TabletOS) && !CommonUtils.checkForAlphaNumeric(item.TabletOS)) {
                errorMessage = `${TabletHeaderName.TABLETOS} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.Manufacturer) && !CommonUtils.checkForAlphaNumeric(item.Manufacturer)) {
                errorMessage = `${TabletHeaderName.MANUFACTURE} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.TabletIMEI)) {
                errorMessage = `${TabletHeaderName.TABLETIMEI} is required `;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.AndroidID)) {
                errorMessage = `${TabletHeaderName.ANDROIDID} is required `;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.TabletIMEI) && !CommonUtils.checkForAlphaNumeric(item.TabletIMEI)) {
                errorMessage = `${TabletHeaderName.TABLETIMEI} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.TabletIMEI)) {
                const isTabletNumberDuplicate = initialList.find(a => (a.TabletIMEI == item.TabletIMEI) && (a.ID != item.ID));
                if (isTabletNumberDuplicate) {
                    errorMessage = `${TabletHeaderName.TABLETIMEI} must be unique`;
                    return;
                }
            }
            if (CommonUtils.checkFieldMaxLength(150, item[TabletFieldName.ASSIGNEDTO])) {
                errorMessage = `${TabletFieldName.ASSIGNEDTO}  is allowed upto 150 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(30, item[TabletFieldName.TABLETOS])) {
                errorMessage = `${TabletFieldName.TABLETOS}  is allowed upto 30 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(30, item[TabletFieldName.MANUFACTURE])) {
                errorMessage = `${TabletFieldName.MANUFACTURE}  is allowed upto 30 characters`;
                return;
            }
            if (CommonUtils.checkFieldMaxLength(20, item[TabletFieldName.TABLETIMEI])) {
                errorMessage = `${TabletFieldName.TABLETIMEI}  is allowed upto 20 characters`;
                return;
            }
        });
        return errorMessage;
    }
}

export const tabletValidationService = new TabletValidationService();