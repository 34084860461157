import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';

const openDetail = (id: any, ticketNo:any) => {
    window.open('/defect?id=' + id+'&ticketno='+ticketNo, '_blank');
};

export const InspectionDetailCellRenderer = (props: ICellRendererParams) => {    
    return (

            <span style={{color:"blue"}}
            onClick={()=>openDetail(props.data.ID,props.data.TicketNumber)}>
                {props.data.TicketNumber ==undefined?'0000000':props.data.TicketNumber }</span>
    );
};
