import { UserApprovalDataStore } from './user-approval-data-store';
import { userApprovalDataService } from '../services/user-approval-data-service';
import { uiService } from '../../../shared/services/ui-service';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';

export const userApprovalStore = new UserApprovalDataStore(
    userApprovalDataService,
    agGridService,
    uiService,
    accountStore
);
