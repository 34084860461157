import { TicketHeaderName, TicketFieldName, TicketVolOrHourly, TicketStatus } from '../constants/ticket-enum';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/ticket-approval-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';

import { RadioButtonCellRenderer } from '../components/ticket-print-checkbox';
import { OperationsComponentName } from '../constants/constant';
const getLastPmtValue = value => {
    if (value === TicketStatus.CANCELED) {
        return 'Canceled by Driver';
    }
    return value;
};
const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};
const commaFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
const isFloat = (n) => {
    return (n - Math.floor(n)) !== 0
}
export const TICKET_COL_DEF: ColDef[] = [
    {
        headerName: TicketHeaderName.SELECT,
        field: TicketHeaderName.SELECT,
        width: 75,
        cellRendererFramework: RadioButtonCellRenderer
    },
    {
        headerName: TicketHeaderName.TICKETDATE,
        field: TicketFieldName.TICKETDATE,
        width: 125,
        valueGetter: params => (params.data[TicketFieldName.TICKETDATE] ? dateUtils.getFormattedDateTime(params.data[TicketFieldName.TICKETDATE]) : '')
    },
    {
        headerName: TicketHeaderName.STARTDATETIME,
        field: TicketFieldName.STARTDATE,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.STARTDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TicketFieldName.STARTDATE])
            }
        }
    },
    {
        headerName: TicketHeaderName.ENDDATETIME,
        field: TicketFieldName.ENDDATE,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.ENDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TicketFieldName.ENDDATE])
            }
        }
    },
    {
        headerName: TicketHeaderName.COMPLETEDDATE,
        field: TicketFieldName.COMPLETEDDATE,
        width: 105,
        // In response Completed Date is coming in UTC Format so converting the value in MM/DD/YYYY HH:mm format
        valueGetter: params => (params.data[TicketFieldName.COMPLETEDDATE] ? dateUtils.getFormattedDateTimeLocal(params.data[TicketFieldName.COMPLETEDDATE]) : '') 
    },
    {
        headerName: TicketHeaderName.TICKETNUMBER,
        field: TicketFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[TicketFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: TicketHeaderName.TYPE,
        field: TicketFieldName.TYPE,
        width: 60,
        valueGetter: params => {
            if (params.data[TicketFieldName.TYPE] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TYPE];
            }
        }
    },
    {
        headerName: TicketHeaderName.LEASEOPERATOR,
        field: TicketFieldName.LEASEOPERATOR,
        width: 150,
        valueGetter: params => {
            if (params.data[TicketFieldName.LEASEOPERATOR] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.LEASEOPERATOR];
            }
        }
    },
    {
        headerName: TicketHeaderName.DRIVERNAME,
        field: TicketFieldName.DRIVERNAME,
        width: 115,
        valueGetter: params => {
            if (params.data[TicketFieldName.DRIVERNAME] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: TicketHeaderName.REGION,
        field: TicketFieldName.REGION,
        width: 75,
        valueGetter: params => {
            if (params.data[TicketFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.REGION];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRAILER,
        field: TicketFieldName.TRAILER,
        width: 80,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRAILER] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRAILER];
            }
        }
    },
    {
        headerName: TicketHeaderName.ORIGIN,
        field: TicketFieldName.ORIGIN,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.ORIGIN];
            }
        }
    },
    {
        headerName: TicketHeaderName.DESTINATION,
        field: TicketFieldName.DESTINATION,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.DESTINATION];
            }
        }
    },
    {
        headerName: TicketHeaderName.Product,
        field: TicketFieldName.PRODUCT,
        width: 90,
        valueGetter: params => {
            if (params.data[TicketFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: TicketHeaderName.VOLUMEDELIVERED,
        field: TicketFieldName.VOLUMEDELIVERED,
        width: 95,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[TicketFieldName.VOLUMEDELIVERED] === null) {
                return '';
            } else {
                return roundOff(params.data[TicketFieldName.VOLUMEDELIVERED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WORKINGTIME,
        field: TicketFieldName.WORKINGTIME,
        colId: TicketFieldName.WORKINGTIME,
        width: 90,
        cellRendererFramework: AlphaNumericCellRenderer,
        filter: 'agNumberColumnFilter',
        cellClass: params => {
            const { data } = params;
            if(isFloat(data[TicketFieldName.WORKINGTIME])) {
                if(data.type == TicketVolOrHourly.HOURLY && !data.isApproved && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)) 
                return ['cell-editable', 'comma-format']
                else return 'comma-format'
            } else {
                if(data.type == TicketVolOrHourly.HOURLY && !data.isApproved && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)) 
                return ['cell-editable', 'work-wait']
                else return 'work-wait'
            }
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WORKINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WORKINGTIMEREASON,
        field: TicketFieldName.WORKINGTIMEREASON,
        colId: TicketFieldName.WORKINGTIMEREASON,
        width: 115,
        cellEditor: 'agSelectCellEditor',
        editable: params =>
            !params.data.isApproved &&
            params.data.type === TicketVolOrHourly.HOURLY &&
            dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval),
        cellClass: params => {
            const { data } = params;
            return data.type == TicketVolOrHourly.HOURLY &&
                !data.isApproved &&
                dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: TicketHeaderName.WAITINGTIME,
        field: TicketFieldName.WAITINGTIME,
        colId: TicketFieldName.WAITINGTIME,
        width: 80,
        cellRendererFramework: AlphaNumericCellRenderer,
        filter: 'agNumberColumnFilter',
        cellClass: params => {
            const { data } = params;
            if(isFloat(data[TicketFieldName.WAITINGTIME])) {
                if(data.type == TicketVolOrHourly.HOURLY && !data.isApproved && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)) 
                return ['cell-editable', 'comma-format']
                else return 'comma-format'
            } else {
                if(data.type == TicketVolOrHourly.HOURLY && !data.isApproved && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)) 
                return ['cell-editable', 'work-wait']
                else return 'work-wait'
            }
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WAITINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TicketHeaderName.WAITINGTIMEREASON,
        field: TicketFieldName.WAITINGTIMEREASON,
        colId: TicketFieldName.WAITINGTIMEREASON,
        width: 110,
        cellEditor: 'agSelectCellEditor',
        editable: params =>
            !params.data.isApproved &&
            params.data.type === TicketVolOrHourly.HOURLY &&
            dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval),
        cellClass: params => {
            const { data } = params;
            return data.type == TicketVolOrHourly.HOURLY &&
                !data.isApproved &&
                dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: TicketHeaderName.COMMENT,
        field: TicketFieldName.COMMENT,
        colId: TicketFieldName.COMMENT,
        width: 100,
        cellRendererFramework: TextAreaCellRenderer,
        cellClass: params => {
            const { data } = params;
            return !data.isApproved &&
                data.type === TicketVolOrHourly.HOURLY &&
                dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.COMMENT];
            }
        }
    },
    { headerName: TicketHeaderName.Id, field: TicketFieldName.ID, hide: true },
    {
        headerName: TicketHeaderName.SHIPPERTICKET,
        field: TicketFieldName.SHIPPERTICKET,
        width: 110,
        valueGetter: params => {
            if (params.data[TicketFieldName.SHIPPERTICKET] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.SHIPPERTICKET];
            }
        }
    },
    {
        headerName: TicketHeaderName.RELATEDVOLTICKET,
        field: TicketFieldName.RELATEDVOLUMETICKET,
        width: 130,
        valueGetter: params => {
            if (params.data[TicketFieldName.RELATEDVOLUMETICKET] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.RELATEDVOLUMETICKET];
            }
        }
    },
    {
        headerName: TicketHeaderName.STATUS,
        cellEditor: 'agSelectCellEditor',
        colId: TicketFieldName.STATUS,
        field: TicketFieldName.STATUS,
        width: 75,
        editable: params =>
            params.data.type == TicketVolOrHourly.HOURLY &&
            !params.data.isApproved &&
            params.data.status !== TicketStatus.CANCELED,
        cellClass: params => {
            const { data } = params;
            return data.type == TicketVolOrHourly.HOURLY && !data.isApproved &&
                data.status !== TicketStatus.CANCELED &&
                data.lastPmtStatus !== TicketStatus.CANCELED &&
                dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.STATUS] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.STATUS];
            }
        }
    },
    {
        field: TicketFieldName.APPROVERCOMMENT,
        headerName: TicketHeaderName.APPROVERCOMMENT,
        colId: TicketFieldName.APPROVERCOMMENT,
        width: 95,
        cellRendererFramework: TextAreaCellRenderer,
        cellClass: params => {
            const { data } = params;
            return data.type == TicketVolOrHourly.HOURLY && !data.isApproved &&
                data.status !== TicketStatus.CANCELED &&
                dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, OperationsComponentName.TicketApproval)
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[TicketFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.APPROVERCOMMENT];
            }
        }
    },
    {
        headerName: TicketHeaderName.LASTUPDATEDBY,
        field: TicketFieldName.LASTUPDATEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.LASTUPDATEDBY] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.LASTUPDATEDBY];
            }
        }
    },
    {
        headerName: TicketHeaderName.UPDATEDDATE,
        field: TicketFieldName.UPDATEDDATE,
        width: 105,
        valueGetter: params => {
            if (params.data[TicketFieldName.UPDATEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TicketFieldName.UPDATEDDATE]);
            }
        }
    },
    {
        headerName: TicketHeaderName.LASTPTISTATUS,
        field: TicketFieldName.LASTPMTSTATUS,
        width: 90,
        valueFormatter: (params: ValueFormatterParams): string => (params.value ? getLastPmtValue(params.value) : ''),
        valueGetter: params => {
            if (params.data[TicketFieldName.LASTPMTSTATUS] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.LASTPMTSTATUS];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRAILERSTATUS,
        field: TicketFieldName.TRAILERSTATUS,
        width: 130,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRAILERSTATUS] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRAILERSTATUS];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRACTORUSED,
        field: TicketFieldName.POWERUNITIDENTIFIER,
        width: 85,
        valueGetter: params => {
            if (params.data[TicketFieldName.POWERUNITIDENTIFIER] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.POWERUNITIDENTIFIER];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRAILERMAPPEDTOCOMPANY,
        field: TicketFieldName.TRAILERMAPPEDTOCOMPANY,
        width: 140,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: TicketHeaderName.MISMATCH,
        width: 145,
        valueGetter: params => {
            if (params.data[TicketFieldName.LEASEOPERATOR] !== params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        },
        cellClass: params => {
            return params.data[TicketFieldName.LEASEOPERATOR] !== params.data[TicketFieldName.TRAILERMAPPEDTOCOMPANY]
                ? 'trailer-mapped-no'
                : 'trailer-mapped-yes';
        }
    },
    {
        headerName: TicketHeaderName.TRACTORMAPPEDTOCOMPANY,
        field: TicketFieldName.TRACTORMAPPEDTOCOMPANY,
        width: 140,
        valueGetter: params => {
            if (params.data[TicketFieldName.TRACTORMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[TicketFieldName.TRACTORMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: TicketHeaderName.TRACTORMISMATCH,
        width: 150,
        valueGetter: params => {
            if (params.data[TicketFieldName.LEASEOPERATOR] !== params.data[TicketFieldName.TRACTORMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        },
        cellClass: params => {
            return params.data[TicketFieldName.LEASEOPERATOR] !== params.data[TicketFieldName.TRACTORMAPPEDTOCOMPANY]
                ? 'trailer-mapped-no'
                : 'trailer-mapped-yes';
        }
    },
    {
        headerName: TicketHeaderName.TICKETSOURCE,
        field: TicketFieldName.TICKETSOURCE,
        width: 80,
    },
    { field: TicketFieldName.ISAPPROVED, hide: true }
];
