import * as React from 'react';
import './_collapisble.scss';
import className from 'classnames';
//import '@font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface CollapisbleProps {
    title: string;
    collapsedpros?: boolean;
}

export interface CollapisbleState {
    collapsed: boolean;
}

export class Collapisble extends React.Component<CollapisbleProps, CollapisbleState> {
    readonly state = {
        collapsed: false
    };
    public render(): React.ReactNode {
        const { collapsed } = this.state;
        const { children, title } = this.props;
        const classNames = className('collapisble__content', {
            'collapisble-collapsed': collapsed
        });
        const collapseIconClassName = collapsed
            ? `fa fa-angle-up  collapisble_fa-angle-up-font`
            : `fa fa-angle-down  collapisble_fa-angle-up-font`;
        // const collapseIconClassName = collapsed ? `fas fa-angle-down` : `fas fa-angle-up`;

        return (
            <div className="collapisble">
                <div className="collapisble__header" onClick={this.handleCollapse}>
                    <div className="collapisble__title">{title}</div>
                    <div className="collapisble__icon">
                        <i className={collapseIconClassName} aria-hidden="true"></i>
                    </div>
                </div>
                <div className={classNames}>{children}</div>
            </div>
        );
    }

    private handleCollapse = (): void => {
        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed });
    };
}
