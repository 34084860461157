import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { uiService } from 'shared/services/ui-service';
import className from 'classnames';
import { dataStore } from 'features/common/stores';
import { columnType, dataType } from './inputpicket_constants';

interface ReactDayPickerInputProps {
    _dataStore: any;
    isDisabled?: boolean;
    showOneDay?:boolean;
    showFirstDay?:boolean;
}
export interface DateRange {
    StartDate: string;
    EndDate: string;
}

export class ReactDayPickerInput extends React.Component<ReactDayPickerInputProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .subtract(6, 'days')
        .toDate();
    fromOneDay: any = moment().toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    date: any = moment().toDate();
    fromFirstDay: any = moment()
        .startOf('month')
        .toDate();
    public render(): React.ReactNode {
        const { _dataStore, isDisabled = true } = this.props;
        const { showOneDay, showFirstDay } = this.props;
        return (
            <div>
                <Row>
                    <Col className="mt-3 mt-sm-0">
                        <div className="InputFromTo">
                            Select Date Range : From{' '}
                            <span className="InputFromTo-from">
                                <DayPickerInput
                                    ref={el => (this.fromDate = el)}
                                    value={showOneDay ? this.fromOneDay : showFirstDay ? this.fromFirstDay : this.from}
                                    inputProps={{ readOnly: true }}
                                    format="MM/dd/yyyy"
                                    formatDate={(value, format, _locale) => {
                                        return moment(value).format(AppConstant.DATE_FORMAT);
                                    }}
                                    placeholder="Start Date"
                                    dayPickerProps={{
                                        selectedDays: showOneDay
                                            ? this.fromOneDay
                                            : showFirstDay
                                            ? this.fromFirstDay
                                            : this.from,
                                        disabledDays: { after: isDisabled ? this.date : '' },
                                        onDayClick: (date, modifiers) => {
                                            if (!modifiers.disabled) {
                                                this.startDate = date;
                                                this.from = date;
                                                this.fromOneDay = date;
                                                this.fromFirstDay = date;
                                                if (!this.endDate) {
                                                    this.toDate.getInput().focus();
                                                } else if (this.startDate > this.endDate) {
                                                    this.toDate.getInput().focus();
                                                } else {
                                                    this.toDate.getInput().focus();
                                                }
                                            }
                                        }
                                    }}
                                />
                            </span>{' '}
                            To{' '}
                            <span className="InputFromTo-to">
                                <DayPickerInput
                                    ref={el => (this.toDate = el)}
                                    value={this.to}
                                    inputProps={{ readOnly: true }}
                                    formatDate={(value, format, _locale) => {
                                        return moment(value).format(AppConstant.DATE_FORMAT);
                                    }}
                                    placeholder="EndDate"
                                    dayPickerProps={{
                                        selectedDays: this.to,
                                        disabledDays: { after: isDisabled ? this.date : '' },
                                        onDayClick: (date, modifiers) => {
                                            if (!modifiers.disabled) {
                                                this.endDate = date;
                                                this.to = date;
                                                if (!this.startDate) {
                                                    this.fromDate.getInput().focus();
                                                } else if (this.startDate > this.endDate) {
                                                    this.fromDate.getInput().focus();
                                                    uiService.toastService.info(
                                                        'StartDate cannot be greater than EndDate'
                                                    );
                                                } else {
                                                    this.getData(_dataStore);
                                                }
                                            }
                                        }
                                    }}
                                />
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    getData = (_dataStore: any) => {
        _dataStore.getRangeResult(this.startDate.toISOString().slice(0, 10), this.endDate.toISOString().slice(0, 10));
    };
}
