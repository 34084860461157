import { action, observable } from 'mobx';
import Loader from '../../../shared/decorators/loader-decorator';
import { MappingDataService } from '../services/mapping-data-service';
import {
    RegionMappingDataRequest,
    RegionMappingType,
    Region,
    CompanyMappingType,
    CompanyMappingDataRequest
} from '../model/model';
import { localStorageService } from 'shared/services/local-storage-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from '../../account/stores/account-store';

export class MappingDataStore {
    userID = 0;
    userName = '';


    @observable regionMappingData: RegionMappingType = {
        NotMappedCompany: {},
        MappedCompany: {},
        MappedTrailer: {},
        NotMappedTrailer: {},
        NotMappedConsignorConsignee: {},
        MappedConsignorConsignee: {},
        MappedDriver: {},
        NotMappedDriver: {}
    };

    @observable companyMappingData: CompanyMappingType = {
        DriverNotMapped: {},
        DriverMapped: {},
        TrailerMapped: {},
        TrailerNotMapped: {},
        TractorNotMapped: {},
        TractorMapped: {},
        CompanyName: '',
        PrinterMapped: {},
        PrinterNotMapped: {},
        TabletMapped: {},
        TabletNotMapped: {}
    };

    @observable dataExist = false;
    @observable isUpdated = false;
    @observable menuLoader = false;
    @observable baseValueDTO: Region[] = [];
    @observable objToSave: {
        CreatedBy: number;
        createdDate: Date;
        lstCompany: number[];
        lstTrailer: number[];
        lstConsignorConsignee: number[];
        lstDrivers: number[];
        regionID: number;
    } = {
            CreatedBy: this.userID,
            createdDate: new Date(),
            lstCompany: [],
            lstTrailer: [],
            lstConsignorConsignee: [],
            lstDrivers: [],
            regionID: 0
        };
    @observable objCompanyMappingToSave: {
        BaseID: number;
        CreatedBy: number;
        createdDate: Date;
        driverMappedID: number[];
        printerMappedID: number[];
        tabletMappedID: number[];
        trailerMappedID: number[];
        truckMappedID: number[];
    } = {
            BaseID: 0,
            CreatedBy: this.userID,
            createdDate: new Date(),
            driverMappedID: [],
            printerMappedID: [],
            tabletMappedID: [],
            trailerMappedID: [],
            truckMappedID: []
        };
    isMappingEdited: boolean = false;
    @observable mappedTrailerToSave = [];
    @observable selectedRegionID = 0;
    constructor(private mappingDataService: MappingDataService, private accountStore: AccountStore) {
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @action
    setIsUpdated(value: boolean) {
        this.isUpdated = value;
    }

    getRegionMapJson(regionMappingArray) {
        const mappingRegion: RegionMappingType = {
            NotMappedCompany: {},
            MappedCompany: {},
            MappedTrailer: {},
            NotMappedTrailer: {},
            NotMappedConsignorConsignee: {},
            MappedConsignorConsignee: {},
            MappedDriver: {},
            NotMappedDriver: {}
        };
        if (regionMappingArray.length > 0) {
            regionMappingArray.forEach(key => {
                if (mappingRegion.hasOwnProperty(key.Mapping)) {
                    mappingRegion[key.Mapping][key.ID] = key.Name;
                }
            });
        }
        return mappingRegion;
    }

    @Loader
    @action
    async getRegionMappingData(reqBody: RegionMappingDataRequest): Promise<void> {
        try {
            this.dataExist = false;
            const result = await this.mappingDataService
                .getCompanyRegionMapping(reqBody)
                .then(response => {
                    this.regionMappingData = this.getRegionMapJson(response.data['Data']);
                    this.menuLoader = false;
                    this.dataExist = true;
                })
                .catch(function (error) {
                    console.log('error in getting mapping data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }
    @Loader
    @action
    async GetMasterData(): Promise<void> {
        const Region = localStorageService.get('region');
        try {
            if (Region) {
                this.baseValueDTO = Region as Region[];
            } else {
                await this.mappingDataService
                    .getRegionTypes()
                    .then(response => {
                        let responseData = response.data['Data'];
                        let sortedDataForNorthRegion = responseData.filter((item) => item.Name.includes('North')).sort((a, b) => a.ID > b.ID);
                        let sortedDataForSouthRegion = responseData.filter((item) => item.Name.includes('South')).sort((a, b) => a.ID > b.ID)
                        this.baseValueDTO = [...sortedDataForNorthRegion, ...sortedDataForSouthRegion]
                        localStorageService.set('region', this.baseValueDTO);
                    })
                    .catch(function (error) {
                        console.log('error in getting Region', error);
                    });
            }
        }
        catch (e) {
            localStorageService.remove('region');
            console.log('error in getting Region', e);
        }
    }

    @Loader
    @action
    async saveRegion(data: any): Promise<void> {
        const toastService = new UiService();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const thisObj = this;
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        try {
            data.CreatedBy = this.accountStore.getUserID();
            await this.mappingDataService
                .saveRegion(data)
                .then(response => {
                    if (response.data['Data']) {
                        toastService.toastService.success('Record(s) Save successfully');
                    } else {
                        toastService.toastService.error('Record(s) Saved failed');
                    }
                })
                .catch(function (error) {
                    thisObj.menuLoader = false;
                    toastService.toastService.error('Record(s) Saved failed');
                });
        } catch (e) {
            this.menuLoader = false;
            toastService.toastService.error('Record(s) Saved failed');
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getCompanyMappingData(reqBody: CompanyMappingDataRequest): Promise<void> {
        try {
            this.dataExist = false;
            const result = await this.mappingDataService
                .getCompanyTrailerMapping(reqBody)
                .then(response => {
                    this.companyMappingData = response.data['Data'];
                    this.menuLoader = false;
                    this.dataExist = true;
                })
                .catch(function (error) {
                    console.log('error in getting mapping data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async saveCompanyMapping(data: any): Promise<void> {
        const toastService = new UiService();
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        data.CreatedBy = this.accountStore.getUserID();
        await this.mappingDataService.saveCompanyMapping(data).then(response => {
            if (response.data['Data']) {
                toastService.toastService.success('Record(s) Save successfully');
            } else {
                toastService.toastService.error('Record(s) Saved failed');
            }
        });
    }
}
