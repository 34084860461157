import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { HazardAssessmentDataStore } from '../stores/hazardAssessment-data-store';
import { HazardAssessmentReportComponent } from './hazardAssessment-report';
import './hazardAssessment-component.scss'
import { CommonUtils } from 'shared/services/common-utils';
import { Container, Row, Col } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from './hazard-pdf-preview-component';
import { HazardAssessmentReport, HazardAssessmentRequest } from '../model/hazardAssessmentReport-model';

interface HazardAssessmentUpComponentProps {
    hazardAssessmentDataStore: HazardAssessmentDataStore;
    hazardAssesmentReport?: HazardAssessmentReport;
    hazardAssessmentNumber: number;
}
@inject('hazardAssessmentDataStore')
@observer
export class HazardAssessmentUpComponent extends Component<HazardAssessmentUpComponentProps> {
    constructor(props) {
        super(props);
        props.hazardAssessmentDataStore.init(
            {
            HazardID: CommonUtils.getQueryStringParam('ticketno') 
            }
        );
       
    }
    handleClear = () => {
        return null;
    };
    render(): React.ReactNode {
        const { hazardAssessmentDataStore } = this.props;
        let hazard = hazardAssessmentDataStore.hazardReport;
        let ticketNumber = CommonUtils.getQueryStringParam('ticketno');
        let titletext = 'Hazard ID: ' + (ticketNumber ==undefined?'0':ticketNumber) ;

        if (ticketNumber) {
            return (
                <Container fluid>
                    <>
                    <Row>
                        <Col>
                            <div>
                                <Row style={{
                                    display: 'flex' }}>
                                    <Col className="mt-3 mt-sm-3" md={10}>
                                      <span className="ticket_number">{titletext}</span>
                                    </Col>

                                    <Col className="mt-3 mt-sm-3">
                                    <span  style={{
                                    display: 'block' }}>
                                    <PDFDownloadLink
                                        document={<MyDocument hazard={hazard} />}
                                        fileName={
                                            hazard.HazardID + '.pdf'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            padding: '8px',
                                            color: '#ffffff',
                                            backgroundColor: '#00B1F6',
                                            borderRadius: '5px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {' '}
                                        DOWNLOAD PDF
                                    </PDFDownloadLink>
                                    </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <Row>
                                    <Col className="mt-3 mt-sm-3">
                                        <HazardAssessmentReportComponent
                                        hazard={hazard}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>    
                    </Row>
                    </>
                </Container>
                );
        } else {
            return <div></div>;
        }
    }
}
