import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import jsPDF from 'jspdf';
import { HourlyReportDataStore } from '../stores/hourlyreport-data-store';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
/* eslint-disable */
interface HourlyReportProps {
    hourlyReportStore: HourlyReportDataStore;
}

@observer
export class HourlyReportComponent extends Component<HourlyReportProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .subtract(6, 'days')
        .toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    constructor(props) {
        super(props);
        dataStore.setModuleName('HourlyReport');
    }

    componentDidMount() {
        this.props.hourlyReportStore.init();
    }
    getTickets = () => {
        this.props.hourlyReportStore.getRangeResult(
            this.startDate.toISOString().slice(0, 10),
            this.endDate.toISOString().slice(0, 10)
        );
    };

    render(): React.ReactNode {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <ClearButtonComponent
                                        handleRefresh={this.handleRefresh}
                                        isExportCSV={true}
                                        disabled={this.props.hourlyReportStore.isDisabled()}
                                    ></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <ReactDayPickerInput _dataStore={this.props.hourlyReportStore}></ReactDayPickerInput>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                        <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Volume Delivered Sum : {this.props.hourlyReportStore.volumeSum.volDeliveredSum}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Waiting Time : {this.props.hourlyReportStore.volumeSum.totalWaitingTime}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Working Time : {this.props.hourlyReportStore.volumeSum.totalWorkingTime}
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('HourlyReport_filter');
        this.props.hourlyReportStore.agGridService.setAllFilters(filters);
    }

    handleRefresh = () => {
        const { hourlyReportStore } = this.props;
            if(hourlyReportStore.startDate && hourlyReportStore.endDate){
            hourlyReportStore.getRangeResult(hourlyReportStore.startDate,hourlyReportStore.endDate);
        }
            else {
            hourlyReportStore.getHourlyReports();
            }
    }

    private exportCsv = (): void => {
        const data:any = this.props.hourlyReportStore.agGridService.getNodes();
        
        for (let i = 0; i < data?.rowModel.rowsToDisplay.length; i++) {
            data.rowModel.rowsToDisplay[i].data['Ticket_Date'] =
            data.rowModel.rowsToDisplay[i].data['Ticket_Date'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data.rowModel.rowsToDisplay[i].data['Ticket_Date']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
        }
        
        data?.exportDataAsCsv({ fileName: TitlePDF.HourlyReport });
    };

    private getGridConfig(): GridOptions {
        const {
            hourlyReportStore: { DuplicateList, agGridService }
        } = this.props;

        const rowData = DuplicateList;
        const columnDefs = this.props.hourlyReportStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const HourlyReportGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(HourlyReportGridParams);
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.hourlyReportStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName() + '_filter', this.props.hourlyReportStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        
        this.props.hourlyReportStore.getVolumeSum(data);
    };
}
