import { PrinterResponse } from "../../domains/printer-management/printer-model"
import { PrinterFieldName, PrinterHeaderName } from "../../domains/printer-management/printer-enum"
import { CommonUtils } from "shared/services/common-utils";

export class PrinterValidationService {
    validateUpdateRequest(updatedList: PrinterResponse[], initialList: PrinterResponse[]) {
        let printerErrorMessage = '';
        updatedList.forEach(item => {

            if (CommonUtils.checkIfFieldIsNullOrEmpty(item[PrinterFieldName.PRINTERSERIALNUMBER])) {
                printerErrorMessage = `${PrinterHeaderName.PRINTERSERIALNUMBER}  is required`;
                return;

            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item[PrinterFieldName.PRINTERNAME])) {
                printerErrorMessage = `${PrinterHeaderName.PRINTERNAME}  is required`;
                return;
            }

            if (CommonUtils.checkFieldMaxLength(150, item[PrinterFieldName.ASSIGNEDTO])) {
                printerErrorMessage = `${PrinterHeaderName.ASSIGNEDTO} is allowed upto 150 characters`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[PrinterFieldName.PRINTERSERIALNUMBER])) {
                const isPrinterNumberDuplicate = initialList.find(a => (a.PrinterSerialNumber == item.PrinterSerialNumber) && (a.ID != item.ID));
                if (isPrinterNumberDuplicate) {
                    printerErrorMessage = `${PrinterHeaderName.PRINTERSERIALNUMBER} must be unique`;
                    return;
                }
            }

        });
        return printerErrorMessage;
    }
}

export const printerValidationService = new PrinterValidationService();