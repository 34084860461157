import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { DailyReportResponse, DateRange } from '../domains/model';

export class DailyReportDataService {
    constructor(private restApi: RestApiService) {}

    async getDailyReport(): Promise<DailyReportResponse[]> {
        const { data } = await this.restApi.postWithToken<DailyReportResponse[]>(Config.getDailyReport);
        return data['Data'];
    }
    async getDailyReportwithParams(reqbody: DateRange): Promise<DailyReportResponse[]> {
        const { data } = await this.restApi.postWithToken<DailyReportResponse[]>(Config.getDailyReport, reqbody, {});
        return data['Data'];
    }
}

export const dailyreportDataService = new DailyReportDataService(restApiService);
