import { UpcomingCertificatesHeaderName, UpcomingCertificatesFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { upcomingCertificationDataStore } from '../stores';

const getYear = () => {
    if(upcomingCertificationDataStore.month == 12){
        return upcomingCertificationDataStore.year + 1
    } else {
        return upcomingCertificationDataStore.year
    }
}
export const upcomingCertificateColDef: ColDef[] = [
    {
        headerName: UpcomingCertificatesHeaderName.DATE,
        field: UpcomingCertificatesFieldName.DATE,
        width: 85,
        valueGetter: (value): string =>
            moment.months()[(upcomingCertificationDataStore.month + 11) % 12].substring(0,3)+ ' 1, '+ 
             [upcomingCertificationDataStore.month === 0 ? upcomingCertificationDataStore.year - 1 : upcomingCertificationDataStore.year]
    },
    {
        headerName: UpcomingCertificatesHeaderName.TRAILERNUMBER,
        field: UpcomingCertificatesFieldName.TRAILERNUMBER,
        width: 85,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.MAPPEDCARRIERCOMPANY,
        field: UpcomingCertificatesFieldName.MAPPEDCARRIERCOMPANY,
        width: 140,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.MAPPEDCARRIERCOMPANY] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.MAPPEDCARRIERCOMPANY];
            }
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.TRAILERSTATUS,
        field: UpcomingCertificatesFieldName.TRAILERSTATUS,
        width: 130,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.TRAILERSTATUS] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.TRAILERSTATUS];
            }
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.TRAILERTYPE,
        field: UpcomingCertificatesFieldName.TRAILERTYPE,
        width: 85,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.TRAILERTYPE] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.TRAILERTYPE];
            }
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.DUEINSPECTIONSCOUNT,
        field: UpcomingCertificatesFieldName.DUEINSPECTIONSCOUNT,
        width: 150,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.DUEINSPECTIONSCOUNT] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.DUEINSPECTIONSCOUNT];
            }
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.DUEDATE,
        field: UpcomingCertificatesFieldName.DUEDATE,
        width: 90,
        valueGetter: params => {
            return moment.months()[upcomingCertificationDataStore.month%12].substring(0,3)+ ' '+ getYear();
        }
    },
    {
        headerName: UpcomingCertificatesHeaderName.DUEINSPECTIONS,
        field: UpcomingCertificatesFieldName.DUEINSPECTIONS,
        width: 300,
        valueGetter: params => {
            if (params.data[UpcomingCertificatesFieldName.DUEINSPECTIONS] === null) {
                return '';
            } else {
                return params.data[UpcomingCertificatesFieldName.DUEINSPECTIONS];
            }
        }
    }
];