import { RestApiService } from '../../../shared/services/rest-api-service';
import { localStorageService } from '../../../shared/services/local-storage-service';
import { authenticationService } from '../../../shared/services/authentication-service';
import { accountSubscription } from '../../../shared/services/account-subscription-service';
import { InspectionDataService } from '../services/inspection-data-service';
import { InspectionDataStore } from './inspection-data-store';
import { TrailerMaintenanceDataStore } from './trailer-maintenance-data-store';
import { trailerMaintenaceDataService } from '../services/maintenance-data-service';
import { uiService } from '../../../shared/services/ui-service';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';
import { InspectionTicketHistoryDataStore } from './InspectionTicketHistoryDataStore';
import { inspectionTicketHistoryDataService } from '../services/inspection-ticket-history-data-service';
export const inspectionDataStore = new InspectionDataStore(
    new InspectionDataService(new RestApiService(localStorageService, accountSubscription, authenticationService))
);

export const trailerMaintenanceStore = new TrailerMaintenanceDataStore(
    trailerMaintenaceDataService,
    agGridService,
    uiService,
    accountStore
);

export const inspectionHistoryStore = new InspectionTicketHistoryDataStore(
    inspectionTicketHistoryDataService,
    agGridService,
    uiService,
    accountStore
);
