/* eslint-disable */
import React, { PureComponent } from 'react';
import { PrepostChecklist } from '../model/prepostchecklist';
import { InspectionDefect } from '../model/inspectionDefect';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { ItemContainer } from 'shared/components/item-container/item-container';
import { CommonUtils } from 'shared/services/common-utils';
import './_maintenance-data.scss';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { ReactSpinner } from 'shared/components/spinner/spinner';
import { localStorageService } from 'shared/services/local-storage-service';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import { ReactSelect } from 'shared/components/select/select';
import { Option } from '../../../shared/types/shared';
import { version } from 'react-dom';
import { observer } from 'mobx-react';
import { Left } from 'react-bootstrap/lib/Media';
import { InspectionDefectRequest } from 'features/maintenance-portal/model/inspectionDefectRequest';
import { inspectionDataStore } from 'features/maintenance-portal/stores/index';
import { InspectionDataService } from 'features/maintenance-portal/services/inspection-data-service';
interface InspectionDefectListComponentProps {
    prepostChecklist: PrepostChecklist[];
    inspectionDefectList: InspectionDefect[];
    inspectionTicketNumber: number;
    onHide?: () => void;
}

interface InspectionDefectListComponentState {
    isMajor: boolean;
    isMinor: boolean;
    IsSelteced: boolean;
    IsNotSelteced: boolean;
    IsSelecteNA: boolean;
    IsAllDefectSelteced: boolean;
    isAllMajorMinor: boolean;
    loader: boolean;
    showPopUp: boolean;
    showPopUpImage: string;
    versionlist: any;
    selectedVersion: any;
}
@observer
export class InspectionDefectListComponent extends PureComponent<
InspectionDefectListComponentProps,
InspectionDefectListComponentState
> {
    constructor(props) {
        super(props);
        this.state = {
            isMajor: false,
            isMinor: false,
            IsSelteced: false,
            IsNotSelteced: false,
            IsSelecteNA: false,
            IsAllDefectSelteced: true,
            isAllMajorMinor: true,
            loader: false,
            showPopUp: false,
            showPopUpImage: '',
            versionlist: [],
            selectedVersion: 0
        };
        this.handleMajorChecked = this.handleMajorChecked.bind(this);
        this.handleMinorChecked = this.handleMinorChecked.bind(this);
        this.handleNotDefected = this.handleNotDefected.bind(this);
        this.handleDefected = this.handleDefected.bind(this);
        this.handleNotApplicable = this.handleNotApplicable.bind(this);
        this.handleAllDefected = this.handleAllDefected.bind(this);
        this.handleAllMajorMinor = this.handleAllMajorMinor.bind(this);
        this.handleShowPop = this.handleShowPop.bind(this);
        this.handleHidePop = this.handleHidePop.bind(this);
    }

    public render(): React.ReactNode {
        const { prepostChecklist } = this.props;
        return (
            <div className={'maintenance-data_scroll1'}>
                {prepostChecklist.length == 0 || this.state.loader == true ? (
                    <div></div>
                ) : (
                        <React.Fragment>
                            <div className="Uimargin1">
                                <Collapisble title="Filter" collapsedpros={true}>
                                    {this.renderFilter()}
                                </Collapisble>
                                {this.renderDefects(this.transfromChecklist(prepostChecklist))}
                                {this.renderAdditionalDefectItemImages(this.transfromAdditionalImages(prepostChecklist))}
                                {this.renderAdditionalDefectItemImagesPopUp('')}
                                {this.fillversion(prepostChecklist)}
                        </div>
                        </React.Fragment>
                    )}
            </div>
        );
    }

    private renderDefects(checklist: Map<string, PrepostChecklist[]>): React.ReactNode {
        let items: JSX.Element[] = [];

        //need to fix the complie error here

        for (const [key, value] of checklist.entries()) {
            const item = (
                <ItemContainer>
                    <Collapisble title={key}>{this.renderDefectItem(value)}</Collapisble>
                </ItemContainer>
            );
            items = [...items, item];
        }
        return items;
    }

    private renderDefectItem(prepostChecklist: PrepostChecklist[]): React.ReactNode {
        return prepostChecklist.map((item, index) => {
            return (
                <ItemContainer key={index}>
                    <Container className={(item.IsMajor ? 'defect-item-container' : 'defect-item-containerWhite')}>
                        <Row>
                            <Col className="defect-column-description">
                                <span className={(item.IsMajor ? 'green' : 'a')}> {item.PrePostChecklistName}</span>
                            </Col>
                            { item.CheckListCategoryName !== "General - Section 13" && (<Col>
                                <div className="radio-right">
                                    <label className="defect-column-first-radio">
                                        <input
                                            type="radio"
                                            id={'chk' + item.PrePostChecklistID}
                                            checked={item.IsSelectedYes}
                                        />
                                        Defect
                                    </label>
                                    <label className="defect-column-first-radio">
                                        <input
                                            type="radio"
                                            id={'chk' + item.PrePostChecklistID}
                                            checked={item.IsSelectedNo}
                                        />
                                        No Defect
                                    </label>
                                    <label className="defect-column-first-radio">
                                        <input
                                            type="radio"
                                            id={'chk' + item.PrePostChecklistID}
                                            checked={item.IsSelectedNA}
                                        />
                                        Not Applicable
                                    </label>
                                </div>
                            </Col>)}
                        </Row>
                    </Container>
                    {this.renderDefectItemImages(item.ImageURL)}
                </ItemContainer>
            );
        });
    }

    transfromAdditionalImages(checklist: PrepostChecklist[]) {
        if (checklist.length > 0) {
            return checklist[0].AdditionalImages;
        }
    }

    fillversion(checklist: PrepostChecklist[]) {
        if (checklist.length > 0) {
            this.setState({ versionlist: checklist[0].version });
        }
    }
    transfromChecklist = (checklist: PrepostChecklist[]): Map<string, PrepostChecklist[]> => {
        const filterObj = this.state;

        if (filterObj.isAllMajorMinor == true && filterObj.IsAllDefectSelteced == true) {
            checklist = checklist;
        } else {
            if (filterObj.IsAllDefectSelteced == true) {
                checklist = checklist;
            } else if (filterObj.IsSelteced == true) {
                checklist = checklist.filter(x => x.IsSelectedYes == true);
            } else if (filterObj.IsNotSelteced == true) {
                checklist = checklist.filter(x => x.IsSelectedNo == true);
            } else if (filterObj.IsSelecteNA === true) {
                checklist = checklist.filter(x => x.IsSelectedNA === true);
            }
            if (filterObj.isAllMajorMinor == true) {
                checklist = checklist;
            } else if (filterObj.isMajor == true) {
                checklist = checklist.filter(x => x.IsMajor == true);
            } else if (filterObj.isMinor == true) {
                checklist = checklist.filter(x => x.IsMajor == false);
            }
        }

        let activeCategoriesChecklistData = checklist.filter((item) => item.CheckListCategoryIsActive && item.IsActive)
        return CommonUtils.groupBy(
            activeCategoriesChecklistData,
            (prepostchecklist: PrepostChecklist) => prepostchecklist.CheckListCategoryName
        );
    };

    private renderDefectItemImages(images: []): React.ReactNode {
        if (images !== undefined && images.length > 0) {
            const DefectImages = images.map(note => (
                <img src={note} width="100" height="100" onClick={this.handleShowPop(note)}></img>
            ));
            return <ul>{DefectImages}</ul>;
        }
    }
    private renderAdditionalDefectItemImages(images: []): React.ReactNode {
        let comments = "";
        const { prepostChecklist } = this.props;
        if(prepostChecklist.length > 0){
            comments = prepostChecklist[0].Comments ;
        }
        if (images !== undefined) {
            const DefectImages = images.map(note => (
                <ul>
                    {' '}
                    <img src={note} width="50" height="50" onClick={this.handleShowPop(note)}></img>
                </ul>
            ));
            if (DefectImages.length > 0 || comments != "") {
                const AdditionalImages = 'General - Section 13';
                const item = (
                    <ItemContainer>
                        <Collapisble title={AdditionalImages}>
                        {comments && (
                        <Col className={'defect-item-containerWhite'}>
                                <span className={'a'}> {comments}</span>
                        </Col>
                        )}
                            <ItemContainer>{DefectImages}</ItemContainer>
                        </Collapisble>
                    </ItemContainer>
                );

                return item;
            }
        }
    }

    private renderAdditionalDefectItemImagesPopUp(images: string): React.ReactNode {
        if (this.state.showPopUp) {
            const item = (
                <Modal size="xl" onHide={this.handleHidePop} show={true}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <img className={'imageauto'} src={this.state.showPopUpImage}></img>{' '}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHidePop} variant="secondary">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
            return item;
        }
    }
    handleShowPop = param => e => {
        this.setState({ showPopUp: true, showPopUpImage: e.target.src });
    };

    handleHidePop = () => {
        this.setState({ showPopUp: false });
    };

    handleDefected = () => {
        this.setState({ loader: true });
        this.setState({ IsSelteced: !this.state.IsSelteced }, () => {
            if (this.state.IsSelteced) {
                this.setState({ IsNotSelteced: false, IsSelecteNA: false, IsAllDefectSelteced: false, loader: false });
            }
        });
    };
    handleNotDefected = () => {
        this.setState({ loader: true });
        this.setState({ IsNotSelteced: !this.state.IsNotSelteced }, () => {
            if (this.state.IsNotSelteced) {
                this.setState({ IsSelteced: false, IsSelecteNA: false, IsAllDefectSelteced: false, loader: false });
            }
        });
    };
    handleNotApplicable = () => {
        this.setState({ loader: true });
        this.setState({ IsSelecteNA: !this.state.IsSelecteNA }, () => {
            if (this.state.IsSelecteNA) {
                this.setState({ IsNotSelteced: false, IsSelteced: false, IsAllDefectSelteced: false, loader: false });
            }
        });
    };
    handleAllDefected = () => {
        this.setState({ loader: true });
        this.setState({ IsAllDefectSelteced: !this.state.IsAllDefectSelteced }, () => {
            if (this.state.IsAllDefectSelteced) {
                this.setState({ IsSelteced: false, IsNotSelteced: false, IsSelecteNA: false, loader: false });
            }
        });
    };
    handleMajorChecked = () => {
        this.setState({ loader: true });
        this.setState({ isMajor: !this.state.isMajor }, () => {
            if (this.state.isMajor) {
                this.setState({ isMinor: false, isAllMajorMinor: false, loader: false });
            }
        });
    };
    handleMinorChecked = () => {
        this.setState({ loader: true });
        this.setState({ isMinor: !this.state.isMinor }, () => {
            if (this.state.isMinor) {
                this.setState({ isMajor: false, isAllMajorMinor: false, loader: false });
            }
        });
    };
    handleAllMajorMinor = () => {
        this.setState({ isAllMajorMinor: !this.state.isAllMajorMinor, loader: true }, () => {
            if (this.state.isAllMajorMinor) {
                this.setState({ isMajor: false, isMinor: false, loader: false });
            }
        });
    };
    handleDataTypeChange = e => {

        // @observable trailerInfo: TrailerMaintenanceRequest = defaultTrailerInfo;
        let ticketid = CommonUtils.getQueryStringParam('id');
        const inspectionDefectRequestobj: InspectionDefectRequest = { InspectionTicketID: 0, Version: 0 };
        inspectionDefectRequestobj.InspectionTicketID = CommonUtils.getQueryStringParam('id');
        inspectionDefectRequestobj.Version = e.value;
        this.setState({ selectedVersion: e.value });
        inspectionDataStore.getPrepostChecklistdata(inspectionDefectRequestobj);
    };

    

    private renderFilter(): React.ReactNode {
        const { selectedVersion } = this.state;
        const versionList: Option[] = [];
        let selectedOption: any = {}
        if (this.state.versionlist) {
            this.state.versionlist.sort((a,b) =>  (a > b ? 1 : -1)).forEach(element => {
                versionList.push({ label: element, value: element })
            });
            if (selectedVersion > 0) {
                selectedOption = { label: selectedVersion, value: selectedVersion }
            }
            else {
                selectedOption = versionList.length > 0 ? versionList[versionList.length-1] : {};

            }
        }
        return (
            <Container fluid={true}>
                <Row className="mt-3">
                    <Col xs={1} >

                        <div className={'filter-select-container'}>
                            <label className={'maintenance-data__label'}>Version </label>
                            <div className={'filter-select-wrapper'}>
                                <ReactSelect
                                    values={[selectedOption]}
                                    placeholder="Version"
                                    options={versionList}
                                    onChange={this.handleDataTypeChange}
                                    isMulti={false} >
                                </ReactSelect>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4} >
                        <fieldset className={'border filtercss'}>
                            <legend className={'maintenance-data__filter-legend'} >Inspection Defect</legend>
                            <div className={'filter-wrapper'}>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        id="d3"
                                        name={'rad' + this.props.inspectionTicketNumber}
                                        checked={this.state.IsAllDefectSelteced}
                                        onChange={this.handleAllDefected}
                                    />
                                    <label htmlFor="d3"> All</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        id="d1"
                                        name={'rad' + this.props.inspectionTicketNumber}
                                        checked={this.state.IsSelteced}
                                        onChange={this.handleDefected}
                                    />
                                    <label htmlFor="d1"> Defect</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        id="d2"
                                        name={'rad' + this.props.inspectionTicketNumber}
                                        checked={this.state.IsNotSelteced}
                                        onChange={this.handleNotDefected}
                                    />
                                    <label htmlFor="d2"> No Defect</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        type="radio"
                                        id="d3"
                                        name={'rad' + this.props.inspectionTicketNumber}
                                        checked={this.state.IsSelecteNA}
                                        onChange={this.handleNotApplicable}
                                    />
                                    <label htmlFor="d3"> Not Applicable</label>
                                </div>
                            </div>
                        </fieldset>
                    </Col>
                    <Col xs={1}>
                    </Col>
                    <Col xs={3} >
                        <fieldset className={'border filtercssShort'}>
                            <legend  className={'maintenance-data__filter-legend'} >Inspection Defect Type</legend>
                            <div style={{ display: 'flex' }}>
                                <div className={'filter-item'}>
                                    <input
                                        id="m3"
                                        type="radio"
                                        name={'radM' + this.props.inspectionTicketNumber}
                                        checked={this.state.isAllMajorMinor}
                                        onChange={this.handleAllMajorMinor}
                                    />
                                    <label htmlFor="m3"> All</label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        id="m2"
                                        type="radio"
                                        name={'radM' + this.props.inspectionTicketNumber}
                                        checked={this.state.isMinor}
                                        onChange={this.handleMinorChecked}
                                    />
                                    <label htmlFor="m2"> Minor </label>
                                </div>
                                <div className={'filter-item'}>
                                    <input
                                        id="m1"
                                        type="radio"
                                        name={'radM' + this.props.inspectionTicketNumber}
                                        checked={this.state.isMajor}
                                        onChange={this.handleMajorChecked}
                                    />
                                    <label htmlFor="m1"> Major </label>
                                </div>
                            </div>
                        </fieldset>
                    </Col>
                </Row>
            </Container>

        );
    }
}
