import { DispatchPlanDataStore } from './dispatchPlan-store';
import { dispatchPlanDataService } from '../services/dispatchPlan-data-service';
// import { mockTicketDataService } from '../services/mock-ticket-data-service';
import { uiService } from 'shared/services/ui-service';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';
import { CommunicationDataStore } from 'features/dispatch/communication/stores/communication-data-store';
import { communicationDataService } from 'features/dispatch/communication/services/communication-data-service';
export const dispatchPlanDataStore = new DispatchPlanDataStore(dispatchPlanDataService,agGridService, uiService, accountStore);
export const communicationDataStore = new CommunicationDataStore(communicationDataService, agGridService, uiService, accountStore);
