import { PrinterFieldName, PrinterHeaderName } from './printer-enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { AlphaNumericCellRenderer } from '../../components/cell-renderers/printer-alpha-numeric-cell-renderer';
import { CheckBoxCellRenderer } from '../../components/cell-renderers/printer-checkbox-cell-renderer';
import { dataStore } from 'features/common/stores';

const modifiedDate = {
    headerName: PrinterHeaderName.MODIFIEDDATE,
    field: PrinterFieldName.MODIFIEDDATE,
    colId: PrinterFieldName.MODIFIEDDATE,
    width: 105,
    valueGetter: params => {
        if (params.data[PrinterFieldName.MODIFIEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[PrinterFieldName.MODIFIEDDATE]);
        }
    }
};

const createdDate = {
    headerName: PrinterHeaderName.CREATEDDATE,
    field: PrinterFieldName.CREATEDDATE,
    colId: PrinterFieldName.CREATEDDATE,
    width: 105,
    valueGetter: params => {
        if (params.data[PrinterFieldName.CREATEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[PrinterFieldName.CREATEDDATE]);
        }
    },
    // valueFormatter: (params: ValueFormatterParams): string =>
    //     params.value ? dateUtils.getFormattedDateTime(params.value) : ''
};

export const PrinterColDef: ColDef[] = [
    { headerName: PrinterHeaderName.ID, field: PrinterFieldName.ID, hide: true },
    {
        headerName: PrinterHeaderName.PRINTERSERIALNUMBER,
        field: PrinterFieldName.PRINTERSERIALNUMBER,
        width: 110,
        cellRendererFramework: AlphaNumericCellRenderer,
        valueGetter: params => {
            if (params.data[PrinterFieldName.PRINTERSERIALNUMBER] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.PRINTERSERIALNUMBER];
            }
        }
    },
    {
        headerName: PrinterHeaderName.PRINTERNAME,
        field: PrinterFieldName.PRINTERNAME,
        width: 105,
        cellRendererFramework: AlphaNumericCellRenderer,
        valueGetter: params => {
            if (params.data[PrinterFieldName.PRINTERNAME] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.PRINTERNAME];
            }
        }
    },
    {
        headerName: PrinterHeaderName.CARRIERCOMPANY,
        field: PrinterFieldName.CARRIERCOMPANY,
        width: 125,
        valueGetter: params => {
            if (params.data[PrinterFieldName.CARRIERCOMPANY] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.CARRIERCOMPANY];
            }
        }
    },
    {
        headerName: PrinterHeaderName.ASSIGNEDTO,
        field: PrinterFieldName.ASSIGNEDTO,
        width: 100,
        cellRendererFramework: AlphaNumericCellRenderer,
        valueGetter: params => {
            if (params.data[PrinterFieldName.ASSIGNEDTO] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.ASSIGNEDTO];
            }
        }
    },
    {
        headerName: PrinterHeaderName.CREATEDBY,
        field: PrinterFieldName.CREATEDBY,
        width: 95,
        valueGetter: params => {
            if (params.data[PrinterFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.CREATEDBY];
            }
        }
    },
    createdDate,
    {
        headerName: PrinterHeaderName.MODIFIEDBY,
        field: PrinterFieldName.MODIFIEDBY,
        width: 95,
        valueGetter: params => {
            if (params.data[PrinterFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[PrinterFieldName.MODIFIEDBY];
            }
        }
    },
    modifiedDate,
    {
        headerName: PrinterHeaderName.DELETE,
        field: PrinterHeaderName.DELETE,
        width: 65,
        cellRendererFramework: CheckBoxCellRenderer
    }
];
