import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { KeyfobsResponse } from '../domains/keyfobs-model';
import { CarrierCompany } from '../domains/model';
import { ResponseDataType } from 'shared/types/shared';
import { OperatorResponse } from '../domains/location-management/model';

export class KeyfobsDataService {
    constructor(private restApi: RestApiService) { }

    async getKeyfobData(): Promise<KeyfobsResponse[]> {
        const { data } = await this.restApi.postWithToken<KeyfobsResponse[]>(Config.getKeyfobsData);
        return data['Data'];
    }

    async updateKeyfobs(reqbody): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.createupdateKeyfobs, reqbody, {});
        return data['Data'];
    }

    async getCarrierCompanyData(): Promise<ResponseDataType<CarrierCompany[]>> {
        const { data } = await this.restApi.postWithToken<CarrierCompany[]>(Config.getCarrierCompanyData);
        return data['Data'];
    }

    async getOperator( reqbody = {}): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getOperator, reqbody, {});
        return data['Data'];
    }
}

export const keyfobsDataService = new KeyfobsDataService(restApiService);
