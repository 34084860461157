import { RestApiService } from "shared/services/rest-api-service";
import { restApiService } from "shared/services";
import { InspectionHistoryViewModel, InspectionHistoryResponse, StatusType } from "../model/inspection-history-model";
import { Config } from "config";
import { ResponseDataType } from "shared/types/shared";
import { TKT_STATUS_TYPE } from "../maintenance_portal_constant";
import { DateRange } from '../../../shared/components/daypicketInput/react-daypickerInput'

export class InspectionTicketHistoryDataService {
    constructor(private restApi: RestApiService) { }




    async getInspectionViewModel(): Promise<InspectionHistoryViewModel> {

        const inspectionDataPromise = this.getInspectionHistoryData();
        const statusTypePromise = this.getStatusTypes();
        const [{ data: inspectionData }, { data: statusTypes }
        ] = await Promise.all([inspectionDataPromise, statusTypePromise]);
        return {
            inspectionData,
            statusTypes
        };
    }

    async getInspectionViewModelWithDateParams(reqbody: DateRange): Promise<InspectionHistoryViewModel> {

        const inspectionDataPromise = this.getInspectionTicketWithDateParams(reqbody);
        const statusTypePromise = this.getStatusTypes();
        const [{ data: inspectionData }, { data: statusTypes }
        ] = await Promise.all([inspectionDataPromise, statusTypePromise]);
        return {
            inspectionData,
            statusTypes
        };
    }

    private getStatusTypes(): Promise<ResponseDataType<StatusType[]>> {
        return this.restApi.postWithToken<StatusType[]>(Config.getBaseValueByClass, { CODE: TKT_STATUS_TYPE });
    }

    private getInspectionHistoryData(): Promise<ResponseDataType<InspectionHistoryResponse[]>> {
        return this.restApi.postWithToken<InspectionHistoryResponse[]>(Config.getInspectionHistoryInfoList);
    }

    async updateInspectionHistory(reqbody: InspectionHistoryResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateInspectionHistoryInfos, reqbody, {});
        return data['Data'];
    }

    async getInspectionTicketWithDateParams(reqbody: DateRange): Promise<ResponseDataType<InspectionHistoryResponse[]>> {
        return this.restApi.postWithToken<InspectionHistoryResponse[]>(Config.getInspectionHistoryInfoList, reqbody, {});
    }

}
export const inspectionTicketHistoryDataService = new InspectionTicketHistoryDataService(restApiService);
