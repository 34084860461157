import React, { Component } from 'react';
import { ShopDataStore } from '../stores/shop-data-store';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { shopStore } from '../stores';
import { dataStore } from '../../common/stores/index';
import { ShopSaveLabel } from '../domains/shop-enum';
import { RESET, ADD_NEW_ROW } from '../data_constant';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
import { agGridService } from 'features/common/services/ag-grid-service';

interface ShopMasterDataProps {
    shopDataStore: ShopDataStore;
}

@observer
export class ShopMasterDataComponent extends Component<ShopMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('ShopManagement');
    }

    componentDidMount() {
        this.props.shopDataStore.init();
    }

    componentWillUnmount() {
        this.props.shopDataStore.reset();
    }

    render(): React.ReactNode {
        const {
            shopDataStore: { isSaveDisabled, isResetEnabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {ShopSaveLabel.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isResetEnabled()}
                                            >
                                                {RESET}
                                            </CustomButton>
                                            <CustomButton type={CustomButtonType.Submit} onClick={this.addNewRow}>
                                                {ADD_NEW_ROW}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('ShopManagement_filter');
        this.props.shopDataStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        this.props.shopDataStore.updateShopData();
    };

    private handleReset = (): void => {
        this.props.shopDataStore.resetShopData();
    };
    
    handleRefresh = () => {
        const { shopDataStore } = this.props;
        if(Object.keys(shopDataStore.cellValueChangeMap).length === 0) {
            shopDataStore.init();
            shopDataStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    shopDataStore.init();
                    shopDataStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    private addNewRow = (): void => {
        this.props.shopDataStore.AddRow();
    };

    private getGridConfig(): GridOptions {
        const {
            shopDataStore: { shopInfo, agGridService, getColDef }
        } = this.props;

        const rowData = shopInfo;
        const columnDefs = getColDef();
        columnDefs.forEach(col => {
            if (col.editable) {
                col.cellClass = dataStore.checkOperationAccess('Save') ? 'cell-editable' : '';
            }
        });
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;

        const shopGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(shopGridParams);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            shopDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            shopStore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                shopDataStore: { cellValueChangeMap, updateRow }
            } = this.props;
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
            agGridService.refreshGrid()
        } catch (err) {
            console.log(err);
        }
    };
}
