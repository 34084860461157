import { observable, action, toJS, computed } from 'mobx';
import { Option, ConfigModel } from 'shared/types/shared';
import { TicketManagementConstant } from '../constants/ticket-management-constant';
import { TicketService } from '../model/ticket-data-service-model';
import { first } from 'lodash';
import { TicketModel } from '../model/ticket-response-model';
import { AppConstant } from 'app_constant';
import { ticketGridService } from '../services/ticket-grid-service';
import { UiService, uiService } from 'shared/services/ui-service';
import { TicketFieldName, TicketStatus, TicketVolOrHourly, Reason } from '../constants/ticket-enum';
import { ticketDataAdapterService } from '../services/ticket-data-adapter-service';
import { AccountStore } from 'features/account/stores/account-store';
import { TICKET_COL_DEF } from '../model/ticket-col-def';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { DateRange } from 'shared/components/daypicketInput/react-daypickerInput';
import moment from 'moment';
import { OperationsComponentName } from '../constants/constant';

const STATUS_KEY_NAME = 'name';

export class TicketStore {
    @observable ticketManagementOptions: Option[] = [];
    @observable ticketManagementSelectedOption: Option = AppConstant.DEFAULT_SELECT_OPTION;
    @observable ticketList: TicketModel[] = [];
    @observable selectedticketList!: TicketModel;
    selectedTicketNumber: any = {};
    ticketStatusList: ConfigModel[] = [];
    backUpTicketList: Map<string, TicketModel> = new Map<string, TicketModel>();
    approvedCommentTicketModel: TicketModel | undefined = undefined;
    @observable isApproverPopUpVisible = false;
    @observable filters: any = {};
    endDate: any;
    startDate: any;
    @observable isDownloadEnabled: boolean = false;
    isStatusReset = false;
    @observable EstVolumeSum: any= 0;
    @observable totalWaitingTime: any = 0;
    @observable totalWorkingTime: any = 0;
    dataToBeDeleted: any = [];
    @observable selectedticketsList: any = [];
    selectButtonText: string = 'Select All';
    checkBoxSelectionCount: any;

    selectTicketToBeDownloaded(ticket, value: any, colName: string): void {
        switch (colName) {
            case 'SELECT':
                ticket.SELECT = !value;
                break;
            default:
                break;
        }
        // if (!value) {
        //     // this.dataToBeDeleted.push({ ID: ticket.ID, Load: ticket.LoadNumber, Type: ticket.LoadType, DispatcherComments: ticket.DispatcherComments, LoadStatus: ticket.LoadStatus });
        //     this.dataToBeDeleted.push(ticket.id)
        // } else {
        //     this.dataToBeDeleted.map ((x,ind) => {
        //         if(x === ticket.id)
        //             this.dataToBeDeleted.splice(ind,1);
        //     })
        // }
        if (!value) {
            const prevResult = this.selectedticketsList.filter( a => a.id === ticket.id );
            if(prevResult.length > 0) {
                this.selectedticketsList = this.selectedticketsList.filter( a => a.id !== ticket.id );
            }
            this.selectedticketsList.push(ticket)
        } else {
            this.selectedticketsList = this.selectedticketsList.filter(a => a.id !== ticket.id);
        }
        console.log(this.selectedticketsList.length);
        // if(this.selectedticketsList.length <= 5){
        //     // this.selectedticketList = ticket
        //     // this.selectedTicketNumber = ticket.ticketNumber
        //     this.isDownloadEnabled = true;
        // } else {
        //     this.isDownloadEnabled = false;
        // }
        this.checkBoxSelectionCount = this.selectedticketsList.length;
    }
    
    @computed get isSaveEnabled() {
        const updatedRecords = ticketDataAdapterService.getUpdatedRecords(toJS(this.ticketList), this.backUpTicketList);
        return updatedRecords.length > 0;
    }

    constructor(
        private ticketDataService: TicketService,
        private accountStore: AccountStore,
        private uiService: UiService
    ) {}

    async init() {
        try {
            this.endDate = moment(new Date().toISOString()).format('YYYY-MM-DD');
            const startDate = moment().subtract(3, 'months').toDate()
            this.startDate = moment(startDate).format('YYYY-MM-DD');
            this.uiService.loaderService.showLoader();
            const options = TicketManagementConstant.SELECT_OPTION_VALUES;
            this.setTicketManagementOptions(options);
            const firstItem = first(options);
            const selectItem = firstItem ? firstItem : AppConstant.DEFAULT_SELECT_OPTION;
            this.setTicketManagementSelectedOption(selectItem);
            let { ticketList, ticketStatusList } = await this.ticketDataService.getTicketViewModel();
            if (ticketList.length > 0 ) {
                ticketList = ticketList.filter(
                    a => a.type === TicketVolOrHourly.HOURLY ? a.status === 'New' || a.status === 'Approved' || a.status === 'Hold' || a.status === 'Not Approved' || a.status === 'System Completed' || a.status === 'In Review' :
                    a.status === 'New' || a.status === 'Approved' || a.status === 'Hold' || a.status === 'Not Approved' || a.status === 'Completed' || a.status === 'System Completed'
                );
                ticketList.map(x => {
                        x.SELECT = false
                } )
            this.setTicketItems(ticketList);
            this.setBackUpTicketList(ticketList);
            this.getVolumeSum(ticketList);
            this.setTicketStatusList(ticketStatusList);
            ticketGridService.updateOptionCellEditorValues(
                ticketStatusList.filter(x => x.name === "New" || x.name === "In Review" || x.name === "Hold" || x.name === 'Not Approved' ),
                TicketFieldName.STATUS,
                STATUS_KEY_NAME
            );
            ticketGridService.updateOptionCellEditorValues(Reason, TicketFieldName.WAITINGTIMEREASON, 'Name');
            ticketGridService.updateOptionCellEditorValues(Reason, TicketFieldName.WORKINGTIMEREASON, 'Name');
        } else {
                this.ticketList = [];
                this.uiService.toastService.error(TicketManagementConstant.NO_TICKETS);
            }
            this.uiService.loaderService.hideLoader();

        } catch (err) {
            this.ticketList = [];
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(TicketManagementConstant.TICKET_FETCH_FAILED);
        }
    }

    setBackUpTicketList(ticketList: TicketModel[]) {
        this.backUpTicketList.clear();
        ticketList.forEach(ticket => {
            const ticketKey = ticketDataAdapterService.getTicketKey(ticket);
            this.backUpTicketList.set(ticketKey, ticket);
        });
    }

    getAllFilter() {
        this.filters = ticketGridService.getAllFilters();
    }

    setAllFilter() {
        ticketGridService.setAllFilters(this.filters);
    }

    @action
    showTicketApproverPopUp() {
        this.isApproverPopUpVisible = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.isApproverPopUpVisible = false;
    }

    @action
    setTicketManagementOptions(ticketManagementOptions: Option[]) {
        this.ticketManagementOptions = ticketManagementOptions;
    }

    @action
    setTicketManagementSelectedOption(option: Option) {
        this.ticketManagementSelectedOption = option;
    }

    @action
    setTicketItems(ticketList: TicketModel[]) {
        this.ticketList = ticketList;
        this.selectedTicketNumber = ticketList[0].ticketNumber
        this.selectedticketList = ticketList[0]
    }

    async saveTicket() {
        try {
            this.uiService.loaderService.showLoader();
            const filters = this.getAllFilter();
            const updatedRecords = ticketDataAdapterService.getUpdatedRecords(
                toJS(this.ticketList),
                this.backUpTicketList
            );
            let userID = this.accountStore.getUserID();
            if (!userID || userID === 0 ) {
                await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                    userID = this.accountStore.getUserID();
                });
                if (!userID || userID === 0 ) {
                    return;
                }
            }
            // const userId = this.accountStore.getUserID();
            const requestModelList = ticketDataAdapterService.getRequestModelList(
                updatedRecords,
                this.ticketStatusList,
                userID
            );
            await this.ticketDataService.saveTicket(requestModelList);
            this.uiService.toastService.success(TicketManagementConstant.TICKET_SAVED_SUCCESSFULLY);
            this.selectedticketsList = [];
            this.getRangeResult(this.startDate, this.endDate);
        } catch (err) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(TicketManagementConstant.TICKET_SAVE_FAILED);
        }
    }

    @action
    isApproveEnabled = () => {
        const csvData: any = ticketGridService.getData();
        let data: any = [];
        const records = csvData?.rowModel.rowsToDisplay ? csvData?.rowModel.rowsToDisplay : []
        records.forEach(x => {
                data.push(x.data);
            });
        let flag = 0;
        data = data.filter(x => x.SELECT);
        data.map(x => {
            if (x.status !== 'In Review'){
                flag = 1
            }
        })
        if(!flag && data.length > 0)
            return false;
        else return true;
    }

    async approveTickets(data) {
        try {
            uiService.loaderService.showLoader();
            const updatedRecords = data.filter(x => x.SELECT);
            console.log(toJS(updatedRecords))
            updatedRecords.map(x => {
                x.status = 'Approved'
            })
            console.log(toJS(updatedRecords))
            let userID = this.accountStore.getUserID();
            if (!userID || userID === 0 ) {
                await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                    userID = this.accountStore.getUserID();
                });
                if (!userID || userID === 0 ) {
                    return;
                }
            }
            const requestModelList = ticketDataAdapterService.getRequestModelList(
                updatedRecords,
                this.ticketStatusList,
                userID
            );
            console.log(toJS(requestModelList));
            await this.ticketDataService.saveTicket(requestModelList);
            uiService.toastService.success(TicketManagementConstant.TICKET_SAVED_SUCCESSFULLY);
            this.selectedticketsList = [];
            this.checkBoxSelectionCount = this.selectedticketsList.length;
            this.getRangeResult(this.startDate, this.endDate);
        } catch (err) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(TicketManagementConstant.TICKET_SAVE_FAILED);
        }
    }
    
    async reloadData() {
        const { ticketList } = await this.ticketDataService.getTicketViewModel();
        this.setTicketItems(ticketList);
        this.getVolumeSum(ticketList);
        this.setBackUpTicketList(ticketList);
        this.uiService.loaderService.hideLoader();
        this.setAllFilter();
    }

    resetTicket() {
        const backUpTickets = this.backUpTicketList.values();
        const list = [...backUpTickets];
        this.setTicketItems(list);
        this.getVolumeSum(list);
    }

    resetValue() {
        const item: any = this.approvedCommentTicketModel;
        const backUpTickets = this.backUpTicketList.values();
        const list = [...backUpTickets];
        this.isStatusReset = true;
        list.forEach(i => {
            if (i.id == item.id && i.ticketNumber == item.ticketNumber) {
                this.updateTicket(item, i.status, 'status');
            }
        });
    }

    @action
    updateTicket(item: TicketModel, value: string, key: string) {
        if (!ticketGridService.gridApi) {
            return;
        }
        const model = ticketGridService.gridApi.getFilterModel();
        this.ticketList.forEach(i => {
            if (i.id == item.id && i.ticketNumber == item.ticketNumber) {
                i[key] = value;
            }
        });
        if (!this.isStatusReset) this.triggerModal(item, value, key);
        // defer the execution to nexttick of event loop
        setTimeout(() => {
            if (!ticketGridService.gridApi) {
                return;
            }
            ticketGridService.gridApi.setFilterModel(model);
            ticketGridService.gridApi.onFilterChanged();
        }, 0);
        this.isStatusReset = false;
    }

    // Trigger modal when ticket status is not approved or hold and approver comment is
    triggerModal(item, value, key) {
        const statusValues = [TicketStatus.HOLD, TicketStatus.NOTAPPROVED];
        if (key == TicketFieldName.STATUS && statusValues.includes(value)) {
            this.approvedCommentTicketModel = item;
            this.showTicketApproverPopUp();
        }
    }

    updateApproverComment(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateTicket(this.approvedCommentTicketModel, approverComment, TicketFieldName.APPROVERCOMMENT);
    }

    setTicketStatusList(ticketStatusList: ConfigModel[]) {
        this.ticketStatusList = ticketStatusList;
    }

    @computed
    get ticketItems(): TicketModel[] {
        return toJS(this.ticketList);
    }

    getColumnDef() {
        this.updateCommentColDef();
        this.updateTimeColDef();
        this.updateApproverCommentColDef();
        return TICKET_COL_DEF;
    }

    updateCommentColDef() {
        const commentColDef = TICKET_COL_DEF.find(x => x.colId == TicketFieldName.COMMENT);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) => this.updateTicket(item, value, TicketFieldName.COMMENT),
                isDisabled: (item: TicketModel, value) =>
                    item.isApproved ||
                    item.type !== TicketVolOrHourly.HOURLY ||
                    !dataStore.checkOperationAccessWithModule(
                        RoleBasedActionName.Save,
                        OperationsComponentName.TicketApproval
                    )
            };
        }
    }

    updateTimeColDef() {
        const timeValues = [TicketFieldName.WORKINGTIME, TicketFieldName.WAITINGTIME];
        timeValues.forEach(timeValue => {
            const timeValueColDef = TICKET_COL_DEF.find(x => x.colId == timeValue);
            if (timeValueColDef) {
                timeValueColDef.cellRendererParams = {
                    decimalScale: 2,
                    isAllowed: values => {
                        const { floatValue, value } = values;
                        return value == '' || floatValue <= 9999;
                    },
                    onChange: (item, value) => this.updateTicket(item, value, timeValue),
                    isReadonly: (item: TicketModel, value) => {
                        return (
                            item.type == TicketVolOrHourly.VOLUME ||
                            item.isApproved ||
                            !dataStore.checkOperationAccessWithModule(
                                RoleBasedActionName.Save,
                                OperationsComponentName.TicketApproval
                            )
                        );
                    }
                };
            }
        });
    }

    updateApproverCommentColDef() {
        const approverCommentColDef = TICKET_COL_DEF.find(x => x.colId == TicketFieldName.APPROVERCOMMENT);
        if (approverCommentColDef) {
            approverCommentColDef.cellRendererParams = {
                onChange: (item, value) => this.updateTicket(item, value, TicketFieldName.APPROVERCOMMENT),
                isDisabled: (item: TicketModel, value) =>
                    item.isApproved ||
                    item.status === TicketStatus.CANCELED ||
                    !dataStore.checkOperationAccessWithModule(
                        RoleBasedActionName.Save,
                        OperationsComponentName.TicketApproval
                    )
            };
        }
    }

    async getRangeResult(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        this.isDownloadEnabled = false;
        try {
            this.uiService.loaderService.showLoader();
            let { ticketList, ticketStatusList } = await this.ticketDataService.getTicketViewModelWithDateParams(
                reqbody
            );
            if (ticketList.length === 0) {
                this.ticketList = [];
                this.uiService.toastService.error(TicketManagementConstant.NO_TICKETS);
            } else {
                ticketList = ticketList.filter(
                    a => a.type === TicketVolOrHourly.HOURLY ? a.status === 'New' || a.status === 'Approved' || a.status === 'Hold' || a.status === 'Not Approved' || a.status === 'System Completed' || a.status === 'In Review' :
                    a.status === 'New' || a.status === 'Approved' || a.status === 'Hold' || a.status === 'Not Approved' || a.status === 'Completed' || a.status === 'System Completed'
                );
                this.setTicketItems(ticketList);
                this.getVolumeSum(ticketList);
                this.setTicketStatusList(ticketStatusList);
                this.setBackUpTicketList(ticketList);
                ticketGridService.updateOptionCellEditorValues(
                    ticketStatusList.filter(x => x.name === "New" || x.name === "In Review" || x.name === "Hold" || x.name === 'Not Approved'),
                    TicketFieldName.STATUS,
                    STATUS_KEY_NAME
                );
            }
            this.checkBoxSelectionCount = 0;
            this.uiService.loaderService.hideLoader();
        } catch (err) {
            this.uiService.loaderService.hideLoader();
            this.ticketList = [];
            this.uiService.toastService.error(TicketManagementConstant.TICKET_FETCH_FAILED);
        }
    }

    isDisabled = (): boolean => {
        if (this.ticketList.length > 0) {
            return false;
        } else {
            return true;
        }
    };
    @action
    getButtonText = () => {
        const csvData: any = ticketGridService.getData();
        const data: any = [];
        const records = csvData?.rowModel.rowsToDisplay ? csvData?.rowModel.rowsToDisplay : []

        records.forEach(x => {
                data.push(x.data);
            });

            let count = 0;
            data.map(x => {
                if (x.SELECT){
                    count++
                }
            })
        if (count === data.length){
            this.selectButtonText = 'Deselect All'
        } else {
            this.selectButtonText = 'Select All'
        }

        return this.selectButtonText
    }

    getVolumeSum(data){
        let volumeCount = 0, waittime = 0, worktime =0;
        for (let report in data) {
            volumeCount +=  data[report].type === 'Volume' || (data[report].type == 'Hourly' && (data[report].relatedVolumeTicket == null ||data[report].relatedVolumeTicket == '')) ? data[report].volumeDelivered : 0;
            waittime += data[report].waitingTime === null ? data[report].waitingTime : parseInt(data[report].waitingTime);
            worktime += data[report].workingTime === null ? data[report].workingTime : parseInt(data[report].workingTime);
        }
        this.EstVolumeSum = volumeCount.toFixed(3);
        this.totalWaitingTime = waittime.toFixed(2);
        this.totalWorkingTime = worktime.toFixed(2);
    }


    @action
    handleSelectAllData = (data) => {
        let loadStatusArray: any = [];
        console.log(this.selectButtonText);
        if (this.selectButtonText === 'Select All') {
            
            this.dataToBeDeleted = [];
            console.log(this.selectedticketsList)
            data.map((item, index) => {
            //             item.SELECT = true;
            //             const prevResult = this.selectedticketsList.filter( a => a.id === item.id );
            // if(prevResult.length > 0) {
            //     this.selectedticketsList = this.selectedticketsList.filter( a => a.id !== item.id );
            // }
            // this.selectedticketsList.push(item)
            this.selectTicketToBeDownloaded(item, false, 'SELECT')
                        
            });
            // this.checkBoxSelectionCount = this.selectedticketsList.length;
            this.selectButtonText = 'Deselect All';
        } else {
            data.map((item, index) => {
                item.SELECT = false;
                this.selectTicketToBeDownloaded(item, true, 'SELECT')
            });
            // this.selectedticketsList = [];
            this.selectButtonText = 'Select All';
        }
    };
}
