import { RestApi, ConfigResponseModel, ResponseDataType } from 'shared/types/shared';
import { restApiService } from 'shared/services';
import { Config } from 'config';
import { RegionMappingType } from 'features/mapping/model/model';

export class CapacityPlanDataService {
    constructor(private apiService: RestApi) {}

    async assignLeave(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.createUpdateCapacityPlan, reqbody);
        return data['Data'];
    }

    async getCapacityPlanData(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.getCapacityPlanningExternal, reqbody);
        return data['Data'];
    }

    public getCompanyRegionMapping(companyRequestdata): Promise<ResponseDataType<RegionMappingType[]>> {
        return this.apiService.postWithToken<RegionMappingType[]>(Config.getCompanyRegionMapping, companyRequestdata);
    }
}

export const capacityPlanDataService = new CapacityPlanDataService(restApiService);