import { MasterTicketReportHeaderName, MasterTicketReportFieldName } from './enum';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { MasterReportCellRenderer } from '../components/ticket-print-selection';
import { commaFormat } from '../reports_constants';
import { TicketStatus } from 'features/operations-portal/constants/ticket-enum';

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

const getLastPmtValue = value => {
    if (value === TicketStatus.CANCELED) {
        return 'Canceled by Driver';
    }
    return value;
};

export const MasterTicketReport: ColDef[] = [
    {
        headerName: MasterTicketReportHeaderName.SELECT,
        field: 'SELECTED',
        colId: 'SELECTED',
        width: 75,
        cellRendererFramework: MasterReportCellRenderer
    },
    {
        headerName: MasterTicketReportHeaderName.TICKETNUMBER,
        field: MasterTicketReportFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.STATUS,
        field: MasterTicketReportFieldName.STATUS,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.STATUS] === null) {
                return '';
            } else if (params.data[MasterTicketReportFieldName.STATUS] === 'Assigned'){
                return 'Dispatched';
            } else if (params.data[MasterTicketReportFieldName.STATUS] === 'Canceled') {
                return 'Cancelled'
            } else {
                return params.data[MasterTicketReportFieldName.STATUS];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TICKETDATE,
        field: MasterTicketReportFieldName.TICKETDATE,
        width: 170,
        valueGetter: (value): string => (value.data[MasterTicketReportFieldName.TICKETDATE] ?
            momenttimezone
                .tz(moment.utc(value.data[MasterTicketReportFieldName.TICKETDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT) : '')
    },
    {
        headerName: MasterTicketReportHeaderName.TICKETACCEPTEDDATE,
        field: MasterTicketReportFieldName.TICKETACCEPTEDDATE,
        width: 130,
        valueGetter: (value): string => (value.data[MasterTicketReportFieldName.TICKETACCEPTEDDATE] ? 
            momenttimezone
                .tz(moment.utc(value.data[MasterTicketReportFieldName.TICKETACCEPTEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT) : "")
    },
    {
        headerName: MasterTicketReportHeaderName.LOADDATETIME,
        field: MasterTicketReportFieldName.LOADDATETIME,
        width: 110,
        valueGetter: params =>{
            if ((params.data[MasterTicketReportFieldName.LOADDATETIME] === null) && (params.data[MasterTicketReportFieldName.STARTDATE] === null)) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TYPE] === 'Hourly' ? dateUtils.getFormattedDateTime(params.data[MasterTicketReportFieldName.STARTDATE]) : dateUtils.getFormattedDateTime(params.data[MasterTicketReportFieldName.LOADDATETIME]);
            }
        }
    },
    // {
    //     headerName: MasterTicketReportHeaderName.PLANNEDDATEANDTIME,
    //     field: MasterTicketReportFieldName.PLANNEDDATEANDTIME,
    //     colId: MasterTicketReportFieldName.PLANNEDDATEANDTIME,
    //     valueGetter: params => {
    //         if (params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME] === null) {
    //             return '';
    //         } else {
    //             return dateUtils.getFormattedDateTime(params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME])//moment(new Date(params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME])).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     }
    // },
    {
        headerName: MasterTicketReportHeaderName.ENDDATE,
        field: MasterTicketReportFieldName.ENDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.ENDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[MasterTicketReportFieldName.ENDDATE])
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.COMPLETEDDATE,
        field: MasterTicketReportFieldName.COMPLETEDDATE,
        width: 105,
        // In response Completed Date is coming in UTC Format so converting the value in MM/DD/YYYY HH:mm format
        valueGetter: params => (params.data[MasterTicketReportFieldName.COMPLETEDDATE] ? dateUtils.getFormattedDateTimeLocal(params.data[MasterTicketReportFieldName.COMPLETEDDATE]) : '') 
    },
    {
        headerName: MasterTicketReportHeaderName.ORIGIN,
        field: MasterTicketReportFieldName.ORIGIN,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.ORIGIN];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DESTINATION,
        field: MasterTicketReportFieldName.DESTINATION,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DESTINATION];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.LEASEOPERATOR,
        field: MasterTicketReportFieldName.LEASEOPERATOR,
        width: 90,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LEASEOPERATOR] === null || params.data[MasterTicketReportFieldName.LEASEOPERATOR] === 'Dummy Test') {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.LEASEOPERATOR];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DRIVERNAME,
        field: MasterTicketReportFieldName.DRIVERNAME,
        width: 75,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DRIVERNAME] === null || params.data[MasterTicketReportFieldName.DRIVERNAME] === 'Dummy Test' || params.data[MasterTicketReportFieldName.DRIVERNAME].trim() === 'SysUser') {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.REGION,
        field: MasterTicketReportFieldName.REGION,
        width: 75,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.REGION];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRAILERNUMBER,
        field: MasterTicketReportFieldName.TRAILERNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.PRODUCT,
        field: MasterTicketReportFieldName.PRODUCT,
        width: 90,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.ESTIMATEVOL,
        field: MasterTicketReportFieldName.ESTIMATEVOL,
        width: 95,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.ESTIMATEVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[MasterTicketReportFieldName.ESTIMATEVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.ACTUALVOL,
        field: MasterTicketReportFieldName.ACTUALVOL,
        width: 80,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.ACTUALVOL] === null) {
                return '';
            } else {
                return roundOff(params.data[MasterTicketReportFieldName.ACTUALVOL]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TYPE,
        field: MasterTicketReportFieldName.TYPE,
        width: 70,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TYPE] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TYPE];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.WORKINGTIME,
        field: MasterTicketReportFieldName.WORKINGTIME,
        colId: MasterTicketReportFieldName.WORKINGTIME,
        width: 90,
        cellClass: 'comma-format',
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.WORKINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.WORKINGTIMEREASON,
        field: MasterTicketReportFieldName.WORKINGTIMEREASON,
        colId: MasterTicketReportFieldName.WORKINGTIMEREASON,
        width: 120,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.WAITINGTIME,
        field: MasterTicketReportFieldName.WAITINGTIME,
        colId: MasterTicketReportFieldName.WAITINGTIME,
        width: 80,
        cellClass: 'comma-format',
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.WAITINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.WAITINGTIMEREASON,
        field: MasterTicketReportFieldName.WAITINGTIMEREASON,
        colId: MasterTicketReportFieldName.WAITINGTIMEREASON,
        width: 110,
        cellEditor: 'agSelectCellEditor',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.COMMENT,
        field: MasterTicketReportFieldName.COMMENT,
        colId: MasterTicketReportFieldName.COMMENT,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.COMMENT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.SHIPPERTICKET,
        field: MasterTicketReportFieldName.SHIPPERTICKET,
        width: 110,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.SHIPPERTICKET] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.SHIPPERTICKET];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.RELATEDVOLTICKET,
        field: MasterTicketReportFieldName.RELATEDVOLUMETICKET,
        width: 125,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.RELATEDVOLUMETICKET] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.RELATEDVOLUMETICKET];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.ROADBAN,
        field: MasterTicketReportFieldName.ROADBAN,
        width: 65,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.ROADBAN] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.ROADBAN];
            }
        }
    },

    {
        headerName: MasterTicketReportHeaderName.VIA,
        field: MasterTicketReportFieldName.VIA,
        width: 65,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.VIA] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.VIA];
            }
        }
    },
    {
        field: MasterTicketReportFieldName.APPROVERCOMMENT,
        headerName: MasterTicketReportHeaderName.APPROVERCOMMENT,
        colId: MasterTicketReportFieldName.APPROVERCOMMENT,
        width: 95,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.APPROVERCOMMENT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.BSW,
        field: MasterTicketReportFieldName.BSW,
        width: 60,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.BSW] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.BSW];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DENSITY,
        field: MasterTicketReportFieldName.DENSITY,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DENSITY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DENSITY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.GROSSWEIGHT,
        field: MasterTicketReportFieldName.GROSSWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.GROSSWEIGHT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.GROSSWEIGHT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TAREWEIGHT,
        field: MasterTicketReportFieldName.TAREWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TAREWEIGHT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TAREWEIGHT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.NETWEIGHT,
        field: MasterTicketReportFieldName.NETWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.NETWEIGHT] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.NETWEIGHT];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.METERFACTOR,
        field: MasterTicketReportFieldName.METERFACTOR,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.METERFACTOR] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.METERFACTOR];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.METERNUMBER,
        field: MasterTicketReportFieldName.METERNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.METERNUMBER] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.METERNUMBER];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.OPENMETERREADING,
        field: MasterTicketReportFieldName.OPENMETERREADING,
        width: 110,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.OPENMETERREADING] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.OPENMETERREADING];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.CLOSEMETERREADING,
        field: MasterTicketReportFieldName.CLOSEMETERREADING,
        width: 115,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CLOSEMETERREADING] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.CLOSEMETERREADING];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.POWERUNITID,
        field: MasterTicketReportFieldName.POWERUNITID,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.POWERUNITID] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.POWERUNITID];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.AXLES,
        field: MasterTicketReportFieldName.AXLES,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.AXLES] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.AXLES];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.LASTPMTSTATUS,
        field: MasterTicketReportFieldName.LASTPMTSTATUS,
        width: 90,
        valueFormatter: (params: ValueFormatterParams): string => (params.value ? getLastPmtValue(params.value) : ''),
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LASTPMTSTATUS] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.LASTPMTSTATUS];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRAILERSTATUS,
        field: MasterTicketReportFieldName.TRAILERSTATUS,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TRAILERSTATUS] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TRAILERSTATUS];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRACTORUSED,
        field: MasterTicketReportFieldName.POWERUNITIDENTIFIER,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.POWERUNITIDENTIFIER] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.POWERUNITIDENTIFIER];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRAILERMAPPEDTOCOMPANY,
        field: MasterTicketReportFieldName.TRAILERMAPPEDTOCOMPANY,
        width: 145,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TRAILERMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TRAILERMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.MISMATCH,
        width: 140,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LEASEOPERATOR] !== params.data[MasterTicketReportFieldName.TRAILERMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRACTORMAPPEDTOCOMPANY,
        field: MasterTicketReportFieldName.TRACTORMAPPEDTOCOMPANY,
        width: 140,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TRACTORMAPPEDTOCOMPANY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.TRACTORMAPPEDTOCOMPANY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TRACTORMISMATCH,
        width: 145,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LEASEOPERATOR] !== params.data[MasterTicketReportFieldName.TRACTORMAPPEDTOCOMPANY]) {
                return 'No';
            } else {
                return 'Yes';
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.TICKETSOURCE,
        field: MasterTicketReportFieldName.TICKETSOURCE,
        width: 130,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.TICKETSOURCE] === 'PDS') {
                return 'PDS';
            } else {
                return 'PMT';
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.CREATEDBY,
        field: MasterTicketReportFieldName.CREATEDBY,
        colId: MasterTicketReportFieldName.CREATEDBY,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.CREATEDBY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.CREATEDDATE,
        field: MasterTicketReportFieldName.CREATEDDATE,
        colId: MasterTicketReportFieldName.CREATEDDATE,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CREATEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[MasterTicketReportFieldName.CREATEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.MODIFIEDBY,
        field: MasterTicketReportFieldName.MODIFIEDBY,
        colId: MasterTicketReportFieldName.MODIFIEDBY,
        width: 90,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.MODIFIEDDATE,
        field: MasterTicketReportFieldName.MODIFIEDDATE,
        colId: MasterTicketReportFieldName.MODIFIEDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[MasterTicketReportFieldName.MODIFIEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    }
];

export const DispatchDetails: ColDef[] = [
    {
        headerName: MasterTicketReportHeaderName.TICKETNUMBER,
        field: MasterTicketReportFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LOADNUMBER] === null) {
                return '';
            } else if(params.data[MasterTicketReportFieldName.MANUALTICKETNUMBER] === null) {
                return params.data[MasterTicketReportFieldName.LOADNUMBER];
            } else {
                return params.data[MasterTicketReportFieldName.MANUALTICKETNUMBER];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.CUSTOMERNAME,
        field: MasterTicketReportFieldName.CUSTOMERNAME,
        colId: MasterTicketReportFieldName.CUSTOMERNAME,
        width: 135,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CUSTOMERNAME] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.CUSTOMERNAME];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DATASOURCE,
        field: MasterTicketReportFieldName.DATASOURCE,
        width: 80,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DATASOURCE] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DATASOURCE];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.LOADTYPE,
        field: MasterTicketReportFieldName.LOADTYPE,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.LOADTYPE] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.LOADTYPE];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.PENDING,
        field: MasterTicketReportFieldName.PENDING,
        width: 85,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.PENDING] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[MasterTicketReportFieldName.PENDING]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DISPATCHED,
        field: MasterTicketReportFieldName.DISPATCHED,
        width: 105,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DISPATCHED] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[MasterTicketReportFieldName.DISPATCHED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.COMPLETED,
        field: MasterTicketReportFieldName.COMPLETED,
        width: 105,
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.COMPLETED] === null) {
                return '0.000';
            } else {
                return roundOff(params.data[MasterTicketReportFieldName.COMPLETED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: MasterTicketReportHeaderName.NOMINATIONMONTH,
        field: MasterTicketReportFieldName.NOMINATIONMONTH,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.NOMINATIONMONTH] === null || params.data[MasterTicketReportFieldName.NOMINATIONMONTH] === 0) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.NOMINATIONMONTH];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.NOMINATIONYEAR,
        field: MasterTicketReportFieldName.NOMINATIONYEAR,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.NOMINATIONYEAR] === null || params.data[MasterTicketReportFieldName.NOMINATIONYEAR] === 0) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.NOMINATIONYEAR];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.PRIORITY,
        field: MasterTicketReportFieldName.PRIORITY,
        colId: MasterTicketReportFieldName.PRIORITY,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.PRIORITY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.PRIORITY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DISPATCHERCOMMENTS,
        field: MasterTicketReportFieldName.DISPATCHERCOMMENTS,
        colId: MasterTicketReportFieldName.DISPATCHERCOMMENTS,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DISPATCHERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DISPATCHERCOMMENTS];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DISPATCHERNOTES,
        field: MasterTicketReportFieldName.DISPATCHERNOTES,
        colId: MasterTicketReportFieldName.DISPATCHERNOTES,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DISPATCHERNOTES] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DISPATCHERNOTES];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.PLANNEDDATEANDTIME,
        field: MasterTicketReportFieldName.PLANNEDDATEANDTIME,
        colId: MasterTicketReportFieldName.PLANNEDDATEANDTIME,
        width: 105,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME])//moment(new Date(params.data[MasterTicketReportFieldName.PLANNEDDATEANDTIME])).format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.DRIVERCOMMENTS,
        field: MasterTicketReportFieldName.DRIVERCOMMENTS,
        colId: MasterTicketReportFieldName.DRIVERCOMMENTS,
        width: 100,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.DRIVERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.DRIVERCOMMENTS];
            }
        }
    },

    {
        headerName: MasterTicketReportHeaderName.CREATEDBY,
        field: MasterTicketReportFieldName.CREATEDBY,
        colId: MasterTicketReportFieldName.CREATEDBY,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.CREATEDBY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.CREATEDDATE,
        field: MasterTicketReportFieldName.CREATEDDATE,
        colId: MasterTicketReportFieldName.CREATEDDATE,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.CREATEDDATE] === null || params.data[MasterTicketReportFieldName.CREATEDDATE] == '0001-01-01T00:00:00') {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[MasterTicketReportFieldName.CREATEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.MODIFIEDBY,
        field: MasterTicketReportFieldName.MODIFIEDBY,
        colId: MasterTicketReportFieldName.MODIFIEDBY,
        width: 90,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[MasterTicketReportFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: MasterTicketReportHeaderName.MODIFIEDDATE,
        field: MasterTicketReportFieldName.MODIFIEDDATE,
        colId: MasterTicketReportFieldName.MODIFIEDDATE,
        width: 85,
        valueGetter: params => {
            if (params.data[MasterTicketReportFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[MasterTicketReportFieldName.MODIFIEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    }
];