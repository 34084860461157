import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { DailyReportResponse, DateRange } from '../domains/model';
import { DailyReportDataService } from '../services/dailyreport-data-service';
import { uiService } from 'shared/services/ui-service';
import { dailyReport } from '../domains/daily-report-col-def';
import { ReportMessage } from '../domains/enum';
import { accountStore } from 'features/account/stores';
import { CCUserRoles } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';
import { restApiService } from 'shared/services';
import { Config } from '../../../config';

export class UpcomingCertificationDataStore {
    @observable upcomingCertificatesReport : any = [];
    userID = 0;
    userName = '';
    userInfo: any;
    restApi: any;
    month: any;
    year: any;
    isRefreshed: boolean = false;
    constructor(
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getUpcomingCertification(new Date().getFullYear(), new Date().getMonth());
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.UPCOMINGCERT_ERROR_MESSAGE))
    async getUpcomingCertification(selectedYear, selectedMonth) {
        const reqbody: any = {Month: selectedMonth, Year: selectedYear};
        this.month = selectedMonth;
        this.year = selectedYear;
        const { data } = await restApiService.postWithToken<any>(Config.getUpcomingCertifications, reqbody, {});
        const upcomingCertificatesReports =  data['Data'];
        this.upcomingCertificatesReport = upcomingCertificatesReports;
        if (this.upcomingCertificatesReport?.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.isRefreshed = false;
    }

    isDisabled = (): boolean => {
        if (this.upcomingCertificatesReport?.length == 0) {
            return true;
        } else {
            return false;
        }
    };
}
