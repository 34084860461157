import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { HazardAssessmentHistoryHeaderName, HazardAssessmentHistoryFieldName } from '../model/hazardAssessment-history-enum';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from 'features/common/stores';
import { HazardAssessmentDetailCellRenderer } from '../components/hazardAssessment-detail-cell-render';
import { hazardAssessmentHistoryStore } from '../stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { CurrentStatusCellRenderer } from '../components/current-status-cell-renderer';
import { HazardAssessmentHistoryResponse } from '../model/hazardAssessment-history-model';
export const hazardAssessmentHistoryColDef: ColDef[] = [
    {
        headerName: HazardAssessmentHistoryHeaderName.HAZARDID,
        field: HazardAssessmentHistoryFieldName.HAZARDID,
        width: 110,
        cellRendererFramework: HazardAssessmentDetailCellRenderer,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.HAZARDID] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.HAZARDID];
            }
        },
        
        
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.TICKETNUMBER,
        field: HazardAssessmentHistoryFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.CONSIGNORLOCATION,
        field: HazardAssessmentHistoryFieldName.CONSIGNORLOCATION,
        width: 150,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.CONSIGNORLOCATION] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.CONSIGNORLOCATION];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.CONSIGNEELOCATION,
        field: HazardAssessmentHistoryFieldName.CONSIGNEELOCATION,
        width: 150,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.CONSIGNEELOCATION] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.CONSIGNEELOCATION];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.FIRSTNAME,
        field: HazardAssessmentHistoryFieldName.FIRSTNAME,
        colId: HazardAssessmentHistoryFieldName.FIRSTNAME,
        width: 95,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.FIRSTNAME] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.FIRSTNAME];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.LASTNAME,
        field: HazardAssessmentHistoryFieldName.LASTNAME,
        colId: HazardAssessmentHistoryFieldName.LASTNAME,
        width: 90,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.LASTNAME] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.LASTNAME];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.OPERATIONSREVIEW,
        field: HazardAssessmentHistoryFieldName.OPERATIONSREVIEW,
        colId: HazardAssessmentHistoryFieldName.OPERATIONSREVIEW,
        width: 90,
        cellEditor: 'agSelectCellEditor',
        editable: params =>{
        const { data } = params;
        return (!(data.Resolved === true) &&
                dataStore.checkOperationAccess('HazardAssessmentOperationReviewEdit'))
            },
        cellClass: params => {
                const { data } = params;
                return !data.Resolved &&
                    dataStore.checkOperationAccess('HazardAssessmentOperationReviewEdit')
                    ? 'cell-editable'
                    : '';
    },
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEW] === null) {
                return 'New';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEW];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.OPERATIONSREVIEWCOMMENTS,
        field: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS,
        width: 135,
        colId: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS,
        cellRendererFramework: TextAreaCellRenderer ,
        cellClass: params => {
            const { data } = params;
            return data.Resolved !== true &&
                dataStore.checkOperationAccess('HazardAssessmentOperationReviewEdit')
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        cellRendererParams: {
            isDisabled: (item: HazardAssessmentHistoryResponse, value) => 
            (!item.Resolved &&
                dataStore.checkOperationAccess('HazardAssessmentOperationReviewEdit')
             ? false : true),
            onChange: (item, value) =>
                hazardAssessmentHistoryStore.updateHazardAssessmentHistoryComment(
                    item,
                    value,
                    HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.OPERATIONSREVIEWDATE,
        field: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWDATE,
        colId: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWDATE,
        width: 115,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWDATE]);
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.OPERATIONSREVIEWBY,
        field: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWBY,
        colId: HazardAssessmentHistoryFieldName.OPERATIONSREVIEWBY,
        width: 105,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWBY] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.OPERATIONSREVIEWBY];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.COMPLIANCEREVIEW,
        field: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW,
        colId: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW,
        width: 100,
        cellEditor: 'agSelectCellEditor',
        editable: params =>{
               return (!params.data.Resolved &&
                dataStore.checkOperationAccess('HazardAssessmentComplianceReviewEdit'))},
        cellClass: params => {
                const { data } = params;
                return !data.Resolved &&
                    dataStore.checkOperationAccess('HazardAssessmentComplianceReviewEdit')
                    ? 'cell-editable'
                    : '';
        },
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW] === null) {
                return 'New';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.COMPLIANCEREVIEWCOMMENTS,
        field: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS,
        width: 135,
        colId: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS,
        cellRendererFramework: TextAreaCellRenderer,
        cellClass: params => {
            const { data } = params;
            return data.Resolved !== true &&
                dataStore.checkOperationAccess('HazardAssessmentComplianceReviewEdit')
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        cellRendererParams: {
            isDisabled: (item: HazardAssessmentHistoryResponse, value) => (
                !item.Resolved &&
                dataStore.checkOperationAccess('HazardAssessmentComplianceReviewEdit') 
                ? false : true
                ),
            
            onChange: (item, value) =>
                hazardAssessmentHistoryStore.updateHazardAssessmentHistoryComment(
                    item,
                    value,
                    HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.COMPLIANCEREVIEWDATE,
        field: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWDATE,
        colId: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWDATE,
        width: 115,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWDATE]);
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.COMPLIANCEREVIEWBY,
        field: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWBY,
        colId: HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWBY,
        width: 105,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWBY] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWBY];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.RESOLVED,
        field: HazardAssessmentHistoryFieldName.RESOLVED,
        width: 85,
        cellRendererFramework: CurrentStatusCellRenderer,
        colId: HazardAssessmentHistoryFieldName.RESOLVED, 
        cellClass: (params) => {
            return (!params.data.Resolved &&
        (dataStore.checkOperationAccess('HazardAssessmentResolvedEdit')) ? 'cell-editable' : '')}
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.RESOLVEDCOMMENTS,
        field: HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS,
        width: 110,
        colId: HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS,
        // cellRendererFramework: TextAreaCellRenderer,
        // cellRendererParams: {
        //     isDisabled: (item, value) => (dataStore.checkOperationAccess('HazardAssessmentResolvedEdit') ? false : true),
        //     onChange: (item, value) =>
        //         hazardAssessmentHistoryStore.updateHazardAssessmentHistoryComment(
        //             item,
        //             value,
        //             HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS
        //         )
        // },
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.RESOLVEDDATE,
        field: HazardAssessmentHistoryFieldName.RESOLVEDDATE,
        colId: HazardAssessmentHistoryFieldName.RESOLVEDDATE,
        width: 110,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.RESOLVEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[HazardAssessmentHistoryFieldName.RESOLVEDDATE]);
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.RESOLVEDBY,
        field: HazardAssessmentHistoryFieldName.RESOLVEDBY,
        colId: HazardAssessmentHistoryFieldName.RESOLVEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.RESOLVEDBY] === null) {
                return '';
            } else {
                return params.data[HazardAssessmentHistoryFieldName.RESOLVEDBY];
            }
        }
    },
    {
        headerName: HazardAssessmentHistoryHeaderName.HAZARDASSESSMENTDATE,
        field: HazardAssessmentHistoryFieldName.HAZARDASSESSMENTDATE,
        colId: HazardAssessmentHistoryFieldName.HAZARDASSESSMENTDATE,
        width: 110,
        valueGetter: params => {
            if (params.data[HazardAssessmentHistoryFieldName.HAZARDASSESSMENTDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[HazardAssessmentHistoryFieldName.HAZARDASSESSMENTDATE]);
            }
        }
    },
    // {
    //     headerName: HazardAssessmentHistoryHeaderName.MODIFIEDDATE,
    //     field: HazardAssessmentHistoryFieldName.MODIFIEDDATE,
    //     colId: HazardAssessmentHistoryFieldName.MODIFIEDDATE,
    //     valueGetter: params => {
    //         if (params.data[HazardAssessmentHistoryFieldName.MODIFIEDDATE] === null) {
    //             return '';
    //         } else {
    //             return dateUtils.getFormattedDateTime(params.data[HazardAssessmentHistoryFieldName.MODIFIEDDATE]);
    //         }
    //     }
    // },
    // {
    //     headerName: HazardAssessmentHistoryHeaderName.MODIFIEDBYUSER,
    //     field: HazardAssessmentHistoryFieldName.MODIFIEDBYUSER,
    //     colId: HazardAssessmentHistoryFieldName.MODIFIEDBYUSER,
    //     valueGetter: params => {
    //         if (params.data[HazardAssessmentHistoryFieldName.MODIFIEDBYUSER] === null) {
    //             return '';
    //         } else {
    //             return params.data[HazardAssessmentHistoryFieldName.MODIFIEDBYUSER];
    //         }
    //     }
    // }
];
