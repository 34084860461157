import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import moment from 'moment';
import { accountStore } from 'features/account/stores';
import { LocationDataStore } from 'features/data_management/stores/location-data-store';
import { LocationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { inject } from 'mobx-react';
import { locationStore } from 'features/data_management/stores';
import { truncateSync } from 'fs';
import { CommonUtils } from 'shared/services/common-utils';
import { uiService } from 'shared/services/ui-service';
import { nominationStore } from 'features/dispatch/nomination/stores/nominatation-data-store';
import { isEmpty } from 'lodash';
import { nominationDataService } from 'features/dispatch/nomination/services/nomination-data-service';
import { ImportErrorCodes } from 'features/dispatch/nomination/nomination-constants';
/* eslint-disable */
export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: () => void;
    originData;
    destinationData;
    dataSourceData;
    shipperNameData;
    customerName?;
    productsData;
    selectedMonth;
    selectedYear;
    raConfig;
    // region;
    // locationDataSerivice: LocationDataService;
}
const customSelectStyles: any = { menuList:()  => ({
    maxHeight: 200,
    overflowY: 'scroll'
})};

export class ManualNominationFilterPopUp extends React.Component<ConfirmProps> {
    state = {
        consignorPlaceholder: "Select Location",
        consigneePlaceholder: "Select Location",
        product: "Select Product",
        newCustomer: '',
        dataSourcePlaceholder: "Select Data Source",
        shipperNamePlaceholder: "Select Shipper Name",
        contactNumber: null as any,
        consignorField: '',
        consignorLSD: '',
        consignor: '',
        consigneeField: '',
        consigneeLSD: '',
        consignee: '',
        plainsRatio: '0',
        productsData: [],
        showPopup: false,
        showLoader: false
    };
    selectedConsignor: any;
    selectedConsignee: any;
    selectedDataSource: any;
    selectedShipperName: any;
    selectedProduct: any;
    otherConsignor: any;
    otherConsignee: any;
    locationErrors: any = [];
    // selectedRegion: any;
    nominationVolume: any = '';

    public render(): React.ReactNode {
        const { onClose, customerName, originData, dataSourceData, shipperNameData, destinationData, productsData, raConfig} = this.props;
        const dataSources = Array.from(new Set(dataSourceData.map((item: any) => item.Label)));
        const configMonth = raConfig.filter((item: any) => item.Code === 'RAIntegrationMonth');
        const configYear = raConfig.filter((item: any) => item.Code === 'RAIntegrationYear');
        let operator: any = [];
        if((this.props.selectedMonth + 1 < parseInt(configMonth[0].Value) && this.props.selectedYear === parseInt(configYear[0].Value)) || 
        this.props.selectedYear < parseInt(configYear[0].Value)){
            operator = Array.from(new Set(originData.map((item: any) => item.AX_Location))).filter((item: any) => item !== null );
        } else {
            operator = Array.from(new Set(originData.map((item: any) => item.RA_Location))).filter((item: any) => item !== null );
        }
        
        operator.push('New Location');
        var volumeInput = document.getElementById("nominationVolume")
        volumeInput?.addEventListener("keydown", (e) => {
            if(['e','+','-'].includes(e.key)){
                e.preventDefault()
            }
        })
        // if (operator[1] === null) operator.splice(operator[1], 1);
        const shipperName = Array.from(new Set(shipperNameData.map((item: any) => item.Label)));
        return (
            <>
            <div style = {{ overflowY: 'scroll', overflowX: 'hidden' }}>
                <div className="nomination" style={{ minHeight: '460px'}}>
                    {customerName &&
                    <Row>
                        <Col>
                            <div style={{ minHeight: '30px'}}>
                                Customer Name:{' '} <b>{this.props.customerName}</b>
                            </div>
                        </Col>
                    </Row>      
                    }
                    {!customerName &&
                    <Row>
                        <Col>
                            <div >
                                Enter Customer Name <b style = {{color: 'red'}}>*</b>
                                    <div className="location__consignee">
                                        <input
                                            className={`form-control`}
                                            placeholder="Enter Customer Name"
                                            onChange={this.handleNewCustomer}
                                            value={this.state.newCustomer}
                                        />
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col>
                            <div>
                                Select Data Source <b style = {{color: 'red'}}>*</b>
                                <div className="location__consignee">
                                    <Select
                                        styles={customSelectStyles}
                                        value={this.selectedDataSource}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.dataSourcePlaceholder}
                                        options={dataSources ? dataSources : dataSourceData}
                                        onChange={this.handleDataSource}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div >
                                Enter Product <b style = {{color: 'red'}}>*</b>
                                    <div className="location__consignee">
                                        <Select
                                            styles={customSelectStyles}
                                            value={this.selectedProduct}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder={this.state.product}
                                            options={this.state.productsData ? this.state.productsData : productsData}
                                            onChange={this.handleChange}
                                            getOptionLabel={option => option}
                                            getOptionValue={option => option}
                                            isDisabled = {this.state.dataSourcePlaceholder === "Select Data Source"}
                                        />
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    {this.selectedDataSource === 'Crude' && (
                        <Row>
                        <Col>
                            <div >
                                Enter Plains % Ratio {this.state.dataSourcePlaceholder === 'Crude' && <b style = {{color: 'red'}}>*</b>}
                                    <div className="location__consignee">
                                        <input
                                            className={`form-control`}
                                            placeholder="Enter Plains % Ratio"
                                            onChange={this.handlePlainsRatioChange}
                                            value={this.state.plainsRatio}
                                        />
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    )
                    }
                    <Row>
                        <Col>
                            <div >
                                Enter Contract Number {this.state.dataSourcePlaceholder === 'Butane' && <b style = {{color: 'red'}}>*</b>}
                                    <div className="location__consignee">
                                        <input
                                            className={`form-control`}
                                            placeholder="Enter Contract Number"
                                            onChange={this.handleContactChange}
                                            value={this.state.contactNumber ? this.state.contactNumber : ''}
                                            disabled ={this.selectedDataSource === 'Crude'}
                                        />
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div >
                                Select Origin <b style = {{color: 'red'}}>*</b>
                                <div className="location__consignee">
                                    <Select
                                         styles={customSelectStyles}
                                        value={this.selectedConsignor}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorPlaceholder}
                                        options={operator ? operator : originData}
                                        onChange={this.handleConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {this.otherConsignor && (
                        <>
                            <Row>
                                <Col>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Field"
                                                onChange={this.handleFieldChange}
                                                value={this.state.consignorField}
                                            ></input>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="LSD: ll-ss-ttt-rrWm"
                                                onChange={this.handleLSDChange}
                                                value={this.state.consignorLSD}
                                            ></input>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Consignor"
                                                onChange={this.handleConsignorChange}
                                                value={this.state.consignor}
                                            ></input>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                        <Row>
                        <Col>
                            <div>
                                Select Destination <b style = {{color: 'red'}}>*</b>
                                <div className="location__consignee">
                                    <Select
                                         styles={customSelectStyles}
                                        value={this.selectedConsignee}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consigneePlaceholder}
                                        options={operator ? operator : originData}
                                        onChange={this.handleConsigneeType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {this.otherConsignee && (
                        <>
                            <Row>
                                <Col>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Field"
                                                onChange={this.handleConsigneeFieldChange}
                                                value={this.state.consigneeField}
                                            ></input>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="LSD: ll-ss-ttt-rrWm"
                                                onChange={this.handleConsigneeLSDChange}
                                                value={this.state.consigneeLSD}
                                            ></input>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="location__consignee">
                                            <input
                                                placeholder="Consignee"
                                                onChange={this.handleConsigneeChange}
                                                value={this.state.consignee}
                                            ></input>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col>
                            <div >
                                Enter Nomination Volume <b style = {{color: 'red'}}>*</b>
                                <div className="input">
                                    <input
                                        id="nominationVolume"
                                        type="number"
                                        min={1}
                                        max={99999}
                                        className={`form-control`}
                                        placeholder="Enter Nomination Volume"
                                        onChange={this.handleNominationChange}
                                        value={this.nominationVolume}
                                    />
                                </div>
                            </div>
                            {parseFloat(this.nominationVolume) == 0 && (
                                <div style={{paddingTop: '3px'}} className="email-error"> Nomination Volume can not be 0 </div>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                Select Shipper Name {this.state.dataSourcePlaceholder === 'Crude' && <b style = {{color: 'red'}}>*</b>}
                                <div className="location__consignee">
                                    <Select
                                        styles={customSelectStyles}
                                        value={this.selectedShipperName}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.shipperNamePlaceholder}
                                        options={shipperName ? shipperName : shipperNameData}
                                        onChange={this.handleShipperName}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div style={{paddingLeft: '7px'}} className="confirm__action mt-10">
                            <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick} disabled={this.isSaveDisabled()}>
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>

                </div>
            </div>
            {this.state.showPopup === true &&
            <div className = 'alertBox'> 
            <div className='error-message'>
            <div style={{color: 'red', paddingLeft: 5, fontSize: '18px', fontWeight: 'bold'}}>{' '}ERRORS</div> 
            {this.locationErrors.map((error,index) => {
                return <div style={{padding: 5}}>{index+1}{'. '}{error}</div>
            })} 
            <div style={{paddingBottom: 10,paddingLeft: 270}}>
            
            <CustomButton type={CustomButtonType.Ok} onClick={()=> {
            this.setState({showPopup: false})
            this.locationErrors = []
            }}> OK </CustomButton> </div>
            </div></div>     
            } 
            {this.state.showLoader === true &&
            <div className = 'alert-overlay'> 
            <div style={{margin: 'auto'}} className = 'dots-1'></div> </div> }
            </>
        );
    }

    handleConsignorType = option => {
        this.selectedConsignor = option;
        if (this.selectedConsignor === 'New Location') this.otherConsignor = true;
        else this.otherConsignor = false;
        console.log(option);
        this.setState({ consignorPlaceholder: option });
    };

    handleChange = (option): void => {
        // const { target } = event;
        // const { value } = target;
        this.setState({ product: option });
    };

    handleNewCustomer = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ newCustomer: value });
    }; 

    handlePlainsRatioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        if(parseFloat(value) > 100) event.preventDefault()
        else this.setState({ plainsRatio: value });
    };

    handleContactChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        if (value && value !== '')
        this.setState({ contactNumber: value });
        else
        this.setState({ contactNumber : null });
    };

    isValidInput = (value) => {
        let valid = true
        if(parseFloat(value) >= 999999) valid = false
        let decimal = value.split('.')[1]
        if(decimal !== undefined && decimal.length > 3) valid = false
        return valid;
    }

    handleNominationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        if(this.isValidInput(value)) {
        this.nominationVolume = parseFloat(value)
        this.setState({ nominationVolume : this.nominationVolume});
        } else event.preventDefault()
    };
    checkIfNominationsAreSame = updatedList => {
        const data: any = Array.from(
            new Set(
                updatedList.map(
                    (item: any) =>
                        item.CustomerName +
                        '_' +
                        item.AX_Origin +
                        '_' +
                        item.Destination +
                        '_' +
                        item.DataSource +
                        '_' +
                        item.Product + '_' + item.ContractNumber
                )
            )
        );
        const existingRows = nominationStore.nominationData.filter(
            a => !nominationStore.getUpdatedRowIDs().includes(a.Id.toString())
        );

        const filteredExistingRows = existingRows.filter(item => {
            return updatedList.find(item1 => {
               return  (item1['CustomerName'] === item['CustomerName'] &&
               item1['AX_Origin'] === item['AX_Origin'] &&
               item1['AX_Destination'] === item['AX_Destination'] &&
               item1['DataSource'] === item['DataSource']) && 
               (item1['DataSource'] !== 'Crude'  ? (item1['ContractNumber'] === item['ContractNumber']) : true) ;
            });
         });
        if ( data.length < updatedList.length || filteredExistingRows.length > 0) {
            return true;
        }
        return false;
    };

    validateUpdateRequest(updatedList) {
        let ErrorMessage = '';
        updatedList.forEach(item => {
            if (this.checkIfNominationsAreSame(updatedList)) {
                ErrorMessage = `Creating Duplicate O/D Pairing nomination. Please verify.`;
                return;
            }
        });
        return ErrorMessage;
    }

    isSaveDisabled = () => {
        const origin = this.state.consignorPlaceholder === 'New Location' ? (this.state.consignor) ? this.state.consignorPlaceholder + ' : ' +  (this.state.consignorField +
        ' ' +
        this.state.consignorLSD +
        ' ' +
        this.state.consignor).trim() : null : this.state.consignorPlaceholder;
        const destination = this.state.consigneePlaceholder === 'New Location' ? (this.state.consignee) ? this.state.consigneePlaceholder + ' : ' + (this.state.consigneeField +
        ' ' +
        this.state.consigneeLSD +
        ' ' +
        this.state.consignee).trim() : null : this.state.consigneePlaceholder;
        if(!(this.props.customerName || this.state.newCustomer) ||
            !this.state.product || this.nominationVolume === '' || isNaN(this.nominationVolume) || parseFloat(this.nominationVolume) == 0 ||
            !this.state.dataSourcePlaceholder ||
            (this.state.dataSourcePlaceholder === 'Butane' && (!this.state.contactNumber || this.state.contactNumber === '')) || 
            !origin || !destination || origin === "Select Location" || destination === "Select Location" || 
            this.state.product === '' || this.state.dataSourcePlaceholder === "Select Data Source" || 
            (this.state.dataSourcePlaceholder === 'Crude' && (!this.state.shipperNamePlaceholder || this.state.shipperNamePlaceholder === "Select Shipper Name" )) || 
            (this.state.dataSourcePlaceholder === 'Crude' && (!this.state.plainsRatio || this.state.plainsRatio.toString() === ''))) {
            return true;
        } else {
            return false;
        }
    }

    handleClick = async() => {
        const request = {CustomerName: this.props.customerName ? this.props.customerName : this.state.newCustomer,
            ContractNumber: this.state.contactNumber,
            Product: this.state.product,
            AX_Origin: this.state.consignorPlaceholder === 'New Location' ? this.state.consignorPlaceholder + ' : ' + (this.state.consignorField +
            ' ' +
            this.state.consignorLSD +
            ' ' +
            this.state.consignor).trim() : this.state.consignorPlaceholder,
            AX_Destination:this.state.consigneePlaceholder === 'New Location' ? this.state.consigneePlaceholder + ' : ' + (this.state.consigneeField +
            ' ' +
            this.state.consigneeLSD +
            ' ' +
            this.state.consignee).trim() : this.state.consigneePlaceholder,
            NominationVolume: this.nominationVolume,
            DataSource: this.state.dataSourcePlaceholder,
            ShipperName: this.state.dataSourcePlaceholder === 'Crude' ?  this.state.shipperNamePlaceholder : this.selectedShipperName ? this.selectedShipperName : null,
            PlainsNominationVolume: this.state.dataSourcePlaceholder === 'Crude' && parseInt(this.state.plainsRatio.toString(), 10) ? parseInt(this.state.plainsRatio.toString(), 10) : 0,
            OthersNominationVolume: this.state.dataSourcePlaceholder === 'Crude' ? 100 - (parseInt(this.state.plainsRatio.toString(), 10) ? parseInt(this.state.plainsRatio.toString(), 10) : 0) : 0
        }
        request.ContractNumber =
        CommonUtils.checkIfFieldIsNullOrEmpty(request.ContractNumber) &&
        (request.DataSource === 'NGL' || request.DataSource === 'Brine')
            ? 'Dummy'
            : request.ContractNumber;
        if (request.DataSource === 'Crude' && !CommonUtils.checkIfFieldIsNullOrEmpty(request.ContractNumber)) {
            request.ContractNumber = null;
            uiService.toastService.info('Contract number for crude is not considered. It will always be blank');
        }
        if ((request.ShipperName === 'Others' && request.PlainsNominationVolume == 100) ||
            (request.ShipperName === 'Plains Midstream Canada ULC' && request.OthersNominationVolume == 100)) {
            uiService.toastService.error('Please select the Plains % and Shipper Name correctly');
            return;
        }
        const nominationData = [{
            CustomerName: request.CustomerName,
            name: '',
            ContractNumber: request.ContractNumber,
            Product: request.Product,
            AX_Origin: request.AX_Origin,
            AX_Destination: request.AX_Destination,
            NominationVolume: request.NominationVolume,
            CurrentNomination: null,
            IsSelected: true,
            IsExpanded: false,
            IsEdit: true,
            IsChild: true,
            PendingVolume: null,
            DispatchedVolume: null,
            DataSource: request.DataSource,
            CompletedVolume: null,
            ShipperName: request.ShipperName,
            NominationType: 'Manual',
            Id: 0,
            Origin: request.AX_Origin, 
            Destination: request.AX_Destination,
            NominationComment: "",
            PlainsNominationVolume: request.PlainsNominationVolume,
            OthersNominationVolume: request.OthersNominationVolume
        }];
        let userID = accountStore.getUserID()
        if (!userID || userID === 0 ) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                uiService.loaderService.hideLoader();
                return;
            }
        }
        const requestBody = {
            NominationMonth: this.props.selectedMonth + 1,
            NominationYear: this.props.selectedYear,
            CreatedBy: accountStore.getUserID(),
            NominationData: nominationData,
            ModifiedBy: accountStore.getUserID()
        };
        const validationMessage = this.validateUpdateRequest(nominationData);
        if (!isEmpty(validationMessage)) {
            uiService.loaderService.hideLoader();
            uiService.toastService.error(validationMessage);
            return;
        }
        this.setState({showLoader : true})
        const response = await nominationDataService.createUpdateNominationData(requestBody); 
        this.setState({showLoader : false})
        let responseCodes = response['Data'].split('\,')
        responseCodes.forEach((code) => {
            if(code.trim() == ImportErrorCodes.ODPAIRING)
            this.locationErrors.push('There is no O/D pairing in PDS. Please set up O/D pairing in Location Management.')
            if(code.trim() == ImportErrorCodes.TRUCKEDBY_ORIGIN)
            this.locationErrors.push('Error adding nominations. Please enable Trucked by Locations for the Origin in Location Mapping screen.')
            if(code.trim() == ImportErrorCodes.TRUCKEDBY_DESTINATION)
            this.locationErrors.push('Error adding nominations. Please enable Trucked by Locations for the Destination in Location Mapping screen.')
        })
        if(this.locationErrors.length > 0) {
            this.setState({showPopup : true})
        }
        if(response['Data'] == '') {
        this.props.onSuccessClick();
        this.props.onClose();
        uiService.toastService.success("Saved Successfully")
        }
    };
    handleConsigneeType = option => {
        this.selectedConsignee = option;
        if (this.selectedConsignee === 'New Location') this.otherConsignee = true;
        else this.otherConsignee = false;
        console.log(option);
        this.setState({ consigneePlaceholder: option });
    };

    handleFieldChange = event => {
        this.setState({ consignorField: event.target.value });
    };

    handleLSDChange = event => {
        this.setState({ consignorLSD: event.target.value });
    };

    handleConsignorChange = event => {
        this.setState({ consignor: event.target.value });
    };

    handleConsigneeFieldChange = event => {
        this.setState({ consigneeField: event.target.value });
    };

    handleConsigneeLSDChange = event => {
        this.setState({ consigneeLSD: event.target.value });
    };

    handleConsigneeChange = event => {
        this.setState({ consignee: event.target.value });
    };


    handleDataSource = option => {
        this.selectedDataSource = option;
        const selectedDataSourceId = this.props.dataSourceData.filter((item) => item.Label === option)[0].ID;
        const productsData = this.props.productsData.filter((item) => item.DataSourceID === selectedDataSourceId);
        this.setState({ 
            dataSourcePlaceholder: option, 
            productsData: Array.from(new Set(productsData.map((item: any) => item.Label))),
            product: Array.from(new Set(productsData.map((item: any) => item.Label)))[0]
        });
    };

    handleShipperName = option => {
        this.selectedShipperName = option;
        this.setState({
            shipperNamePlaceholder: option
        })
    }

}