import { action, observable, toJS } from 'mobx';
import Loader from '../../../../shared/decorators/loader-decorator';
import { DispatchBoardDataService } from '../services/dispatch-board-data-service';
import {
    Region,
    RegionMappingDataRequest,
    RegionMappingType,
    DispatchBoardDataRequest,
    DriversStatusType
} from '../model/model';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';
import { AccountStore } from '../../../account/stores/account-store';
import { availableStatus, unAvailableStatus, availablityNotProvided, dispatched } from '../dispatchBoard-constants';
import { dateUtils } from 'shared/services/date-utils';
import { AgGridService } from 'features/common/services/ag-grid-service';
export class DispatchBoardDataStore {
    userID = 0;
    userName = '';

    @observable tempArrayUnavailableDrivers = [] as any;
    @observable tempArrayDispatchedDrivers = [] as any;
    @observable selectedMonth: any = '';
    @observable selectedYear: any = '';
    @observable dataExist = false;
    @observable menuLoader = false;
    @observable lstCompany = [] as any;
    @observable regionMappingData: RegionMappingType = {
        NotMappedCompany: {},
        MappedCompany: {},
        MappedTrailer: {},
        NotMappedTrailer: {}
    };
    @observable baseValueDTO: Region[] = [];
    @observable selectedRegionID = 0;
    @observable dispatchBoardData = [] as any;
    @observable driverInfo: any = [];
    constructor(private dispatchBoardDataService: DispatchBoardDataService, private accountStore: AccountStore, public agGridService: AgGridService,) {
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }
    @observable displayedDataSource: DriversStatusType = {
        TotalDrivers: {},
        Available: {},
        NotAvailableDrivers: {},
        AvaialabilityNotProvided: {}
    };

    getRegionMapJson(regionMappingArray) {
        const mappingRegion: RegionMappingType = {
            NotMappedCompany: {},
            MappedCompany: {},
            MappedTrailer: {},
            NotMappedTrailer: {}
        };
        if (regionMappingArray.length > 0) {
            regionMappingArray.forEach(key => {
                if (mappingRegion.hasOwnProperty(key.Mapping)) {
                    mappingRegion[key.Mapping][key.ID] = key.Name;
                }
            });
        }
        return mappingRegion;
    }

    RefactorDriverStatus(responseArray) {
        const tempArray = Object.values(
            responseArray.reduce((acc, item) => {
                if (!acc[item.Date])
                    acc[item.Date] = {
                        dateOfMonth: item.Date,
                        driverStatusData: {
                            totalDrivers: [],
                            availableDrivers: [],
                            unAvailableDrivers: [],
                            availibilityNotProvided: [],
                            dispatched: []
                        }
                    };

                if (item.Availability === availableStatus) {
                    if (item.IsDispatched === true) {
                        item.Availability = dispatched;
                        acc[item.Date].driverStatusData.dispatched.push({ ...item, Color: '#faba4d' });
                    } else {
                        acc[item.Date].driverStatusData.availableDrivers.push({ ...item, Color: '#3dc651' });
                    }
                } else if (item.Availability === dispatched && item.IsDispatched === true) {
                    acc[item.Date].driverStatusData.dispatched.push({ ...item, Color: '#faba4d' });
                } else if (item.Availability === unAvailableStatus) {
                    acc[item.Date].driverStatusData.unAvailableDrivers.push({ ...item, Color: '#f96361' });
                } else if (item.Availability === availablityNotProvided) {
                    acc[item.Date].driverStatusData.availibilityNotProvided.push({ ...item, Color: '#8f75c9' });
                }
                if (acc[item.Date].driverStatusData.totalDrivers.some(item1 => item1.DriverID === item.DriverID)) {
                    return acc;
                }
                acc[item.Date].driverStatusData.totalDrivers.push({
                    ...item,
                    Color:
                        item.Availability === availableStatus
                            ? '#3dc651'
                            : item.Availability === unAvailableStatus
                            ? '#f96361'
                            : item.Availability === availablityNotProvided
                            ? '#8f75c9'
                            : '#faba4d'
                });
                return acc;
            }, {})
        );
        return tempArray;
    }

    getDriverStatusJson(responseArray) {
        const newTempArray = this.RefactorDriverStatus(responseArray.filter(array => array.Driver != null));
        newTempArray.sort((item1, item2) => this.sort_item(item2, item1))
        return newTempArray;
    }

    sort_item(a, b){
        // a should come before b in the sorted order
        if(dateUtils.isDateSameorGreaterThanGivenDate(b.dateOfMonth, a.dateOfMonth)){
                return 1;
        // a should come after b in the sorted order
        }else if(dateUtils.isDateSameorGreaterThanGivenDate(a.dateOfMonth, b.dateOfMonth)){
                return -1;
        // and and b are the same
        }else{
                return 0;
        }
    }

    @Loader
    @action
    async GetMasterData(): Promise<void> {
        const Region = localStorageService.get('region');
        let allArray = [{BaseClassID: 1, Code: "All", ID: "All", Name: "All", Value: null}]
        
        try {
            if (Region) {
                this.baseValueDTO = Region as Region[];
                let newToDoList: any;
               if( this.baseValueDTO.some(item => item.Name === 'All')) {
                    newToDoList = [...this.baseValueDTO]
                    console.log(newToDoList, "newToDoList", toJS(this.baseValueDTO))
                } else {
                    newToDoList = [...allArray, ...this.baseValueDTO]
                    console.log(newToDoList, "newToDoList with all")
                }
                this.baseValueDTO = newToDoList as unknown as Region[]
            } else {
                await this.dispatchBoardDataService
                    .getRegionTypes()
                    .then(response => {
                        const responseData = response.data['Data'];
                        const sortedDataForNorthRegion = responseData
                            .filter(item => item.Name.includes('North'))
                            .sort((a, b) => a.ID > b.ID);
                        const sortedDataForSouthRegion = responseData
                            .filter(item => item.Name.includes('South'))
                            .sort((a, b) => a.ID > b.ID);
                            if (this.baseValueDTO.some(item => item.Name === 'All')) {
                                this.baseValueDTO = [...sortedDataForNorthRegion, ...sortedDataForSouthRegion];
                                console.log(this.baseValueDTO, "baseValueDTO all")
                            } else {
                                this.baseValueDTO = [...allArray, ...sortedDataForNorthRegion, ...sortedDataForSouthRegion];
                                console.log(this.baseValueDTO, "newToDoList without all")
                            }
                        localStorageService.set('region', this.baseValueDTO);
                    })
                    .catch(function(error) {
                        console.log('error in getting Region', error);
                    });
            }
        } catch (e) {
            localStorageService.remove('region');
            console.log('error in getting Region', e);
        }
    }

    @Loader
    @action
    async getCompanyRegionMapping(reqBody: RegionMappingDataRequest): Promise<void> {
        try {
            this.dataExist = false;
            const result = await this.dispatchBoardDataService
                .getCompanyRegionMapping(reqBody)
                .then(response => {
                    this.regionMappingData = this.getRegionMapJson(response.data['Data']);
                    this.menuLoader = false;
                    this.dataExist = true;
                })
                .catch(function(error) {
                    console.log('error in getting mapping data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getLocationData(reqBody: RegionMappingDataRequest): Promise<void> {
        try {
            this.dataExist = false;
            const result = await this.dispatchBoardDataService
                .getCompanyRegionMapping(reqBody)
                .then(response => {
                    this.regionMappingData = this.getRegionMapJson(response.data['Data']);
                    this.menuLoader = false;
                    this.dataExist = true;
                })
                .catch(function(error) {
                    console.log('error in getting mapping data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getCapacityDisplayData(reqBody: DispatchBoardDataRequest): Promise<void> {
        try {
            console.log('reqBody' + reqBody);
            this.dispatchBoardData = [];
            const result = await this.dispatchBoardDataService
                .getCapacityPlanningDisplayData(reqBody)
                .then(response => {
                    this.dispatchBoardData = this.getDriverStatusJson(response.data['Data']);
                })
                .catch(function(error) {
                    console.log('error in getting capacity display data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }

    @Loader
    @action
    async getDispatchBoardDetails(reqBody: DispatchBoardDataRequest): Promise<void> {
        try {
            this.driverInfo = [];
            const result = await this.dispatchBoardDataService
                .getDispatchBoardDetails(reqBody)
                .then(response => {
                    this.driverInfo = response.data['Data'];
                })
                .catch(function(error) {
                    console.log('error in getting capacity display data', error);
                });
        } catch (e) {
            return Promise.reject();
        }
    }
}
