import { uiService } from '../../../shared/services/ui-service';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';

import { DailyReportDataStore } from './dailyreport-data-store';
import { LeaseOperatorStore } from './leaseoperator-data-store';
import { dailyreportDataService } from '../services/dailyreport-data-service';
import { leaseoperatorDataService } from '../services/leaseoperator-data-service';
import { hourlyreportDataService } from '../services/hourlyreport-data-service';
import { HourlyReportDataStore } from './hourlyreport-data-store';
import { CustomerReportStore } from './customerreport-data-store';
import { customerreportDataService } from '../services/customerreport-data-service';
import { RejectedLoadDataStore } from './rejected-load-data-store';
import { rejectedLoadDataService } from '../services/rejected-load-data-service';
import { scheduledReportDataService } from '../services/scheduledreport-data-service';
import { ScheduledReportDataStore } from './scheduledreport-data-store';
import { UpcomingCertificationDataStore } from './upcomingcertificate-data-store';
import { SearchTicketsDataStore } from './search-tickets-data-store';
import { searchTicketsDataService } from '../services/search-tickets-data-service';
import { MasterTicketReportDataStore } from './master-ticket-report-data-store';
import { masterTicketDataService } from '../services/master-ticket-report-data-service';

export const leaseOperatorStore = new LeaseOperatorStore(
    leaseoperatorDataService,
    agGridService,
    uiService,
    accountStore
);
export const dailyReportStore = new DailyReportDataStore(
    dailyreportDataService,
    agGridService,
    uiService,
    accountStore
);
export const hourlyReportStore = new HourlyReportDataStore(
    hourlyreportDataService,
    agGridService,
    uiService,
    accountStore
);
export const customerReportStore = new CustomerReportStore(
    customerreportDataService,
    agGridService,
    uiService,
    accountStore
);
export const scheduledReportStore = new ScheduledReportDataStore(
    scheduledReportDataService,
    agGridService,
    uiService,
    accountStore
);
export const upcomingCertificationDataStore = new UpcomingCertificationDataStore(
    agGridService,
    uiService,
    accountStore
)

export const rejectedLoadStore = new RejectedLoadDataStore(rejectedLoadDataService, agGridService, accountStore);

export const searchTicketsStore = new SearchTicketsDataStore(searchTicketsDataService, agGridService, uiService, accountStore);

export const masterTicketReportStore = new MasterTicketReportDataStore(
    masterTicketDataService,
    agGridService,
    uiService,
    accountStore
)