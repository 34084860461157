import { AppConstant } from 'app_constant';
import { Config } from 'config';
import { CompanyResponse, CompanyViewModel } from 'features/data_management/domains/company-model';
import { OperatorResponse } from 'features/data_management/domains/location-management/model';
import { restApiService } from 'shared/services';
import { BaseConfigValue, ResponseDataType, RestApi } from 'shared/types/shared';

export class NominationDataService {
    constructor(private apiService: RestApi) {}

    getNominationData(reqbody) {
        return this.apiService.postWithToken<{ Data: any }>(Config.getNomination, reqbody);
    }

    getNominationLoadData(reqbody) {
        return this.apiService.postWithToken<{ Data: any }>(Config.getNominationLoad, reqbody);
    }

    getApportionmentData(reqbody) {
        return this.apiService.postWithToken<{ Data: any }>(Config.getApportionmentData, reqbody);
    }

    uploadExcel(reqbody) {
        return this.apiService.postWithToken<{}>(Config.uploadNominationExcel, reqbody);
    }

    async getOperator(): Promise<OperatorResponse[]> {
        const { data } = await this.apiService.postWithToken<OperatorResponse[]>(Config.getLocationMappingInfo);
        return data['Data'];
    }

    async getLocations(): Promise<OperatorResponse[]> {
        const { data } = await this.apiService.postWithToken<OperatorResponse[]>(Config.getOperator, {AxOrRAData: 'All'});
        return data['Data'];
    }

    async createUpdateNominationData(reqbody) {
        const { data } = await this.apiService.postWithToken<OperatorResponse[]>(
            Config.createUpdateNominationData,
            reqbody
        );
        return data;
    }

    async createUpdateNominationLoadData(reqbody) {
        const { data } = await this.apiService.postWithToken<OperatorResponse[]>(
            Config.createUpdateNominationLoadData,
            reqbody
        );
        return data['Data'];
    }

    async sendLocationMapping(reqbody) {
        const { data } = await this.apiService.post(Config.sendLocationMapping, reqbody, {});
        return data;
    }

    private getCompanyResponse(): Promise<ResponseDataType<CompanyResponse[]>> {
        return this.apiService.postWithToken<CompanyResponse[]>(Config.getCarrierCompanyData);
    }
    async getCompanyViewModel(): Promise<CompanyViewModel> {
        const companyDataPromise = this.getCompanyResponse();
        const [{ data: companyData }] = await Promise.all([companyDataPromise]);
        return {companyData};
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.apiService.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }
}
export const nominationDataService = new NominationDataService(restApiService);
