import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { DriverResponse } from '../domains/driver-management/model';
import { DriverMessage, DriverFieldName } from '../domains/driver-management/enum';
import { DriverDataService } from '../services/driver-management/driver-data-service';
import { DriverValidationService } from '../services/driver-management/driver-validation-service';
import { driverColDef } from '../domains/driver-management/driver-col-def';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { FilterKeyForReferenceData } from '../domains/enum';
import { CCUserRoles, INVALID_CCID, INVALID_USERID } from '../data_constant';
import { threadId } from 'worker_threads';
import { CommonUtils } from 'shared/services/common-utils';

export class DriverDataStore {
    @observable drivers: DriverResponse[] = [];
    backupDriverList: DriverResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    userInfo: any;
    constructor(
        private driverDataService: DriverDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private driverValidationService: DriverValidationService
    ) { }

    init(): void {

        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getDrivers();
    }

    getColDef() {
        this.updatePhoneNumberColDef();
        this.updateNameColDef();
        return driverColDef;
    }

    updatePhoneNumberColDef = () => {
        const phoneColDef = driverColDef.find(x => x.colId == DriverFieldName.PHONENUMBER);
        if (phoneColDef) {
            phoneColDef.cellRendererParams = {
                onChange: (item, value) => this.setPhoneNumber(item, value, DriverFieldName.PHONENUMBER),
                isReadonly: (item: DriverResponse, value) => {
                    return (
                        !dataStore.checkOperationAccessWithModule(
                            "PhoneEdit",
                            FilterKeyForReferenceData.DriverManagement)
                    );
                },
                mask: "_",
                format: "(###) ### ####"
            }
        }
    }

    updateNameColDef = () => {
        const nameValues = [DriverFieldName.FIRSTNAME, DriverFieldName.LASTNAME];
        nameValues.forEach(nameValue => {
            const nameColDef = driverColDef.find(x => x.colId == nameValue);
            if (nameColDef) {
                const propertyName = nameValue === DriverFieldName.FIRSTNAME ? 'FirstNameEdit' : 'LastNameEdit';
                nameColDef.cellRendererParams = {
                    onChange: (item, value) => this.setNameValues(item, value, nameValue),
                    isReadonly: (item: DriverResponse, value) => {
                        return !dataStore.checkOperationAccessWithModule(
                            propertyName,
                            FilterKeyForReferenceData.DriverManagement
                        );
                    }
                };
            }
        });
    };

    @Loader
    @Catch(() => errorHandler(DriverMessage.FETCH_ERROR_MESSAGE))
    async getDrivers(): Promise<void> {
        const reqbody: any = {};
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const drivers = await this.driverDataService.getDrivers(reqbody);
        drivers.forEach(data => {
            if(data.CellPhoneNo != null)
            data.CellPhoneNo = data.CellPhoneNo.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
        })
        this.setDriverList(drivers);
        this.setBackupDriverList(drivers);
    }

    @action
    setPhoneNumber(driver: DriverResponse, value: string, col: string): void {
        this.setValueInChangeMap(driver.ID, col, driver.CellPhoneNo, value);
        driver.CellPhoneNo = value;
        this.updateRow(driver);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    setNameValues(driver: DriverResponse, value: string, colName: string): void {
        this.setValueInChangeMap(driver.ID, colName, driver[colName], value);
        switch (colName) {
            case DriverFieldName.FIRSTNAME:
                driver.FirstName = value;
                break;
            case DriverFieldName.LASTNAME:
                driver.LastName = value;
                break;
            default:
                break;
        }
        this.updateRow(driver);
    }

    setBackupDriverList(backUpList: DriverResponse[]) {
        this.backupDriverList = backUpList;
    }

    @computed
    get DriverList(): DriverResponse[] {
        return toJS(this.drivers);
    }

    @action
    setDriverList(drivers: DriverResponse[]): void {
        this.drivers = drivers;
    }

    @action
    reset(): void {
        this.setDriverList([]);
    }

    @action
    resetDriverList(): void {
        this.setDriverList(this.backupDriverList);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: DriverResponse): void => {
        const updatedDriverIndex = this.drivers.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.drivers[updatedDriverIndex], selectedRowData)) {
            this.mapEditableColumns(this.drivers[updatedDriverIndex], selectedRowData);
        }
    };

    @Loader
    @action
    async updateDrivers(): Promise<void> {
        const updatedRowIDs = this.drivers.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateDriverRequest(updatedRowIDs);
        const validationMessage = this.driverValidationService.validateUpdateRequest(requestBody);
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        let errorInSave = false;
        let errorSummary: ToastMessage[] = [];
        const response = await this.driverDataService.updateDrivers(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].ID) {
                    const objIndex = this.drivers.findIndex(a => a.ID === response[key].ID);
                    this.drivers[objIndex].ModifiedDate = response[key].UpdatedDate;
                    this.drivers[objIndex].ModifiedBy = this.userID;
                    this.drivers[objIndex].ModifiedByUserName = this.userName;
                    this.drivers[objIndex].CellPhoneNo = this.drivers[objIndex].CellPhoneNo.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
                    for (let i = 0; i < this.backupDriverList.length; i++) {
                        const backupItem = this.backupDriverList[i];
                        if (backupItem.ID === response[key].ID) {
                            this.mapEditableColumns(backupItem, this.drivers[objIndex]);
                            break;
                        }
                    }
                }
                else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key.toLowerCase(), description: response[key] }];
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.drivers.length; i++) {
                    const updatedDriver = this.drivers[i];
                    if (updatedDriver.LoginName.toLowerCase() === errorMessage.id) {
                        const backupDriverIndex = this.backupDriverList.findIndex(a => a.LoginName.toLowerCase() == errorMessage.id);
                        this.mapEditableColumns(updatedDriver, this.backupDriverList[backupDriverIndex]);
                        break;
                    }
                }
            });
        }
        else {
            this.uiService.toastService.success(DriverMessage.SAVE);
        }
    }

    updateDriverRequest(updatedRows: DriverResponse[]): DriverResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.ModifiedByUserName = this.userName;
            item.CellPhoneNo = item.CellPhoneNo.replace(/\s+/g, '').replace('(','').replace(')','');
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    mapEditableColumns(currentItem: DriverResponse, updatedItem: DriverResponse) {
        currentItem.FirstName = updatedItem.FirstName;
        currentItem.LastName = updatedItem.LastName;
        currentItem.CellPhoneNo = updatedItem.CellPhoneNo.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
        currentItem.ModifiedDate = updatedItem.ModifiedDate;
        currentItem.ModifiedByUserName = updatedItem.ModifiedByUserName;
        currentItem.ModifiedBy = updatedItem.ModifiedBy;
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }
}
