import { User, ResponseDataType, BaseConfigValue } from '../types/shared';
import { RestApiService } from './rest-api-service';
import { Config } from 'config';
import { restApiService } from '.';
import { AppConstant } from 'app_constant';

export class UserService {
    constructor(private restApi: RestApiService) { }

    getSignUpUserInfo(loginName: string): Promise<ResponseDataType<User>> {
        return this.restApi.post<User>(Config.getUser, { LoginName: loginName });
    }

    getLoginUserInfo(loginName: string): Promise<ResponseDataType<User>> {
        return this.restApi.postWithToken<User>(Config.getLoginUser, { LoginName: loginName });
    }

    getUserTypes(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.USER_TYPE });
    }

    updateUserStatusForApproval(user: any): Promise<any> {
        return this.restApi.post<any>(Config.updateUserStatusForApproval, user);
    }

    getFacilities(): Promise<any> {
        return this.restApi.post(Config.getFacility);
    }

    getPSACarrierWithDispatcher(): Promise<any> {
        return this.restApi.post(Config.getPSACarrierWithDispatcher);
    }

    getPsaUserRoles(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.PSA_USER_ROLE });
    }

    getPtsUserRoles(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.PTS_USER_ROLE });
    }

    getUserApprovalInfoForDispatcher(user: any): Promise<any> {
        return this.restApi.postWithToken<any>(Config.getUserToApproveForDispatcher, user);
    }

    updateUserStatusByDispatcher(user: any): Promise<any> {
        return this.restApi.postWithToken<any>(Config.UpdateUserStatusByDispatcher, user);
    }
}

export const userService = new UserService(restApiService);
