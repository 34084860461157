import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
export class DateUtils {
    getFormattedDateTime(value: string) {
        return momenttimezone
            .tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER)
            .format(AppConstant.DATE_TIME_FORMAT);
    }

    getFormattedDateTimeForCommunication(value: string) {
        return momenttimezone
            .tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER)
            .format('YYYY/MM/DD HH:mm');
    }

    getFormattedDateOnly(value: string) {
        return momenttimezone.tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER).format('MM/DD/YYYY');
    }

    getFormattedMSTDateOnly(value: any) {
        return moment(value).format('MM/DD/YYYY');
    }

    getFormattedTimeOnly(value: string) {
        return momenttimezone.tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER).format('HH:mm:ss');
    }

    getFormattedTimeHHmm(value: any) {
        return momenttimezone.tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER).format('HH:mm');
    }

    getUTCFormattedTime(value: string) {
        return moment.utc(value).format('HH:mm');
    }
    getMSTTimeOnly(value: string) {
        return moment(value).format('HH:mm');
    }

    getTimeWithoutSeconds(value: string) {
        return moment(value).format('HH:mm');
    }

    getFormattedDateTimeLocal(value: string) {
        return momenttimezone.tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER).format(AppConstant.DATE_TIME_FORMAT);
    }

    getFormattedDate(value: string) {
        return momenttimezone.tz(moment.utc(value), AppConstant.MST_TIME_FORMATTER).format(AppConstant.DATE_FORMAT);
    }

    getFormattedDateTimeToSendToAPI() {
        return momenttimezone.tz(moment(), AppConstant.MST_TIME_FORMATTER).format(AppConstant.API_DATE_FORMAt);
    }

    getDateInMMDDYYYYFormat(value: string) {
        return moment(value).format(AppConstant.DATE_FORMAT);
    }

    isDateSameorGreaterThanGivenDate(date1, date2) {
        return moment(this.getDateInMMDDYYYYFormat(date1)).isSameOrAfter(this.getDateInMMDDYYYYFormat(date2));
    }

    getLastSubstring(value: string, count: number) {
        return value.length > count ? value.substr(value.length - count) : value;
    }

    updateFormatDate(value: string) {
        return moment(value).format('yyyy-MM-DD');
    }

    getApiDateFormat(value: string) {
        return moment(value).format('YYYY-MM-DD')+ 'T07:00';
    }
    isDateSameAsCurrentDate(value: string) {
        const currentDate = moment.utc().toString();
        return moment(this.getDateInMMDDYYYYFormat(value)).isSame(this.getDateInMMDDYYYYFormat(currentDate));
    }
}
export const dateUtils = new DateUtils();
