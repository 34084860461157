import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { InspectionDataStore } from '../stores/inspection-data-store';
import { Modal, Button } from 'react-bootstrap';
import { InspectionDefectListComponent } from './inspection-defect-list';
import { CommonUtils } from 'shared/services/common-utils';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { Container, Row, Col } from 'react-bootstrap';

interface InspectionUpComponentProps {
    inspectionDataStore: InspectionDataStore;
    inspectionNumber: number;
    showchild: boolean;
    onHide: () => void;
    version?: number;
}

@inject('inspectionDataStore')
@observer
export class InspectionUpComponent extends Component<InspectionUpComponentProps> {
    constructor(props) {
        super(props);

        this.props.inspectionDataStore.init({
            InspectionTicketID: CommonUtils.getQueryStringParam('id') // this.props.inspectionNumber,
        });
        this.state = {
            showmodal: false
        };
    }

    render(): React.ReactNode {
        const { showchild, inspectionDataStore } = this.props;
        let ticketid = CommonUtils.getQueryStringParam('id');
        let ticketNumber = CommonUtils.getQueryStringParam('ticketno');
        let titletext = 'Ticket Number ' + (ticketNumber ==undefined?'0':ticketNumber) ;
        if (ticketid !== undefined && ticketid > 0) {
            return (
                <div className="Uimargin">
                    <span className="ticket_number">{titletext}</span>
                            <InspectionDefectListComponent
                                prepostChecklist={inspectionDataStore.prepostChecklist}
                                inspectionDefectList={inspectionDataStore.inspectionDefects}
                                inspectionTicketNumber={ticketid}
                            />
                     </div>
            );
        } else {
            return <div></div>;
        }
    }
}
