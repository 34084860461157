/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { CommonUtils } from 'shared/services/common-utils';
import { AppConstant } from 'app_constant';
import { accountStore } from 'features/account/stores';
import { CCUserRoles, plainsRoles } from 'features/dispatch/communication/communication-constants';
import { dataStore } from 'features/common/stores';
import { dateUtils } from 'shared/services/date-utils';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (email) => void;
    mail: boolean;
    msg;
    reply;
    ccname;
}

export class EmailPopUp extends React.Component<ConfirmProps> {
    state = {
        emailId: '',
        invalidEmail: '',
        message: '',
        isReply: false,
        reply: false,
    };
    public render(): React.ReactNode {
        const { onClose, mail, msg, reply,ccname } = this.props;
        return (
            <div>
                {!mail && (
                    !ccname ? (<div className="confirm">
                        <div className="confirm__action mt-10">Recipient EmailID</div>
                        <Row className="mb-2">
                            <Col>
                                <div className="input">
                                    <input
                                        className={`form-control ${this.state.invalidEmail}`}
                                        placeholder="Enter Email ID"
                                        onChange={this.handleChange}
                                        value={this.state.emailId}
                                    />
                                    {this.state.invalidEmail && (
                                        <div className="email-error">{AppConstant.INVALID_EMAIL_ADDRESS}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className="confirm__action mt-10">
                            <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick}>
                                Send
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </div>
                    ) :
                    (<div className="confirm">
                        <div className="confirm__action mt-10">Carrier Company Name</div>
                        <Row className="mb-2">
                            <Col>
                                <div className="input">
                                    <input
                                        className={`form-control ${this.state.invalidEmail}`}
                                        placeholder="Enter CarrierComapny"
                                        onChange={this.handleChange}
                                        value={this.state.emailId}
                                    />
                                    {this.state.invalidEmail && (
                                        <div className="email-error">Please Enter CarrierCompany Name</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className="confirm__action mt-10">
                            <CustomButton type={CustomButtonType.Ok} onClick={this.handleCarrierClick}>
                                Send
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </div>
                    )
                   
                )}
                {mail && (
                    <div className="email">
                        {this.state.reply && (
                            <>
                                <div>Subject: {msg[0].Subject}</div>
                                <div>
                                    <textarea
                                        style={{ width: '100%', height: '55px' }}
                                        rows={3}
                                        name="message"
                                        id="message"
                                        placeholder="Type Reply"
                                        onBlur={this.handleBlur}
                                    />
                                    {this.state.invalidEmail && <div className="email-error">Please enter Message</div>}
                                </div>
                                <div style={{ paddingTop: '20px' }}>Mail Trail:</div>
                            </>
                        )}

                        <>
                            {!this.state.reply && msg[0].Subject !== '' && <div>Subject: {msg[0].Subject}</div>}
                            {/* <div>Message: {this.state.emailId} Message has been sent</div>
                            <div style={{ paddingTop: '25px' }}>
                                <div>Thanks</div>
                                <div></div>
                            </div> */}
                            {msg.map(X => {
                                return (
                                    <div
                                        style={{
                                            padding: '10px 0px',
                                            backgroundColor: 'whitesmoke',
                                            borderBottom: '1px solid grey'
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            {reply && (
                                                <span className="spanWidth30">
                                                    {!this.checkCCLogin()
                                                        ? X.SenderID === accountStore.getUserID() ||
                                                          X.SenderRole === accountStore.userRoles[0] ||
                                                          plainsRoles.includes(X.SenderRole)
                                                            ? 'Sent'
                                                            : 'Received'
                                                        : X.SenderID === accountStore.getUserID()
                                                        ? 'Sent'
                                                        : 'Received'}<br></br>
                                                        {dateUtils.getFormattedDateTimeForCommunication(X.CreatedDate)}
                                                </span>
                                            )}
                                            <span style={{ width: '220px' }}>
                                                {reply && (
                                                    <div>
                                                        {!this.checkCCLogin()
                                                            ? X.SenderID === accountStore.getUserID() ||
                                                              X.SenderRole === accountStore.userRoles[0] ||
                                                              plainsRoles.includes(X.SenderRole)
                                                                ? X.Receiver
                                                                : X.Sender
                                                            : X.SenderID === accountStore.getUserID()
                                                            ? X.Receiver
                                                            : X.Sender}
                                                    </div>
                                                )}
                                                {/* <Row> Subject: {X.Subject}</Row> */}
                                                <div style={{ padding: '5px' }}> Message: {X.Message}</div>
                                            </span>
                                            {reply && (
                                                <>
                                                    <span className="spanWidth30">
                                                        <div>Delivered</div>
                                                        <div>
                                                            {X.IsSent || X.IsSent == null ? (
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    style={{ color: 'green', fontSize: '24px' }}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa fa-times-circle"
                                                                    style={{ color: 'red', fontSize: '24px' }}
                                                                ></i>
                                                            )}
                                                        </div>
                                                    </span>
                                                    <span className="spanWidth30">
                                                        <div>Read</div>
                                                        <div>
                                                            {X.IsRead ? (
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    style={{ color: 'green', fontSize: '24px' }}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa fa-times-circle"
                                                                    style={{ color: 'red', fontSize: '24px' }}
                                                                ></i>
                                                            )}
                                                        </div>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            {!this.state.reply && (
                                <div className="confirm__action mt-10">
                                    {reply && dataStore.checkOperationAccessWithModule('Save','Communication') && (
                                        <CustomButton type={CustomButtonType.Ok} onClick={this.handleReply}>
                                            Reply
                                        </CustomButton>
                                    )}
                                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                        OK
                                    </CustomButton>
                                </div>
                            )}
                            {this.state.reply && (
                                <div className="confirm__action mt-10">
                                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleSend}>
                                        Send
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                        Cancel
                                    </CustomButton>
                                </div>
                            )}
                        </>
                    </div>
                )}
            </div>
        );
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ emailId: value });
        this.setState({ invalidEmail: '' });
    };

    private handleReply = () => {
        this.setState({ reply: true });
    };

    private handleSend = () => {
        if (this.state.message !== '') {
            this.props.onSuccessClick(this.state.message);
            this.props.onClose();
        } else {
            this.setState({ invalidEmail: 'invalid-email' });
        }
    };

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    isEmailValid(email: string) {
        const isValid = CommonUtils.validateEmail(email);
        if (!isValid) {
            this.setState({ invalidEmail: 'invalid-email' });
            return false;
        } else {
            this.setState({ invalidEmail: '' });
            return true;
        }
    }

    handleBlur = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ message: value });
    };

    handleClick = () => {
        if (this.isEmailValid(this.state.emailId)) {
            this.props.onSuccessClick(this.state.emailId);
            this.props.onClose();
        }
    };

    handleCarrierClick = () => {
        if (!CommonUtils.checkIfFieldIsNullOrEmpty(this.state.emailId)) {
            this.props.onSuccessClick(this.state.emailId);
            this.props.onClose();
        }
    }
}
