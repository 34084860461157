import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { nominationStore } from '../stores/nominatation-data-store';
import { nominationDataService } from '../services/nomination-data-service';
import { toJS } from 'mobx';
import Loader from '../../../../shared/decorators/loader-decorator';
import Catch from '../../../../shared/decorators/catch-decorator';
import { errorHandler } from 'shared/handlers/error-handler';
interface AlphaNumericCellRendererState {
    value: boolean;
}
export class NominationSelectionRadio extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }
    nominationLoadData: any = [];
    @Loader
    @Catch(() => errorHandler('Could not load NominationLoad Data'))
    async getNominationLoadData(data) {
        const reqbody = {
            CustomerName: data.CustomerName,
            ContractNumber: data.ContractNumber,
            OriginID: data.OriginID,
            DestinationID: data.DestinationID,
            NominationMonth: data.NominationMonth,
            NominationYear: data.NominationYear,
            AX_Origin: data.OriginID === null ? data.AX_Origin : '',
            AX_Destination: data.DestinationID === null ? data.AX_Destination : '',
            ToCode: data.ToCode,
            Product: data.Product,
            DataSource: data.DataSource
        };
        const response = await nominationDataService.getNominationLoadData(reqbody);
        this.nominationLoadData = response.data['Data'].filter(element => {
            return element.CustomerName == data.CustomerName;
        });
        let count = 0;
        const loadDeleted: any = [];
        data.Pending = this.getSum(this.nominationLoadData, 'Pending');
        data.Dispatched = this.getSum(this.nominationLoadData, 'Dispatched');
        data.Completed = this.getSum(this.nominationLoadData, 'Completed');
        count = data.ApportionmentVolume ? parseInt(data.ApportionmentVolume) : 0;
        this.nominationLoadData.forEach((loaddata, ind) => {
            loaddata.IsChild = true;
            loaddata.IsSelected = false;
            loaddata.IsLoad = true;
            loaddata.IsInvalid = false;
            loaddata.CurrentNomination = null;
            loaddata.DataSource = data.DataSource;
            loaddata.Id = parseInt(ind) + 1;
            loaddata.Pending = loaddata.Pending ? loaddata.Pending : 0.0;
            loaddata.Dispatched = loaddata.Dispatched ? loaddata.Dispatched : 0.0;
            loaddata.Completed = loaddata.Completed ? loaddata.Completed : 0.0;
        });
        const load = toJS(data);
        this.nominationLoadData.unshift(load);
        this.nominationLoadData = nominationStore.orderedLoadDataBasedOnStatus(this.nominationLoadData);
        nominationStore.nominationLoadData = this.nominationLoadData;
        nominationStore.setBackupnominationData(this.nominationLoadData);
        nominationStore.navigated = true;
        nominationStore.isLoadPopupVisible = true;
    }
    setPopup() {
        nominationStore.isLoadPopupVisible = true;
    }
    getSumLoad(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && x['IsLoad'] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }

    getSumTOV(data) {
        let count = 0;
        data.forEach(x => {
            if (x['IsLoad'] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x['Pending'] + x['Dispatched'] + x['Completed'];
            }
        });
        if (count !== 0) {
            return count.toFixed(3);
        } else {
            return 0.0;
        }
    }
    getSum(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }
    handleRadioButtonChange() {
        this.setState({ value: !this.props.value });
        console.log('Row Index' + JSON.stringify(this.props.data));
        if (this.props.data) {
            let currentDate = new Date(new Date().setHours(0,0,0,0));
            let checkDate = new Date(this.props.data.NominationYear, this.props.data.NominationMonth - 2, 24)
            if (this.props.data && !this.props.data['IsNewOrigin'] && !this.props.data['IsNewDestination'] && currentDate >= checkDate) {
                nominationStore.navigated = true;
                nominationStore.selected = true;
                this.getNominationLoadData(this.props.data);
            }
            if (this.props.data['IsNewOrigin'] || this.props.data['IsNewDestination']) {
                errorHandler('Please map the location to AX to view the loads');
            }
            if (currentDate < checkDate) {
                errorHandler('Loads will be generated only from 24th of the previous month');
            }
        } else nominationStore.getCustomerGroupSelection(this.props.node.key);
    }

    render() {
        const { value } = this.state;
        const rowId = this.props.rowIndex;
        if (rowId === 0) {
            this.setState({ value: true });
        }
        return <input onClick={this.handleRadioButtonChange} type="radio" checked={this.props.value} name="pdf" />;
    }
}
