import { isEmpty } from "lodash";
import { isNullOrUndefined } from "util";

export class CommonUtils {
    static toFixed(value, precision = 0): string {
        let result = '';
        const power = Math.pow(10, precision);
        const absValue = Math.abs(Math.round(value * power));
        result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

        if (precision > 0) {
            const fraction = String(absValue % power),
                padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
            result += '.' + padding + fraction;
        }
        return result;
    }

    static groupBy<T>(list: T[], keyGetter) {
        const map = new Map<string, T[]>();
        list.forEach(item => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    static getQueryStringParam(param: any) {
        var url = window.location.toString();
        url.match(/\?(.+)$/);
        var params = RegExp.$1;
        let params2 = params.split('&');
        var queryStringList = {};
        for (var i = 0; i < params2.length; i++) {
            var tmp = params2[i].split('=');
            queryStringList[tmp[0]] = unescape(tmp[1]);
        }
        return queryStringList[param];
    }

    static getArrayFromNumber(num: number, startFromZero = false) {
        return new Array(num)
            .join()
            .split(',')
            .map(function (item, index) {
                return !startFromZero ? index : ++index;
            });
    }

    static validateEmail(elementValue) {
        var emailPattern = /^([a-zA-Z0-9_\-\.]+)@+([a-zA-Z][a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{1,10})$/;
        return emailPattern.test(elementValue);
    }

    static validateMultipleEmail(elementValue) {
        var emailPattern = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        return emailPattern.test(elementValue);
    }

    static dictionaryToObj(dict: any) {
        const optionlist: { id: number; ID: number; Text: string; label: string }[] = [];
        for (const key in dict) {
            const obj = { ID: 0, id: 0, Text: '', label: '' };
            obj.ID = (key as unknown) as number;
            obj.id = (key as unknown) as number;
            obj.Text = dict[key];
            obj.label = dict[key];
            optionlist.push(obj);
        }
        return optionlist;
    }

    static getPublicUrl(): string {
        return process.env.PUBLIC_URL;
    }

    static getCellPhoneRegex() {
        return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    }

    static alphanumeric(value, key) {
        const regWithSpace = /^[a-zA-Z0-9 ]+$/;
        if (regWithSpace.test(value)) {
            return '';
        }
        else {
            return key;
        }
    }


    static checkFieldMaxLength(len: number, field?: string,): boolean {
        return field == undefined ? false : field?.length >= len;
    }

    static checkIfFieldIsNullOrEmpty(field: string): boolean {
        return (field == '') || isEmpty(field) || isNullOrUndefined(field) || field.trim() === ''
    }

    static checkIfFieldIsNotEmpty(field: string): boolean {
        return (!isEmpty(field) && !isNullOrUndefined(field) && field.trim() !== '')
    }

    static sortArrayBasedOnDateField(array: any[]) {
        array.sort(function (a, b) {
            const dateA = a.ModifiedDate ?? a.CreatedDate;
            const dateB = b.ModifiedDate ?? b.CreatedDate;
            // to get a value that is either negative, positive, or zero.
            return new Date(dateB).getTime() - new Date(dateA).getTime();
        });
    }

    static checkForAlphaNumeric(field: string) {
        // check for letters and digits
        return field.match(/^[a-zA-Z0-9 ]+$/i) !== null;
    }

    static checkForPhoneNumberSyntax(field: string) {
        const phoneRegex = this.getCellPhoneRegex();
        return field.match(phoneRegex) !== null;
    }

    static checkForLoad(field: string) {
        return field.match(/^[0-9]*\.?[0-9]*$/) !== null;
    }

}
