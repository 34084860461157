import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';
import { scheduledReportStore } from '../stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class ScheduledReportRadioCellRenderer extends PureComponent<
    ICellRendererParams,
    AlphaNumericCellRendererState,
    {}
> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }

    handleRadioButtonChange() {
        this.setState({ value: !this.props.value });
        scheduledReportStore.handleRadioSelection(this.props.data);
    }

    render() {
        const { value } = this.state;
        const rowId = this.props.rowIndex;
        if (rowId === 0) {
            this.setState({ value: true });
        }
        return <input disabled = {!dataStore.checkOperationAccess('Save')} onChange={this.handleRadioButtonChange} type="radio" checked={this.props.value} name="pdf" />;
    }
}
