import { action, observable, computed, toJS } from 'mobx';
import _ from 'lodash';
import Loader from '../../../shared/decorators/loader-decorator';
import { RejectedLoadResponse, RejectedDateRange } from '../domains/model';
import Catch from '../../../shared/decorators/catch-decorator';
import { RejectedLoadDataService } from '../services/rejected-load-data-service';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { rejectedLoadColDef } from '../domains/rejected-loads-col-defs';
import moment from 'moment';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { ReportMessage } from '../domains/enum';
import { AccountStore } from 'features/account/stores/account-store';
import { RejectedLoadsStatus } from '../reports_constants';
import { localStorageService } from 'shared/services/local-storage-service';

export class RejectedLoadDataStore {
    @observable rejectedLoad: RejectedLoadResponse[] = [];
    backupRejectedLoad: RejectedLoadResponse[] = [];
    from: any = moment().toDate();
    to: any = moment().toDate();
    toDate: any;
    fromDate: any;
    startDate: any = this.from.toISOString().slice(0, 10);
    endDate: any = this.to.toISOString().slice(0, 10);
    start: any;
    end: any;
    isRefreshed: boolean = false;
    @observable userInfo: any = {};
    @observable selectedStatus: any = [{ value: 'all', label: 'All'}]
    @observable clearSelection: boolean = false;

    constructor(private rejectedLoadDataService: RejectedLoadDataService, public agGridService: AgGridService, private accountStore: AccountStore) {}

    init(): void {
        this.loadViewModel();
    }
    getColDef() {
        return rejectedLoadColDef;
    }

    isDisabled = (): boolean => {
        if (this.rejectedLoad.length == 0) {
            return true;
        } else {
            return false;
        }
    };
    
    @computed
    get DuplicateList(): RejectedLoadResponse[] {
        return toJS(this.rejectedLoad);
    }
    
    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.REJECTED_ERROR_MESSAGE);
    })
    async loadViewModel(): Promise<void> {
        let dropdown: any = localStorageService.get('RejectedLoads_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedStatus = dropdown[0].TicketStatus
        } else {
            localStorageService.set('RejectedLoads_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }]}])
            this.selectedStatus = [{ value: 'all', label: 'All' }];
        }
        this.userInfo = this.accountStore.getUserInfo();
        const rejectedLoads = await this.rejectedLoadDataService.getRejectedLoadSP({CC: this.userInfo.carrierCompanyId});
        this.rejectedLoad = rejectedLoads;
        let status: any = Array.from(new Set(this.selectedStatus.map((item: any) => item.value)));
        this.setRejectedLoads(this.rejectedLoad);
        this.setBackupRejectedLoads(rejectedLoads);
        this.statusFiltered(status)
        this.start = '';
        this.end = '';
        console.log('RESPONSE from loadViewModel' + JSON.stringify(this.rejectedLoad));
        this.isRefreshed = false;
        
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.REJECTED_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        let dropdown: any = localStorageService.get('RejectedLoads_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedStatus = dropdown[0].TicketStatus
        } else {
            localStorageService.set('RejectedLoads_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }]}])
            this.selectedStatus = [{ value: 'all', label: 'All' }];
        }
        this.start = startDate;
        this.end = endDate;
        const reqbody: RejectedDateRange = {
            StartDate: startDate,
            EndDate: endDate,
            CC: this.userInfo.carrierCompanyId
        };
        const rejectedLoads = await this.rejectedLoadDataService.getRejectedLoadSPwithParams(reqbody);
        let status: any = Array.from(new Set(this.selectedStatus.map((item: any) => item.value)));
        this.rejectedLoad = rejectedLoads;
        this.setRejectedLoads(rejectedLoads);
        this.setBackupRejectedLoads(rejectedLoads);
        this.statusFiltered(status)
        console.log('RESPONSE from getRangeResult' + JSON.stringify(this.rejectedLoad));
        this.isRefreshed = false;
    }

    statusFiltered(statusList) {
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(RejectedLoadsStatus.map((item: any) => item.value)));
        }
        let filterStatus: any = (statusList.length == 1 && statusList.includes('all')) ? ['Rejected By Driver', 'Cancelled By Driver', 'Rejected By Carrier', 'Cancelled By Carrier'] : statusList; 
        const rejectedLoads: any = this.backupRejectedLoad.filter(element => filterStatus.includes(element.LoadStatus) )
        this.rejectedLoad = rejectedLoads;
    }

    @action
    setRejectedLoads(data: RejectedLoadResponse[]): void {
        this.rejectedLoad = data;
    }
    @action
    setBackupRejectedLoads(data: RejectedLoadResponse[]): void {
        this.backupRejectedLoad = data;
    }
}
