export const PSA_DOWNLOAD_INSTRUCTION = 'Download the Plains Scheduling App(Non Plains user) Registration Instructions guide by';
export const PTS_DOWNLOAD_INSTRUCTION = 'Download the Plains Trucking Portal(Plains User) Registration Instructions guide by';

export const PLAINSUSEREMAILADDRESS = '@plainsmidstream.com';


export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const PENDING = 'PENDING';
export const AWAITING_ACTIVATION = 'AWAITINGACTIVATION';
export const NON_PLAINS = 'Non-Plains';
export const PLAINS = 'Plains';
export const DRIVER = 'Driver';
export const LEASEOPERATOR = 'Lease Operator';
export const OWNEROPERATOR = 'Owner Operator';
export const OWNER = 'Owners';
export const CARRIERCOMPANY = 'Carrier Company';
export const DISPATCHER = 'Dispatcher';
export const USER_LOADING_ERROR = 'Error fetching user details';
export const EMAIL_VALIDATION_ERROR = 'Error validating user email';
export const SIGNUP_ERROR = 'Error signing up user';
export const DUPLICATE_ERROR = 'Email Address already exists';
export const STATUS_UPDATE_ERROR = 'Error verifying email address';
export const USER_APPROVAL_CONFIRMATION = 'Are you sure you want to approve this user?';
export const USER_APPROVAL_SUCCESS = 'User Approved successfully';
export const USER_DISAPPROVAL_SUCCESS = 'User Rejected successfully';
export const USER_APPROVAL_FAIL = 'User Record(s) Saved failed';
export const ADMIN = 'Admin';