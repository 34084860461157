import React, { Component } from 'react';
import { Document, Page, Text,Image, View, StyleSheet } from '@react-pdf/renderer';
import { printDisclaimers, printLabels } from '../../operations-portal/constants/ticket-management-constant';
import { TicketVolOrHourly } from '../../operations-portal/constants/ticket-enum';
import logo from '../../../assets/pmc-logo.png'
import { dateUtils } from 'shared/services/date-utils';
import { commaFormat } from '../reports_constants';
import { CommonUtils } from 'shared/services/common-utils';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    section: {
        margin: 10,
        padding: 15,
        flexGrow: 1
    },
    pdfContainer: {
        fontSize: 9,
        fontWeight: 'light',
        padding: 0,
        marginTop: 20,
        marginLeft: 50,
        marginRight: 50
    },
    logo:{
        maxHeight: 50,
        width: 180,
        marginLeft: 150,
        paddingBottom: 3,
        paddingTop: 3,
    },
    detailsFooter: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5
    },
    detailsFooterWithNoPadding: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5
    },
    combinedRows: {
        display: 'flex',
        flexDirection: 'column',
        padding: 3
    },
    printViewContainer: {
        justifyContent: 'space-between',
        paddingBottom: 6
    },
    content: {
        minWidth: 220,
        maxWidth: 220,
        border: 1,
        borderStyle: 'solid',
        padding: 3
    },
    contentright: {
        fontWeight: 'light',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        border: 1,
        borderStyle: 'solid',
        padding: 3,
    },
    printViewAddress: {
        alignContent: 'flex-start',
        fontWeight: 'light',
        paddingBottom: 2,
        paddingTop: 2,
        paddingLeft: 5
    },
    disclaimerText: {
        fontWeight: 'light',
        maxWidth: 440,
        paddingLeft: 5,
        paddingTop: 5,
    },
    contentStart: {
        fontWeight: 'light',
        paddingRight: 50,
        minWidth: 130,
        maxWidth: 130,
        border: 1,
        borderRadius: 1,
        padding: 3,
        paddingLeft: 5
    },
    contentOne: {
        fontWeight: 'light',
        paddingRight: 50,
        minWidth: 110,
        border: 1,
        borderRadius: 1,
        padding: 3,
    },
    contentTwo: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 110,
        minWidth: 110,
        border: 1,
        borderRadius: 1,
        flex: 1,
        flexDirection: 'row',
        padding: 3,
    },
    contentThree: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 120,
        border: 1,
        borderRadius: 1,
        flex: 1,
        flexDirection: 'row',
        padding: 3,
        paddingLeft: 5
    },
    contentFour: {
        fontWeight: 'light',
        maxWidth: 100,
        border: 1,
        borderRadius: 1,
        flex: 1,
        padding: 3,
        flexDirection: 'row',
        paddingLeft: 5
    },
    contentEnd: {
        fontWeight: 'light',
        maxWidth: 133,
        minWidth: 133,
        width: 133,
        border: 1,
        borderRadius: 1,
        padding: 3,
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 5
    },
    contentMiddle: {
        fontWeight: 'light',
        paddingRight: 50,
        maxWidth: 177,
        minWidth: 177,
        border: 1,
        borderRadius: 1,
        padding: 3,
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 5
    },
});
interface PDFComponentProps {
    selectedTickets: any;
}

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};
const phoneFormat = phoneNumber => {
    return phoneNumber!= null && phoneNumber.toString().replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3")
}
export class MyDocument extends Component<PDFComponentProps> {
    constructor(props: Readonly<PDFComponentProps>) {
        super(props);
    }
    render(): React.ReactNode {
        const { selectedTickets } = this.props
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.pdfContainer}>
                        <View>
                            <View style={styles.detailsFooter}>
                                <View>
                                    <Text style={styles.printViewAddress}>{printLabels.COMPANY_NAME}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.ADDRESS_LINE_1}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.ADDRESS_LINE_2}</Text>
                                    <Text style={styles.printViewAddress}>{printLabels.PHONE_NUMBER}</Text>
                                </View>
                                <View style={styles.logo}>
                                    <Image src={logo} />
                                </View>
                            </View>
                        </View>
                        {selectedTickets && selectedTickets.TicketType === TicketVolOrHourly.HOURLY ? (
                            <View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.TICKET} : {selectedTickets?.TicketNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.RELATED_VOLUME_TICKET} : {selectedTickets?.RelatedVolumeTicket}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CARRIER} : {selectedTickets?.LeaseOperatorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.DRIVER} : {selectedTickets?.DriverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR} : {selectedTickets?.Origin}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNEE} : {selectedTickets?.Destination}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNOR_FIELD} : {selectedTickets?.ConsignorField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNOR_LSD} : {selectedTickets?.ConsignorLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ID_NAME} : {(selectedTickets?.Consignor !== selectedTickets?.Origin || selectedTickets?.Consignor ===  'New Location')?  selectedTickets?.Consignor : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNEE_FIELD} : {selectedTickets?.ConsigneeField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNEE_LSD} : {selectedTickets?.ConsigneeLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNEE_ID_NAME} : {(selectedTickets?.Consignee !== selectedTickets?.Destination || selectedTickets?.Consignee === 'New Location') ? selectedTickets?.Consignee : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.TRAILER_NUMBER} : {selectedTickets?.TrailerNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.AXLES} : {selectedTickets?.Axles}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.TRAILER_MILEAGE} : {selectedTickets?.TrailerMileage}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.UNITS} : {selectedTickets?.UomID}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_24_PHONE} : {phoneFormat(selectedTickets?.Consignor24Phone)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_NAME} : {selectedTickets?.ConsignorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ADDRESS} : {selectedTickets?.ConsignorAddress}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.SHIPPER_TICKET_NUMBER} : {selectedTickets?.ShipperTicketBOL}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.TRACTOR_IDENTIFIER} : {selectedTickets?.PowerUnitIdentifier}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.START_DATE} : {dateUtils.getFormattedDateTime(selectedTickets?.StartDate)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.END_DATE} : {selectedTickets?.EndDate != null ? dateUtils.getFormattedDateTime(selectedTickets?.EndDate) : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.ESTIMATED_VOLUME} : {commaFormat(roundOff(selectedTickets?.EstimatedVolume))}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.ACTUAL_VOLUME} : {commaFormat(roundOff(selectedTickets?.VolumeDelivered))}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PLAN_NUMBER} : {selectedTickets?.ConsignorERPPlanNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PHONE_NUMBER} : {phoneFormat(selectedTickets?.ConsignorERPPhoneNumber)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.PRODUCT_LOADED} : {selectedTickets?.Product}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.HAZARD_ID} : {selectedTickets?.HazardID}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.WORK_TIME} : {commaFormat(selectedTickets?.WorkingTime)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.WORKING_TIME_REASON} : {selectedTickets?.WorkingTimeReason}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.WAIT_TIME} : {commaFormat(selectedTickets?.WaitingTime)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.WAITING_TIME_REASON} : {selectedTickets?.WaitingTimeReason}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.EMPTY_RES} : {selectedTickets?.EmptyResidueLastContained}

                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.COMMENTS} : {selectedTickets?.Comments}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CREATED} : {dateUtils.getFormattedDateTime(selectedTickets?.TicketDate)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CREATED_BY} : {selectedTickets?.DriverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.INQUIRIES} : {selectedTickets?.InquiryID}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.STATUS} : {selectedTickets?.TicketStatus}
                                    </Text>
                                </View>
                            </View>) : (<View>
                                {/********* * Volume ticket pdf format *****************************************/}
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.TICKET} : {selectedTickets?.TicketNumber}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.STATUS} : {selectedTickets?.TicketStatus == 'Assigned' ? 'Dispatched' : selectedTickets?.TicketStatus}{'\n'}
                                        {selectedTickets?.TicketStatus == 'Completed' && (printLabels.COMPLETEDTIME + ' : ' + dateUtils.getFormattedDateTimeLocal(selectedTickets?.CompletedDate))} 
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.RUN_SCALE_TICKET_NUMBER} : {selectedTickets?.RunTickeScaleTicketNumber}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CARRIER} : {selectedTickets?.LeaseOperatorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.DRIVER} : {selectedTickets?.DriverName}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR} : {selectedTickets?.Origin}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNEE} : {selectedTickets?.Destination}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNOR_FIELD} : {selectedTickets?.ConsignorField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNOR_LSD} : {selectedTickets?.ConsignorLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ID_NAME} : {(selectedTickets?.Consignor !== selectedTickets?.Origin || selectedTickets?.Consignor ===  'New Location')?  selectedTickets?.Consignor : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.CONSIGNEE_FIELD} : {selectedTickets?.ConsigneeField}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.CONSIGNEE_LSD} : {selectedTickets?.ConsigneeLSD}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNEE_ID_NAME} : {(selectedTickets?.Consignee !== selectedTickets?.Destination || selectedTickets?.Consignee ===  'New Location')?  selectedTickets?.Consignee : ''}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PLAN_NUMBER} : {selectedTickets?.ConsignorERPPlanNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ERP_PHONE_NUMBER} : {phoneFormat(selectedTickets?.ConsignorERPPhoneNumber)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.TRAILER_NUMBER} : {selectedTickets?.TrailerNumber}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.AXLES} : {selectedTickets?.Axles}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                            {' '}
                                            {printLabels.TRAILER_MILEAGE} : {selectedTickets?.TrailerMileage}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.UNITS} : {selectedTickets?.UomID}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.CONSIGNOR_24_PHONE} : {phoneFormat(selectedTickets?.Consignor24Phone)}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.PRODUCT_LOADED} : {selectedTickets?.Product}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.COMMENTS} : {selectedTickets?.Comments}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CONSIGNOR_NAME} : {selectedTickets?.ConsignorName}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CONSIGNOR_ADDRESS} : {selectedTickets?.ConsignorAddress}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.SHIPPER_TICKET_NUMBER} : {selectedTickets?.ShipperTicketBOL}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.TRACTOR_IDENTIFIER} : {selectedTickets?.PowerUnitIdentifier}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.ESTIMATED_VOLUME} : {commaFormat(roundOff(selectedTickets?.EstimatedVolume))}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.ACTUAL_VOLUME} : {commaFormat(roundOff(selectedTickets?.VolumeDelivered))}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentOne}>
                                        {' '}
                                        {printLabels.LOAD_TEMP} : {selectedTickets?.LoadTemperature}
                                    </Text>
                                    <Text style={styles.contentTwo}>
                                        {' '}
                                        {printLabels.LOAD_TIME} : {selectedTickets?.LoadDateTime != null ? dateUtils.getFormattedDateTime(selectedTickets?.LoadDateTime) : ''}
                                    </Text>
                                    <Text style={styles.contentThree}>
                                        {' '}
                                        {printLabels.UNLOAD_TEMP} : {selectedTickets?.UnLoadTemperature}
                                    </Text>
                                    <Text style={styles.contentFour}>
                                        {' '}
                                        {printLabels.GROSSWEIGHT} : {selectedTickets?.GrossWeight}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentOne}>
                                        {' '}
                                        {printLabels.ROAD_VIA} : {selectedTickets?.Via}
                                    </Text>
                                    <Text style={styles.contentTwo}>
                                        {' '}
                                        {printLabels.ROAD_BAN} : {selectedTickets?.RoadBan}
                                    </Text>
                                    <Text style={styles.contentThree}>
                                        {' '}
                                        {printLabels.DENSITY} : {selectedTickets?.Density}
                                    </Text>
                                    <Text style={styles.contentFour}>
                                        {' '}
                                        {printLabels.B_S_W} : {selectedTickets?.BSW}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.HAZARD_ID} : {selectedTickets?.HazardID}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.INQUIRIES} : {selectedTickets?.InquiryID}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.SPLIT_LOAD} : {(selectedTickets?.SplitLoad !== null ? selectedTickets?.SplitLoad === true ? 'True' : 'False' : '')}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.GROSS_VOLUME} : {selectedTickets?.GrossVolume}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.TANK_HEIGHT} : {selectedTickets?.TankHeight}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TARE_WEIGHT} : {selectedTickets?.TareWeight}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.OPEN_METER_READING} : {selectedTickets?.OpenMeterReading}
                                    </Text>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CLOSE_METER_READING} : {selectedTickets?.CloseMeterReading}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.METER_NUMBER} : {selectedTickets?.MeterNumber}
                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.METER_FACTOR} : {selectedTickets?.MeterFactor}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TANK_NUMBER} : {selectedTickets?.TankNumber}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.contentStart}>
                                        {' '}
                                        {printLabels.EMPTY_RES} : {selectedTickets?.EmptyResidueLastContained}

                                    </Text>
                                    <Text style={styles.contentMiddle}>
                                        {' '}
                                        {printLabels.NET_WEIGHT} : {selectedTickets?.NetWeight}
                                    </Text>
                                    <Text style={styles.contentEnd}>
                                        {' '}
                                        {printLabels.TANK_SIZE} : {selectedTickets?.TankSize}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.OBS_TEMP} : {selectedTickets?.ObsTemp}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.OBS_GRAVITY} : {selectedTickets?.ObsGravity}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooterWithNoPadding}>
                                    <Text style={styles.content}>
                                        {' '}
                                        {printLabels.CREATED} : {dateUtils.getFormattedDateTime(selectedTickets?.TicketDate)}
                                    </Text>
                                    <Text style={styles.contentright}>
                                        {' '}
                                        {printLabels.CREATED_BY} : {selectedTickets?.DriverName}
                                    </Text>
                                </View>


                            </View>)}
                        <View>
                            <Text style={styles.disclaimerText}>{printDisclaimers.accuracyDisclaimer}</Text>
                        </View>
                        <View>
                            <Text style={styles.printViewAddress}>{printLabels.CONSIGNOR_NAME} -</Text>
                            <Text style={styles.printViewAddress}>{printLabels.AUTHORIZED_BY_PRINT} -</Text>
                            <Text style={styles.printViewAddress}>{printLabels.AUTHORIZED_BY_SIGN} -</Text>
                            <Text style={styles.printViewAddress}>
                                {printLabels.DRIVER_SIGNATURE} {': '}
                                {'__________________________'}
                            </Text>
                        </View>
                        <View>
                            <Text style={styles.disclaimerText}>{printDisclaimers.regulationsDisclaimer}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

}
