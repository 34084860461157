import { TrailerManagementStatus } from './enum';

let stateTransitions = new Map();
stateTransitions.set(TrailerManagementStatus.ACTIVE, [
    TrailerManagementStatus.ACTIVE,
    TrailerManagementStatus.INACTIVE_PARKED,
    TrailerManagementStatus.INACTIVE_PARKEDOUTOF
]);
stateTransitions.set(TrailerManagementStatus.INACTIVE, [
    TrailerManagementStatus.INACTIVE,
]);
stateTransitions.set(TrailerManagementStatus.INACTIVE_INSHOP, [
    TrailerManagementStatus.INACTIVE_INSHOP
]);
stateTransitions.set(TrailerManagementStatus.INACTIVE_PARKED, [
    TrailerManagementStatus.INACTIVE_PARKED,
    TrailerManagementStatus.ACTIVE,
    TrailerManagementStatus.INACTIVE_PARKEDOUTOF
]);
stateTransitions.set(TrailerManagementStatus.ACTIVE_PAPERWORK, [
    TrailerManagementStatus.ACTIVE_PAPERWORK
]);
stateTransitions.set(TrailerManagementStatus.INACTIVE_PARKEDOUTOF, [
    TrailerManagementStatus.INACTIVE_PARKEDOUTOF,
    TrailerManagementStatus.INACTIVE_PARKED,
    TrailerManagementStatus.ACTIVE,
]);

export const transact = (prevState: string, currentState: string): boolean => {
    let canMoveFromPrevToCurrenttate = false;
    for (let [key, value] of stateTransitions) {
        if (key == prevState) {
            canMoveFromPrevToCurrenttate = value.includes(currentState);
            break;
        }
    }
    return canMoveFromPrevToCurrenttate;
};
