import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../common/stores/data-store';
import { ReactSelect } from 'shared/components/select/select';
import './_maintenance-data.scss';
import { componentMapper } from '../../maintenance-portal/components/maintenance-data-mapper';
import { dataTypeOptions } from '../maintenance_portal_constant';
import { dataStore } from '../../common/stores/index';
import { PathDataTypeMap } from 'shared/static/data';
import history from '../../../shared/services/history';

interface MaintenanceDataComponentProps {
    dataStore: DataStore;
}

@inject('dataStore')
@observer
export class MaintenanceDataComponent extends Component<MaintenanceDataComponentProps> {
    dataTypeOptionsFiltered: any;
    constructor(props) {
        super(props);
        this.dataTypeOptionsFiltered = dataStore.configureDataTypeOptionsByRole(dataTypeOptions);
        this.props.dataStore.init(this.dataTypeOptionsFiltered);
    }

    componentWillUnmount() {
        this.props.dataStore.reset();
    }

    render(): React.ReactNode {
        const {
            dataStore: { dataTypes }
        } = this.props;
        return (
            <Container fluid={true}>
                {(dataTypes.length > 1) && (
                    <Row className="mt-3">
                        <Col>{this.renderSelectedComponent()}</Col>
                    </Row>)}
                <Row className="mt-3">
                    <Col>{this.renderGrid()}</Col>
                </Row>
            </Container>
        );
    }

    renderSelectedComponent(): React.ReactNode {
        const { selectedDataType, dataTypes } = this.props.dataStore;
        const hasViewAccessTailerMaintainance = dataStore.checkOperationAccessWithModule('View', 'TrailerBookingMaintenance');
        let filteredDataTypes = dataTypes;
        if (!hasViewAccessTailerMaintainance) {
            filteredDataTypes = filteredDataTypes.filter(function (ele) { return ele.value !== 'TrailerBookingMaintenance'; });
        }
        let selectedValue: any = {};
        const selectedPathMapping = PathDataTypeMap.filter(
            x => x.path.toLowerCase() === history.location.pathname.toLowerCase()
        );
        if (selectedPathMapping[0]) {
            selectedValue = dataTypeOptions.filter(x => x.value === selectedPathMapping[0].dataTypeValue)[0];
        } else {
            selectedValue = selectedDataType;
        }
        return (
            <form className="form-inline">
                <label className="maintenance-data__label">Select Type</label>
                <div className="form-group maintenance-data__type">
                    <ReactSelect
                        values={selectedValue}
                        placeholder="SelectType"
                        options={filteredDataTypes}
                        onChange={this.handleDataTypeChange}
                        isMulti={false}
                    />
                </div>
            </form>
        );
    }

    renderGrid(): React.ReactNode {
        const { selectedDataType } = this.props.dataStore;
        let selectedValue = '';
        const selectedPathMapping = PathDataTypeMap.filter(
            x => x.path.toLowerCase() === history.location.pathname.toLowerCase()
        );
        if (selectedPathMapping[0]) {
            selectedValue = selectedPathMapping[0].dataTypeValue;
        } else {
            selectedValue = selectedDataType.value;
        }
        const selectedComponent = componentMapper.get(selectedValue);
        if (selectedComponent) return selectedComponent();
    }

    handleDataTypeChange = option => {
        const selectedPath = PathDataTypeMap.filter(x => x.dataTypeValue.toLowerCase() === option.value.toLowerCase());
        if (selectedPath[0]) {
            history.push(selectedPath[0].path);
        } else {
            history.push('/maintenance');
        }
        this.props.dataStore.setSelectedDataType(option);
    };
}
