import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { dataStore } from '../../../common/stores/index';
import { userApprovalStore } from 'features/userApprovalFlow/stores';
import { Modal } from 'react-bootstrap';
import { CarrierFacilityComponent } from '../carrier-facility-component';
import { CarrierFacilityPopUpComponent } from '../carrier-facility-popup-component';
import { UserApprovalDataStore } from 'features/userApprovalFlow/stores/user-approval-data-store';
const handleCarrierEdit = () => {
    userApprovalStore.setCarriersModal(true)
};
export const MappedCarrierCellRenderer = (props: ICellRendererParams) => {
    const { value } = props;
    const hasEditAccess = dataStore.checkOperationAccess('UserInfoEdit');
    return (
        <div className="current-status">
            { hasEditAccess && (
                <div className="current-status__carrier" onClick={handleCarrierEdit}>
                    {value}
                </div>
            )}
            {!hasEditAccess && (<div className="current-status__carrier" >
                {value}
            </div>)}
        </div>
    );
};
