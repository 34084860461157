import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { MaintenanceType, MaintenanceStatus, ShopInfo } from '../model/model';
import { TrailerMaintenanceResponse } from '../model/trailerMaintenanceResponse';
import { TrailerMaintenanceDataService } from '../services/maintenance-data-service';
import { TrailerMaintenanceMessage, TrailerMaintenanceFieldName, TrailerMaintenanceStatus } from '../domains/enum';
import { maintenanceColDef, ASSINGED_SHOP, NOT_ASSIGNED_SHOP } from '../maintenance_portal_constant';
import moment from 'moment';
import { TrailerMaintenanceRequest } from '../model/trailerMaintenanceRequest';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { dataStore } from 'features/common/stores';
import _ from 'lodash';
import { DateRange } from '../../../shared/components/daypicketInput/react-daypickerInput';
import { dateUtils } from 'shared/services/date-utils';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { INVALID_USERID } from 'features/data_management/data_constant';

export class TrailerMaintenanceDataStore {
    @observable trailerMaintenanceInfo: TrailerMaintenanceResponse[] = [];
    backUpTrailerMaintenanceInfo: TrailerMaintenanceResponse[] = [];
    maintenanceStatuses: MaintenanceStatus[] = [];
    maintenanceTypes: MaintenanceType[] = [];
    @observable showModal = false;
    @observable showModalMaintenance = false;
    @observable shops: ShopInfo[] = [];
    @observable cellValueChangeMap = {};
    @observable filters: any = {};
    isApproverPopUpVisible = false;
    isRefreshed: boolean = false;
    approvedCommentTicketModel: TrailerMaintenanceResponse | undefined = undefined;
    startDate: any;
    endDate: any;
    constructor(
        private maintenanceDataService: TrailerMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.loadViewModel();
        this.getShops();
    }

    getColDef() {
        this.updateCommentColDef();
        this.updateBookedDateDef();
        return maintenanceColDef;
    }

    updateBookedDateDef = () => {
        const bookedColDef = maintenanceColDef.find(x => x.colId === TrailerMaintenanceFieldName.BOOKED);
        if (bookedColDef) {
            bookedColDef.cellRendererParams = {
                onChange: (item, value) => this.updateBookedDate(item, value, TrailerMaintenanceFieldName.BOOKED),
                isDisabled: (item: TrailerMaintenanceResponse, value) =>
                (item.StatusID !== 23 && item.StatusID !== 24) ||
                    !dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
            };
        }
    };
   
    updateCommentColDef = () => {
        const commentColDef = maintenanceColDef.find(x => x.colId === TrailerMaintenanceFieldName.COMMENT);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateTrailerMaintenanceComment(item, value, TrailerMaintenanceFieldName.COMMENT),
                isDisabled: (item: TrailerMaintenanceResponse, value) =>
                    !dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
            };
        }
    };

    @action
    updateTrailerMaintenanceComment(item: TrailerMaintenanceResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item.Comments, value);
        if (popup && item[key] != null) item[key] = item[key] + '\n' + value;
        else item[key] = value;
        this.updateRow(item);
    }

    @action
    updateBookedDate(item: TrailerMaintenanceResponse, value: string, key: string) {
      let timeToSendToApi = dateUtils.getApiDateFormat(value)
        this.setValueInChangeMap(item.ID, key, String(item.Booked), timeToSendToApi);
        item[key] = timeToSendToApi;
        this.updateRow(item);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue)) {
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        } else {
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
        }
    }

    getAllFilters = () => {
        this.filters = this.agGridService.getAllFilters();
    };

    setAllFilters = () => {
        this.agGridService.setAllFilters(this.filters);
    };

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setShowModalMaintenance(): void {
        this.showModalMaintenance = !this.showModalMaintenance;
    }

    @action
    @Catch(() => errorHandler(TrailerMaintenanceMessage.ERROR_LOADING_SHOPS))
    async getShops(): Promise<void> {
        await this.maintenanceDataService.getShops().then(response => {
            this.shops = response;
        });
    }

    @Loader
    @Catch(() => errorHandler(TrailerMaintenanceMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        if(!this.isRefreshed) this.trailerMaintenanceInfo = [];
        this.uiService.loaderService.showLoader();
        const {
            trailerMaintenanceInfo,
            maintenanceTypes,
            maintenanceStatuses
        } = await this.maintenanceDataService.getTrailerMaintenanceViewModel();
        const maintenances: TrailerMaintenanceResponse[] = trailerMaintenanceInfo['Data'].filter(a => {
            return a.InspectionTicketNumber !== 'initialValue';
        });
        const maintenanceStatusesData = maintenanceStatuses['Data'];
        this.maintenanceStatuses =  this.orderedTrailerMaintenanceStatusData(maintenanceStatusesData);
        this.maintenanceTypes = maintenanceTypes['Data'];
        this.startDate = '';
        this.endDate = '';
        this.populateNameFieldsBasedOnId(maintenances);
        this.setTrailerMaintenanceList(maintenances);
        this.setBackUpTrailerMaintenanceList(maintenances);
        this.addValuesInCellDropdowns();
        this.isRefreshed = false;
    }

    // Logical Ordering of the maintenance status.
    orderedTrailerMaintenanceStatusData(trailerStatusData: any[]) {
        const trailerStatusOrder = ['Booked', 'Shop Assigned', 'In Maintenance', 'Maintenance Complete', 'Pending Paperwork', 'Parked', 'Parked Out Of Service', 'Cancelled',];
        const orderedTrailerStatusData =  [] as any;
        trailerStatusOrder.forEach((item) => {
            const status = trailerStatusData.filter((status) => status.Name === item)[0];
            if (status.ID == 25) {
                status.Name = TrailerMaintenanceStatus.INMAINTENANCE;
            }
            orderedTrailerStatusData.push(status);
        })
        return orderedTrailerStatusData;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(
            this.maintenanceTypes,
            TrailerMaintenanceFieldName.MAINTENANCETYPE,
            'Name'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.maintenanceStatuses,
            TrailerMaintenanceFieldName.STATUS,
            'Name'
        );
    }

    @computed
    get trailerMaintenanceList(): TrailerMaintenanceResponse[] {
        const userID = this.accountStore.getUserID();
        return toJS(this.trailerMaintenanceInfo);
    }

    @action
    setTrailerMaintenanceList(trailerMaintenanceInfo: TrailerMaintenanceResponse[]): void {
        const modified = trailerMaintenanceInfo.map(x => {
            x.StatusEditable =
                x.Status !== TrailerMaintenanceStatus.MAINTENANCECOMPLETE &&
                x.Status !== TrailerMaintenanceStatus.CANCELLED;
            return x;
        });
        this.trailerMaintenanceInfo = modified;
    }

    @action
    showTicketApproverPopUp() {
        this.isApproverPopUpVisible = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.isApproverPopUpVisible = false;
    }

    setBackUpTrailerMaintenanceList(maintenances: TrailerMaintenanceResponse[]) {
        this.backUpTrailerMaintenanceInfo = maintenances;
    }

    private populateNameFieldsBasedOnId(maintenances: TrailerMaintenanceResponse[]): void {
        this.populateMaintenanceTypeField(maintenances);
        this.populateMaintenanceStatusField(maintenances);
        this.populateAssignedShopField(maintenances);
    }

    private populateMaintenanceTypeField(maintenances: TrailerMaintenanceResponse[]): void {
        maintenances.forEach(item => {
            const maintenancetype = this.maintenanceTypes.find(a => a.ID == item.MaintenanceTypeID);
            if (maintenancetype) {
                item.MaintenanceType = maintenancetype.Name;
            }
        });
    }

    private populateMaintenanceStatusField(maintenances: TrailerMaintenanceResponse[]): void {
        maintenances.forEach(item => {
            const maintenanceStatus = this.maintenanceStatuses.find(a => a.ID == item.StatusID);
            if (maintenanceStatus) {
                item.Status = maintenanceStatus.Name;
            }
        });
    }

    private getStatusIDBasedOnStatusName(name: string): number | undefined {
        return this.maintenanceStatuses.find(a => a.Name == name)?.ID;
    }

    private getMaintenanceTypeIDBasedOnTypeName(name: string): number | undefined {
        return this.maintenanceTypes.find(a => a.Name == name)?.ID;
    }

    private populateAssignedShopField(maintenances: TrailerMaintenanceResponse[]): void {
        maintenances.forEach(item => {
            item.AssignedShop = item.ShopID ? ASSINGED_SHOP : NOT_ASSIGNED_SHOP;
        });
    }

    @action
    reset(): void {
        this.setTrailerMaintenanceList([]);
    }

    @action
    resetTrailerMaintenanceList(): void {
        this.setTrailerMaintenanceList(this.backUpTrailerMaintenanceInfo);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: TrailerMaintenanceResponse): void => {
        const status: any = [TrailerMaintenanceStatus.PARKED, TrailerMaintenanceStatus.PARKEDOUTOFSERVICE];
        this.trailerMaintenanceInfo.forEach(maintenance => {
            if (maintenance.ID === selectedRowData.ID) {
                maintenance.Booked = selectedRowData.Booked;
                if (!_.isEqual(maintenance, selectedRowData)) {
                    if (maintenance.Status != selectedRowData.Status && status.includes(selectedRowData.Status)) {
                        this.triggerModal(selectedRowData);
                    }
                    maintenance.StatusID =
                        this.getStatusIDBasedOnStatusName(selectedRowData.Status) ?? selectedRowData.StatusID;
                    maintenance.MaintenanceTypeID =
                        this.getMaintenanceTypeIDBasedOnTypeName(selectedRowData.MaintenanceType) ??
                        selectedRowData.MaintenanceTypeID;
                    maintenance.Comments = selectedRowData.Comments;
                    maintenance.MaintenanceType = selectedRowData.MaintenanceType;
                    maintenance.Status = selectedRowData.Status;
                }
            }
        });
    };

    triggerModal(item) {
        this.approvedCommentTicketModel = item;
        this.showTicketApproverPopUp();
    }

    updateComment(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateTrailerMaintenanceComment(
            this.approvedCommentTicketModel,
            approverComment,
            TrailerMaintenanceFieldName.COMMENT
        );
    }

    updateDate(updatedDate: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateBookedDate(this.approvedCommentTicketModel, updatedDate, TrailerMaintenanceFieldName.BOOKED);
    }

    @Loader
    @action
    @Catch(() => errorHandler(TrailerMaintenanceMessage.FAILED_SUBMIT))
    async updateTrailerMaintenances(): Promise<void> {
        this.getAllFilters();
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.trailerMaintenanceInfo.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateTrailerMaintenanceRequest(updatedRowIDs);
        if (requestBody.length == 0) {
            this.uiService.toastService.error(`Status cannot be changed from BOOKED to SHOP ASSIGNED
             without Shop Name selection. please select Shop Name before saving`);
             return;
        }
        const response = await this.maintenanceDataService.updateTrailerMaintenances(requestBody);
        if (response) {
            for (const key in response) {
                const trailerNumber = requestBody.filter(a => a.ID.toString() == key)[0].TrailerNumber;
                errorSummary = [...errorSummary, { id: trailerNumber, description: response[key] }];
            }
            this.uiService.toastService.error('', {}, errorSummary);
        }
        this.cellValueChangeMap = {};
        await this.getRangeResult(this.startDate, this.endDate);
        if (errorSummary.length == 0) {
            this.uiService.toastService.success(TrailerMaintenanceMessage.SAVE);
            this.setAllFilters();
        }
    }

    updateTrailerMaintenanceRequest(updatedRows: TrailerMaintenanceResponse[]): TrailerMaintenanceRequest[] {
        const userID = this.accountStore.getUserID();
        let requestList: TrailerMaintenanceRequest[] = [];
        //const currentDate = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm');
        const currentDate = ((moment().utc()).toISOString()).substring(0,19);
        updatedRows.forEach(item => {
   const { StatusID, MaintenanceTypeID, ID, ShopID, TrailerNumber, Comments, Booked,
                Acheck_Value, CVIP_Value, V_Value, K_Value, I_Value, P_Value, UC_Value, OTES_Value,
                Acheck, CVIP, V, K, I, P, UC, OTES, MaintenanceCompleteDate } = item;
            const cancelledStatusId = this.getStatusIDBasedOnStatusName(TrailerMaintenanceStatus.CANCELLED);
            const parkedId = this.getStatusIDBasedOnStatusName(TrailerMaintenanceStatus.PARKED);
            const parkedoutId = this.getStatusIDBasedOnStatusName(TrailerMaintenanceStatus.PARKEDOUTOFSERVICE);
            const shopAssignedId = this.getStatusIDBasedOnStatusName(TrailerMaintenanceStatus.SHOPASSIGNED);
            const currentShopID = this.cellValueChangeMap[ID]?.AssignedShop?.currentValue;
            const selectedShopId =
                cancelledStatusId !== StatusID && parkedId !== StatusID && parkedoutId !== StatusID
                    ? currentShopID
                        ? currentShopID
                        : ShopID
                    : null;
            if (shopAssignedId == StatusID && selectedShopId == 0) {
                return [];
            }
            requestList = [
                ...requestList,
                {
                    StatusUpdateDate: MaintenanceCompleteDate ? MaintenanceCompleteDate : currentDate,
                    TrailerNumber: TrailerNumber,
                    ModifiedDate: currentDate,
                    StatusID: StatusID,
                    MaintenanceTypeID: MaintenanceTypeID,
                    ID: ID,
                    ModifiedBy: userID,
                    ShopID: selectedShopId,
                    Comments: Comments,
                    Booked: String(Booked),
                    Acheck_Value: Acheck_Value,
                    CVIP_Value: CVIP_Value ,
                    V_Value: V_Value ,
                    K_Value: K_Value ,
                    I_Value: I_Value ,
                    P_Value: P_Value ,
                    UC_Value: UC_Value ,
                    OTES_Value: OTES_Value,
                    Acheck: Acheck != null ? Acheck : false,
                    CVIP: CVIP != null ? CVIP : false,
                    V: V != null ? V : false,
                    K: K != null ?  K : false,
                    I: I != null ?  I : false,
                    P: P != null ?  P : false,
                    UC: UC != null ? UC : false,
                    OTES: OTES != null ? OTES : false
                }
            ];
        });
        return requestList;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    isDisabled = (): boolean => {
        if (this.trailerMaintenanceInfo.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    @Loader
    @Catch(() => {
        errorHandler(TrailerMaintenanceMessage.FETCH_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        if(!this.isRefreshed) this.trailerMaintenanceInfo = [];
        const {
            trailerMaintenanceInfo,
            maintenanceTypes,
            maintenanceStatuses
        } = await this.maintenanceDataService.getTrailerMaintenanceViewModelWithDateParams(reqbody);

        const maintenances: TrailerMaintenanceResponse[] = trailerMaintenanceInfo['Data']
            ? trailerMaintenanceInfo['Data'].filter(a => {
                  return a.InspectionTicketNumber !== 'initialValue';
              })
            : [];
        const maintenanceStatusesData = maintenanceStatuses['Data'];
        this.maintenanceStatuses =  this.orderedTrailerMaintenanceStatusData(maintenanceStatusesData);
        this.maintenanceTypes = maintenanceTypes['Data'];
        this.populateNameFieldsBasedOnId(maintenances);
        this.setTrailerMaintenanceList(maintenances);
        this.setBackUpTrailerMaintenanceList(maintenances);
        this.addValuesInCellDropdowns();
        if (maintenances.length == 0) {
            errorHandler(TrailerMaintenanceMessage.NO_BOOKINGS);
        }
        this.isRefreshed = false;
    }

    saveDateRange(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
