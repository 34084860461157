import * as React from 'react';
import { RouteProps, RouteComponentProps, withRouter } from 'react-router';
import { AccountStore } from '../../features/account/stores/account-store';
import ErrorBoundary from './error-boundary/error-boundary';
import { Config } from 'config';
export interface ProtectedRouteProps extends RouteProps {
    accountStore: AccountStore;
    component: React.ComponentClass<any>;
    roles: string[];
}

export class ProtectedRouteComponent extends React.Component<ProtectedRouteProps & RouteComponentProps> {
    public render(): React.ReactNode {
        const { isLoggedIn } = this.props.accountStore;
        const { component: Component, roles, location, accountStore, history } = this.props;
        const isAccessAllowed = accountStore.isLoggedInUserHasRoleToAccessAView(roles);
        const role = accountStore.getRoleForAView(roles);
        const newProps = { role };
        if (!isLoggedIn) {
            const { pathname, search } = location;
            const path = {
                pathname: '/login',
                state: { from: { pathname, search } }
            };
            history.push(path);
            return null;
        }
        const userInfo = accountStore.getUserInfo();
        if(role && isLoggedIn && userInfo &&  (userInfo.isPSA && userInfo.userType != 'Dispatcher' && userInfo.userType != 'Supervisor' ) && !userInfo.isPTS){
            window.location.href = Config.psaWebUrl;
        }
        if (!isAccessAllowed) {
            return null;
        }
        return (
            <ErrorBoundary>
                <Component {...newProps} />
            </ErrorBoundary>
        );
    }
}

const ProtectedRoute = withRouter(ProtectedRouteComponent);
export default ProtectedRoute;
