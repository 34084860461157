import React from 'react';
import { Modal } from 'react-bootstrap';
import { TrailerResponse } from '../model/model';
import { DispatchPlanTrailerComponent } from './dispatchPlan-trailer-component';

interface DispatchPlanPopUpProps {
    onHide: () => void;
    show: boolean;
    trailerNumber?: any;
    selectedTrailerId: number;
    trailers: TrailerResponse[]
    onSelectionChange: (driverID: any) => void;
}

export const DispatchPlanTrailerPopupComponent = (props: DispatchPlanPopUpProps) => {
    const { trailerNumber, onHide, show, onSelectionChange, selectedTrailerId, trailers } = props;
    return (
        <Modal size="lg" onHide={onHide} backdrop= 'static' show={show} animation={false}>
            <Modal.Body>
                <DispatchPlanTrailerComponent
                    selectedTrailerId={selectedTrailerId}
                    trailerNumber={trailerNumber}
                    onHide={onHide}
                    trailers={trailers}
                    onSelectionChange={onSelectionChange}
                ></DispatchPlanTrailerComponent>
            </Modal.Body>
        </Modal>
    );
};