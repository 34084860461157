import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { InspectionHistoryHeaderName, InspectionHistoryFieldName } from '../model/inspection-history-enum';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from 'features/common/stores';
import { InspectionDetailCellRenderer } from '../components/cell-renderers/inspection-detail-cell-render';
import { inspectionHistoryStore } from '../stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { OTESHistoryCellRenderer } from '../components/cell-renderers/otes-cell-renderer';

export const inspectionHistoryColDef: ColDef[] = [
    {
        headerName: InspectionHistoryHeaderName.TICKETNUMBER,
        field: InspectionHistoryFieldName.TICKETNUMBER,
        width: 120,
        cellRendererFramework: InspectionDetailCellRenderer,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.OTESNO,
        field: InspectionHistoryFieldName.OTESNO,
        width: 125,
        cellRendererFramework: OTESHistoryCellRenderer,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.OTESNO] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.OTESNO];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.TRAILORNUMBER,
        field: InspectionHistoryFieldName.TRAILORNUMBER,
        width: 75,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.TRAILORNUMBER] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.TRAILORNUMBER];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.TRACTORNUMBER,
        field: InspectionHistoryFieldName.TRACTORNUMBER,
        width: 75,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.TRACTORNUMBER] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.TRACTORNUMBER];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.FIRSTNAME,
        field: InspectionHistoryFieldName.FIRSTNAME,
        colId: InspectionHistoryFieldName.FIRSTNAME,
        width: 90,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.FIRSTNAME] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.FIRSTNAME];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.LASTNAME,
        field: InspectionHistoryFieldName.LASTNAME,
        colId: InspectionHistoryFieldName.LASTNAME,
        width: 90,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.LASTNAME] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.LASTNAME];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MAINTENANCEREVIEW,
        field: InspectionHistoryFieldName.MAINTENANCEREVIEW,
        colId: InspectionHistoryFieldName.MAINTENANCEREVIEW,
        width: 100,
        cellEditor: 'agSelectCellEditor',
        editable: () => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MAINTENANCEREVIEW] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MAINTENANCEREVIEW];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.COMPLIANCEREVIEW,
        field: InspectionHistoryFieldName.COMPLIANCEREVIEW,
        colId: InspectionHistoryFieldName.COMPLIANCEREVIEW,
        width: 95,
        cellEditor: 'agSelectCellEditor',
        editable: () => (dataStore.checkOperationAccess('ComplianceReviewEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('ComplianceReviewEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.COMPLIANCEREVIEW] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.COMPLIANCEREVIEW];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MAINTENANCEREVIEWDATE,
        field: InspectionHistoryFieldName.MAINTENANCEREVIEWDATE,
        colId: InspectionHistoryFieldName.MAINTENANCEREVIEWDATE,
        width: 115,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWDATE]);
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MAINTENANCEREVIEWBY,
        field: InspectionHistoryFieldName.MAINTENANCEREVIEWBY,
        colId: InspectionHistoryFieldName.MAINTENANCEREVIEWBY,
        width: 105,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWBY] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWBY];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MAINTENANCEREVIEWCOMMENTS,
        field: InspectionHistoryFieldName.MAINTENANCEREVIEWCOMMENTS,
        colId: InspectionHistoryFieldName.MAINTENANCEREVIEWCOMMENTS,
        width: 135,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('MaintenanceReviewEdit') ? false : true),
            onChange: (item, value) =>
                inspectionHistoryStore.updateInspectionHistoryComment(
                    item,
                    value,
                    InspectionHistoryFieldName.MAINTENANCEREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MAINTENANCEREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.COMPLIANCEREVIEWDATE,
        field: InspectionHistoryFieldName.COMPLIANCEREVIEWDATE,
        colId: InspectionHistoryFieldName.COMPLIANCEREVIEWDATE,
        width: 115,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWDATE]);
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.COMPLIANCEREVIEWBY,
        field: InspectionHistoryFieldName.COMPLIANCEREVIEWBY,
        colId: InspectionHistoryFieldName.COMPLIANCEREVIEWBY,
        width: 105,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWBY] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWBY];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.COMPLIANCEREVIEWCOMMENTS,
        field: InspectionHistoryFieldName.COMPLIANCEREVIEWCOMMENTS,
        colId: InspectionHistoryFieldName.COMPLIANCEREVIEWCOMMENTS,
        width: 135,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('ComplianceReviewEdit') ? false : true),
            onChange: (item, value) =>
                inspectionHistoryStore.updateInspectionHistoryComment(
                    item,
                    value,
                    InspectionHistoryFieldName.COMPLIANCEREVIEWCOMMENTS
                )
        },
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWCOMMENTS] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.COMPLIANCEREVIEWCOMMENTS];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.INSPECTIONDATE,
        field: InspectionHistoryFieldName.INSPECTIONDATE,
        colId: InspectionHistoryFieldName.INSPECTIONDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.INSPECTIONDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[InspectionHistoryFieldName.INSPECTIONDATE]);
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.TRAILERHUBMETERREADING,
        field: InspectionHistoryFieldName.TRAILERHUBMETERREADING,
        width: 110,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.TRAILERHUBMETERREADING] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.TRAILERHUBMETERREADING];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.CRITICALITEMS,
        field: InspectionHistoryFieldName.CRITICALITEMS,
        hide: true
    },
    {
        headerName: InspectionHistoryHeaderName.MAJORDEFECTS,
        field: InspectionHistoryFieldName.MAJORDEFECTS,
        width: 70,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MAJORDEFECTS] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MAJORDEFECTS];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MINORDEFECTS,
        field: InspectionHistoryFieldName.MINORDEFECTS,
        width: 70,
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MINORDEFECTS] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MINORDEFECTS];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.COMMENT,
        field: InspectionHistoryFieldName.COMMENT,
        width: 90,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => true
        },
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.COMMENT];
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.LASTSAVEDATE,
        field: InspectionHistoryFieldName.LASTSAVEDATE,
        colId: InspectionHistoryFieldName.LASTSAVEDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.LASTSAVEDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[InspectionHistoryFieldName.LASTSAVEDATE]);
            }
        }
    },
    {
        headerName: InspectionHistoryHeaderName.MODIFIEDBYUSER,
        field: InspectionHistoryFieldName.MODIFIEDBYUSER,
        colId: InspectionHistoryFieldName.MODIFIEDBYUSER,
        width: 100,
        valueGetter: params => {
            if (params.data[InspectionHistoryFieldName.MODIFIEDBYUSER] === null) {
                return '';
            } else {
                return params.data[InspectionHistoryFieldName.MODIFIEDBYUSER];
            }
        }
    }
];
