import * as React from 'react';
import './_font-icon.scss';
import classnames from 'classnames';

export interface FontIconProps {
    onClick?: () => void;
    className: string;
    label?: string;
}

export default class FontIcon extends React.Component<FontIconProps> {
    public render(): React.ReactNode {
        const { onClick, className, label = '' } = this.props;
        const classNames = classnames('fa', className);
        return (
            <div onClick={onClick} className="font-icon">
                <div className="font-icon__box">
                    <i className={classNames} aria-hidden="true"></i>
                </div>
                <div className="font-icon__label ml-1">{label}</div>
            </div>
        );
    }
}
