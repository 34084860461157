import React, { Component, PureComponent } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { printLabels } from '../constants/hazard-constant';
import { HazardAssessmentHistoryResponse } from '../model/hazardAssessment-history-model';
import { dateUtils } from 'shared/services/date-utils';
import { observer } from 'mobx-react';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    pdfContainer: {
        fontSize: 11,
        fontWeight: 'light',
        padding: 0,
        marginTop: 20,
        marginLeft: 50,
        marginRight: 50
    },
    detailsFooter: {
        display: 'flex',
        flexDirection: 'row',
        padding: 2
    },
    contentAll: {
        minWidth: 483,
        maxWidth: 483,
        border: 0.4,
        borderStyle: 'solid',
        padding: 3
    },
    contentLong: {
        minWidth: 483,
        maxWidth: 483,
        border: 0.4,
        borderStyle: 'solid',
        padding: 3,
        height: 80
    },
   contentHead:{
        minWidth: 483,
        maxWidth: 483,
        padding: 3,
        color: '#002960',
        fontWeight: 'bold',
        fontSize: 12
   },
   hazardinfo: {
       border: 0.4,
   }
});
interface PDFComponentProps {
    hazard: any;
}

@observer
export class MyDocument extends PureComponent<PDFComponentProps> {
    constructor(props: Readonly<PDFComponentProps>) {
        super(props);
    }
    public render(): React.ReactNode {
        const { hazard } = this.props
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.pdfContainer}>
                            <View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.HAZARDID} {hazard?.HazardID}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.HAZARDASSESSMENT}
                                </Text>
                                </View>
                                <View style={styles.hazardinfo}>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.LEVELOFRISK}
                                </Text>
                                </View>
                                <View  style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                         {hazard?.RiskLevel}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '} 
                                        {printLabels.DATE} 
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                        {' '}
                                         {hazard?.CreatedDate}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '} 
                                        {printLabels.RELATEDTICKET} 
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                        {' '}
                                         {hazard?.VolumeorHourlyTicketNumber}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.WEATHER} 
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                        {' '}
                                         {hazard?.Weather}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.LOCATION} 
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                        {' '}
                                     {hazard.Consignor === null? hazard.Consignee : hazard.Consignor}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.CUSTOMERNAME}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentAll}>
                                        {' '}
                                         {hazard?.CustomerName}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.DESCRIPTION}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentLong}>
                                        {' '}
                                         {hazard?.Description}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.POSSIBLECAUSES}  
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                <Text style={styles.contentLong}>
                                        {' '}
                                        {hazard?.EventDetails}
                                </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.RECOMMENDATIONS} 
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentLong}>
                                        {' '}
                                        {hazard?.Recommendations}
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentHead}>
                                        {' '}
                                        {printLabels.DRIVERNAME} 
                                    </Text>
                                </View>
                                <View style={styles.detailsFooter}>
                                    <Text style={styles.contentAll}>
                                        {' '}
                                        {hazard?.FirstName}{' '}{hazard?.LastName}
                                    </Text>
                                </View>
                                </View>
                            </View> 
                    </View>
                </Page>
            </Document>
        );
    }

}
