import React from 'react';
import './current-status-cell-renderer';
import { ICellRendererParams } from 'ag-grid-community';

const openDetail = (id: any, ticketNo:any) => {
    window.open('/hazardAssessment?ticketno='+ticketNo , '_blank');
};

export const HazardAssessmentDetailCellRenderer = (props: ICellRendererParams) => {  
    return (
            <span style={{color:"blue"}}
            onClick={()=>openDetail(props.data.ID,props.data.HazardID)}>
                {props.data.HazardID ==undefined?'0000000':props.data.HazardID }
                </span>
    );
};
