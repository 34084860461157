import React from 'react';
import './style.scss';
import moment from 'moment';
import dataJson from './dataFile.json';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper';
import { DispatchBoardDataStore } from '../store/dispatch-board-store';
import { inject, observer } from 'mobx-react';
import { CommonUtils } from 'shared/services/common-utils';
import { dispatchBoardDataStore } from '../store';
import { uiService } from 'shared/services/ui-service';
import { DispatchBoardDataRequest } from '../model/model';
import defaultpageicon from '../../../../assets/defaultpageicon.png';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { localStorageService } from '../../../../shared/services/local-storage-service';
import { driverList } from 'features/dispatch/communication/communication-constants';
import { DriverListPopupComponent } from './driver-list-popUp';
import { capacityPlanDataStore } from 'features/dispatch/capacityPlan/store';
import { DispatchDetailsPopupComponent } from './dispatch-board-popUp';
import { locationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { accountStore } from 'features/account/stores';
import { dataStore } from 'features/common/stores';

const filterOptions = [
    {
        id: '1',
        name: 'Total Drivers',
        color: '#2cb2f3'
    },
    {
        id: '2',
        name: 'Available Drivers',
        color: '#3dc651'
    },
    {
        id: '3',
        name: 'Unavailable Drivers',
        color: '#f96361'
    },
    {
        id: '4',
        name: 'Availability not provided',
        color: '#8f75c9'
    },
    {
        id: '5',
        name: 'Dispatched',
        color: '#faba4d'
    }
];

interface DispatchBoardStates {
    open: boolean;
    openDispatchDetails: boolean;
    dispatchDetails: any;
    tableData: any;
    selectedRegion: any;
    tableHeader: any;
    selectedMonth: any;
    selectedYear: any;
    region: any;
    companyList: any;
    locationList: any;
    showLoader: boolean;
    dataSource: any;
    datesOfMonth: any;
    isChecked: any;
    checkedList: any;
    resultArray: any;
}
interface DispatchBoardProps {
    dispatchBoardDataStore?: DispatchBoardDataStore;
}

@inject('dispatchBoardDataStore')
@observer
export class DispatchBoardComponent extends React.Component<DispatchBoardProps, DispatchBoardStates> {
    constructor(props) {
        super(props);
        const datesOfMonth = Array.from(
            Array(this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
            (_, i) => i + 1
        );
        this.state = {
            open: false,
            openDispatchDetails: false,
            tableData: [],
            dispatchDetails: [],
            selectedRegion: 0,
            tableHeader: '',
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            region: [],
            companyList: [],
            locationList: [],
            showLoader: false,
            dataSource: [],
            datesOfMonth: datesOfMonth,
            isChecked: false,
            checkedList: [],
            resultArray: []
        };
        dataStore.setModuleName('DispatchBoard');
    }
    /* eslint-disable */
    async componentDidMount() {
        const { dispatchBoardDataStore } = this.props;
        // const regionList = [] as any
        dispatchBoardDataStore?.GetMasterData().then(() => {
            if (dispatchBoardDataStore !== undefined && dispatchBoardDataStore?.baseValueDTO.length > 0) {
                this.setState({ selectedRegion: dispatchBoardDataStore?.baseValueDTO[0].ID as number });
                if (dispatchBoardDataStore) {
                    dispatchBoardDataStore.selectedRegionID = dispatchBoardDataStore?.baseValueDTO[0].ID as number;
                }
                this.getData(dispatchBoardDataStore?.baseValueDTO[0].ID as number);
            } /* eslint-disable-line */
        });
    }

    GetMasterData = async () => {
        const { dispatchBoardDataStore } = this.props;
        dispatchBoardDataStore?.GetMasterData();
    };

    getData = async (FilterID: any) => {
        const { dispatchBoardDataStore } = this.props;
        this.setState({ showLoader: true });
        this.setState({ selectedRegion: FilterID });
        if (dispatchBoardDataStore) {
            dispatchBoardDataStore.selectedRegionID = FilterID;
        }
        this.GetMasterData().then(() => {
            const CompanyRequestdata = { regionID: FilterID };
            if (dispatchBoardDataStore !== undefined) {
                dispatchBoardDataStore?.getCompanyRegionMapping(CompanyRequestdata).then(async () => {
                    let allArray = [{ ID: 0, id: 0, Text: ' All', label: ' All' }];
                    let dictMappedCompany = CommonUtils.dictionaryToObj(
                        dispatchBoardDataStore?.regionMappingData.MappedCompany
                    );
                    this.setState({ showLoader: true });
                    let reqbody = {
                        AxOrRAData : ''
                    }
                    const locationData: any = await locationDataService.getOperator(reqbody);
                    this.setState({ showLoader: false });
                    dictMappedCompany = [...allArray, ...dictMappedCompany];
                    this.setState({
                        companyList: dictMappedCompany,
                        locationList: locationData
                    });
                    this.getCapacityDataToDisplay(
                        dispatchBoardDataStore,
                        this.state.selectedMonth,
                        this.state.selectedYear
                    );
                });
            }
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            openDispatchDetails: false
        });
        dispatchBoardDataStore.driverInfo = [];
    };

    handleRefresh = () => {
        const { dispatchBoardDataStore } = this.props;
        if (dispatchBoardDataStore) {
            this.setState({
                showLoader: true
            });
            this.getCapacityDataToDisplay(dispatchBoardDataStore, this.state.selectedMonth, this.state.selectedYear);
        }
    };

    private getCapacityDataToDisplay(dispatchBoardDataStore: DispatchBoardDataStore, selectedMonth, selectedYear) {
        const requestData: DispatchBoardDataRequest = {
            Type: 'Internal',
            CC: this.state.selectedRegion === 'All' ? 'All' : 'RegionCC',
            Month: moment.months()[selectedMonth],
            StartDate: moment(new Date(selectedYear, selectedMonth, 1)).format('YYYY-MM-DD'),
            EndDate: moment(new Date(selectedYear, selectedMonth + 1, 0)).format('YYYY-MM-DD'),
            Year: selectedYear,
            RegionID: this.state.selectedRegion,
            LocationID: 'All'
        };
        dispatchBoardDataStore?.getCapacityDisplayData(requestData).then(() => {
            this.setState({
                dataSource: dispatchBoardDataStore.dispatchBoardData,
                showLoader: false
            });
        });
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    handleOpenDispatchDetails = (month, year) => {
        dispatchBoardDataStore.selectedMonth = month;
        dispatchBoardDataStore.selectedYear = year;
        this.setState({
            selectedMonth: month,
            selectedYear: year,
            openDispatchDetails: true
        });
        // data.sort((a,b) => a.drivername.localeCompare(b.drivername));
    };

    handleClickOpen = (data, string) => {
        // data.sort((a,b) => a.drivername.localeCompare(b.drivername));
        this.setState({
            open: true,
            tableData: data,
            tableHeader: string
        });
    };

    handleRegionChange = event => {
        if (dispatchBoardDataStore) {
            console.log('handleRegionChange' + event.currentTarget.value);
            this.getData(event.currentTarget.value as number);
        }
    };

    handleChangeMonth = month => {
        this.setState({
            selectedMonth: moment.months().indexOf(month),
            datesOfMonth: Array.from(
                Array(this.daysInMonth(moment.months().indexOf(month) + 1, this.state.selectedYear)),
                (_, i) => i + 1
            )
        });
        if (dispatchBoardDataStore) {
            this.setState({ showLoader: true });
            this.getCapacityDataToDisplay(
                dispatchBoardDataStore,
                moment.months().indexOf(month),
                this.state.selectedYear
            );
        }
    };

    handleChangeYear = year => {
        this.setState({
            selectedYear: year,
            datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1)
        });
        if (dispatchBoardDataStore) {
            this.setState({ showLoader: true });
            this.getCapacityDataToDisplay(dispatchBoardDataStore, this.state.selectedMonth, year);
        }
    };

    onApplyClick = () => {
        this.setState({
            resultArray: this.state.checkedList
        });
    };
    onClearButtonClicked = () => {
        this.setState({
            checkedList: [],
            resultArray: []
        });
    };
    onCheckboxSelection = (e, id) => {
        let resultArray: string[] = [];
        if (e.target.checked) {
            resultArray = this.state.checkedList.filter(CheckedId => CheckedId !== id);
            resultArray.push(id);
        } else {
            resultArray = this.state.checkedList.filter(CheckedId => CheckedId !== id);
        }
        this.setState({
            checkedList: resultArray
        });
    };

    selectedRegionCompanyName(options: any, id) {
        if (options && options.length > 0) {
            const value = options.filter(data => data.ID === id);
            if (value.length > 0) {
                return value[0].Name ? value[0].Name : value[0].Text;
            }
        }
        return '';
    }

    sortCarrierCompany(carrierName) {
        var sortOrder = 1;
        if (carrierName[0] === '-') {
            sortOrder = -1;
            carrierName = carrierName.substr(1);
        }
        return function(a, b) {
            if (sortOrder == -1) {
                return b[carrierName].localeCompare(a[carrierName]);
            } else {
                return a[carrierName].localeCompare(b[carrierName]);
            }
        };
    }

    render(): React.ReactNode {
        const monthNames = moment.months();
        const { dispatchBoardDataStore } = this.props;
        const {
            selectedMonth,
            selectedYear,
            selectedRegion,
            region,
            open,
            openDispatchDetails,
            tableData,
            dispatchDetails,
            tableHeader,
            companyList,
            locationList,
            showLoader,
            dataSource,
            datesOfMonth,
            resultArray,
            checkedList
        } = this.state;
        let companyData: any = [],
            locationData: any = [];
        companyList.map(company => {
            companyData.push({ ID: company.ID, value: company.Text, label: company.Text });
        });
        locationList.map(location => {
            locationData.push({ value: location.Description, label: location.Description });
        });
        const getYear = new Date().getFullYear();
        const years = Array.from(new Array(20), (val, index) => index + getYear);
        if (showLoader) {
            uiService.loaderService.showLoader();
        } else {
            uiService.loaderService.hideLoader();
        }
        return !dispatchBoardDataStore?.menuLoader &&
            dispatchBoardDataStore?.baseValueDTO &&
            dispatchBoardDataStore?.baseValueDTO.length > 0 ? (
            <div>
                <div className="form-inline padding-bottom">
                    <div className="react-select-container">
                        Month :{' '}
                        <select
                            name="month"
                            value={monthNames[selectedMonth]}
                            onChange={e => this.handleChangeMonth(e.currentTarget.value)}
                            // defaultValue={monthNames[selectedMonth]}
                            className="react-select-style"
                        >
                            {monthNames.map((month, i) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="react-select-container">
                        Year :{' '}
                        <select
                            name="year"
                            value={selectedYear}
                            className="react-select-style"
                            // defaultValue={selectedYear}
                            onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                        >
                            {years.map(yearData => (
                                <option key={yearData} value={yearData}>
                                    {yearData}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="react-select-container">
                        Region :{' '}
                        <select
                            name="region"
                            // value={selectedRegion}
                            className="react-select-style"
                            onChange={this.handleRegionChange}
                        >
                            {this.props.dispatchBoardDataStore?.baseValueDTO.map(region => (
                                <option key={region.ID} value={region.ID}>
                                    {region.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <CustomButton
                        type={CustomButtonType.Submit}
                        onClick={() =>
                            this.handleOpenDispatchDetails(this.state.selectedMonth, this.state.selectedYear)
                        }
                    >
                        {' '}
                        View Dispatch Details{' '}
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.handleRefresh}>
                        Refresh
                    </CustomButton>
                </div>
                <div className="root">
                    <div className="filter-main-container">
                        <div className="region-company-style">
                            <p>
                                <b>Region</b> :{' '}
                                {this.selectedRegionCompanyName(
                                    this.props.dispatchBoardDataStore?.baseValueDTO,
                                    selectedRegion
                                )}
                            </p>
                            <p>
                                <b>Carrier Co.</b> : All
                            </p>
                        </div>

                        <div className="filter-container">
                            {filterOptions.map((item, index) => (
                                <div className="filter-row-style" key={item.id}>
                                    <div className="circle" style={{ backgroundColor: item.color }}></div>
                                    <p>{item.name}</p>
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        name="totalDrivers"
                                        value={item.id}
                                        checked={this.state.checkedList.includes(item.id)}
                                        onChange={e => this.onCheckboxSelection(e, item.id)}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="button-container">
                            <button onClick={this.onClearButtonClicked} className="clear-button-style">
                                CLEAR
                            </button>
                            <button className="apply-button-style" onClick={this.onApplyClick}>
                                APPLY
                            </button>
                        </div>
                    </div>
                    <div className="outer-grid-view">
                        <p>
                            <b>{monthNames[selectedMonth]}</b> {selectedYear}
                        </p>
                        {dataSource.length > 0 ? (
                            <div className="grid-view-main-container">
                                {datesOfMonth.map(date => (
                                    <div className="card" key={date}>
                                        <div className="container">
                                            <div className="inner-container" key={date - 1}>
                                                <div>
                                                    <label>{date}</label>
                                                </div>
                                                {(resultArray.includes('1') || resultArray.length === 0) && (
                                                    <div className="card-button-container">
                                                        <p>Total Drivers</p>
                                                        <button
                                                            onClick={() =>
                                                                this.handleClickOpen(
                                                                    dataSource[date - 1]
                                                                        ? dataSource[date - 1].driverStatusData
                                                                              .totalDrivers
                                                                        : [],
                                                                    'Total Drivers'
                                                                )
                                                            }
                                                            style={{ backgroundColor: '#eaf6fe' }}
                                                        >
                                                            {dataSource[date - 1]
                                                                ? dataSource[date - 1].driverStatusData.totalDrivers
                                                                      .length
                                                                : 0}
                                                        </button>
                                                    </div>
                                                )}
                                                {(resultArray.includes('2') || resultArray.length === 0) && (
                                                    <div className="card-button-container">
                                                        <p>Available Drivers</p>
                                                        <button
                                                            onClick={() =>
                                                                this.handleClickOpen(
                                                                    dataSource[date - 1]
                                                                        ? dataSource[date - 1].driverStatusData
                                                                              .availableDrivers
                                                                        : [],
                                                                    'Available Drivers'
                                                                )
                                                            }
                                                            style={{ backgroundColor: '#edfaed' }}
                                                        >
                                                            {dataSource[date - 1]
                                                                ? dataSource[date - 1].driverStatusData.availableDrivers
                                                                      .length
                                                                : 0}
                                                        </button>
                                                    </div>
                                                )}
                                                {(resultArray.includes('3') || resultArray.length === 0) && (
                                                    <div className="card-button-container">
                                                        <p> Unavailable Drivers</p>
                                                        <button
                                                            onClick={() =>
                                                                this.handleClickOpen(
                                                                    dataSource[date - 1]
                                                                        ? dataSource[date - 1].driverStatusData
                                                                              .unAvailableDrivers
                                                                        : [],
                                                                    'Unavailable Drivers'
                                                                )
                                                            }
                                                            style={{ backgroundColor: '#fdf0ee' }}
                                                        >
                                                            {dataSource[date - 1]
                                                                ? dataSource[date - 1].driverStatusData
                                                                      .unAvailableDrivers.length
                                                                : 0}
                                                        </button>
                                                    </div>
                                                )}

                                                {(resultArray.includes('4') || resultArray.length === 0) && (
                                                    <div className="card-button-container">
                                                        <p> Availablity not provided</p>
                                                        <button
                                                            onClick={() =>
                                                                this.handleClickOpen(
                                                                    dataSource[date - 1]
                                                                        ? dataSource[date - 1].driverStatusData
                                                                              .availibilityNotProvided
                                                                        : [],
                                                                    'Availablity Not Provided'
                                                                )
                                                            }
                                                            color="primary"
                                                        >
                                                            {dataSource[date - 1]
                                                                ? dataSource[date - 1].driverStatusData
                                                                      .availibilityNotProvided.length
                                                                : 0}
                                                        </button>
                                                    </div>
                                                )}
                                                {(resultArray.includes('5') || resultArray.length === 0) && (
                                                    <div className="card-button-container">
                                                        <p> Dispatched</p>
                                                        <button
                                                            onClick={() =>
                                                                this.handleClickOpen(
                                                                    dataSource[date - 1]
                                                                        ? dataSource[date - 1].driverStatusData
                                                                              .dispatched
                                                                        : [],
                                                                    'Dispatched'
                                                                )
                                                            }
                                                            style={{ backgroundColor: '#fef7ea' }}
                                                        >
                                                            {dataSource[date - 1]
                                                                ? dataSource[date - 1].driverStatusData.dispatched
                                                                      .length
                                                                : 0}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="defaultpage__parent_container">
                                <img className="defaultpage__defaulticon" src={defaultpageicon} alt="DefaultPage" />
                                <label className="defaultpage__nodatafound">No Data to Show</label>
                            </div>
                        )}
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <div id="alert-dialog-title" className="dialog-container">
                        <div className="dialog-title">
                            <p>
                                {tableHeader}: {tableData.length}
                            </p>
                        </div>

                        <button onClick={this.handleClose} className="popover-close-button">
                            X
                        </button>
                    </div>
                    <DialogContent className="dialog-main-container">
                        <DialogContentText id="alert-dialog-description">
                            <Paper className="paper-style">
                                <DriverListPopupComponent
                                    dataSource={tableData.filter(data => data.Driver != null)}
                                    dispatchBoardDataStore={dispatchBoardDataStore}
                                />
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDispatchDetails}
                    onClose={this.handleClose}
                    aria-labelledby="simple-dialog-title"
                    fullWidth={true}
                    maxWidth="xl"
                >
                    <div id="alert-dialog-title" className="dialog-container">
                        <div className="dialog-title" style={{ marginLeft: '10px', width: '100%' }}>
                            <p>
                                CAPACITY & DISPATCH DETAILS - {moment.months(selectedMonth).toUpperCase()}{' '}
                                {selectedYear}
                            </p>
                        </div>

                        <button onClick={this.handleClose} className="popover-close-button">
                            X
                        </button>
                    </div>
                    <DialogContent className="dispatch-board-main-container">
                        <DialogContentText id="alert-dialog-description">
                            <Paper className="paper-style">
                                <DispatchDetailsPopupComponent
                                    dispatchBoardDataStore={dispatchBoardDataStore}
                                    month={this.state.selectedMonth}
                                    year={this.state.selectedYear}
                                    region={this.state.selectedRegion}
                                    companyData={companyData}
                                    locationData={locationData}
                                />
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        ) : (
            <div></div>
        );
    }
}
