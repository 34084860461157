export enum TabletSaveLabel {
    SAVE = 'Save Tablet Data'
}

export enum TabletMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Tablet data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    FAILED_DELETE = 'Failed to delete tablet',
    TABLET_DELETE = 'Deleted Successfully'
}

export enum TabletHeaderName {
    ID = 'Tablet ID',
    TABLETOS = 'Serial No.',
    ANDROIDID = 'Android ID',
    KNOXSERVICE = 'Knox Service',
    LOCATIONSERVICE = 'Location Service',
    MANUFACTURE = 'Sim Card #',
    TABLETIMEI = 'Tablet IMEI',
    CREATEDBYUSER = 'Created By',
    CREATEDDATE = 'Created Date',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    CARRIERCOMPANY = 'Carrier Company',
    ASSIGNEDTO = 'Assigned To',
    LASTUSER = 'Last User',
    LASTLOGINDATE = 'Last Login Date',
    DELETE = 'Delete'
}

export enum TabletFieldName {
    ID = 'ID',
    TABLETOS = 'TabletOS',
    ANDROIDID = 'AndroidID',
    KNOXSERVICE = 'IsKnoxEnabled',
    LOCATIONSERVICE = 'IsLocationEnabled',
    MANUFACTURE = 'Manufacturer',
    TABLETIMEI = 'TabletIMEI',
    CREATEDBYUSER = 'CreatedByUser',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDBYUSER = 'ModifiedByUser',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    CARRIERCOMPANY = 'CarrierCompany',
    ASSIGNEDTO = 'AssignedTo',
    LASTUSER = 'LastUser',
    LASTLOGINDATE = 'LastLoginDate',
    CHECKBOX = 'IsDeleted'
}
