import React, { PureComponent } from 'react';
import './_user-request.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { accountStore } from '../stores';

interface UserRequestState {
    isValidUser?: boolean;
}

@inject('accountStore')
@observer
export class UserRequestComponent extends PureComponent<RouteComponentProps, UserRequestState> {
    constructor(props) {
        super(props);
        this.state = {
            isValidUser: undefined
        };
    }

    onClick = () => {
        this.props.history.push('/');
    };

    updateUserStatus = () => {
        const urlQueryStrings = this.props.location.search;
        if (urlQueryStrings) {
            const queryValues = queryString.parse(urlQueryStrings);
            accountStore.updateUserStatusForApproval(queryValues).then(res => {
                this.setState({ isValidUser: res.data['Data'] });
            });
        }
    };

    componentDidMount() {
        this.updateUserStatus();
    }

    render(): React.ReactNode {
        const { isValidUser } = this.state;
        if (isValidUser !== undefined) {
            return (
                <div className="user-request">
                    <div className="user-request_content">
                        <div> {this.renderMessage(isValidUser)}</div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    renderMessage = (isValidUser?: boolean) => {
        if (isValidUser) {
            return (
                <div>
                    <div className="user-request_header">Thank you for the verification of your Email</div>
                    <div>Your request has been sent for approval.</div>
                    <div className="user-request_footer">
                        <div>Thanks</div>
                        <div>Plains Midstream Canada</div>
                    </div>
                    <Button className="mt-3 mb-3" onClick={this.onClick}>
                        OK
                    </Button>
                </div>
            );
        } else {
            return (
                <>
                    <div className="user-request_invalidlink">Verification email link is invalid</div>
                    <div className="user-request_header">Your request has been already sent for approval.</div>
                    <Button className="mt-3 mb-3" onClick={this.onClick}>
                        OK
                    </Button>
                </>
            );
        }
    };
}
