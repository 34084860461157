import { ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { MaintenanceType, MaintenanceStatus, TrailerMaintenanceViewModel, ShopInfo } from '../model/model';
import { TRAILER_MAINTENANCE_STATUS, TRAILER_MAINTENANCE_TYPE } from '../maintenance_portal_constant';
import { TrailerMaintenanceResponse } from '../model/trailerMaintenanceResponse';
import { TrailerMaintenanceRequest } from '../model/trailerMaintenanceRequest';
import { DateRange } from '../../../shared/components/daypicketInput/react-daypickerInput'

export class TrailerMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    private getTrailerMaintenanceData(): Promise<ResponseDataType<TrailerMaintenanceResponse[]>> {
        return this.restApi.postWithToken<TrailerMaintenanceResponse[]>(Config.getTrailerMaintenanceData);
    }

    async getTrailerMaintenanceDataWithDateParams(reqbody: DateRange): Promise<ResponseDataType<TrailerMaintenanceResponse[]>> {
        return this.restApi.postWithToken<TrailerMaintenanceResponse[]>(Config.getTrailerMaintenanceData, reqbody, {});
    }

    private getMaintenanceTypes(): Promise<ResponseDataType<MaintenanceType[]>> {
        return this.restApi.postWithToken<MaintenanceType[]>(Config.getBaseValueByClass, { CODE: TRAILER_MAINTENANCE_TYPE });
    }

    private getMaintenanceStatuses(): Promise<ResponseDataType<MaintenanceStatus[]>> {
        return this.restApi.postWithToken<MaintenanceStatus[]>(Config.getBaseValueByClass, { CODE: TRAILER_MAINTENANCE_STATUS });
    }

    async getShops(): Promise<Array<ShopInfo>> {
        try {
            const { data: shops } = await this.restApi.postWithToken<Array<ShopInfo>>(
                Config.getShopInfo,
                { SearchBy: 'all', SearchByValue: 'all' },
                {}
            );
            return shops['Data'];
        } catch (e) {
            return [];
        }
    }

    async getTrailerMaintenanceViewModel(): Promise<TrailerMaintenanceViewModel> {
        const maintenanceDataPromise = this.getTrailerMaintenanceData();
        const maintenanceTypesPromise = this.getMaintenanceTypes();
        const maintenanceStatusPromise = this.getMaintenanceStatuses();
        const [
            { data: trailerMaintenanceInfo },
            { data: maintenanceTypes },
            { data: maintenanceStatuses }
        ] = await Promise.all([maintenanceDataPromise, maintenanceTypesPromise, maintenanceStatusPromise]);
        return {
            trailerMaintenanceInfo: trailerMaintenanceInfo,
            maintenanceTypes: maintenanceTypes,
            maintenanceStatuses: maintenanceStatuses
        };
    }

    async getTrailerMaintenanceViewModelWithDateParams(reqbody: DateRange): Promise<TrailerMaintenanceViewModel> {

        const maintenanceDataPromise = this.getTrailerMaintenanceDataWithDateParams(reqbody);
        const maintenanceTypesPromise = this.getMaintenanceTypes();
        const maintenanceStatusPromise = this.getMaintenanceStatuses();
        const [
            { data: trailerMaintenanceInfo },
            { data: maintenanceTypes },
            { data: maintenanceStatuses }
        ] = await Promise.all([maintenanceDataPromise, maintenanceTypesPromise, maintenanceStatusPromise]);
        return {
            trailerMaintenanceInfo: trailerMaintenanceInfo,
            maintenanceTypes: maintenanceTypes,
            maintenanceStatuses: maintenanceStatuses
        };
    }

    async updateTrailerMaintenances(reqbody: TrailerMaintenanceRequest[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateTrailerMaintenances, reqbody, {});
        return data['Data'];
    }
}

export const trailerMaintenaceDataService = new TrailerMaintenanceDataService(restApiService);
