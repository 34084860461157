import { TractorResponse } from '../domains/tractor-modal';
import { TractorHeaderName } from '../domains/tractor-enum';
import { CommonUtils } from 'shared/services/common-utils';

export class TractorValidationService {
    validateUpdateRequest(updatedList: TractorResponse[], initialList: TractorResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.VIN)) {
                errorMessage = `${TractorHeaderName.VINNUMBER} is required `;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item.RegistrationNo)) {
                errorMessage = `${TractorHeaderName.PLATE} is required `;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.VIN) && !CommonUtils.checkForAlphaNumeric(item.VIN)) {
                errorMessage = `${TractorHeaderName.VINNUMBER} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.RegistrationNo) && !CommonUtils.checkForAlphaNumeric(item.RegistrationNo)) {
                errorMessage = `${TractorHeaderName.PLATE} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item.VIN)) {
                const isVINNumberDuplicate = initialList.find(a => a.VIN === item.VIN && a.ID != item.ID);
                if (isVINNumberDuplicate) {
                    errorMessage = `${TractorHeaderName.VINNUMBER} must be unique`;
                    return;
                }
            }
        });
        return errorMessage;
    }
}

export const tractorValidationService = new TractorValidationService();
