import { action, observable } from 'mobx';
import Loader from 'shared/decorators/loader-decorator';
import { HazardAssessmentDataService } from '../services/hazardAssessment-data-service';
import { HazardAssessmentReport, HazardAssessmentRequest } from '../model/hazardAssessmentReport-model';
import { HazardAssessmentHistoryResponse } from '../model/hazardAssessment-history-model';
import { dateUtils } from 'shared/services/date-utils';
export class HazardAssessmentDataStore {
    @observable hazardReport: HazardAssessmentReport = {
        HazardID: '',
    Weather: '',
    Description: '',
    CustomerName: '',
    Consignor: '',
    Consignee: '',
    EventDetails: '',
    Recommendations: '',
    RiskLevel: '',
    CreatedDate: '',
    VolumeorHourlyTicketNumber: '',
    FirstName: '',
    LastName: '',
    }
    constructor(
        private hazardAssessmentService: HazardAssessmentDataService,
    ) {}

    init(reqBody: HazardAssessmentRequest): void {
        this.getReportByHazardAssessmentNumber(reqBody);
    }

    @Loader
    @action
    async getReportByHazardAssessmentNumber(reqBody){
        try {
            const response: any = await this.hazardAssessmentService.getHazardAssessmentReport(reqBody);
            const hazard = response.data['Data'];
            console.log(hazard)
            if(hazard.length > 0){
                this.hazardReport.HazardID = hazard[0].HazardID;
                this.hazardReport.Weather = hazard[0].Weather;
                this.hazardReport.Description = hazard[0].Description;
                this.hazardReport.CustomerName = hazard[0].CustomerName;
                this.hazardReport.Consignor = hazard[0].Consignor;
                this.hazardReport.Consignee = hazard[0].Consignee;
                this.hazardReport.EventDetails = hazard[0].EventDetails;
                this.hazardReport.Recommendations = hazard[0].Recommendations;
                this.hazardReport.RiskLevel = hazard[0].RiskLevel;
                this.hazardReport.CreatedDate = dateUtils.getFormattedDateTime(hazard[0].CreatedDate);
                this.hazardReport.VolumeorHourlyTicketNumber = hazard[0].VolumeorHourlyTicketNumber; 
                this.hazardReport.FirstName = hazard[0].FirstName;
                this.hazardReport.LastName = hazard[0].LastName;
            }
        } catch (e) {
            console.log('catch err', e);
            return [];
        }
    }

    
}