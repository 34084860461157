import React from 'react';
import { Modal } from 'react-bootstrap';
import { DispatchPlanDateComponent } from './dispatch-plan-date-component';

interface DispatchPlanPopUpProps {
    onHide: () => void;
    show: boolean;
    startDate: any;
    onSelectionChange: (date: any) => void;
}

export const DispatchPlanPopUpComponent = (props: DispatchPlanPopUpProps) => {
    const { startDate, onHide, show, onSelectionChange } = props;
    return (
        <Modal size="lg" onHide={onHide} backdrop='static' show={show} animation={false}>
            <Modal.Body>
                <DispatchPlanDateComponent
                    startDate={startDate}
                    onHide={onHide}
                    onSelectionChange={onSelectionChange}
                ></DispatchPlanDateComponent>
            </Modal.Body>
        </Modal>
    );
};
