import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { OperatorResponse, LocationResponse, RegionType } from 'features/data_management/domains/location-management/model';
import { Company_Region_Type } from 'features/mapping/mapping_portal_constant';
import { AppConstant } from 'app_constant';

export class LocationDataService {
    constructor(private restApi: RestApiService) { }

    async getOperator(reqbody = {}): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getOperator, reqbody, {});
        return data['Data'];
    }

    async sendOperator(reqbody): Promise<LocationResponse[]> {
        const { data } = await this.restApi.post<LocationResponse>(Config.sendOperator, reqbody, {});
        return data['Data'];
    }

    async getLocationData(): Promise<LocationResponse[]> {
        const { data } = await this.restApi.postWithToken<LocationResponse[]>(Config.getLocationScreenData);
        return data['Data'];
    }

    async sendLocationExcelReport(reqbody): Promise<LocationResponse[]> {
        const { data } = await this.restApi.post<any>(Config.uploadLocationExcel, reqbody, {});
        return data;
    }

    async getRegionTypes(): Promise<ResponseDataType<RegionType[]>> {
        return this.restApi.postWithToken<RegionType[]>(Config.getBaseValueByClass, { CODE: Company_Region_Type });
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }
}

export const locationDataService = new LocationDataService(restApiService);
