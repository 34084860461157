/* eslint-disable @typescript-eslint/camelcase */
import { accountStore } from 'features/account/stores';
import { AccountStore } from 'features/account/stores/account-store';
import { ReportMessage } from 'features/reports/domains/enum';
import { action, computed, observable, toJS } from 'mobx';
import Loader from '../../../../shared/decorators/loader-decorator';
import Catch from '../../../../shared/decorators/catch-decorator';
import { errorHandler } from 'shared/handlers/error-handler';
import { uiService, UiService } from 'shared/services/ui-service';
import { nominationDataService, NominationDataService } from '../services/nomination-data-service';
import { LOAD_COL_DEF, NOMINATION_COL_DEF } from '../models/nomination-col-def';
import { agGridService, AgGridService } from 'features/common/services/ag-grid-service';
import { NominationDataRequest } from '../models/model';
import _ from 'lodash';
import { loadStatus, dataSources, ShipperName, suppliersRoles } from '../nomination-constants';
import { LoaderService } from 'shared/components/loader/loader-service';
import { dispatchPlanDataService } from 'features/dispatch/dispatch-plan-management/services/dispatchPlan-data-service';
import moment from 'moment';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { CommonUtils } from 'shared/services/common-utils';
import { INVALID_USERID } from 'features/data_management/data_constant';

export class NominationStore {
    nominationData;
    @observable nominaData: any[] = [];
    @observable isLoadPopupVisible: boolean = false;
    backupnomination = [];
    fullNominationData: any = [];
    isRefreshed: boolean = false;
    @observable cellValueChangeMap = {};
    @observable hourlyReports: any[] = [];
    @observable nominationDisplayData = [] as any;
    @observable isCustomerAddButton = false;
    @observable isApproverPopUpVisible = false;
    selectedMonth: any;
    selectedYear: any;
    selectedProduct: any;
    selectedRegion: any;
    @observable selectButtonText: any = 'Select All';
    checkBoxSelectionCount: any;
    navigated: any = false;
    isLoadRowEdited: boolean = false;
    selected: any = false;
    responseData: any = [];
    errorMessage: any = [];
    excelData: any = [];
    rowfield: any = {};
    selectedData: any = {};
    selectedDate: any = '';
    @observable dataToBeDeleted: any = [];
    @observable nominationLoadData: any = [];
    backupnominationLoad: any;
    checkeddata: any;
    apportionmentData: any = [];
    crudeModel: any;
    nominationGrid: any;
    CustomerName: any;
    allActiveDriverValues: any[] = [];
    startDate: any = moment().subtract(6, 'days').toISOString().slice(0, 10);;
    endDate: any = moment().toISOString().slice(0, 10);
    @observable VolumeSum: any = {
        VTTSum: 0,
        TOVSum: 0,
        NominationSum: 0,
        Completedpercentage: 0,
        CompletedSum: 0
    };
    constructor(
        public nominationDataService: NominationDataService,
        public agGridService: AgGridService,
        private accountStore: AccountStore,
        private uiService: UiService
    ) {}
    @Loader
    @Catch(() => errorHandler('Could not load Apportionment Data'))
    async getApportionmentData() {
        const reqbody = {
            Month: this.selectedMonth + 1,
            Year: this.selectedYear
        };
        const response = await this.nominationDataService.getApportionmentData(reqbody);
        this.apportionmentData = response.data['Data'];
        return this.apportionmentData;
    }
    @Loader
    @Catch(() => errorHandler('Could not load Nomination Data'))
    async getNominationData(reqBody: NominationDataRequest): Promise<void> {
        const response = await this.nominationDataService.getNominationData(reqBody);
        this.setfullNomination(response.data['Data']);
        if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Butane' || element.DataSource === 'NGL';
            });
        } else if (accountStore.userRoles.includes('ptssupplierbutane')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Butane';
            });
        } else if (accountStore.userRoles.includes('ptssupplierngl')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'NGL';
            });
        } else if (accountStore.userRoles.includes('ptssuppliercrude')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Crude' && element.ShipperName === 'Plains Midstream Canada ULC';
            });
        } else if (accountStore.userRoles.includes('PTSSUpplierBrine')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Brine';
            });
        } else {
            this.nominationData = response.data['Data'];
        }
        this.nominationData.forEach(data => {
            data.IsChild = true;
            data.IsSelected = false;
            data.PendingVolume = data.PendingVolume ? data.PendingVolume : 0.0;
            data.DispatchedVolume = data.DispatchedVolume ? data.DispatchedVolume : 0.0;
            data.CompletedVolume = data.CompletedVolume ? data.CompletedVolume : 0.0;
            data.ApportionmentVolume = data.ApportionmentVolume ? data.ApportionmentVolume : null;
            data.VolsToBeTrucked = data.VolsToBeTrucked ? data.VolsToBeTrucked : null;
            data.FromCode = data.FromCode ? data.FromCode : null;
            data.FromFacility = data.FromFacility ? data.FromFacility : null;
        });
        this.setBackupnomination(this.nominationData);
        this.nomiData(this.nominationData);
    }
    getNominationColDef() {
        return NOMINATION_COL_DEF;
    }
    getLoadColDef() {
        return LOAD_COL_DEF;
    }

    setBackupnomination(data) {
        console.log(data);
        this.backupnomination = data;
    }

    setfullNomination(data) {
        this.fullNominationData = data;
    }

    setBackupnominationData(data) {
        this.backupnominationLoad = data;
    }

    resetNomination() {
        this.nominationData = this.backupnomination;
        this.nomiData(this.nominationData);
    }

    resetNominationLoad() {
        this.nominationLoadData = this.backupnominationLoad;
    }

    checkSupplierRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (suppliersRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    @Loader
    @Catch(() => errorHandler('Could not load NominationLoad Data'))
    async getNominationLoadData(data) {
        this.uiService.loaderService.showLoader();
        const reqbody = {
            CustomerName: data.CustomerName,
            ContractNumber: data.ContractNumber,
            OriginID: data.OriginID,
            DestinationID: data.DestinationID,
            NominationMonth: data.NominationMonth,
            NominationYear: data.NominationYear
        };
        const response = await this.nominationDataService.getNominationLoadData(reqbody);
        this.nominationLoadData = response.data['Data'];
        this.nominationLoadData.forEach(data => {
            data.IsChild = true;
            data.IsSelected = false;
            data.IsLoad = true;
        });
        this.uiService.loaderService.hideLoader();
        this.nominationLoadData.unshift(data);
        this.nominationLoadData = this.orderedLoadDataBasedOnStatus(this.nominationLoadData);
        this.setBackupnominationData(this.nominationLoadData);
        return this.nominationLoadData;
    }

    @action
    orderedLoadDataBasedOnStatus(nominationLoadData) {
        const statusArray = {
            Pending: 1,
            'Rejected By Driver': 2,
            'Rejected By Carrier': 3,
            'Cancelled By Driver': 4,
            'Cancelled By Carrier': 5,
            'Recalled': 6,
            'Recalled By Carrier': 7,
            Recalled_Pending: 8,
            Dispatch_Pending: 9,
            'Driver Dispatched': 10,
            'Carrier Assigned': 11,
            'Driver Accepted': 12,
            'Carrier Accepted': 13,
            Enroute: 14,
            Loaded: 15,
            Completed: 16,
            Cancelled: 17,
            Deleted: 18,
            'Apportionment Deleted': 19,
            'Manually Deleted ': 20,
            'System Deleted': 21
        };
        nominationLoadData.sort((a, b) => statusArray[a.LoadStatus] - statusArray[b.LoadStatus]);
        return nominationLoadData;
    }

    @action
    getRadioSelection(data: any) {
        this.selectedData = data;
    }
    @action
    getCustomerGroupSelection(key) {
        console.log(key);
        this.CustomerName = key;
    }
    @action
    isAddCustomerRow(data: any) {
        this.selectedData = data;
    }

    @computed
    get NominationLoad(): any[] {
        return toJS(this.nominaData);
    }

    @Loader
    @Catch(() => errorHandler('Error on Apportionment Volume update'))
    async updateApportionmentValue(filteredCrude) {
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const loaderService = new LoaderService();
        loaderService.showLoader();
        const requestBody = {
            NominationMonth: this.selectedMonth + 1,
            NominationYear: this.selectedYear,
            CreatedBy: userID,
            NominationData: filteredCrude,
            ModifiedBy: userID
        };
        return await this.nominationDataService.createUpdateNominationData(requestBody);
    }

    addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(dataSources, 'DataSource', 'Label');
        this.agGridService.updateOptionCellEditorValues(ShipperName, 'ShipperName', 'Label');
    }

    orderBy(array, type) {
        array.sort(function(a, b) {
            const nameA = a.ID,
                nameB = b.ID;
            if (type == 'asc') {
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
            } else {
                if (nameA < nameB) return 1;
                if (nameA > nameB) return -1;
            }
            console.log('in orderby function');
            return 0; //default return value (no sorting)
        });
    }
    @action
    handleSelectAllData = (data: any, filterData) => {
        let newNominationLoadData: any = [];

        if (this.selectButtonText === 'Select All') {
            // this.dataToBeDeleted = [];
            data.map((item, index) => {
                filterData.forEach(product => {
                    if (
                        item.LoadNumber === product.LoadNumber &&
                        item.IsDeleted === false &&
                        !item.DELETE &&
                        loadStatus.includes(item.LoadStatus)
                    ) {
                        this.dataToBeDeleted.push({ ID: item.Id, Load: item.LoadNumber, DispatcherComments: item.DispatcherComments, LoadStatus: item.LoadStatus });
                        item.DELETE = true;
                        this.checkBoxSelectionCount = this.dataToBeDeleted.length;
                    }
                });
            });
            newNominationLoadData = data;
            this.selectButtonText = this.dataToBeDeleted.length!== 0 ? 'Deselect All' : 'Select All' ;
        } else {
            data.map((item, index) => {
                this.dataToBeDeleted = [];
                item.DELETE = false;
                this.selectButtonText = 'Select All';
            });
            newNominationLoadData = data;
        }
        this.nominationLoadData = newNominationLoadData;
    };
    @action
    handleCheckboxClick(data, value: any, colName: string): void {
        switch (colName) {
            case 'DELETE':
                data.DELETE = !value;
                break;
            default:
                break;
        }
        const existingArray = this.dataToBeDeleted.map(load => load.Load)
        if (!value) {
            if(!existingArray.includes(data.LoadNumber))
            this.dataToBeDeleted.push({ ID: data.Id, Load: data.LoadNumber, Type: data.LoadType, DispatcherComments: data.DispatcherComments, LoadStatus: data.LoadStatus });
            const Load = this.dataToBeDeleted.filter(x => x.ID > 0);
            this.orderBy(Load, 'asc');
            const newLoad = this.dataToBeDeleted.filter(x => x.ID <= 0);
            this.orderBy(newLoad, 'desc');
            this.dataToBeDeleted = [...Load, ...newLoad];
            if (this.checkBoxSelectionCount === this.dataToBeDeleted.length) {
                this.selectButtonText = 'Deselect All';
            }
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== data.Id);
            this.selectButtonText = 'Select All';
        }
    }

    @action
    showPlainsRatioPopUp() {
        this.isApproverPopUpVisible = true;
    }

    @action
    hidePlainsRatioPopUp() {
        this.isApproverPopUpVisible = false;
    }

    triggerModal(item) {
        this.crudeModel = item;
        this.showPlainsRatioPopUp();
    }

    updatePlainsRatioPopup(plainsRatio: any) {
        if (!this.crudeModel) {
            return;
        }
        this.setValueInChangeMap(
            this.crudeModel,
            'PlainsNominationVolume',
            this.crudeModel['PlainsNominationVolume'],
            plainsRatio
        );
        this.setValueInChangeMap(
            this.crudeModel,
            'OthersNominationVolume',
            this.crudeModel['OthersNominationVolume'],
            (100 - plainsRatio).toString()
        );
        this.crudeModel['PlainsNominationVolume'] = plainsRatio;
        this.crudeModel['OthersNominationVolume'] = 100 - plainsRatio;
        this.updateRow(this.crudeModel);
    }

    isNglLoad() {
        return this.selectedData.IsChild
            ? this.checkSupplierRole()
                ? this.selectedData.DataSource === 'Butane'
                    ? false
                    : true
                : false
            : true;
    }

    isDeleteDisabled() {
        if (this.dataToBeDeleted.length > 0) {
            let check = false;
            this.checkeddata = toJS(this.dataToBeDeleted);
            const data: any = Array.from(new Set(this.checkeddata.map((item: any) => item.ID)));
            data.forEach((x, ind) => {
                this.nominationLoadData.forEach(element => {
                    if (x === element.Id && element.IsLoad) {
                        if (!loadStatus.includes(element.LoadStatus) && element.LoadStatus !== null) {
                            check = true;
                        }
                    }
                });
            });
            return check;
        } else {
            return true;
        }
    }
    isEditLoadDisabled() {
        if (this.nominationLoadData.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    isNewDisabled() {
        return this.CustomerName && this.CustomerName !== '' ? (!this.checkSupplierRole() ? false : true) : true;
    }
    isEditDisabled() {
        return this.selectedData.IsChild
                   ? this.checkSupplierRole()
                         ? (this.selectedData.DataSource === 'Butane' || this.selectedData.DataSource === 'NGL' || this.selectedData.DataSource === 'Brine')
                            ? false
                             : true
                         : false
                    : true;
        
    }
    @action
    nomiData(nominationData) {
        this.nominaData = [];
        this.nominationData = nominationData;
        this.getVolumeSum(nominationData);
        const fullData = JSON.parse(JSON.stringify(this.fullNominationData));
        const section = nominationData.filter(name => {
            return name.customer;
        });
        if (section.customer === 'add-customer') {
            return;
        } else {
            const customer = Array.from(new Set(nominationData.map((item: any) => item.CustomerName)));
            customer.forEach((x: any) => {
                let data: any = [];
                const crudeData = [];
                if (this.selectedProduct !== 'Crude' && this.selectedProduct !== 'All') {
                    //&& this.selectedProduct !== 'All'
                    data = nominationData.filter(name => {
                        return name.CustomerName === x;
                    });
                }
                let alldata: any = [];
                const filtereddata: any = [];
                // if (accountStore.userRoles.includes('ptssuppliercrude')) {
                //     alldata = nominationData.filter(name => {
                //         return name.CustomerName === x;
                //     });
                // } else {
                alldata = nominationData.filter(name => {
                    return name.CustomerName === x;
                });
                // }
                // filtereddata = nominationData.filter(name => {
                //     return name.CustomerName === x;
                // });
                if (this.selectedProduct === 'Crude' || this.selectedProduct === 'All') {
                    if (this.selectedProduct === 'Crude') {
                        if (accountStore.userRoles.includes('ptssuppliercrude')) {
                            alldata = fullData.filter(name => {
                                return (
                                    name.CustomerName === x &&
                                    (name.DataSource === this.selectedProduct || name.DataSource === null) &&
                                    name.ShipperName === 'Plains Midstream Canada ULC'
                                );
                            });
                        } else {
                            alldata = fullData.filter(name => {
                                return (
                                    name.CustomerName === x &&
                                    (name.DataSource === this.selectedProduct || name.DataSource === null)
                                );
                            });
                        }
                    } else {
                        alldata = nominationData.filter(name => {
                            return name.CustomerName === x;
                        });
                    }
                    alldata = this.calculateCrude(alldata);
                    // filtereddata = this.calculateCrude(filtereddata);
                    data = alldata;
                    // data = alldata.filter(name => {
                    //     return name.IsSelected;
                    // });
                }

                // const value = {
                //     CustomerName: x,
                //     name: x,
                //     ContractNumber: this.getString(alldata, 'ContractNumber'),
                //     Product: this.getString(alldata, 'Product'),
                //     DataSource: this.getString(alldata, 'DataSource'),
                //     AX_Origin: this.getString(alldata, 'AX_Origin'),
                //     AX_Destination: this.getString(alldata, 'AX_Destination'),
                //     ApportionmentVolume: this.getSum(alldata, 'ApportionmentVolume'),
                //     NominationVolume: this.getSum(alldata, 'NominationVolume'),
                //     CurrentNomination: this.getSum(alldata, 'CurrentNomination'),
                //     PendingVolume: this.getSum(alldata, 'PendingVolume'),
                //     DispatchedVolume: this.getSum(alldata, 'DispatchedVolume'),
                //     CompletedVolume: this.getSum(alldata, 'CompletedVolume'),
                //     TotalLoadVol: this.getSum(alldata, 'TotalLoadVol'),
                //     VolsToBeTrucked: this.getSum(alldata, 'VolsToBeTrucked'),
                //     CreatedDate: null,
                //     ModifiedDate: null,
                //     IsSelected: true,
                //     IsExpanded: false
                // };
                // value.IsExpanded = data.length > 0 ? true : false;
                // data.unshift(value);
                this.rowfield[x] = data;
                this.nominaData = [...this.nominaData, ...this.rowfield[x]];
            });
            // if (
            //     new Date().getMonth() <= this.selectedMonth &&
            //     new Date().getFullYear() <= this.selectedYear &&
            //     new Date().getFullYear() <= this.selectedYear &&
            //     !this.checkSupplierRole()
            // ) {
            //     this.nominaData.unshift({
            //         customer: 'add-customer',
            //         CustomerName: '',
            //         IsSelected: false,
            //         CreatedDate: null,
            //         ModifiedDate: null,
            //         IsChild: false
            //     });
            // }
            // this.agGridService.getNodes()?.forEachNode(node =>  {
            //     if(node.group && node.key === this.CustomerName){
            //         node.expanded = true;
            //     }
            // })
        }
    }
    calculateCrudeWithCustomerName(data) {
        const groupData = {};
        const returnData: any = [];
        data.forEach(element => {
            if (element.DataSource == 'Crude') {
                const key =
                    element.Product +
                    '_' +
                    element.AX_Origin +
                    '_' +
                    element.AX_Destination +
                    '_' +
                    element.CustomerName +
                    '_' +
                    element.ToCode;
                if (!groupData.hasOwnProperty(key)) {
                    groupData[key] = element;
                } else {
                    if (!groupData[key].hasOwnProperty('TotalId') && groupData[key]['Id'] !== element.Id) {
                        groupData[key]['TotalId'] += element.Id;
                        groupData[key]['ApportionmentVolume'] += element.ApportionmentVolume;
                        groupData[key]['NominationVolume'] += element.NominationVolume;
                        groupData[key]['CurrentNomination'] += element.CurrentNomination;
                        groupData[key]['PendingVolume'] += element.PendingVolume;
                        groupData[key]['DispatchedVolume'] += element.DispatchedVolume;
                        groupData[key]['CompletedVolume'] += element.CompletedVolume;
                        groupData[key]['ApportionmentDate'] = element.ApportionmentDate;
                        groupData[key]['VolsToBeTrucked'] += element.VolsToBeTrucked;
                        groupData[key]['ShipperName'] = element.ShipperName;
                        groupData[key]['LoadAdjustmentFlag'] =
                            groupData[key]['LoadAdjustmentVolume'] *
                                (groupData[key]['LoadAdjustmentFlag'] === 0 ? -1 : 1) +
                                element.LoadAdjustmentVolume * (element.LoadAdjustmentFlag == 0 ? -1 : 1) >=
                            0
                                ? 1
                                : 0;
                        groupData[key]['LoadAdjustmentVolume'] = Math.abs(
                            groupData[key]['LoadAdjustmentVolume'] *
                                (groupData[key]['LoadAdjustmentFlag'] === 0 ? -1 : 1) +
                                element.LoadAdjustmentVolume * (element.LoadAdjustmentFlag == 0 ? -1 : 1)
                        );
                        groupData[key]['PlannedVolume'] += element.PlannedVolume;
                        groupData[key]['EditNomination'] += element.EditNomination;
                    }
                }
            } else if (element.DataSource == 'Brine' || element.DataSource == 'NGL') {
                const key =
                    element.Product +
                    '_' +
                    element.AX_Origin +
                    '_' +
                    element.AX_Destination +
                    '_' +
                    element.CustomerName + '_' + element.ContractNumber;
                if (!groupData.hasOwnProperty(key)) {
                    groupData[key] = element;
                    groupData[key].ContractNumber =
                        groupData[key].ContractNumber !== 'Dummy' ? element.ContractNumber : '';
                } else {
                    if (groupData[key]['Id'] !== element.Id && !element.mapped) {
                        groupData[key]['TotalId'] += element.Id;
                        groupData[key]['ContractNumber'] +=
                            element.ContractNumber !== 'Dummy' && element.ContractNumber !== null
                                ? ', ' + element.ContractNumber
                                : '';
                        element.mapped = true;
                    }
                }
            } else {
                returnData.push(element);
            }
        });
        Object.keys(groupData).forEach(key => {
            returnData.push(groupData[key]);
        });
        return returnData;
    }

    calculateCrude(data) {
        const groupData = {};
        const returnData: any = [];
        data.forEach(element => {
            if (element.DataSource == 'Crude') {
                const key =
                    element.Product + '_' + element.AX_Origin + '_' + element.AX_Destination + '_' + element.ToCode;
                if (!groupData.hasOwnProperty(key)) {
                    groupData[key] = element;
                    groupData[key]['TotalRatio'] = groupData[key]['TotalRatio']
                        ? groupData[key]['TotalRatio']
                        : element.VolumeRatio;
                } else {
                    if (!groupData[key].hasOwnProperty('TotalId') && groupData[key]['Id'] !== element.Id) {
                        groupData[key]['TotalId'] += element.Id;
                        groupData[key]['ApportionmentVolume'] += element.ApportionmentVolume;
                        groupData[key]['NominationVolume'] += element.NominationVolume;
                        groupData[key]['CurrentNomination'] += element.CurrentNomination;
                        groupData[key]['PendingVolume'] += element.PendingVolume;
                        groupData[key]['DispatchedVolume'] += element.DispatchedVolume;
                        groupData[key]['CompletedVolume'] += element.CompletedVolume;
                        groupData[key]['VolsToBeTrucked'] += element.VolsToBeTrucked;
                        groupData[key]['TotalRatio'] += element.VolumeRatio;
                        groupData[key]['LoadAdjustmentFlag'] =
                            groupData[key]['LoadAdjustmentVolume'] *
                                (groupData[key]['LoadAdjustmentFlag'] === 0 ? -1 : 1) +
                                element.LoadAdjustmentVolume * (element.LoadAdjustmentFlag == 0 ? -1 : 1) >=
                            0
                                ? 1
                                : 0;
                        groupData[key]['LoadAdjustmentVolume'] = Math.abs(
                            groupData[key]['LoadAdjustmentVolume'] *
                                (groupData[key]['LoadAdjustmentFlag'] === 0 ? -1 : 1) +
                                element.LoadAdjustmentVolume * (element.LoadAdjustmentFlag == 0 ? -1 : 1)
                        );
                        groupData[key]['PlannedVolume'] += element.PlannedVolume;
                        groupData[key]['EditNomination'] += element.EditNomination;
                    }
                }
            } else if (element.DataSource == 'Brine' || element.DataSource == 'NGL') {
                const key =
                    element.Product +
                    '_' +
                    element.AX_Origin +
                    '_' +
                    element.AX_Destination +
                    '_' +
                    element.CustomerName + '_' + element.ContractNumber;
                if (!groupData.hasOwnProperty(key)) {
                    groupData[key] = element;
                    groupData[key].ContractNumber =
                        groupData[key].ContractNumber !== 'Dummy' ? element.ContractNumber : '';
                } else {
                    if (groupData[key]['Id'] !== element.Id && !element.mapped) {
                        groupData[key]['TotalId'] += element.Id;
                        groupData[key]['ContractNumber'] +=
                            element.ContractNumber !== 'Dummy' && element.ContractNumber !== null
                                ? ', ' + element.ContractNumber
                                : '';
                        element.mapped = true;
                    }
                }
            } else {
                returnData.push(element);
            }
        });
        Object.keys(groupData).forEach(key => {
            returnData.push(groupData[key]);
        });
        return returnData;
    }

    getApportionmentVolume(data) {
        if (this.apportionmentData.length > 0 && data.IsChild && data.DataSource === 'Crude') {
            const filterData = this.apportionmentData.filter(x => {
                return (
                    (x.FacilityCode == data.ToCode && x.Product === data.Product) ||
                    (x.ToFacilityDescription == data.Destination && x.Product === data.Product)
                );
            });
            if (filterData.length > 0) {
                if (
                    data.customer === 'add-customer' ||
                    data['PendingVolume'] + data['DispatchedVolume'] + data['CompletedVolume'] === 0
                ) {
                    return null;
                } else if (filterData[0].Apportionment > 0) {
                    return data.ShipperName === 'Plains Midstream Canada ULC'
                        ? data['PendingVolume'] +
                              data['DispatchedVolume'] +
                              data['CompletedVolume'] -
                              (filterData[0].Apportionment / 100) *
                                  (data['PendingVolume'] + data['DispatchedVolume'] + data['CompletedVolume'])
                        : data['PendingVolume'] + data['DispatchedVolume'] + data['CompletedVolume'];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    addCustomerButton() {
        uiService.toastService.info('On adding New Customer, It is mandatory to add nomination for that customer');
        const nominataion = {
            CustomerName: '',
            name: '',
            ContractNumber: null,
            Product: '',
            AX_Origin: '',
            AX_Destination: '',
            NominationVolume: null,
            CurrentNomination: null,
            VolsToBeTrucked: null,
            IsSelected: true,
            IsExpanded: true,
            IsEdit: true,
            IsChild: true,
            PendingVolume: null,
            DispatchedVolume: null,
            DataSource: null,
            CompletedVolume: null,
            NominationType: 'Manual',
            Id: 0
        };
        if (this.nominationData.length > 0) {
            const minID: number = this.nominationData[this.nominationData.length - 1].Id;
            if (minID <= 0) {
                nominataion.Id = minID - 1;
            }
        }
        this.nominationData.unshift(nominataion);
        if (this.selectedProduct === 'Crude') this.fullNominationData.unshift(nominataion);
        this.nomiData(this.nominationData);
        this.isCustomerAddButton = true;
    }

    getSum(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && x[column] !== null) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }

    getString(data, column) {
        let str = '';
        data.forEach(x => {
            if (!(str.indexOf(x[column]) !== -1) && x[column] !== null) {
                str += x[column];
            }
        });
        return str;
    }

    @action
    addRow(loadData): void {
        this.nominationLoadData = loadData;
        const printer = {
            LoadNumber: '',
            Pending: '',
            PlannedDateTime: '',
            DispatcherComments: '',
            Dispatched: '',
            Completed: ''
        };
        this.nominationLoadData.unshift(printer);
    }

    @action
    updateLocation(item, value: string, key: string) {
        this.setValueInChangeMap(item.Id, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
        // this.nomiData(this.nominationData);
    }

    @action
    updateDispatcherComment(item, value: string, key: string) {
        this.setValueInChangeMap(item.Id, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateEditNomination(item, value: string, key: string) {
        this.setValueInChangeMap(item.Id, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }

        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue)) {
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        } else {
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
        }
    }

    @action
    updateRow = (selectedRowData): void => {
        const updatedDriverIndex = this.nominationData.findIndex(a => a.Id == selectedRowData.Id);
        if (
            selectedRowData.IsEdit &&
            this.nominationData[updatedDriverIndex].DataSource !== selectedRowData.DataSource &&
            selectedRowData.DataSource === 'Crude'
        ) {
            this.triggerModal(selectedRowData);
        }
        this.mapEditableColumns(this.nominationData[updatedDriverIndex], selectedRowData);
    };

    @action
    updateLoadRow = (selectedRowData): void => {
        const updatedDriverIndex = this.nominationLoadData.findIndex(a => a.Id == selectedRowData.Id);
        this.mapLoadEditableColumns(this.nominationLoadData[updatedDriverIndex], selectedRowData);
        this.isLoadRowEdited = true;
    };

    mapLoadEditableColumns(currentItem, updatedItem) {
        currentItem.Pending = updatedItem.Pending ? parseFloat(updatedItem.Pending) : null;
        currentItem.PlannedDateTime = updatedItem.PlannedDateTime;
        currentItem.DispatcherComments = updatedItem.DispatcherComments;
        currentItem.DriverName = updatedItem.DriverName;
    }

    mapEditableColumns(currentItem, updatedItem) {
        currentItem.Id = updatedItem.Id;
        currentItem.CustomerName = updatedItem.CustomerName;
        currentItem.ContractNumber = updatedItem.ContractNumber;
        currentItem.Product = updatedItem.Product;
        currentItem.AX_Origin = updatedItem.AX_Origin;
        currentItem.AX_Destination = updatedItem.AX_Destination;
        currentItem.Origin = updatedItem.AX_Origin;
        currentItem.Destination = updatedItem.AX_Destination;
        currentItem.DataSource = updatedItem.DataSource;
        currentItem.ShipperName = updatedItem.ShipperName;
        currentItem.NominationComment = updatedItem.Comment ? updatedItem.Comment : '';
        currentItem.PlainsNominationVolume = updatedItem.PlainsNominationVolume
            ? parseInt(updatedItem.PlainsNominationVolume)
            : 0;
        currentItem.OthersNominationVolume = updatedItem.OthersNominationVolume
            ? parseInt(updatedItem.OthersNominationVolume)
            : 0;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }
    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return this.isCustomerAddButton ? false : true;
    };

    getVolumeSum(data) {
        let volumeCount = 0,
            vttSum = 0,
            tovSum = 0,
            completedSum = 0,
            completedPercentage = 0;
        for (const report in data) {
            data[report].CurrentNomination = data[report].CurrentNomination > 0 ? data[report].CurrentNomination : 0.0
            data[report].PendingVolume = data[report].PendingVolume > 0 ? data[report].PendingVolume : 0.0
            data[report].DispatchedVolume = data[report].DispatchedVolume > 0 ? data[report].DispatchedVolume : 0.0
            data[report].CompletedVolume = data[report].CompletedVolume > 0 ? data[report].CompletedVolume : 0.0
            volumeCount += data[report].CurrentNomination;
            vttSum += data[report].VolsToBeTrucked;
            tovSum += data[report].PendingVolume + data[report].DispatchedVolume + data[report].CompletedVolume;
            completedSum += data[report].CompletedVolume;
        }
        this.VolumeSum.NominationSum = volumeCount.toFixed(3);
        this.VolumeSum.TOVSum = tovSum.toFixed(3);
        this.VolumeSum.VTTSum = vttSum.toFixed(3);
        this.VolumeSum.CompletedSum = completedSum.toFixed(3);
        this.VolumeSum.Completedpercentage = completedSum <= 0 ? '0.000' : vttSum <= 0 ? tovSum <= 0 ? '0.000' : ((completedSum * 100) / tovSum).toFixed(3) : ((completedSum * 100) / vttSum).toFixed(3)
    }
}
export const nominationStore = new NominationStore(nominationDataService, agGridService, accountStore, uiService);
