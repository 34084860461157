import React, { Component } from 'react';
import { PrinterDataStore } from '../stores/printer-data-store';
import { GridOptions } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from '../../common/stores/index';
import { PrinterSaveLabel } from '../domains/printer-management/printer-enum';
import { RESET, ADD_NEW_ROW, DELETE_ITEM } from '../data_constant';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';

interface PrinterMasterDataProps {
    printerStore: PrinterDataStore;
}

@observer
export class PrinterMasterDataComponent extends Component<PrinterMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('PrinterManagement');
    }

    componentDidMount() {
        this.props.printerStore.init();
    }

    componentWillUnmount() {
        this.props.printerStore.reset();
    }

    render(): React.ReactNode {
        const {
            printerStore: { isSaveDisabled, isDeleteEnabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {PrinterSaveLabel.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                {RESET}
                                            </CustomButton>

                                            <CustomButton type={CustomButtonType.Submit} onClick={this.addNewRow}>
                                                {ADD_NEW_ROW}
                                            </CustomButton>

                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {DELETE_ITEM}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }
    componentDidUpdate() {
        const filters = localStorageService.get('PrinterManagement_filter');
        this.props.printerStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        const { printerStore } = this.props;
        printerStore.updatePrinters();
    };

    private handleReset = (): void => {
        const { printerStore } = this.props;
        printerStore.resetPrinterList();
    };

    private addNewRow = (): void => {
        const { printerStore } = this.props;
        printerStore.addRow();
    };

    private deleteitems = (): void => {
        const { printerStore } = this.props;
        printerStore.deleteRows();
    };

    handleRefresh = () => {
        const { printerStore } = this.props;
        if(Object.keys(printerStore.cellValueChangeMap).length === 0) {
            printerStore.init();
            printerStore.dataToBeDeleted = [];
            printerStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    printerStore.init();
                    printerStore.dataToBeDeleted = [];
                    printerStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    private getGridConfig(): GridOptions {
        const {
            printerStore: { printerList, agGridService, getColDef }
        } = this.props;

        const rowData = printerList;
        const columnDefs = getColDef();

        const PrinterMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs
        };
        return agGridService.getGridConfig(PrinterMaintenanceGridParams);
    }
}
