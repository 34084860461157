import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { masterTicketReportStore } from '../stores';
import { uiService } from 'shared/services/ui-service';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class MasterReportCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data.SELECTED ? props.data.SELECTED : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }
    
    handleRadioButtonChange() {
        this.setState({ value: !this.state.value });
        masterTicketReportStore.selectedTickets(this.props.data, this.state.value, this.props.column.getColId());
        if(masterTicketReportStore.selectedticketsList.length > 10){
            uiService.toastService.error("Please select maximum of 10 tickets to download at a time")
        }
    };
      
      render() {
        return ( 
            <input 
            onChange={this.handleRadioButtonChange}
            type="checkbox" 
            checked={this.state.value} />
         )
      }
}