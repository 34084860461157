
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { CompanyResponse } from '../model/model';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';

interface DispatchPlanProps {
    selectedCarrierId: number;
    carrierName: string;
    carriers: CompanyResponse[]
    onHide: () => void;
    onSelectionChange: (carrierName: string, buttonName: string) => void;
}

interface DispatchPlanState {
    selectedCarrierId: number;
    selectedCarrierName: string;
}

@observer
export class DispatchPlanCarrierComponent extends Component<DispatchPlanProps, DispatchPlanState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedCarrierId: 0,
            selectedCarrierName: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedCarrierId != this.state.selectedCarrierId) {
            this.setState({ selectedCarrierId: this.props.selectedCarrierId, selectedCarrierName: this.state.selectedCarrierName });
        }
    }

    mapCarrierToCarrierOption = carriers => DataAdapterService.getOptionValues(carriers, 'CompanyName', 'ID', false);

    getSelectedShopOption = (id, carriers) => {
        let selectedShopObj = carriers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { carriers } = this.props;
        const { selectedCarrierId, selectedCarrierName } = this.state;
        const shopOptions = this.mapCarrierToCarrierOption(carriers);
        const selectedShopOption = this.getSelectedShopOption(selectedCarrierId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Carrier Name</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Carrier"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignShop}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => {
        this.setState({ selectedCarrierName: option.label, selectedCarrierId: option.value });
    }

    assignShop = () => {
        var selectedDriverOrCarrierID;
        const carrierName = this.props.carriers.findIndex(a => a.CompanyName == this.state.selectedCarrierName);
        selectedDriverOrCarrierID = this.props.carriers[carrierName].ID;
        const confirmService = new ConfirmService();
        confirmService.confirmBulkAssignPopup(
            (buttonName) => {
                this.props.onSelectionChange(selectedDriverOrCarrierID, buttonName == 'DispatchLoad' ? 'CarrierDispatchLoad' : 'SaveData');
                this.props.onHide();
            }
        );
    };
}
