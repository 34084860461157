import { action, computed, observable, toJS } from 'mobx';
import { UiService, uiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { LocationMapingResponse } from '../domains/location-maping-management/model';
import { LocationMapingFieldName, LocationMapingMessage } from '../domains/location-maping-management/enum';
import { locationMapColDef } from '../domains/location-maping-management/location-map-col-def';
import { LocationMapingDataService } from '../domains/location-maping-management/location-map-data-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import _ from 'lodash';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { LocationFieldName } from '../domains/location-management/enum';

export class LocationMapingDataStore {
    @observable locationData: LocationMapingResponse[] = [];
    userID = 0;
    userName = '';
    backupLocationData: LocationMapingResponse[] = [];
    @observable cellValueChangeMap = {};
    lastId: any;
    @observable dataToBeDeleted: any = [];
    dataToBeDeletedID: any = [];

    constructor(
        private locationMapingDataService: LocationMapingDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.getLocationMapingData();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return locationMapColDef;
    }

    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FETCH_ERROR_MESSAGE))
    async getLocationMapingData(): Promise<void> {
        const locationData = await this.locationMapingDataService.getLocationMapingScreenData();
        this.setLocationData(locationData);
        this.setBackupLocationData(locationData);
        this.lastId = locationData.pop()?.Id;
    }

    setBackupLocationData(backUpList: LocationMapingResponse[]) {
        this.backupLocationData = backUpList;
    }

    @action
    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FAILED_SUBMIT))
    async showAXPopUp(eventData): Promise<void> {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let reqbody = {
            AxOrRAData: 'All'
        }
        const location: any = (await this.locationMapingDataService.getOperator(reqbody))
        const broadcastReceiver = location.filter(x => x.SourceSystem !== 'RA');
        uiService.loaderService.hideLoader();
        confirmService.showLocationAXPopup(async location => {
            const reqbody = [
                {
                    Id: eventData.Id,
                    SourceLocation: eventData.SourceLocation,
                    SourceLocCode: eventData.SourceLocCode,
                    SourceLocLSD: eventData.SourceLocLSD,
                    AX_Location: location,
                    TruckedByLocations: eventData.TruckedByLocations,
                    CreatedBy: this.accountStore.getUserID(),
                    CreatedDate: moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss'),
                    IsAXorRA: "AX"
                }
            ];
            const request = {
                locationMappingRequest: reqbody,
                DeleteRecords: [],
                ModifiedBy: this.userID
            };
            uiService.loaderService.showLoader();
            await this.locationMapingDataService.sendLocationMapping(request);
            await this.getLocationMapingData();
            uiService.loaderService.hideLoader();
        }, toJS(broadcastReceiver));
    }

    @action
    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FAILED_SUBMIT))
    async showRAPopUp(eventData): Promise<void> {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let reqbody = {
            AxOrRAData: 'All'
        }
        const location: any = (await this.locationMapingDataService.getOperator(reqbody))
        const broadcastReceiver = location.filter(x => x.SourceSystem === 'RA');
        uiService.loaderService.hideLoader();
        confirmService.showLocationAXPopup(async location => {
            const reqbody = [
                {
                    Id: eventData.Id,
                    SourceLocation: eventData.SourceLocation,
                    SourceLocCode: eventData.SourceLocCode,
                    SourceLocLSD: eventData.SourceLocLSD,
                    AX_Location: location,
                    TruckedByLocations: eventData.TruckedByLocations,
                    CreatedBy: this.accountStore.getUserID(),
                    CreatedDate: moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss'),
                    IsAXorRA: "RA"
                }
            ];
            const request = {
                locationMappingRequest: reqbody,
                DeleteRecords: [],
                ModifiedBy: this.userID
            };
            uiService.loaderService.showLoader();
            await this.locationMapingDataService.sendLocationMapping(request);
            await this.getLocationMapingData();
            uiService.loaderService.hideLoader();
        }, toJS(broadcastReceiver));
    }

    @action
    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FAILED_SUBMIT))
    async addRow(): Promise<void> {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let reqbody = {
            AxOrRAData : ''
        }
        const broadcastReceiver: any = await this.locationMapingDataService.getOperator(reqbody);
        const raConfig: any = await this.locationMapingDataService.getRAConfig();
        const configFlag: any = raConfig.filter((item: any) => item.Code === 'RAIntegration');
        uiService.loaderService.hideLoader();
        confirmService.showLocationMapingPopup(
            async (sourceLoc, sourceLocCode, sourceLocLSD, AXLoc, Datasource, truckedByLocation) => {
                const reqbody = [
                    {
                        SourceLocation: sourceLoc ? sourceLoc : '',
                        SourceLocCode: sourceLocCode ? sourceLocCode : '',
                        SourceLocLSD: sourceLocLSD ? sourceLocLSD : '',
                        AX_Location: AXLoc,
                        FuelType: Datasource,
                        TruckedByLocations: truckedByLocation && truckedByLocation === 'Yes' ? true : false,
                        CreatedBy: this.accountStore.getUserID(),
                        CreatedDate: moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss'),
                        IsAXorRA: configFlag[0]?.Value
                    }
                ];
                if (!this.accountStore.getUserID() || this.accountStore.getUserID() === 0) {
                    await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                        this.userID = this.accountStore.getUserID();
                    });
                    if (!this.userID || this.userID === 0) {
                        return;
                    }
                }
                uiService.loaderService.showLoader();
                await this.locationMapingDataService.sendLocationMapping({
                    locationMappingRequest: reqbody,
                    DeleteRecords: [],
                    ModifiedBy: this.userID
                });
                await this.getLocationMapingData();
                uiService.loaderService.hideLoader();
            },
            toJS(broadcastReceiver),
            this.locationMapingDataService
        );
    }

    @computed
    get LocationData(): LocationMapingResponse[] {
        return toJS(this.locationData);
    }

    @action
    setLocationData(locationData: LocationMapingResponse[]): void {
        this.locationData = locationData;
    }

    @action
    reset(): void {
        this.setLocationData(this.backupLocationData);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;

        console.log(toJS(this.cellValueChangeMap));
    }

    @action
    updateRow = selectedRowData => {
        console.log('updateRow', selectedRowData);
        const updatedDriverIndex = this.locationData.findIndex(a => a.Id == selectedRowData.Id);
        if (!_.isEqual(this.locationData[updatedDriverIndex], selectedRowData)) {
            this.mapEditableColumns(this.locationData[updatedDriverIndex], selectedRowData);
        }
    };

    mapEditableColumns(currentItem, updatedItem) {
        currentItem.SourceLocation = updatedItem.SourceLocation;
        currentItem.SourceLocCode = updatedItem.SourceLocCode;
        currentItem.SourceLocLSD = updatedItem.SourceLocLSD;
    }

    updateLocationRequest(updatedRows) {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            //item.ModifiedDate = ;
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                if (LocationMapingFieldName.DELETE != col) {
                    const obj = this.cellValueChangeMap[row][col];
                    if (obj.initValue !== obj.currentValue) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FAILED_SUBMIT))
    @action
    async updateDrivers(): Promise<void> {
        // const updatedRowIDs = this.locationData.filter(a => this.getUpdatedRowIDs().includes(a.Id.toString()));
        const updatedRows = toJS(this.cellValueChangeMap);
        if (!this.userID || this.userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0) {
                return;
            }
        }
        const updatedRowIDs: any = [];
        Object.keys(updatedRows).forEach(x => {
            let a: any = {};
            a = { Id: parseInt(x) };
            const locationdata = toJS(this.locationData);
            const data1 = this.locationData.filter(a => a.Id.toString() === x);
            console.log(toJS(data1), data1[0]['TruckedByLocations']);
            a['TruckedByLocations'] = data1[0]['TruckedByLocations'];
            // a['DeleteRecords'] = [];
            Object.keys(updatedRows[x]).forEach(data => {
                console.log(data);
                a[data] = updatedRows[x][data]['currentValue'];
            });
            updatedRowIDs.push(a);
        });
        console.log(updatedRowIDs);

        // const requestBody = this.updateLocationRequest(updatedRowIDs);
        const response = await this.locationMapingDataService.sendLocationMapping({
            locationMappingRequest: updatedRowIDs,
            DeleteRecords: [],
            ModifiedBy: this.userID
        });
        // if (response) {
        //     for (const key in response) {
        //         if (response[key].Id) {
        await this.getLocationMapingData();
        //         }
        //     }
        // }
        this.cellValueChangeMap = {};
        this.uiService.toastService.success(LocationMapingMessage.SAVE);
    }

    @action
    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.DELETEDERROR))
    async deleteRows(): Promise<void> {
        if (this.dataToBeDeletedID.length > 0) {
            const updateData = this.locationData.filter(x => !this.dataToBeDeleted.filter(y => y.ID === x.Id).length);
            await this.locationMapingDataService.sendLocationMapping({
                locationMappingRequest: [],
                DeleteRecords: this.dataToBeDeletedID,
                ModifiedBy: this.userID
            });
            this.locationData = updateData;
            this.dataToBeDeleted = [];
            this.cellValueChangeMap = {};
            this.isDeleteEnabled();
            this.uiService.toastService.success(LocationMapingMessage.DELETEDSUCCESS);
        } else errorHandler(LocationMapingMessage.NO_ROW_SELECTED);
    }
    @action
    isDeleteEnabled = (): boolean => {
        console.log('deletebutton', this.dataToBeDeleted);
        const isEnabled = this.dataToBeDeleted.length > 0 ? false : true;
        return isEnabled;
    };

    @action
    handleCheckboxClick(location: LocationMapingResponse, value: any, colName: string): void {
        console.log('selected');
        const row = location.Id;
        const initValue = location[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        console.log(location.IsDeleted);
        switch (colName) {
            case LocationMapingFieldName.DELETE:
                location.IsDeleted = !value;
                break;
            default:
                break;
        }
        console.log(location.IsDeleted);
        if (location.IsDeleted) {
            this.dataToBeDeleted.push({ ID: location.Id });
            this.dataToBeDeletedID.push(location.Id);
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== location.Id);
            this.dataToBeDeletedID = this.dataToBeDeletedID.filter(a => a !== location.Id);
        }
    }

    @action
    updateComment(item, value: string, key: string) {
        this.setValueInChangeMap(item.Id, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    @Loader
    @Catch(() => errorHandler(LocationMapingMessage.FAILED_SUBMIT))
    async handleTruckedByLocations(location: LocationMapingResponse, value: any, colName: string) {
        const row = location.Id;
        const initValue = location[colName];
        // this.setValueInChangeMap(row, colName, initValue, value);
        console.log(location.TruckedByLocations);
        switch (colName) {
            case LocationMapingFieldName.TRUCKEDBYLOCATIONS:
                location.TruckedByLocations = !value;
                break;
            default:
                break;
        }
        console.log(location.TruckedByLocations);
        const updatedRow: any = this.locationData.filter(a => a.Id === location.Id);
        console.log(toJS(updatedRow), location.TruckedByLocations);
        const req = [
            {
                Id: updatedRow[0].Id,
                TruckedByLocations: location.TruckedByLocations,
                AX_Location: updatedRow[0].AX_Location
            }
        ];
        updatedRow[0].TruckedByLocations = location.TruckedByLocations;
        const response = await this.locationMapingDataService.sendLocationMapping({
            locationMappingRequest: req,
            DeleteRecords: [],
            ModifiedBy: this.userID
        });
        // if (response) {
        await this.getLocationMapingData();
        // }
        this.cellValueChangeMap = {};
        this.uiService.toastService.success(LocationMapingMessage.SAVE);
    }
}
