/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import moment from 'moment';
import { accountStore } from 'features/account/stores';
import { LocationDataStore } from 'features/data_management/stores/location-data-store';
import { LocationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { inject } from 'mobx-react';
import { locationStore } from 'features/data_management/stores';
import { ConfirmService } from './confirm-service';
import { Console } from 'console';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (selectedDispatchType, driver, trailer, buttonName) => void;
    msg;
    trailerData;
    selectedDispatchType;
}
export class BulkAssignPopUp extends React.Component<ConfirmProps> {
    state = {
        driverPlaceholder: this.props.selectedDispatchType === 'Driver' ? "Select Driver" : "Select Carrier",
        trailerPlaceholder: "Select Trailer",
    };
    selectedDriver: any;
    selectedTrailer: any;


    public render(): React.ReactNode {
        const { onClose, msg, trailerData } = this.props;
        const driverOrCarrierOptions  = this.props.selectedDispatchType === 'Driver' ? Array.from(new Set(msg.map((item: any) => item.DriverName))) : Array.from(new Set(msg.map((item: any) => item.CompanyName)))
        const trailer = Array.from(new Set(trailerData.map((item: any) => item.TrailerNumber)));

        return (
            <div>
                <div className="location">
                    <Row>
                        <Col>
                            <div >
                                Select {this.props.selectedDispatchType}:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedDriver}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.driverPlaceholder}
                                        options={driverOrCarrierOptions ? driverOrCarrierOptions: msg}
                                        onChange={this.handleDriverChange}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                            <div>
                                Select Trailer(optional):{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedTrailer}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.trailerPlaceholder}
                                        options={trailer ? trailer : trailerData}
                                        onChange={this.handleTrailerChange}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="confirm__action mt-10">
                            <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick} disabled={!this.selectedDriver}>
                                Assign
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>

                </div>
            </div>
        );
    }

    handleDriverChange = option => {
        this.selectedDriver = option;
        this.setState({ driverPlaceholder: option})
    };

    handleTrailerChange = option => {
        this.selectedTrailer = option;
        this.setState({ trailerPlaceholder: option })
    };

    handleClick = () => {
        const { msg } = this.props;
        var selectedDriverOrCarrierID;
        if(this.props.selectedDispatchType === 'Driver') {
            const driverName = msg.findIndex(a => a.DriverName == this.state.driverPlaceholder);
            selectedDriverOrCarrierID = msg[driverName].DriverID;
        } else {
            const carrierName = msg.findIndex(a => a.CompanyName == this.state.driverPlaceholder);
            selectedDriverOrCarrierID = msg[carrierName].ID;
        }
        const confirmService = new ConfirmService();
        confirmService.confirmBulkAssignPopup(
            (buttonName) => {
                this.props.onSuccessClick(this.props.selectedDispatchType, selectedDriverOrCarrierID, this.state.trailerPlaceholder !== 'Select Trailer' ? this.state.trailerPlaceholder : '', this.props.selectedDispatchType === 'Carrier' ? buttonName === 'DispatchLoad' ?  'CarrierDispatchLoad' : buttonName : buttonName)
                this.props.onClose();
            }
        );
    };
}
