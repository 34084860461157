import React from 'react';
import { DispatchComponentName } from './constants/constant';
import { DefaultPageComponent } from 'features/common/components/default-page-component';
import { CommunicationComponent } from '../communication/components/communication-component';
import { NominationComponent } from '../nomination/components/nomination-component';
import { CapacityPlanComponent } from '../capacityPlan/components/capacityPlan-component';
import { capacityPlanDataStore } from '../capacityPlan/store/index';
import { DispatchBoardComponent } from '../dispatch-board/components/dispatch-board-component'
import { communicationDataStore, dispatchPlanDataStore } from '../dispatch-plan-management/stores';
import { DispatchPlanContainer } from '../dispatch-plan-management/components/dispatchPlan-container';
import { nominationStore } from '../nomination/stores/nominatation-data-store';
import { ApportionmentComponent } from '../apportionment/components/apportionment-component';
import { apportionmentStore } from '../apportionment/stores/apportionment-data-store';
import { DispatchScreen } from '../dispatch-screen/components/dispatch-screen';
import { dispatchScreenDataStore } from '../dispatch-screen/stores';
export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        DispatchComponentName.Select,
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.Communication,
        () => (
            <React.Fragment>
                <CommunicationComponent communicationDataStore={communicationDataStore} />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.Nomination,
        () => (
            <React.Fragment>
                <NominationComponent nominationDataStore={nominationStore} />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.CapacityPlan,
        () => (
            <React.Fragment>
                <CapacityPlanComponent capacityPlanDataStore={capacityPlanDataStore} />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.DispatchBoard,
        () => (
            <React.Fragment>
                <DispatchBoardComponent />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.Apportionment,
        () => (
            <React.Fragment>
                <ApportionmentComponent apportionmentStore={apportionmentStore} />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.DispatchPlan,
        () => (
            <React.Fragment>
                <DispatchPlanContainer dispatchPlanDataStore={dispatchPlanDataStore} />
            </React.Fragment>
        )
    ],
    [
        DispatchComponentName.DispatchScreen,
        () => (
            <React.Fragment>
                <DispatchScreen dispatchScreenDataStore={dispatchScreenDataStore}/>
            </React.Fragment>
        )
    ]
]);
