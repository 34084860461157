import React, { Component } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { dataStore } from 'features/common/stores';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import './_dispatch-plan-date-component.scss';
import Select from 'react-select';
import { CommonUtils } from 'shared/services/common-utils';
import { inject, observer } from 'mobx-react';


export interface CompleteTicketPopUpProps {
    onHide: () => void;
    data: any;
    products: any;
    units: any;
    onSave: (data) => void;
}
 export interface TicketPopupProps {
    actualVol: number;
    selectedProduct: number;
    selectedUnit: number;
    carrierTicket: string;
    bswValue: number;
    isValid: boolean
    
 }
@inject('accountStore')
@observer
export class CompleteTicketPopupComponent extends Component<CompleteTicketPopUpProps, TicketPopupProps> {
    selectedProduct: any;
    selectedUnit: any;
    actualVolume: any;
    shipperNumber: any;
    isError: any;
    bswValue: any;
    constructor(props) {
        super(props);
        this.state = {
            actualVol: 0,
            selectedProduct: 0,
            selectedUnit: 0,
            carrierTicket: '',
            bswValue: 0,
            isValid: true
        };
    }


    render(): React.ReactNode {
        const { onHide } = this.props
        return (

            <div className="modal" id="ticket-modal">
                <div id="ticket-modal-content">
                    <div className="ticket-modal-body" id="ticket-modal-body">
                        <div className="ticket-container-header">

                            <div style={
                                { 'paddingLeft': '16px' }
                            }>
                                {dataStore.checkOperationAccess('Save') && (
                                    <b>
                                        TICKET DETAILS
                                    </b>
                                )}
                            </div>
                        </div>
                        <Container>
                            <Row style={
                                { 'textAlign': 'left' }}>
                                <Col className="mt-3">
                                    <b>Ticket Number: </b>{this.props.data[0].LoadNumber}
                                </Col>
                                <Col className="mt-3">
                                    <b>Origin: </b>{this.props.data[0].AX_Origin}
                                </Col>
                            </Row>
                            <Row style={
                                { 'textAlign': 'left' }}>
                                <Col className="mt-3">
                                    <b>Destination: </b>{this.props.data[0].AX_Destination}
                                </Col>
                                <Col className="mt-3">
                                    <b>DataSource: </b>{this.props.data[0].DataSource}
                                </Col>
                            </Row>
                        </Container>
                        <br />
                        <Container>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p><b style={{ color: 'red' }}>*</b>
                                                    <b> Carrier Ticket Number: </b>
                                                </p>
                                                <p>
                                                    <input className="completeTicket_input" onChange={this.handleShipperNumber}></input>
                                                </p>
                                            </div>{' '}
                                        </Col>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p><b style={{ color: 'red' }}>*</b>
                                                    <b> Product Loaded: </b>

                                                </p>
                                                {/* <p> */}
                                                <Select
                                                    value={this.selectedProduct}
                                                    closeMenuOnSelect={true}
                                                    closeMenuOnScroll={true}
                                                    placeholder={'Select Product'}
                                                    options={this.props.products}
                                                    onChange={this.handleProductChange}
                                                />
                                            </div>{' '}
                                        </Col>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p>
                                                    <b> Estimated Volume: </b>

                                                </p>
                                                <p>
                                                    <input className="completeTicket" value={this.props.data[0].EstimatedVolume} disabled></input>
                                                </p>
                                            </div>{' '}
                                        </Col>{' '}
                                    </Row>
                                    <Row>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p> <b style={{ color: 'red' }}>*</b>
                                                    <b> Actual Volume: </b>
                                                </p>
                                                <p>
                                                    <input className="completeTicket_input" type='number' onChange={this.handleActVolume} value={this.actualVolume} ></input>
                                                </p>
                                            </div>{' '}
                                        </Col>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p><b style={{ color: 'red' }}>*</b>
                                                    <b> Units: </b>

                                                </p>
                                                {/* <p> */}
                                                <Select
                                                    value={this.selectedUnit}
                                                    closeMenuOnSelect={true}
                                                    closeMenuOnScroll={true}
                                                    placeholder={'Select Units'}
                                                    options={this.props.units}
                                                    onChange={this.handleUnitChange}
                                                />
                                            </div>{' '}
                                        </Col>
                                        <Col className="mt-3">
                                            <div className="react-select-inner-container-new">
                                                <p> <b style={{ color: 'red' }}>*</b>
                                                    <b> BSW: </b>
                                                </p>
                                                <p>
                                                    <input className="completeTicket_input" type='number' onChange={this.handleBSWValue} value={this.bswValue} ></input>
                                                </p>
                                            </div>{' '}
                                        </Col>
                                        {' '}
                                    </Row>{' '}
                                    <Row>
                                        {this.isError && (
                                            <div style={{ fontSize: '14px', color: 'red', padding: '16px 0 0 16px' }}>
                                                Please fill all required fields
                                            </div>
                                        )
                                        }
                                    </Row>
                                </Col>
                            </Row>{' '}
                        </Container>
                        <br />
                        <Row style={{ textAlign: 'center' }}>
                            <Col>
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={onHide}

                                >
                                    CANCEL
                                </CustomButton>
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.saveData}

                                >
                                    COMPLETE LOAD
                                </CustomButton>
                            </Col>
                        </Row>
                    </div>{' '}
                </div>{' '}
            </div>

        );
    }
    handleProductChange = option => {
        this.selectedProduct = option
        this.setState({ selectedProduct: option.value })
    }
    handleUnitChange = option => {
        this.selectedUnit = option 
        this.setState({ selectedUnit: option.value })
    }
    handleActVolume = event => {
        const t = event.target.value
        this.actualVolume = (t.indexOf(".") >= 0) ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4) : t;
        this.setState({ actualVol: this.actualVolume })
    }

    handleBSWValue = event => {
        const t = event.target.value
        this.bswValue = (t.indexOf(".") >= 0) ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
        this.setState({ bswValue: this.bswValue })
    }
    handleShipperNumber = event => {
        this.shipperNumber = event.target.value
        this.setState({ carrierTicket: this.shipperNumber })
    }


    saveData = async () => {
        const isFilled = CommonUtils.checkIfFieldIsNullOrEmpty(this.shipperNumber) || CommonUtils.checkIfFieldIsNullOrEmpty(this.selectedProduct?.label)
            || CommonUtils.checkIfFieldIsNullOrEmpty(this.actualVolume) || CommonUtils.checkIfFieldIsNullOrEmpty(this.selectedUnit?.label)
            || CommonUtils.checkIfFieldIsNullOrEmpty(this.bswValue);

        this.isError = isFilled;
        if (!isFilled) {
            this.setState({ isValid: true })
            const data = {
                "CarrierTicketNumber": this.shipperNumber,
                "ProductLoadedID": this.selectedProduct?.value,
                "EstimatedVolume": this.props.data[0].EstimatedVolume,
                "UomID": this.selectedUnit?.value,
                "ActualVolume": parseFloat(this.actualVolume),
                "BSW": parseFloat(this.bswValue),
                "Comments": "CarrierCompany : " + this.props.data[0].CarrierName
            }
            this.props.onSave(data);
            this.props.onHide()
        } else {
            this.setState({ isValid: false })
            
        }
    }
};
