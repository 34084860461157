import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import './_data-grid.scss';
import { dataStore } from 'features/common/stores';
interface DataGridProps {
    gridOption: GridOptions;
}

export class DataGrid extends Component<DataGridProps> {
    /* fix height issue */
    render(): React.ReactNode {
        const { gridOption } = this.props;

        const statusBar = {
            statusPanels:  [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
                {
                  statusPanel: 'agTotalRowCountComponent',
                  align: 'center',
                },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
              ],
        };
        
        const options = { ...gridOption, onGridReady: this.onGridReady,
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true, statusBar:statusBar };
        const screen = dataStore.getCurrentModuleName();
        return (
            <div className="ag-theme-balham data-grid" id = {screen}>
                <AgGridReact stopEditingWhenGridLosesFocus={true} {...options} />
            </div>
        );
    }

    onGridReady = (event: GridReadyEvent): void => {
        if (this.props.gridOption.onGridReady) {
            this.props.gridOption.onGridReady(event);
        }
    };
}
