export enum HttpMethodName {
    GET = 'GET',
    POST = 'POST'
}

export enum HttpHeaderKey {
    APIKEY = 'api-key'
}

export enum RoleBasedActionName {
    Save = 'Save',
    View = 'View'
}
