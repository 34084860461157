/* eslint-disable react/jsx-key */
import * as React from 'react';
import './_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Input } from '../input/input';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import { dateUtils } from 'shared/services/date-utils';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (consignorAXID, type?) => void;
    msg;
    dispatch;
    selectedTickets?;
}
export class LocationAXPopUp extends React.Component<ConfirmProps> {
    state = {
        consignorPlaceholder: 'Select Location',
        ticketPlaceholder: 'Select Tickets',
        consignor: '',
        selectedTicket: []
    };
    selectedLocation: any;
    selectedTickets: any = this.props.selectedTickets;
    onChange: boolean = false;

    public render(): React.ReactNode {
        const { onClose, msg, dispatch = false } = this.props;
        let ticketList: any = []
         if(dispatch) {
            msg.map(list => {
                ticketList.push({ value: list.LoadNumber, label: `${list.LoadNumber} | ${list.ShortNames}`,origin: list.AX_Origin, destination: list.AX_Destination,
            date: dateUtils.getFormattedMSTDateOnly(list.PlannedDateTime), status: 'Added', shortName: list.ShortNames})
            })
            
        }
        const operator = Array.from(new Set(msg.map((item: any) => item.Description)));
        console.log(this.selectedTickets)
        return (
            <div>
                <div className={dispatch ? 'tickets' : 'location'}>
                    <Row>
                        <Col>
                            {!dispatch && <div>
                                Select Location:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedLocation}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.consignorPlaceholder}
                                        options={operator ? operator : msg}
                                        onChange={this.handleConsignorType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>}
                            {dispatch && <div>
                                Select Tickets:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedTickets}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.ticketPlaceholder}
                                        options={ticketList}
                                        onChange={this.handleTicketSelected}
                                        isMulti={true}
                                    />
                                </div>
                            </div>}
                        </Col>
                    </Row>
                    <Row>
                    {!dispatch && <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={!this.selectedLocation}
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>}
                        {dispatch && <div>
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleAdd}
                                disabled={(this.selectedTickets.length === 0 && this.props.selectedTickets.length === 0) || !this.onChange}
                            >
                                SAVE
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={this.handleReset}>
                                Reset
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton> </div>}
                    </Row>
                </div>
            </div>
        );
    }

    handleChange = event => {
        this.setState({ consignor: event.target.value });
    };

    handleConsignorType = option => {
        this.selectedLocation = option;
        console.log(option);
        this.setState({ consignorPlaceholder: option });
    };

    handleTicketSelected = option => {
        console.log(option);
        this.onChange = true;
        if (option) {
            this.selectedTickets = option;
            this.onChange = true;

            this.setState({selectedTicket: this.selectedTickets})
        } else {
            this.selectedTickets = [];
            if(this.props.selectedTickets.length>0){
                this.onChange = true;
            } else {
                this.onChange = false;
            }

            this.setState({selectedTicket: this.selectedTickets})
        }
    }

    handleClick = () => {
        this.props.onSuccessClick(this.state.consignorPlaceholder);
        this.props.onClose();
    };

    handleReset = () => {
        this.onChange = false;
        this.selectedTickets = this.props.selectedTickets;
        this.setState({selectedTicket: this.selectedTickets});
    }

    handleAdd = () => {
        this.props.onSuccessClick(this.state.selectedTicket);
        this.props.onClose();
    }
}
