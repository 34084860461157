import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListSelect from './list-select';
import { Collapisble } from 'shared/components/collapsible/collapsible';
import { UiService } from 'shared/services/ui-service';
import { MappingDataStore } from '../store/mapping-store';
import { inject, observer } from 'mobx-react';
import { CommonUtils } from 'shared/services/common-utils';
import { dataStore } from '../../common/stores/index';
/* eslint-disable */
interface CompanyManagemetState {
    loader?: boolean;
    companyID: number;
}

interface MappingManagemetProps {
    mappingDataStore?: MappingDataStore;
    companyID: number;
    companyName: string;
    userId :number;
}

@inject('mappingDataStore')
@observer
export class CompanyManagementComponent extends React.Component<MappingManagemetProps, CompanyManagemetState, any> {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            companyID: 0
        };
        this.state = { companyID: props.companyID };
    }

    render(): React.ReactNode {
        return <div>{this.renderGrid('')}</div>;
    }


    componentDidMount() {
        this.getData(this.state.companyID);
    }
    componentDidUpdate(newProps) {
        const { companyID } = this.props;
        if (companyID !== newProps.companyID) {
            this.setState({ companyID: newProps.companyID });
        }
    }

    handleMappedDriver = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objCompanyMappingToSave.driverMappedID = [];
        }
        items.forEach(item => {
            mappingDataStore?.objCompanyMappingToSave.driverMappedID.push(item.ID);
        });
    };

    handleMappedTrailer = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objCompanyMappingToSave.trailerMappedID = [];
        }
        items.forEach(item => {
            mappingDataStore?.objCompanyMappingToSave.trailerMappedID.push(item.ID);
        });
    };

    handleMappedPrinter = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objCompanyMappingToSave.printerMappedID = [];
        }
        items.forEach(item => {
            mappingDataStore?.objCompanyMappingToSave.printerMappedID.push(item.ID);
        });
    };

    handleMappedTractor = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objCompanyMappingToSave.truckMappedID = [];
        }
        items.forEach(item => {
            mappingDataStore?.objCompanyMappingToSave.truckMappedID.push(item.ID);
        });
    };

    handleMappedTablet = (items: any[]) => {
        const { mappingDataStore } = this.props;
        if (mappingDataStore) {
            mappingDataStore.objCompanyMappingToSave.tabletMappedID = [];
        }
        items.forEach(item => {
            mappingDataStore?.objCompanyMappingToSave.tabletMappedID.push(item.ID);
        });
    };

    getData = async (FilterID: any) => {
        const { mappingDataStore } = this.props;
        const CompanyRequestdata = { Searchbyvalue: FilterID };
        this.setState({ loader: true });       
        mappingDataStore?.getCompanyMappingData(CompanyRequestdata).then(() => {
            mappingDataStore.objCompanyMappingToSave.driverMappedID = [];
            mappingDataStore.objCompanyMappingToSave.printerMappedID = [];
            mappingDataStore.objCompanyMappingToSave.trailerMappedID = [];
            mappingDataStore.objCompanyMappingToSave.tabletMappedID = [];
            mappingDataStore.objCompanyMappingToSave.truckMappedID = [];
            for (const key in mappingDataStore?.companyMappingData.DriverMapped) {
                mappingDataStore.objCompanyMappingToSave.driverMappedID.push((key as unknown) as number);
            }
            for (const key in mappingDataStore?.companyMappingData.PrinterMapped) {
                mappingDataStore?.objCompanyMappingToSave.printerMappedID.push((key as unknown) as number);
            }
            for (const key in mappingDataStore?.companyMappingData.TrailerMapped) {
                mappingDataStore?.objCompanyMappingToSave.trailerMappedID.push((key as unknown) as number);
            }
            for (const key in mappingDataStore?.companyMappingData.TabletMapped) {
                mappingDataStore?.objCompanyMappingToSave.tabletMappedID.push((key as unknown) as number);
            }
            for (const key in mappingDataStore?.companyMappingData.TractorMapped) {
                mappingDataStore?.objCompanyMappingToSave.truckMappedID.push((key as unknown) as number);
            }
            this.setState({ loader: false });
        });
    };

    renderGrid(url): React.ReactNode {
        if (this.props.companyID > 0) return this.renderMapping();
        else return this.renderItem();
    }

    renderItem() {
        return <div></div>;
    }

    renderMapping() {
        const { mappingDataStore, companyName } = this.props;
        const toastService = new UiService();
        if (this.state.loader) {
            toastService.loaderService.showLoader();
        } else {
            toastService.loaderService.hideLoader();
        }
        const companyNameTitle = companyName + ' Mapping';
        const dictDriverNotMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.DriverNotMapped);
        const dictDriverMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.DriverMapped);
        const dictTrailerNotMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TrailerNotMapped);
        const dictTrailerMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TrailerMapped);
        const dictTabletNotMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TabletNotMapped);
        const dictTabletMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TabletMapped);
        const dictPrinterNotMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.PrinterNotMapped);
        const dictPrinterMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.PrinterMapped);
        const dictTractorNotMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TractorNotMapped);
        const dictTractorMapped = CommonUtils.dictionaryToObj(mappingDataStore?.companyMappingData.TractorMapped);

        return (
            <Collapisble title={companyNameTitle}>
                <div>
                    {this.state.loader ? (
                        <div></div>
                    ) : (
                            <>
                                <div className={'parentmargin'}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <ListSelect
                                                    filterBy={'driverFilter'}
                                                    headerHeading={'DRIVER'}
                                                    filterMapped={'driverfilterMapped'}
                                                    selectall={false}
                                                    notMapped={dictDriverNotMapped}
                                                    mapped={dictDriverMapped}
                                                    btnRemove={'btnRemoveDriver'}
                                                    btnAdd={'btnAddDriver'}
                                                    handleItems={this.handleMappedDriver}
                                                />
                                            </Col>
                                            <Col>
                                                <ListSelect
                                                    filterMapped={'trailerFilterMapped'}
                                                    filterBy={'trailerFilter'}
                                                    headerHeading={'TRAILER'}
                                                    mapped={dictTrailerMapped}
                                                    notMapped={dictTrailerNotMapped}
                                                    btnRemove={'btnRemoveTrailer'}
                                                    btnAdd={'btnAddTrailer'}
                                                    handleItems={this.handleMappedTrailer}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ListSelect
                                                    filterBy={'tabletMapped'}
                                                    filterMapped={'tabletMappedfilter'}
                                                    headerHeading={'TABLET'}
                                                    mapped={dictTabletMapped}
                                                    notMapped={dictTabletNotMapped}
                                                    btnRemove={'btnRemovetabletMapped'}
                                                    btnAdd={'btnAddTablet'}
                                                    handleItems={this.handleMappedTablet}
                                                />
                                            </Col>

                                            <Col>
                                                <ListSelect
                                                    filterBy={'printerMappedfilter'}
                                                    filterMapped={'printerInfoFilterMapped'}
                                                    headerHeading={'PRINTER'}
                                                    mapped={dictPrinterMapped}
                                                    notMapped={dictPrinterNotMapped}
                                                    btnRemove={'btnRemovePrinterInfo'}
                                                    btnAdd={'btnAddPrinter'}
                                                    handleItems={this.handleMappedPrinter}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ListSelect
                                                    filterBy={'tractorInfoFilter'}
                                                    filterMapped={'tractorInfoFilterMapped'}
                                                    headerHeading={'TRACTOR'}
                                                    mapped={dictTractorMapped}
                                                    notMapped={dictTractorNotMapped}
                                                    btnRemove={'btnRemoveTractorInfo'}
                                                    btnAdd={'btnAddtractor'}
                                                    handleItems={this.handleMappedTractor}
                                                />
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </>
                        )}
                </div>
            </Collapisble>
        );
    }
}
