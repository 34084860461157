import { ShopInfo } from './model/model';
import { TrailerMaintenanceRequest } from './model/trailerMaintenanceRequest';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { TrailerMaintenanceHeaderName, TrailerMaintenanceFieldName, TrailerMaintenanceStatus } from './domains/enum';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CurrentStatusCellRenderer } from './components/cell-renderers/current-status-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from '../common/stores/index';
import { DateCellRenderer } from 'shared/components/cell-renderers/date-picker-cell-renderer';
import { trailerMaintenanceStore } from './stores';
import { AppConstant } from 'app_constant';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { useParams } from 'react-router';

export const dataTypeOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'InspectionTicketHistory', label: 'Inspection Ticket History' },
    { value: 'TrailerBookingMaintenance', label: 'Trailer Booking Maintenance' }
];

export const defaultShopInfo: ShopInfo = {
    ShopAddress: '',
    ShopName: '',
    ID: 0,
    Lattitude: '',
    Longitude: '',
    ContactNumber: '',
    ModifiedDate: '',
    City: '',
    Province: '',
    PostalCode: '',
    ModifiedBy: 0
};

export const defaultSelectedShop = { value: 'Select', label: 'Select' };
export const defaultTrailerInfo: TrailerMaintenanceRequest = {
    ID: 0,
    StatusID: 0,
    TrailerNumber: '',
    MaintenanceTypeID: 0,
    ModifiedBy: 1,
    ShopID: 0,
    StatusUpdateDate: '',
    ModifiedDate: '',
    Comments: '',
    Booked: '',
    Acheck_Value: '',
    CVIP_Value: '',
    V_Value: '',
    K_Value: '',
    I_Value: '',
    P_Value: '',
    UC_Value: '',
    OTES_Value: '',
    Acheck: false,
    CVIP: false,
    V: false,
    K: false,
    I: false,
    P: false,
    UC: false,
    OTES: false
};
const commaFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const TRAILER_MAINTENANCE_STATUS = 'TRM_STATUS';
export const TRAILER_MAINTENANCE_TYPE = 'MAINTENANCE_TYPE';
export const ASSINGED_SHOP = 'Assigned';
export const NOT_ASSIGNED_SHOP = 'Not Assigned';
export const TKT_STATUS_TYPE = 'TKT_STATUS';

const status = {
    headerName: TrailerMaintenanceHeaderName.STATUS,
    field: TrailerMaintenanceFieldName.STATUS,
    cellEditor: 'agSelectCellEditor',
    width: 65,
    colId: TrailerMaintenanceFieldName.STATUS,
    editable: (params): boolean => {
        return (
            params.data.StatusEditable && dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
        );
    },
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.STATUS] === null) {
            return '';
        } else {
            return params.data[TrailerMaintenanceFieldName.STATUS];
        }
    },
    cellClass: () =>
        dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance') ? 'cell-editable' : ''
};

const maintenanceType = {
    headerName: TrailerMaintenanceHeaderName.MAINTENANCETYPE,
    field: TrailerMaintenanceFieldName.MAINTENANCETYPE,
    width: 75,
    // cellEditor: 'agSelectCellEditor',
    // colId: TrailerMaintenanceFieldName.MAINTENANCETYPE,
    // editable: (params): boolean => {
    //     return (
    //         params.data.StatusEditable && dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
    //     );
    // },
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.MAINTENANCETYPE] === null) {
            return '';
        } else {
            return params.data[TrailerMaintenanceFieldName.MAINTENANCETYPE];
        }
    }
    // cellClass: () =>
    //     dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance') ? 'cell-editable' : ''
};

const assignedShop = {
    headerName: TrailerMaintenanceHeaderName.ASSIGNEDSHOP,
    field: TrailerMaintenanceFieldName.ASSIGNEDSHOP,
    width: 115,
    cellRendererFramework: CurrentStatusCellRenderer,
    colId: TrailerMaintenanceFieldName.ASSIGNEDSHOP,
    cellClass: () =>
        dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance') ? 'cell-editable' : ''
};

const assignedShopName = {
    headerName: TrailerMaintenanceHeaderName.SHOPNAME,
    field: TrailerMaintenanceFieldName.SHOPNAME,
    colId: TrailerMaintenanceFieldName.SHOPNAME,
    width: 115,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.SHOPNAME] === null) {
            return '';
        } else {
            return params.data[TrailerMaintenanceFieldName.SHOPNAME];
        }
    }
    };

const booked = {
    headerName: TrailerMaintenanceHeaderName.BOOKED,
    field: TrailerMaintenanceFieldName.BOOKED,
    colId: TrailerMaintenanceFieldName.BOOKED,
    width: 75,
    cellRendererFramework: DateCellRenderer,
    cellClass: params => {
        const { data } = params;
        return data.StatusID &&
            (data.StatusID === TrailerMaintenanceStatus.BOOKED || data.StatusID === TrailerMaintenanceStatus.SHOPASSIGNED) &&
            dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
            ? 'cell-editable'
            : '';
    },
    editable: params => params.data.StatusID && (params.data.StatusID === TrailerMaintenanceStatus.BOOKED || params.data.StatusID === TrailerMaintenanceStatus.SHOPASSIGNED),
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.BOOKED] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.BOOKED]);
        }
    }
};
const shopAssignedDate = {
    headerName: TrailerMaintenanceHeaderName.SHOPASSIGNEDDATE,
    field: TrailerMaintenanceFieldName.SHOPASSIGNEDDATE,
    colId: TrailerMaintenanceFieldName.SHOPASSIGNEDDATE,
    width: 110,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.SHOPASSIGNEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.SHOPASSIGNEDDATE]);
        }
    }
};

const inMaintenanceDate = {
    headerName: TrailerMaintenanceHeaderName.INMAINTENANCEDATE,
    field: TrailerMaintenanceFieldName.INMAINTENANCEDATE,
    colId: TrailerMaintenanceFieldName.INMAINTENANCEDATE,
    width: 75,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.INMAINTENANCEDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.INMAINTENANCEDATE]);
        }
    }
};

const completedDate = {
    headerName: TrailerMaintenanceHeaderName.MAINTENANCECOMPLETEDATE,
    field: TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDATE,
    colId: TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDATE,
    width: 115,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDATE]);
        }
    }
};

const cancelledDate = {
    headerName: TrailerMaintenanceHeaderName.BOOKINGCANCELLEDDATE,
    field: TrailerMaintenanceFieldName.BOOKINGCANCELLEDDATE,
    colId: TrailerMaintenanceFieldName.BOOKINGCANCELLEDDATE,
    width: 115,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.BOOKINGCANCELLEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.BOOKINGCANCELLEDDATE]);
        }
    }
};

const parkedDate = {
    headerName: TrailerMaintenanceHeaderName.PARKEDASSIGNEDDATE,
    field: TrailerMaintenanceFieldName.PARKEDASSIGNEDDATE,
    colId: TrailerMaintenanceFieldName.PARKEDASSIGNEDDATE,
    width: 110,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.PARKEDASSIGNEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.PARKEDASSIGNEDDATE]);
        }
    }
};

const parkedOutOfServiceDate = {
    headerName: TrailerMaintenanceHeaderName.PARKEDOUTOFSERVICEASSIGNEDDATE,
    field: TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDDATE,
    colId: TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDDATE,
    width: 160,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDDATE]);
        }
    }
};
const pendingPaperworkDate = {
    headerName: TrailerMaintenanceHeaderName.PENDINGPAPERWORKDATE,
    field: TrailerMaintenanceFieldName.PENDINGPAPERWORKDATE,
    colId: TrailerMaintenanceFieldName.PENDINGPAPERWORKDATE,
    width: 120,
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.PENDINGPAPERWORKDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.PENDINGPAPERWORKDATE]);
        }
    }
};

const descriptionOfMaintenanceRequested = {
    headerName: TrailerMaintenanceHeaderName.DESCRIPTIONOFMAINTENANCEREQUESTED,
    field: TrailerMaintenanceFieldName.DESCRIPTIONOFMAINTENANCEREQUESTED,
    colId: TrailerMaintenanceFieldName.DESCRIPTIONOFMAINTENANCEREQUESTED,
    width: 165,
    cellRendererFramework: TextAreaCellRenderer,
    cellRendererParams: {
        isDisabled: (item, value) => true
    },
    valueGetter: params => {
        if (params.data[TrailerMaintenanceFieldName.DESCRIPTIONOFMAINTENANCEREQUESTED] === null) {
            return '';
        } else {
            return params.data[TrailerMaintenanceFieldName.DESCRIPTIONOFMAINTENANCEREQUESTED];
        }
    }
};

export const maintenanceColDef: ColDef[] = [
    { 
        headerName: TrailerMaintenanceHeaderName.ID,
        field: TrailerMaintenanceFieldName.ID, 
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.ID] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.ID];
            }
        }
    },
    { headerName: TrailerMaintenanceHeaderName.TRAILERID, field: TrailerMaintenanceFieldName.TRAILERID, hide: true },
    {
        headerName: TrailerMaintenanceHeaderName.TRAILERNUMBER,
        field: TrailerMaintenanceFieldName.TRAILERNUMBER,
        width: 75,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: TrailerMaintenanceHeaderName.LASTNAME,
        field: TrailerMaintenanceFieldName.LASTNAME,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.LASTNAME] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.LASTNAME];
            }
        }
    },
    {
        headerName: TrailerMaintenanceHeaderName.FIRSTNAME,
        field: TrailerMaintenanceFieldName.FIRSTNAME,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.FIRSTNAME] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.FIRSTNAME];
            }
        }
    },
    {
        headerName: TrailerMaintenanceHeaderName.COMPANYNAME,
        field: TrailerMaintenanceFieldName.COMPANYNAME,
        width: 125,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.COMPANYNAME] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.COMPANYNAME];
            }
        }
    },
    booked,
    maintenanceType,
    {
        headerName: TrailerMaintenanceHeaderName.REMAININGMAINTENANCEKMS,
        field: TrailerMaintenanceFieldName.REMAININGMAINTENANCEKMS,
        width: 120,
        cellClass: 'work-wait',
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.REMAININGMAINTENANCEKMS] === null) {
                return '';
            } else {
                    return params.data[TrailerMaintenanceFieldName.REMAININGMAINTENANCEKMS];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: TrailerMaintenanceHeaderName.MAINTENANCEDUEDATE,
        field: TrailerMaintenanceFieldName.MAINTENANCEDUEDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.MAINTENANCEDUEDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TrailerMaintenanceFieldName.MAINTENANCEDUEDATE]);
            }
        }
    },

    descriptionOfMaintenanceRequested,
    {
        headerName: TrailerMaintenanceHeaderName.INSPECTIONTICKETNUMBER,
        field: TrailerMaintenanceFieldName.INSPECTIONTICKETNUMBER,
        width: 110,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.INSPECTIONTICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.INSPECTIONTICKETNUMBER];
            }
        }
    },
    status,
    assignedShop,
    assignedShopName,
    {
        headerName: TrailerMaintenanceHeaderName.SHOPASSIGNEDBY,
        field: TrailerMaintenanceFieldName.SHOPASSIGNEDBY,
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.SHOPASSIGNEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.SHOPASSIGNEDBY];
            }
        }
    },
    shopAssignedDate,
    {
        headerName: TrailerMaintenanceHeaderName.MAINTENANCEASSIGNEDBY,
        field: TrailerMaintenanceFieldName.MAINTENANCEASSIGNEDBY,
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.MAINTENANCEASSIGNEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.MAINTENANCEASSIGNEDBY];
            }
        }
    },
    inMaintenanceDate,
    {
        headerName: TrailerMaintenanceHeaderName.MAINTENANCECOMPLETEDBY,
        field: TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDBY,
        width: 110,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.MAINTENANCECOMPLETEDBY];
            }
        }
    },
    completedDate,
    {
        headerName: TrailerMaintenanceHeaderName.BOOKINGCANCELLEDBY,
        field: TrailerMaintenanceFieldName.BOOKINGCANCELLEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.BOOKINGCANCELLEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.BOOKINGCANCELLEDBY];
            }
        }
    },
    cancelledDate,
    {
        headerName: TrailerMaintenanceHeaderName.PARKEDASSIGNEDBY,
        field: TrailerMaintenanceFieldName.PARKEDASSIGNEDBY,
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.PARKEDASSIGNEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.PARKEDASSIGNEDBY];
            }
        }
    },
    parkedDate,
    {
        headerName: TrailerMaintenanceHeaderName.PARKEDOUTOFSERVICEASSIGNEDBY,
        field: TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDBY,
        width: 150,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.PARKEDOUTOFSERVICEASSIGNEDBY];
            }
        }
    },
    parkedOutOfServiceDate,
    {
        headerName: TrailerMaintenanceHeaderName.PENDINGPAPERWORKASSIGNEDBY,
        field: TrailerMaintenanceFieldName.PENDINGPAPERWORKASSIGNEDBY,
        width: 140,
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.PENDINGPAPERWORKASSIGNEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.PENDINGPAPERWORKASSIGNEDBY];
            }
        }
    },
    pendingPaperworkDate,
    {
        headerName: TrailerMaintenanceHeaderName.COMMENT,
        field: TrailerMaintenanceFieldName.COMMENT,
        colId: TrailerMaintenanceFieldName.COMMENT,
        width: 90,
        cellRendererFramework: TextAreaCellRenderer,
        cellClass: params => {
            return dataStore.checkOperationAccessWithModule('Save', 'TrailerBookingMaintenance')
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[TrailerMaintenanceFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[TrailerMaintenanceFieldName.COMMENT];
            }
        }
    }
];
