import React, { Component, useState } from 'react';
import { Tabs, Tab, Col, Container, Row, Nav } from 'react-bootstrap';
import { SELECT_TYPE_MESSAGE, NO_DATA_AVAILABLE } from '../../../common/default-page_constant';
import {
    communicationTabs,
    broadCastType,
    regionType,
    driverList,
    carrierList,
    broadCastMessageType,
    isDispatcher,
    mappedList,
    mappedCarrierList,
    directData,
    plainsRoles,
    CCUserRoles,
    CCCommunicationTabs,
    userRoles
} from '../communication-constants';
import './communication.scss';
// import { NavLink } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { dataStore } from 'features/common/stores';
import Select from 'react-select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import FontIcon from 'shared/components/font-icon/font-icon';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import MultiSelect from '@kenshooui/react-multi-select';
import { accountStore } from 'features/account/stores';
import { toJS } from 'mobx';
import moment from 'moment';
import { communicationDataService } from '../services/communication-data-service';
import { CommonUtils } from 'shared/services/common-utils';
import { dateUtils } from 'shared/services/date-utils';
import AlertRedIcon from '../../../../assets/AlertRedIcon.png';
import AlertYellowIcon from '../../../../assets/AlertYellowIcon.png';
import { localStorageService } from 'shared/services/local-storage-service';
import { uiService } from 'shared/services/ui-service';
import { CellClickedEvent, GridOptions } from 'ag-grid-community';
import { AgGridComponentParams } from 'features/common/domain/model';
import { BROADCAST_COL_DEF, CC_COL_DEF, MESSAGE_COL_DEF } from '../models/communication-col-def';
import { agGridService } from 'features/common/services/ag-grid-service';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { RowClickedEvent } from 'ag-grid-community';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { CommunicationDataStore } from '../stores/communication-data-store';
import { inject } from 'mobx-react';
// eslint-disable-next-line @typescript-eslint/class-name-casing
interface stateProps {
    key: number;
    intervalId: any;
    count: number;
    broadcastcount: number;
    messageType: string;
    broadcastType: string;
    regionType: string;
    isCompanySelected: boolean;
    isRegionSelected: boolean;
    isRoleSelected: boolean;
    IsNorthWest: number;
    IsSouthWest: number;
    IsNorthEast: number;
    IsSouthEast: number;
    IsCarrierCompany: number;
    IsDrivers: number;
    IsAllRegion: number;
    Category: string;
    Subject: string;
    Message: string;
    driverList: { ID: number; Text: string }[];
    companyList: any;
    carrierList: any[];
    selectedDrivers: [];
    selectedCarriers: [];
    roleSelected: any[];
}
interface CommunicationDataProps {
    communicationDataStore: CommunicationDataStore;
}
@inject('communicationDataStore')
export class CommunicationComponent extends Component<CommunicationDataProps, stateProps> {
    key = 2;
    messageType = '';
    broadcastType = '';
    regionType = '';
    selectedBroadCastOption: any;
    selectedRegionOption: any;
    selectedDriverOption: any;
    selectedCarrierOption: any;
    selectedBroadCastMessageOption: any;
    leaseOperatorName: any[] = [];
    leaseoperator: any[] = [];
    driverList: any;
    userId: any;
    userRole;
    directMsg: any[] = [];
    broadCastMsg: any;
    count: any = 0;
    driverSelected: any = [];
    Broadcastcount: any = 0;
    selectedRoleOption: any;
    roleType: any = 'All';
    companySelected: any = [];
    companyNameSelected: any[] = [];
    getMessagesInterval: any;
    selectedTab: { ID: number; Name: string; } = { ID: 2, Name: 'Messages' };
    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            count: 0,
            broadcastcount: 0,
            intervalId: 0,
            messageType: '',
            broadcastType: '',
            regionType: '',
            isCompanySelected: false,
            isRegionSelected: false,
            isRoleSelected: false,
            IsNorthWest: 0,
            IsSouthWest: 0,
            IsNorthEast: 0,
            IsSouthEast: 0,
            IsCarrierCompany: 0,
            IsDrivers: 0,
            IsAllRegion: 0,
            Category: '',
            Subject: '',
            Message: '',
            driverList: [],
            carrierList: [],
            companyList: [],
            selectedDrivers: [],
            selectedCarriers: [],
            roleSelected: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handlerefresh = this.handlerefresh.bind(this);
        this.userId = accountStore.getUserID();
        const role = toJS(accountStore.userRoles);
        this.userRole = this.getPriorityroles();
        dataStore.setModuleName('Communication');
    }

    getPriorityroles = () => {
        const roles = toJS(accountStore.userRoles);
        let flag = 0;
        let userRole;
        roles.forEach(role => {
            if(plainsRoles.includes(role)){
                flag = 1;
                userRole = role;
            }
        });
        if(flag == 0){
            roles.forEach(role => {
                if(['ptsmaintenance','ptstruckingadmin'].includes(role)){
                    flag = 1;
                    userRole = role;
                }
            })
        }
        if(flag == 0){
            roles.forEach(role => {
                if(role !== 'ptsdriver'){
                    userRole = role;
                }
            })
        }
        return userRole
    }
    checkUpdates = () => {
        const { communicationDataStore } = this.props;
        if (communicationDataStore.isUpdated === true) {
            this.setState({ key: 2 });
            this.getMessages();
            communicationDataStore.isUpdated = false;
        }
    };
    interval = () => {
        if (this.checkCCLogin()) {
            if (localStorageService.get('Communication_BroadCast_' + accountStore.getUserID())) {
                const broadcastData: any = localStorageService.get(
                    'Communication_BroadCast_' + accountStore.getUserID()
                );
                let count = 0;
                for (let i = 0; i < broadcastData.length; i++) {
                    if (!broadcastData[i].IsRead && broadcastData[i].SenderID !== accountStore.getUserID()) {
                        count++;
                    }
                }
                this.Broadcastcount = count;
                this.handleBroadcastToggle(this.state.key);
            }
        }
    };

    async componentDidMount() {
        this.props.communicationDataStore.init();
        this.userId = accountStore.getUserID();
        var checkUpdate = setInterval(this.checkUpdates, 1000);
        this.getMessagesInterval = setInterval(() => {
            this.getMessages();
        }, 10000 * 6 * 2);
        let intervalI = setInterval(this.interval, 20000);
        const itemMap = {};
        if (!localStorageService.get('Communication_Direct_' + accountStore.getUserID())) {
            this.getMessages().then(()=>{
                if (this.key == 2) {
                    dataStore.setModuleName('Communication');
                    const data = this.props.communicationDataStore.directMessages;
                        let count = 0;
                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < data[i].Data.length; j++) {
                                if (
                                    !this.checkCCLogin()
                                        ? !data[i].Data[j].IsRead &&
                                          data[i].Data[j].SenderID !== accountStore.getUserID() &&
                                          !plainsRoles.includes(data[i].Data[j].SenderRole)
                                        : !data[i].Data[j].IsRead && data[i].Data[j].SenderID !== accountStore.getUserID()
                                ) {
                                    count++;
                                }
                            }
                        }
                        this.setState({ count: count });
                }
            });
        }

        if (this.checkCCLogin()) {
            if (localStorageService.get('Communication_BroadCast_' + accountStore.getUserID())) {
                const broadcastData: any = localStorageService.get(
                    'Communication_BroadCast_' + accountStore.getUserID()
                );
                let count = 0;
                for (let i = 0; i < broadcastData.length; i++) {
                    if (!broadcastData[i].IsRead && broadcastData[i].SenderID !== accountStore.getUserID()) {
                        count++;
                    }
                }
                this.Broadcastcount = count;
                this.setState({ broadcastcount: count });
                // this.handleBroadcastToggle();
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.getMessagesInterval)
    }
    async getMessages() {
        const { communicationDataStore } = this.props; 
            const itemMap = {};
            this.broadCastMsg = [];
            this.directMsg = [];
            console.log(this.userId,this.userRole) //1259 'ptstruckingmanager'
            uiService.loaderService.showLoader();
            const messages = await communicationDataService.getAllMessages({
                UserID: this.userId,
                Role: this.userRole
            });
            if (messages) {
                this.directMsg = messages.Direct;
                this.broadCastMsg = messages.Broadcast;
                this.directMsg.forEach((item, ind) => {
                    const key = item.Type === 'Direct' ? item.ConvId : item.MessageID;
                    if (!itemMap.hasOwnProperty(key)) {
                        itemMap[key] = {
                            ConvId: item.ConvId,
                            CreateDate: item.CreatedDate,
                            Message: item.Message,
                            Subject: item.Subject,
                            MessageType: item.Type,
                            To:
                                item.SenderID === this.userId ||
                                item.SenderRole === this.userRole ||
                                plainsRoles.includes(item.SenderRole)
                                    ? item.Receiver
                                    : item.Sender,
                            From:
                                item.SenderID === this.userId ||
                                item.SenderRole === this.userRole ||
                                plainsRoles.includes(item.SenderRole)
                                    ? item.Sender
                                    : item.Receiver,
                            IsRead: item.IsRead,
                            IsSent: item.IsSent,
                            Data: [item]
                        };
                    } else {
                        itemMap[key].Data.push(item);
                    }
                });
                const variantKeys = Object.keys(itemMap);
                //   let itemsOrdered = [];
                communicationDataStore.directMessages = [];
                variantKeys.forEach(key => {
                    communicationDataStore.directMessages.push(itemMap[key]);
                });
                uiService.loaderService.hideLoader();
                this.broadCastMsg.forEach((item, ind) => {
                    if (!item.isRead && item.SenderID != accountStore.getUserID()) {
                        this.setState({ broadcastcount: this.state.broadcastcount + 1 });
                    }
                });
                localStorageService.set('Communication_BroadCast_' + accountStore.getUserID(), this.broadCastMsg);

        }
    }

    handleToggle = key => {
        this.setState({
            key: key
        });
    };

    handleBroadcastToggle = key => {
        this.setState({
            key: key
        });
    };

    handlerefresh() {
        this.setState({ key: 2 });
    }

    handleChange(selectedDriversList) {
        const selectedDrivers: any = Array.from(new Set(selectedDriversList.map((item: any) => item.ID)));
        this.driverSelected = selectedDrivers;
    }

    handleCompanyChange(selectedDriversList) {
        const selectedCompany: any = Array.from(new Set(selectedDriversList.map((item: any) => item.ID)));
        this.companySelected = selectedCompany;
        this.companyNameSelected = Array.from(new Set(selectedDriversList.map((item: any) => item.Text)));
        let roleList: any = [];
        if (this.roleType === 'All') {
            roleList = ['Carrier Company Scheduler', 'Carrier Company Admin', 'CarrierOwner', 'Owner', 'Owner/Driver', 'Driver'];
        } else if (this.roleType === 'OwnerAdmin') {
            roleList = ['Carrier Company Admin', 'CarrierOwner', 'Owner', 'Owner/Driver'];
        } else if (this.roleType === 'Scheduler') {
            roleList = ['Carrier Company Scheduler'];
        } else if (this.roleType === 'Drivers') {
            roleList = ['Driver'];
        }
        const driverList = this.driverList.filter(x => {
            return roleList.includes(x.Role) && this.companyNameSelected.includes(x.CarrierCompanyName);
        });
        const driverMapping = this.getRoleMappingJson(driverList);
        const driverLists = CommonUtils.dictionaryToObj(driverMapping);
        const sortedDriverList =  driverLists.sort((a,b)=>
        this.sortDriverListByLastName(a,b,'label') )
        this.setState({ driverList: sortedDriverList });
    }

    handleCarrierChange(selectedCarriers) {
        this.setState({ selectedCarriers });
    }

    render(): React.ReactNode {
        const { isCompanySelected, selectedDrivers, isRegionSelected, selectedCarriers, driverList, companyList } = this.state;
        const isCCUser = this.checkCCLogin();
        return (
            <>
                <div>{this.renderTabs()}</div>

                {this.key === 1 && (
                    <Container style={{ backgroundColor: '#FFFFFF', minHeight: '700px', padding: '16px 25px' }}>
                        <Row>
                            <div className=" padding-bottom form-inline" style={{ padding: '0px 10px 10px' }}>
                                <label style={{ width: '100px', fontSize: '20px' }}>Subject :</label>
                                <input
                                    style={{ width: '700px', height: '45px', paddingLeft: '10px' }}
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    placeholder="Type Subject"
                                    onBlur={this.handleBlur}
                                    maxLength={100}
                                />
                            </div>
                            <div className=" padding-bottom form-inline" style={{ padding: '0px 10px 10px' }}>
                                <label style={{ width: '150px', fontSize: '11px', color: 'red'}}>Allowed max 100 characters</label>
                            </div>
                        </Row>
                        <Row>
                            <div className=" padding-bottom form-inline" style={{ padding: '0px 10px 10px' }}>
                                <label style={{ width: '100px', fontSize: '20px' }}>Message :</label>
                                <textarea
                                    style={{ width: '700px', height: '95px', padding: '10px' }}
                                    id="message"
                                    placeholder="Type Message"
                                    rows={4}
                                    onBlur={this.handleMessageBlur}
                                />
                            </div>
                        </Row>

                        <Row>
                            <div
                                onChange={this.setType}
                                style={{ padding: '0px 10px 10px', fontSize: '20px', margin: '0px' }}
                            >
                                {!isCCUser && (
                                    <>
                                        <input
                                            type="radio"
                                            value="BROADCAST"
                                            name="type"
                                            style={{ padding: '0px 10px', marginLeft: '0px ' }}
                                        />{' '}
                                        Broadcast
                                        <input
                                            type="radio"
                                            value="DRIVER"
                                            name="type"
                                            style={{ padding: '0px 10px' }}
                                        />{' '}
                                        Direct to Drivers
                                        {!isCCUser && (
                                            <>
                                                {' '}
                                                <input
                                                    type="radio"
                                                    value="CARRIER"
                                                    name="type"
                                                    style={{ padding: '0px 10px' }}
                                                />{' '}
                                                Direct to Carrier Company
                                            </>
                                        )}
                                    </>
                                )}
                                {isCCUser && (
                                    <>
                                        <input
                                            type="radio"
                                            value="DISPATCHER"
                                            name="type"
                                            style={{ padding: '0px 10px', marginLeft: '0px ' }}
                                        />{' '}
                                        Direct to Dispatchers
                                    </>
                                )}
                            </div>
                        </Row>
                        <Row>
                            {this.messageType === 'BROADCAST' && (
                                <>
                                    <div
                                        className="form-inline padding-bottom"
                                        style={{ padding: '0px 10px 10px', fontSize: '20px' }}
                                    >
                                        Broadcast Type:{' '}
                                        <div className="reportmanagement__type">
                                            <Select
                                                value={this.selectedBroadCastMessageOption}
                                                closeMenuOnSelect={true}
                                                closeMenuOnScroll={true}
                                                placeholder="SelectType"
                                                options={broadCastMessageType}
                                                onChange={this.handleBroadCastMessageType}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="form-inline padding-bottom"
                                        style={{ padding: '0px 10px 10px', fontSize: '20px' }}
                                    >
                                        Recipients :{' '}
                                        <div className="reportmanagement__type">
                                            <Select
                                                value={this.selectedBroadCastOption}
                                                closeMenuOnSelect={true}
                                                closeMenuOnScroll={true}
                                                placeholder="SelectType"
                                                options={broadCastType}
                                                onChange={this.handleBroadCastType}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {this.broadcastType !== '' && this.messageType === 'BROADCAST' && (
                                <div
                                    className="form-inline padding-bottom"
                                    style={{ padding: '0px 10px 10px', fontSize: '20px' }}
                                >
                                    Region :{' '}
                                    <div className="reportmanagement__type">
                                        <Select
                                            value={this.selectedRegionOption}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder="SelectType"
                                            options={regionType}
                                            onChange={this.handleRegionType}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.messageType === 'DRIVER' && (
                                <div
                                    className="padding-bottom"
                                    style={{
                                        padding: '0px 10px 10px',
                                        fontSize: '20px',
                                        width: '46%',
                                        display: 'flex'
                                    }}
                                >
                                    Region :{' '}
                                    <div className="reportmanagement__type">
                                        <Select
                                            value={this.selectedDriverOption}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder="SelectCarrier"
                                            options={regionType}
                                            onChange={this.handleCarrierGroupSelection}
                                        />
                                    </div>{' '}
                                    <>
                                        <span className="searchlistbox">
                                            <label className="selectlistbox" style={{ fontSize: '20px' }}>
                                                {' '}
                                                DriverList{' '}
                                            </label>
                                            <MultiSelect
                                                items={driverList}
                                                wrapperClassName={'SelMapped selDefaultSize'}
                                                id={'driver'}
                                                showSelectedItems={false}
                                                // searchRenderer={this.searchRendererForNotMapped}
                                                showSelectAll={false}
                                                selectedItems={selectedDrivers}
                                                onChange={this.handleChange}
                                            ></MultiSelect>
                                        </span>
                                    </>
                                </div>
                            )}
                            {this.messageType === 'CARRIER' && (<>
                                <div
                                    className="padding-bottom"
                                    style={{
                                        padding: '0px 10px 10px',
                                        fontSize: '20px',
                                        width: '46%',
                                        display: 'flex'
                                    }}
                                >
                                    Region :{' '}
                                    <div className="reportmanagement__type">
                                        <Select
                                            value={this.selectedCarrierOption}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder="SelectRegion"
                                            options={regionType}
                                            onChange={this.handleRegionSelection}
                                        />
                                    </div>{' '}
                                    <>
                                        <span className="searchlistbox">
                                            <label className="selectlistbox" style={{ fontSize: '20px' }}>
                                                {' '}
                                                CarrierCompanyList{' '}
                                            </label>
                                            <MultiSelect
                                                items={companyList}
                                                wrapperClassName={'SelMapped selDefaultSize'}
                                                id={'carrier'}
                                                showSelectedItems={false}
                                                // searchRenderer={this.searchRendererForNotMapped}
                                                showSelectAll={false}
                                                selectedItems={selectedDrivers}
                                                onChange={this.handleCompanyChange}
                                            ></MultiSelect>
                                        </span>
                                    </>
                                    
                                </div>
                                <div
                                    className="padding-bottom"
                                    style={{
                                        padding: '0px 10px 10px',
                                        fontSize: '20px',
                                        width: '46%',
                                        display: 'flex'
                                    }}
                                >
                                    {this.companySelected.length > 0 && <>Role :{' '}<div className="reportmanagement__type">
                                        <Select
                                            value={this.selectedRoleOption}
                                            closeMenuOnSelect={true}
                                            closeMenuOnScroll={true}
                                            placeholder="SelectRole"
                                            options={userRoles}
                                            onChange={this.handleRoleSelection}
                                        />
                                    </div>
                                    {' '}
                                    <>
                                    <span className="searchlistbox">
                                        <label className="selectlistbox" style={{ fontSize: '20px' }}>
                                            {' '}
                                            CarrierCompanyUserList{' '}
                                        </label>
                                        <MultiSelect
                                            items={driverList}
                                            wrapperClassName={'SelMapped selDefaultSize'}
                                            id={'carrier'}
                                            showSelectedItems={false}
                                            // searchRenderer={this.searchRendererForNotMapped}
                                            showSelectAll={true}
                                            selectedItems={selectedDrivers}
                                            onChange={this.handleChange}
                                        ></MultiSelect>
                                    </span>
                                </></>
                                    }
                                    </div></>
                            )}
                            {this.messageType === 'DISPATCHER' && (
                                <div
                                    className="padding-bottom"
                                    style={{
                                        padding: '0px 10px 10px',
                                        fontSize: '20px',
                                        width: '42%',
                                        display: 'flex'
                                    }}
                                >
                                    {/* <div className="reportmanagement__type"></div> */}
                                    {isCompanySelected && (
                                        <>
                                            <span className="searchlistbox">
                                                <label className="selectlistbox" style={{ fontSize: '20px' }}>
                                                    {' '}
                                                    DispatcherList{' '}
                                                </label>
                                                <MultiSelect
                                                    items={driverList}
                                                    wrapperClassName={'SelMapped selDefaultSize'}
                                                    id={'driver'}
                                                    showSelectedItems={false}
                                                    // searchRenderer={this.searchRendererForNotMapped}
                                                    showSelectAll={false}
                                                    selectedItems={selectedDrivers}
                                                    onChange={this.handleChange}
                                                ></MultiSelect>
                                            </span>
                                        </>
                                    )}
                                </div>
                            )}
                        </Row>
                        <Row>
                            <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick}>
                                Send
                            </CustomButton>
                        </Row>
                    </Container>
                )}
                {this.key === 2 && (
                    <>
                        <AgGridComponent gridConfig={this.getMessagesGridConfig()} />
                    </>
                )}
                {this.key === 3 && !isCCUser && (
                    <>
                        <AgGridComponent gridConfig={this.getBroadCastGridConfig()} />
                    </>
                )}
                {this.key === 3 && isCCUser && (
                    <>
                        <AgGridComponent gridConfig={this.getCCMessagesGridConfig()} />
                    </>
                )}
            </>
        );
    }

    errorIcon() {
        return <FontIcon className={'exclamation'}></FontIcon>;
    }

    handleRefresh = () => {
        this.getMessages();
    }
    markAsUnRead = () => {
        const { communicationDataStore } = this.props; 
        let ID: any = [];
        const data: any = communicationDataStore.directMessages;
        for (let i = 0; i < data.length; i++) {
                if (data[i].Data[0].IsRead != false && 
                    !data[i].Data[0].ReceiverRole.includes('ptsdriver') &&
                    data[i].Data[0].SenderID !== accountStore.getUserID()
                ) {
                    ID.push(data[i].Data[0].MessageID)
            }
        }
        communicationDataService.updateReadMessage({ MessageID: ID, Type: 'Direct', IsRead: false }).then(() => {
            this.getMessages();
        });
    }
    markAsRead = () => {
        const { communicationDataStore } = this.props; 
        let ID: any = [];
        const data: any = communicationDataStore.directMessages;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].Data.length; j++) {
                if (data[i].Data[j].IsRead != true && 
                    !data[i].Data[j].ReceiverRole.includes('ptsdriver') &&
                    data[i].Data[j].SenderID !== accountStore.getUserID()
                ) {
                    ID.push(data[i].Data[j].MessageID)
                }
            }
        }
        communicationDataService.updateReadMessage({ MessageID: ID, Type: 'Direct', IsRead: true }).then(() => {
            this.getMessages();
        });
    }
    renderTabs() {
        const tabHeaders = this.checkCCLogin() ? CCCommunicationTabs : communicationTabs;
        return (
            <Container fluid={true}>
                {dataStore.checkOperationAccess('MarkAllAsRead') && (
                    <>
                        <CustomButton
                            type={CustomButtonType.Submit}
                            onClick={this.markAsRead}
                        >
                            Mark all as Read
                        </CustomButton>
                        <CustomButton
                            type={CustomButtonType.Submit}
                            onClick={this.markAsUnRead}
                        >
                            Mark all as Unread
                        </CustomButton>
                    </>
                )}
                <ClearButtonComponent handleRefresh={this.handleRefresh}></ClearButtonComponent>
                <Nav className="ml-3">
                    {tabHeaders.map(X => {
                        const f1 = a => t => {
                            this.selectedTab = X;
                            this.open(X);
                        };
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <Nav.Link
                                className={`header__link ${this.key == X.ID ? 'header-active' : 'lnkInactive'}`}
                                style={{ fontSize: '14px', width: '190px', textAlign: 'center' }}
                                onClick={f1(X.ID)}
                                disabled={
                                    dataStore.checkOperationAccessWithModule(X.Name, 'Communication')
                                        ? false
                                        : X.Name === 'New'
                                        ? true
                                        : false
                                }
                            >
                                {X.Name} {X.Name === 'Messages' && <> ({this.state.count}) </>}
                                {X.Name === 'Broadcast' && this.checkCCLogin() && <> ({this.state.broadcastcount}) </>}
                            </Nav.Link>
                        );
                    })}
                </Nav>
            </Container>
        );
    }
    handleBlur = event => {
        this.setState({ Subject: event.target.value });
    };

    handleMessageBlur = event => {
        this.setState({ Message: event.target.value });
    };

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    handleClick = async () => {
        const userID = accountStore.getUserID();
        const role = this.getPriorityroles();
        const date = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (this.messageType === 'BROADCAST') {
            const req = {
                ID: 1,
                Subject: this.state.Subject,
                Role: role,
                Message: this.state.Message,
                IsNorthWest: this.state.IsNorthWest,
                IsSouthWest: this.state.IsSouthWest,
                IsNorthEast: this.state.IsNorthEast,
                IsSouthEast: this.state.IsSouthEast,
                IsCarrierCompany: this.state.IsCarrierCompany,
                IsDrivers: this.state.IsDrivers,
                CreatedBy: userID,
                CreatedDate: date,
                IsAllRegion: this.state.IsAllRegion,
                Category: this.state.Category
            };

            uiService.loaderService.showLoader();
            const result: any = await communicationDataService.sendBroadCast(req);
            uiService.loaderService.hideLoader();
            this.key = 0;
            if (result) uiService.toastService.success('Broadcast Message sent');
            this.getMessages();
            this.setState({ key: 2 });
        } else if (this.messageType === 'DRIVER') {
            const req = {
                ID: 1,
                Message: this.state.Message,
                Subject: this.state.Subject,
                Type: 'Direct',
                Sender: userID,
                Receiver: this.driverSelected,
                CreatedDate: date,
                Category: '',
                ParentId: 0,
                IsRead: 0,
                ConvId: 0,
                SenderRole: role,
                ReceiverRole: null,
                IsDirectCC: false
            };

            uiService.loaderService.showLoader();
            const result: any = await communicationDataService.sendDirectMessage(req);
            uiService.loaderService.hideLoader();
            this.key = 0;
            if (result) uiService.toastService.success('Direct Message sent');
            this.getMessages();
            this.setState({ key: 2 });
        } else if (this.messageType === 'CARRIER') {
            const req = {
                ID: 1,
                Message: this.state.Message,
                Subject: this.state.Subject,
                Type: 'Direct',
                Sender: userID,
                Receiver: this.driverSelected,
                CreatedDate: date,
                Category: '',
                ParentId: 0,
                IsRead: 0,
                ConvId: 0,
                SenderRole: role,
                ReceiverRole: null,
                IsDirectCC: true
            };

            uiService.loaderService.showLoader();
            const result: any = await communicationDataService.sendDirectMessage(req);
            uiService.loaderService.hideLoader();
            this.key = 0;
            if (result) uiService.toastService.success('Direct Message sent');
            this.getMessages();
            this.setState({ key: 2 });
        } else if (this.messageType === 'DISPATCHER') {
            const req = {
                ID: 1,
                Message: this.state.Message,
                Subject: this.state.Subject,
                Type: 'Direct',
                Sender: userID,
                Receiver: this.driverSelected,
                CreatedDate: date,
                Category: '',
                ParentId: 0,
                IsRead: 0,
                ConvId: 0,
                SenderRole: role,
                ReceiverRole: 'ptsdispatcher',
                IsDirectCC: false
            };

            uiService.loaderService.showLoader();
            const result: any = await communicationDataService.sendDirectMessage(req);
            uiService.loaderService.hideLoader();
            this.key = 0;
            if (result) uiService.toastService.success('Direct Message sent');
            this.getMessages();
            this.setState({ key: 2 });
        }
    };

    setType = async event => {
        this.messageType = event.target.value;
        this.setState({ messageType: event.target.value });
        if (this.messageType === 'DRIVER') {
            uiService.loaderService.showLoader();
            this.driverList = await communicationDataService.getDriverList();
            uiService.loaderService.hideLoader();
            this.selectedDriverOption = [{ value: 'All', label: 'All' }];
            this.leaseOperatorName = [];
            this.leaseoperator = Array.from(new Set(this.driverList.map((item: any) => item.CarrierCompany)));
            this.leaseoperator.forEach(name => {
                this.leaseOperatorName.push({ value: name, label: name });
            });
            const driverMapping = this.getMappingJson(this.driverList);
            const driverLists = CommonUtils.dictionaryToObj(driverMapping);
            const driverList =  driverLists.sort((a,b)=>
            this.sortDriverListByLastName(a,b,'label'))
            this.setState({ driverList: driverList });
        } else if (this.messageType === 'DISPATCHER') {
            uiService.loaderService.showLoader();
            this.driverList = await communicationDataService.getDispatcherList();
            uiService.loaderService.hideLoader();
            this.handleDispatcher();
            console.log(this.driverList);
        } else if (this.messageType === 'CARRIER') {
            uiService.loaderService.showLoader();
            this.selectedCarrierOption = [{ value: 'All', label: 'All' }];
            this.selectedRoleOption = [{ value: 'All', label: 'All' }];
            this.driverList = await communicationDataService.getCCUserList();
            uiService.loaderService.hideLoader();
            this.leaseOperatorName = [];
            this.leaseoperator = Array.from(new Set(this.driverList.map((item: any) => item.Region)));
            this.leaseoperator.forEach(name => {
                this.leaseOperatorName.push({ value: name, label: name });
            });
            const driverMapping = this.getRegionMappingJson(this.driverList);
            const driverLists = CommonUtils.dictionaryToObj(driverMapping);
            const companyList= this.removeDuplicates(driverLists, 'label')
            const sortedCompanyList= companyList.sort((a,b) => 
                {
                    if(a.label < b.label)
                     return -1;
                    if(a.label > b.label)
                     return 1;

                    return 0;
            }
            )
            this.setState({ companyList: sortedCompanyList});
        }
    };

    removeDuplicates(arr, prop){
        var new_arr: any = [];
        var lookup:any = {};
        for (var i in arr) {
            lookup[arr[i][prop]] = arr[i];
        }
        for (i in lookup) {
            new_arr.push(lookup[i]);
        }
        return new_arr;
    }

    handleBroadCastType = option => {
        this.selectedBroadCastOption = option;
        this.broadcastType = option.value;
        this.setState({
            IsAllRegion: 0,
            IsCarrierCompany: 0,
            IsDrivers: 0,
            IsNorthEast: 0,
            IsNorthWest: 0,
            IsSouthEast: 0,
            IsSouthWest: 0
        });
        if (option.value === 'Drivers') this.setState({ IsDrivers: 1 });
        else if (option.value === 'Carrier Company') this.setState({ IsCarrierCompany: 1 });
        else if (option.value === 'All') {
            this.setState({ IsCarrierCompany: 1, IsAllRegion: 1, IsDrivers: 1 });
        }
    };

    handleBroadCastMessageType = option => {
        this.selectedBroadCastMessageOption = option;
        this.setState({ Category: option.value });
    };

    getMappingJson(data) {
        var mappinData = {};
        if (data.length > 0) {
            data.forEach(key => {
                mappinData[key.ID] = key.DriverName;
            });
        }
        return mappinData;
    }
    getRegionMappingJson(data) {
        var mappinData = {};
        if (data.length > 0) {
            data.forEach(key => {
                mappinData[key.ID] = key.CarrierCompanyName;
            });
        }
        return mappinData;
    }

    getRoleMappingJson(data){
        var mappinData = {};
        if (data.length > 0) {
            data.forEach(key => {
                mappinData[key.ID] = key.CarrierCompanyUser;
            });
        }
        return mappinData;
    }
    getDispatcherMappingJson(data) {
        var mappinData = {};
        if (data.length > 0) {
            data.forEach(key => {
                mappinData[key.ID] = key.FullName;
            });
        }
        return mappinData;
    }

    handleCarrierGroupSelection = option => {
        this.setState({ isCompanySelected: true });
        this.selectedDriverOption = option;
        console.log(option.value);
        let driverList = [];
        if (option.label == 'All') {
            driverList = this.driverList;
        } else {
            driverList = this.driverList.filter(x => {
                return x.Region === option.label;
            });
        }
        console.log(driverList);
        const driverMapping = this.getMappingJson(driverList);
        const driverLists = CommonUtils.dictionaryToObj(driverMapping);
        const sortedDriverList =  driverLists.sort((a,b)=>
        this.sortDriverListByLastName(a,b,'label') )
        this.setState({ driverList: sortedDriverList });
    };

    handleDispatcher() {
        this.setState({ isCompanySelected: true });
        const driverList = this.driverList;
        console.log(driverList);
        const driverMapping = this.getDispatcherMappingJson(driverList);
        const driverLists = CommonUtils.dictionaryToObj(driverMapping);
        const sortedDriverList =  driverLists.sort((a,b)=>
        this.sortDriverListByLastName(a,b,'label') )
        this.setState({ driverList: sortedDriverList });
    }

    handleRegionSelection = option => {
        this.setState({ isRegionSelected: true });
        this.selectedCarrierOption = option;
        console.log(option.value);
        let driverList = [];
        if (option.label == 'All') {
            driverList = this.driverList;
        } else {
            driverList = this.driverList.filter(x => {
                return x.Region === option.label;
            });
        }
        console.log(driverList);
        const driverMapping = this.getRegionMappingJson(driverList);
        const driverLists = CommonUtils.dictionaryToObj(driverMapping);
        this.setState({ companyList: this.removeDuplicates(driverLists, 'label') });
    };

    handleRoleSelection = option => {
        this.setState({ isRoleSelected: true });
        this.selectedRoleOption = option;
        this.roleType = option.value;
        let roleList: any = [];
        if (option.value === 'All') {
            roleList = ['Carrier Company Scheduler', 'Carrier Company Admin', 'CarrierOwner', 'Owner', 'Owner/Driver', 'Driver'];
        } else if (option.value === 'OwnerAdmin') {
            roleList = ['Carrier Company Admin', 'CarrierOwner', 'Owner', 'Owner/Driver'];
        } else if (option.value === 'Scheduler') {
            roleList = ['Carrier Company Scheduler'];
        } else if (option.value === 'Drivers') {
            roleList = ['Driver'];
        }
        console.log(option.value);
        let driverList = [];
        driverList = this.driverList.filter(x => {
            return roleList.includes(x.Role) && this.companyNameSelected.includes(x.CarrierCompanyName);
        });
        const driverMapping = this.getRoleMappingJson(driverList);
        const driverLists = CommonUtils.dictionaryToObj(driverMapping);
        const sortedDriverList =  driverLists.sort((a,b)=>
        this.sortDriverListByLastName(a,b,'label') )
        this.setState({ driverList: sortedDriverList });
    };

    handleRegionType = option => {
        this.selectedRegionOption = option;
        this.regionType = option.value;
        this.setState({
            IsAllRegion: 0,
            // IsCarrierCompany: 0,
            // IsDrivers: 0,
            IsNorthEast: 0,
            IsNorthWest: 0,
            IsSouthEast: 0,
            IsSouthWest: 0
        });
        if (option.value === 'All') this.setState({ IsAllRegion: 1 });
        else if (option.value === 'NorthEast') this.setState({ IsNorthEast: 1 });
        else if (option.value === 'NorthWest') this.setState({ IsNorthWest: 1 });
        else if (option.value === 'SouthEast') this.setState({ IsSouthEast: 1 });
        else if (option.value === 'SouthWest') this.setState({ IsSouthWest: 1 });
    };

    handleMailClick(conv) {
        const userID = accountStore.getUserID();
        const role = this.getPriorityroles();
        const date = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        let cnt = 0;
        let IDs: any[]= []
        conv.Data.forEach(x => {
            if (
                (!x.IsRead && x.SenderID !== this.userId) ||
                (this.checkCCLogin() && !x.IsRead && x.SenderID !== this.userId)
            ) {
                x.IsRead = true;
                cnt++;
                IDs.push(x.MessageID);
            }
        });
        const confirmService = new ConfirmService();
        confirmService.showEmailPopup(
            async msg => {
                const req = {
                    ID: 1,
                    Message: msg,
                    Subject: conv.Data[0].Subject == '' ? '' : conv.Data[0].Subject,
                    Type: 'Direct',
                    Sender: this.userId,
                    Receiver:
                        conv.Data[0].SenderID === this.userId ? [conv.Data[0].ReceiverID] : [conv.Data[0].SenderID],
                    CreatedDate: date,
                    Category: '',
                    ParentId: 0,
                    IsRead: 0,
                    ConvId: conv.Data[0].ConvId,
                    SenderRole: role,
                    ReceiverRole:
                        conv.Data[0].SenderID === this.userId ? conv.Data[0].ReceiverRole : conv.Data[0].SenderRole
                };

                uiService.loaderService.showLoader();
                const result: any = await communicationDataService.sendDirectMessage(req);
                uiService.loaderService.hideLoader();
                if (result) {
                    uiService.toastService.success('Direct Message sent');
                    this.getMessages();
                }
            },
            conv.Data,
            true,
            true
        );
        this.props.communicationDataStore.handleCheckboxClick(conv, true);
    }
    handleBroadCast(conv) {
        let cnt = 0;
        if (!conv.IsRead && conv.SenderID !== this.userId) {
            conv.IsRead = true;
            cnt++;
            communicationDataService.updateReadMessage({ MessageID: [conv.MessageID], Type: conv.Type, IsRead: true });
        }

        this.setState({ key: 3 });
        if (cnt > 0) {
            this.getMessages();
        }
        const confirmService = new ConfirmService();
        confirmService.showEmailPopup(email => {}, [conv], true, false);
    }

    async handleBroadCastCount(conv) {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        const broadcastReceiver: any = await communicationDataService.getBroadcastCount({ MessageID: conv.MessageID });
        uiService.loaderService.hideLoader();
        if (broadcastReceiver.length > 0) {
            const sortedData= broadcastReceiver.sort((a,b) => (
                this.sortDriverListByLastName (a,b,'Receiver')
               )) 
            confirmService.showBroadCastPopup(() => {}, sortedData);
        } else {
            uiService.toastService.error('No Reciever for this BroadCast');
        }
    }

    sortDriverListByLastName (a,b,field) {
               
        if (a[field].trim().replace("  "," ").split(" ")[a[field].trim().replace("  "," ").split(" ").length-1].toLowerCase() 
        < b[field].trim().replace("  "," ").split(" ")[b[field].trim().replace("  "," ").split(" ").length-1].toLowerCase())
         return -1;
        if (a[field].trim().replace("  "," ").split(" ")[a[field].trim().replace("  "," ").split(" ").length-1].toLowerCase() 
        > b[field].trim().replace("  "," ").split(" ")[b[field].trim().replace("  "," ").split(" ").length-1].toLowerCase())
         return 1;
         return 0;
       }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const { communicationDataStore } = this.props; 
        if (
            this.key != 1 &&
            (prevState.messageType == 'BROADCAST' ||
                prevState.messageType == 'DRIVER' ||
                prevState.messageType == 'CARRIER' ||
                prevState.messageType == 'DISPATCHER')
        ) {
            this.messageType = '';
            this.setState({ messageType: '', Subject: '', Message: '' });
            this.setState({ isCompanySelected: false });
            this.broadcastType = '';
            this.selectedBroadCastMessageOption = [];
            this.selectedBroadCastOption = [];
            this.selectedRegionOption = [];
            this.selectedCarrierOption = [];
            this.selectedDriverOption = [];
            if (this.key == 0) {
                this.key = 1;
            }
        }
        if (this.key == 2) {
            let count = 0;
            const data: any = communicationDataStore.directMessages;
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].Data.length; j++) {
                        if (
                            !this.checkCCLogin()
                                ? !data[i].Data[j].IsRead &&
                                  data[i].Data[j].SenderID !== accountStore.getUserID() &&
                                  !plainsRoles.includes(data[i].Data[j].SenderRole)
                                : !data[i].Data[j].IsRead && data[i].Data[j].SenderID !== accountStore.getUserID()
                        ) {
                            count++;
                        }
                    }
                }
            const filters = localStorageService.get('Communication_filter');
            if (communicationDataStore.directMessages.length > 0 && agGridService.getColumns()?.getColumn('From')) agGridService.setAllFilters(filters);
            if (prevState.count !== count) {
                this.setState({ count: count });
            }
            if (prevState.key !== 2) {
                setTimeout(this.interval, 100);
                this.setState({ key: 2 });
            }
        }
        if (this.key == 3) {
            const filters = localStorageService.get('BroadCast_filter');
            if (this.broadCastMsg.length > 0 && agGridService.getColumns()?.getColumn('Sender')) agGridService.setAllFilters(filters);
            if (prevState.key !== 3) {
                this.setState({ key: 3 });
                setTimeout(this.interval, 100);
            }
        }
        if (this.checkCCLogin()) {
            if (localStorageService.get('Communication_BroadCast_' + accountStore.getUserID())) {
                const broadcastData: any = localStorageService.get(
                    'Communication_BroadCast_' + accountStore.getUserID()
                );
                let count = 0;
                for (let i = 0; i < broadcastData.length; i++) {
                    if (!broadcastData[i].IsRead && broadcastData[i].SenderID !== accountStore.getUserID()) {
                        count++;
                    }
                }
                // this.Broadcastcount = count;
                if (prevState.broadcastcount !== count) {
                    this.setState({ broadcastcount: count });
                }
            }
        }
    }
    onMessageClicked = (event: CellClickedEvent) => {
        if(event.column.getColId() !== 'IsRead')
        this.handleMailClick(event.node.data);
    };

    onBroadcastRowClicked = (event: RowClickedEvent) => {
        this.handleBroadCast(event.node.data);
    };

    async open(value) {
        const itemMap = {};
        this.key = value.ID;
        if (value.ID === 3) {
            this.broadCastMsg = [];
            dataStore.setModuleName('BroadCast');
            const data: any = localStorageService.get('Communication_BroadCast_' + this.userId);
            if (localStorageService.get('Communication_BroadCast_' + this.userId)) {
                this.broadCastMsg = data;
            }
        }
        if (value.ID == 2) {
            dataStore.setModuleName('Communication');
        }

        this.setState({ key: this.key });
    }
    onCellclicked = event => {
        if (event.column.colId == 0) {
            this.handleBroadCastCount(event.data);
        } else {
            this.handleBroadCast(event.data);
        }
    };

    private getBroadCastGridConfig(): GridOptions {
        const rowData = this.broadCastMsg;
        const columnDefs = BROADCAST_COL_DEF;
        const onCellClicked = this.onCellclicked;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }

    private getMessagesGridConfig(): GridOptions {
        const { communicationDataStore } = this.props; 
        const rowData = communicationDataStore.directMessages;
        const columnDefs = communicationDataStore.getMessagesColDef();
        const onCellClicked = this.onMessageClicked;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }

    private getCCMessagesGridConfig(): GridOptions {
        const rowData = this.broadCastMsg;
        const columnDefs = CC_COL_DEF;
        const onCellClicked = this.onCellclicked;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }
}
