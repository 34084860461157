import React, { Component } from 'react';
import { GridOptions, CellEditingStoppedEvent, CellEditingStartedEvent, RangeSelectionChangedEvent, CellClickedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from 'features/common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { agGridService } from 'features/common/services/ag-grid-service';
import { apportionmentData } from '../apportionment.constants';
import { APPORTIONMENT_COL_DEF } from './apportionment-col-def';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ApportionmentDataStore } from '../stores/apportionment-data-store';
import { toJS } from 'mobx';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import moment from 'moment';
import { Right } from 'react-bootstrap/lib/Media';
import { AppConstant } from 'app_constant';
/* eslint-disable */
interface ApportionmentDataProps {
    apportionmentStore: ApportionmentDataStore;
}

interface ApportionmentDataStates {
    selectedMonth: any;
    selectedYear: any;
    datesOfMonth: any;
    currentMonth: any;
    currentYear: any;
}
@observer
export class ApportionmentComponent extends Component<ApportionmentDataProps, ApportionmentDataStates> {
    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
    checkeddata: any;
    nominationLoadData: any[] = [];
    constructor(props) {
        super(props);
        const datesOfMonth = Array.from(
            Array(this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
            (_, i) => i + 1
        );
        this.state = {
            selectedMonth: new Date().getMonth(),
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            selectedYear: new Date().getFullYear(),
            datesOfMonth: datesOfMonth
        };
        dataStore.setModuleName('Apportionment');
    }
    componentDidMount() {
        console.log('init');
        this.props.apportionmentStore.init();
    }

    render(): React.ReactNode {
        const monthNames = moment.months();
        const getYear = new Date().getFullYear();
        const years = Array.from(new Array(25), (val, index) => getYear + (index - 5));
        const { selectedMonth, selectedYear } = this.state;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleEdit}
                                            disabled={
                                                this.props.apportionmentStore.IsEditDisabled() || this.isSaveDisabled() || !dataStore.checkOperationAccess('Save')
                                            }
                                        >
                                            Edit
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleSave}
                                            disabled={
                                                this.props.apportionmentStore.IsSaveDisabled() || this.isSaveDisabled()  || !dataStore.checkOperationAccess('Save')
                                            }
                                        >
                                            Save
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleReset}
                                            disabled={
                                                this.props.apportionmentStore.IsSaveDisabled() || this.isSaveDisabled()
                                            }
                                        >
                                            Reset
                                        </CustomButton>
                                        {/* <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleApply}
                                            disabled={
                                                this.isApplyDisabled() ||
                                                this.props.apportionmentStore.isApplyDisabled() || !dataStore.checkOperationAccess('Save')
                                            }
                                        >
                                            Apply
                                        </CustomButton> */}
                                        <ClearButtonComponent
                                            handleRefresh={this.handleRefresh}
                                            isExportCSV={true}
                                            disabled={this.props.apportionmentStore.isDisabled()}
                                        ></ClearButtonComponent>
                                    </>
                                </Col>
                            </Row>
                                
                            <Row>
                                <div className="react-select-container">
                                    <div className="react-select-inner-container">
                                        <b>Select: </b> <p>Month</p>
                                        <select
                                            name="month"
                                            value={monthNames[selectedMonth]}
                                            onChange={e => this.handleChangeMonth(e.currentTarget.value)}
                                            // defaultValue={monthNames[selectedMonth]}
                                            className="react-select-style"
                                        >
                                            {monthNames.map((month, i) => (
                                                <option key={month} value={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="react-select-inner-container">
                                        <p>Year</p>
                                        <select
                                            name="year"
                                            value={selectedYear}
                                            className="react-select-style"
                                            // defaultValue={selectedYear}
                                            onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                                        >
                                            {years.map(yearData => (
                                                <option key={yearData} value={yearData}>
                                                    {yearData}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    
                                </div>
                                <Col></Col>
                                <Col>
                                <div className="mt-3 mt-sm-0" style={{ fontSize: 10, textAlign: "left"}}>
                                Note: </div>
                                <div className="mt-3 mt-sm-0" style={{ fontSize: 10, textAlign: "left"}}>
                                1) Apportionment values should be updated before the last day of the pre-prod month.</div>
                                <div className="mt-3 mt-sm-0" style={{ fontSize: 10, textAlign: "left"}}>
                                i.e. for June apportionment values should be updated before May 31.</div>
                                <div className="mt-3 mt-sm-0" style={{ fontSize: 10, textAlign: "left"}}>
                                2) Apportionment will be applied automatically on the last day of the pre-prod month at <span style={{fontSize: 'inherit', textAlign: 'inherit',whiteSpace: 'nowrap'}}>11:59 PM MST.</span></div>
                               <div className="mt-3 mt-sm-0" style={{ fontSize: 10, textAlign: "left"}}>
                                3) Once Apportionment is applied, it cannot be reversed.</div>
                            </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    isSaveDisabled = (): any => {
        if (
            (this.state.currentMonth > this.state.selectedMonth && this.state.currentYear == this.state.selectedYear) ||
            this.state.currentYear > this.state.selectedYear
        ) {
            return true;
        } else {
            return false;
        }
    };

    handleRefresh = () => {
        const { apportionmentStore } = this.props;
        if(Object.keys(apportionmentStore.cellValueChangeMap).length === 0) {
            apportionmentStore.getApportionmentScreenData(
                    this.state.selectedMonth + 1,
                    this.state.selectedYear );
            apportionmentStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    apportionmentStore.getApportionmentScreenData(
                        this.state.selectedMonth + 1,
                        this.state.selectedYear );
                    apportionmentStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    isApplyDisabled = (): any => {
        if (
            (this.state.currentMonth > this.state.selectedMonth && this.state.currentYear == this.state.selectedYear) ||
            this.state.currentYear > this.state.selectedYear
        ) {
            return true;
        } else {
            return false;
        }
    };

    handleChangeMonth = month => {
        this.setState({
            selectedMonth: moment.months().indexOf(month),
            datesOfMonth: Array.from(
                Array(this.daysInMonth(moment.months().indexOf(month) + 1, this.state.selectedYear)),
                (_, i) => i + 1
            )
        });
        // this.props.apportionmentStore.selectedMonth = moment.months().indexOf(month);
        this.props.apportionmentStore.getApportionmentScreenData(
            moment.months().indexOf(month) + 1,
            this.state.selectedYear
        );
        this.props.apportionmentStore.cellValueChangeMap = []
    };

    handleChangeYear = year => {
        this.setState({
            selectedYear: year,
            datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1)
        });
        // this.props.apportionmentStore.selectedYear = year;
        this.props.apportionmentStore.getApportionmentScreenData(this.state.selectedMonth + 1, year);
    };

    exportCsv() {
        agGridService.getNodes()?.exportDataAsCsv({ fileName: dataStore.getCurrentModuleName() });
    }
    componentDidUpdate() {
        console.log('updated');
        const filters = localStorageService.get('Apportionment_filter');
        agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        this.props.apportionmentStore.updateApportionment(this.state.selectedMonth + 1, this.state.selectedYear, false);
    };

    private handleReset = (): void => {
        this.props.apportionmentStore.resetApportionData();
    };

    private handleApply = (): void => {
        const confirmService = new ConfirmService();
        confirmService.showApportionmentApplyPopup(value => {
            this.props.apportionmentStore.updateApportionment(this.state.selectedMonth + 1, this.state.selectedYear, true);
        })
    };

    private handleEdit = (): void => {
        const confirmService = new ConfirmService();
        this.checkeddata = toJS(this.props.apportionmentStore.dataToBeDeleted);
        const data: any = Array.from(new Set(this.checkeddata.map((item: any) => item.ID)));
        data.forEach((x, ind) => {
            this.nominationLoadData.filter(element => {
                if (x === element.ID) {
                    this.props.apportionmentStore.setValueInChangeMap(
                        element.ID,
                        'Apportionment',
                        element.Apportionment ? element.Apportionment : '',
                        ''
                    );
                }
            });
        });
        confirmService.showApportionmentPopup(value => {
            this.checkeddata = toJS(this.props.apportionmentStore.dataToBeDeleted);
            const data: any = Array.from(new Set(this.checkeddata.map((item: any) => item.ID)));
            this.nominationLoadData = toJS(this.props.apportionmentStore.getApportionmentData);
            data.forEach((x, ind) => {
                this.nominationLoadData.filter(element => {
                    if (x === element.ID) {
                        this.props.apportionmentStore.setValueInChangeMap(
                            element.ID,
                            'Apportionment',
                            element.Apportionment ? element.Apportionment : '',
                            value
                        );
                        element.Apportionment = value;
                        element.DELETE = false;

                        this.props.apportionmentStore.updateLoadRow(element);
                    }
                });
            });
            this.props.apportionmentStore.dataToBeDeleted = [];
        });
    };

    private getGridConfig(): GridOptions {
        const rowData = this.props.apportionmentStore.getApportionmentData;
        const columnDefs = APPORTIONMENT_COL_DEF;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;
        const onCellClicked = this.onCellclicked;

        const LocationManagementGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onRangeSelectionChanged,
            onCellClicked,
        };
        return agGridService.getGridConfig(LocationManagementGridParams);
    }

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.apportionmentStore.agGridService.getNodes();
        if(e.started === false && e.finished === true) {            
            var cellRanges:any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index )=> {
                if(index >= startRowIndex && index <= endRowIndex) {                    
                    x.setDataValue('DELETE', true);
                    this.props.apportionmentStore.handleCheckboxClick(x.data, false, "DELETE");
                }
            });

            csvData?.rowModel.gridApi.refreshCells();            
        }
    }

    onCellclicked = (event: CellClickedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            
            
            const csvData: any = this.props.apportionmentStore.agGridService.getNodes();

            if(event.column.getColId() !== 'DELETE') {
                csvData?.rowModel.rowsToDisplay.forEach((x, index )=> {                    
    
                    // if(event.data.ID === x.data.ID) {
                    //     this.props.apportionmentStore.handleCheckboxClick(x.data, false, "DELETE");
                    //     x.setDataValue('DELETE', true);
                    // } else {
                        this.props.apportionmentStore.handleCheckboxClick(x.data, true, "DELETE");
                        x.setDataValue('DELETE', false);
                    // }
                });
            }

            
        } catch (err) {
            console.log(err);
        }
    };
}
