import { observable, action, computed, toJS } from 'mobx';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import Catch from 'shared/decorators/catch-decorator';
import { errorHandler } from 'shared/handlers/error-handler';
import { ComplianceReviewDropdown, HazardAssessmentHistoryRequest, HazardAssessmentHistoryResponse, OperationsReviewDropdown} from '../model/hazardAssessment-history-model';
import Loader from 'shared/decorators/loader-decorator';
import { HazardAssessmentHistoryMessage, HazardAssessmentHistoryFieldName } from '../model/hazardAssessment-history-enum';
import { HazardAssessmentTicketHistoryDataService } from '../services/hazardAssessment-ticket-history-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { hazardAssessmentHistoryColDef } from '../domains/hazardAssessment-history-col-def';
import { dataStore } from 'features/common/stores';
import { DateRange } from 'shared/components/daypicketInput/react-daypickerInput';
import _ from 'lodash';
import moment from 'moment';

export class HazardAssessmentTicketHistoryDataStore {
    @observable _hazardAssessmentHistoryInfo: HazardAssessmentHistoryResponse[] = [];
    @observable _hazardAssessmentData: HazardAssessmentHistoryResponse[] =[];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    // statusTypes: StatusType[] = [];
    operationsReviewDropdown: any[] = [];
    complianceReviewDropdown: any[] = [];
    backupHazardAssessmentHistoryList: HazardAssessmentHistoryResponse[] = [];
    startDate: any;
    endDate: any;
    isApproverPopUpVisible = false;
    approvedCommentTicketModel: any;
    approvedColumn: any;
    updatedRowIDs: any = []

    constructor(
        private _service: HazardAssessmentTicketHistoryDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @computed
    get hazardAssessmentHistoryList(): HazardAssessmentHistoryResponse[] {
        return toJS(this._hazardAssessmentHistoryInfo);
    }
    getColDef() {
        // this.updateOperationsCommentColDef();
        // this.updateComplianceCommentColDef();
        return hazardAssessmentHistoryColDef;
    }
    updateOperationsCommentColDef = () => {
        const commentColDef = hazardAssessmentHistoryColDef.find(x => x.colId === HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateHazardAssessmentHistoryComment(item, value, HazardAssessmentHistoryFieldName.OPERATIONSREVIEWCOMMENTS),
                isDisabled: (item: HazardAssessmentHistoryResponse, value) =>
                    item.Resolved ||
                    !dataStore.checkOperationAccessWithModule(
                        'HazardAssessmentOperationReviewEdit', 
                        'HazardAssessmentTicketHistory')
            };
        }
    };

    updateComplianceCommentColDef = () => {
        const commentColDef = hazardAssessmentHistoryColDef.find(x => x.colId === HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateHazardAssessmentHistoryComment(item, value, HazardAssessmentHistoryFieldName.COMPLIANCEREVIEWCOMMENTS),
                isDisabled: (item: HazardAssessmentHistoryResponse, value) =>
                    item.Resolved ||
                    !dataStore.checkOperationAccessWithModule(
                        'HazardAssessmentComplianceReviewEdit', 
                        'HazardAssessmentTicketHistory')
            };
        }
    };
    updateReviewColDef() {
        const reviewValues = [HazardAssessmentHistoryFieldName.OPERATIONSREVIEW, HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW];
        reviewValues.forEach(reviewValue => {
            const commentColDef = hazardAssessmentHistoryColDef.find(x => x.colId == reviewValue);
            if (commentColDef) {
                commentColDef.cellRendererParams = {
                    onChange: (item, value) => this.updateHazardAssessmentHistoryComment(item, value, reviewValue),
                    isReadonly: (item: HazardAssessmentHistoryResponse, value) => {
                        return (
                            item.Resolved ||
                            !dataStore.checkOperationAccessWithModule(
                                'HazardAssessmentComplianceReviewEdit', 
                                'HazardAssessmentTicketHistory')
                        );
                    }
                };
            }
        });
    }
    // updateResolvedCommentColDef = () => {
    //     const commentColDef = hazardAssessmentHistoryColDef.find(x => x.colId === HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS);
    //     if (commentColDef) {
    //         commentColDef.cellRendererParams = {
    //             onChange: (item, value) =>
    //                 this.updateHazardAssessmentHistoryComment(item, value, HazardAssessmentHistoryFieldName.RESOLVEDCOMMENTS),
    //             isDisabled: (item: HazardAssessmentHistoryResponse, value) =>
    //                 !dataStore.checkOperationAccessWithModule('HazardAssessmentResolvedEdit', 'HazardAssessmentTicketHistory')
    //         };
    //     }
    // };

    @action
    reset(): void {
        this.sethazardAssessmentHistoryList([]);
    }

    @action
    sethazardAssessmentHistoryList(tabletInfo: HazardAssessmentHistoryResponse[]): void {
        this._hazardAssessmentHistoryInfo = tabletInfo;
    }

    @action
    setHazardAssessmentHistoryList(hazardAssessmentHistoryInfo: HazardAssessmentHistoryResponse[]): void {
        this._hazardAssessmentHistoryInfo = hazardAssessmentHistoryInfo;
    }
    @action
    resetHazardAssessmentList(): void {
        this.setHazardAssessmentHistoryList(this.backupHazardAssessmentHistoryList);
        this.cellValueChangeMap = {};
    }

    @action
    updateHazardAssessmentHistoryComment(item: HazardAssessmentHistoryResponse, value: string, key: string, popup?: boolean) {
        if((value.length > 0 && value != item[key]) || (value.length == 0 && value !== item[key]) || popup === true){
            this.setValueInChangeMap(item.ID, key, item[key], value);
            if (popup && item[key] != null) item[key] = item[key] + '\n' + value;
            else item[key] = value;
            this.updateRow(item);
        }
      
    }

    getUpdatedRowIDs(): string[] {

        let updatedRowIDs: string[] = [];
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        console.log(updatedRowIDs)
       return updatedRowIDs;
    }
    

    updateTicketHistoryRequest(updatedRows: HazardAssessmentHistoryResponse[]): HazardAssessmentHistoryRequest[] {
        let requestList: HazardAssessmentHistoryRequest[] = [];
        
        updatedRows.forEach(item => {
            const { HazardID,
                    RiskLevel,
                    VolumeorHourlyTicketNumber,
                    DriverID,
                    Weather,
                    Description,
                    ConsignorID,
                    ConsigneeID,
                    CustomerName,
                    EventDetails,
                    Recommendations,
                    Signature,
                    CreatedBy } = item;
            if (
                dataStore.checkOperationAccess('HazardAssessmentOperationReviewEdit') &&
                (this.cellValueChangeMap[item.ID]['OperationReviewComments'] ||
                    this.cellValueChangeMap[item.ID]['OperationReview'])
            ) {
                item.OperationReviewBy = this.userID;
                item.OperationReviewDate = moment()
                    .toDate()
                    .toISOString();
            }
            if (
                dataStore.checkOperationAccess('HazardAssessmentComplianceReviewEdit') &&
                (this.cellValueChangeMap[item.ID]['ComplianceReviewComments'] ||
                    this.cellValueChangeMap[item.ID]['ComplianceReview'])
            ) {
                item.ComplianceReviewBy = this.userID;
                item.ComplianceReviewDate = moment()
                    .toDate()
                    .toISOString();
            }
            // if(
            //     dataStore.checkOperationAccess('HazardAssessmentResolvedEdit') &&
            //          (item.Resolved === false || item.Resolved === null))
            //              item.Resolved= true
                
            requestList = [
                ...requestList,
                {
                    HazardID: HazardID,
                    RiskLevel: RiskLevel,
                    VolumeorHourlyTicketNumber: VolumeorHourlyTicketNumber,
                    DriverID: DriverID,
                    Weather: Weather,
                    Description: Description,
                    ConsignorID: ConsignorID,
                    ConsigneeID: ConsigneeID,
                    CustomerName: CustomerName,
                    EventDetails: EventDetails,
                    Recommendations: Recommendations,
                    Signature: Signature,
                    CreatedBy: CreatedBy,
                    OperationReview: item.OperationReview,
                    OperationReviewComments: item.OperationReviewComments,
                    ComplianceReview: item.ComplianceReview,
                    ComplianceReviewComments: item.ComplianceReviewComments,
                    OperationReviewBy: item.OperationReviewBy,
                    ComplianceReviewBy: item.ComplianceReviewBy,
                    ModifiedBy: item.ModifiedBy,
                    OperationReviewDate: item.OperationReviewDate,
                    ComplianceReviewDate: item.ComplianceReviewDate,
                    ModifiedDate: item.ModifiedDate,
                    Resolved: item.Resolved,
                    ResolvedDate: item.ResolvedDate,
                    ResolvedBy: item.ResolvedBy,
                    ResolvedComments: item.ResolvedComments,
                }
            ];

        });
        return requestList;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    @Loader
    @Catch(() => errorHandler(HazardAssessmentHistoryMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {

        const { hazardAssessmentData } = await this._service.getHazardAssessmentViewModel();
        const _hazardAssessmentData: HazardAssessmentHistoryResponse[] = hazardAssessmentData['Data'].filter(a => !a.IsDeleted);
        
        this.operationsReviewDropdown = OperationsReviewDropdown;
        this.complianceReviewDropdown = ComplianceReviewDropdown;
        
        this.sethazardAssessmentHistoryList(_hazardAssessmentData);
        this.setBackUpHazardAssessmentHistoryList(_hazardAssessmentData);
        this.startDate = '';
        this.endDate = '';
       console.log(_hazardAssessmentData)
        this.addValuesInCellDropdowns();
       
    }

    isDisabled = (): boolean => {
        if (this._hazardAssessmentHistoryInfo.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    @action
    showTicketApproverPopUp() {
        this.isApproverPopUpVisible = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.isApproverPopUpVisible = false;
    }

    @Loader
    @action
    @Catch(() => errorHandler(HazardAssessmentHistoryMessage.FAILED_SUBMIT))
    async updateHazardAssessmentHistory(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const updatedList = this._hazardAssessmentHistoryInfo.filter(a => {
            this.backupHazardAssessmentHistoryList.filter(b =>{
                if(a.ID === b.ID){
                    if(a.ComplianceReview != b.ComplianceReview || a.ComplianceReviewComments != b.ComplianceReviewComments ||
                        a.OperationReview != b.OperationReview || a.OperationReviewComments != b.OperationReviewComments ||
                        a.Resolved != b.Resolved){
                            this.updatedRowIDs.push(a)
                        }}
                })
        })
       const requestBody = this.updateTicketHistoryRequest(this.updatedRowIDs);
       console.log(requestBody)
        let errorInSave = false;
        if(requestBody){
        const response = await this._service.updateHazardAssessmentHistory(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key]) {
                    const objIndex = this._hazardAssessmentHistoryInfo.findIndex(a => a.ID === parseInt(key));
                    this._hazardAssessmentHistoryInfo[objIndex].ModifiedBy = 
                        response[key].ModifiedBy;
                    this._hazardAssessmentHistoryInfo[objIndex].ModifiedDate = 
                        response[key].ModifiedDate;
                    this._hazardAssessmentHistoryInfo[objIndex].OperationReview =
                        response[key].OperationReview;
                    this._hazardAssessmentHistoryInfo[objIndex].OperationReviewBy =
                        response[key].OperationReviewBy;
                    this._hazardAssessmentHistoryInfo[objIndex].OperationReviewComments =
                        response[key].OperationReviewComments;
                    this._hazardAssessmentHistoryInfo[objIndex].OperationReviewDate =
                        response[key].OperationReviewDate;
                    this._hazardAssessmentHistoryInfo[objIndex].Resolved =
                        response[key].Resolved;
                    this._hazardAssessmentHistoryInfo[objIndex].ResolvedBy =
                        response[key].ResolvedBy;
                    this._hazardAssessmentHistoryInfo[objIndex].ResolvedBy =
                        response[key].ResolvedBy;
                    this._hazardAssessmentHistoryInfo[objIndex].ResolvedByName =
                        response[key].ResolvedByName;
                        this._hazardAssessmentHistoryInfo[objIndex].ResolvedBy =
                        response[key].ResolvedBy;
                        this._hazardAssessmentHistoryInfo[objIndex].ResolvedByName =
                        response[key].ResolvedByName;
                    
                    this._hazardAssessmentHistoryInfo[objIndex].ResolvedComments =
                        response[key].ResolvedComments;
                    this._hazardAssessmentHistoryInfo[objIndex].ResolvedDate =
                        response[key].ResolvedDate;
                    this._hazardAssessmentHistoryInfo[objIndex].ComplianceReview =
                        response[key].ComplianceReview;
                    this._hazardAssessmentHistoryInfo[objIndex].ComplianceReviewBy =
                        response[key].ComplianceReviewBy;
                    this._hazardAssessmentHistoryInfo[objIndex].ComplianceReviewDate = 
                        response[key].ComplianceReviewedDate;
                    this._hazardAssessmentHistoryInfo[objIndex].ComplianceReviewComments =
                        response[key].ComplianceReviewComments;

                    for (let i = 0; i < this.backupHazardAssessmentHistoryList.length; i++) {
                        const backupItem = this.backupHazardAssessmentHistoryList[i];
                        if (backupItem.ID === parseInt(key)) {
                            this.mapEditableColumns(backupItem, this._hazardAssessmentHistoryInfo[objIndex]);
                            break;
                        }
                    }
                } else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: response[key] }];
                }
            }
        }}
        this.getRangeResult(this.startDate,this.endDate);
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this._hazardAssessmentHistoryInfo.length; i++) {
                    const updatedtickethistory = this._hazardAssessmentHistoryInfo[i];
                    if (updatedtickethistory.DriverID === parseInt(errorMessage.id)) {
                        const backupTicketHazardAssessmentIndex = this.backupHazardAssessmentHistoryList.findIndex(
                            a => a.DriverID === parseInt(errorMessage.id)
                        );
                        this.mapEditableColumns(
                            updatedtickethistory,
                            this.backupHazardAssessmentHistoryList[backupTicketHazardAssessmentIndex]
                        );
                        break;
                    }
                }
            });
        } else {
            this.uiService.toastService.success(HazardAssessmentHistoryMessage.SAVE);

            
        }
        this.cellValueChangeMap = {};
        this.updatedRowIDs = [];
    }

    
    @action
    updateRow = (selectedRowData: HazardAssessmentHistoryResponse): void => {
        this._hazardAssessmentHistoryInfo.forEach(hazardAssessment => {
            if (hazardAssessment.ID === selectedRowData.ID) {
                if (!_.isEqual(hazardAssessment, selectedRowData)) {    
                    if (
                        hazardAssessment.OperationReview !== selectedRowData.OperationReview &&
                        selectedRowData.OperationReview == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'OperationReviewComments');
                    }
                    if (
                        hazardAssessment.ComplianceReview != selectedRowData.ComplianceReview &&
                        selectedRowData.ComplianceReview == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'ComplianceReviewComments');
                    }
                    this.mapEditableColumns(hazardAssessment, selectedRowData);
                }
            }
        });
    };
    @action
    updateResolved = (selectedRowData: HazardAssessmentHistoryResponse): void => {
        this._hazardAssessmentHistoryInfo.forEach(hazardAssessment => {
            if (hazardAssessment.ID === selectedRowData.ID) {
                 if ((selectedRowData.Resolved == false || selectedRowData.Resolved == null)){
                    if(selectedRowData.OperationReview == 'New' || 
                    selectedRowData.OperationReview == null ){
                        
                    this.uiService.toastService.error(`Please complete Operations Review before Resolving`);
                    this.getRangeResult(this.startDate,this.endDate);
                    }
                 else if (selectedRowData.ResolvedComments == null){
                    this.triggerModal(selectedRowData, 'ResolvedComments');
                    selectedRowData.ResolvedBy = this.userID;
                    selectedRowData.ResolvedDate = moment()
                        .toDate()
                        .toISOString();
                    selectedRowData.Resolved = true; 
                    this.mapEditableColumns(hazardAssessment, selectedRowData);
                    }
                }
            }
        });
    };

    mapEditableColumns(currentItem: HazardAssessmentHistoryResponse, updatedItem: HazardAssessmentHistoryResponse) {
        if (currentItem) {
            currentItem.OperationReview = updatedItem.OperationReview;
            currentItem.ComplianceReview = updatedItem.ComplianceReview;
            currentItem.ComplianceReviewComments = updatedItem.ComplianceReviewComments;
            currentItem.OperationReviewComments = updatedItem.OperationReviewComments;
            currentItem.ResolvedComments = updatedItem. ResolvedComments;
            currentItem.Resolved = updatedItem.Resolved;
            currentItem.ResolvedBy = updatedItem.ResolvedBy;
            currentItem.ResolvedByName = updatedItem.ResolvedByName;
            currentItem.ResolvedDate = updatedItem.ResolvedDate;
            
        }
        if(currentItem.ResolvedComments){
                this.updateHazardAssessmentHistory()
            }
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    triggerModal(item, col) {
        this.approvedCommentTicketModel = item;
        this.approvedColumn = col;
        this.showTicketApproverPopUp();
    }

    updateComment(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
       
        this.updateHazardAssessmentHistoryComment(
            this.approvedCommentTicketModel,
            approverComment,
            this.approvedColumn,
            true
        );
    }

    setBackUpHazardAssessmentHistoryList(hazardAssessment: HazardAssessmentHistoryResponse[]) {
        this.backupHazardAssessmentHistoryList = hazardAssessment;
    }

    private addValuesInCellDropdowns(): void {
                
                    this.agGridService.updateOptionCellEditorValues(
                        this.operationsReviewDropdown,
                        HazardAssessmentHistoryFieldName.OPERATIONSREVIEW,
                        'label'
                    );
                    this.agGridService.updateOptionCellEditorValues(
                        this.complianceReviewDropdown,
                        HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW,
                        'label'
                    );
                    // this.accessCheck(HazardAssessmentHistoryFieldName.OPERATIONSREVIEW, 'HazardAssessmentOperationReviewEdit');
                    // this.accessCheck(HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW, 'HazardAssessmentComplianceReviewEdit');
                
       }

    accessCheck(columnKey: string, propertyName: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);
        this.agGridService.updateEditableProperty(columnKey, access);
        return access;
    }

    @Loader
    @Catch(() => {
        errorHandler(HazardAssessmentHistoryMessage.FETCH_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        // this._hazardAssessmentHistoryInfo = [];
        const { hazardAssessmentData} = await this._service.getHazardAssessmentViewModelWithDateParams(reqbody);
        const _hazardAssessmentData: HazardAssessmentHistoryResponse[] = hazardAssessmentData['Data'].filter(a => !a.IsDeleted);
        this.sethazardAssessmentHistoryList(_hazardAssessmentData);
        this.setBackUpHazardAssessmentHistoryList(_hazardAssessmentData);
            this.addValuesInCellDropdowns();
        
        if (_hazardAssessmentData.length == 0) {
            errorHandler(HazardAssessmentHistoryMessage.NO_TICKETS);
        }
    }

    saveDateRange(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
