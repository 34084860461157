import React, { Component } from 'react';
import './_info.scss';
import { CommonUtils } from 'shared/services/common-utils';
import { InfoVideoPopUp, infoVideoProps } from 'shared/components/confirm/infoVideoPopup';
import { ConfirmService } from 'shared/components/confirm/confirm-service';

export const videos = [
    { text: 'Carrier Portal Overview', file: 'SignUp.mp4'}
]

export class InfoComponent extends Component {
    render(): React.ReactNode {
        return (
            
            <div className="infopage__parent_container">
                <div className="infopage__defaultpage">
                    <label className="infopage__header">Videos</label>
                    {videos.map((link, key) => {
                        return (
                        <div onClick={() => this.getVideoURL(link)}>-{'>'} <span className="infopage__list">{link.text}</span></div>
                        )
                    })
                    
                    }
                </div>
            </div>
        );
    }
    getVideoURL(link) {
        const publicUrl = CommonUtils.getPublicUrl();
        console.log('publicUrl', publicUrl);
        const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {},
                    InfoVideoPopUp,
                    {url: publicUrl + '/SignUp.mp4', text: link.text} as infoVideoProps,
                    ''
                );
    }
}
