import * as React from 'react';
import '../../../../shared/components/confirm/_confirm.scss'//'../../../shared/components/confirm/_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import Select from 'react-select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { uiService } from 'shared/services/ui-service';
import { dispatchScreenDataStore } from '../stores';
import { dateUtils } from 'shared/services/date-utils';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { toJS } from 'mobx';
import { Confirm } from 'shared/components/confirm/confirm';
export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (date) => void;
}
export class TicketsDispatchPopUp extends React.Component<ConfirmProps> {
    state = {
        startDate: '',
        endDate: '',
        popUpMsg:''
    }
    selectedVendorData: any;
    fromDate: any = '';
    StartDate: string = '';
    from: any = '';
    EndDate: any = '';
    toDate: any = '';
    to: any = '';
    isSaveDisabled: boolean = false;
    public render(): React.ReactNode {
        const { onClose } = this.props;
        return (
            <div className="plannedDatePopUp">
                <label style={{ fontSize: '16px', padding: '16px 0' }}>Select PlannedDate Range:</label>
                {this.state.popUpMsg !== '' && (
                            <div
                                style={{
                                    minHeight: '200px',
                                    position: 'absolute',
                                    margin: '150px 0 0 75px',
                                    zIndex: 1
                                }}
                            >
                                <Confirm
                                    onClose={() => {
                                        this.setState({ popUpMsg: '' });
                                    }}
                                    primaryText=""
                                    secondaryText={this.state.popUpMsg}
                                    onSuccessClick={this.handleSave}
                                />
                            </div>
                        )}
                <div>
                <Row>
                    <div style={{ paddingLeft: '16px', paddingRight: '0px' }}>
                        <div className="startDate-container">
                            <label style={{ paddingRight: '5px', fontSize: '14px' }}>From -</label><DayPickerInput
                                ref={el => (this.fromDate = el)}
                                dayPickerProps={{
                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                    canChangeMonth: false,
                                    className: 'day-picker',
                                    disabledDays: []
                                }}
                                value={
                                    this.StartDate !== ''
                                        ? moment(this.StartDate)
                                            .format('YYYY-MM-DD')
                                            .toString()
                                        : ''
                                }
                                inputProps={{
                                    style: {
                                        height: '40px',
                                        border: '1px solid #1eb2f3',
                                        borderRadius: 5,
                                        textAlign: 'center',
                                        fontSize: 12
                                    },
                                    disabled: null,
                                    value:
                                        this.StartDate !== ''
                                            ? moment(this.StartDate)
                                                .format('YYYY-MM-DD')
                                                .toString()
                                            : '',
                                    placeholder: 'yyyy-mm-dd'
                                }}
                                onDayChange={(date, modifiers) => {
                                    if (!modifiers.disabled) {
                                        this.StartDate = date.toISOString().slice(0, 10);
                                        this.setState({ startDate: this.StartDate });
                                        this.from = date;
                                        if (!this.EndDate) {
                                            this.toDate.getInput().focus();
                                        } else if (this.StartDate > this.EndDate) {
                                            this.toDate.getInput().focus();
                                        } else {
                                            this.toDate.getInput().focus();
                                        }
                                    }
                                }}

                            />
                        </div>
                    </div>
                    <div style={{ paddingLeft: '10px', paddingRight: '0px' }}>
                        <div className="endDate-container">
                            <label style={{ paddingRight: '5px', fontSize: '14px' }}>To -</label><DayPickerInput
                                ref={el => (this.toDate = el)}
                                dayPickerProps={{
                                    month: new Date(dispatchScreenDataStore.nominationYear, dispatchScreenDataStore.nominationMonth, 1),
                                    canChangeMonth: false,
                                    className: 'day-picker',
                                    disabledDays: []
                                }}
                                value={
                                    this.EndDate !== ''
                                        ? moment(this.EndDate)
                                            .format('YYYY-MM-DD')
                                            .toString()
                                        : ''
                                }
                                inputProps={{
                                    style: {
                                        height: '40px',
                                        border: '1px solid #1eb2f3',
                                        borderRadius: 5,
                                        textAlign: 'center',
                                        fontSize: 12
                                    },
                                    disabled: null,
                                    value:
                                        this.EndDate !== ''
                                            ? moment(this.EndDate)
                                                .format('YYYY-MM-DD')
                                                .toString()
                                            : '',
                                    placeholder: 'yyyy-mm-dd'
                                }}
                                onDayChange={(date, modifiers) => {
                                    if (!modifiers.disabled) {
                                        this.EndDate = date.toISOString().slice(0, 10);
                                        this.setState({ endDate: this.EndDate });
                                        this.to = date;
                                        this.isSaveDisabled = false
                                        if (!this.StartDate) {
                                            this.fromDate.getInput().focus();
                                        } else if (this.StartDate > this.EndDate) {
                                            this.fromDate.getInput().focus();
                                            uiService.toastService.info(
                                                'StartDate cannot be greater than EndDate'
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Row>
                {/* <Row> */}
                
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleDispatch}
                        disabled={this.StartDate === '' || this.EndDate === ''}>
                        Dispatch
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.handleCancel}
                    >
                        Cancel
                    </CustomButton>
                </div>
                {/* </Row> */}
            </div>
        );
    }
    handleVendor = option => {
        this.selectedVendorData = option;
        console.log(option);
        this.setState({ vendorPlaceholder: option });
    };
    handleDispatch = () => {
        let selectedTicketsFilter: any = [], plannedTicketsFilter: any = []
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            selectedTicketsFilter = dispatchScreenDataStore.selectedTickets.filter(item => dateUtils.updateFormatDate(item.PlannedDateTime) >= dateUtils.updateFormatDate(this.StartDate) && dateUtils.updateFormatDate(item.PlannedDateTime) <= dateUtils.updateFormatDate(this.EndDate))
            console.log(toJS(dispatchScreenDataStore.selectedTickets), this.EndDate, this.StartDate);
            console.log(toJS(selectedTicketsFilter))
        }
        if (dispatchScreenDataStore.plannedLoads.length > 0) {
            plannedTicketsFilter = dispatchScreenDataStore.plannedLoads.filter(item => dateUtils.updateFormatDate(item.Date) >= dateUtils.updateFormatDate(this.StartDate) && dateUtils.updateFormatDate(item.Date) <= dateUtils.updateFormatDate(this.EndDate))
        }
        if(plannedTicketsFilter.length === 0 && selectedTicketsFilter.length === 0){
            uiService.toastService.error('There are no tickets to be dispatched in the given Planned Date Range');
        } else {
            console.log(toJS(dispatchScreenDataStore.selectedTickets), toJS(selectedTicketsFilter))
            if(dispatchScreenDataStore.selectedTickets.length !== selectedTicketsFilter.length) {
                this.setState({ popUpMsg: 'There are other unsaved Tickets which are not in the selected Planned Date range which will be lost. You can save now and Dispatch later. Do you still want to continue ?'})
                
            } else {
                this.props.onSuccessClick({ startDate: this.StartDate, endDate: this.EndDate });
                this.props.onClose();
            }
            
        }
        
    };

    handleSave = () => {
        this.props.onSuccessClick({ startDate: this.StartDate, endDate: this.EndDate });
        this.props.onClose();
    }

    handleCancel = () => {
        this.props.onClose();
    }
}