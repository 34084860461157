import React, { Component } from 'react';
import { CellValueChangedEvent, ColDef, ColumnMovedEvent, ColumnPinnedEvent,ColumnResizedEvent, ColumnState, ColumnVisibleEvent, FilterChangedEvent, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import { UserApprovalDataStore } from '../stores/user-approval-data-store';
// import { DriverStatusCellRenderer } from '../cell-renderers/driver-status-custom-cell-renderer';
import '../../dispatch/dispatch-board/components/style.scss';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { AgGridReact } from 'ag-grid-react';
import { dataStore } from 'features/common/stores';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { ticketsGridService } from '../services/tickets-grid-service';
import { accountStore } from 'features/account/stores';

export interface DriversOpenTicketListPopupProps {
    dataSource: any;
    onClose: () => void;
    onCellValueChanged?: (value: CellValueChangedEvent) => void;
    onFilterChanged?: (value: FilterChangedEvent) => void;
    userApprovalDataStore: UserApprovalDataStore;
}

@inject('userApprovalDataStore')
@observer
export class DriversOpenTicketListPopupComponent extends Component<DriversOpenTicketListPopupProps> {

    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        };
        dataStore.setModuleName('AssignedTickets');
        window.addEventListener('keydown',(event) => {
            if(event.code == 'Escape'){
                dataStore.setModuleName('UserApproval')
            }
        })
    }

    render(): React.ReactNode {
        const {
            dataSource,
            onClose
        } = this.props;
        const statusBar = {
            statusPanels:  [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
                {
                  statusPanel: 'agTotalRowCountComponent',
                  align: 'center',
                },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
              ],
        };
        
        const options = { ...this.getGridConfig(),
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true, statusBar:statusBar };
        return (
            <div className="modal" id='tickets-modal'>
           <div id="tickets-modal-content" style={{backgroundColor: '#ffffff', width: '300px', height: '30vh'}}>
                    <Row>
                    <div style={{paddingLeft:'20px', paddingTop:'5px'}} className="justify-content-left mt-10">
                        <CustomButton
                            type={CustomButtonType.Submit}
                            disabled={this.isClearAllDisabled()}
                            onClick={this.handleClear}
                        >
                            Clear Filters
                        </CustomButton>
                        </div>
                    </Row> <Row>
                        <Col>
                            <div style={{paddingLeft:'10px',paddingRight:'10px'}} className="ag-theme-balham open-tickets-data-grid">
                                <AgGridReact stopEditingWhenGridLosesFocus={true} {...options} />
                            </div>
                        </Col>
                    </Row>
                        <div className="justify-content-center confirm__primary-text mt-10">
                            Please verify all the tickets assigned to the driver before proceeding.
                        </div>
                        <div className="justify-content-center confirm__action mt-10">
                            <Button className="mt-3 mb-3" onClick={()=>{
                                dataStore.setModuleName('UserApproval')
                                onClose()
                            }}>
                                OK
                            </Button>
                        </div>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('Driver_Open_Tickets_Detail_Filters');
        this.props.userApprovalDataStore.agGridService.setAllFilters(filters);
    }

    handleClear = () => {
        localStorageService.set('AssignedTickets_filter', {})
        ticketsGridService.clearAllFilters();
    }

    isClearAllDisabled = (): boolean => {
        if (this.props.dataSource.length == 0) {
            return true;
        } else {
            return false;
        }
    };
    getGridConfig(): GridOptions {
        const { dataSource, onCellValueChanged, onFilterChanged } = this.props;
        const gridOptions: GridOptions = {
            columnDefs: DriversOpenTicketListColDef,
            rowData: dataSource,
            onColumnMoved: this.onColumnMoved,
            onColumnPinned: this.onColumnPinned,
            onColumnResized: this.onColumnResized,
            onColumnVisible: this.onColumnVisible,
            onGridReady: this.handleGridReady,
            onCellValueChanged,
            onFilterChanged,
        };
        const config = ticketsGridService.getTicketsGridOptions();
        return { ...gridOptions, ...config };
    }
    
    onColumnMoved = (event: ColumnMovedEvent): void => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        localStorageService.set('AssignedTickets' + '_' + userId, columnState)
    }

    onColumnPinned = (event: ColumnPinnedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        localStorageService.set('AssignedTickets' + '_' + userId, columnState)
    }

    onColumnResized = (event: ColumnResizedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        localStorageService.set('AssignedTickets' + '_' + userId, columnState)
    }
    
    onColumnVisible = (event: ColumnVisibleEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        localStorageService.set('AssignedTickets' + '_' + userId, columnState)
    }

    handleGridReady = (event: GridReadyEvent) => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState =  localStorageService.get('AssignedTickets' + '_' + userId);
        try {
            if (columnState) {
                let columnArrangement = columnState as ColumnState[];
                columnApi.setColumnState(columnArrangement);
            }
        }
        catch (e) {
            localStorageService.remove('AssignedTickets' + '_' + userId);
            console.log('error in getting Column arrangement for module', e);
        }
        ticketsGridService.setColumnApi(columnApi);
        ticketsGridService.setGridApi(api);
    };
}

export const DriversOpenTicketListColDef: ColDef[] = [
    {
        headerName: 'Ticket Number',
        field: 'TicketNumber',
        width: 120,
        valueGetter: params => {
            if (params.data['TicketNumber'] === null) {
                return '';
            } else {
                return params.data['TicketNumber'];
            }
        }
    },
    {
        headerName: 'Status',
        field: 'Status',
        width: 85,
        valueGetter: params => {
            if (params.data['Status'] === null) {
                return '';
            } else if (params.data['Status'] === 'Assigned') {
                return 'Dispatched';
            } else {
                return params.data['Status'];
            }
        }
    }
];
