import { observable, action, computed, toJS } from 'mobx';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import Catch from '../../../shared/decorators/catch-decorator';
import { errorHandler } from 'shared/handlers/error-handler';
import { InspectionHistoryResponse, StatusType } from '../model/inspection-history-model';
import Loader from '../../../shared/decorators/loader-decorator';
import { InspectionHistoryMessage, InspectionHistoryFieldName } from '../model/inspection-history-enum';
import { InspectionTicketHistoryDataService } from '../services/inspection-ticket-history-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { inspectionHistoryColDef } from '../domains/inspection-ticket-history-col-def';
import { dataStore } from 'features/common/stores';
import { DateRange } from '../../../shared/components/daypicketInput/react-daypickerInput';
import _ from 'lodash';
import moment from 'moment';

export class InspectionTicketHistoryDataStore {
    @observable _inspectionHistoryInfo: InspectionHistoryResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    statusTypes: StatusType[] = [];
    backupInspectionHistoryList: InspectionHistoryResponse[] = [];
    startDate: any;
    endDate: any;
    isApproverPopUpVisible = false;
    isRefreshed: boolean = false;
    approvedCommentTicketModel: any;
    approvedColumn: any;

    constructor(
        private _service: InspectionTicketHistoryDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @computed
    get inspectionHistoryList(): InspectionHistoryResponse[] {
        return toJS(this._inspectionHistoryInfo);
    }
    getColDef() {
        return inspectionHistoryColDef;
    }
    updateMaintanenceCommentColDef = () => {
        const commentColDef = inspectionHistoryColDef.find(x => x.colId === InspectionHistoryFieldName.COMMENT);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateInspectionHistoryComment(item, value, InspectionHistoryFieldName.COMMENT),
                isDisabled: (item: InspectionHistoryResponse, value) =>
                    !dataStore.checkOperationAccessWithModule('MaintenanceReviewEdit', 'InspectionTicketHistory')
            };
        }
    };

    updateComplianceCommentColDef = () => {
        const commentColDef = inspectionHistoryColDef.find(x => x.colId === InspectionHistoryFieldName.COMMENT);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateInspectionHistoryComment(item, value, InspectionHistoryFieldName.COMMENT),
                isDisabled: (item: InspectionHistoryResponse, value) =>
                    !dataStore.checkOperationAccessWithModule('ComplianceReviewEdit', 'InspectionTicketHistory')
            };
        }
    };

    @action
    reset(): void {
        this.setinspectionHistoryList([]);
    }

    @action
    setinspectionHistoryList(tabletInfo: InspectionHistoryResponse[]): void {
        this._inspectionHistoryInfo = tabletInfo;
    }

    @action
    setInspectionHistoryList(inspectionHistoryInfo: InspectionHistoryResponse[]): void {
        this._inspectionHistoryInfo = inspectionHistoryInfo;
    }
    @action
    resetInspectionList(): void {
        this.setInspectionHistoryList(this.backupInspectionHistoryList);
        this.cellValueChangeMap = {};
    }

    @action
    updateInspectionHistoryComment(item: InspectionHistoryResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        if (popup && item[key] != null) item[key] = item[key] + '\n' + value;
        else item[key] = value;
        this.updateRow(item);
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    updateTicketHistoryRequest(updatedRows: InspectionHistoryResponse[]): InspectionHistoryResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.ModifiedByUser = this.userName;
            if (
                dataStore.checkOperationAccess('MaintenanceReviewEdit') &&
                (this.cellValueChangeMap[item.ID]['MaintanenceReviewComments'] ||
                    this.cellValueChangeMap[item.ID]['MaintenanceReviewStatus'])
            ) {
                item.MaintenanceReviewedBy = this.userID;
                item.MaintenanceReviewedDate = moment()
                    .toDate()
                    .toISOString();
            }
            if (
                dataStore.checkOperationAccess('ComplianceReviewEdit') &&
                (this.cellValueChangeMap[item.ID]['ComplianceReviewComments'] ||
                    this.cellValueChangeMap[item.ID]['ComplianceReviewStatus'])
            ) {
                item.ComplianceReviewedBy = this.userID;
                item.ComplianceReviewedDate = moment()
                    .toDate()
                    .toISOString();
            }
        });

        return updatedRows;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    @Loader
    @Catch(() => errorHandler(InspectionHistoryMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {

        if(!this.isRefreshed) this._inspectionHistoryInfo = [];
        const { inspectionData, statusTypes } = await this._service.getInspectionViewModel();
        const _inspectionData: InspectionHistoryResponse[] = inspectionData['Data'].filter(a => !a.IsDeleted);
        this.statusTypes = statusTypes['Data'];
        this.setinspectionHistoryList(_inspectionData);
        this.setBackUpInspectionHistoryList(_inspectionData);
        this.addValuesInCellDropdowns();
        this.startDate = '';
        this.endDate = '';
        this.isRefreshed = false;
    }

    isDisabled = (): boolean => {
        if (this._inspectionHistoryInfo.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    @action
    showTicketApproverPopUp() {
        this.isApproverPopUpVisible = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.isApproverPopUpVisible = false;
    }

    @Loader
    @action
    @Catch(() => errorHandler(InspectionHistoryMessage.FAILED_SUBMIT))
    async updateInspectionHistory(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this._inspectionHistoryInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        if (!this.userID || this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateTicketHistoryRequest(updatedRowIDs);
        let errorInSave = false;
        const response = await this._service.updateInspectionHistory(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key]) {
                    const objIndex = this._inspectionHistoryInfo.findIndex(a => a.ID === parseInt(key));
                    this._inspectionHistoryInfo[objIndex].StatusID = response[key].StatusID;
                    this._inspectionHistoryInfo[objIndex].ComplianceReviewStatusID =
                        response[key].ComplianceReviewStatusID;
                    this._inspectionHistoryInfo[objIndex].ModifiedByUser = this.accountStore.displayName;
                    this._inspectionHistoryInfo[objIndex].LastSavedDate = response[key].ModifiedDate;
                    this._inspectionHistoryInfo[objIndex].ComplianceReviewedByUser =
                        response[key].ComplianceReviewedByUser;
                    this._inspectionHistoryInfo[objIndex].MaintenanceReviewComments =
                        response[key].MaintenanceReviewComments;
                    this._inspectionHistoryInfo[objIndex].MaintenanceReviewedDate =
                        response[key].MaintenanceReviewedDate;
                    this._inspectionHistoryInfo[objIndex].MaintenanceReviewedByUser =
                        response[key].MaintenanceReviewedByUser;
                    this._inspectionHistoryInfo[objIndex].ComplianceReviewedDate = response[key].ComplianceReviewedDate;
                    this._inspectionHistoryInfo[objIndex].ComplianceReviewComments =
                        response[key].ComplianceReviewComments;

                    for (let i = 0; i < this.backupInspectionHistoryList.length; i++) {
                        const backupItem = this.backupInspectionHistoryList[i];
                        if (backupItem.ID === parseInt(key)) {
                            this.mapEditableColumns(backupItem, this._inspectionHistoryInfo[objIndex]);
                            break;
                        }
                    }
                } else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: response[key] }];
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this._inspectionHistoryInfo.length; i++) {
                    const updatedtickethistory = this._inspectionHistoryInfo[i];
                    if (updatedtickethistory.ID === parseInt(errorMessage.id)) {
                        const backupTicketInspectionIndex = this.backupInspectionHistoryList.findIndex(
                            a => a.ID === parseInt(errorMessage.id)
                        );
                        this.mapEditableColumns(
                            updatedtickethistory,
                            this.backupInspectionHistoryList[backupTicketInspectionIndex]
                        );
                        break;
                    }
                }
            });
        } else {
            this.uiService.toastService.success(InspectionHistoryMessage.SAVE);
        }
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: InspectionHistoryResponse): void => {
        this._inspectionHistoryInfo.forEach(inspection => {
            if (inspection.ID === selectedRowData.ID) {
                if (!_.isEqual(inspection, selectedRowData)) {
                    if (
                        inspection.MaintenanceReviewStatus !== selectedRowData.MaintenanceReviewStatus &&
                        selectedRowData.MaintenanceReviewStatus == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'MaintenanceReviewComments');
                    }
                    if (
                        inspection.ComplianceReviewStatus != selectedRowData.ComplianceReviewStatus &&
                        selectedRowData.ComplianceReviewStatus == 'Reviewed-Issue'
                    ) {
                        this.triggerModal(selectedRowData, 'ComplianceReviewComments');
                    }
                    this.updateIDFieldBasedOnName(selectedRowData);
                    this.mapEditableColumns(inspection, selectedRowData);
                }
            }
        });
    };

    private updateIDFieldBasedOnName(ticketInspection: InspectionHistoryResponse) {
        ticketInspection.StatusID = this.statusTypes.find(a => a.Name == ticketInspection.MaintenanceReviewStatus)?.ID;
        ticketInspection.MaintenanceReviewStatus = this.statusTypes.find(
            a => a.Name == ticketInspection.MaintenanceReviewStatus
        )?.Name;
        ticketInspection.ComplianceReviewStatusID = this.statusTypes.find(
            a => a.Name == ticketInspection.ComplianceReviewStatus
        )?.ID;
        ticketInspection.ComplianceReviewStatus = this.statusTypes.find(
            a => a.Name == ticketInspection.ComplianceReviewStatus
        )?.Name;
    }

    mapEditableColumns(currentItem: InspectionHistoryResponse, updatedItem: InspectionHistoryResponse) {
        if (currentItem) {
            currentItem.StatusID = updatedItem.StatusID;
            currentItem.MaintenanceReviewStatus = updatedItem.MaintenanceReviewStatus;
            currentItem.ComplianceReviewStatusID = updatedItem.ComplianceReviewStatusID;
            currentItem.ComplianceReviewStatus = updatedItem.ComplianceReviewStatus;
            currentItem.ComplianceReviewComments = updatedItem.ComplianceReviewComments;
            currentItem.MaintenanceReviewComments = updatedItem.MaintenanceReviewComments;
        }
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }
    triggerModal(item, col) {
        this.approvedCommentTicketModel = item;
        this.approvedColumn = col;
        this.showTicketApproverPopUp();
    }

    updateComment(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateInspectionHistoryComment(
            this.approvedCommentTicketModel,
            approverComment,
            this.approvedColumn,
            true
        );
    }

    setBackUpInspectionHistoryList(inspection: InspectionHistoryResponse[]) {
        this.backupInspectionHistoryList = inspection;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(
            this.statusTypes,
            InspectionHistoryFieldName.MAINTENANCEREVIEW,
            'Name'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.statusTypes,
            InspectionHistoryFieldName.COMPLIANCEREVIEW,
            'Name'
        );
        this.accessCheck(InspectionHistoryFieldName.MAINTENANCEREVIEW, 'MaintenanceReviewEdit');
        this.accessCheck(InspectionHistoryFieldName.COMPLIANCEREVIEW, 'ComplianceReviewEdit');
    }

    accessCheck(columnKey: string, propertyName: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);
        this.agGridService.updateEditableProperty(columnKey, access);
        return access;
    }

    @Loader
    @Catch(() => {
        errorHandler(InspectionHistoryMessage.FETCH_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        if(!this.isRefreshed) this._inspectionHistoryInfo = [];
        const { inspectionData, statusTypes } = await this._service.getInspectionViewModelWithDateParams(reqbody);
        const _inspectionData: InspectionHistoryResponse[] = inspectionData['Data'].filter(a => !a.IsDeleted);
        this.statusTypes = statusTypes['Data'];
        this.setinspectionHistoryList(_inspectionData);
        this.setBackUpInspectionHistoryList(_inspectionData);
        this.addValuesInCellDropdowns();
        if (_inspectionData.length == 0) {
            errorHandler(InspectionHistoryMessage.NO_TICKETS);
        }
        this.isRefreshed = false;
    }

    saveDateRange(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
