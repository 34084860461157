import React from 'react';
import './_list-select.scss';
import PropTypes from 'prop-types';
import MultiSelect from "@kenshooui/react-multi-select";
import { mappingDataStore } from '../store';


class ListSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {},
            notMapped: [],
            mapped: [],
            dictMapped: [],
            dictNotMapped: [],
            toMenu: [],
            filterTextMapped: '',
            filterTextNotMapped: '',
            selectedItems: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.state.dictNotMapped = props.notMapped;
        this.state.dictMapped = props.mapped;
    }
   
    handleChange(selectedItems) {
        this.setState({ selectedItems });
      }
    static propTypes = {
        notMapped: PropTypes.array.isRequired,
        mapped: PropTypes.array.isRequired,
        id: PropTypes.string.isRequired,
        btnAdd: PropTypes.string.isRequired,
        btnRemove: PropTypes.string.isRequired,
        headerHeading: PropTypes.string.isRequired,
        filterBy: PropTypes.string.isRequired,
        filterMapped: PropTypes.string.isRequired,
        handleItems: PropTypes.func.isRequired,
        setIsUpdated: PropTypes.func,
    };

    componentWillUnmount() {
        this.setState({ notMapped: [] });
        this.setState({ mapped: [] });
    }

    componentDidUpdate(newProps) {
        const { notMapped, mapped } = this.props;
        const {dictMapped, dictNotMapped} = this.state;
        if (notMapped !== newProps.notMapped) {
            this.setState({ dictNotMapped: newProps.notMapped });
        }
        if (mapped !== newProps.mapped) {
            this.setState({ dictMapped: newProps.mapped });
        }
    }

    filterNotMapped = e => {
        this.setState({ filterTextNotMapped: e.target.value });
    };

    filterMapped = e => {
        this.setState({ filterTextMapped: e.target.value });
    };

    addToMappedItem = () => {
        const selectedItemsConst = [];
        const { dictMapped, menu, dictNotMapped, unmappedSelectedItems, selectedItems } = this.state;
        const { handleItems, setIsUpdated } = this.props;
        if (selectedItems.length > 0) {
            for (let i = 0, l = selectedItems.length; i < l; i++) {
                selectedItemsConst.push(selectedItems[i].id);
                const notMappedItem = dictNotMapped.find(item => item.ID === selectedItems[i].id);
                const index = dictNotMapped.findIndex(item => item.ID === selectedItems[i].id);
                if (notMappedItem) {
                    dictMapped.push(notMappedItem);
                    dictNotMapped.splice(index, 1);
                    this.setState({ dictNotMapped: dictNotMapped });
                    this.setState({ dictMapped: dictMapped });
                    this.setState({selectedItems : []})
                    this.setState({filterTextNotMapped :''})
                }
                if(setIsUpdated){
                    setIsUpdated(true);
                }
            }
        mappingDataStore.isMappingEdited = true;
        }
        handleItems(dictMapped);
    };

    removeItemFromMappedItem = () => {
        const { dictMapped, toMenu, dictNotMapped, mappedSelectedItems, selectedItems} = this.state;
        const { handleItems, setIsUpdated } = this.props;
            if (selectedItems.length > 0) {
                for (let i = 0, l = selectedItems.length; i < l; i++) {
                const x = selectedItems[i].id;
                const notMappedItem = dictMapped.find(item => item.ID === x);
                const index = dictMapped.findIndex(item => item.ID === x);
                if (notMappedItem) {
                    dictNotMapped.push(notMappedItem);
                    dictMapped.splice(index, 1);
                }
                if(setIsUpdated){
                    setIsUpdated(true);
                }
            }
            mappingDataStore.isMappingEdited = true;
        }
        this.setState({ dictNotMapped: dictNotMapped });
        this.setState({ dictMapped: dictMapped });
        this.setState({selectedItems : []})
        this.setState({filterTextMapped :''})
        handleItems(dictMapped);
    };

    searchRendererForNotMapped = () => {
        const filter = this.props.filterBy;
        return (
            <div className="searchMultiple">
                <input
                    id={filter}
                    value={this.state.filterTextNotMapped}
                    type="text"
                    autoComplete="off"
                    onChange={this.filterNotMapped}
                    placeholder="Search"
                    className={`searchMultiple ${filter}`}
                ></input>
            </div>
        );
    }
    searchRendererForMapped = () => {
        const filterMapped = this.props.filterMapped;
        return (
            <div className="searchMultiple">
                <input
                    id={filterMapped}
                    value={this.state.filterTextMapped}
                    type="text"
                    autoComplete="off"
                    placeholder="Search "
                    className={`searchMultiple ${filterMapped}`}
                    onChange={this.filterMapped}
                ></input>
            </div>
        );
    }
    sortDrivers = (listOfDrivers) => {
        return listOfDrivers.sort((a,b) => {
            a = a.label.trim().replace("  "," ").split(" ");
            b = b.label.trim().replace("  "," ").split(" ");
            const a_length = a.length - 1;
            const b_length = b.length - 1;

            if(a[a_length].toLowerCase() < b[b_length].toLowerCase()) return -1;
            else if(a[a_length].toLowerCase() > b[b_length].toLowerCase()) return 1;
            else return 0;
        });
    }
    render() {
        const filter = this.props.filterBy;
        const headerHeading = this.props.headerHeading;
        const filterMapped = this.props.filterMapped;
        const { unmappedSelectedItems, selectedItems, mappedSelectedItems } = this.state;
        const { dictMapped, dictNotMapped, filterTextNotMapped, filterTextMapped } = this.state;
        let listNotMapped = [];
        let listMapped = [];
        if (dictNotMapped !== undefined) {
            listNotMapped =
                filterTextNotMapped !== ''
                    ? dictNotMapped.filter(x => x.Text !== null && x.Text.toLowerCase().includes(filterTextNotMapped.toLowerCase()))
                    : dictNotMapped;
        if(filter === 'driverFilter'){
            this.sortDrivers(listNotMapped)
        } }
        if (dictMapped !== undefined) {
            listMapped =
                filterTextMapped !== ''
                    ? dictMapped.filter(x => x.Text !== null && x.Text.toLowerCase().includes(filterTextMapped.toLowerCase()))
                    : dictMapped;
        if(filter === 'driverFilter'){
            this.sortDrivers(listMapped)
        } }
        return (
            <div className="searchbox">
                <div className="divinner">
                    <div className="searchbox">
                        {headerHeading ==='DRIVER' ?
                            (<label className="selectbox"> ACTIVE AND APPROVED DRIVERS </label>) : 
                            (<label className="selectbox"> UNMAPPED {headerHeading} </label>)
                        }
                        <MultiSelect
                            items={listNotMapped}
                            wrapperClassName={'SelMapped selDefaultSize'}
                            id={this.props.id}
                            showSelectedItems={false}
                            searchRenderer={this.searchRendererForNotMapped}
                            showSelectAll={false}
                            selectedItems={selectedItems}
                            onChange={this.handleChange}
                            ></MultiSelect>
                    </div>
                    <div className="Margindiv">
                        <div align="center">
                            <label className="lbl"> Map {headerHeading} </label>
                        </div>

                        <button
                            type="button"
                            onClick={this.addToMappedItem}
                            className={`btn btn-primaryrounded ${this.props.btnAdd}`}
                        >
                            <i className="glyphicon glyphicon-chevron-right"></i> Map{' '}
                        </button>
                        <button
                            type="button"
                            onClick={this.removeItemFromMappedItem}
                            className={`btn btn-primaryrounded ${this.props.btnRemove}`}
                        >
                            {' '}
                            Unmap<i className="glyphicon glyphicon-chevron-left"></i>{' '}
                        </button>
                    </div>
                    <div className="searchbox">
                        <label className="selectbox"> MAPPED {headerHeading}</label>
                            <MultiSelect
                            items={listMapped}
                            wrapperClassName={'SelMapped selDefaultSize'}
                            id={this.props.id}
                            showSelectedItems={false}
                            searchRenderer={this.searchRendererForMapped}
                            showSelectAll={false}
                            selectedItems={selectedItems}
                            onChange={this.handleChange}
                            ></MultiSelect>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListSelect;
