import React, { Component } from 'react';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from 'features/common/stores';
import { HazardAssessmentTicketHistoryDataStore } from '../stores/hazardAssessmentTicketHistoryDataStore';
import { HazardAssessmentHistoryFieldName, HazardAssessmentHistorySaveLabel } from '../model/hazardAssessment-history-enum';
import { RESET } from 'features/data_management/data_constant';
import { hazardAssessmentHistoryStore } from '../stores';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { transact } from '../model/statetranistions';
import { TicketApproverCommentPopup } from './ticket-approver-comment-popup';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';

interface HazardAssessmentTicketHistoryMappingProps {
    _dataStore: HazardAssessmentTicketHistoryDataStore;
}

@observer
export class HazardAssessmentTicketHistoryComponent extends Component<HazardAssessmentTicketHistoryMappingProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('HazardAssessmentHistory');
    }

    componentDidMount() {
        this.props._dataStore.init();
    }

    componentWillUnmount() {
        this.props._dataStore.reset();
    }
    handleApproverCommentVisibility = () => {
        this.props._dataStore.hideTicketApproverPopUp();
    };

    handleApproverCommentSubmission = (comment: string) => {
        this.props._dataStore.updateComment(comment);
    };

    render(): React.ReactNode {
        const {
            _dataStore: { isSaveDisabled, isDisabled, isApproverPopUpVisible }
        } = this.props;

        return (
            <Container fluid>
                <>
                    <TicketApproverCommentPopup
                        onApproverCommentSubmission={this.handleApproverCommentSubmission}
                        onApproverCommentVisibility={this.handleApproverCommentVisibility}
                        onApproverCommentCancel={this.handleApproverCommentVisibility}
                        showPopup={isApproverPopUpVisible}
                    />
                    <Row>
                        <Col>
                            <div className="master-data__action">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        {dataStore.checkOperationAccess('Save') && (
                                            <>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleSave}
                                                    disabled={isSaveDisabled()}
                                                >
                                                    {HazardAssessmentHistorySaveLabel.SAVE}
                                                </CustomButton>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleReset}
                                                    disabled={isSaveDisabled()}
                                                >
                                                    {RESET}
                                                </CustomButton>
                                            </>
                                        )}
                                        <ClearButtonComponent
                                            handleRefresh={this.handleRefresh}
                                            isExportCSV={true}
                                            disabled={isDisabled()}
                                        ></ClearButtonComponent>
                                    </Col>
                                </Row>
                            </div>
                            <ReactDayPickerInput _dataStore={this.props._dataStore} showFirstDay={true}></ReactDayPickerInput>
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>
                </>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('HazardAssessmentHistory_filter');
            this.props._dataStore.agGridService.setAllFilters(filters);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            _dataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            hazardAssessmentHistoryStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            _dataStore: { cellValueChangeMap,uiService, updateRow }
        } = this.props;
        const initValue = cellValueChangeMap[row][col]['initValue'];
        if (col === HazardAssessmentHistoryFieldName.OPERATIONSREVIEW && !transact(initValue, value) ||
              col === HazardAssessmentHistoryFieldName.COMPLIANCEREVIEW && !transact(initValue, value)
            ) {
            cellValueChangeMap[row][col]['currentValue'] = initValue;
            event.value = initValue;
            event.data[col] = initValue;
            uiService.toastService.error(`Status cannot be changed from ${initValue} to ${value}`);
            return;
        }
        else {
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
    }
    };

    private handleSave = (): void => {
        this.props._dataStore.updateHazardAssessmentHistory();
    };

    private handleReset = (): void => {
        this.props._dataStore.resetHazardAssessmentList();
    };

    handleRefresh = () => {
        const { _dataStore } = this.props;
        if(Object.keys(_dataStore.cellValueChangeMap).length === 0) {
            if(_dataStore.startDate && _dataStore.endDate){
            _dataStore.getRangeResult(_dataStore.startDate,_dataStore.endDate);
            _dataStore.cellValueChangeMap = {}
        }
            else {
            _dataStore.init();
            _dataStore.cellValueChangeMap = {}
            }
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    if(_dataStore.startDate && _dataStore.endDate){
                        _dataStore.getRangeResult(_dataStore.startDate,_dataStore.endDate);
                        _dataStore.cellValueChangeMap = {}
                    }
                        else {
                        _dataStore.init();
                        _dataStore.cellValueChangeMap = {}
                        }
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    private getGridConfig(): GridOptions {
        const {
            _dataStore: { hazardAssessmentHistoryList: hazardAssessmentHistoryList, agGridService, getColDef }
        } = this.props;

        const rowData = hazardAssessmentHistoryList;
        const columnDefs = getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const hazardAssessmentHistoryMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped
        };

        return agGridService.getGridConfig(hazardAssessmentHistoryMaintenanceGridParams);
    }
}
