import React, { Component } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import './_date-cell-renderer.scss';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';

interface DateCellRendererState {
    value: string;
}

interface DateCellRendererParams extends ICellRendererParams {
    onChange: (data, value) => void;
    isDisabled: (item, value) => boolean;
}

export class DateCellRenderer extends Component<DateCellRendererParams, DateCellRendererState> {
    constructor(props: DateCellRendererParams) {
        super(props);
        this.state = {
            value: props.value || ''
        };
    }

    static defaultProps = { isDisabled: (item, value) => false };

    handleChange = (event): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ value });
    };

    handleBlur = () => {
        const { data, onChange } = this.props;
        onChange(data, this.state.value);
    };

    render(): React.ReactNode {
        const { value } = this.state;
        const minDate =  moment.utc(new Date()).format('YYYY-MM-DD');

        const { isDisabled, data } = this.props;
        const updatedDateFormat = dateUtils.updateFormatDate(value);
        const readonly = isDisabled(data, updatedDateFormat);
        if (readonly) {
            return <div>{updatedDateFormat}</div>;
        }
        return (
            <div>
                <div className="date-cell-renderer">
                    <input
                        disabled={isDisabled(data, updatedDateFormat)}
                        value={updatedDateFormat}
                        type="date"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        min={minDate}
                    />
                </div>
            </div>
        );
    }
}
