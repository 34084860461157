import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { CompanyDataService } from '../services/company-data-service';
import { CompanyResponse } from '../domains/company-model'
import { AgGridService } from 'features/common/services/ag-grid-service';
import { companyColDef } from '../domains/company-col-def';
import { CompanyFieldName, CompanyMessage } from '../domains/company-enum';
import _ from 'lodash';
import { dataStore } from 'features/common/stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { CompanyDropDownPopUp, ConfirmProps } from '../components/company-dropdown-popup';


export class CompanyDataStore {
    @observable companyInfo: CompanyResponse[] = [];
    backupCompanyList: CompanyResponse[] = [];
    @observable cellValueChangeMap = {};
    trackerAndVinValuesMap: any[] = [];
    @observable raVendorData: any[] = [];
    userID = 0;
    userName = '';
    constructor(
        private companyDataService: CompanyDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) { }

    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return companyColDef;
    }

    @Loader
    @Catch(() => errorHandler(CompanyMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const {
            companyData,
            raVendorData
        } = await this.companyDataService.getCompanyViewModel();
        this.raVendorData = raVendorData?raVendorData['Data']: [];
        // this.raVendorData.unshift({
        //     'ID': 0,
        //     'AccountNumber': '',
        //     'CompanyName': ''
        // })
        const carrierCompanyData: CompanyResponse[] = companyData['Data'].filter(a => !a.IsDeleted);
        this.setCompanyList(carrierCompanyData);
        this.setBackupCompanyData(carrierCompanyData);
    }

    @computed
    get companyList(): CompanyResponse[] {
        return toJS(this.companyInfo);
    }

    @action
    setCompanyList(companyInfo: CompanyResponse[]): void {
        this.companyInfo = companyInfo;
    }

    setBackupCompanyData(backUpList: CompanyResponse[]) {
        this.backupCompanyList = backUpList;
    }

    @action
    updateRow = (selectedRowData: CompanyResponse): void => {
        const updatedIndex = this.companyInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.companyInfo[updatedIndex], selectedRowData)) {
            this.mapEditableColumns(this.companyInfo[updatedIndex], selectedRowData);
        }
    };

    mapEditableColumns(currentItem: CompanyResponse, updatedItem: CompanyResponse) {
        if (currentItem) {
            currentItem.RAAccountNumber = updatedItem.RAAccountNumber;
            currentItem.RACompanyName = updatedItem.RACompanyName;
        }
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(this.raVendorData, CompanyFieldName.RAVENDORNAME, 'CompanyName');
         this.agGridService.updateOptionCellEditorValues(this.raVendorData, CompanyFieldName.RAVENDORNUMBER, 'AccountNumber');
        this.accessCheck(CompanyFieldName.RAVENDORNAME, 'Save');
    }
    
    accessCheck(columnKey: string, propertyName?: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);

        this.agGridService.updateEditableProperty(columnKey, access == undefined ? false : access);
        return access;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    updateRequest(updatedRows: CompanyResponse[]): CompanyResponse[] {
        const updatedFields: any = [];
        updatedRows.map(item => (updatedFields.push({
            CCMasterID: item.ID,
            ModifiedBy: this.userID,
            RACompanyName: item.RACompanyName,
            RAAccountNumber: item.RAAccountNumber
        })));
        return updatedFields;
    }

    @action
    getRAVendorDetails(eventData, colId) {
        const confirmService = new ConfirmService();
        let vendorData: any = [];
        if(colId === 'RAAccountNumber')
            vendorData = Array.from(new Set(this.raVendorData.map((item: any) => item.AccountNumber)));
        else vendorData = Array.from(new Set(this.raVendorData.map((item: any) => item.CompanyName)));
        confirmService.showConfirmWithCustomComponent(
            (vendor) => {
                this.setValueInChangeMap(eventData.ID, colId, eventData[colId] ? eventData[colId]: '', vendor);
                this.cellValueChangeMap[eventData.ID][colId]['currentvalue'] = vendor;
                
                const selectedRAVendor: any = this.raVendorData.filter(item => {
                    if(colId === 'RAAccountNumber')
                        return item.AccountNumber === vendor
                    else return item.CompanyName === vendor
                })
                this.companyInfo.forEach(element => {
                    if(element.ID === eventData.ID) {
                        element.RAAccountNumber = selectedRAVendor[0].AccountNumber
                        element.RACompanyName = selectedRAVendor[0].CompanyName
                    }
                })
            },
            CompanyDropDownPopUp,
            {
                vendorData: vendorData,
                msg: 'Select '+colId
            } as ConfirmProps,
            ''
        );  
    }
    
    @Loader
    @action
    async saveVendorData(): Promise<void> {
        const updatedRowIDs = this.companyInfo.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateRequest(updatedRowIDs);
        const response = await this.companyDataService.updateVendorData(requestBody);
        this.cellValueChangeMap = {};
    }

    @action
    reset(): void {
        this.cellValueChangeMap = {};
        this.setCompanyList(this.backupCompanyList);
    }
}
