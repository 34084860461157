import * as React from 'react';
import './_checkbox.scss';

export interface CheckboxProps {
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

export interface CheckboxState {
    value: boolean;
}

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
    readonly state: CheckboxState = {
        value: false
    };

    componentDidMount(): void {
        const { value } = this.props;
        this.setState({ value });
    }

    componentDidUpdate(prevProps: CheckboxProps): void {
        const { value = false } = this.props;
        const { value: prevValue } = prevProps;
        if (value !== undefined && prevValue !== undefined && value !== prevValue) {
            this.setState({ value });
        }
    }

    public render(): React.ReactNode {
        const { label, disabled = false } = this.props;
        return (
            <label className="checkbox-container">
                <span className="checkbox-container__label">{label}</span>
                <input
                    disabled={disabled}
                    onBlur={this.handleBlur}
                    type="checkbox"
                    name={label}
                    checked={this.state.value}
                    onChange={this.handleChange}
                />
                <span className="checkbox-container__checkmark"></span>
            </label>
        );
    }

    private handleBlur = (): void => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;
        const { target } = event;
        const { checked } = target;
        this.setState({ value: checked });
        onChange(checked);
    };
}
