import { TrailerDataStore } from './trailer-data-store';
import { trailerDataService } from '../services/trailer-data-service';
import { uiService } from '../../../shared/services/ui-service';
import { accountStore } from 'features/account/stores';
import { agGridService } from 'features/common/services/ag-grid-service';
import { trailerValidationService } from '../services/trailer-validation-service';

import { tabletDataService } from '../services/tablet-data-service';
import { tabletValidationService } from '../services/tablet-validation-service';
import { TabletDataStore } from './tablet-data-store';

import { PrinterDataStore } from './printer-data-store';
import { printerDataService } from '../services/printer-management/printer-data-service';
import { printerValidationService } from '../services/printer-management/printer-validation-service';

import { TractorDataStore } from './tractor-data-store';
import { tractorDataService } from '../services/tractor-data-service';
import { tractorValidationService } from '../services/tractor-validation-service';

import { ShopDataStore } from './shop-data-store';
import { shopDataService } from '../services/shop-data-service';
import { shopValidationService } from '../services/shop-validation-service';

import { CompanyDataStore } from './company-data-store';
import { companyDataService } from '../services/company-data-service';

import { DriverDataStore } from './driver-data-store';
import { driverDataService } from '../services/driver-management/driver-data-service';
import { driverValidationService } from '../services/driver-management/driver-validation-service';
import { locationDataService } from '../domains/location-management/location-data-service';
import { LocationDataStore } from './location-data-store';
import { locationMapingDataService } from '../domains/location-maping-management/location-map-data-service';
import { LocationMapingDataStore } from './location-maping-data-store';
import { locationValidationService } from '../services/location-management/location-validation-service';
import { KeyfobsDataStore } from './keyfobs-data-store';
import { keyfobsDataService } from '../services/keyfobs-data-service';

export const trailerStore = new TrailerDataStore(
    trailerDataService,
    agGridService,
    uiService,
    accountStore,
    trailerValidationService
);

export const printerStore = new PrinterDataStore(
    printerDataService,
    agGridService,
    uiService,
    accountStore,
    printerValidationService
);

export const tabletStore = new TabletDataStore(
    tabletDataService,
    agGridService,
    uiService,
    accountStore,
    tabletValidationService
);
export const tractorStore = new TractorDataStore(
    tractorDataService,
    agGridService,
    uiService,
    accountStore,
    tractorValidationService
);

export const shopStore = new ShopDataStore(
    shopDataService,
    shopValidationService,
    agGridService,
    accountStore,
    uiService
);
export const locationStore = new LocationDataStore(
    locationDataService,
    agGridService,
    uiService,
    accountStore,
    locationValidationService
);

export const locationMapingStore = new LocationMapingDataStore(
    locationMapingDataService,
    agGridService,
    uiService,
    accountStore,
);

export const keyFobstore = new KeyfobsDataStore(
    keyfobsDataService,
    agGridService,
    uiService,
    accountStore,
);

export const companyStore = new CompanyDataStore(companyDataService, agGridService, uiService, accountStore);
export const driverStore = new DriverDataStore(driverDataService, agGridService, uiService, accountStore, driverValidationService);
