import { FilterKeyForReferenceData } from '../domains/enum';
import React from 'react';
import { Config } from 'config';
import { DefaultPageComponent } from 'features/common/components/default-page-component';
import { TrailerMaintenanceComponent } from './trailer-maintenance-component';
import { trailerMaintenanceStore, inspectionHistoryStore } from '../stores';
import { InspectionTicketHistoryComponent } from './inspection-ticket-history-component';
export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        FilterKeyForReferenceData.Select,
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.TrailerBookingMaintenance,
        () => (
            <React.Fragment>
                <TrailerMaintenanceComponent trailerMaintenanceStore={trailerMaintenanceStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.InspectionTicketHistory,
        () => (
            <div>
                <InspectionTicketHistoryComponent _dataStore={inspectionHistoryStore} />
            </div>
        )
    ]
]);
