import React, { PureComponent } from 'react';
import { printerStore } from '../../stores';
import './_alpha-numeric-cell-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';

interface AlphaNumericCellRendererState {
    value: string;
}

export class AlphaNumericCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState> {
    constructor(props: ICellRendererParams) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    handleKeyDown = (event) => {
        var charCode = (event.which) ? event.which : event.KeyCode;
        //allow for letter,number,spacebar,comma,period,dash,backspace
        if (!((charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 91)
            || (charCode > 96 && charCode < 123) || charCode == 0 || (charCode > 187 && charCode < 191) || charCode == 32
            || charCode == 8 || charCode == 222)) {
            event.preventDefault();
            return;
        }
    }

    handleChange = (event): void => {
        this.setState({ value: event.target.value });
    };
    ///TODO need to refactor this to use generic component
    handleBlur = (): void => {
        printerStore.setAlphaNumeric(this.props.data, this.state.value, this.props.column.getColId());
    };

    render(): React.ReactNode {
        const { value } = this.state;
        const readonly = !dataStore.checkOperationAccess('Save');
        if (readonly) {
            return <div>{value}</div>;
        }
        return (
            <div className="alpha-numeric-cell">
                <input type="text" value={value}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                    readOnly={readonly} />
            </div>
        );
    }
}
