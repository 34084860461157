import { action, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { TabletDataService } from '../services/tablet-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { TabletResponse } from '../domains/tablet-modal-response';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { tabletColDef } from '../domains/tablet-col-def';
import { TabletMessage, TabletFieldName } from '../domains/tablet-enum';
import { TabletValidationService } from '../services/tablet-validation-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';

export class TabletDataStore {
    @observable tabletInfo: TabletResponse[] = [];
    backupTabletList: TabletResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    companyData: any = [];
    @observable dataToBeDeleted: TabletResponse[] = [];

    constructor(
        private tabletDataService: TabletDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private tabletValidationService: TabletValidationService
    ) {}

    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @action
    reset(): void {
        this.setTabletList([]);
    }

    @action
    resetTabletList(): void {
        this.setTabletList(this.backupTabletList);
        this.cellValueChangeMap = {};
    }

    @action
    setTabletList(tabletInfo: TabletResponse[]): void {
        this.tabletInfo = tabletInfo;
    }

    getColDef() {
        return tabletColDef;
    }

    setBackupTabletList(tablets: TabletResponse[]) {
        this.backupTabletList = tablets;
    }

    @Loader
    @Catch(() => errorHandler(TabletMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const tabletInfo: TabletResponse[] = await this.tabletDataService.getTabletData();
        const companyData: any  = await this.tabletDataService.getCarrierCompanyData();
        this.companyData = companyData.filter(a => !a.IsDeleted && a.RACompanyName != null);
        this.companyData.map(data => {
            data.CompanyName = data.RACompanyName;
            data.AccountNumber = data.RAAccountNumber;
        })
        
        this.setTabletList(tabletInfo);
        this.setBackupTabletList(tabletInfo);
        this.addValuesInCellDropdowns();
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };
    updateIMEI(item, value, key) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue == undefined ? '' : initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(this.companyData, TabletFieldName.CARRIERCOMPANY, 'CompanyName');
    }

    @action
    updateRow = (selectedRowData: TabletResponse): void => {
        const updatedTabletIndex = this.tabletInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.tabletInfo[updatedTabletIndex], selectedRowData)) {
            this.updateIDFieldBasedOnName(selectedRowData);
            this.mapEditableColumns(this.tabletInfo[updatedTabletIndex], selectedRowData);
        }
    };

    private updateIDFieldBasedOnName(tablet: TabletResponse) {
        tablet.CarrierCompanyID = this.companyData.find(a => a.CarrierCompany == tablet.CarrierCompany)?.ID;
    }

    private mapEditableColumns(currentItem: TabletResponse, updatedItem: TabletResponse) {
        currentItem.Manufacturer = updatedItem.Manufacturer;
        currentItem.TabletOS = updatedItem.TabletOS;
        currentItem.TabletIMEI = updatedItem.TabletIMEI;
        currentItem.ModifiedByUser = updatedItem.ModifiedByUser;
        currentItem.ModifiedBy = updatedItem.ModifiedBy;
        currentItem.AssignedTo = updatedItem.AssignedTo?.trim();
        currentItem.AndroidID = updatedItem.AndroidID;
        currentItem.CarrierCompany = updatedItem.CarrierCompany;
    }

    @Loader
    @action
    @Catch(() => errorHandler(TabletMessage.FAILED_SUBMIT))
    async updateTablet(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.tabletInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateTabletRequest(updatedRowIDs);
        const validationMessage = this.tabletValidationService.validateUpdateRequest(
            requestBody,
            this.backupTabletList
        );
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        const response = await this.tabletDataService.updateTablets(requestBody);
        if (!isEmpty(response)) {
            for (const key in response) {
                errorSummary = [...errorSummary, { id: key, description: response[key] }];
            }
            this.uiService.toastService.error('', {}, errorSummary);
        }
        this.cellValueChangeMap = {};
        this.loadViewModel();
        if (errorSummary.length == 0) this.uiService.toastService.success(TabletMessage.SAVE);
    }

    @action
    DeleteRows = (): void => {
        const updateData = this.tabletInfo.filter(x => !this.dataToBeDeleted.filter(y => y.ID === x.ID).length);
        this.deleteTablet(this.dataToBeDeleted, updateData);
    };

    @Loader
    @action
    @Catch(() => errorHandler(TabletMessage.FAILED_DELETE))
    async deleteTablet(dataToBeDeleted, updateData): Promise<void> {
        const Promises: any = [];
        const dataToBeDeletedLocal = dataToBeDeleted.filter(a => a.ID > 0);
        if (dataToBeDeletedLocal.length > 0) {
            this.tabletDataService.updateTablets(dataToBeDeletedLocal);
        }
        Promise.all(Promises).then(
            () => {
                this.setTabletList(updateData);
                this.setBackupTabletList(updateData);
                this.dataToBeDeleted = [];
                this.isDeleteEnabled();
                this.uiService.toastService.success(TabletMessage.TABLET_DELETE);
            },
            () => {
                this.uiService.toastService.error(TabletMessage.FAILED_DELETE);
            }
        );
    }

    isDeleteEnabled = (): boolean => {
        const isEnabled = this.dataToBeDeleted.length > 0 ? false : true;
        return isEnabled;
    };

    @action
    handleCheckboxClick(tablet: TabletResponse, value: any, colName: string): void {
        switch (colName) {
            case TabletFieldName.CHECKBOX:
                tablet.IsDeleted = !value;
                break;
            default:
                break;
        }
        if (tablet.IsDeleted) {
            this.dataToBeDeleted.push(tablet);
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== tablet.ID);
        }
    }

    updateTabletRequest(updatedRows: TabletResponse[]): TabletResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.ModifiedByUser = this.userName;
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    setAlphaNumeric(tablet: TabletResponse, value: string, colName: string): void {
        const row = tablet.ID;
        const initValue = tablet[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case TabletFieldName.MANUFACTURE:
                tablet.Manufacturer = value;
                break;
            case TabletFieldName.TABLETOS:
                tablet.TabletOS = value;
                break;
            case TabletFieldName.ASSIGNEDTO:
                tablet.AssignedTo = value;
                break;
            default:
                break;
        }
        this.updateRow(tablet);
    }

    isResetEnabled = (): boolean => {
        const updatedRows = this.tabletInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()) || a.ID < 1);
        return updatedRows.length === 0;
    };

    @action
    addRow(): void {
        const tabletrow: TabletResponse = {
            ID : 0,
            TabletIMEI: '',
            TabletOS: '',
            AndroidID: '',
            CarrierCompanyID: 0,
            UnplannedVolumeTicketCounter: 0,
            UnplannedHourlyTicketCounter: 0,
            TicketCounterYear: 0,
            CreatedBy: this.userID,
            Manufacturer: '',
            CreatedDate: new Date().toUTCString(),
            ModifiedByUser: '',
        };
        let minID: number = this.tabletInfo[0].ID;
        if (minID <= 0) {
            tabletrow.ID = minID - 1;
        }
        this.tabletInfo = [tabletrow, ...this.tabletInfo]
    };

}
