import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { PrinterResponse } from '../../domains/printer-management/printer-model';

export class PrinterDataService {
    constructor(private restApi: RestApiService) { }

    async getPrinterData(): Promise<PrinterResponse[]> {
        const { data } = await this.restApi.postWithToken<PrinterResponse[]>(Config.getPrinterData);
        return data['Data'];
    }

    async updatePrinter(reqbody: PrinterResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updatePrinter, reqbody, {});
        return data['Data'];
    }

    async deletePrinter(reqbody: any): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.deletePrinter, reqbody, {});
        return data['Data'];
    }
}

export const printerDataService = new PrinterDataService(restApiService);
