import { Option } from '../types/shared';
export class DataAdapterService {
    static getOptionValues<T>(
        o: T[],
        modelKey: Extract<keyof T, string>,
        modelValue: Extract<keyof T, string>,
        shouldIncludeSelectAll = false
    ): Option[] {
        const items = o.map(item => {
            const label = (item[modelKey] as unknown) as string;
            const value = (item[modelValue] as unknown) as string;
            return { label, value };
        });
        return shouldIncludeSelectAll ? [{ label: 'Select', value: 'Select' }, ...items] : items;
    }
}
