import React from 'react';
import { Modal } from 'react-bootstrap';
import { ApproverComment } from './approver-comment';

interface PastDueClearanceCommentPopup {
    onApproverCommentSubmission: (approverComment: string) => void;
    onApproverCommentVisibility: () => void;
    onApproverCommentCancel: () => void;
    showPopup: boolean;
    cancelOption?: boolean;
}

export const PastDueClearanceCommentPopup = (props: PastDueClearanceCommentPopup) => {
    const {
        onApproverCommentSubmission,
        onApproverCommentVisibility,
        onApproverCommentCancel,
        showPopup,
        cancelOption = true
    } = props;
    return (
        <Modal backdrop="static" show={showPopup} centered>
            <Modal.Header>
                <Modal.Title>Approver Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApproverComment
                    onApproverCommentVisibility={onApproverCommentVisibility}
                    onApproverCommentSubmission={onApproverCommentSubmission}
                    onApproverCommentCancel={onApproverCommentCancel}
                    cancelOption={cancelOption}
                />
            </Modal.Body>
        </Modal>
    );
};
