import React from 'react';
import './_register-container.scss';
import logo from '../../../assets/pmc-logo.png';
import { PSA_DOWNLOAD_INSTRUCTION, PTS_DOWNLOAD_INSTRUCTION } from '../account_constant';
import { CommonUtils } from 'shared/services/common-utils';

export const SignUpHeaderComponent = () => {
    const publicUrl = CommonUtils.getPublicUrl();
    console.log('publicUrl', publicUrl);
    return (
        <div>
            <div>
                <img className="register-container-logo" src={logo} alt="Logo" />
            </div>
            <div>{PSA_DOWNLOAD_INSTRUCTION} <a href={publicUrl + '/Sign-Up-Non-Plains-User.pdf'} download>clicking here</a></div>
            <div>{PTS_DOWNLOAD_INSTRUCTION} <a href={publicUrl + '/Sign-Up-Plains-User.pdf'} download>clicking here</a></div>
        </div>
    );
}
