import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { TractorDataService } from '../services/tractor-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { TractorResponse, DeleteRequest, ComplianceStatus, CarrierCompany } from '../domains/tractor-modal';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { tractorColDef } from '../domains/tractor-col-def';
import { TractorMessage, TractorFieldName } from '../domains/tractor-enum';
import { TractorValidationService } from '../services/tractor-validation-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from 'features/common/stores';

export class TractorDataStore {
    @observable tractorInfo: TractorResponse[] = [];
    backupTractorList: TractorResponse[] = [];
    @observable cellValueChangeMap = {};
    trackerAndVinValuesMap: any[] = [];
    @observable dataToBeDeleted: DeleteRequest[] = [];
    userID = 0;
    userName = '';
    userEmail = '';
    complianceStatus: ComplianceStatus[] = [];
    companyData: CarrierCompany[] = [];
    raConfig: any = [];
    constructor(
        private tractorDataService: TractorDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private tractorValidationService: TractorValidationService
    ) {}

    async init(): Promise<void> {
        this.raConfig = await this.tractorDataService.getRAConfig();
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userEmail = this.accountStore.userName ? this.accountStore.userName : '';
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return tractorColDef;
    }

    @Loader
    @Catch(() => errorHandler(TractorMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const { tractorData, tractorStatus, companyData } = await this.tractorDataService.getTractorViewModel();
        const tractors: TractorResponse[] = tractorData['Data'].filter(a => !a.IsDeleted);
        tractors.forEach(tractor => {
            if (tractor.ComplianceReviewStatusID == null) {
                tractor.ComplianceReviewStatus = 'Submitted';
                tractor.ComplianceReviewStatusID = 1;
            }
        });
        this.complianceStatus = tractorStatus['Data'];
        // this.companyData = companyData['Data'];
        const raFlag = this.raConfig.filter((item: any) => item.Code === 'RAIntegration');

        if (raFlag[0]?.Value === 'RA') {
            this.companyData = companyData['Data'].filter(a => !a.IsDeleted && a.RACompanyName != null);
            this.companyData.map(data => {
                data.CompanyName = data.RACompanyName;
                data.AccountNumber = data.RAAccountNumber;
            })
        } else {
            this.companyData = companyData['Data'].filter(a => !a.IsDeleted)
        }
        const tractorsLocal = this.addCheckBox(tractors);
        this.setTractorList(tractorsLocal);
        this.setbackupTractorList(tractorsLocal);
        this.addValuesInCellDropdowns();
    }
    addCheckBox(tractors) {
        tractors.map(item => {
            item.CheckBox = false;
        });
        return tractors;
    }
    setValueInChangeMap(row: number, col: string, initValue: string, newValue: any) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    setAlphaNumeric(tractor: TractorResponse, value: string, colName: string): void {
        const row = tractor.ID;
        const initValue = tractor[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case TractorFieldName.VIN:
                tractor.VIN = value;
                break;
            case TractorFieldName.PLATE:
                tractor.RegistrationNo = value;
                break;
            default:
                break;
        }
        this.updateRow(tractor);
    }
    @action
    handleCheckboxClick(tractor: TractorResponse, value: any, colName: string): void {
        const row = tractor.ID;
        const initValue = tractor[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case TractorFieldName.CHECKBOX:
                tractor.CheckBox = !value;
                break;
            default:
                break;
        }
        if (tractor.CheckBox) {
            this.dataToBeDeleted.push({ ID: tractor.ID });
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== tractor.ID);
        }
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(
            this.complianceStatus,
            TractorFieldName.COMPLIANCEREVIEW,
            'Name'
        );

        this.agGridService.updateOptionCellEditorValues(this.companyData, TractorFieldName.COMPANYNAME, 'CompanyName');

        this.accessCheck(TractorFieldName.COMPLIANCEREVIEW, 'ComplianceReviewEdit');
        this.accessCheck(TractorFieldName.COMPANYNAME, 'CarrierCompanyEdit');
    }

    accessCheck(columnKey: string, propertyName: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);
        this.agGridService.updateEditableProperty(columnKey, access);
        return access;
    }

    setbackupTractorList(tractor: TractorResponse[]) {
        this.backupTractorList = tractor;
    }

    @computed
    get tractorList(): TractorResponse[] {
        return toJS(this.tractorInfo);
    }

    @action
    setTractorList(tractorInfo: TractorResponse[]): void {
        this.tractorInfo = tractorInfo;
    }

    @action
    reset(): void {
        this.setTractorList([]);
    }

    @action
    resetTractorList(): void {
        this.setTractorList(this.backupTractorList);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: TractorResponse): void => {
        const updatedTractorIndex = this.tractorInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.tractorInfo[updatedTractorIndex], selectedRowData)) {
            selectedRowData.ComplianceReviewStatusID = this.complianceStatus.find(
                a => a.Name == selectedRowData.ComplianceReviewStatus
            )?.ID;
            this.mapEditableColumns(this.tractorInfo[updatedTractorIndex], selectedRowData);
        }
    };

    @action
    AddRow = (): void => {
        const getMinIdFromExistingData = Math.min.apply(
            null,
            this.tractorInfo.map(item => item.ID)
        );
        const object = {
            ID: 0,
            VIN: '',
            RegistrationNo: '',
            CarrierCompany: '',
            ComplianceReviewStatus: '',
            ComplianceReviewStatusID: 0,
            ModifiedBy: 0,
            ModifiedDate: '',
            CreatedByUser: this.userName,
            CreatedBy: this.userID,
            Checkbox: false
        };
        const tractorResponse: TractorResponse = object;
        tractorResponse.ID = getMinIdFromExistingData > 0 ? 0 : getMinIdFromExistingData - 1;
        this.agGridService.addNewRowToGrid(tractorResponse, 0);
        this.tractorInfo.splice(0, 0, tractorResponse);
    };

    @Loader
    @action
    @Catch(() => errorHandler(TractorMessage.FAILED_SUBMIT))
    async updateTractors(): Promise<void> {
        const updatedRowIDs = this.tractorInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        this.getUpdatedRowIDs().forEach(id => {
            console.log(Object.keys(this.cellValueChangeMap[id]).indexOf('ComplianceReviewStatus'));
            if (
                Object.keys(this.cellValueChangeMap[id]).length >= 1 &&
                Object.keys(this.cellValueChangeMap[id]).indexOf('ComplianceReviewStatus') == -1
            ) {
                updatedRowIDs.forEach(row => {
                    if (row.ID == id) {
                        row.ComplianceReviewStatusID = 1;
                        row.ComplianceReviewStatus = 'Submitted';
                    }
                });
            }
        });
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateTractorRequest(updatedRowIDs);
        const validationMessage = this.tractorValidationService.validateUpdateRequest(
            requestBody,
            this.backupTractorList
        );
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        let errorInSave = false;
        const response = await this.tractorDataService.updateTractor(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].ID) {
                    errorInSave = true;
                    break;
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error(TractorMessage.FAILED_SUBMIT);
        } else this.uiService.toastService.success(TractorMessage.SAVE);
        this.loadViewModel();
    }

    updateTractorRequest(updatedRows: TractorResponse[]): TractorResponse[] {
        updatedRows.forEach(item => {
            if (item.ID > 0) {
                item.ModifiedBy = this.userID;
                item.ModifiedByUser = this.userName;
            }
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): any {
        let updatedRowIDs: any = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };
    isDeleteEnabled = (): boolean => {
        const isEnabled = this.dataToBeDeleted.length > 0 ? false : true;
        return isEnabled;
    };
    isAddDisabled = (): boolean => {
        return !(dataStore.checkOperationAccess('VINEdit') && dataStore.checkOperationAccess('RegistrationEdit'));
    };

    mapEditableColumns(currentItem: TractorResponse, updatedItem: TractorResponse) {
        currentItem.VIN = updatedItem.VIN;
        currentItem.RegistrationNo = updatedItem.RegistrationNo;
        currentItem.CheckBox = updatedItem.CheckBox;
        currentItem.ComplianceReviewStatus = updatedItem.ComplianceReviewStatus;
        currentItem.ComplianceReviewStatusID = updatedItem.ComplianceReviewStatusID;
        currentItem.CarrierCompany = updatedItem.CarrierCompany;
    }

    @action
    DeleteRows = (): void => {
        const updateData = this.tractorInfo.filter(x => !this.dataToBeDeleted.filter(y => y.ID === x.ID).length);
        this.deleteTractor(this.dataToBeDeleted, updateData);
    };
    @Loader
    @action
    @Catch(() => errorHandler(TractorMessage.FAILED_DELETE))
    async deleteTractor(dataToBeDeleted, updateData): Promise<void> {
        const Promises: any = [];
        const dataToBeDeletedLocal = dataToBeDeleted.filter(a => a.ID > 0);
        if (dataToBeDeletedLocal.length > 0) {
            dataToBeDeletedLocal.map(async item => {
                const deleteResult = this.tractorDataService.deleteTractor(item);
                Promises.push(deleteResult);
            });
        }
        Promise.all(Promises).then(
            () => {
                this.setTractorList(updateData);
                this.setbackupTractorList(updateData);
                this.dataToBeDeleted = [];
                this.isDeleteEnabled();
                this.uiService.toastService.success(TractorMessage.TRACTOR_DELETE);
            },
            () => {
                this.uiService.toastService.error(TractorMessage.FAILED_DELETE);
            }
        );
    }
}
