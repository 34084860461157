import * as React from 'react';
import './_confirm.scss';
import { Row, Col } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import Select from 'react-select';
import { LocationMapingDataService } from 'features/data_management/domains/location-maping-management/location-map-data-service';
import { inject } from 'mobx-react';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (externalLoc, sourceLocCode, sourceLocLSD, aXLoc, datasource, truckedbByLocation) => void;
    msg;
    locationMapingDataSerivice: LocationMapingDataService;
}
export class LocationMapingPopUp extends React.Component<ConfirmProps> {
    state = {
        sourceLocationPlaceholder: '',
        sourceLocationCodePlaceholder: '',
        sourceLocationLSDPlaceholder: '',
        aXLocationPlaceholder: 'Select Location',
        datasourcePlaceholder: 'Select Datasource',
        truckedByLocationPlaceholder: 'Select TruckedByLocations'
    };
    enteredSourceLoc: any;
    selectedAXLocation: any;
    selectedDataSource: any;

    handleKeyDown = event => {
        const charCode = event.which ? event.which : event.KeyCode;
        //allow for letter,number,spacebar,comma,period,dash,backspace
        if (
            !(
                (charCode > 47 && charCode < 58) ||
                (charCode > 64 && charCode < 91) ||
                (charCode > 96 && charCode < 123) ||
                charCode == 0 ||
                (charCode > 187 && charCode < 191) ||
                charCode == 32 ||
                charCode == 8 ||
                charCode == 222
            )
        ) {
            event.preventDefault();
            return;
        }
    };

    onTextChange = event => {
        this.setState({ sourceLocationPlaceholder: event.target.value });
    };

    onCodeTextChange = event => {
        this.setState({ sourceLocationCodePlaceholder: event.target.value });
    };

    onLSDTextChange = event => {
        this.setState({ sourceLocationLSDPlaceholder: event.target.value });
    };
    selectedTruckedByLocation: any;

    public render(): React.ReactNode {
        const { onClose, msg } = this.props;
        const AXLocations = Array.from(new Set(msg.map((item: any) => item.Description)));
        const isSaveDisabled =
            this.state.sourceLocationPlaceholder != '' ||
            this.state.sourceLocationCodePlaceholder != '' ||
            this.state.sourceLocationLSDPlaceholder != '' ||
            this.selectedAXLocation != undefined;
        const allproduct = [
            { value: 'NGL', label: 'NGL' },
            { value: 'Crude', label: 'Crude' },
            { value: 'Butane', label: 'Butane' },
            { value: 'Brine', label: 'Brine' }
        ];
        const locationOption = [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
        ];
        return (
            <div>
                <div className="location__maping" style={{ height: 540 }}>
                    <Row>
                        <Col>
                            <div>
                                Enter External LocName :{' '}
                                <div className="location__maping__text">
                                    <textarea
                                        style={{ width: '450px', height: '40px' }}
                                        autoComplete="off"
                                        placeholder={this.state.sourceLocationPlaceholder}
                                        onChange={this.onTextChange}
                                    ></textarea>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                Enter External LocCode :{' '}
                                <div className="location__maping__text">
                                    <textarea
                                        style={{ width: '250px', height: '40px' }}
                                        autoComplete="off"
                                        placeholder={this.state.sourceLocationCodePlaceholder}
                                        onChange={this.onCodeTextChange}
                                    ></textarea>
                                </div>
                            </div>

                            <div>
                                Enter External LocLSD :{' '}
                                <div className="location__maping__text">
                                    <textarea
                                        style={{ width: '250px', height: '40px' }}
                                        autoComplete="off"
                                        placeholder={this.state.sourceLocationLSDPlaceholder}
                                        onChange={this.onLSDTextChange}
                                    ></textarea>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div>
                                Select a Mapped Location:{' '}
                                <div className="location__maping__text">
                                    <Select
                                        value={this.selectedAXLocation}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.aXLocationPlaceholder}
                                        options={AXLocations ? AXLocations : msg}
                                        onChange={this.handleAXLocationType}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                Select Datasource:{' '}
                                <div className="location__maping__text">
                                    <Select
                                        value={this.selectedDataSource}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.datasourcePlaceholder}
                                        options={allproduct}
                                        onChange={this.handleDatasourceType}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                Select TruckedByLocations:{' '}
                                <div className="location__maping__text">
                                    <Select
                                        value={this.selectedTruckedByLocation}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.truckedByLocationPlaceholder}
                                        options={locationOption}
                                        onChange={this.handleTruckedByLocation}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="confirm__actionLoc mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={!this.selectedDataSource}
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }

    handleAXLocationType = option => {
        this.selectedAXLocation = option;
        this.setState({ aXLocationPlaceholder: option });
    };

    handleDatasourceType = option => {
        this.selectedDataSource = option.value;
        this.setState({ datasourcePlaceholder: option.value });
    };

    handleTruckedByLocation = option => {
        this.selectedTruckedByLocation = option.value;
        this.setState({ truckedByLocationPlaceholder: option.value });
    };

    handleClick = () => {
        this.props.onSuccessClick(
            this.state.sourceLocationPlaceholder,
            this.state.sourceLocationCodePlaceholder,
            this.state.sourceLocationLSDPlaceholder,
            this.state.aXLocationPlaceholder === 'Select Location' ? null : this.state.aXLocationPlaceholder,
            this.state.datasourcePlaceholder,
            this.state.truckedByLocationPlaceholder
        );
        this.props.onClose();
    };
}
