export enum LocationMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Location data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    DELETEDSUCCESS = 'Deleted Successfully',
    DELETEDERROR = 'Failed to delete records',
    NO_ROW_SELECTED = 'No record selected',
    UPLOAD_SUCCESS = 'Record successfully uploaded ',
    UPLOAD_ERROR = 'Failed to upload records',
    DUPLICATE_ERROR = 'Record already exists'
}

export enum LocationButtons {
        SAVE = 'Save Location Data',
        RESET = 'Reset',
        CLEARALL = 'Clear All',
        ADDNEWROW = 'Add New Row',
        UPLOAD = 'Upload',
        EXPORTTOEXCEL = 'Export To Excel',
        DELETEITEMS = 'Delete Items',
}

export enum LocationHeaderName {
    ID = 'ID',
    DELETE = 'DELETE',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    REGION = 'REGION',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE',
    CURRENTLOADSIZE = 'CURRENT LOAD SIZE (m3)',
    PLANNEDLOADSIZE = 'PLANNED LOAD SIZE (m3)',
    RESTRICTEDLOADSIZE = 'RESTRICTED LOAD SIZE (m3)',
    RESTRICTEDFLAG = 'RESTRICTED FLAG',
    CONSIGNORNAME = 'CONSIGNOR NAME',
    CONSIGNORPHONE = 'CONSIGNOR PHONE',
    CONSIGNORADDRESS = 'CONSIGNOR ADDRESS',
    CONSIGNORRA = 'CONSIGNOR RA',
    CONSIGNEERA = 'CONSIGNEE RA',
    SHORTNAME = 'OD PAIR SHORT NAME'
}

export enum LocationFieldName {
    ReportJson = 'ReportJson',
    ID = 'ID',
    CONSIGNOR = 'Consignor',
    CONSIGNEE = 'Consignee',
    MODIFIEDBY = 'ModifiedByUser',
    MODIFIEDDATE = 'ModifiedDate',
    REGION = 'Region',
    CURRENTLOADSIZE = 'CurrentLoadsize',
    DELETE = "Delete",
    PLANNEDLOADSIZE = 'PlannedLoadsize',
    RESTRICTEDLOADSIZE = 'RestrictedLoadsize',
    RESTRICTEDFLAG = 'IsRestrictedLoadsize',
    CONSIGNORNAME = 'ConsignorName',
    CONSIGNORPHONE = 'Consignor24Phone',
    CONSIGNORADDRESS = 'ConsignorAddress',
    CONSIGNORRA = 'RAConsignor',
    CONSIGNEERA = 'RAConsignee',
    SHORTNAME = 'ShortNames'

}
