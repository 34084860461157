import React, { Component } from 'react';
import { CellValueChangedEvent, ColDef, FilterChangedEvent, GridOptions } from 'ag-grid-community';
import { Col, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { localStorageService } from 'shared/services/local-storage-service';
import '../../dispatch-board/components/style.scss';
import { AgGridReact } from 'ag-grid-react';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { DispatchPlanDataStore } from '../stores/dispatchPlan-store';
import { ticketsGridService } from 'features/userApprovalFlow/services/tickets-grid-service';

export interface SelectedTicketListPopupProps {
    dataSource: any;
    onClose: () => void;
    onSuccessClick: () => void;
    onCellValueChanged?: (value: CellValueChangedEvent) => void;
    onFilterChanged?: (value: FilterChangedEvent) => void;
    dispatchPlanDataStore: DispatchPlanDataStore;
    buttonName: string;
}

export const SelectedTicketListColDef: ColDef[] = [
    {
        headerName: 'Ticket Number',
        field: 'LoadNumber',
        width: 110,
        valueGetter: params => {
            if (params.data['LoadNumber'] === null) {
                return '';
            } else {
                return params.data['LoadNumber'];
            }
        }
    },
    {
        headerName: 'Load Status',
        field: 'LoadStatus',
        width: 100,
        valueGetter: params => {
            if (params.data['LoadStatus'] === null) {
                return '';
            } else if (params.data['LoadStatus'] === 'Assigned') {
                return 'Dispatched';
            } else {
                return params.data['LoadStatus'];
            }
        }
    },
    {
        headerName: 'Driver Name',
        field: 'DriverName',
        width: 200,
        valueGetter: params => {
            if (params.data['DriverName'] === null || params.data['DriverName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['DriverName'];
            }
        }
    },
    {
        headerName: 'Carrier Name',
        field: 'CarrierName',
        width: 200,
        valueGetter: params => {
            if (params.data['CarrierName'] === null || params.data['CarrierName'] === 'Dummy Test') {
                return '';
            } else {
                return params.data['CarrierName'];
            }
        }
    }
];

@inject('dispatchPlanDataStore')
@observer
export class SelectedTicketListPopupComponent extends Component<SelectedTicketListPopupProps> {
    constructor(props) {
        super(props);
    }

    render(): React.ReactNode {
        const { onClose, onSuccessClick, buttonName } = this.props;
        const options = { ...this.getGridConfig() };
        return (
            <div className="modal" id="tickets-modal">
                <div
                    id="selected-tickets-content"
                    style={{ backgroundColor: '#ffffff', width: '300px', height: '30vh' }}
                >
                    <Row>
                        <Col>
                            <div
                                style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px' }}
                                className="ag-theme-balham open-tickets-data-grid"
                            >
                                <AgGridReact stopEditingWhenGridLosesFocus={true} {...options} />
                            </div>
                        </Col>
                    </Row>
                    <div className="justify-content-center confirm__primary-text">
                        Do you want to proceed with {buttonName} these tickets?
                    </div>
                    <div className="justify-content-center confirm__action">
                        <CustomButton
                            type={CustomButtonType.Ok}
                            onClick={() => {
                                onSuccessClick();
                                onClose();
                            }}
                        >
                            Ok
                        </CustomButton>
                        <CustomButton
                            type={CustomButtonType.Ok}
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }

    handleClear = () => {
        localStorageService.set('SelectedTickets_filter', {});
        ticketsGridService.clearAllFilters();
    };

    isClearAllDisabled = (): boolean => {
        if (this.props.dataSource.length == 0) {
            return true;
        } else {
            return false;
        }
    };
    getGridConfig(): GridOptions {
        const { dataSource, onCellValueChanged, onFilterChanged } = this.props;
        const gridOptions: GridOptions = {
            columnDefs: SelectedTicketListColDef,
            rowData: dataSource,
            onCellValueChanged,
            onFilterChanged
        };
        const config = ticketsGridService.getTicketsGridOptions();
        return { ...gridOptions, ...config };
    }
}
