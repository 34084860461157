import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
export interface infoVideoProps {
    onClose: () => void;
    url: string;
    text: string;
}


export class InfoVideoPopUp extends React.Component<infoVideoProps> {
    public render(): React.ReactNode {
        const { onClose, url, text } = this.props;
        console.log(url, text);
        return (
            <div>
                <div>
                <label className='infoVideo__labelFont'>{text}</label>
                <div className="infoVideo__warning">
                    <i className="fa fa-close confirm-warning" onClick={onClose}></i>
                </div>
                </div>
                <ReactPlayer controls url={[
                    { src: 'https://pmcwu2hddlrststtrucking.blob.core.windows.net/tutorial/SignUp_azure.mp4', type: 'video/mp4'}
                ]}/>
                
            </div>
        );
    }

    handleClick = () => {
        this.props.onClose();
    };
}
