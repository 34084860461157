import { RestApiService } from "shared/services/rest-api-service";
import { restApiService } from "shared/services";
import { HazardAssessmentHistoryViewModel, HazardAssessmentHistoryResponse, HazardAssessmentHistoryRequest } from "../model/hazardAssessment-history-model";
import { Config } from "config";
import { ResponseDataType } from "shared/types/shared";
import { DateRange } from "shared/components/daypicketInput/react-daypickerInput";

export class HazardAssessmentTicketHistoryDataService {
    constructor(private restApi: RestApiService) { }
    
    async getHazardAssessmentViewModel(): Promise<HazardAssessmentHistoryViewModel> {

        const hazardAssessmentDataPromise = this.getHazardAssessmentHistoryData();
        const [{ data: hazardAssessmentData }
        ] = await Promise.all([hazardAssessmentDataPromise]);
        return {
            hazardAssessmentData,
        };
    }

    async getHazardAssessmentViewModelWithDateParams(reqbody: DateRange): Promise<HazardAssessmentHistoryViewModel> {

        const hazardAssessmentDataPromise = this.getHazardAssessmentTicketWithDateParams(reqbody);
        const [{ data: hazardAssessmentData }
        ] = await Promise.all([hazardAssessmentDataPromise]);
        return {
            hazardAssessmentData
        };
    }

    private getHazardAssessmentHistoryData(): Promise<ResponseDataType<HazardAssessmentHistoryResponse[]>> {
        return this.restApi.postWithToken<HazardAssessmentHistoryResponse[]>(Config.getHazardAssessmentReport);
    }

    async updateHazardAssessmentHistory(reqbody: HazardAssessmentHistoryRequest[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.createUpdateHazardAssessment, reqbody, {});
        return data['Data'];
    }

    async getHazardAssessmentTicketWithDateParams(reqbody: DateRange): Promise<ResponseDataType<HazardAssessmentHistoryResponse[]>> {
        return this.restApi.postWithToken<HazardAssessmentHistoryResponse[]>(Config.getHazardAssessmentReport, reqbody, {});
    }

}
export const hazardAssessmentTicketHistoryDataService = new HazardAssessmentTicketHistoryDataService(restApiService);
