import React, { PureComponent } from 'react';
import { printerStore } from '../../stores';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class CheckBoxCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange() {
        this.setState({ value: !this.state.value });
        printerStore.handleCheckboxClick(this.props.data, this.state.value, this.props.column.getColId());
    }

    render() {
        return <input type="checkbox"
            checked={this.state.value}
            onChange={this.handleCheckboxChange}
            disabled={!dataStore.checkOperationAccess('Save')} />;
    }
}
