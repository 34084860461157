import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import * as XLSX from 'xlsx';
import { toJS } from 'mobx';
import { Row, Col, Container } from 'react-bootstrap';
import { AppConstant } from 'app_constant';

export interface ConfirmProps {
  onSuccessClick: (email) => void;
  onClose: () => void;
  data;
}
export class ExcelPopup extends React.Component<ConfirmProps> {
  fileInput: any
  state = {
    invalidFile: '',
  };
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  public render(): React.ReactNode {
    const { onClose } = this.props;
    return (
      <div>
        <div className="confirm __uploadingWidth">
          <div className="confirm__action mt-10">Please upload your excel file</div>
          <Row className="mb-2">
            <Col>
              <div className="input">
                <input
                  //onChange={this.handleSubmit}
                  className={`form-control ${this.state.invalidFile}`}
                  type="file"
                  ref={this.fileInput}
                  accept=".xlsx" />
              </div>
            </Col>
          </Row>

          <div className="confirm__action mt-10">
            <CustomButton type={CustomButtonType.Ok} onClick={this.handleSubmit}>
              Send
            </CustomButton>
            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
              Cancel
            </CustomButton>
          </div>
        </div>
      </div>
    )
  }

  handleSubmit = () => {
    if (this.fileInput.current.files.length === 0) {
      this.setState({ invalidFile: '' });
    } else {
      var f = this.fileInput.current.files;
      this.setState({ invalidFile: 'file' });
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target?.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.props.onSuccessClick(toJS(data));
      };
      reader.readAsBinaryString(f[0])
      this.props.onClose();
    };
  }
}
