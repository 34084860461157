import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import {
    DriverResponse,
    TrailerResponse,
    DispatchPlanResponse,
    DispatchPlanViewModel,
    UpdateDispatchPlanResponse,
    RegionType,
    CompanyResponse
} from '../model/model';
import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';
import { DateRange } from 'shared/components/daypicketInput/react-daypickerInput';
import { AppConstant } from 'app_constant';

export class DispatchPlanDataService {
    constructor(private restApi: RestApiService) {}

    async getTrailerResponse(): Promise<ResponseDataType<TrailerResponse[]>> {
        return this.restApi.postWithToken<TrailerResponse[]>(Config.getTrailer, { Version: 1 });
    }

    async getDriverResponse(reqbody): Promise<ResponseDataType<DriverResponse[]>> {
        const { data } =  await this.restApi.post<any>(Config.getDriverForDispatchPlan, reqbody, {});
        return data;
    }

    async getCarrierCompanyData(): Promise<ResponseDataType<CompanyResponse[]>> {
        const { data } =  await this.restApi.postWithToken<any>(Config.getCarrierCompanyData);
        return data;
    }

    async getDispatchPlanResponse(reqbody): Promise<ResponseDataType<DispatchPlanResponse[]>> {
        return this.restApi.postWithToken<DispatchPlanResponse[]>(Config.getDispatchPlanData, reqbody, {});
    }

    async updateDispatchPlanStatus(reqbody): Promise<UpdateDispatchPlanResponse[]> {
        const { data } = await this.restApi.post<any>(Config.updateDispatchPlanStatus, reqbody, {});
        return data;
    }

    async getDispatchPlanModel(): Promise<DispatchPlanViewModel> {
        const trailerDataPromise = this.getTrailerResponse();
        const [{ data: trailerData }] = await Promise.all([
            trailerDataPromise
        ]);
        return {
            trailerData
        };
    }

    async getDispatchPlanViewModelWithDateParams(reqbody: DateRange): Promise<DispatchPlanViewModel> {
        const trailerDataPromise = this.getTrailerResponse();
        const [{ data: trailerData }] = await Promise.all([
            trailerDataPromise
        ]);
        return {
            trailerData
        };
    }

    async getDispatchPlanDataWithDateParams(reqbody: any): Promise<ResponseDataType<DispatchPlanResponse[]>> {
        return this.restApi.postWithToken<DispatchPlanResponse[]>(Config.getDispatchPlanData, reqbody, {});
    }

    async getRegionTypes(): Promise<ResponseDataType<RegionType[]>> {
        return this.restApi.postWithToken<RegionType[]>(Config.getBaseValueByClass, { CODE: 'REGION_NAMES' });
    }

    async getUOM() {
        const { data } = await this.restApi.postWithToken<RegionType[]>(Config.getUom);
        return data['Data'];
    }
    async getProduct() {
        const { data } = await this.restApi.postWithToken<RegionType[]>(Config.getProduct);
        return data['Data'];
    }
    async getInquiries() {
        const { data } = await this.restApi.postWithToken<RegionType[]>(Config.getInquiries);
        return data;
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }
}

export const dispatchPlanDataService = new DispatchPlanDataService(restApiService);
