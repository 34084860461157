import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { MasterTicketReportResponse, DateRange } from '../domains/model';
import { MasterTicketReportDataService } from '../services/master-ticket-report-data-service';
import { uiService } from 'shared/services/ui-service';
import { DispatchDetails, MasterTicketReport } from '../domains/master-ticket-report-col-def';
import { ReportMessage } from '../domains/enum';
import { accountStore } from 'features/account/stores';
import { CCUserRoles, MasterTicketstatusType } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';
import { TicketModel } from 'features/operations-portal/model/ticket-response-model';
import { localStorageService } from 'shared/services/local-storage-service';
import moment from 'moment';

export class MasterTicketReportDataStore {
    @observable MasterTicketReports: any[] = [];
    @observable DispatchDetails: any[] = [];
    userID = 0;
    userName = '';
    @observable gridData: any;
    @observable masterGrid: any;
    @observable startDate: any = '';
    @observable endDate: any = '';
    @observable selectedType: any = 'All';
    @observable selectedStatus: any = ['all'];
    @observable showDispatchDetailsPopup: boolean = false;
    isRefreshed: boolean = false;
    @observable selectedticketList!: TicketModel;
    selectedTicketNumber: any = {};
    @observable selectedticketsList: any[] = [];
    @observable isDownloadEnabled: boolean = false;
    @observable ticketList: TicketModel[] = [];
    @observable MasterTicketBackupReport: MasterTicketReportResponse[] = [];
    @observable volumeSum:any ={
        volumeDelivered : 0,
        actVolume : 0
    }
    userInfo: any;
    @observable selectedOption: any = [{ value: 'all', label: 'All' }];
    @observable selectedTicketType: any = { value: 'All', label: 'All' }
    @observable errorFlag: boolean = false;
    constructor(
        private MasterTicketreportDataService: MasterTicketReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) { }

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getMasterTicketReports();
    }

    @action
    setTicketItems(ticketList: any[]) {
        this.ticketList = ticketList;
        this.selectedTicketNumber = ticketList[0].ticketNumber
        this.selectedticketList = ticketList[0]
    }

    getColDef() {
        return MasterTicketReport;
    }

    getDispatchDetailsColDef() {
        return DispatchDetails;
    }

    statusFiltered(statusList, type) {
        this.saveFilters(type, statusList)
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(MasterTicketstatusType.map((item: any) => item.value)));
        }
        if(statusList && statusList.length > 0) {
            if (statusList.includes('all') && statusList.length > 1) {
                statusList.forEach((ele, index) => {
                    if (ele.value == 'all') statusList.splice(index, 1);
                });
            }
        }
        if(statusList.includes('Cancelled')) statusList.push('Canceled')
        const reports: any = this.MasterTicketBackupReport.filter(element => {
            return ((statusList.includes('all') && statusList.length == 1)? true : statusList.includes(element.TicketStatus)) 
             && (type !== 'All' ? type.includes(element.TicketType) : true);
        });
        this.MasterTicketReports = reports;
        if (this.MasterTicketReports.length == 0) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
        this.getVolumeSum(this.MasterTicketReports);
    }

    typeFiltered(type, statusList) {
        this.saveFilters(type, statusList)
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(MasterTicketstatusType.map((item: any) => item.value)));
        }
        let status: any = [];
        if(statusList && statusList.length > 0) {
            status = Array.from(new Set(statusList.map((item: any) => item)));
            if (statusList.includes('all') && statusList.length > 1) {
                status.forEach((ele, index) => {
                    if (ele == 'all') status.splice(index, 1);
                });
            }
        }
        if(statusList.includes('Cancelled')) statusList.push('Canceled')
        const reports: any = this.MasterTicketBackupReport.filter(element => {
            return (type == element.TicketType || type === 'All') && (!status.includes('all') ? status.includes(element.TicketStatus) : true);
        });
        this.MasterTicketReports = reports;
        if (this.MasterTicketReports.length == 0) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
        this.getVolumeSum(this.MasterTicketReports);
    }

    filterFromDropdown(statusList, type) {
        type = type.value
        this.saveFilters(type, statusList)
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(MasterTicketstatusType.map((item: any) => item.value)));
        }
        if(statusList && statusList.length > 0) {
            if (statusList.includes('all') && statusList.length > 1) {
                statusList.forEach((ele, index) => {
                    if (ele.value == 'all') statusList.splice(index, 1);
                });
            }
        }
        if(statusList.includes('Cancelled')) statusList.push('Canceled')
        const reports: any = this.MasterTicketBackupReport.filter(element => {
            return ((statusList.includes('all') && statusList.length == 1)? true : statusList.includes(element.TicketStatus)) 
             && (type !== 'All' ? type.includes(element.TicketType) : true);
        });
        this.MasterTicketReports = reports;
        if (this.MasterTicketReports.length == 0 && this.errorFlag == false) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
        this.getVolumeSum(this.MasterTicketReports);
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.MASTERTICKET_ERROR_MESSAGE))
    async getMasterTicketReports() {
        let dropdown: any = localStorageService.get('MasterTicketReport_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            this.selectedTicketType = dropdown[0].TicketType
        } else {
            localStorageService.set('MasterTicketReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'TicketType': { value: 'All', label: 'All' }}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            this.selectedTicketType = { value: 'All', label: 'All' }
        }
        // this.MasterTicketReports = [];
        const reqbody: any = {};
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const MasterTicketReports = await this.MasterTicketreportDataService.getMasterTicketwithParams(reqbody);
        this.MasterTicketReports = MasterTicketReports;
        this.errorFlag = false;
        this.startDate = moment().format("yyyy-MM-01")
        this.endDate = moment().format("yyyy-MM-"+moment().date())
        if (this.MasterTicketReports.length == 0) {
            this.errorFlag = true;
            uiService.toastService.error(ReportMessage.NO_RECORDS)
            return;
        }
        this.selectedType = 'All';
        this.selectedStatus = ['all'];
        if(this.isRefreshed === false){
        this.setTicketItems(this.MasterTicketReports)}
        this.setBackupReport(this.MasterTicketReports);
        this.getVolumeSum(this.MasterTicketReports);
        this.selectedticketsList = [];
        this.isRefreshed = false;
        const filters = localStorageService.get('MasterTicketReport_filter');
        this.agGridService.setAllFilters(filters);
        let status = Array.from(new Set(this.selectedOption.map((item: any) => item.value)))
        this.filterFromDropdown(status, this.selectedTicketType);
        
    }
    @computed
    get DuplicateList(): MasterTicketReportResponse[] {
        return toJS(this.MasterTicketReports);
    }
    @computed
    get DispatchDetailsList(): MasterTicketReportResponse[] {
        return toJS(this.DispatchDetails);
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.MASTERTICKET_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        let dropdown: any = localStorageService.get('MasterTicketReport_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            this.selectedTicketType = dropdown[0].TicketType
        } else {
            localStorageService.set('MasterTicketReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'TicketType': [{ value: 'All', label: 'All' }]}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            this.selectedTicketType = { value: 'All', label: 'All' }
        }
        this.startDate = startDate;
        this.endDate = endDate;
        this.saveDateRange(startDate, endDate);
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        // this.MasterTicketReports = [];
        const MasterTicketReports = await this.MasterTicketreportDataService.getMasterTicketwithParams(reqbody);
        this.MasterTicketReports = MasterTicketReports;
        if (this.MasterTicketReports.length == 0) {
            this.errorFlag = true;
            uiService.toastService.error(ReportMessage.NO_RECORDS)
            return;
        }
        this.errorFlag = false;
        this.selectedType = 'All';
        this.selectedStatus = ['all'];
        if(this.isRefreshed === false){
        this.setTicketItems(this.MasterTicketReports);}
        this.setBackupReport(this.MasterTicketReports);
        this.getVolumeSum(this.MasterTicketReports);
        this.selectedticketsList = [];
        this.isRefreshed = false;
        this.filterFromDropdown(Array.from(new Set(this.selectedOption.map((item: any) => item.value))), this.selectedTicketType);
        const filters = localStorageService.get('MasterTicketReport_filter');
        this.agGridService.setAllFilters(filters);
    }
    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.DISPATCHDETAILS_ERROR_MESSAGE);
    })
    async getDispatchDetails() {
        const reqbody: any = [];
        this.gridData.map(element => {
            reqbody.push(element.TicketNumber)
        })
        const DispatchDetails = await this.MasterTicketreportDataService.getDispatchDetails({TicketNumbers: reqbody});
        this.DispatchDetails = DispatchDetails;

        if (this.DispatchDetails.length == 0) {
            uiService.toastService.error(ReportMessage.NO_RECORDS)
            return;
        }
    }
    @action
    setBackupReport(reports: MasterTicketReportResponse[]): void {
        this.MasterTicketBackupReport = reports;
    }

    isDisabled = (): boolean => {
        if (this.MasterTicketReports.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    saveFilters(type, statusList) {
        this.selectedType = type;
        this.selectedStatus = statusList;
    }
    saveDateRange(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
    async handleRefresh(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        uiService.loaderService.showLoader()
        const MasterTicketReports = await this.MasterTicketreportDataService.getMasterTicketwithParams(reqbody);
        uiService.loaderService.hideLoader()
        this.MasterTicketReports = MasterTicketReports;
        this.setBackupReport(this.MasterTicketReports);
        this.getVolumeSum(this.MasterTicketReports);
        this.selectedticketsList = [];
        let status = Array.from(new Set(this.selectedOption.map((item: any) => item.value)))
        this.filterFromDropdown(status, this.selectedTicketType);
        const filters = localStorageService.get('MasterTicketReport_filter');
        this.agGridService.setAllFilters(filters);
    }
    selectTicketToBeDownloaded(ticket: any, value: any, colName: string): void {
        this.selectedticketList = ticket
        this.selectedTicketNumber = ticket.TicketNumber
        this.isDownloadEnabled = true
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    selectedTickets(ticket: any, value: any, colName: string): void {
        const selectedTicket: any = this.MasterTicketReports.filter(a => a.ID === ticket.ID);
        switch (colName) {
            case 'SELECTED':
                selectedTicket[0].SELECTED = !value;
                ticket.SELECTED = !value;
                break;
            default:
                break;
        }
        if (!value) {
            const prevResult = this.selectedticketsList.filter( a => a.ID === ticket.ID );
            if(prevResult.length > 0) {
                this.selectedticketsList = this.selectedticketsList.filter( a => a.ID !== ticket.ID );
            }
            this.selectedticketsList.push(ticket)
        } else {
            this.selectedticketsList = this.selectedticketsList.filter(a => a.ID !== ticket.ID);
        }
    }

    getVolumeSum(data){
        this.gridData = data;
        let estVolSum=0, actVolSum=0;
        for (let report in data) {
            estVolSum += data[report].EstimatedVolume;
            actVolSum += data[report].VolumeDelivered;
        }
        this.volumeSum.volumeDelivered = estVolSum.toFixed(3);
        this.volumeSum.actVolume = actVolSum.toFixed(3);
    }
}
