import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { NominationComponent } from '../components/nomination-component';
import { nominationStore } from '../stores/nominatation-data-store';
import { dataStore } from 'features/common/stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class NominationCheckBoxSelection extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data.DELETE ? props.data.DELETE : false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange() {
        this.setState({ value: !this.state.value });
        console.log('Row Index' + this.state.value);
        nominationStore.handleCheckboxClick(this.props.data, this.state.value, this.props.column.getColId());
    }

    render() {
        console.log(this.state.value, this.props.data.DELETE);
        return (
            <>
                {this.props.data.IsLoad && (
                    <input
                        type="checkbox"
                        disabled={
                            this.props.data.IsDeleted ||
                            this.props.data.IsAdjustDeleted ||
                            this.props.data.IsApportionmentDeleted ||
                            !dataStore.checkOperationAccess('Save')
                        }
                        checked={this.state.value}
                        onChange={this.handleCheckboxChange}
                    />
                )}
            </>
        );
    }
}
