import React, { PureComponent } from 'react';
import { locationStore } from '../../stores';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class CheckBoxCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }
    
    handleRadioButtonChange() {
        this.setState({ value: !this.props.value });
        locationStore.handleCheckboxClick(this.props.data, this.state.value, this.props.column.getColId());
    };
      
      render() {
        const { value } = this.state;
        const rowId = this.props.rowIndex
        if (rowId === 0) {
           this.state = { value : true }
        }
        return ( 
            <input 
            onChange={this.handleRadioButtonChange}
            type="radio" 
            disabled = {!dataStore.checkOperationAccess('Save')}
            checked={this.props.value && locationStore.selectedRow.CheckBox}
            name="pdf"/> 
         )
      }
}