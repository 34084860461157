import { AuthToken } from '../types/shared';
import * as Msal from 'msal';
import { Config } from '../../config';
import { AppConstant } from '../../app_constant';
import { isArray } from 'lodash';

export class AuthenticationService {
    private clientApplication: Msal.UserAgentApplication;
    private token = '';
    private config: Msal.Configuration = {
        auth: {
            clientId: Config.apiClientID,
            redirectUri: Config.redirectURL,
            postLogoutRedirectUri: Config.redirectURL,
            authority: Config.msalEndpoint + Config.tenantID + '/'
        },
        cache: {
            cacheLocation: 'localStorage'
        }
    };

    constructor() {
        console.log("auth service Msal call this.config "+ JSON.stringify(this.config))
        this.clientApplication = new Msal.UserAgentApplication(this.config);
        console.log("auth service Msal call "+ JSON.stringify(this.clientApplication))
    }

    async login(): Promise<AuthToken> {
        const clientApplication = this.clientApplication;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const idToken: any = await clientApplication.loginPopup();
        console.log('loginPopup', idToken);
        return Promise.resolve({
            value: idToken.idToken.rawIdToken,
            userName: idToken.account.userName,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idToken.roles)
        });
    }

    async acquireTokenSilent(): Promise<AuthToken> {
        const loginRequest = {
            scopes: [Config.apiClientID]
        };
        const clientApplication = this.clientApplication;
        const idToken: any = await clientApplication.acquireTokenSilent(loginRequest);
        console.log("acquireTokenSilent Msal call "+ JSON.stringify(idToken))
        return Promise.resolve({
            value:
                idToken.accessToken === '' || idToken.accessToken === null
                    ? idToken.idToken.rawIdToken
                    : idToken.accessToken,
            userName: idToken.account.userName,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idToken.roles)
        });
    }

    async acquireTokenPopup(): Promise<AuthToken> {
        const loginRequest = {
            scopes: [Config.apiClientID]
        };
        const clientApplication = this.clientApplication;
        const idToken: any = await clientApplication.acquireTokenPopup(loginRequest);
        return Promise.resolve({
            value:
                idToken.accessToken === '' || idToken.accessToken === null
                    ? idToken.idToken.rawIdToken
                    : idToken.accessToken,
            userName: idToken.account.userName,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idToken.roles)
        });
    }

    // If user has Is Role, it automatically get all other permission of APPROLES
    getUserRoles(roles: string[]) {
        let newRoles: string[] = [];

        if (roles && isArray(roles)) {
            newRoles = roles;
        }
        return newRoles;
    }

    public logout(): void {
        this.clientApplication.logout();
    }

    public isOnline(): boolean {
        return this.clientApplication.getAccount() != null;
    }

    public getUser(): Msal.Account {
        return this.clientApplication.getAccount();
    }
}

export const authenticationService = new AuthenticationService();
