/* eslint-disable react/jsx-key */
import * as React from 'react';
import '../../../shared/components/confirm/_confirm.scss';
import { Row, Col, Container } from 'react-bootstrap';
import Select from 'react-select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';

export interface ConfirmProps {
    onClose: () => void;
    onSuccessClick: (consignorAXID) => void;
    vendorData;
    msg;
}
export class CompanyDropDownPopUp extends React.Component<ConfirmProps> {
    state = {
        vendorPlaceholder: this.props.msg,
        consignor: ''
    };
    selectedVendorData: any;

    public render(): React.ReactNode {
        const { onClose, msg,vendorData } = this.props;
        // const operator = Array.from(new Set(msg.map((item: any) => item.Description)));
        return (
            <div>
                <div className="location">
                    <Row>
                        <Col>
                            <div>
                                {this.props.msg}:{' '}
                                <div className="location__consignee">
                                    <Select
                                        value={this.selectedVendorData}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={this.state.vendorPlaceholder}
                                        options={vendorData}
                                        onChange={this.handleVendor}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="confirm__action mt-10">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleClick}
                                disabled={!this.selectedVendorData}
                            >
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                                Cancel
                            </CustomButton>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }

    handleVendor = option => {
        this.selectedVendorData = option;
        console.log(option);
        this.setState({ vendorPlaceholder: option });
    };

    handleClick = () => {
        this.props.onSuccessClick(this.selectedVendorData);
        this.props.onClose();
    };
}
