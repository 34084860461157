import moment from 'moment';
import { dispatchBoardDataStore } from '../store';
import weekDayHeader from './week-day-header';
const generateDate = date => {
    const { selectedMonth, selectedYear } = dispatchBoardDataStore;
    return moment(new Date(selectedYear, selectedMonth, date).toISOString()).format('MM/DD/YYYY');
};
export const dispatchBoardColDef: any = [];
dispatchBoardColDef.push(
    {
        headerName: 'Carrier Company',
        field: 'CompanyName',
        colId: 0,
        rowGroup: true,
        hide: true,
        width: 95
    },
    {
        headerName: 'Driver Name',
        field: 'Driver',
        colId: 1,
        width: 65,
        valueFormatter: params => {
            return !params.node.group ? params.data.Driver && params.data.Driver : '';
        }
    }
);
for (let i = 0; i < 31; i++) {
    dispatchBoardColDef.push({
        headerComponentFramework: weekDayHeader,
        headerComponentParams: { date: i + 1 },
        width: 58,
        colId: i,
        cellClass: params => {
            if (!params.node.group) {
                for (let ind = 0; ind < params.data.DateResponse.length; ind++) {
                    if (moment(params.data.DateResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                        let dateResponse = !params.node.group ? params.data.DateResponse[ind] : '';
                        if (!params.node.group && dateResponse != '' && dateResponse.Availability === 'UnAvailable') {
                            return 'trailer-ques-bgColor-red';
                        } else if (
                            !params.node.group &&
                            dateResponse != '' &&
                            dateResponse.Availability === 'Available'
                        ) {
                            return 'trailer-ques-bgColor-green';
                        } else return '';
                    }
                }
            }
        },
        valueFormatter: params => {
            if (!params.node.group) {
                for (let ind = 0; ind < params.data.DateResponse.length; ind++) {
                    if (moment(params.data.DateResponse[ind].Date).format('MM/DD/YYYY') === generateDate(i + 1)) {
                        let dateResponse = !params.node.group
                            ? params.data.DateResponse[ind].Date
                                ? params.data.DateResponse[ind].Date
                                : ''
                            : '';
                        return !params.node.group &&
                            dateResponse != '' &&
                            moment(dateResponse).format('MM/DD/YYYY') == generateDate(i + 1)
                            ? params.data.DateResponse[ind].CustomerName
                            : '';
                    }
                }
            }
        }
    });
}
