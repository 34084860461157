import React from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions } from 'ag-grid-community';
import { dataStore } from 'features/common/stores';
import { searchTicketsStore } from '../stores';
import './_reportmanagement.scss';
import { AppConstant } from 'app_constant';
import { localStorageService } from 'shared/services/local-storage-service';
import { agGridService } from 'features/common/services/ag-grid-service';
import { FilterChangedEvent } from 'ag-grid-community';
import _ from 'lodash';
import { SearchTicketsDataStore } from '../stores/search-tickets-data-store';
import { dateTypeArray, searchByType } from '../reports_constants';
import Select from 'react-select';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-dayPickerSearchTickets';
import { observable } from 'mobx';
import { uiService } from 'shared/services/ui-service';
import { DispatchPlanCarrierPopupComponent } from 'features/dispatch/dispatch-plan-management/components/dispatchPlan-carrier-popup-coomponent';
import { DispatchPlanTrailerPopupComponent } from 'features/dispatch/dispatch-plan-management/components/dispatchPlan-trailer-popup-component';
import { KeyFobManagementPopupComponent } from 'features/data_management/components/keyfob-management-popup-component';
import { SearchTicketFieldName } from '../domains/enum';
import { SearchTicketsPopupComponent } from './search-tickets-popup-component';

interface SearchTicketsProps {
    searchTicketsStore: SearchTicketsDataStore;
}

interface SearchTicketsState {
    showTicketInput : boolean;
    showDriverInput : boolean;
    searchBy: any;
    ticketNumber: any;
    ticketCount: number;
    dateType: string;
    duplicateTicket: boolean;
    showMaxTicketError: boolean;
}
@observer
export class SearchTicketsComponent extends React.Component<SearchTicketsProps,SearchTicketsState> {
    searchTicketsData: any = []
    ticketNumbers: any = [];
    @observable firstName: string = '';
    @observable lastName: string = '';
    data: any;
    constructor(props) {
        super(props);
        dataStore.setModuleName('SearchTickets');
        this.state = {
            showTicketInput : false,
            showDriverInput : false,
            searchBy: [],
            ticketNumber : [],
            ticketCount: 0,
            dateType: '',
            duplicateTicket: false,
            showMaxTicketError: false
        };
    }

    componentDidMount() {
        this.props.searchTicketsStore.ticketsData = []
    }

    componentDidUpdate() {
        const filters: any = localStorageService.get('SearchTickets_filter');
        agGridService.setAllFilters(filters);
    }
    
    render() {
        const {
            searchTicketsStore: { showTRModal, showCCModal, showLocModal, showDRModal }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                <ClearButtonComponent disabled={this.props.searchTicketsStore.ticketsData.length === 0} isExportCSV={true}></ClearButtonComponent>
                                <CustomButton
                                            type={CustomButtonType.Submit}
                                            // disabled={!isSaveEnabled}
                                            onClick={this.handleSave}
                                        >
                                            Save Ticket Data
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            // disabled={!isSaveEnabled}
                                            onClick={this.handleReset}
                                        >
                                            Reset
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            // disabled={!isSaveEnabled}
                                            onClick={this.handleRefresh}
                                        >
                                            Refresh
                                        </CustomButton>
                                </Col>
                                
                            </Row>
                        </div>
                        
                        <div className="form-inline padding-bottom search-container">
                            Search By : {' '}
                                <div className="reportmanagement__consignee">
                                    <Select
                                        value={this.state.searchBy}
                                        placeholder="Please select Search type"
                                        closeMenuOnSelect={true}
                                        options={searchByType}
                                        onChange={this.handleSearchType}
                                        isMulti={false}
                                    />
                                </div>{' '}

                                {this.state.showDriverInput === true && 
                                    <> <div style={{padding: '10px 10px 0 10px', margin: '10px'}}>
                                        <div style={{paddingRight: '10px'}}> 
                                        First Name{' '} <input type="text" onChange={this.handleFirstName} style={{paddingLeft: '5px', border: '0.5px solid black'}} className= 'ticket-input' placeholder=' Enter First Name'/> {'  '}
                                        Last Name{' '}  <input type="text" onChange={this.handleLastName} style={{paddingLeft: '5px', border: '0.5px solid black'}} className= 'ticket-input' placeholder=' Enter Last Name'/>
                                        </div>
                                    </div> 

                                    <div style={{display: 'flex', width: '100%', height: '100%', border: '0.4px solid black', flexDirection: 'row'}}>
                                    <div style={{margin: '10px'}}>Select Date Type : </div> {dateTypeArray.map((dateType) => (
                                        <div style={{margin: '10px'}}>
                                            <input type="radio" name= 'dateType' onClick={this.handleRadioButtonChange} id={dateType.label} value ={dateType.value}/>
                                            <div style={{display: 'inline-block'}}><label htmlFor = {dateType.label} style={{fontSize: '15px'}}>{dateType.label}</label></div>
                                        </div>
                                    ))}

                                    <div style={{padding: '10px 10px 0 10px'}}>
                                    <ReactDayPickerInput
                                        _dataStore={this.props.searchTicketsStore}
                                        dateType={this.state.dateType}
                                    ></ReactDayPickerInput>
                                    </div>
                                    
                                    <div style={{padding: '0 10px 0 10px'}}>
                                    <CustomButton type={CustomButtonType.Submit} disabled={(this.firstName === '' && this.lastName === '') || this.state.dateType === ''} onClick={this.handleSearchByDriver}>
                                            Search </CustomButton> </div>
                                    </div> </> 
                                }

                                {this.state.showTicketInput === true &&
                                    <> <div style={{paddingTop: '18px'}}>
                                    <div className='ticket-container'>
                                        {this.state.ticketNumber.map((ticket, index) => (
                                            <div className='ticket-item' key={index}>
                                                <span className='text'>{ticket}</span>
                                                <span className='close' onClick ={() => this.removeTicket(index)}>&times;</span>
                                            </div>
                                        ))} 
                                        <input type="text" 
                                            onBlur={this.handleChange}
                                            onKeyDown={this.handleKeyDown} 
                                            className= 'ticket-input' 
                                            placeholder=' Enter Ticket Number'/>
                                    </div>
                                    <div className="ticket-info" style={{ width: 500 }}>
                                        {this.state.ticketNumber.length < 10 ? this.state.duplicateTicket ? AppConstant.DUPLICATE_TICKETS :  AppConstant.ADD_TICKETS : AppConstant.MAX_TICKETS}
                                        </div> 
                                    </div>  
                                    <CustomButton type={CustomButtonType.Submit} onClick={this.handleSearchByTicket}> Search </CustomButton> </> 
                                }
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
                <Row>
                                <Col>
                                <SearchTicketsPopupComponent
                                    data={this.data}
                                    selectedCarrierId={1}
                                    onHide={this.handleCarrierPopUpClose}
                                    onSelectionChange={this.handleCarrierChange}
                                    showTRModal={showTRModal}
                                    showCCModal={showCCModal}
                                    showLocModal={showLocModal}
                                    showDRModal = {showDRModal}
                                ></SearchTicketsPopupComponent>
                                </Col>
                            </Row>
                           
            </Container>
        );
    }

    handleFirstName = event => {
        this.firstName = event.target.value
    }
    handleLastName = event => {
        this.lastName = event.target.value
    }

    handleSave = async() => {
        await this.props.searchTicketsStore.handleSave();
        this.handleRefresh();

    }

    handleReset = () => {

    }

    handleSearchByTicket = async ()  => {
        if(this.state.ticketNumber.length ===0){
            uiService.toastService.error("Please enter a Ticket Number")
            return
        }
        this.setState({ticketNumber: [...new Set(this.state.ticketNumber)]})
        this.props.searchTicketsStore.searchByTicket(this.state.ticketNumber)
    }

    handleSearchByDriver = async () => {
        this.props.searchTicketsStore.searchByDriver(
            this.firstName,
            this.lastName, 
            this.state.dateType, 
            this.props.searchTicketsStore.startDate, 
            this.props.searchTicketsStore.endDate)
    }

    handleRefresh = () => {
        if(this.state.showTicketInput)
            this.handleSearchByTicket();
        else this.handleSearchByDriver();
    }

    handleRadioButtonChange = event => {
        this.setState({dateType: event.target.value})
    }
    
    handleSearchType = option => {
        this.setState({searchBy: option})
        if(option.value === 'TicketNumber'){
            this.firstName = ''; 
            this.lastName = ''; 
            this.setState({dateType: ''})
            this.setState({showTicketInput : true})
            this.setState({showDriverInput: false})
        } else {
            this.ticketNumbers = []
            this.setState({ticketNumber: []})
            this.setState({showTicketInput : false})
            this.setState({showDriverInput: true})
        }
    }

    handleChange = event => {
        const value = event.target.value;
        this.setState({duplicateTicket: false})
        if(this.state.ticketNumber.length < 10 ){
            if(!value.trim()) return
            if(!this.ticketNumbers.includes(value)){
            this.ticketNumbers.push(value)
            this.setState({ticketNumber: this.ticketNumbers})
            }
            event.target.value = ''
        }  else {
            this.setState({showMaxTicketError: true})
            event.preventDefault(); 
        } 
    }

    handleKeyDown = event => {
        const value = event.target.value;
        this.setState({duplicateTicket: false})
        if(this.state.ticketNumber.length < 10 ){
        if(event.key !== 'Enter') {
            return
        };
        if(this.ticketNumbers.includes(value)){
            this.setState({duplicateTicket: true}) 
            event.target.value = ''
            return
        }
        if(!value.trim()) return

        this.ticketNumbers.push(value)
        this.setState({ticketNumber: this.ticketNumbers})
        event.target.value = ''
    }  else {
        this.setState({showMaxTicketError: true})
        event.preventDefault(); 
        } 
    }

    removeTicket = (index) => {
        this.setState({ticketNumber : this.state.ticketNumber.filter((element, ind) => ind !== index)})
        this.ticketNumbers = this.ticketNumbers.filter((element, ind) => ind !== index)
    }

    private getGridConfig(): GridOptions {
        const {
            searchTicketsStore: { DuplicateList, agGridService }
        } = this.props;
        const rowData = DuplicateList;
        const columnDefs = searchTicketsStore.getColDef();
        const onFilterChanged = this.handleFilterChange;
        const onCellClicked = this.onCellclicked;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged,
            onCellClicked,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(broadcastGridParams);
    }

    handleFilterChange = (event: FilterChangedEvent): void => {
        localStorageService.set('SearchTickets_filter', agGridService.getAllFilters());
    };

    private handleCarrierPopUpClose = () => {
        this.props.searchTicketsStore.hideCarrierPopUp();
    };

    private handleCarrierChange = (comment: string) => {
        this.props.searchTicketsStore.updateCarrierCompany(comment);
        console.log(comment);
        this.props.searchTicketsStore.agGridService.refreshGrid();
    };

    onCellclicked = (event: CellClickedEvent): void => {
        const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                searchTicketsStore: { cellValueChangeMap, updateRow }
            } = this.props;
            console.log(event.column.getColId())
            if (event.column.getColId() === SearchTicketFieldName.LEASEOPERATOR) {
                this.data = searchTicketsStore.companyData;
                searchTicketsStore.showCarrierPopUp()
                searchTicketsStore.approvedCommentTicketModel = event.data
                searchTicketsStore.approvedColumn = SearchTicketFieldName.LEASEOPERATOR
            }
            if (event.column.getColId() === SearchTicketFieldName.ORIGIN) {
                this.data = searchTicketsStore.locationData;
                searchTicketsStore.showLocationPopUp()
                searchTicketsStore.approvedCommentTicketModel = event.data
                searchTicketsStore.approvedColumn = SearchTicketFieldName.ORIGIN
            }
            if (event.column.getColId() === SearchTicketFieldName.DESTINATION) {
                this.data = searchTicketsStore.locationData;
                searchTicketsStore.showLocationPopUp()
                searchTicketsStore.approvedCommentTicketModel = event.data
                searchTicketsStore.approvedColumn = SearchTicketFieldName.DESTINATION
            }
            if (event.column.getColId() === SearchTicketFieldName.TRAILERNUMBER) {
                this.data = searchTicketsStore.trailerData;
                searchTicketsStore.showTrailerPopUp()
                searchTicketsStore.approvedCommentTicketModel = event.data
                searchTicketsStore.approvedColumn = SearchTicketFieldName.TRAILERNUMBER
            }
            if (event.column.getColId() === SearchTicketFieldName.DRIVERNAME) {
                this.data = searchTicketsStore.driverData;
                searchTicketsStore.showDriverPopUp()
                searchTicketsStore.approvedCommentTicketModel = event.data
                searchTicketsStore.approvedColumn = SearchTicketFieldName.DRIVERNAME
            }
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            searchTicketsStore: { cellValueChangeMap }
        } = this.props;
        console.log(row, col)
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
            console.log(event.column.getColId())
        if (!isNotEmpty) {
            searchTicketsStore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                searchTicketsStore: { cellValueChangeMap, updateRow }
            } = this.props;
            console.log(event.column.getColId())
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
        } catch (err) {
            console.log(err);
        }
    };
}
