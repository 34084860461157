export enum FilterKeyForReferenceData {
    TrailerManagement = 'TrailerManagement',
    DriverManagement = 'DriverManagement',
    TractorManagement = 'TractorManagement',
    CompanyManagement = 'CompanyManagement',
    ShopManagement = 'ShopManagement',
    TabletManagement = 'TabletManagement',
    PrinterManagement = 'PrinterManagement',
    RegionMapping = 'RegionMapping',
    LocationManagement = 'LocationManagement',
    LocationMaping = 'LocationMaping',
    KeyFobManagement = 'KeyFobManagement',
    Select = 'Select'
}

export enum TrailerMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Trailer data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    FAILED_CREATE_MAINTENANCE = 'Failed to create scheduled maintenance'
}

export enum TrailerHeaderName {
    ID = 'ID',
    AXID = 'AXID',
    TRAILERNUMBER = 'Trailer Number',
    STATUS = 'Status',
    STATUSID = 'StatusID',
    OWNERSHIPTYPE = 'Ownership Type',
    OWNERSHIPTYPEID = 'Ownership Type ID',
    CATEGORYTYPE = 'Category Type',
    CATEGORYTYPEID = 'Category Type ID',
    YEAR = 'Year',
    TRACKERNUMBER = 'Tracker Number',
    AXLES = 'Axles',
    CARRIER = 'Carrier Company',
    COMMENTS = 'Comments',
    REGION = 'Region',
    BOOKINGDATE = 'Booking Date',
    SHOPNAME = 'Shop Name',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    TANKSPECIFICATION = 'Tank Specification',
    TANKSPECIFICATIONID = 'Tank Specification ID',
    MANUFACTURER = 'Manufacturer',
    VIN = 'VIN',
    REGISTRATION = 'Registration',
    SELECT = 'Select',
    TRAILERTYPE = 'Trailer Type',
    WATERCAPACITYLEAD = 'Water Capacity Lead(L)',
    WATERCAPACITYPUP = 'Water Capacity Pup(L)',
    DRIPLESSADAPTERSIDE = 'Dripless Adapter Side',
    OPTICALPLUG = 'Optical Plug',
    SKULLY = 'Skully',
    SCRUBBER = 'Scrubber',
    TRIDRIVECOMPATIBLE = 'Tri-Drive Compatible',
    PUMPS = 'No. of Pumps',
    LANDMARKNAME = 'Landmark Name',
    LANDMARKTYPE = 'Landmark Type',
    MAINTENANCEFLEET = 'Maintenance Fleet',
    Acheck= 'A-check',
    CVIP= 'CVIP',
    V= 'V',
    K= 'K',
    I= 'I',
    P= 'P',
    UC= 'U/C',
    OTES = 'OTES',
    OTESTRIGGER = 'OTES Required',
    TRAILERMAPPEDTOCOMPANY = 'Trailer Mapped To Company',
    MISMATCH = 'Trailer Mapping Matched',
    MAINTENANCEID = 'Maintenance ID',
    HOSERACK = 'Hose Rack'
}

export enum TrailerFieldName {
    ID = 'ID',
    AXID = 'AXID',
    TRAILERNUMBER = 'TrailerNumber',
    STATUS = 'Status',
    STATUSID = 'StatusID',
    OWNERSHIPTYPE = 'OwnershipType',
    OWNERSHIPTYPEID = 'OwnershipTypeID',
    CATEGORYTYPE = 'CategoryType',
    CATEGORYTYPEID = 'CategoryTypeID',
    YEAR = 'Year',
    TRACKERNUMBER = 'TrackerNumber',
    AXLES = 'Model',
    CARRIER = 'CompanyName',
    COMMENTS = 'Comments',
    REGION = 'RegionName',
    BOOKINGDATE = 'BookingDate',
    SHOPNAME = 'ShopName',
    MODIFIEDBY = 'ModifiedByUserName',
    MODIFIEDDATE = 'ModifiedDate',
    TANKSPECIFICATION = 'TankSpecificationValue',
    TANKSPECIFICATIONID = 'TankSpecificationID',
    MANUFACTURER = 'Manufacturer',
    VIN = 'VIN',
    REGISTRATION = 'Registration',
    SELECT = 'Select',
    TRAILERTYPE = 'TGITrailerType',
    WATERCAPACITYLEAD = 'WaterCapacityLead',
    WATERCAPACITYPUP = 'WaterCapacityPup',
    DRIPLESSADAPTERSIDE = 'DriplessAdapter',
    OPTICALPLUG = 'OpticalPlug',
    SKULLY = 'Skully',
    SCRUBBER = 'Scrubber',
    TRIDRIVECOMPATIBLE = 'TriDriveCompatible',
    PUMPS = 'NumberOfPumps',
    LANDMARKNAME = 'LandmarkName',
    LANDMARKTYPE = 'LandmarkType',
    MAINTENANCEFLEET = 'MaintenanceFleet',
    Acheck= 'Acheck_Value',
    CVIP = 'CVIP_Value',
    V = 'V_Value',
    K = 'K_Value',
    I = 'I_Value',
    P = 'P_Value',
    UC = 'UC_Value',
    OTES = 'OTES_Value',
    OTESTRIGGER = 'OTESTrigger',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    MAINTENANCEID = 'MaintenanceID',
    HOSERACK = 'HoseRack'
}

export enum TrailerManagementStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    DONT_REHIRE = 'Do Not Rehire',
    INACTIVE_INSHOP = 'InActive-InShop',
    MAINTENANCECOMPLETE = 'Maintenance Complete',
    INACTIVE_PARKED = 'InActive-Parked',
    INACTIVE_PARKEDOUTOF = 'InActive-Parked-OutOfService',
    ACTIVE_PAPERWORK = 'Active-PendingPaperwork'
}

export const DriplessAdapterSideTypes = [
    {Name: 'Passenger'},
    {Name: 'Driver'},
    {Name: 'Both'},
    {Name: 'N/A'}
]
export const OpticalPlugTypes = [
    {Name: 'Yes'},
    {Name: 'No'}
]
export const SkullyTypes = [
    {Name: 'Yes'},
    {Name: 'No'}
]
export const TriDriveCompatibleTypes = [
    {Name: 'Yes'},
    {Name: 'No'}
]
export const PumpsTypes = [
    {Name: 1},
    {Name: 2},
    {Name: 'N/A'}
]
export const MaintenanceFleetTypes = [
    {Name: 'Yes'},
    {Name: 'No'}
]
export const RegionData = [
    { Value: 'North East', ID: '1', Name: 'North East' },
    { Value: 'North West', ID: '2', Name: 'North West' },
    { Value: 'South East', ID: '3', Name: 'South East' },
    { Value: 'South West', ID: '4', Name: 'South West' }
];