import { CompanyFieldName, CompanyHeaderName } from './company-enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from 'features/common/stores';

const createdDate = {
    headerName: CompanyHeaderName.CREATEDDATE,
    field: CompanyFieldName.CREATEDDATE,
    colId: CompanyFieldName.CREATEDDATE,
    width: 105,
    resizable: true,
    valueGetter: params => {
        if (params.data[CompanyFieldName.CREATEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[CompanyFieldName.CREATEDDATE]);
        }
    }
};

export const companyColDef: ColDef[] = [
    { headerName: CompanyHeaderName.ID, field: CompanyFieldName.ID, hide: true },
    {
        headerName: CompanyHeaderName.VENDORNUMBER,
        field: CompanyFieldName.AccountNumber,
        width: 75,
        resizable: true,
        valueGetter: params => {
            if (params.data[CompanyFieldName.AccountNumber] === null) {
                return '';
            } else {
                return params.data[CompanyFieldName.AccountNumber];
            }
        }
    },
    {
        headerName: CompanyHeaderName.VENDORNAME,
        field: CompanyFieldName.VENDORNAME,
        width: 105,
        valueGetter: params => {
            if (params.data[CompanyFieldName.VENDORNAME] === null) {
                return '';
            } else {
                return params.data[CompanyFieldName.VENDORNAME];
            }
        },
        resizable: true
    },
    {
        headerName: CompanyHeaderName.REGION,
        field: CompanyFieldName.REGION,
        width: 70,
        valueGetter: params => {
            if (params.data[CompanyFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[CompanyFieldName.REGION];
            }
        },
        resizable: true
    },
    {
        headerName: CompanyHeaderName.RAVENDORNUMBER,
        field: CompanyFieldName.RAVENDORNUMBER,
        colId: CompanyFieldName.RAVENDORNUMBER,
        width: 90,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[CompanyFieldName.RAVENDORNUMBER] === null) {
                return '';
            } else {
                return params.data[CompanyFieldName.RAVENDORNUMBER];
            }
        }
    },
    {
        headerName: CompanyHeaderName.RAVENDORNAME,
        field: CompanyFieldName.RAVENDORNAME,
        colId: CompanyFieldName.RAVENDORNAME,
        width: 105,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[CompanyFieldName.RAVENDORNAME] === null) {
                return '';
            } else {
                return params.data[CompanyFieldName.RAVENDORNAME];
            }
        }
    },
    createdDate
];
