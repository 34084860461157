export const dispatchSelectOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'Nomination', label: 'Nomination Workbench' },
    { value: 'DispatchPlan', label: 'Dispatch Plan' },
    { value: 'CapacityPlan', label: 'Capacity Plan' },
    { value: 'Communication', label: 'Communication' },
    { value: 'DispatchScreen', label: 'Dispatch Board' },
    // { value: 'DispatchBoard', label: 'Dispatch Board' },
    { value: 'Apportionment', label: 'Apportionment' }
];

export enum DispatchComponentName {
    Select = 'Select',
    Communication = 'Communication',
    Nomination = 'Nomination',
    CapacityPlan = 'CapacityPlan',
    DispatchBoard = 'DispatchBoard',
    DispatchPlan = 'DispatchPlan',
    Apportionment = 'Apportionment',
    NominationWorkbench = 'NominationWorkbench',
    DispatchScreen = 'DispatchScreen'
}
