import React, { Component } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import './_numeric-cell-renderer.scss';
import { dataStore } from 'features/common/stores';

interface NumericCellRendererState {
    value: string;
}

interface NumericCellRendererParams extends ICellRendererParams {
    onChange: (data, value) => void;
    isReadonly: (data, value) => boolean;
    thousandSeparator: boolean | string;
    decimalScale: number;
    mask: string | string[];
    format: string;
    isAllowed: (values) => boolean;
}

export class NumericCellRenderer extends Component<NumericCellRendererParams, NumericCellRendererState> {
    constructor(props: NumericCellRendererParams) {
        super(props);
        this.state = {
            value: props.value ? props.value : ''
        };
    }

    static defaultProps = {
        isReadonly: (data, value) => !dataStore.checkOperationAccess('Save'),
        isAllowed: values => true,
        thousandSeparator: false,
        decimalScale: 0,
        mask: '',
        format: ''
    };

    handleChange = (number: NumberFormatValues): void => {
        this.setState({ value: number.value });
    };

    handleBlur = (): void => {
        const { onChange } = this.props;
        onChange(this.props.data, this.state.value);
    };

    render(): React.ReactNode {
        const { value } = this.state;
        const { isReadonly, data, thousandSeparator, format, mask, decimalScale, isAllowed } = this.props;
        const props = format ? { format } : {};
        const readonly = isReadonly(data, value);
        if (readonly) {
            return <div>{value}</div>;
        }

        return (
            <div className="numeric-cell">
                <NumberFormat
                    onBlur={this.handleBlur}
                    onFocus={this.handleBlur}
                    decimalScale={decimalScale}
                    value={value}
                    onValueChange={this.handleChange}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={'.'}
                    allowNegative={false}
                    mask={mask}
                    readOnly={readonly}
                    {...props}
                    isAllowed={isAllowed}
                />
            </div>
        );
    }
}
