

export enum KeyfobMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Keyfobs data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    DELETEDSUCCESS = 'Deleted Successfully',
    DELETEDERROR = 'Failed to delete records',
    NO_ROW_SELECTED = 'No record selected'

}

export enum KeyfobHeaderName {
    ID = 'Key Fob ID',
    KEYFOBNUMBER = 'Key Fob Number',
    LOCATION = 'Location',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    CREATEDBY = 'Created By',
    CREATEDDATE = 'Created Date',
    DELETE = 'Delete',
    CARRIERCOMPANY = 'Carrier Company',
    ASSIGNEDDATE = 'Assigned Date',
    STATUS = 'Status'
}

export enum KeyfobFieldName {
    ID = 'ID',
    MODIFIEDBY = 'ModifiedByUser',
    MODIFIEDDATE = 'ModifiedDate',
    CREATEDBY = 'CreatedByUser',
    CREATEDDATE = 'CreatedDate',
    KEYFOBNUMBER = 'KeyFobNumber',
    LOCATION = 'Location',
    STATUS = 'Status',
    DELETE = 'IsDeleted',
    CARRIERCOMPANY = 'CarrierCompany',
    ASSIGNEDDATE = 'AssignedDate'
}