import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import {ShopResponse, ShopViewModel, ShopStatus} from '../domains/shop-model';
import { SHOP_STATUS } from '../data_constant';

export class ShopDataService {
    constructor(private restApi: RestApiService) { }

    private getShopData(): Promise<ResponseDataType<ShopResponse[]>> {
        return this.restApi.postWithToken<ShopResponse[]>(Config.getShopInfoList);
    }

    private getShopStatusValues(): Promise<ResponseDataType<ShopStatus[]>> {
        return this.restApi.postWithToken<ShopStatus[]>(Config.getBaseValueByClass, { CODE: SHOP_STATUS });
    }
   
    async getShopViewModel(): Promise<ShopViewModel> {
        const shopDataPromise = this.getShopData();
        const shopStatusValuesPromise = this.getShopStatusValues();
        const [
            { data: shopData },
            { data: shopStatusValues }
        ] = await Promise.all([shopDataPromise, shopStatusValuesPromise]);
        return {
            shopData,
            shopStatusValues
        };
    }

    async updateShops(reqbody: ShopResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateShopInfoList, reqbody, {});
        return data['Data'];
    }
}

export const shopDataService = new ShopDataService(restApiService);