import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { LeaseOperatorResponse, DateRange } from '../domains/model';

export class LeaseOperatorDataService {
    constructor(private restApi: RestApiService) {}

    async getDailyReport(): Promise<LeaseOperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<LeaseOperatorResponse[]>(Config.getLeaseOperator);
        return data['Data'];
    }
    async getDailyReportwithParams(reqbody: DateRange): Promise<LeaseOperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<LeaseOperatorResponse[]>(
            Config.getLeaseOperator,
            reqbody,
            {}
        );
        return data['Data'];
    }
    async sendReport(reqbody): Promise<void> {
        const { data } = await this.restApi.post<void>(Config.sendReport, reqbody, {});
        return data['Data'];
    }
}

export const leaseoperatorDataService = new LeaseOperatorDataService(restApiService);
