import { action, computed, observable, toJS } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AccountStore } from '../../../account/stores/account-store';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import {
    DispatchPlanMessage,
    DispatchPlanFieldName,
    DispatchPlanHeaderName,
    DispatchAllConstants
} from '../constants/enums';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dispatchPlanColDef, dispatchPlanCCColDef } from '../components/dispatchPlan-col-def';
import { DispatchPlanDataService } from '../services/dispatchPlan-data-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import {
    DispatchPlanResponse,
    TrailerResponse,
    PriorityType,
    UpdateDispatchPlanResponse,
    Region
} from '../model/model';
import { DriverResponse } from 'features/data_management/domains/driver-management/model';
import { dateUtils } from 'shared/services/date-utils';
import { DateRange } from 'shared/components/daypicketInput/react-daypickerInput';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';
import { INVALID_USERID } from 'features/data_management/data_constant';
import { CCUserRoles } from 'features/dispatch/nomination/nomination-constants';

export class DispatchPlanDataStore {
    @observable drivers: DriverResponse[] = [];
    @observable dispatchPlanData: DispatchPlanResponse[] = [];
    @observable modifiedDispatchPlanData: DispatchPlanResponse[] = [];
    @observable trailers: TrailerResponse[] = [];
    @observable cellValueChangeMap = {};
    @observable showLoader = false;
    @observable userInfo: any = {};
    userID = 0;
    userName = '';
    backupDispatchPlanData: DispatchPlanResponse[] = [];
    activeTrailerValues: any[] = [];
    activeDriverValues: any[] = [];
    activeCarrierValues: any[] = [];
    allActiveDriverValues: any[] = [];
    activeProrityValues: any[] = [];
    isRefreshed: boolean = false;
    @observable selectedDispatchPlanList: DispatchPlanResponse | undefined;
    @observable isCancelLoadEnabled = false;
    @observable isDispatchLoadButtonEnabled = false;
    @observable isRecallButtonEnabled = false;
    @observable isRejectOrAcceptButtonEnabled = false;
    @observable isCCRoleDispatchLoadButtonEnabled = false;
    @observable isCCRecallButtonEnabled = false;
    @observable showModal = false;
    @observable showDriverModal = false;
    @observable showTrailerModal = false;
    @observable showCarrierModal = false;
    @observable isCommentsPopUpVisible = false;
    @observable showCompleteTicketModal = false;
    @observable isCompleteButtonEnabled = false;
    approvedCommentTicketModel: any;
    approvedDriverModel: any;
    approvedColumn: any;
    buttonPressed: any;
    startDate: any = moment()
        .subtract(6, 'days')
        .toISOString()
        .slice(0, 10);
    endDate: any = moment()
        .toISOString()
        .slice(0, 10);
    startdate: any = '';
    enddate: any = '';
    @observable selectButtonText: any = 'Select All';
    @observable loadNumberSelected: any;
    @observable driverIdSelected: any;
    @observable trailerNumberSelected: any;
    @observable prioritySelected: any;
    @observable plannedDateTimeSelected: any;
    @observable dispatcherCommentSelected: any;
    hasLoadFilterChanged: boolean = false;
    @observable hasNoDataInFilter: boolean = false;
    @observable hasNoDataInGridFilter: boolean = false;
    @observable selectedRegion: any;
    @observable selectedProduct: any;
    @observable selectedLoadStatus: any;
    @observable dataToBeDeleted: any[] = [];
    checkBoxSelectionCount: any;
    plannedDate: any = moment(new Date());
    cancelDisableArray = [
        'Cancelled',
        'Manually Deleted ',
        'Completed',
        'Apportionment Deleted',
        'System Deleted',
        'System Cancelled',
        'Cancelled Pending'
    ];
    recallDisableArray = [
        'Cancelled',
        'Recalled',
        'Recalled By Carrier',
        'Cancelled By Driver',
        'Rejected By Carrier',
        'Cancelled By Carrier',
        'Manually Deleted ',
        'Completed',
        'Pending',
        'Loaded',
        'Rejected By Driver',
        'Apportionment Deleted',
        'System Deleted',
        'Recalled Pending'
    ];
    recalledEnableArray = [
        'Driver Dispatched',
        'Driver Accepted',
        'Enroute',
        'Dispatch Pending',
        'Carrier Assigned',
        'Carrier Accepted',
        'Recalled By Carrier'
    ];
    rejectOrAcceptEnableArray = ['Carrier Assigned'];
    rejectOrAcceptDisableArray = [
        'Driver Accepted',
        'Enroute',
        'Dispatch Pending',
        'Cancelled',
        'Manually Deleted ',
        'Completed',
        'Apportionment Deleted',
        'System Deleted',
        'System Cancelled',
        'Carrier Accepted',
        'Cancelled Pending',
        'Pending',
        'Driver Dispatched',
        'Recalled',
        'Recalled By Carrier',
        'Rejected By Driver',
        'Rejected By Carrier',
        'Cancelled By Carrier',
        'Cancelled By Driver',
        'Enroute',
        'Dispatch Pending',
        'Loaded',
        'Recalled Pending'
    ];
    cancelEnableArray = [
        'Pending',
        'Driver Dispatched',
        'Driver Accepted',
        'Recalled',
        'Recalled By Carrier',
        'Rejected By Driver',
        'Cancelled By Driver',
        'Enroute',
        'Dispatch Pending',
        'Loaded',
        'Recalled Pending',
        'Rejected By Carrier',
        'Cancelled By Carrier',
        'Carrier Assigned',
        'Carrier Accepted'
    ];
    dispatchLoadEnableArray = ['Pending', 'Recalled', 'Rejected By Driver', 'Cancelled By Driver', 'Rejected By Carrier', 'Cancelled By Carrier'];
    dispatchLoadDisableArray = [
        'Cancelled',
        'Driver Dispatched',
        'Driver Accepted',
        'Manually Deleted ',
        'Enroute',
        'Loaded',
        'Completed',
        'Apportionment Deleted',
        'System Deleted',
        'Recalled Pending',
        'Dispatch Pending',
        'Carrier Accepted',
        'Carrier Assigned',
    ];
    ccDispatchLoadEnableArray = [
        'Carrier Accepted',
        'Recalled By Carrier',
        'Rejected By Driver',
        'Cancelled By Driver'
    ];
    ccDispatchLoadDisableArray = [
        'Cancelled',
        'Driver Dispatched',
        'Driver Accepted',
        'Manually Deleted ',
        'Enroute',
        'Loaded',
        'Completed',
        'Apportionment Deleted',
        'System Deleted',
        'Carrier Assigned',
        'Dispatch Pending'
    ];
    ccRecallDisableArray = [
        'Carrier Assigned',
        'Carrier Accepted',
        'Loaded',
        'Completed',
        'Recalled By Carrier',
        'Cancelled By Driver',
        'Rejected By Driver',
        'Recalled Pending'
    ];
    completeEnableArray = ['Carrier Accepted']
    ccRecalledEnableArray = ['Driver Dispatched', 'Driver Accepted', 'Enroute', 'Dispatch Pending'];
    @observable bulkAssignData: any;
    @observable EstVolumeSum: any = 0;
    @observable baseValueDTO: Region[] = [];
    @observable selectedDispatchType = !this.checkCCLogin() ? 'Driver' : 'Carrier';
    isCCLogin = this.checkCCLogin();
    @observable unitsArray: any[] = [];
    @observable productArray: any[] = [];
    raConfig: any = [];

    constructor(
        private dispatchPlanDataService: DispatchPlanDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.isCCLogin = this.checkCCLogin();
        this.loadViewModel();
        this.userName = this.accountStore.displayName;
        this.selectedRegion = [];
        this.selectedProduct = 'All';
        this.selectedLoadStatus = 'All';
        this.selectedDispatchType = !this.checkCCLogin() ? 'Driver' : 'Carrier';
        this.getRegionData();
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    @Loader
    @action
    async getRegionData(): Promise<void> {
        const Region = localStorageService.get('region');
        try {
            if (Region) {
                this.baseValueDTO = Region as Region[];
            } else {
                await this.dispatchPlanDataService
                    .getRegionTypes()
                    .then(response => {
                        let responseData = response.data['Data'];
                        let sortedDataForNorthRegion = responseData
                            .filter(item => item.Name.includes('North'))
                            .sort((a, b) => a.ID > b.ID);
                        let sortedDataForSouthRegion = responseData
                            .filter(item => item.Name.includes('South'))
                            .sort((a, b) => a.ID > b.ID);
                        this.baseValueDTO = [...sortedDataForNorthRegion, ...sortedDataForSouthRegion];
                        localStorageService.set('region', this.baseValueDTO);
                    })
                    .catch(function(error) {
                        console.log('error in getting Region', error);
                    });
            }
            this.raConfig = await this.dispatchPlanDataService.getRAConfig();
        } catch (e) {
            localStorageService.remove('region');
            console.log('error in getting Region', e);
        }
        console.log(Region, 'region');
    }
    @Loader
    @Catch(() => errorHandler(DispatchPlanMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        this.startDate = moment().format('yyyy-MM-01');
        this.endDate = moment().format('yyyy-MM-' + moment().daysInMonth());
        this.showLoader = true;
        const cCUID = this.isCCLogin ? this.accountStore.getUserID() : null;
        const dispatchPlanData = await this.dispatchPlanDataService.getDispatchPlanResponse({ CCUID: cCUID });
        const dispatchPlanInfo: DispatchPlanResponse[] = dispatchPlanData.data['Data'];
        this.showLoader = false;
        const data: any = dispatchPlanInfo;
        if (!this.isCCLogin) {
            data.map(item => {
                item.selectedType = item.DispatchType;
                if (!item.selectedType && this.dispatchLoadEnableArray.includes(item.LoadStatus)) {
                    item.selectedType = 'Driver';
                }
            });
        }
        this.userInfo = this.accountStore.getUserInfo();
        this.modifiedDispatchPlanData = data;
        this.setDispatchPlanData(data);
        this.setBackupDispatchPlanData(data);
        this.getVolumeSum(dispatchPlanInfo);
        this.isRefreshed = false;
        this.isRejectOrAcceptButtonEnabled = false;
        this.isCompleteButtonEnabled = false;
    }

    async getAllDriversForBulkAssign() {
        uiService.loaderService.showLoader();
        const reqbody = {
            CC: this.checkCCLogin() ? this.userInfo.carrierCompanyId : null,
            StartDate: this.startDate,
            EndDate: this.endDate,
            RegionID: 'All',
            Month: ''
        };
        const driverData: any = await this.dispatchPlanDataService.getDriverResponse(reqbody);
        this.allActiveDriverValues = driverData['Data'];
    }

    async getAllCarrierCompanyForBulkAssign() {
        uiService.loaderService.showLoader();
        const driverData: any = await this.dispatchPlanDataService.getCarrierCompanyData();
        this.allActiveDriverValues = driverData['Data'];
    }
    async getDriverDataByPlannedDateTime(plannedDateTime, eventData) {
        const date = new Date(dateUtils.getFormattedDateTime(plannedDateTime));
        const month = date.toLocaleString('default', { month: 'long' });
        const year = plannedDateTime.substring(0, 4);
        let region: any = [];
        let selectedRegionArray: any = [];
        if (this.selectedRegion && this.selectedRegion.length > 0) {
            let selectedRegion = toJS(this.selectedRegion).map(x => x.label);
            for (let i = 0; i < this.selectedRegion.length; i++) {
                const getRegionName = this.baseValueDTO.find(x => x.Name === selectedRegion[i]);
                selectedRegionArray.push(toJS(getRegionName));
                region = selectedRegionArray.map(x => Number(x.ID));
            }
        }
        const reqbody = {
            CC: this.checkCCLogin() ? this.userInfo.carrierCompanyId : null,
            PlannedDate: dateUtils.getFormattedDateTime(plannedDateTime),
            RegionID: region && region.length > 0 ? region.toString() : 'All',
            Month: month,
            Year: year
        };
        uiService.loaderService.showLoader();
        const driverData = await this.dispatchPlanDataService.getDriverResponse(reqbody);
        this.activeDriverValues = driverData['Data'];
        uiService.loaderService.hideLoader();
        this.showDriverApproverPopUp();
        this.approvedCommentTicketModel = eventData;
        this.approvedColumn = DispatchPlanFieldName.DRIVERNAME;
    }

    async getCarrierDataByPlannedDateTime(plannedDateTime, eventData) {
        uiService.loaderService.showLoader();
        const carrierData = await this.dispatchPlanDataService.getCarrierCompanyData();
        const raFlag = this.raConfig.filter((item: any) => item.Code === 'RAIntegration');

        if (raFlag[0]?.Value === 'RA') {
            this.activeCarrierValues = carrierData['Data'].filter(a => !a.IsDeleted && a.RACompanyName != null);
            this.activeCarrierValues.map(data => {
                data.CompanyName = data.RACompanyName;
                data.AccountNumber = data.RAAccountNumber;
            })
        } else {
            this.activeCarrierValues = carrierData['Data'].filter(a => !a.IsDeleted)
        }
        //this.activeCarrierValues = carrierData['Data'];
        uiService.loaderService.hideLoader();
        this.showCarrierApproverPopUp();
        this.approvedCommentTicketModel = eventData;
        this.approvedColumn = DispatchPlanFieldName.CARRIERNAME;
    }

    async handleRefresh(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const cCUID = this.isCCLogin ? this.accountStore.getUserID() : null;
        const reqbody = {
            StartDate: startDate,
            EndDate: endDate,
            CCUID: cCUID
        };
        // this.dispatchPlanData = [];
        this.selectButtonText = 'Select All';
        uiService.loaderService.showLoader();
        const dispatchPlanData = await this.dispatchPlanDataService.getDispatchPlanDataWithDateParams(reqbody);
        const dispatchPlan: DispatchPlanResponse[] = dispatchPlanData.data['Data'] ? dispatchPlanData.data['Data'] : [];
        uiService.loaderService.hideLoader();
        const data: any = dispatchPlan;
        if (!this.isCCLogin) {
            data.map(item => {
                item.selectedType = item.DispatchType;
                if (!item.selectedType && this.dispatchLoadEnableArray.includes(item.LoadStatus)) {
                    item.selectedType = 'Driver';
                }
            });
        }
        this.modifiedDispatchPlanData = data;
        this.setBackupDispatchPlanData(data);
        this.getVolumeSum(data);
        this.filterDataBasedOnSelection(this.selectedRegion, this.selectedLoadStatus, this.selectedProduct);
        this.isDispatchLoadButtonEnabled = false;
        this.isCancelLoadEnabled = false;
        this.isRecallButtonEnabled = false;
        this.isRejectOrAcceptButtonEnabled = false;
        this.isCompleteButtonEnabled = false;
        this.isCCRoleDispatchLoadButtonEnabled = false;
        this.selectedDispatchType = !this.isCCLogin ? 'Driver' : 'Carrier';
    }

    async getDriverTrailerData() {
        const { trailerData } = await this.dispatchPlanDataService.getDispatchPlanModel();
        const trailerInfo: TrailerResponse[] = trailerData['Data'];
        this.activeTrailerValues = trailerInfo.filter(
            a => a.Status === 'Active' || a.Status === 'Active-PendingPaperwork'
        );
        this.activeProrityValues = PriorityType;
        this.addValuesInCellDropdowns();
    }

    getColDef() {
        const isCC = this.checkCCLogin();
        if (isCC) {
            this.updateCarrierCommentColDef();
            return dispatchPlanCCColDef;
        } else {
            this.updateCommentColDef();
            return dispatchPlanColDef;
        }
    }

    @action
    reset(): void {
        this.setDispatchPlanData(this.backupDispatchPlanData);
        this.modifiedDispatchPlanData = this.backupDispatchPlanData;
        this.filterDataBasedOnSelection(this.selectedRegion, this.selectedLoadStatus, this.selectedProduct);
        this.selectedDispatchType = !this.isCCLogin ? 'Driver' : 'Carrier';
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setDispatchPlanData(dispatchPlanData: DispatchPlanResponse[]): void {
        this.dispatchPlanData = dispatchPlanData;
    }

    setBackupDispatchPlanData(backUpList: DispatchPlanResponse[]) {
        this.backupDispatchPlanData = backUpList;
    }

    @computed
    get DispatchPlanData(): DispatchPlanResponse[] {
        return toJS(this.dispatchPlanData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @action
    showDriverApproverPopUp() {
        this.showDriverModal = true;
    }

    @action
    showCarrierApproverPopUp() {
        this.showCarrierModal = true;
    }

    @action
    hideCarrierApproverPopUp() {
        this.showCarrierModal = false;
    }

    @action
    hideDriverApproverPopUp() {
        this.showDriverModal = false;
    }

    @action
    showTrailerApproverPopUp() {
        this.showTrailerModal = true;
    }

    @action
    hideTrailerApproverPopUp() {
        this.showTrailerModal = false;
    }

    @Loader
    @action
    async showCompleteTicketPopUp() {
        this.productArray = [];
        this.unitsArray = [];
        const unitsList = await this.dispatchPlanDataService.getUOM();
        const productList = await this.dispatchPlanDataService.getProduct();
        productList.forEach(list => {
            if (!list.IsDeleted)
            this.productArray.push({value: list.ID, label: list.ProductDescription})
        });
        unitsList.forEach(list => {
            if (list.Uom !== 'yd3')
            this.unitsArray.push({value: list.ID, label: list.Uom})
        });
        //this.unitsArray = unitsList //Array.from(new Set(unitsList.map((item: any) => item.Description)));
        //this.productArray = productList //Array.from(new Set(productList.map((item: any) => item.Description)));
        this.showCompleteTicketModal = true;
    }

    @action
    hideCompleteTicketPopUp() {
        this.showCompleteTicketModal = false;
    }

    @action
    isDriverOrCarreerSelected(dispatchType) {
        const data = this.dispatchPlanData;
        data.map(item => {
            item.selectedType = item.DispatchType;
            if (
                !item.selectedType ||
                (item.selectedType &&
                    item.selectedType !== dispatchType &&
                    this.dispatchLoadEnableArray.includes(item.LoadStatus))
            ) {
                item.selectedType = dispatchType;
            }
        });
        this.setDispatchPlanData(data);
        this.selectedDispatchType = dispatchType;
    }

    triggerModal(item, col) {
        this.approvedCommentTicketModel = item;
        this.approvedColumn = col;
        this.showTicketApproverPopUp();
    }

    updatePlannedDateTime(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updatePlannedDateTimeInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    updateDriver(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateDriverInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    updateCarrier(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateCarrierInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    updateTrailer(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateTrailerInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    @Loader
    @action
    @Catch(() => errorHandler(DispatchPlanMessage.FAILED_SUBMIT))
    async updateDispatchPlanData(): Promise<void> {
        const updatedRowIDs = toJS(this.dataToBeDeleted);
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        let requestList: UpdateDispatchPlanResponse[] = [];
        let driverID: any;
        let carrierID: any;
        updatedRowIDs.forEach(async item => {
            const {
                LoadNumber,
                ID,
                DriverName,
                PlannedDateTime,
                Priority,
                DispatcherComments,
                TrailerNumber,
                DriverID,
                DispatcherNotes,
                CarrierID,
                CarrierName,
                CarrierComments
            } = item;
            let selectedType = item.selectedType ? item.selectedType : item.DispatchType;
            const planDateTime = moment(new Date(PlannedDateTime).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss');
            if (
                this.buttonPressed !== 'Cancel' &&
                (this.isCCLogin
                    ? selectedType === 'Carrier' && !DriverName && this.buttonPressed === 'DispatchLoad'
                    : !DriverName && !CarrierName)
            ) {
                await this.getRangeResult(this.startDate, this.endDate);
                this.uiService.toastService.error(
                    this.isCCLogin ? DispatchAllConstants.NO_DRIVER : DispatchAllConstants.NO_DRIVER_CARRIER
                );
                return;
            } else {
                const updatedTrailerIndex = this.activeDriverValues.findIndex(a => a.DriverName === DriverName);
                if (updatedTrailerIndex > -1) {
                    driverID = this.activeDriverValues[updatedTrailerIndex].DriverID;
                } else {
                    driverID = DriverID ? DriverID : null;
                }
                const updatedcarrierIndex = this.activeCarrierValues.findIndex(a => a.CompanyName === CarrierName);
                if (updatedcarrierIndex > -1) {
                    carrierID = this.activeCarrierValues[updatedcarrierIndex].ID;
                } else {
                    carrierID = CarrierID ? CarrierID : null;
                }
            }
            const updatedData = toJS(this.cellValueChangeMap);
            if (this.getUpdatedRowIDs().includes(item.ID.toString())) {
                if (!updatedData[item.ID]['DispatcherComments'] && !updatedData[item.ID]['CarrierComments']) {
                    requestList = [
                        ...requestList,
                        {
                            DriverID: driverID,
                            PlannedDateTime: PlannedDateTime ? planDateTime : '',
                            Priority: Priority,
                            LoadNumber: LoadNumber,
                            ModifiedBy: userID,
                            ButtonName:
                                this.buttonPressed === 'DispatchLoad' && !this.isCCLogin
                                    ? selectedType === 'Driver'
                                        ? this.buttonPressed
                                        : 'CarrierDispatchLoad'
                                    : this.buttonPressed,
                            DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                            TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                            IsAdhoc: false,
                            DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                            CarrierID:
                                (carrierID == 0 || carrierID == null)
                                    ? this.checkCCLogin()
                                        ? this.accountStore.getUserInfo()?.carrierCompanyId
                                        : carrierID
                                    : carrierID
                        }
                    ];
                } else {
                    if (updatedData[item.ID]['DispatcherComments']) {
                        requestList = [
                            ...requestList,
                            {
                                DriverID: driverID,
                                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                                Priority: Priority,
                                DispatcherComments: DispatcherComments ? DispatcherComments : '',
                                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                                LoadNumber: LoadNumber,
                                ModifiedBy: userID,
                                ButtonName:
                                    this.buttonPressed === 'DispatchLoad' && !this.isCCLogin
                                        ? selectedType === 'Driver'
                                            ? this.buttonPressed
                                            : 'CarrierDispatchLoad'
                                        : this.buttonPressed,
                                TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                                IsAdhoc: false,
                                DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                                CarrierID:
                                    (carrierID == 0 || carrierID == null)
                                        ? this.checkCCLogin()
                                            ? this.accountStore.getUserInfo()?.carrierCompanyId
                                            : carrierID
                                        : carrierID
                            }
                        ];
                    } else if (updatedData[item.ID]['CarrierComments']) {
                        requestList = [
                            ...requestList,
                            {
                                DriverID: driverID,
                                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                                Priority: Priority,
                                CarrierComments: CarrierComments ? CarrierComments : '',
                                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                                LoadNumber: LoadNumber,
                                ModifiedBy: userID,
                                ButtonName:
                                    this.buttonPressed === 'DispatchLoad' && !this.isCCLogin
                                        ? selectedType === 'Driver'
                                            ? this.buttonPressed
                                            : 'CarrierDispatchLoad'
                                        : this.buttonPressed,
                                TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                                IsAdhoc: false,
                                DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                                CarrierID:
                                    (carrierID == 0 || carrierID == null)
                                        ? this.checkCCLogin()
                                            ? this.accountStore.getUserInfo()?.carrierCompanyId
                                            : carrierID
                                        : carrierID
                            }
                        ];
                    }
                }
            } else {
                requestList = [
                    ...requestList,
                    {
                        DriverID: driverID,
                        PlannedDateTime: PlannedDateTime ? planDateTime : '',
                        Priority: Priority,
                        DispatcherComments: DispatcherComments ? DispatcherComments : '',
                        DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                        LoadNumber: LoadNumber,
                        ModifiedBy: userID,
                        ButtonName:
                            this.buttonPressed === 'DispatchLoad' && !this.isCCLogin
                                ? selectedType === 'Driver'
                                    ? this.buttonPressed
                                    : 'CarrierDispatchLoad'
                                : this.buttonPressed,
                        TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                        IsAdhoc: false,
                        DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                        CarrierID:
                            (carrierID == 0 || carrierID == null)
                                ? this.checkCCLogin()
                                    ? this.accountStore.getUserInfo()?.carrierCompanyId
                                    : carrierID
                                : carrierID,
                        CarrierComments: CarrierComments ? CarrierComments : ''
                    }
                ];
            }
        });
        const errorInSave = false;
        const errorSummary: ToastMessage[] = [];
        const request = {
            ModifiedBy: userID,
            IsAdhoc: false,
            dispatchPlanRequest: requestList
        };
        const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(request);
        this.cellValueChangeMap = {};
        await this.getRangeResult(this.startDate, this.endDate);
        this.selectedDispatchPlanList = undefined;
        this.dataToBeDeleted = [];
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.dispatchPlanData.length; i++) {
                    const updatedShop = this.dispatchPlanData[i];
                    if (updatedShop.ID.toString() === errorMessage.id) {
                        const backupShopIndex = this.backupDispatchPlanData.findIndex(
                            a => a.ID.toString() == errorMessage.id
                        );
                        this.mapEditableColumns(updatedShop, this.backupDispatchPlanData[backupShopIndex]);
                        break;
                    }
                }
            });
        } else {
            if (response['StatusCode'] === 422) {
                this.uiService.toastService.error(response['Message']);
            } else if (requestList.length !== 0) {
                this.uiService.toastService.success(DispatchPlanMessage.SAVE);
                this.dataToBeDeleted = [];
            }
        }
        this.selectButtonText = 'Select All';
    }
    @Loader
    @action
    @Catch(() => errorHandler(DispatchPlanMessage.FAILED_SUBMIT))
    async saveDispatchPlanData(): Promise<void> {
        const updatedRowIDs = this.dispatchPlanData.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        const requestBody = this.updateDispatchPlanRequest(updatedRowIDs);
        if (requestBody.length !== 0) {
            const errorInSave = false;
            const errorSummary: ToastMessage[] = [];
            const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(requestBody);
            this.cellValueChangeMap = {};
            await this.getRangeResult(this.startDate, this.endDate);
            this.dataToBeDeleted = [];
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.dispatchPlanData.length; i++) {
                        const updatedShop = this.dispatchPlanData[i];
                        if (updatedShop.ID.toString() === errorMessage.id) {
                            const backupShopIndex = this.backupDispatchPlanData.findIndex(
                                a => a.ID.toString() == errorMessage.id
                            );
                            this.mapEditableColumns(updatedShop, this.backupDispatchPlanData[backupShopIndex]);
                            break;
                        }
                    }
                });
            } else {
                if (response['StatusCode'] === 422) {
                    this.uiService.toastService.error(response['Message']);
                } else this.uiService.toastService.success(DispatchPlanMessage.SAVE);
            }
            this.selectButtonText = 'Select All';
        }
    }

    @Loader
    @action
    @Catch(()=> errorHandler(DispatchPlanMessage.FAILED_SUBMIT))
    async saveCompleteTicket(data) {
        const updatedRowIDs = toJS(this.dataToBeDeleted);
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        let requestList: UpdateDispatchPlanResponse[] = [];
        updatedRowIDs.forEach(async item => {
            const {
                LoadNumber,
                ID,
                DriverName,
                PlannedDateTime,
                Priority,
                DispatcherComments,
                TrailerNumber,
                DriverID,
                DispatchType,
                DispatcherNotes,
                CarrierID,
                CarrierName,
                CarrierComments
            } = item;
            requestList = [
                ...requestList,
                {
                    DriverID: DriverID,
                    PlannedDateTime: PlannedDateTime,
                    Priority: Priority,
                    DispatcherComments: DispatcherComments ? DispatcherComments : '',
                    DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                    LoadNumber: LoadNumber,
                    ModifiedBy: userID,
                    ButtonName: 'Complete',
                    TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                    IsAdhoc: false,
                    DispatchType: DispatchType,
                    CarrierID: CarrierID,
                    CarrierComments: CarrierComments ? CarrierComments : ''
                }
            ];
        })
        const request = {
            ModifiedBy: userID,
            IsAdhoc: false,
            dispatchPlanRequest: requestList,
            ThirdPartyRequestDTO: data
        };
        const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(request);
        this.cellValueChangeMap = {};
        await this.getRangeResult(this.startDate, this.endDate);
        this.selectedDispatchPlanList = undefined;
        this.dataToBeDeleted = [];
        this.isCompleteButtonEnabled = false;
        this.selectButtonText = 'Select All';
    }

    updateDispatchPlanRequest(updatedRows: DispatchPlanResponse[]): UpdateDispatchPlanResponse[] {
        let requestList: UpdateDispatchPlanResponse[] = [];
        let driverID: any = 0;
        let carrierID: any = 0;
        let userID = this.accountStore.getUserID();
        let request: any;
        updatedRows.forEach(async item => {
            const {
                LoadNumber,
                ID,
                DriverName,
                PlannedDateTime,
                Priority,
                DispatcherComments,
                TrailerNumber,
                DriverID,
                DispatcherNotes,
                CarrierName,
                CarrierID,
                CarrierComments
            } = item;
            let loadsArray: any = [
                'Recalled',
                'Rejected By Driver',
                'Rejected By Carrier',
                'Cancelled By Carrier',
                'Cancelled By Driver'
            ];
            let selectedType = loadsArray.includes(item.LoadStatus)
                ? this.selectedDispatchType
                : item.selectedType
                ? item.selectedType
                : item.DispatchType;
            const planDateTime = moment(new Date(PlannedDateTime).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss');
            if (
                this.buttonPressed != 'SaveData' &&
                (this.isCCLogin
                    ? selectedType === 'Carrier' && !DriverName && this.buttonPressed === 'DispatchLoad'
                    : !DriverName && !CarrierName)
            ) {
                await this.getRangeResult(this.startDate, this.endDate);
                this.uiService.toastService.error(
                    this.isCCLogin ? DispatchAllConstants.NO_DRIVER : DispatchAllConstants.NO_DRIVER_CARRIER
                );
                return;
            } else {
                const updatedTrailerIndex = this.activeDriverValues.findIndex(a => a.DriverName === DriverName);
                if (updatedTrailerIndex > -1) {
                    driverID = this.activeDriverValues[updatedTrailerIndex].DriverID;
                } else {
                    driverID = DriverID ? DriverID : null;
                }
                const updatedCarrierIndex = this.activeCarrierValues.findIndex(a => a.CompanyName === CarrierName);
                if (updatedCarrierIndex > -1) {
                    carrierID = this.activeCarrierValues[updatedCarrierIndex].ID;
                } else {
                    carrierID = CarrierID ? CarrierID : null;
                }
            }

            const updatedData = toJS(this.cellValueChangeMap);
            if (this.getUpdatedRowIDs().includes(item.ID.toString())) {
                if (!updatedData[item.ID]['DispatcherComments'] && !updatedData[item.ID]['CarrierComments']) {
                    requestList = [
                        ...requestList,
                        {
                            DriverID: driverID,
                            PlannedDateTime: PlannedDateTime ? planDateTime : '',
                            Priority: Priority,
                            LoadNumber: LoadNumber,
                            ModifiedBy: userID,
                            ButtonName:
                                this.buttonPressed === 'DispatchLoad'
                                    ? selectedType === 'Driver'
                                        ? this.buttonPressed
                                        : 'CarrierDispatchLoad'
                                    : this.buttonPressed,
                            TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                            DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                            IsAdhoc: false,
                            DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                            CarrierID:
                                (carrierID == 0 || carrierID == null)
                                    ? this.checkCCLogin()
                                        ? this.accountStore.getUserInfo()?.carrierCompanyId
                                        : carrierID
                                    : carrierID
                        }
                    ];
                } else {
                    if (updatedData[item.ID]['DispatcherComments']) {
                        requestList = [
                            ...requestList,
                            {
                                DriverID: driverID,
                                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                                Priority: Priority,
                                DispatcherComments: DispatcherComments ? DispatcherComments : '',
                                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                                LoadNumber: LoadNumber,
                                ModifiedBy: userID,
                                ButtonName:
                                    this.buttonPressed === 'DispatchLoad'
                                        ? selectedType === 'Driver'
                                            ? this.buttonPressed
                                            : 'CarrierDispatchLoad'
                                        : this.buttonPressed,
                                TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                                IsAdhoc: false,
                                DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                                CarrierID:
                                    (carrierID == 0 || carrierID == null)
                                        ? this.checkCCLogin()
                                            ? this.accountStore.getUserInfo()?.carrierCompanyId
                                            : carrierID
                                        : carrierID
                            }
                        ];
                    } else if (updatedData[item.ID]['CarrierComments']) {
                        requestList = [
                            ...requestList,
                            {
                                DriverID: driverID,
                                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                                Priority: Priority,
                                CarrierComments: CarrierComments ? CarrierComments : '',
                                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                                LoadNumber: LoadNumber,
                                ModifiedBy: userID,
                                ButtonName:
                                    this.buttonPressed === 'DispatchLoad'
                                        ? selectedType === 'Driver'
                                            ? this.buttonPressed
                                            : 'CarrierDispatchLoad'
                                        : this.buttonPressed,
                                TrailerNumber: TrailerNumber ? TrailerNumber : ' ',
                                IsAdhoc: false,
                                DispatchType: selectedType == null ? this.selectedDispatchType : selectedType,
                                CarrierID:
                                    (carrierID == 0 || carrierID == null)
                                        ? this.checkCCLogin()
                                            ? this.accountStore.getUserInfo()?.carrierCompanyId
                                            : carrierID
                                        : carrierID
                            }
                        ];
                    }
                }
            }
            request = {
                ModifiedBy: userID,
                IsAdhoc: false,
                dispatchPlanRequest: requestList
            };
        });
        return request;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateTrailerInPopUp(item: DispatchPlanResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateDriverInPopUp(item: DispatchPlanResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updatePlannedDateTimeInPopUp(item: DispatchPlanResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateCarrierInPopUp(item: DispatchPlanResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(
            this.activeTrailerValues,
            DispatchPlanFieldName.TRAILERNUMBER,
            'TrailerNumber'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.activeDriverValues,
            DispatchPlanFieldName.DRIVERNAME,
            'DriverName'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.activeCarrierValues,
            DispatchPlanFieldName.CARRIERNAME,
            'CarrierName'
        );
        this.agGridService.updateOptionCellEditorValues(
            this.activeProrityValues,
            DispatchPlanFieldName.PRIORITY,
            'label'
        );
    }

    isDisabled = (): boolean => {
        if (this.dispatchPlanData.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    @action
    updateRow = (selectedRowData: DispatchPlanResponse): void => {
        const updatedShopIndex = this.dispatchPlanData.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.backupDispatchPlanData[updatedShopIndex], selectedRowData)) {
            //this.updateIDFieldBasedOnName(selectedRowData);
            this.mapEditableColumns(this.dispatchPlanData[updatedShopIndex], selectedRowData);
        }
        if (selectedRowData.SELECTED) {
            this.isDispatchLoadButtonEnabled = false;
            this.isCancelLoadEnabled = false;
            this.isCCRoleDispatchLoadButtonEnabled = false;
            this.dataToBeDeleted = [];
        }
    };

    mapEditableColumns(currentItem: DispatchPlanResponse, updatedItem: DispatchPlanResponse) {
        currentItem.DriverName = updatedItem.DriverName;
        currentItem.Priority = updatedItem.Priority;
        currentItem.TrailerNumber = updatedItem.TrailerNumber;
        currentItem.DispatcherComments = updatedItem.DispatcherComments;
        currentItem.PlannedDateTime = updatedItem.PlannedDateTime;
        currentItem.DispatcherNotes = updatedItem.DispatcherNotes;
        currentItem.CarrierName = updatedItem.CarrierName;
        currentItem.CarrierComments = updatedItem.CarrierComments;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    updateDispatcherComments(item: DispatchPlanResponse, value: string, key: string) {
        this.setValueInChangeMap(item.ID, key, item.DispatcherComments, value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateCarrierComments(item: DispatchPlanResponse, value: string, key: string) {
        this.setValueInChangeMap(item.ID, key, item.CarrierComments, value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    updateCCComments(item: DispatchPlanResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item.CarrierComments, value);
        if (popup && item[key] != null) item[key] = item[key] + ' ' + value.replace(/(\r\n|\n|\r)/gm, " ").trim();
        else item[key] = value;
        this.updateRow(item);
    }

    updateCommentColDef = () => {
        const commentColDef = dispatchPlanColDef.find(x => x.colId === DispatchPlanFieldName.DISPATCHERCOMMENTS);
        const notesColDef = dispatchPlanColDef.find(x => x.colId === DispatchPlanFieldName.DISPATCHERNOTES);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateDispatcherComments(item, value, DispatchPlanFieldName.DISPATCHERCOMMENTS),
                isDisabled: (item: DispatchPlanResponse, value) =>
                    (item.LoadStatus !== 'Pending' &&
                        item.LoadStatus !== 'Rejected By Driver' &&
                        item.LoadStatus !== 'Cancelled By Driver' &&
                        item.LoadStatus !== 'Recalled' &&
                        item.LoadStatus !== 'Recalled By Carrier' &&
                        item.LoadStatus !== 'Cancelled By Carrier' &&
                        item.LoadStatus !== 'Rejected By Carrier') ||
                    !dataStore.checkOperationAccessWithModule('Save', 'DispatchPlan')
            };
        }
        if (notesColDef) {
            notesColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateDispatcherComments(item, value, DispatchPlanFieldName.DISPATCHERNOTES),
                isDisabled: (item: DispatchPlanResponse, value) =>
                    (item.LoadStatus !== 'Pending' &&
                        item.LoadStatus !== 'Rejected By Driver' &&
                        item.LoadStatus !== 'Cancelled By Driver' &&
                        item.LoadStatus !== 'Recalled' &&
                        item.LoadStatus !== 'Recalled By Carrier' &&
                        item.LoadStatus !== 'Cancelled By Carrier' &&
                        item.LoadStatus !== 'Rejected By Carrier') ||
                    !dataStore.checkOperationAccessWithModule('Save', 'DispatchPlan')
            };
        }
    };

    updateCarrierCommentColDef = () => {
        const commentColDef = dispatchPlanColDef.find(x => x.colId === DispatchPlanFieldName.CARRIERCOMMENTS);
        if (commentColDef) {
            commentColDef.cellRendererParams = {
                onChange: (item, value) =>
                    this.updateCarrierComments(item, value, DispatchPlanFieldName.CARRIERCOMMENTS),
                isDisabled: (item: DispatchPlanResponse, value) =>
                    item.LoadStatus !== 'Carrier Assigned' ||
                    !dataStore.checkOperationAccessWithModule('Save', 'DispatchPlan')
            };
        }
    };

    @action
    updateDispatcherNotes(item: DispatchPlanResponse, value: string, key: string) {
        this.setValueInChangeMap(item.ID, key, item.DispatcherNotes, value);
        item[key] = value;
        this.updateRow(item);
    }

    @action
    @Loader
    @Catch(() => {
        errorHandler(DispatchPlanMessage.FETCH_ERROR_MESSAGE);
    })
    async handleDriverChange(driver, buttonName): Promise<void> {
        uiService.loaderService.showLoader();
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        let requestList: UpdateDispatchPlanResponse[] = [];
        const {
            LoadNumber,
            PlannedDateTime,
            Priority,
            DispatcherComments,
            DispatcherNotes,
            Trailer,
            DispatchType,
            CarrierName,
            CarrierID,
            CarrierComments
        } = this.approvedCommentTicketModel;
        const planDateTime = moment(new Date(PlannedDateTime).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss');

        requestList = [
            ...requestList,
            {
                DriverID: this.selectedDispatchType === 'Driver' || this.isCCLogin ? driver : null,
                TrailerNumber: Trailer,
                ModifiedBy: userID,
                ButtonName: buttonName,
                LoadNumber: LoadNumber,
                DispatcherComments: DispatcherComments,
                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                Priority: Priority,
                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                IsAdhoc: false,
                DispatchType: !this.checkCCLogin() ? 'Driver' : 'Carrier',
                CarrierID:
                    CarrierID == 0
                        ? this.checkCCLogin()
                            ? this.accountStore.getUserInfo()?.carrierCompanyId
                            : CarrierID
                        : CarrierID
            }
        ];
        const errorInSave = false;
        const errorSummary: ToastMessage[] = [];

        const request = {
            ModifiedBy: userID,
            IsAdhoc: false,
            dispatchPlanRequest: requestList
        };
        uiService.loaderService.showLoader();
        const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(request);
        uiService.loaderService.hideLoader();
        this.cellValueChangeMap = {};
        await this.getRangeResult(this.startDate, this.endDate);
        this.selectedDispatchPlanList = undefined;
        this.dataToBeDeleted = [];
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.dispatchPlanData.length; i++) {
                    const updatedShop = this.dispatchPlanData[i];
                    if (updatedShop.ID.toString() === errorMessage.id) {
                        const backupShopIndex = this.backupDispatchPlanData.findIndex(
                            a => a.ID.toString() == errorMessage.id
                        );
                        this.mapEditableColumns(updatedShop, this.backupDispatchPlanData[backupShopIndex]);
                        break;
                    }
                }
            });
        } else {
            if (response['StatusCode'] === 422) {
                this.uiService.toastService.error(response['Message']);
            } else if (requestList.length !== 0) {
                this.uiService.toastService.success(DispatchPlanMessage.SAVE);
                this.dataToBeDeleted = [];
            }
        }
    }

    @action
    @Loader
    @Catch(() => {
        errorHandler(DispatchPlanMessage.FETCH_ERROR_MESSAGE);
    })
    async handleCarrierChange(carrier, buttonName): Promise<void> {
        uiService.loaderService.showLoader();
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        let requestList: any[] = [];
        const {
            LoadNumber,
            PlannedDateTime,
            Priority,
            DispatcherComments,
            DispatcherNotes,
            DriverID,
            Trailer,
            DispatchType,
            CarrierName,
            CarrierID,
            CarrierComments
        } = this.approvedCommentTicketModel;
        const planDateTime = moment(new Date(PlannedDateTime).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss');

        requestList = [
            ...requestList,
            {
                DriverID: DriverID,
                TrailerNumber: Trailer,
                ModifiedBy: userID,
                ButtonName: buttonName,
                LoadNumber: LoadNumber,
                DispatcherComments: DispatcherComments,
                DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                Priority: Priority,
                PlannedDateTime: PlannedDateTime ? planDateTime : '',
                IsAdhoc: false,
                DispatchType: 'Carrier',
                CarrierID: carrier
            }
        ];
        const errorInSave = false;
        const errorSummary: ToastMessage[] = [];

        const request = {
            ModifiedBy: userID,
            IsAdhoc: false,
            dispatchPlanRequest: requestList
        };
        uiService.loaderService.showLoader();
        const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(request);
        uiService.loaderService.hideLoader();
        this.cellValueChangeMap = {};
        await this.getRangeResult(this.startDate, this.endDate);
        this.selectedDispatchPlanList = undefined;
        this.dataToBeDeleted = [];
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.dispatchPlanData.length; i++) {
                    const updatedShop = this.dispatchPlanData[i];
                    if (updatedShop.ID.toString() === errorMessage.id) {
                        const backupShopIndex = this.backupDispatchPlanData.findIndex(
                            a => a.ID.toString() == errorMessage.id
                        );
                        this.mapEditableColumns(updatedShop, this.backupDispatchPlanData[backupShopIndex]);
                        break;
                    }
                }
            });
        } else {
            if (response['StatusCode'] === 422) {
                this.uiService.toastService.error(response['Message']);
            } else if (requestList.length !== 0) {
                this.uiService.toastService.success(DispatchPlanMessage.SAVE);
                this.dataToBeDeleted = [];
            }
        }
    }

    @action
    @Loader
    @Catch(() => {
        errorHandler(DispatchPlanMessage.FETCH_ERROR_MESSAGE);
    })
    async handleBulkAssign(): Promise<void> {
        const selectedRows = this.dataToBeDeleted;
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let region: any = [];
        let ccID: any;
        let selectedRegionArray: any = [];
        if (this.selectedRegion && this.selectedRegion.length > 0) {
            let selectedRegion = toJS(this.selectedRegion).map(x => x.label);
            for (let i = 0; i < this.selectedRegion.length; i++) {
                const getRegionName = this.baseValueDTO.find(x => x.Name === selectedRegion[i]);
                selectedRegionArray.push(toJS(getRegionName));
                region = selectedRegionArray.map(x => Number(x.ID));
            }
        }
        const reqbody = {
            CC: this.checkCCLogin() ? this.userInfo.carrierCompanyId : null,
            StartDate: this.startDate,
            EndDate: this.endDate,
            RegionID: region && region.length > 0 ? region.toString() : 'All',
            Month: ''
        };
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        const driverData: any =
            this.selectedDispatchType === 'Driver' || (this.isCCLogin && this.selectedDispatchType === 'Carrier')
                ? await this.dispatchPlanDataService.getDriverResponse(reqbody)
                : null;
        const carrierData: any =
            this.selectedDispatchType === 'Carrier' ? await this.dispatchPlanDataService.getCarrierCompanyData() : null;
        let companyInfo: any = []
        const raFlag = this.raConfig.filter((item: any) => item.Code === 'RAIntegration');

        if (raFlag[0].Value === 'RA') {
            companyInfo = carrierData['Data'].filter(a => !a.IsDeleted && a.RACompanyName != null);
            companyInfo.map(data => {
                data.CompanyName = data.RACompanyName;
                data.AccountNumber = data.RAAccountNumber;
            })
        } else {
            companyInfo = carrierData['Data'].filter(a => !a.IsDeleted)
        }
        uiService.loaderService.hideLoader();
        let requestList: UpdateDispatchPlanResponse[] = [];
        confirmService.showBulkAssignPopup(
            async (selectedDispatchType, driver, trailer, buttonName) => {
                selectedRows.forEach(async item => {
                    const {
                        LoadNumber,
                        PlannedDateTime,
                        Priority,
                        DispatcherComments,
                        DispatcherNotes,
                        DispatchType,
                        CarrierName,
                        CarrierID,
                        CarrierComments
                    } = item;
                    const planDateTime = moment(new Date(PlannedDateTime).setSeconds(0)).format('YYYY-MM-DDTHH:mm:ss');
                    let carrierID: any;
                    if (
                        this.isCCLogin
                            ? DispatchType === 'Carrier' && !driver && buttonName === 'DispatchLoad'
                            : !driver && !CarrierName
                    ) {
                        await this.getRangeResult(this.startDate, this.endDate);
                        this.uiService.toastService.error(
                            this.isCCLogin ? DispatchAllConstants.NO_DRIVER : DispatchAllConstants.NO_DRIVER_CARRIER
                        );
                        return;
                    } else {
                        const updatedTrailerIndex = this.activeCarrierValues.findIndex(
                            a => a.CompanyName === CarrierName
                        );
                        if (updatedTrailerIndex > -1) {
                            carrierID = this.activeCarrierValues[updatedTrailerIndex].ID;
                        } else {
                            carrierID = CarrierID ? CarrierID : 0;
                        }
                    }
                    if (this.selectedDispatchType === 'Carrier') {
                        if (this.isCCLogin) {
                            if (carrierID == 0 || carrierID == null) {
                                ccID = this.accountStore.getUserInfo()?.carrierCompanyId;
                            } else ccID = carrierID;
                        } else {
                            ccID = driver;
                        }
                    } else {
                        ccID = 0;
                    }
                    requestList = [
                        ...requestList,
                        {
                            DriverID: this.selectedDispatchType === 'Driver' || this.isCCLogin ? driver : null,
                            TrailerNumber: trailer,
                            ModifiedBy: userID,
                            ButtonName: buttonName,
                            LoadNumber: LoadNumber,
                            DispatcherComments: DispatcherComments,
                            DispatcherNotes: DispatcherNotes ? DispatcherNotes : '',
                            Priority: Priority,
                            PlannedDateTime: PlannedDateTime ? planDateTime : '',
                            IsAdhoc: false,
                            DispatchType: this.selectedDispatchType,
                            CarrierID: ccID
                        }
                    ];
                });
                const errorInSave = false;
                const errorSummary: ToastMessage[] = [];
                const request = {
                    ModifiedBy: userID,
                    IsAdhoc: false,
                    dispatchPlanRequest: requestList
                };
                uiService.loaderService.showLoader();
                const response = await this.dispatchPlanDataService.updateDispatchPlanStatus(request);
                uiService.loaderService.hideLoader();
                this.cellValueChangeMap = {};
                await this.getRangeResult(this.startDate, this.endDate);
                this.selectedDispatchPlanList = undefined;
                this.dataToBeDeleted = [];
                if (errorInSave) {
                    this.uiService.toastService.error('', {}, errorSummary);
                    errorSummary.forEach(errorMessage => {
                        for (let i = 0; i < this.dispatchPlanData.length; i++) {
                            const updatedShop = this.dispatchPlanData[i];
                            if (updatedShop.ID.toString() === errorMessage.id) {
                                const backupShopIndex = this.backupDispatchPlanData.findIndex(
                                    a => a.ID.toString() == errorMessage.id
                                );
                                this.mapEditableColumns(updatedShop, this.backupDispatchPlanData[backupShopIndex]);
                                break;
                            }
                        }
                    });
                } else {
                    if (response['StatusCode'] === 422) {
                        this.uiService.toastService.error(response['Message']);
                    } else if (requestList.length !== 0) {
                        this.uiService.toastService.success(DispatchPlanMessage.SAVE);
                        this.dataToBeDeleted = [];
                    }
                }
            },
            this.selectedDispatchType === 'Driver' || this.isCCLogin ? driverData['Data'] : companyInfo,
            this.activeTrailerValues,
            this.isCCLogin ? 'Driver' : this.selectedDispatchType
        );
    }

    @action
    handleSelectAllData = (data: any, filteredData: any) => {
        let loadStatusArray: any = [];
        if (this.selectButtonText === 'Select All') {
            this.dataToBeDeleted = [];
            data.map((item, index) => {
                loadStatusArray.push(item.LoadStatus);
                filteredData.forEach(product => {
                    if (item.LoadNumber === product.LoadNumber && !item.SELECTED) {
                        this.dataToBeDeleted.push({
                            ID: item.ID,
                            LoadNumber: item.LoadNumber,
                            PlannedDateTime: item.PlannedDateTime,
                            TrailerNumber: item.TrailerNumber,
                            DriverID: item.DriverID,
                            DispatcherComments: item.DispatcherComments,
                            Priority: item.Priority,
                            DriverName: item.DriverName,
                            DispatcherNotes: item.DispatcherNotes,
                            CarrierID: item.CarrierID,
                            CarrierName: item.CarrierName,
                            DispatchType: item.DispatchType,
                            CarrierComments: item.CarrierComments,
                            LoadStatus: item.LoadStatus,
                            AX_Origin: item.AX_Origin,
                            AX_Destination: item.AX_Destination,
                            DataSource: item.DataSource,
                            EstimatedVolume: item.EstimatedVolume
                        });
                        item.SELECTED = true;
                        this.checkBoxSelectionCount = this.dataToBeDeleted.length;
                    }
                });
            });
            const driverCarrierBlank = this.dataToBeDeleted.filter(item => !item.DriverID && !item.CarrierID);
            const ccdriverBlank = this.dataToBeDeleted.filter(item => !item.DriverID);
            if (this.cancelDisableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isCancelLoadEnabled = false;
            } else if (this.cancelEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isCancelLoadEnabled = true;
            }
            if (
                this.dispatchLoadDisableArray.some(v => loadStatusArray.indexOf(v) !== -1) ||
                driverCarrierBlank.length > 0
            ) {
                this.isDispatchLoadButtonEnabled = false;
            } else if (this.dispatchLoadEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isDispatchLoadButtonEnabled = true;
            }
            if (
                this.ccDispatchLoadDisableArray.some(v => loadStatusArray.indexOf(v) !== -1) ||
                ccdriverBlank.length > 0
            ) {
                this.isCCRoleDispatchLoadButtonEnabled = false;
            } else if (this.ccDispatchLoadEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isCCRoleDispatchLoadButtonEnabled = true;
            }
            if (this.recallDisableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isRecallButtonEnabled = false;
            } else if (this.recalledEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isRecallButtonEnabled = true;
            }
            if (this.rejectOrAcceptDisableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isRejectOrAcceptButtonEnabled = false;
            } else if (this.rejectOrAcceptEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isRejectOrAcceptButtonEnabled = true;
            }
            if (this.ccRecallDisableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isCCRecallButtonEnabled = false;
            } else if (this.ccRecalledEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)) {
                this.isCCRecallButtonEnabled = true;
            }
            if (this.dataToBeDeleted.length == 1  && this.completeEnableArray.some(v => loadStatusArray.indexOf(v) !== -1)){
                this.isCompleteButtonEnabled = true;
            } else {
                this.isCompleteButtonEnabled = false;
            }
            this.selectButtonText = 'Deselect All';
            this.setDispatchPlanData(data);
        } else {
            data.map((item, index) => {
                item.SELECTED = false;
                this.dataToBeDeleted = [];
                this.selectButtonText = 'Select All';
                this.isDispatchLoadButtonEnabled = false;
                this.isRejectOrAcceptButtonEnabled = false;
                this.isRecallButtonEnabled = false;
                this.isCancelLoadEnabled = false;
                this.hasLoadFilterChanged = false;
            });
            this.setDispatchPlanData(data);
        }
    };

    selectedDispatchPlanRowData(dispatchPlanData, value: any, colName: string): void {
        this.driverIdSelected = dispatchPlanData.DriverName;
        const row = dispatchPlanData.ID;
        const initValue = dispatchPlanData[colName];
        let allSelectedStatus: any[] = [];
        if (colName !== 'SELECTED') this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case 'SELECTED':
                dispatchPlanData.SELECTED = !value;
                break;
            default:
                break;
        }
        if (!value) {
            const prevResult = this.dataToBeDeleted.filter(a => a.ID === dispatchPlanData.ID);
            if (prevResult.length > 0) {
                this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== dispatchPlanData.ID);
            }
            this.dataToBeDeleted.push({
                LoadStatus: dispatchPlanData.LoadStatus,
                ID: dispatchPlanData.ID,
                LoadNumber: dispatchPlanData.LoadNumber,
                PlannedDateTime: dispatchPlanData.PlannedDateTime,
                TrailerNumber: dispatchPlanData.TrailerNumber,
                DriverID: dispatchPlanData.DriverID,
                DispatcherComments: dispatchPlanData.DispatcherComments,
                Priority: dispatchPlanData.Priority,
                DriverName: dispatchPlanData.DriverName,
                DispatcherNotes: dispatchPlanData.DispatcherNotes,
                CarrierName: dispatchPlanData.CarrierName,
                CarrierID: dispatchPlanData.CarrierID,
                DispatchType: dispatchPlanData.DispatchType,
                CarrierComments: dispatchPlanData.CarrierComments,
                AX_Origin: dispatchPlanData.AX_Origin,
                AX_Destination: dispatchPlanData.AX_Destination,
                DataSource: dispatchPlanData.DataSource,
                EstimatedVolume: dispatchPlanData.EstimatedVolume
            });
            if (this.checkBoxSelectionCount === this.dataToBeDeleted.length) {
                this.selectButtonText = 'Deselect All';
            }
            allSelectedStatus.push(dispatchPlanData.LoadStatus);
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== dispatchPlanData.ID);
            this.selectButtonText = 'Select All';
        }
        allSelectedStatus = toJS(this.dataToBeDeleted);
        const driverCarrierBlank = this.dataToBeDeleted.filter(item => !item.DriverID && !item.CarrierID);
        const ccdriverBlank = this.dataToBeDeleted.filter(item => !item.DriverID);
        let selectedRowStatus = allSelectedStatus.map(item => item.LoadStatus);
        if (this.cancelDisableArray.some(r => selectedRowStatus.includes(r))) {
            this.isCancelLoadEnabled = false;
        } else if (this.cancelEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isCancelLoadEnabled = true;
        }
        if (this.dispatchLoadDisableArray.some(r => selectedRowStatus.includes(r)) || driverCarrierBlank.length > 0) {
            this.isDispatchLoadButtonEnabled = false;
        } else if (this.dispatchLoadEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isDispatchLoadButtonEnabled = true;
        }
        if (this.recallDisableArray.some(r => selectedRowStatus.includes(r))) {
            this.isRecallButtonEnabled = false;
        } else if (this.recalledEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isRecallButtonEnabled = true;
        }
        if (this.ccDispatchLoadDisableArray.some(r => selectedRowStatus.includes(r)) || ccdriverBlank.length > 0) {
            this.isCCRoleDispatchLoadButtonEnabled = false;
        } else if (this.ccDispatchLoadEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isCCRoleDispatchLoadButtonEnabled = true;
        }
        if (this.rejectOrAcceptDisableArray.some(r => selectedRowStatus.includes(r))) {
            this.isRejectOrAcceptButtonEnabled = false;
        } else if (this.rejectOrAcceptEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isRejectOrAcceptButtonEnabled = true;
        }
        if (this.ccRecallDisableArray.some(r => selectedRowStatus.includes(r))) {
            this.isCCRecallButtonEnabled = false;
        } else if (this.ccRecalledEnableArray.some(r => selectedRowStatus.includes(r))) {
            this.isCCRecallButtonEnabled = true;
        }
        if (this.dataToBeDeleted.length == 1 && this.completeEnableArray.some(v => selectedRowStatus.indexOf(v) !== -1)){
            this.isCompleteButtonEnabled = true;
        } else {
            this.isCompleteButtonEnabled = false;
        }
    }

    @Loader
    @Catch(() => {
        errorHandler(DispatchPlanMessage.FETCH_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.startdate = startDate;
        this.enddate = endDate;
        this.saveDateRange(startDate, endDate);
        const cCUID = this.isCCLogin ? this.accountStore.getUserID() : null;
        const reqbody = {
            StartDate: startDate,
            EndDate: endDate,
            CCUID: cCUID
        };
        // this.dispatchPlanData = [];
        this.selectButtonText = 'Select All';
        const dispatchPlanData = await this.dispatchPlanDataService.getDispatchPlanDataWithDateParams(reqbody);
        const dispatchPlan: DispatchPlanResponse[] = dispatchPlanData.data['Data'] ? dispatchPlanData.data['Data'] : [];
        const data: any = dispatchPlan;
        if (!this.checkCCLogin()) {
            data.map(item => {
                item.selectedType = item.DispatchType;
                if (!item.selectedType && this.dispatchLoadEnableArray.includes(item.LoadStatus)) {
                    item.selectedType = 'Driver';
                }
            });
        }
        this.modifiedDispatchPlanData = data;
        this.setDispatchPlanData(data);
        this.setBackupDispatchPlanData(data);
        this.filterDataBasedOnSelection(this.selectedRegion, this.selectedLoadStatus, this.selectedProduct);
        this.isDispatchLoadButtonEnabled = false;
        this.isCancelLoadEnabled = false;
        this.isRecallButtonEnabled = false;
        this.isRejectOrAcceptButtonEnabled = false;
        this.isRefreshed = false;
        // this.selectedDispatchType = !this.isCCLogin ? 'Driver' : 'Carrier';
    }

    saveDateRange(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    filterDataBasedOnSelection(selectedRegion: any, selectedLoadStatus: any, selectedProduct: any) {
        let filteredProduct = this.modifiedDispatchPlanData;
        if (selectedLoadStatus !== 'All' && !this.isCCLogin) {
            if (selectedLoadStatus === 'PEN/REC/CBD/RBD/CBC/RBC') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Pending' ||
                        product.LoadStatus === 'Rejected By Driver' ||
                        product.LoadStatus === 'Cancelled By Driver' ||
                        product.LoadStatus === 'Recalled' ||
                        product.LoadStatus === 'Rejected By Carrier' ||
                        product.LoadStatus === 'Cancelled By Carrier'
                    );
                });
            } else if (selectedLoadStatus === 'DIS/ACC/ENR/CA/CACC/RECBC') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Driver Dispatched' ||
                        product.LoadStatus === 'Driver Accepted' ||
                        product.LoadStatus === 'Enroute' ||
                        product.LoadStatus === 'Recalled Pending' ||
                        product.LoadStatus === 'Cancelled Pending' ||
                        product.LoadStatus === 'Dispatch Pending' ||
                        product.LoadStatus === 'Carrier Assigned' ||
                        product.LoadStatus === 'Carrier Accepted' ||
                        product.LoadStatus === 'Recalled By Carrier'
                    );
                });
            } else if (selectedLoadStatus === 'Loaded/Completed') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Loaded' ||
                        product.LoadStatus === 'Completed' ||
                        product.LoadStatus === 'System Completed'
                    );
                });
            } else if (selectedLoadStatus === 'Deleted/Cancelled') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Cancelled' ||
                        product.LoadStatus === 'Manually Deleted ' ||
                        product.LoadStatus === 'Apportionment Deleted' ||
                        product.LoadStatus === 'System Deleted' ||
                        product.LoadStatus === 'System Cancelled'
                    );
                });
            }
        } else if (selectedLoadStatus !== 'All' && this.isCCLogin) {
            if (selectedLoadStatus === 'CA') {
                filteredProduct = filteredProduct.filter(product => {
                    return product.LoadStatus === 'Carrier Assigned';
                });
            } else if (selectedLoadStatus === 'CACC/CBD/RBD/RECBC') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Carrier Accepted' ||
                        product.LoadStatus === 'Rejected By Driver' ||
                        product.LoadStatus === 'Recalled By Carrier' ||
                        product.LoadStatus === 'Cancelled By Driver'
                    );
                });
            } else if (selectedLoadStatus === 'DIS/ACC/ENR') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Driver Dispatched' ||
                        product.LoadStatus === 'Driver Accepted' ||
                        product.LoadStatus === 'Enroute' ||
                        product.LoadStatus === 'Recalled Pending' ||
                        product.LoadStatus === 'Cancelled Pending' ||
                        product.LoadStatus === 'Dispatch Pending'
                    );
                });
            } else if (selectedLoadStatus === 'Loaded/Completed') {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.LoadStatus === 'Loaded' ||
                        product.LoadStatus === 'Completed' ||
                        product.LoadStatus === 'System Completed'
                    );
                });
            }
        }
        if (this.accountStore.userRoles.includes('ptssupplierbutanengl')) {
            if (selectedProduct !== 'All' && selectedProduct !== 'NGL & Butane') {
                filteredProduct = filteredProduct.filter(product => {
                    return product.DataSource === selectedProduct || product.DataSource === null;
                });
            } else if (selectedProduct === 'NGL & Butane') {
                filteredProduct = filteredProduct.filter(product => {
                    return product.DataSource === 'Butane' || product.DataSource === 'NGL';
                });
            } else {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.DataSource === 'Butane' || product.DataSource === 'NGL' || product.DataSource === null
                    );
                });
            }
        } else {
            if (selectedProduct !== 'All' && selectedProduct !== 'NGL & Butane') {
                filteredProduct = filteredProduct.filter(product => {
                    return product.DataSource === selectedProduct || product.DataSource === null;
                });
            } else if (selectedProduct === 'NGL & Butane') {
                filteredProduct = filteredProduct.filter(product => {
                    return product.DataSource === 'Butane' || product.DataSource === 'NGL';
                });
            } else {
                filteredProduct = filteredProduct.filter(product => {
                    return (
                        product.DataSource === 'Butane' ||
                        product.DataSource === 'NGL' ||
                        product.DataSource === 'Crude' ||
                        product.DataSource === 'Brine' ||
                        product.DataSource === null
                    );
                });
            }
        }
        if (selectedRegion && selectedRegion.length > 0 && !selectedRegion.some(item => item.value === 'All')) {
            if (selectedRegion.length < 4) {
                filteredProduct = filteredProduct.filter(product => {
                    return selectedRegion && selectedRegion.some(region => product.Region === region.value);
                });
            }
        }
        this.setDispatchPlanData(filteredProduct);
        this.getVolumeSum(filteredProduct);
        if (filteredProduct.length === 0) {
            this.hasNoDataInFilter = true;
        } else {
            this.hasNoDataInFilter = false;
        }
    }

    @action
    showCCCommentsPopUp() {
        this.isCommentsPopUpVisible = true;
    }

    @action
    hideCCCommentsPopUp() {
        this.isCommentsPopUpVisible = false;
    }

    updatePopupComment(approverComment: string) {
        this.dataToBeDeleted.forEach(row => {
            this.updateCCComments(row, approverComment, DispatchPlanFieldName.CARRIERCOMMENTS, true);
        });
    }

    async getRefreshData(startDate, endDate) {
        this.saveDateRange(startDate, endDate);
        const cCUID = this.isCCLogin ? this.accountStore.getUserID() : null;
        const reqbody = {
            StartDate: startDate,
            EndDate: endDate,
            CCUID: cCUID
        };
        // this.dispatchPlanData = [];
        this.selectButtonText = 'Select All';
        const dispatchPlanData = await this.dispatchPlanDataService.getDispatchPlanDataWithDateParams(reqbody);
        const dispatchPlan: DispatchPlanResponse[] = dispatchPlanData.data['Data'] ? dispatchPlanData.data['Data'] : [];
        const data: any = dispatchPlan;
        if (!this.checkCCLogin()) {
            data.map(item => {
                item.selectedType = item.DispatchType;
                if (!item.selectedType && this.dispatchLoadEnableArray.includes(item.LoadStatus)) {
                    item.selectedType = 'Driver';
                }
            });
        }
        this.modifiedDispatchPlanData = data;
        this.setBackupDispatchPlanData(data);
        this.getVolumeSum(data);
        this.filterDataBasedOnSelection(this.selectedRegion, this.selectedLoadStatus, this.selectedProduct);
        this.isDispatchLoadButtonEnabled = false;
        this.isCancelLoadEnabled = false;
        this.isRecallButtonEnabled = false;
        this.isRejectOrAcceptButtonEnabled = false;
        this.selectedDispatchType = !this.isCCLogin ? 'Driver' : 'Carrier';
    }

    getVolumeSum(data) {
        let volumeCount = 0;
        for (let report in data) {
            volumeCount += data[report].EstimatedVolume;
        }
        this.EstVolumeSum = volumeCount.toFixed(3);
    }
}
