export interface HazardAssessmentHistoryRequest {
    HazardID: string;
    VolumeorHourlyTicketNumber: string;
    RiskLevel: string;
    DriverID: number;
    Weather: string;
    Description: string;
    ConsignorID: number;
    ConsigneeID: number;
    CustomerName: string;
    EventDetails: string;
    Recommendations: string;
    Signature: string;
    CreatedBy: number;
    OperationReview: string,
    OperationReviewComments: string,
    ComplianceReview: string,
    ComplianceReviewComments: string,
    OperationReviewBy: number,
    ComplianceReviewBy: number,
    Resolved: boolean,
    ResolvedDate: string,
    ResolvedBy: number,
    // ResolvedByName: string,
    ResolvedComments: string,
    ModifiedBy: number,
    // ModifiedByName: string,
    OperationReviewDate: string,
    ComplianceReviewDate: string,
    ModifiedDate: string
}
export interface HazardAssessmentHistoryResponse {
    HazardID: string;
    VolumeorHourlyTicketNumber: string;
    DriverID: number;
    Weather: string;
    Description: string;
    ConsignorID: number;
    ConsigneeID: number;
    CustomerName: string;
    Consignor: string | null;
    Consignee: string | null;
    EventDetails: string;
    Recommendations: string;
    RiskLevel: string;
    Date: string;
    CreatedByName: string;
    CreatedDate: string;
    PossibleCauses: string;
    Signature: string;
    OperationReview: string;
    OperationReviewDate: string;
    OperationReviewBy: number;
    OperationReviewByName: string;
    OperationReviewComments: string;
    ComplianceReview: string;
    ID: number;
    CreatedBy: number;
    FirstName: string;
    LastName: string;
    ModifiedBy: number;
    ModifiedByName: string;
    ModifiedDate: string;
    TicketNumber?: string;
    ComplianceReviewComments: string;
    ComplianceReviewDate: string;
    ComplianceReviewBy: number;
    Resolved: boolean;
    ResolvedDate: string;
    ResolvedBy: number;
    ResolvedByName: string;
    ResolvedComments: string;
    ComplianceReviewedByUser: string;
    ComplianceReviewByName: string;
}


export interface HazardAssessmentHistoryViewModel {
    hazardAssessmentData: HazardAssessmentHistoryResponse[];
    // statusTypes: StatusType[];
}

// export interface StatusType {
//     Name: string;
//     ID: number;
//     Code: string;
//     Value: number;
// }

export const OperationsReviewDropdown = [
    { value: 'New', label: 'New' },
    { value: 'Reviewed', label: 'Reviewed' },
    { value: 'Reviewed-Issue', label: 'Reviewed-Issue' }
];

export const ComplianceReviewDropdown = [
    { value: 'New', label: 'New' },
    { value: 'Reviewed', label: 'Reviewed' },
    { value: 'Reviewed-Issue', label: 'Reviewed-Issue' }
];