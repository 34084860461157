import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { CustomerReportResponse, DateRange, EmailConfigurationResponse } from '../domains/model';

export class CustomerReportDataService {
    constructor(private restApi: RestApiService) {}

    async getCustomerReport(): Promise<CustomerReportResponse[]> {
        const { data } = await this.restApi.postWithToken<CustomerReportResponse[]>(Config.getCustomerReport);
        return data['Data'];
    }
    async getCustomerReportwithParams(reqbody: DateRange): Promise<CustomerReportResponse[]> {
        const { data } = await this.restApi.postWithToken<CustomerReportResponse[]>(Config.getCustomerReport, reqbody, {});
        return data['Data'];
    }
    async sendReport(reqbody): Promise<void> {
        const { data } = await this.restApi.post<void>(Config.sendCustomerReport, reqbody, {});
        return data['Data'];
    }
    async createCustomerReport(reqbody): Promise<void> {
        const { data } = await this.restApi.post<void>(Config.createCustomerReport, reqbody, {});
        return data['Data'];
    }
    async getEmailConfiguration(reqbody): Promise<EmailConfigurationResponse[]> {
        const { data } = await this.restApi.post<EmailConfigurationResponse[]>(
            Config.getEmailConfiguration,
            reqbody,
            {}
        );
        return data['Data'];
    }
}

export const customerreportDataService = new CustomerReportDataService(restApiService);
