import { uiService } from '../services/ui-service';

export default (target: any, key: any, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function(...args: any) {
        uiService.loaderService.showLoader();
        const result = await originalMethod.apply(this, args);
        uiService.loaderService.hideLoader();
        return result;
    };
    return descriptor;
};
