import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import './communication.scss';

export class MessageCellRenderer extends PureComponent<ICellRendererParams, {}> {
    constructor(props) {
        super(props);
    }
    render() {
        const message = this.props.data['Data'][0];
        return (
            <>
                {message['IsRead'] !== true && message['SenderRole'].includes('ptsdriver') ? (
                    <span className="red-dot"></span>
                ) : (
                    <span></span>
                )}
                <span> {this.props.data['Data'][0]['Message'] ? this.props.data['Data'][0]['Message'] : ''}</span>
            </>
        );
    }
}
