export const communicationTabs = [
    {
        ID: 1,
        Name: 'New'
    },
    {
        ID: 2,
        Name: 'Messages'
    },
    {
        ID: 3,
        Name: 'Broadcast'
    }
];

export const apportionmentData = [
    {
        ID: 1,
        FacilityDesc: 'Buck Creek TM-Plains',
        Product: 'P',
        FacilityCode: 'ABGP0001700',
        Apportionment: 10
    },
    {
        ID: 2,
        FacilityDesc: 'Cromer TM-Plains',
        Product: 'M',
        FacilityCode: 'MBTMTT08003',
        Apportionment: 10
    },
    {
        ID: 3,
        FacilityDesc: 'Dollard TT-Plains',
        Product: 'F',
        FacilityCode: 'SKTM0002008',
        Apportionment: 10
    }
];

export const dataSources = [
    {
        ID: 1,
        Label: 'Butane'
    },
    {
        ID: 2,
        Label: 'NGL'
    },
    {
        ID: 3,
        Label: 'Crude'
    },
    {
        ID: 4,
        Label: 'Brine'
    }
];

export const productsData = [
    {
        ID: 1,
        Label: 'FC4',
        DataSourceID: 1,
    },
    {
        ID: 2,
        Label: 'C3+',
        DataSourceID: 1,
    },
    {
        ID: 3,
        Label: 'C2+',
        DataSourceID: 1,
    },
    {
        ID: 4,
        Label: 'C3+',
        DataSourceID: 2,
    },
    {
        ID: 5,
        Label: 'C2+',
        DataSourceID: 2,
    },
    {
        ID: 6,
        Label: 'FC4',
        DataSourceID: 2,
    },
    {
        ID: 7,
        Label: 'C5+',
        DataSourceID: 3,
    },
    {
        ID: 8,
        Label: 'AW',
        DataSourceID: 3,
    },
    {
        ID: 9,
        Label: 'C4',
        DataSourceID: 3,
    },
    {
        ID: 10,
        Label: 'AHS',
        DataSourceID: 3,
    },
    {
        ID: 11,
        Label: 'SO',
        DataSourceID: 3,
    },
    {
        ID: 12,
        Label: 'WH',
        DataSourceID: 3,
    },
    {
        ID: 13,
        Label: 'F',
        DataSourceID: 3,
    },
    {
        ID: 14,
        Label: 'HH',
        DataSourceID: 3,
    },
    {
        ID: 15,
        Label: 'LLK',
        DataSourceID: 3,
    },
    {
        ID: 16,
        Label: 'SW',
        DataSourceID: 3,
    },
    {
        ID: 17,
        Label: 'SH',
        DataSourceID: 3,
    },
    {
        ID: 18,
        Label: 'SYN',
        DataSourceID: 3,
    },
    {
        ID: 19,
        Label: 'BR',
        DataSourceID: 4,
    },
    {
        ID: 20,
        Label: 'NC4',
        DataSourceID: 1,
    },
    {
        ID: 21,
        Label: 'IC4',
        DataSourceID: 1,
    }
];

export const ShipperName = [
    {
        ID: 1,
        Label: 'Plains Midstream Canada ULC'
    },
    {
        ID: 2,
        Label: 'Others'
    }
]
export enum ImportErrorCodes {
    SHIPPERNAMECODE = '6',
    SHIPPERCODE = '7',
    SHIPPERNAME = '8',
    CONTRACTNUMBER_CRUDE = '9',
    LOCATION_ORIGIN = '11',
    LOCATION_DESTINATION = '13',
    ODPAIRING = '15',
    BUTANE_LOADS = '17',
    CRUDE_LOADS = '19',
    NGL_LOADS = '21',
    BRINE_LOADS = '23',
    CUSTOMERNAME = '25',
    PRODUCT = '27',
    ORIGIN = '29',
    DESTINATION = '31',
    NOMINATION = '33',
    NOMINATIONMONTH_MISSING = '35',
    NOMINATIONYEAR_MISSING = '37',
    REGION_FORMAT = '39',
    REGION_MISSING = '40',
    DATASOURCE = '45',
    CONTRACTNUMBER_BUTANE = '47',
    NOMINATIONMONTH_FORMAT = '49',
    NOMINATIONYEAR_FORMAT = '48',
    TRUCKEDBY_ORIGIN = '52',
    TRUCKEDBY_DESTINATION = '53'
}

export const loadStatus = ['Pending', 'Recalled', 'Recalled By Carrier', 'Rejected By Driver', 'Rejected By Carrier', 'Cancelled By Driver', 'Cancelled By Carrier'];
export const suppliersRoles = ['ptssupplierbutane', 'ptssupplierngl', 'ptssuppliercrude', 'ptssupplierbutanengl', 'PTSSupplierBrine'];
export const schedulerRoles = ['PTSScheduler']
export const CCUserRoles = [
    'PTSOwnerOperator',
    'PTSCarrierCompanyScheduler',
    'PTSCarrierOwner',
    'PTSCarrierCompanyAdmin',
    'ptsthirdpartydispatcher'
];

export const commaFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const loadDeletedFormat = (number) => {
    return '(' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ')'
}