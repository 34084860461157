import React from 'react';
import { Modal } from 'react-bootstrap';
import { MaintenanceUpdateComponent } from './maintenance-update-component';

interface MaintenanceUpdatePopUpProps {
    onHide: () => void;
    show: boolean;
    status: string;
    trailerType: string;
    onCancelUpadte: () => void;
    onConfirmUpdate: (ID: number , quesData:any, completedDate: Date) => void;
    selectedID: number;
    bookingDate: string;
}

export const MaintenanceUpdatePopUp = (props: MaintenanceUpdatePopUpProps) => {
    const { onHide, show, status, onCancelUpadte, selectedID , onConfirmUpdate , bookingDate, trailerType} = props;
    return (
        <Modal size="sm" onHide={onHide} show={show} centered backdrop="static">
            <Modal.Body>
                <MaintenanceUpdateComponent
                    status={status}
                    trailerType={trailerType}
                    onHide={onHide}
                    onCancelUpadte={onCancelUpadte}
                    onConfirmUpdate={onConfirmUpdate}
                    selectedID={selectedID}
                    bookingDate={bookingDate}
                ></MaintenanceUpdateComponent>
            </Modal.Body>
        </Modal>
    );
};
