import React, { Component } from 'react';
import { TractorDataStore } from '../stores/tractor-data-store';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { tractorStore } from '../stores';
import { dataStore } from '../../common/stores/index';
import { TractorSaveLabel } from '../domains/tractor-enum';
import { RESET, ADD_NEW_ROW, DELETE_ITEM } from '../data_constant';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';

interface TractorMasterDataProps {
    tractorStore: TractorDataStore;
}

@observer
export class TractorMasterDataComponent extends Component<TractorMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('TractorManagement');
    }

    componentDidMount() {
        this.props.tractorStore.init();
    }

    componentWillUnmount() {
        this.props.tractorStore.reset();
    }

    render(): React.ReactNode {
        const {
            tractorStore: { isSaveDisabled, isDeleteEnabled, isAddDisabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {TractorSaveLabel.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                {RESET}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.addNewRow}
                                                disabled={isAddDisabled()}
                                            >
                                                {ADD_NEW_ROW}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {DELETE_ITEM}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('TractorManagement_filter');
        this.props.tractorStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        this.props.tractorStore.updateTractors();
    };

    private handleReset = (): void => {
        this.props.tractorStore.resetTractorList();
    };

    handleRefresh = () => {
        const { tractorStore } = this.props;
        if(Object.keys(tractorStore.cellValueChangeMap).length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    tractorStore.init();
                    tractorStore.cellValueChangeMap = {};
                    tractorStore.dataToBeDeleted = [];
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
        else {
            tractorStore.init();
            tractorStore.cellValueChangeMap = {};
            tractorStore.dataToBeDeleted = [];
        } 
    }

    private addNewRow = (): void => {
        const {
            tractorStore: { AddRow }
        } = this.props;

        AddRow();
    };
    private deleteitems = (): void => {
        const {
            tractorStore: { DeleteRows }
        } = this.props;

        DeleteRows();
    };

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            tractorStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            tractorStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            tractorStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    private getGridConfig(): GridOptions {
        const {
            tractorStore: { tractorList, agGridService, getColDef }
        } = this.props;

        const rowData = tractorList;
        const columnDefs = getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;

        const tractorGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(tractorGridParams);
    }
}
