import React, { Component } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

interface TextAreaCellRendererState {
    value: string;
}

interface TextAreaCellRendererParams extends ICellRendererParams {
    onChange: (data, value) => void;
    isDisabled: (item, value) => boolean;
}

export class TextAreaCellRenderer extends Component<TextAreaCellRendererParams, TextAreaCellRendererState> {
    constructor(props: TextAreaCellRendererParams) {
        super(props);
        this.state = {
            value: props.value || ''
        };
    }

    static defaultProps = { isDisabled: (item, value) => false };

    handleChange = (event): void => {
        const { target } = event;
        const { value } = target;
        this.setState({ value });
    };

    handleBlur = () => {
        const { data, onChange } = this.props;
        onChange(data, this.state.value);
    };

    getValue() {
        return this.state.value;
    }

    render(): React.ReactNode {
        const { value } = this.state;
        const { isDisabled, data } = this.props;
        return (
            <div>
                <textarea
                    disabled={isDisabled(data, value)}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={value}
                    rows={2}
                    cols={50}
                />
            </div>
        );
    }
}
