import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { DriverResponse } from 'features/data_management/domains/driver-management/model';

export class DriverDataService {
    constructor(private restApi: RestApiService) { }

    async getDrivers(reqbody = {}): Promise<DriverResponse[]> {
        const { data } = await this.restApi.postWithToken<DriverResponse[]>(Config.getDrivers, reqbody, {});
        return data['Data'];
    }

    async updateDrivers(reqbody: DriverResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateDrivers, reqbody, {});
        return data['Data'];
    }
}

export const driverDataService = new DriverDataService(restApiService);
