import React, { useEffect, useState } from 'react';
import './capacityPlan.scss';
import data from './capacity-dataSource.json';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { CCUserRoles, defaultSelectedRow, startingYearForSelection } from '../capacityPlan-constants';
import { uiService } from 'shared/services/ui-service';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { inject, observer } from 'mobx-react';
import { CapacityPlanDataStore } from '../store/capacityPlan-data-store';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import defaultpageicon from '../../../../assets/defaultpageicon.png';
import { toJS } from 'mobx';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { CommonUtils } from 'shared/services/common-utils';
import { DispatchBoardDataRequest } from 'features/dispatch/dispatch-board/model/model';
import Select from 'react-select';
import { dataStore } from 'features/common/stores';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AppConstant } from 'app_constant';
import { accountStore } from 'features/account/stores';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
library.add(faTrash, faSearch, faEdit, faTimes);
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface CapacityPlanScreenState {
    selectedRow: any;
    applyForFullMonth: boolean;
    applyForDateRange: boolean;
    selectedYear: any;
    selectedMonth: any;
    companyList: any;
    selectedCompany: any;
    selectedMonthLabel: any;
    startDate: any;
    endDate: any;
    additionalbutton: boolean;
    didVacationPlanChanged: boolean;
    dataSource: any;
    dataBackupSource: any;
    datesOfMonth: any;
    noOfDaysInAMonth: number;
    deletedVacationPlansId: any;
    yearsRange: any;
    responseData: any;
    readableOnly: boolean;
    vacationId: any;
    selectedRowDataBeforeEdit: any;
    additionalRow: any;
}

interface CapacityPlanScreenProps {
    capacityPlanDataStore: CapacityPlanDataStore;
}

@inject('capacityPlanDataStore')
@observer
export class CapacityPlanComponent extends React.Component<CapacityPlanScreenProps, CapacityPlanScreenState, any> {
    months = moment.months();
    toDate: any;
    fromDate: any;
    isRefreshed: boolean = false;
    searchValue: string = '';
    editSelectedRow: any = [];
    selectedOption: any = [];
    constructor(props) {
        super(props);
        const datesOfMonth = Array.from(
            Array(this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
            (_, i) => i + 1
        );
        const years = [] as any;
        for (let i = startingYearForSelection; i <= new Date().getFullYear() + 1; i += 1) {
            years.push(i);
        }
        this.state = {
            selectedRow: defaultSelectedRow,
            applyForFullMonth: false,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth(),
            companyList: [],
            selectedCompany: '',
            selectedMonthLabel: moment.months()[new Date().getMonth()],
            startDate: '',
            endDate: '',
            additionalbutton: false,
            didVacationPlanChanged: false,
            dataSource: [],
            dataBackupSource: [],
            datesOfMonth: datesOfMonth,
            noOfDaysInAMonth: datesOfMonth.length,
            deletedVacationPlansId: [],
            yearsRange: years,
            responseData: [],
            applyForDateRange: false,
            readableOnly: this.checkCCLogin() ? true : false,
            vacationId: null,
            selectedRowDataBeforeEdit: defaultSelectedRow,
            additionalRow: [{ endDate: '', startDate: '' }]
        };
        dataStore.setModuleName('CapacityPlan');
    }

    componentDidMount() {
        const { capacityPlanDataStore } = this.props;
        capacityPlanDataStore.init();
        const { selectedMonthLabel, selectedYear, selectedCompany } = this.state;
        if (capacityPlanDataStore !== undefined ) {
            const CompanyRequestdata = { regionID: 'All' };
            capacityPlanDataStore?.getCompanyRegionMapping(CompanyRequestdata).then(() => {
                    let dictMappedCompany = CommonUtils.dictionaryToObj(
                        capacityPlanDataStore?.regionMappingData.MappedCompany
                );
                this.setState({ companyList: dictMappedCompany });
                this.setState({ companyList: this.state.companyList.map(company =>({ ...company,value: company.Text })) })
                this.state.companyList.sort(this.sortCarrierCompany("Text"))
                if (dictMappedCompany.length > 0) {
                    this.setState({
                        selectedCompany: this.state.companyList[0].ID
                    });
                } else {
                    this.setState({
                        selectedCompany: 0
                    });
                }
                this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, this.state.selectedCompany);
            });
        }
    }
    private getMasterData(
        capacityPlanDataStore: CapacityPlanDataStore,
        selectedMonthLabel: string,
        selectedYear: number,
        selectedCompany: string
    ) {
        const carrierCompany = capacityPlanDataStore.mappedCCID ? capacityPlanDataStore.mappedCCID : 'All'
        const requestBody = { Type: 'External', Month: selectedMonthLabel, Year: selectedYear, CC: carrierCompany };
        capacityPlanDataStore?.GetMasterData(requestBody).then(() => {
            if (capacityPlanDataStore !== undefined && capacityPlanDataStore?.capacityPlanningData) {
                const data = this.createDataSourceToDisplay(capacityPlanDataStore?.capacityPlanningData);
                console.log(data, toJS(data));
                this.setState({ dataSource: data });
                this.setState({ dataBackupSource: this.state.dataSource })
                this.sortDrivers(this.state.dataSource)
                this.setState({ responseData: data });
                const filters = this.selectedOption.map((option) => option.Text)
                this.carrierCompaniesFiltered(filters);
                this.searchValue !== '' && this.handleSearch(this.searchValue)
            }
        });
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    createDataSourceToDisplay = (data: any[]) => {
        console.log(data, toJS(data));
        const tempArray = Object.values(
            data.reduce((acc, item) => {
                if (!acc[item.DriverID])
                    acc[item.DriverID] = {
                        id: item.DriverID,
                        name: item.Driver,
                        month: item.Month ? item.Month : this.state.selectedMonthLabel,
                        year: item.Year ? item.Year : this.state.selectedYear,
                        vacationPlan: [],
                        companyName: item.CompanyName,
                        // availabilityProvided: item.Availability ? item.Availability : false
                        availabilityProvided: item.Availability && item.Availability !== 0 ? true : false
                    };
                if (item.VacationID && item.StartDate && item.EndDate) {
                    acc[item.DriverID].vacationPlan.push({
                        vacationID: item.VacationID,
                        startDate: item.StartDate,
                        endDate: item.EndDate,
                        count: item.DayOff,
                        IsDeleted: item.IsDeleted,
                        editDisabled: true
                    });
                    acc[item.DriverID].vacationPlan.sort((item1, item2) => {
                        return (
                            parseInt(moment(new Date(item1.startDate)).format('D')) -
                            parseInt(moment(new Date(item2.startDate)).format('D')) +
                            (parseInt(moment(new Date(item1.endDate)).format('D')) -
                                parseInt(moment(new Date(item2.endDate)).format('D')))
                        );
                    });
                }
                return acc;
            }, {})
        );
        return tempArray;
    };

    daysInMonth = (month, year) => {
        // Use 1 for January, 2 for February, etc.
        return new Date(year, month, 0).getDate();
    };

    reset = async () => {
        const { dataSource, selectedRow, selectedMonthLabel, selectedYear, selectedCompany } = this.state;
        uiService.loaderService.showLoader();
        const { capacityPlanDataStore } = this.props;
        const userVacationPlanData = dataSource.filter(item => item.id === selectedRow[0].id);
        const usersVacationPlan = userVacationPlanData[0].vacationPlan;
        const IdsToBeDeleted = [] as any;
        usersVacationPlan.map(item => IdsToBeDeleted.push(item.vacationID));
        let userID = capacityPlanDataStore.userID;
        if (!userID || userID === 0 ) {
            await capacityPlanDataStore.accountStore.getLoggedInUserDetailsIfUserIdZero(capacityPlanDataStore.accountStore.userName).then(() => {
                capacityPlanDataStore.userID = capacityPlanDataStore.accountStore.getUserID();
            });
            if (!capacityPlanDataStore.userID || capacityPlanDataStore.userID === 0 ) {
                return;
            }
        }
        const requestParams = [
            {
                DriverID: selectedRow[0].id,
                Driver: selectedRow[0].name,
                VacationPlan: [],
                Month: selectedRow[0].month,
                Year: selectedRow[0].year,
                CreatedBy: capacityPlanDataStore.userID,
                // CreatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                ModifiedBy: capacityPlanDataStore.userID,
                DeletedRecords: IdsToBeDeleted
            }
        ];
        capacityPlanDataStore.AssignVacationPlan(requestParams).then(() => {
            this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, selectedCompany);
        });
        this.setState({
            selectedRow: defaultSelectedRow,
            selectedRowDataBeforeEdit: defaultSelectedRow,
            startDate: '',
            endDate: '',
            didVacationPlanChanged: false,
            applyForDateRange: false,
            applyForFullMonth: false
        });
        uiService.loaderService.hideLoader();
    };

    assignVacation = async() => {
        const {
            startDate,
            endDate,
            selectedRow,
            dataSource,
            deletedVacationPlansId,
            didVacationPlanChanged,
            selectedMonthLabel,
            selectedYear,
            additionalRow,
            selectedCompany
        } = this.state;
        const { capacityPlanDataStore } = this.props;
        if (endDate < startDate) {
            uiService.toastService.info('StartDate cannot be greater than EndDate');
        } else {
            uiService.loaderService.showLoader();
            const newVacationPlan = [] as any;
            if (additionalRow.length > 0) this.setState({ didVacationPlanChanged: true });
            additionalRow.map((data, ind) => {
                if (data.startDate && data.startDate !== '' && data.endDate && data.endDate !== '') {
                    newVacationPlan.push({
                        IsDeleted: false,
                        StartDate: moment(data.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                        EndDate: moment(data.endDate).format('YYYY-MM-DDTHH:mm:ss'),
                        Count: this.calculateNewLeaveCount(data.startDate, data.endDate),
                        VacationId: this.state.vacationId ? this.state.vacationId : null
                    });
                }
            });
            if (selectedRow[0].vacationPlan.length > 0) {
                this.setState({ didVacationPlanChanged: true });
                selectedRow[0].vacationPlan.map((data, ind) => {
                    if (data.startDate && data.startDate !== '' && data.endDate && data.endDate !== '') {
                        newVacationPlan.push({
                            IsDeleted: false,
                            StartDate: moment(data.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                            EndDate: moment(data.endDate).format('YYYY-MM-DDTHH:mm:ss'),
                            Count: this.calculateNewLeaveCount(data.startDate, data.endDate),
                            VacationId: data.vacationID ? data.vacationID : null
                        });
                    }
                });
            }
            console.log(newVacationPlan);
            if (didVacationPlanChanged) {
                let userID = capacityPlanDataStore.userID;
                if (!userID || userID === 0 ) {
                    await capacityPlanDataStore.accountStore.getLoggedInUserDetailsIfUserIdZero(capacityPlanDataStore.accountStore.userName).then(() => {
                        capacityPlanDataStore.userID = capacityPlanDataStore.accountStore.getUserID();
                    });
                    if (!capacityPlanDataStore.userID || capacityPlanDataStore.userID === 0 ) {
                        return;
                    }
                }
                const requestParams = [] as any;
                selectedRow.map(item => {
                    const requestParam = {
                        DriverID: item.id,
                        Driver: item.name,
                        VacationPlan: newVacationPlan,
                        Month: item.month,
                        Year: item.year,
                        CreatedBy: capacityPlanDataStore.userID,
                        CreatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        ModifiedBy: capacityPlanDataStore.userID,
                        DeletedRecords: deletedVacationPlansId,
                        AvailabilityProvided: 1
                    };
                    requestParams.push(requestParam);
                });
                capacityPlanDataStore.AssignVacationPlan(requestParams).then(() => {
                    this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, selectedCompany);
                });
            }
            this.setState({
                selectedRow: defaultSelectedRow,
                selectedRowDataBeforeEdit: defaultSelectedRow,
                startDate: '',
                endDate: '',
                didVacationPlanChanged: false,
                applyForDateRange: false,
                applyForFullMonth: false,
                vacationId: null,
                additionalRow: [{ endDate: '', startDate: '' }]
            });
            uiService.loaderService.hideLoader();
        }
    };

    assignAvailability = async () => {
        const {
            startDate,
            endDate,
            selectedRow,
            dataSource,
            deletedVacationPlansId,
            didVacationPlanChanged,
            selectedMonthLabel,
            selectedYear,
            selectedCompany
        } = this.state;
        const { capacityPlanDataStore } = this.props;
        uiService.loaderService.showLoader();
        const requestParams = [] as any;
        let userID = capacityPlanDataStore.userID;
        if (!userID || userID === 0 ) {
            await capacityPlanDataStore.accountStore.getLoggedInUserDetailsIfUserIdZero(capacityPlanDataStore.accountStore.userName).then(() => {
                capacityPlanDataStore.userID = capacityPlanDataStore.accountStore.getUserID();
            });
            if (!capacityPlanDataStore.userID || capacityPlanDataStore.userID === 0 ) {
                return;
            }
        }
        selectedRow.map(item => {
            const requestParam = {
                DriverID: item.id,
                Driver: item.name,
                VacationPlan: [],
                Month: item.month,
                Year: item.year,
                CreatedBy: capacityPlanDataStore.userID,
                CreatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                ModifiedBy: capacityPlanDataStore.userID,
                DeletedRecords: deletedVacationPlansId,
                AvailabilityProvided: 1
            };
            requestParams.push(requestParam);
        });

        capacityPlanDataStore.AssignVacationPlan(requestParams).then(() => {
            this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, selectedCompany);
        });
        this.setState({
            selectedRow: defaultSelectedRow,
            selectedRowDataBeforeEdit: defaultSelectedRow,
            startDate: '',
            endDate: '',
            didVacationPlanChanged: false,
            applyForDateRange: false,
            applyForFullMonth: false,
            additionalRow: [{ endDate: '', startDate: '' }]
        });
        uiService.loaderService.hideLoader();
    };

    applyForEntireMonth = () => {
        const { selectedYear, selectedMonth } = this.state;
        const monthStartDate = moment(new Date(selectedYear, selectedMonth, 1)).format('YYYY-MM-DD');
        const monthEndDate = moment(new Date(selectedYear, selectedMonth + 1, 0)).format('YYYY-MM-DD');
        if (this.checkExistingPlansInBetweenDates(monthStartDate, monthEndDate, 0)) {
            uiService.toastService.info(
                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
            );
        } else {
            this.setState({
                additionalRow: [{ endDate: monthEndDate, startDate: monthStartDate }],
                startDate: monthStartDate,
                endDate: monthEndDate,
                applyForFullMonth: true,
                didVacationPlanChanged: true,
                applyForDateRange: false
            });
        }
    };

    handleSearch = value => {
        const { dataSource, responseData } = this.state;
        console.log(value);
        const responseObject = responseData;
        if (value && value !== '') {
            let list = [] as any;
            list =
                value !== ''
                    ? this.filterObjectByValue(responseObject, option =>
                          option.name.toLowerCase().includes(value.toLowerCase())
                      )
                    : responseObject;
            const newData = Object.keys(list).map(i => list[i]);
            this.setState({
                dataSource: newData
            });
        } else {
            this.setState({
                dataSource: this.createDataSourceToDisplay(this.props.capacityPlanDataStore.capacityPlanningData)
            });
        }
    };

    filterObjectByValue = (obj, predicate) => {
        return Object.keys(obj)
            .filter(key => predicate(obj[key]))
            .reduce((res, key) => ((res[key] = obj[key]), res), {});
    };

    showReadableViewOnly = (month, year) => {
        const monthIndex = this.months.indexOf(month);
        const currentDate = new Date().getDate();
        const currentMonthIndex = new Date().getMonth();
        const getCurrentYear = new Date().getFullYear();
        if (year < getCurrentYear) {
            return true;
        } else {
            if (year === getCurrentYear) {
                if(this.checkCCLogin()) {
                    if((currentDate <= 15 && monthIndex <= currentMonthIndex + 1) || (currentDate > 15 && monthIndex <= currentMonthIndex + 2)) {
                        return true;
                    }
                } else return monthIndex < currentMonthIndex;
            } else if (year > getCurrentYear && monthIndex >= currentMonthIndex) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {},
                    ConfirmWithOK,
                    {primaryText: 'Capacity can be planned only for 12 months ahead.'} as ConfirmWithOKProps,
                    'confirmPopup'
                );
                return true;
            }
            return false;
        }
    };

    handleChangeMonth(month) {
        if (this.state.didVacationPlanChanged) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    this.setState({
                        selectedMonth: month,
                        selectedMonthLabel: moment.months()[month],
                        datesOfMonth: Array.from(
                            Array(this.daysInMonth(month + 1, this.state.selectedYear)),
                            (_, i) => i + 1
                        ),
                        noOfDaysInAMonth: Array.from(
                            Array(this.daysInMonth(month + 1, this.state.selectedYear)),
                            (_, i) => i + 1
                        ).length,
                        selectedRow: defaultSelectedRow,
                        selectedRowDataBeforeEdit: defaultSelectedRow,
                        didVacationPlanChanged: false,
                        readableOnly: this.showReadableViewOnly(moment.months()[month], this.state.selectedYear)
                    });
                    this.getMasterData(
                        this.props.capacityPlanDataStore,
                        moment.months()[month],
                        this.state.selectedYear,
                        this.state.selectedCompany
                    );
                },
                '',
                'Unsaved changes on the plan will be lost. Are you sure you want to navigate away?'
            );
        } else {
            this.setState({
                selectedMonth: month,
                selectedMonthLabel: moment.months()[month],
                datesOfMonth: Array.from(Array(this.daysInMonth(month + 1, this.state.selectedYear)), (_, i) => i + 1),
                noOfDaysInAMonth: Array.from(
                    Array(this.daysInMonth(month + 1, this.state.selectedYear)),
                    (_, i) => i + 1
                ).length,
                selectedRow: defaultSelectedRow,
                selectedRowDataBeforeEdit: defaultSelectedRow,
                didVacationPlanChanged: false,
                readableOnly: this.showReadableViewOnly(moment.months()[month], this.state.selectedYear)
            });
            this.getMasterData(this.props.capacityPlanDataStore, moment.months()[month], this.state.selectedYear,this.state.selectedCompany);
        }
    }

    handleChangeYear = year => {
        if (this.state.didVacationPlanChanged) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    this.setState({
                        selectedYear: year,
                        datesOfMonth: Array.from(
                            Array(this.daysInMonth(this.state.selectedMonth + 1, year)),
                            (_, i) => i + 1
                        ),
                        noOfDaysInAMonth: Array.from(
                            Array(this.daysInMonth(this.state.selectedMonth + 1, year)),
                            (_, i) => i + 1
                        ).length,
                        selectedRow: defaultSelectedRow,
                        selectedRowDataBeforeEdit: defaultSelectedRow,
                        didVacationPlanChanged: false,
                        readableOnly: this.showReadableViewOnly(this.state.selectedMonthLabel, year)
                    });
                    this.getMasterData(this.props.capacityPlanDataStore, this.state.selectedMonthLabel, year, this.state.selectedCompany);
                },
                '',
                'Unsaved changes on the plan will be lost. Are you sure you want to navigate away?'
            );
        } else {
            this.setState({
                selectedYear: year,
                datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1),
                noOfDaysInAMonth: Array.from(
                    Array(this.daysInMonth(this.state.selectedMonth + 1, year)),
                    (_, i) => i + 1
                ).length,
                selectedRow: defaultSelectedRow,
                selectedRowDataBeforeEdit: defaultSelectedRow,
                didVacationPlanChanged: false,
                readableOnly: this.showReadableViewOnly(this.state.selectedMonthLabel, year)
            });
            this.getMasterData(this.props.capacityPlanDataStore, this.state.selectedMonthLabel, year, this.state.selectedCompany);
        } }
    sortCarrierCompany(carrierName) {
        var sortOrder = 1;
        if (carrierName[0] === "-") {
            sortOrder = -1;
            carrierName = carrierName.substr(1);
        }
        return function (a, b) {
            if (sortOrder == -1) {
                return b[carrierName].localeCompare(a[carrierName]);
            } else {
                return a[carrierName].localeCompare(b[carrierName]);
            }
        }
    }
    handleCarrierCompanyChange = option => {
        let selectoption: any = [];
        if (option && option.length > 0) {
            selectoption = Array.from(new Set(option.map((item: any) => item.label)));
            this.setState((this.selectedOption = option));
        } 
        else {
            selectoption = []
            this.setState((this.selectedOption = selectoption));
            }
        this.carrierCompaniesFiltered(selectoption);
    };
    carrierCompaniesFiltered(name) {
        if (name.length > 0) {
            const reports: any = this.state.dataBackupSource.filter(element => {
                return name.includes(element.companyName);
            });
            this.setState({
                dataSource: reports
            })
        }
         else {
            this.setState({
                dataSource: this.state.dataBackupSource
            })
        }
    }
    renderSelectedComponent = () => {
        const { selectedMonth, selectedYear, selectedCompany } = this.state;
        return (
        <Container fluid><Row><Col>
            <div className="form-inline padding-bottom">
                <div className="timeline-label-div-style">View Timeline : </div>
                    Year
                <div className="select-dropdown">
                    <select
                        name="year"
                        key="year"
                        className="select-style"
                        onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                        value={selectedYear}
                    >
                        {this.state.yearsRange.map((yearData, index) => (
                            <option key={index} value={yearData}>
                                {yearData}
                            </option>
                        ))}
                    </select>
                </div>
                    Month
                <div className="select-dropdown">
                    <select
                        name="month"
                        key="month"
                        onChange={e => this.handleChangeMonth(parseInt(e.currentTarget.value, 10))}
                        value={selectedMonth}
                        className="select-style"
                    >
                        {this.months.map((month, i) => (
                            <option key={i} value={i}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                {dataStore.checkOperationAccess('CCDropdown') && (
                    <>
                        Carrier Company
                        <div className="select-carrier">
                        <Select
                            value={this.selectedOption}
                            placeholder="Select Carrier Company"
                            closeMenuOnSelect={false}
                            closeMenuOnScroll={true}
                            options={this.state.companyList}
                            onChange={this.handleCarrierCompanyChange}
                            isMulti={true}
                        /> </div> </>
                )}
                <CustomButton type={CustomButtonType.Submit} onClick={this.handleRefresh}>
                    Refresh
                </CustomButton>
                <CustomButton type={CustomButtonType.Submit} onClick={this.exportExcel} disabled={this.state.dataSource.length == 0} >
                    Export to excel
                </CustomButton>{' '}
                    </div>
        </Col></Row>
        </Container>
        );
    };

    convertDateFormat(inputDate) {
        let [month, day, year] = inputDate.split("/");
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    convertDateMonthFormat(inputDate) {
        let dateObject = new Date(inputDate)
        let monthAbbreviation = dateObject.toLocaleString('default', { month: 'short' });

        let formattedDay = dateObject.getDate();
        let formattedYear = dateObject.getFullYear();

        let result = formattedDay + '-' + monthAbbreviation + '-' + formattedYear;
        return result;
    }
    exportExcel = () =>{
        let excelArray: any = [];
        let startDate = new Date(this.state.selectedYear, this.state.selectedMonth, 1).toLocaleDateString('en-US');
        let endDate = new Date(this.state.selectedYear+((this.state.selectedMonth+1)/12), (this.state.selectedMonth+1)%12, 0).toLocaleDateString('en-US');
        uiService.loaderService.showLoader();
        this.state.dataSource.map((x,index) => {
            let stdate = startDate
            let JSONObj =
                    {
                        DriverName: x.name,
                        CarrierCompany: x.companyName,   
                    }
            while (this.convertDateFormat(stdate) <= this.convertDateFormat(endDate)) {
                if(x.availabilityProvided == true) {
                    JSONObj[this.convertDateMonthFormat(stdate)] = 1    
                    if(x.vacationPlan.length>0){
                        x.vacationPlan.map((y,ind) => {
                            if(this.convertDateFormat(stdate) >= y.startDate.substring(0,10) && this.convertDateFormat(stdate) <= y.endDate.substring(0,10)){
                                JSONObj[this.convertDateMonthFormat(stdate)] = 0        
                            }
                        })
                    } else if (x.vacationPlan.length === 0) {
                       JSONObj[this.convertDateMonthFormat(stdate)] = 1    
                    }
                }
                else {                
                    JSONObj[this.convertDateMonthFormat(stdate)] = null    
                }
                
                let date = new Date(stdate)
                stdate = new Date(date.setDate(date.getDate()+1)).toLocaleDateString('en-US');     
            } 
            excelArray.push(JSONObj);
        });
        console.log(excelArray);
        const ws = XLSX.utils.json_to_sheet(excelArray);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const exceldata: Blob = new Blob([excelBuffer], {
                type: EXCEL_TYPE
            });
            uiService.loaderService.hideLoader();
            FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
    };
    handleLeaveStartDate = (selectedDay, id) => {
        const { endDate, additionalRow, selectedRow } = this.state;
        let additionalData = additionalRow;
        const selectedDate = moment(selectedDay).format('YYYY-MM-DD');
        let ind = 0;
        selectedRow[0].vacationPlan.map((x,index) => {
            if (x.vacationID === id) {
                console.log(index);
                ind = index;
            }
        });
        if (selectedRow[0].vacationPlan[ind].endDate !== '' && selectedRow[0].vacationPlan[ind].endDate < selectedDate) {
            this.editSelectedRow[ind].toDate.getInput().focus();
            uiService.toastService.info('StartDate cannot be greater than EndDate');
        } else if (this.checkExistingPlansInBetweenDates(selectedDate, selectedRow[0].vacationPlan[ind].endDate, id)) {
            this.editSelectedRow[ind].toDate.getInput().focus();
            uiService.toastService.info(
                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
            );
        } else {
            this.editSelectedRow[ind].toDate.getInput().focus();
            // additionalData[id].startDate = selectedDate;
            selectedRow[0].vacationPlan[ind].startDate = selectedDate;
            this.setState({
                selectedRow
            });
            if (selectedDate !== '' && selectedRow[0].vacationPlan[ind].endDate !== '') {
                this.setState({
                    didVacationPlanChanged: true
                });
            }
        }
    };

    handleNewLeaveStartDate = (selectedDay, id) => {
        const { endDate, additionalRow } = this.state;
        let additionalData = additionalRow;
        const selectedDate = moment(selectedDay).format('YYYY-MM-DD');
        if (additionalRow[id].endDate !== '' && additionalRow[id].endDate < selectedDate) {
            this.toDate.getInput().focus();
            uiService.toastService.info('StartDate cannot be greater than EndDate');
        } else if (this.checkExistingPlansInBetweenDates(selectedDate, additionalRow[id].endDate, id)) {
            this.toDate.getInput().focus();
            uiService.toastService.info(
                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
            );
        } else {
            this.toDate.getInput().focus();
            additionalData[id].startDate = selectedDate;
            this.setState({
                additionalRow: additionalData
            });
            if (selectedDate !== '' && additionalData[id].endDate !== '') {
                this.setState({
                    didVacationPlanChanged: true
                });
            }
        }
    };

    handleLeaveEndDate = (selectedDay, id) => {
        const { startDate, additionalRow, selectedRow } = this.state;
        let additionalData = additionalRow;
        const selectedDate = moment(selectedDay).format('YYYY-MM-DD');
        let ind = 0;
        selectedRow[0].vacationPlan.map((x,index) => {
            if (x.vacationID === id) {
                console.log(index);
                ind = index;
            }
        });
        if (selectedDate < selectedRow[0].vacationPlan[ind].startDate && selectedRow[0].vacationPlan[ind].startDate !== '') {
            this.editSelectedRow[ind].toDate.getInput().focus();
            this.setState({
                endDate: ''
            });
            uiService.toastService.info('StartDate cannot be greater than EndDate');
        } else if (this.checkExistingPlansInBetweenDates(selectedRow[0].vacationPlan[ind].startDate, selectedDate, id)) {
            this.editSelectedRow[ind].toDate.getInput().focus();
            this.setState({
                endDate: ''
            });
            uiService.toastService.info(
                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
            );
        } else {
            selectedRow[0].vacationPlan[ind].endDate = selectedDate;
            this.setState({
                selectedRow
            });
            if (selectedRow[0].vacationPlan[ind].startDate !== '' && selectedDate !== '') {
                this.setState({
                    didVacationPlanChanged: true
                });
            }
        }
    };

    handleNewLeaveEndDate = (selectedDay, id) => {
        const { startDate, additionalRow } = this.state;
        let additionalData = additionalRow;
        const selectedDate = moment(selectedDay).format('YYYY-MM-DD');
        
        if (selectedDate < additionalRow[id].startDate && additionalRow[id].startDate !== '') {
            this.toDate.getInput().focus();
            additionalData[id].endDate = '';
            this.setState({
                additionalRow: additionalData
            });
            uiService.toastService.info('StartDate cannot be greater than EndDate');
        } else if (this.checkExistingPlansInBetweenDates(additionalRow[id].startDate, selectedDate, id)) {
            this.toDate.getInput().focus();
            additionalData[id].endDate = '';
            this.setState({
                additionalRow: additionalData
            });
            uiService.toastService.info(
                'Some leave plans are already assigned in between dates. Please delete them before creating new.'
            );
        } else {
            additionalData[id].endDate = selectedDate;
            this.setState({
                additionalRow: additionalData
            });
            if (additionalData[id].startDate !== '' && selectedDate !== '') {
                this.setState({
                    didVacationPlanChanged: true
                });
            }
        }
    };

    checkExistingPlansInBetweenDates = (date1, date2, id) => {
        const { selectedRow } = this.state;
        const appliedLeaveDates = this.disabledDatesInCalendarInput(selectedRow, id);
        let vacationPlanExist = false;
        const dateArrayBetweenStartAndEndDate = [] as any;
        const start = parseInt(moment(new Date(date1.toString())).format('D'));
        const end = parseInt(moment(new Date(date2.toString())).format('D'));
        for (let i = 0; i <= end - start; i++) {
            const date = moment(date1).add(i, 'days');
            if (appliedLeaveDates.filter(item => item.toString() === new Date(date.toString()).toString()).length > 0) {
                vacationPlanExist = true;
                break;
            }
        }
        return vacationPlanExist;
    };

    handleAdditionalDaysOff = (id, startDate, endDate, count, disabled) => {
        const { selectedRow, selectedYear, selectedMonth, applyForFullMonth, additionalRow } = this.state;
        const disabledDateStyle = `.DayPicker-Day--disabled {
        background-color: grey;
        color: white;
      }`;
        
        const disableInputstyle = disabled;
        const disabledDays = this.disabledDatesInCalendarInput(selectedRow, id);
        console.log(selectedRow, id);
        let ind = 0;
        selectedRow[0].vacationPlan.map((x,index) => {
                this.editSelectedRow.push({ fromDate: '', toDate: '' });
            if (x.vacationID === id) {
                console.log(index);
                ind = index;
                
            }
        });
        const vacationdays = selectedRow[0].vacationPlan;
        const countDaysoff = this.calculateNewLeaveCount(vacationdays[ind].startDate, vacationdays[ind].endDate);
        console.log(ind);
        return (
            <div className="start-end-date-input-container">
                <style>{disabledDateStyle}</style>
                <div className="start-container">
                    <DayPickerInput
                        ref={el => (this.editSelectedRow[ind].fromDate = el)}
                        dayPickerProps={{
                            month: new Date(selectedYear, selectedMonth, 1),
                            canChangeMonth: false,
                            className: 'day-picker',
                            disabledDays: disabledDays
                        }}
                        value={
                            vacationdays[ind].startDate !== ''
                                ? moment(vacationdays[ind].startDate)
                                      .format('YYYY-MM-DD')
                                      .toString()
                                : ''
                        }
                        onDayChange={selectedDay => this.handleLeaveStartDate(selectedDay, id)}
                        inputProps={{
                            style: {
                                width: '90%',
                                height: '35px',
                                border: disableInputstyle ? '1px solid #1eb2f3' : '1px solid red',
                                borderRadius: 5,
                                textAlign: 'center',
                                fontSize: 10
                            },
                            disabled: applyForFullMonth || disabled,
                            value:
                            vacationdays[ind].startDate !== ''
                                    ? moment(vacationdays[ind].startDate)
                                          .format('YYYY-MM-DD')
                                          .toString()
                                    : '',
                            placeholder: 'yyyy-mm-dd'
                        }}
                    />
                </div>

                <div className="end-container">
                    <DayPickerInput
                        ref={el => (this.editSelectedRow[ind].toDate = el)}
                        dayPickerProps={{
                            month: new Date(selectedYear, selectedMonth, 1),
                            canChangeMonth: false,
                            className: 'day-picker',
                            disabledDays: disabledDays
                        }}
                        value={
                            vacationdays[ind].endDate !== ''
                                ? moment(vacationdays[ind].endDate)
                                      .format('YYYY-MM-DD')
                                      .toString()
                                : ''
                        }
                        onDayChange={selectedDay => this.handleLeaveEndDate(selectedDay, id)}
                        inputProps={{
                            style: {
                                width: '90%',
                                height: '35px',
                                border: disableInputstyle ? '1px solid #1eb2f3' : '1px solid red',
                                borderRadius: 5,
                                textAlign: 'center',
                                fontSize: 10
                            },
                            disabled: applyForFullMonth || disabled,
                            value:
                            vacationdays[ind].endDate !== ''
                                    ? moment(vacationdays[ind].endDate)
                                          .format('YYYY-MM-DD')
                                          .toString()
                                    : '',
                            placeholder: 'yyyy-mm-dd'
                        }}
                    />
                </div>

                <div className="day-off-container">
                    <label className="day-off-value">{countDaysoff}</label>
                </div>

                {disabled && (
                    <div className="icon-div">
                        <FontAwesomeIcon
                            icon="trash"
                            size="xs"
                            onClick={() => {
                                this.deleteItem(id, startDate, endDate);
                            }}
                        />
                    </div>
                )}
                {disabled &&
                    !this.state.additionalbutton &&
                    !this.state.selectedRow[0].vacationPlan.editDisabled && (
                        <div className="icon-div">
                            <FontAwesomeIcon
                                icon="edit"
                                size="sm"
                                onClick={() => {
                                    this.editItem(id, startDate, endDate, count);
                                }}
                            />
                        </div>
                    )}
                {!disabled && !this.state.selectedRow[0].vacationPlan.editDisabled && (
                    <div className="icon-div">
                        <FontAwesomeIcon
                            icon="times"
                            size="sm"
                            onClick={() => {
                                this.cancelEdit(id);
                            }}
                        />
                    </div>
                )}
            </div>
        );
    };

    handleNewAdditionalDaysOff = (id, startDate, endDate, count, disabled) => {
        const { selectedRow, selectedYear, selectedMonth, applyForFullMonth, additionalRow } = this.state;
        const disabledDateStyle = `.DayPicker-Day--disabled {
        background-color: grey;
        color: white;
      }`;
        const countDaysoff = this.calculateNewLeaveCount(startDate, endDate);
        const disableInputstyle = disabled;
        const disabledDays = this.disabledDatesInCalendarInput(selectedRow, id);
        return (
            <div className="start-end-date-input-container">
                <style>{disabledDateStyle}</style>
                <div className="start-container">
                    <DayPickerInput
                        ref={el => (this.fromDate = el)}
                        dayPickerProps={{
                            month: new Date(selectedYear, selectedMonth, 1),
                            canChangeMonth: false,
                            className: 'day-picker',
                            disabledDays: disabledDays
                        }}
                        value={
                            startDate !== ''
                                ? moment(startDate)
                                      .format('YYYY-MM-DD')
                                      .toString()
                                : ''
                        }
                        onDayChange={selectedDay => this.handleNewLeaveStartDate(selectedDay, id)}
                        inputProps={{
                            style: {
                                width: '90%',
                                height: '35px',
                                border: disableInputstyle ? '1px solid #1eb2f3' : '1px solid red',
                                borderRadius: 5,
                                textAlign: 'center',
                                fontSize: 10
                            },
                            disabled: applyForFullMonth || disabled,
                            value: startDate !== '' ? moment(startDate)
                                  .format('YYYY-MM-DD')
                                  .toString()
                            : '',
                            placeholder: 'yyyy-mm-dd',
                            readOnly: true
                        }}
                    />
                </div>

                <div className="end-container">
                    <DayPickerInput
                        ref={el => (this.toDate = el)}
                        dayPickerProps={{
                            month: new Date(selectedYear, selectedMonth, 1),
                            canChangeMonth: false,
                            className: 'day-picker',
                            disabledDays: disabledDays
                        }}
                        value={
                            endDate !== ''
                                ? moment(endDate)
                                      .format('YYYY-MM-DD')
                                      .toString()
                                : ''
                        }
                        onDayChange={selectedDay => this.handleNewLeaveEndDate(selectedDay, id)}
                        inputProps={{
                            style: {
                                width: '90%',
                                height: '35px',
                                border: disableInputstyle ? '1px solid #1eb2f3' : '1px solid red',
                                borderRadius: 5,
                                textAlign: 'center',
                                fontSize: 10
                            },
                            disabled: applyForFullMonth || disabled,
                            value:
                                endDate !== ''
                                    ? moment(endDate)
                                          .format('YYYY-MM-DD')
                                          .toString()
                                    : '',
                            placeholder: 'yyyy-mm-dd',
                            readOnly: true
                        }}
                    />
                </div>

                <div className="day-off-container">
                    <label className="day-off-value">{countDaysoff}</label>
                </div>

                {disabled && (
                    <div className="icon-div">
                        <FontAwesomeIcon
                            icon="trash"
                            size="xs"
                            onClick={() => {
                                this.deleteItem(id, startDate, endDate);
                            }}
                        />
                    </div>
                )}
                {disabled &&
                    !this.state.additionalbutton &&
                    !this.state.selectedRow[0].vacationPlan.some(item => !item.editDisabled) && (
                        <div className="icon-div">
                            <FontAwesomeIcon
                                icon="edit"
                                size="sm"
                                onClick={() => {
                                    this.editItem(id, startDate, endDate, count);
                                }}
                            />
                        </div>
                    )}
                {!disabled && this.state.selectedRow[0].vacationPlan.some(item => !item.editDisabled) && (
                    <div className="icon-div">
                        <FontAwesomeIcon
                            icon="times"
                            size="sm"
                            onClick={() => {
                                this.cancelEdit(id);
                            }}
                        />
                    </div>
                )}


{this.state.additionalRow.length > 1 && (
                    <div className="icon-div">
                        <FontAwesomeIcon
                            icon="times"
                            size="sm"
                            onClick={() => {
                                this.cancelNewRow(id);
                            }}
                        />
                    </div>
                )}
            </div>
        );
    };

    onDriverSelection = (checked, item) => {
        if (this.state.selectedRow.some(row => row.id === item.id)) {
            const filteredSelectedRow = this.state.selectedRow.filter(row => row.id !== item.id);
            this.setState({
                selectedRow: filteredSelectedRow.length > 0 ? filteredSelectedRow : defaultSelectedRow,
                // selectedRowDataBeforeEdit: filteredSelectedRow.length > 0 ? filteredSelectedRow : defaultSelectedRow,
                startDate: '',
                endDate: '',
                additionalbutton: false,
                applyForFullMonth: false,
                applyForDateRange: true,
                deletedVacationPlansId: [],
                didVacationPlanChanged: filteredSelectedRow.length > 0 && this.state.didVacationPlanChanged
            });
            return;
        }
        const alreadySelectedRow = this.state.selectedRow === defaultSelectedRow ? [] : this.state.selectedRow;
        // alreadySelectedRow.forEach(x => {
        //     this.editSelectedRow.push({ fromDate: '', toDate: '' });
        // })
        if (checked && !this.isRefreshed) {
            if (this.state.didVacationPlanChanged) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>
                        this.setState({
                            selectedRow: [...alreadySelectedRow, ...[item]],
                            // selectedRowDataBeforeEdit: [...alreadySelectedRow, ...[item]],
                            startDate: '',
                            endDate: '',
                            additionalbutton: false,
                            applyForFullMonth: false,
                            deletedVacationPlansId: [],
                            additionalRow: [{ endDate: '', startDate: '' }]

                        }),
                    '',
                    'Unsaved changes on the plan will be lost. Are you sure you want to navigate away?'
                );
            } else {
                this.setState({
                    selectedRow: [...alreadySelectedRow, ...[item]],
                    // selectedRowDataBeforeEdit: [...alreadySelectedRow, ...[item]],
                    startDate: '',
                    endDate: '',
                    additionalbutton: false,
                    applyForFullMonth: false,
                    applyForDateRange: true,
                    deletedVacationPlansId: [],
                    additionalRow: [{ endDate: '', startDate: '' }]
                });
            }
        } else {
            this.setState({
                selectedRow: defaultSelectedRow,
                selectedRowDataBeforeEdit: defaultSelectedRow,
                additionalRow: [{ endDate: '', startDate: '' }]
            });
        }
    };

    disabledDatesInCalendarInput = (selectedRow, id) => {
        const disableDatesAraay = [] as any;
        if (selectedRow && selectedRow.some(item => item.vacationPlan.length > 0)) {
            selectedRow.map(row =>
                row.vacationPlan.map(item => {
                    if (id !== item.vacationID) {
                        const start = parseInt(moment(new Date(item.startDate.toString())).format('D'));
                        const end = parseInt(moment(new Date(item.endDate.toString())).format('D'));
                        for (let i = 0; i <= end - start; i++) {
                            const date = moment(item.startDate).add(i, 'days');
                            disableDatesAraay.push(new Date(date.toString()));
                        }
                    }
                })
            );
        }
        return disableDatesAraay;
    };

    calculateNewLeaveCount = (startDate, endDate) => {
        const { applyForFullMonth, noOfDaysInAMonth } = this.state;
        return applyForFullMonth
            ? noOfDaysInAMonth
            : moment(new Date(endDate)).diff(new Date(startDate), 'days')
            ? moment(new Date(endDate)).diff(new Date(startDate), 'days') + 1
            : startDate !== '' && endDate === startDate
            ? 1
            : 0;
    };

    calculateTotalLeaveCountOfSelectedDriver = () => {
        const { selectedRow, startDate, endDate, additionalRow } = this.state;
        let count = 0;
        if (selectedRow.length === 1 && selectedRow[0].vacationPlan.length > 0) {
            selectedRow[0].vacationPlan.map(item =>
                item.editDisabled ? (count = count + item.count) : (count = count + item.count)
            );
        }

        let newCount = 0;
        if (additionalRow.length > 0) {
            additionalRow.forEach(data => {
                if (data.startDate && data.startDate !== '' && data.endDate && data.endDate !== '') {
                    newCount = newCount + this.calculateNewLeaveCount(data.startDate, data.endDate);
                }
            });
        }
        return count + newCount;
    };

    deleteItem = (id, startDate, endDate) => {
        const { selectedRow, deletedVacationPlansId } = this.state;
        deletedVacationPlansId.push(id);
        const selectedRowVacationPlanAfterDeletion = selectedRow[0].vacationPlan.filter(
            item => item.startDate !== startDate && item.endDate !== endDate
        );
        const selectedRowAfterDeletion = [{ ...selectedRow[0], vacationPlan: selectedRowVacationPlanAfterDeletion }];
        this.setState({
            selectedRow: selectedRowAfterDeletion,
            selectedRowDataBeforeEdit: selectedRowAfterDeletion,
            didVacationPlanChanged: true,
            deletedVacationPlansId
        });
    };

    editItem = (id, startDate, endDate, count) => {
        const { selectedRow, deletedVacationPlansId } = this.state;
        this.setState({
            selectedRowDataBeforeEdit: selectedRow
        });
        this.setState({
            vacationId: id
        });
        console.log(selectedRow[0].vacationPlan);
        let fileterdSelectedRowVacationPlan: any = [];
        let index = selectedRow[0].vacationPlan.map((item, index) => {
            if (item.vacationID === id ) {
                fileterdSelectedRowVacationPlan.push({
                    vacationID: id,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    count: item.count,
                    IsDeleted: false,
                    editDisabled: false
                });
            } else {
                fileterdSelectedRowVacationPlan.push(item);
            }
        });
        // let fileterdSelectedRowVacationPlan = selectedRow[0].vacationPlan.filter(
        //     item => item.startDate !== startDate && item.endDate !== endDate
        // );
        // fileterdSelectedRowVacationPlan.push({
        //                 vacationID: id,
        //                 startDate: startDate,
        //                 endDate: endDate,
        //                 count: count,
        //                 IsDeleted: false,
        //                 editDisabled: false
        // })
        const selectedRowOnEditClicked = [{ ...selectedRow[0], vacationPlan: fileterdSelectedRowVacationPlan }];
        // console.log(fileterdSelectedRowVacationPlan, selectedRowOnEditClicked);
        this.setState({
            selectedRow: selectedRowOnEditClicked
            // didVacationPlanChanged: true,
        });
    };

    cancelNewRow = id => {
        const { additionalRow } = this.state;
        const newRow = additionalRow;
        console.log(id, additionalRow, newRow);
        newRow.splice(newRow[id], 1);
        console.log(newRow)
        this.setState({
            additionalRow: newRow
        });
    };

    cancelEdit = (id) => {
        const { selectedRow, deletedVacationPlansId } = this.state;

        let fileterdSelectedRowVacationPlan: any = [];
        console.log(selectedRow[0].vacationPlan);
        let index = selectedRow[0].vacationPlan.map((item, index) => {
            if (item.vacationID === id ) {
                item.editDisabled = true;
                fileterdSelectedRowVacationPlan.push({
                    vacationID: id,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    count: item.count,
                    IsDeleted: false,
                    editDisabled: true
                });
            } else {
                fileterdSelectedRowVacationPlan.push(item);
            }
        });
        console.log(selectedRow[0].vacationPlan);
        const selectedRowOnEditClicked = [{ ...selectedRow[0], vacationPlan: fileterdSelectedRowVacationPlan }];
        // console.log(fileterdSelectedRowVacationPlan, selectedRowOnEditClicked);
        this.setState({
            selectedRow
            // didVacationPlanChanged: true,
        });
        // this.setState({
        //     selectedRow: selectedRowDataBeforeEdit,
        //     startDate: '',
        //     endDate: ''
        // });
    };

    onAdditionalButtonClick = () => {
        console.log(this.state.additionalRow);
        const newVacationPlan = this.state.additionalRow; //.map((item, index) => { item.vacationPlan });
        if (this.state.additionalbutton || this.state.selectedRow[0].vacationPlan.length === 0) newVacationPlan.push({ endDate: '', startDate: '' });
        else {
            this.setState({
                additionalbutton: true
            });
        }

        this.setState({
            additionalRow: newVacationPlan,
            additionalbutton: true
        });
    };
    sortDrivers = (listOfDrivers) => {
        return listOfDrivers.sort((a,b) => {
            a = a.name.trim().replace("  "," ").split(" ");
            b = b.name.trim().replace("  "," ").split(" ");
            const a_length = a.length - 1;
            const b_length = b.length - 1;
            if(a[a_length].toLowerCase() < b[b_length].toLowerCase()) return -1;
            else if(a[a_length].toLowerCase() > b[b_length].toLowerCase()) return 1;
            else return 0;
        });
    }
    handleRefresh = () => {
        const { capacityPlanDataStore } = this.props;
        const { selectedMonthLabel, selectedYear } = this.state;
        if(!this.state.didVacationPlanChanged) {
        this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, this.state.selectedCompany);
        this.setState({
            selectedRow: defaultSelectedRow,
            startDate: '',
            endDate: '',
            additionalbutton: false,
            applyForFullMonth: false,
            deletedVacationPlansId: [],
            additionalRow: [{ endDate: '', startDate: '' }]
        })
        this.isRefreshed = true;
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    
                    this.getMasterData(capacityPlanDataStore, selectedMonthLabel, selectedYear, this.state.selectedCompany);
                    this.setState({
                        selectedRow: defaultSelectedRow,
                        startDate: '',
                        endDate: '',
                        additionalbutton: false,
                        applyForFullMonth: false,
                        deletedVacationPlansId: [],
                        additionalRow: [{ endDate: '', startDate: '' }]
                    })
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
            this.isRefreshed = true;
        }
        this.isRefreshed = false;
        this.setState({didVacationPlanChanged: false})
    }

    render(): React.ReactNode {
        const {
            selectedMonth,
            selectedYear,
            dataSource,
            selectedRow,
            additionalbutton,
            selectedMonthLabel,
            datesOfMonth,
            noOfDaysInAMonth,
            startDate,
            endDate,
            didVacationPlanChanged,
            readableOnly,
            additionalRow
        } = this.state;
        const thWidth = 75 / noOfDaysInAMonth;
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const generateDate = date => {
            return moment(new Date(selectedYear, selectedMonth, date).toISOString()).format('MM/DD/YYYY');
        };
        return (
            <Container fluid>
                <Row className="mt-3">
                    <Col>{this.renderSelectedComponent()}</Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex justify-content-between" style={{ backgroundColor: '#ffffff' }}>
                            <div className="p-2" style={{ width: '20%', backgroundColor: '#F6F6F6' }}>
                                <div className=" d-flex justify-content-between search-input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Search Driver"
                                        onChange={event => this.handleSearch(event.currentTarget.value)}
                                        className="search-input-textField"
                                    ></input>
                                    <FontAwesomeIcon icon="search" className="search-icon" />
                                </div>
                            </div>
                            <div
                                className="p-2"
                                style={{
                                    alignItems: 'center',
                                    borderRight: '1px solid #c3c3c3',
                                    width: `${noOfDaysInAMonth * thWidth}%`
                                }}
                            >
                                <label style={{ color: 'grey', fontSize: 20, marginLeft: '140px' }}>
                                    Driver Availability Timeline :{' '}
                                </label>{' '}
                                <label style={{ color: '#000000', fontSize: 20, marginLeft: 5 }}>
                                    {selectedMonthLabel} {selectedYear}
                                </label>
                            </div>
                        </div>
                        <div
                            className="main-Container"
                            style={{ pointerEvents: readableOnly ? 'none' : 'all', opacity: readableOnly ? 0.5 : 1 }}
                        >
                            <div className="table-wrapper">
                                <table className="main-table">
                                    <tbody>
                                        <tr>
                                            <th className="table-heading-select">Select</th>
                                            <th className="table-heading-drivers">Drivers</th>

                                            {datesOfMonth.map((date, index) => (
                                                <th
                                                    className="table-heading-dates"
                                                    style={{ width: `${thWidth}%` }}
                                                    key={index}
                                                >
                                                    {daysOfWeek[new Date(generateDate(date)).getDay()]} <br/> 
                                                    {date}
                                                </th>
                                            ))}
                                        </tr>

                                        {dataSource.map((item, index) => {
                                            if (item.vacationPlan.length > 0) {
                                                const lastSpan =
                                                    noOfDaysInAMonth -
                                                    parseInt(
                                                        moment(
                                                            new Date(
                                                                item.vacationPlan[
                                                                    item.vacationPlan.length - 1
                                                                ].endDate.toString()
                                                            )
                                                        ).format('D')
                                                    );
                                                let widthofLastSpan = 0;
                                                return (
                                                    <tr className="table-rows" key={index}>
                                                        <td className="checkbox-style">
                                                            <input
                                                                type="checkbox"
                                                                style={{ width: 25, height: 25 }}
                                                                onChange={checked => {
                                                                    this.onDriverSelection(checked, item);
                                                                }}
                                                                multiple={false}
                                                                disabled={!dataStore.checkOperationAccess('Save')}
                                                                checked={
                                                                    selectedRow &&
                                                                    selectedRow.some(driver => driver.id === item.id)
                                                                }
                                                            ></input>
                                                        </td>
                                                        <td className="table-coloumn-driver">{item.name}<br/>
                                                        {dataStore.checkOperationAccess('CCDropdown') && (
                                                            <> ({item.companyName}) </> )}
                                                        </td>
                                                        {item.vacationPlan.map((plan, index) => {
                                                            const endSpan =
                                                                parseInt(
                                                                    moment(new Date(plan.endDate.toString())).format(
                                                                        'D'
                                                                    )
                                                                ) -
                                                                (index - 1 >= 0
                                                                    ? parseInt(
                                                                          moment(
                                                                              new Date(
                                                                                  item.vacationPlan[
                                                                                      index - 1
                                                                                  ].endDate.toString()
                                                                              )
                                                                          ).format('D')
                                                                      )
                                                                    : 0);
                                                            const availableDifference =
                                                                parseInt(
                                                                    moment(new Date(plan.startDate.toString())).format(
                                                                        'D'
                                                                    )
                                                                ) -
                                                                (index - 1 >= 0
                                                                    ? parseInt(
                                                                          moment(
                                                                              new Date(
                                                                                  item.vacationPlan[
                                                                                      index - 1
                                                                                  ].endDate.toString()
                                                                              )
                                                                          ).format('D')
                                                                      )
                                                                    : 0) -
                                                                1;
                                                            const availableWidth =
                                                                availableDifference * (100 / noOfDaysInAMonth);
                                                            const notAvailableWidth =
                                                                (endSpan - availableDifference) *
                                                                (100 / noOfDaysInAMonth);
                                                            widthofLastSpan =
                                                                100 - (availableWidth + notAvailableWidth);
                                                            return (
                                                                <td
                                                                    scope="column"
                                                                    colSpan={endSpan}
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        borderBottom: '1px solid #e5e5e5',
                                                                        width: `${availableWidth + notAvailableWidth}%`
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <table className="main-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                {availableDifference > 0 && (
                                                                                    <td
                                                                                        scope="column"
                                                                                        colSpan={
                                                                                            availableDifference === 1
                                                                                                ? 1
                                                                                                : availableDifference +
                                                                                                  1
                                                                                        }
                                                                                        style={{
                                                                                            width: `${availableWidth}%`,
                                                                                            backgroundColor:
                                                                                                'transparent'
                                                                                        }}
                                                                                    >
                                                                                        <div className="not-available-main-container">
                                                                                            <div className="available-bar">
                                                                                                <label className="not-available-label">
                                                                                                    {availableDifference ===
                                                                                                    1
                                                                                                        ? 1
                                                                                                        : availableDifference}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                )}
                                                                                {endSpan - availableDifference !==
                                                                                    0 && (
                                                                                    <td
                                                                                        scope="column"
                                                                                        colSpan={
                                                                                            endSpan -
                                                                                            availableDifference
                                                                                        }
                                                                                        style={{
                                                                                            width: `${notAvailableWidth}%`,
                                                                                            backgroundColor:
                                                                                                'transparent'
                                                                                        }}
                                                                                    >
                                                                                        <div className="not-available-main-container">
                                                                                            <div className="not-available-bar">
                                                                                                <label className="not-available-label">
                                                                                                    {endSpan -
                                                                                                        availableDifference ===
                                                                                                    1
                                                                                                        ? 1
                                                                                                        : endSpan -
                                                                                                          availableDifference}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            );
                                                        })}
                                                        {lastSpan !== 0 && (
                                                            <td
                                                                colSpan={lastSpan}
                                                                style={{
                                                                    backgroundColor: 'transparent',
                                                                    borderBottom: '1px solid #e5e5e5',
                                                                    width: `${widthofLastSpan}%`
                                                                }}
                                                            >
                                                                <table className="main-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td
                                                                                scope="column"
                                                                                colSpan={lastSpan}
                                                                                style={{ width: `${widthofLastSpan}%` }}
                                                                            >
                                                                                <div className="not-available-main-container">
                                                                                    <div className="available-bar">
                                                                                        <label className="not-available-label">
                                                                                            {lastSpan}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            } else {
                                                return (
                                                    <tr className="table-rows" key={index}>
                                                        <td className="checkbox-style">
                                                            <input
                                                                type="checkbox"
                                                                style={{ width: 25, height: 25 }}
                                                                onChange={checked => {
                                                                    this.onDriverSelection(checked, item);
                                                                }}
                                                                multiple={false}
                                                                disabled={!dataStore.checkOperationAccess('Save')}
                                                                checked={
                                                                    selectedRow &&
                                                                    selectedRow.some(driver => driver.id === item.id)
                                                                }
                                                            ></input>
                                                        </td>
                                                        <td className="table-coloumn-driver">{item.name}<br/>
                                                        {dataStore.checkOperationAccess('CCDropdown') && (
                                                            <> ({item.companyName}) </> )}
                                                        </td>
                                                        <td
                                                            colSpan={noOfDaysInAMonth}
                                                            style={{
                                                                backgroundColor: 'transparent',
                                                                borderBottom: '1px solid #e5e5e5',
                                                                width: '75%'
                                                            }}
                                                        >
                                                            <table className="main-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            scope="column"
                                                                            colSpan={noOfDaysInAMonth}
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <div className="not-available-main-container">
                                                                                {item.availabilityProvided && (
                                                                                    <div className="available-bar">
                                                                                        <label className="not-available-label">
                                                                                            {noOfDaysInAMonth}
                                                                                        </label>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                                {dataSource.length === 0 && (
                                    <div className="defaultpage__parent_container">
                                        <img
                                            className="defaultpage__defaulticon"
                                            src={defaultpageicon}
                                            alt="DefaultPage"
                                        />
                                        <label className="defaultpage__nodatafound">No Rows to Show</label>
                                    </div>
                                )}
                            </div>

                            {/* Right View */}
                            <div
                                className="right-view-container"
                                style={{ pointerEvents: selectedRow.some(item => item.id === 0) ? 'none' : 'all' }}
                            >
                                <div className="right-view-main-container">
                                    {/* Status Bar */}
                                    <div className="right-availablity-container">
                                        <div className="right-available-circle"></div>
                                        <label className="right-availablity-label">Available</label>
                                    </div>

                                    <div className="right-availablity-container">
                                        <div className="right-not-available-circle"></div>
                                        <label className="right-availablity-label">Unavailable</label>
                                    </div>
                                </div>
                                <div className="day-off-driver-name-container">
                                    <label className="day-off-label">
                                        Days Off : ''
                                        {/* {selectedRow ? selectedRow.name : ''} */}
                                    </label>{' '}
                                    <div className="radio-button-container">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id="dateRange"
                                                name="date"
                                                value="month"
                                                onChange={() => {
                                                    this.applyForEntireMonth();
                                                }}
                                                disabled={
                                                    !(
                                                        additionalbutton ||
                                                        selectedRow.some(item => item.vacationPlan.length === 0)
                                                    )
                                                }
                                                checked={this.state.applyForFullMonth}
                                            ></input>
                                            <label className="right-availablity-label">Apply to entire month</label>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id="fullMonth"
                                                name="date"
                                                value="select"
                                                onChange={() => {
                                                    this.setState({
                                                        applyForFullMonth: false,
                                                        applyForDateRange: true
                                                    });
                                                }}
                                                disabled={
                                                    !(
                                                        additionalbutton ||
                                                        selectedRow.some(item => item.vacationPlan.length === 0)
                                                    )
                                                }
                                                checked={this.state.applyForDateRange}
                                            ></input>
                                            <label className="right-availablity-label">Select Date Range</label>
                                        </div>
                                    </div>
                                    <div className="start-end-container">
                                        <div className="start-container">
                                            <label className="start-end-day-off-label">Start</label>
                                        </div>

                                        <div className="end-container">
                                            <label className="start-end-day-off-label">End</label>
                                        </div>

                                        <div className="day-off-container">
                                            <label className="start-end-day-off-label">Days Off</label>
                                        </div>
                                    </div>
                                    {selectedRow.length === 1 &&
                                        selectedRow.some(item => item.vacationPlan.length !== 0) && (
                                            <div className="start-end-date-scrollable-container">
                                                {selectedRow[0].vacationPlan.map(item => {
                                                    if (item.editDisabled) {
                                                        return this.handleAdditionalDaysOff(
                                                            item.vacationID,
                                                            item.startDate,
                                                            item.endDate,
                                                            item.count,
                                                            item.editDisabled
                                                        );
                                                    } else {
                                                        return this.handleAdditionalDaysOff(
                                                            item.vacationID,
                                                            startDate,
                                                            endDate,
                                                            this.calculateNewLeaveCount(startDate, endDate),
                                                            item.editDisabled
                                                        );
                                                    }
                                                })}
                                            </div>
                                        )}
                                    {(additionalbutton ||
                                        (selectedRow.length >= 1 &&
                                            selectedRow.some(item => item.vacationPlan.length === 0))) &&
                                        additionalRow.map((data, ind) => {
                                            return this.handleNewAdditionalDaysOff(
                                                ind,
                                                data.startDate,
                                                data.endDate,
                                                this.calculateNewLeaveCount(data.startDate, data.endDate),
                                                false
                                            );
                                        })}
                                    <div className="additional-day-off-container">
                                        <button
                                            className={
                                                //additionalbutton //||
                                                //selectedRow.some(item => item.vacationPlan.length < 1 || item.vacationPlan.some((vacation) => !vacation.editDisabled))
                                                //? 'disabled-additional-day-off-label'
                                                'additional-day-off-label'
                                            }
                                            onClick={() => {
                                                this.onAdditionalButtonClick();
                                            }}
                                            // disabled={
                                            //     additionalbutton //&&
                                            //     //selectedRow.some(item => item.vacationPlan.length < 1)
                                            // }
                                        >
                                            {' '}
                                            + Add Additional Days Off
                                        </button>
                                        <div className="total-count-container">
                                            <label className="total-days-off">Total Days Off</label>
                                            <label className="day-off-value">
                                                {this.calculateTotalLeaveCountOfSelectedDriver()}
                                            </label>
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: 5, width: '90%' }} />
                                    <div className="button-container">
                                        <button
                                            type="button"
                                            className={
                                                selectedRow.length > 1
                                                    ? 'disabled-assign-button-style'
                                                    : 'reset-button-style'
                                            }
                                            onClick={this.reset}
                                            disabled={selectedRow.length > 1}
                                        >
                                            RESET
                                        </button>
                                        <button
                                            type="button"
                                            className={
                                                didVacationPlanChanged
                                                    ? 'assign-button-style'
                                                    : 'disabled-assign-button-style'
                                            }
                                            onClick={this.assignVacation}
                                            disabled={!didVacationPlanChanged}
                                        >
                                            ASSIGN
                                        </button>
                                    </div>
                                    <div className="button-container">
                                        <button
                                            type="button"
                                            className={
                                                selectedRow.some(
                                                    item => item.vacationPlan.length > 0 || item.availabilityProvided
                                                )
                                                    ? 'disabled-assign-button-style'
                                                    : 'assign-button-style'
                                            }
                                            onClick={this.assignAvailability}
                                            disabled={selectedRow.some(
                                                item => item.vacationPlan.length > 0 || item.availabilityProvided
                                            )}
                                        >
                                            ZERO DAYS OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CapacityPlanComponent;
