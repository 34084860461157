import React, { Component } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Col, Container, Row } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { localStorageService } from 'shared/services/local-storage-service';
import { DispatchBoardDataStore } from '../store/dispatch-board-store';
import { DriverStatusCellRenderer } from '../cell-renderers/driver-status-custom-cell-renderer';
import './style.scss';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';

interface DriverListsPopUpProps {
    dataSource: any;
    dispatchBoardDataStore: DispatchBoardDataStore;
}

@inject('dispatchBoardDataStore')
@observer
export class DriverListPopupComponent extends Component<DriverListsPopUpProps> {

    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        };
    }

    render(): React.ReactNode {
        const {
            dataSource
        } = this.props;
        return (
            <Container fluid>
                <div>
                    <Row>
                        <Col>
                            <ClearButtonComponent disabled={this.isClearAllDisabled()}></ClearButtonComponent>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('DispatchBoard_Detail_Filters');
        this.props.dispatchBoardDataStore.agGridService.setAllFilters(filters);
    }

    isClearAllDisabled = (): boolean => {
        if (this.props.dataSource.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    private getGridConfig(): GridOptions {
        const {
            dispatchBoardDataStore: { agGridService }
        } = this.props;
        const { dispatchBoardDataStore, dataSource } = this.props;

        const rowData = dataSource;
        const columnDefs = dispatchBoardDriverListColDef;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }
}

export const dispatchBoardDriverListColDef: ColDef[] = [
    {
        headerName: 'Driver Name',
        field: 'Driver',
        minWidth: 200,
        valueGetter: params => {
            if (params.data['Driver'] === null) {
                return '';
            } else {
                return params.data['Driver'];
            }
        }
    },
    {
        headerName: 'Company Name',
        field: 'CompanyName',
        minWidth: 380,
        valueGetter: params => {
            if (params.data['CompanyName'] === null) {
                return '';
            } else {
                return params.data['CompanyName'];
            }
        }
    },
    {
        headerName: 'Status',
        field: 'Availability',
        minWidth: 200,
        cellRendererFramework: DriverStatusCellRenderer
    },
];
