import { DriverHeaderName, DriverFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';

export const driverColDef: ColDef[] = [
    { headerName: DriverHeaderName.ID, field: DriverFieldName.ID, hide: true },
    {
        headerName: DriverHeaderName.FIRSTNAME,
        field: DriverFieldName.FIRSTNAME,
        colId: DriverFieldName.FIRSTNAME,
        width: 90,
        editable: () => (dataStore.checkOperationAccess('FirstNameEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('FirstNameEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[DriverFieldName.FIRSTNAME] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.FIRSTNAME];
            }
        }
    },
    {
        headerName: DriverHeaderName.LASTNAME,
        field: DriverFieldName.LASTNAME,
        colId: DriverFieldName.LASTNAME,
        width: 90,
        cellClass: () => (dataStore.checkOperationAccess('LastNameEdit') ? 'cell-editable' : ''),
        editable: () => (dataStore.checkOperationAccess('LastNameEdit') ? true : false),
        valueGetter: params => {
            if (params.data[DriverFieldName.LASTNAME] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.LASTNAME];
            }
        }
    },
    {
        headerName: DriverHeaderName.PHONENUMBER,
        field: DriverFieldName.PHONENUMBER,
        colId: DriverFieldName.PHONENUMBER,
        width: 120,
        editable: () => (dataStore.checkOperationAccess('PhoneEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('PhoneEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[DriverFieldName.PHONENUMBER] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.PHONENUMBER];
            }
        }
    },
    {
        headerName: DriverHeaderName.LOGINNAME,
        field: DriverFieldName.LOGINNAME,
        width: 100,
        valueGetter: params => {
            if (params.data[DriverFieldName.LOGINNAME] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.LOGINNAME];
            }
        }
    },
    {
        headerName: DriverHeaderName.CARRIERNAME,
        field: DriverFieldName.CARRIERNAME,
        width: 130,
        valueGetter: params => {
            if (params.data[DriverFieldName.CARRIERNAME] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.CARRIERNAME];
            }
        }
    },
    {
        headerName: DriverHeaderName.LASTLOGINDATE,
        field: DriverFieldName.LASTLOGINDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[DriverFieldName.LASTLOGINDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[DriverFieldName.LASTLOGINDATE]);
            }
        }
    },
    {
        headerName: DriverHeaderName.REGION,
        field: DriverFieldName.REGION,
        width: 70,
        valueGetter: params => {
            if (params.data[DriverFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.REGION];
            }
        }
    },
    {
        headerName: DriverHeaderName.CREATEDDATE,
        field: DriverFieldName.CREATEDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[DriverFieldName.CREATEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[DriverFieldName.CREATEDDATE]);
            }
        }
    },
    {
        headerName: DriverHeaderName.MODIFIEDBY,
        field: DriverFieldName.MODIFIEDBY,
        width: 95,
        valueGetter: params => {
            if (params.data[DriverFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[DriverFieldName.MODIFIEDBY];
            }
        }
    },
    {
        headerName: DriverHeaderName.MODIFIEDDATE,
        field: DriverFieldName.MODIFIEDDATE,
        colId: DriverFieldName.MODIFIEDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[DriverFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[DriverFieldName.MODIFIEDDATE]);
            }
        }
    },
    {
        headerName: DriverHeaderName.STATUS,
        field: DriverFieldName.STATUS,
        width: 70,
        valueGetter: params => {
            if (params.data[DriverFieldName.STATUS] === true) {
                return 'Active';
            } else {
                return 'InActive';
            }
        }
    }
];
