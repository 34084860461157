export enum DriverMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Driver data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes'
}

export enum DriverButtons {
    SAVE = 'Save Driver Data',
    RESET = 'Reset'
}

export enum DriverHeaderName {
    ID = 'ID',
    LOGINNAME = 'Login Name',
    FIRSTNAME = 'First Name',
    LASTNAME = 'Last Name',
    PHONENUMBER = 'Phone Number',
    CARRIERNAME = 'Carrier Company',
    LASTLOGINDATE = 'Last Login Date',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    CREATEDDATE = 'Created Date',
    REGION = 'Region',
    STATUS = 'Status'
}

export enum DriverFieldName {
    ID = 'ID',
    LOGINNAME = 'LoginName',
    FIRSTNAME = 'FirstName',
    LASTNAME = 'LastName',
    PHONENUMBER = 'CellPhoneNo',
    CARRIERNAME = 'CarrierName',
    LASTLOGINDATE = 'LoginDateTime',
    MODIFIEDBY = 'ModifiedByUserName',
    MODIFIEDDATE = 'ModifiedDate',
    CREATEDDATE = 'CreatedDate',
    REGION = 'Region',
    STATUS = 'IsActive'
}
