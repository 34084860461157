export interface DateRange {
    StartDate: string;
    EndDate: string;
    CCUID?: number;
}
export interface DailyReportResponse {
    TicketDate: string;
    TicketNumber: string;
    Power_Unit_Identifier: string;
    Consignor: string;
    Consignee: string;
    Product: string;
    Estimated_Vol: string;
    Actual_Vol: string;
    CompanyName: string;
    Trailer: string;
    Status: string;
    Load_Date: string;
    Driver_Name: string;
}
export interface LeaseOperatorResponse {
    Product: string;
    ShipperTicketBOL: string;
    RoadBan: string;
    Via: string;
    LastPMTStatus: string;
    RelatedVolumeTicket: string;
    WorkingTime: string;
    ApproverComment: string;
    LeaseOperatorName: string;
    DriverName: string;
    TicketType: string;
    TicketStatus: string;
    TicketNumber: string;
    Origin: string;
    Destination: string;
    WorkingTimeReason: string;
    TicketDate: string;
    Trailer: string;
    Comments: string;
    UpdatedBy: string;
    WaitingTime: string;
    WaitingTimeReason: string;
    VolumeDelivered: string;
    UpdatedDate: string;
    TrailerStatus: boolean;
    TrailerMappedToCompany: boolean;
}
export interface HourlyReportResponse {
    Ticket_Date: string;
    Ticket_Number: string;
    Type: string;
    Lease_Operator: string;
    Driver_Name: string;
    Trailer: string;
    Consignor: string;
    Consignee: string;
    Product: string;
    Volume_Delivered: string;
    Working_Time: string;
    Working_Time_Reason: string;
    Waiting_Time: string;
    Waiting_Time_Reason: string;
    Related_Volume_Ticket: string;
    Comments: string;
    Status: string;
    Approver_Comment: string;
}

export interface CustomerReportResponse {
    Product: string;
    ShipperTicketBOL: string;
    RoadBan: string;
    Via: string;
    LastPMTStatus: string;
    RelatedVolumeTicket: string;
    WorkingTime: string;
    ApproverComment: string;
    LeaseOperatorName: string;
    DriverName: string;
    TicketType: string;
    TicketStatus: string;
    TicketNumber: string;
    Origin: string;
    Destination: string;
    VolumeDelivered: number;
    WorkingTimeReason: string;
    TicketDate: string;
    Trailer: string;
    Comments: string;
    UpdatedBy: string;
    WaitingTime: string;
    WaitingTimeReason: string;
    UpdatedDate: string;
    UomID: number;
    ConsignorName: string;
    ConsignorAddress: string;
    PowerUnitIdentifier: string;
    StartDate: string;
    EndDate: string;
    EstimatedVolume: string;
    ConsignorERPPhoneNumber: string;
    RunTickeScaleTicketNumber: number;
    LoadDateTime: string;
    GrossWeight: number;
    TareWeight: number;
    NetWeight: number;
    Density: number;
    BSW: number;
    OpenMeterReading: number;
    CloseMeterReading: number;
    MeterNumber: number;
    MeterFactor: number;
    CreatedBy: string;
    AuthorizedBy: string;
    Axles: string;
    CompletedDate: string;
    StartDateTime: string;
}
export interface EmailConfigurationResponse {
    ScreenName: string;
    EMailAddress: string;
    Frequency: string;
    FieldForReport: string;
    CreatedBy: string;
    CreatedDate: string;
    ModifiedBy: string;
    ModifiedDate: string;
    ConsignorID: number;
    ConsignorName: string;
}
export interface RejectedLoadResponse {
    TicketNumber: string;
    TicketDate: string;
    Consignee: string;
    Consignor: string;
    DriverName: string;
    CarrierCompany: string;
    TrailerNumber: string;
    Comments: string;
}
export interface RejectedDateRange {
    StartDate: string;
    EndDate: string;
    CC: number;
}
export interface PastDueDateResponse {
    ID: number,
    TrailerID: number,
    TrailerNumber: string,
    TicketNumber: string,
    DriverID: number,
    DriverName: string,
    PhoneNumber: string,
    CarrierCompanyID: number,
    CompanyName: string,
    CertificateOverdue: number,
    CertificateOverdueDate: string,
    OpsReviewDate: string,
    OpsReviewComments: string,
    OpsReview: string,
    MaintenanceReview: string,
    MaintenanceReviewDate: string,
    MaintenanceComments: string,
    Overrideflag: boolean | string,
    OverrideBy: number,
    OverrideDate: string,
    ReasontoOverride: string,
    OverrideByName: string,
    OpsReviewBy: number,
    OpsReviewByName: string,
    MaintenanceReviewBy: number,
    MaintenanceReviewByName: string,
    ClearanceFlag: boolean | string, 
    ClearanceComments: string, 
    ClearanceBy: number, 
    ClearanceDate: string
}
export interface PastDueViewModel {
  pastDueData: PastDueDateResponse[];
}
export const OperationsDropdown = [
    { value: 'New', label: 'New' },
    { value: 'Reviewed', label: 'Reviewed' },
    { value: 'Reviewed-Issue', label: 'Reviewed-Issue' }
];

export const MaintenanceDropdown = [
    { value: 'New', label: 'New' },
    { value: 'Reviewed', label: 'Reviewed' },
    { value: 'Reviewed-Issue', label: 'Reviewed-Issue' }
];
export const OverrideDropdown = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
];
