import { action, computed, observable, toJS } from 'mobx';
import { UiService, uiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { OperatorResponse, LocationResponse, Region } from '../domains/location-management/model';
import { LocationMessage, LocationFieldName } from '../domains/location-management/enum';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { FilterKeyForReferenceData } from '../domains/enum';
import { locationColDef } from '../domains/location-management/location-col-def';
import { DeleteRequest } from '../domains/location-management/model';
import { LocationDataService } from '../domains/location-management/location-data-service';
import { LocationValidationService } from '../services/location-management/location-validation-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';
import { INVALID_USERID } from '../data_constant';
import { AppConstant } from 'app_constant';
import { CommonUtils } from 'shared/services/common-utils';

interface stateProps {
    carrierList: any[];
}

export class LocationDataStore {
    @observable operators: OperatorResponse[] = [];
    @observable locationData: LocationResponse[] = [];
    @observable location: LocationResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    @observable dataToBeDeleted: DeleteRequest[] = [];
    @observable dataToBeDeletedID: number[] = [];
    operatorName: any[] = [];
    operator: any[] = [];
    selectedRow: any = [];
    isRefreshed: boolean = false;
    backupLocationData: LocationResponse[] = [];
    @observable baseValueDTO: Region[] = [];

    constructor(
        private locationDataSerivice: LocationDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private locationValidationService: LocationValidationService
    ) { }

    init(): void {
        this.getLocationData();
        this.getRegionData();
        this.dataToBeDeletedID = []
        this.userID = this.accountStore.getUserID();
        if (! this.userID ||  this.userID === 0) {
            errorHandler(INVALID_USERID);
            return;
        }
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return locationColDef;
    }

    @Loader
    @Catch(() => errorHandler(LocationMessage.FETCH_ERROR_MESSAGE))
    async getOperator(): Promise<void> {
        let reqbody = {
            AxOrRAData : ''
        }
        const operators = await this.locationDataSerivice.getOperator(reqbody);
        this.setOperatorList(operators);
        this.operatorName = [];
        this.operator = Array.from(new Set(this.OperatorList.map((item: any) => item.Description)));
        this.operator.forEach(name => {
            this.operatorName.push({ value: name, label: name });
        });
    }

    @Loader
    @Catch(() => errorHandler(LocationMessage.FETCH_ERROR_MESSAGE))
    async getLocationData(): Promise<void> {
        const locationData = await this.locationDataSerivice.getLocationData();
        this.setLocationData(locationData);
        this.setBackupLocationData(locationData);
        this.isRefreshed = false;
    }

    setBackupLocationData(backUpList: LocationResponse[]) {
        this.backupLocationData = backUpList;
    }

    @action
    updateRow = (selectedRowData: LocationResponse): void => {
        const updatedLocationIndex = this.locationData.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.locationData[updatedLocationIndex], selectedRowData) || this.isRefreshed) {
            // this.updateIDFieldBasedOnName(selectedRowData);
            this.mapEditableColumns(this.locationData[updatedLocationIndex], selectedRowData);
        }
    };

    @Loader
    @action
    async getRegionData(): Promise<void> {
        const Region = localStorageService.get('region');
        try {
            if (Region) {
                this.baseValueDTO = Region as Region[];
            } else {
                await this.locationDataSerivice
                    .getRegionTypes()
                    .then(response => {
                        let responseData = response.data['Data'];
                        let sortedDataForNorthRegion = responseData.filter((item) => item.Name.includes('North')).sort((a, b) => a.ID > b.ID);
                        let sortedDataForSouthRegion = responseData.filter((item) => item.Name.includes('South')).sort((a, b) => a.ID > b.ID)
                        this.baseValueDTO = [...sortedDataForNorthRegion, ...sortedDataForSouthRegion]
                        localStorageService.set('region', this.baseValueDTO);
                    })
                    .catch(function (error) {
                        console.log('error in getting Region', error);
                    });
            }
        }
        catch (e) {
            localStorageService.remove('region');
            console.log('error in getting Region', e);
        }
    }

    @action
    @Catch(() => errorHandler(LocationMessage.DELETEDERROR))
    async addRow(): Promise<void> {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let reqbody = {
            AxOrRAData : 'All'
        }
        const broadcastReceiver: any = await this.locationDataSerivice.getOperator(reqbody);
        const raConfig = await this.locationDataSerivice.getRAConfig();
        this.getRegionData();
        uiService.loaderService.hideLoader();
        confirmService.showLocationPopup(async (consginor, consignee, consignorRA, consigneeRA, loadSize, region) => {
            let userID = this.accountStore.getUserID()
            if (!userID || userID === 0 ) {
                await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                    userID = this.accountStore.getUserID();
                    this.userID = this.accountStore.getUserID();
                });
                if (!userID || userID === 0 ) {
                    return;
                }
            }
            const reqbody = {
                ID: 0,
                CreatedBy: this.accountStore.getUserID(),
                Consignor: consginor,
                Consignee: consignee,
                RAConsignee: consigneeRA,
                RAConsignor: consignorRA,
                DeletedRecords: [],
                CreatedDate:moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss'),
                ModifiedBy: userID,
                CurrentLoadsize: loadSize,
                RegionID: region
            }
            const response: any = await this.locationDataSerivice.sendOperator([reqbody]);
            if (response === "Record already exists") {
                this.uiService.toastService.error(LocationMessage.DUPLICATE_ERROR);
            } else {
                this.uiService.toastService.success(LocationMessage.SAVE);
            }
            this.dataToBeDeletedID = []
            this.getLocationData()
        },
        toJS(broadcastReceiver), toJS(this.baseValueDTO), this.locationDataSerivice,[], raConfig)
    };

    @action
    @Loader
    @Catch(() => errorHandler(LocationMessage.DELETEDERROR))
    async deleteRows(): Promise<void> {
        if (this.dataToBeDeletedID.length > 0) {
            const updateData = this.locationData.filter(x => !this.dataToBeDeleted.filter(y => y.ID === x.ID).length);
            const reqbody = {
                DeletedRecords: this.dataToBeDeletedID
            }
            await this.locationDataSerivice.sendOperator([reqbody]);
            this.locationData = updateData;
            this.isDeleteEnabled();
            this.uiService.toastService.success(LocationMessage.DELETEDSUCCESS);
            this.dataToBeDeletedID = []
            this.getLocationData()
        }
        else
            errorHandler(LocationMessage.NO_ROW_SELECTED);
    }
    
    @Loader
    @action
    @Catch(() => errorHandler(LocationMessage.FAILED_SUBMIT))
    async handleRestrictedFlag(location: any): Promise<void> {
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const confirmService = new ConfirmService();
        if(location.RestrictedLoadsize === null) 
            uiService.toastService.error("Please enter the Restricted Loadsize value")
        else {
            confirmService.showConfirmDialog(
                async () => {
                    uiService.loaderService.showLoader();
                    location.ModifiedBy = this.userID;
                    location.DeletedRecords = [];
                    location.IsRestrictedLoadsize = !location.IsRestrictedLoadsize;
                    const response = await this.locationDataSerivice.sendOperator([location]);
                    uiService.loaderService.hideLoader();
                    this.cellValueChangeMap = {};
                    this.getLocationData();
                },
                '',
                AppConstant.RESIZE_LOADS
            );
        }
    }
    
    isDeleteEnabled = (): boolean => {
        const isEnabled = this.dataToBeDeletedID.length > 0 ? false : true;
        return isEnabled;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @computed
    get OperatorList(): OperatorResponse[] {
        return toJS(this.operators);
    }

    @computed
    get LocationData(): LocationResponse[] {
        return toJS(this.locationData);
    }

    @action
    setOperatorList(operators: OperatorResponse[]): void {
        this.operators = operators;
    }
    @action
    setLocationData(locationData: LocationResponse[]): void {
        this.locationData = locationData;
    }

    @action
    reset(): void {
        this.cellValueChangeMap = {};
        this.setLocationData(this.backupLocationData);
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    updateLoadSizeRequest(updatedRows: LocationResponse[]): LocationResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.DeletedRecords = [];
        });
        return updatedRows;
    }
    @Loader
    @action
    async showRAPopUp(row, col, value, eventData): Promise<void> {
        const confirmService = new ConfirmService();
        uiService.loaderService.showLoader();
        let reqbody = {
            AxOrRAData: 'All'
        }

        const location: any = await this.locationDataSerivice.getOperator(reqbody);
        const broadcastReceiver = location.filter(x => x.SourceSystem === 'RA');
         uiService.loaderService.hideLoader();
        confirmService.showLocationAXPopup(async location => {
            this.setValueInChangeMap(row, col, value, location)
            this.cellValueChangeMap[row][col]['currentValue'] = location;
            this.locationData.map(x => {
                if(x.ID === row) {
                    x[col] = location;
                }
            })
            
        }, toJS(broadcastReceiver));
    }


    @Loader
    @action
    @Catch(() => errorHandler(LocationMessage.UPLOAD_ERROR))
    async uploadLocationExcel(): Promise<void> {
        const confirmService = new ConfirmService();
        confirmService.showExcelPopup(async (consginor) => {
            let userID = this.accountStore.getUserID()
            if (!userID || userID === 0 ) {
                await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                    userID = this.accountStore.getUserID();
                    this.userID = this.accountStore.getUserID();
                });
                if (!userID || userID === 0 ) {
                    return;
                }
            }
            const reqbody = {
                FileName: "LoadSizeExcel",
                CreatedBy: userID,
                ModifiedBy: userID,
                ReportJson: consginor,
            }
            const errorInSave = false;
            const errorSummary: ToastMessage[] = [];
            uiService.loaderService.showLoader();
            const response = await this.locationDataSerivice.sendLocationExcelReport(reqbody);        uiService.loaderService.showLoader();
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                });
            } else {
                if (response['StatusCode'] === 422) {
                    this.uiService.toastService.error(response['Message']);
                } else {
                    this.dataToBeDeletedID = []
                    this.uiService.toastService.success(LocationMessage.UPLOAD_SUCCESS);
                    this.getLocationData()
                }
            }
            uiService.loaderService.hideLoader();
        })
    }

    validateUpdateRequest(updatedList: LocationResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNotEmpty(item[LocationFieldName.CONSIGNORPHONE]) && !CommonUtils.checkForPhoneNumberSyntax(item[LocationFieldName.CONSIGNORPHONE])) {
                errorMessage = `Consignor Phone Number must contain 10 digits`;
                return;
            }
        });
        return errorMessage;
    }

    @Loader
    @action
    async updateLoadSize(): Promise<void> {
        const updatedRowIDs = this.locationData.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateLoadSizeRequest(updatedRowIDs);
        const validationMessage = this.validateUpdateRequest(requestBody);
        if (!isEmpty(validationMessage)) {
            this.uiService.toastService.error(validationMessage);
            return;
        }
        let errorInSave = false;
        let errorSummary: ToastMessage[] = [];
        const response = await this.locationDataSerivice.sendOperator(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].ID) {
                    const objIndex = this.locationData.findIndex(a => a.ID === response[key].ID);
                    this.locationData[objIndex].ModifiedDate = response[key].ModifiedDate;
                    this.locationData[objIndex].ModifiedBy = this.userID;
                    for (let i = 0; i < this.backupLocationData.length; i++) {
                        const backupItem = this.backupLocationData[i];
                        if (backupItem.ID === response[key].ID) {
                            this.mapEditableColumns(backupItem, this.locationData[objIndex]);
                            break;
                        }
                    }
                }
                else {
                    errorInSave = true;
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.locationData.length; i++) {
                    const updatedShop = this.locationData[i];
                    if (updatedShop.ID.toString() === errorMessage.id) {
                        const backupShopIndex = this.backupLocationData.findIndex(a => a.ID.toString() == errorMessage.id);
                        this.mapEditableColumns(updatedShop, this.backupLocationData[backupShopIndex]);
                        break;
                    }
                }
            });
        }
        else {
            this.uiService.toastService.success(LocationMessage.SAVE);
        }
        this.dataToBeDeletedID = []
        this.getLocationData();
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    mapEditableColumns(currentItem: LocationResponse, updatedItem: LocationResponse) {
        if(currentItem) {
            currentItem.PlannedLoadsize = updatedItem.PlannedLoadsize;
            currentItem.RestrictedLoadsize = updatedItem.RestrictedLoadsize;
            currentItem.Consignor24Phone = updatedItem.Consignor24Phone ? updatedItem.Consignor24Phone.toString().replaceAll('(','').replaceAll(')','').replaceAll(' ','') : '';
            currentItem.ConsignorName = updatedItem.ConsignorName;
            currentItem.ConsignorAddress = updatedItem.ConsignorAddress;
            currentItem.ShortNames = updatedItem.ShortNames;
        }
    }


    @action
    async handleCheckboxClick(locationData: LocationResponse, value: any, colName: string): Promise<void> {
        const row = locationData.ID;
        this.dataToBeDeletedID = [row];
        this.selectedRow = locationData
    }
}
