import React from 'react';
import './_modal-popup.scss';
import { Button, Modal } from 'react-bootstrap';
import { APPROVED } from '../account_constant';
import { PLAINSUSEREMAILADDRESS } from '../account_constant';

interface EmailValidationPopUpProps {
    onLogin: () => void;
    userStatus: string
    onHide: () => void;
    show: boolean;
}

export const EmailValidationPopUpComponent = (props: EmailValidationPopUpProps) => {
    const { userStatus, onHide, onLogin, show } = props;
    const renderMessagebasedOnStatus = () => {
        if (userStatus === APPROVED) {
            return (
                <div>
                    <div>User already exists, Kindly login! </div>
                    <Button className="mt-3 mb-3" onClick={onLogin}>
                        Login
                </Button>
                </div>
            )
        }
        else if(userStatus === PLAINSUSEREMAILADDRESS)
        {
            return (
                <div>
                    <div>Users with Plainsmidstream account needs to sign up via service desk ticket.
                         Please contact service desk.</div>
                    <Button className="mt-3 mb-3" onClick={onHide}>
                        OK
                    </Button>
                </div>
            )
        }
        else {
            return (
                <div>
                    <div>User is either pending approval or already registered.
                         Please talk Plains service desk in case of any queries</div>
                    <Button className="mt-3 mb-3" onClick={onHide}>
                        OK
                    </Button>
                </div>
            )
        }
    }

    return (
        <Modal size="lg" onHide={onHide} show={show}>
            <Modal.Body>
                <div>{renderMessagebasedOnStatus()}</div>
            </Modal.Body>
        </Modal>
    );
};
