import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import '../components/style.scss'; 

interface DriverStatusCellRendererState {
    data: string;
}

export class DriverStatusCellRenderer extends PureComponent<ICellRendererParams, DriverStatusCellRendererState> {
    constructor(props: ICellRendererParams) {
        super(props);
        this.state = {
            data: props.data
        };
    }


    render(): React.ReactNode {
        return (
            <div className="circle-container-table">
                <div 
                    className="table-circle" 
                    style={{ backgroundColor: this.props.data.Color}}>
                </div>
                    {this.props.data.Availability}
            </div>
        );
    }
}
