export enum FilterKeyForReferenceData {
    DailyReport = 'DailyReport',
    LeaseOperator = 'LeaseOperatorReport',
    Select = 'Select',
    HourlyReport = 'HourlyReport',
    CustomerReport = 'CustomerReport',
    RejectedLoads = 'RejectedLoads',
    ScheduledReports ='ScheduledReports',
    PastDueCertificationReport = 'PastDueCertificationReport',
    UpcomingCertificationReport = 'UpcomingCertificationReport',
    SearchTickets = 'SearchTickets',
    MasterTicketReport = 'MasterTicketReport'
}
export enum PastDueSaveLabel {
    SAVE = 'Save',
}
export enum ReportMessage {
    DAILY_ERROR_MESSAGE = 'Daily Report data could not be loaded',
    DAILY_NO_RECORDS = 'No Records for selected Status',
    NO_RECORDS = 'No Records Found',
    UPLOAD_ERROR = 'Error Sending Report',
    LEASE_ERROR_MESSAGE = 'Carrier Company Report data could not be loaded',
    HOURLY_ERROR_MESSAGE = 'Hourly Report data could not be loaded',
    CUSTOMER_ERROR_MESSAGE = 'Customer Report data could not be loaded',
    REJECTED_ERROR_MESSAGE = 'Rejected Load data could not be loaded',
    SCHEDULED_ERROR_MESSAGE = 'Scheduled Reports data could not be loaded',
    PASTDUE_ERROR_MESSAGE = 'PastDue Reports data could not be loaded',
    SAVE = 'Saved Successfully',
    FAILED_SUBMIT = 'Failed to save changes'
}

export enum ReportsButton {
    PDF = 'Export to PDF',
    CSV = 'Export to Excel',
    PREV = '<<',
    NEXT = '>>'
}

export enum DailyRecordHeaderName {
    TICKETDATE = 'TICKET CREATION DATE',
    TICKETNUMBER = 'TICKET NUMBER',
    DRIVERNAME = 'DRIVER NAME',
    POWERUNIT = 'POWER UNIT IDENTIFIER',
    PRODUCT = 'PRODUCT',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    ACTUALVOL = 'ACTUAL VOLUME',
    ESTIMATEVOL = 'ESTIMATED VOLUME',
    STATUS = 'STATUS',
    LOADDATE = 'LOAD DATE',
    CARRIER = 'CARRIER COMPANY',
    TRAILER = 'TRAILER',
    COMPLETEDDATETIME = 'COMPLETED DATE TIME',
    LOADDATETIME = 'LOAD DATE TIME',
    REGION = 'REGION'
}
export enum DailyRecordFieldName {
    TICKETDATE = 'TicketDate',
    TICKETNUMBER = 'TicketNumber',
    DRIVERNAME = 'Driver_Name',
    POWERUNIT = 'Power_Unit_Identifier',
    PRODUCT = 'Product',
    CONSIGNOR = 'Consignor',
    CONSIGNEE = 'Consignee',
    ACTUALVOL = 'Actual_Vol',
    ESTIMATEVOL = 'Estimated_Vol',
    STATUS = 'Status',
    LOADDATE = 'Load_Date',
    CARRIER = 'CompanyName',
    TRAILER = 'Trailer',
    COMPLETEDDATETIME = 'CompletedDate',
    LOADDATETIME = 'Load_Date',
    REGION = 'Region'
}

export enum HourlyReportHeaderName {
    TICKETDATE = 'TICKET DATE',
    TICKETNUMBER = 'TICKET NUMBER',
    TYPE = 'TYPE',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    TRAILER = 'TRAILER',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    PRODUCT = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    RELATEDVOLUME = 'RELATED VOLUME TICKET',
    COMMENTS = 'COMMENTS',
    STATUS = 'STATUS',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    REGION = 'REGION'
}

export enum HourlyReportFieldName {
    TICKETDATE = 'Ticket_Date',
    TICKETNUMBER = 'Ticket_Number',
    TYPE = 'Type',
    LEASEOPERATOR = 'Lease_Operator',
    DRIVERNAME = 'Driver_Name',
    TRAILER = 'Trailer',
    CONSIGNOR = 'Consignor',
    CONSIGNEE = 'Consignee',
    PRODUCT = 'Product',
    VOLUMEDELIVERED = 'Volume_Delivered',
    WORKINGTIME = 'Working_Time',
    WORKINGTIMEREASON = 'Working_Time_Reason',
    WAITINGTIME = 'Waiting_Time',
    WAITINGTIMEREASON = 'Waiting_Time_Reason',
    RELATEDVOLUME = 'Related_Volume_Ticket',
    COMMENTS = 'Comments',
    STATUS = 'Status',
    APPROVERCOMMENT = 'Approver_Comment',
    REGION = 'Region'
}

export enum TitlePDF {
    DailyReport = 'Daily Report',
    LeaseOperator = 'Carrier Company Report',
    HourlyReport = 'Hourly Report',
    CustomerReport = 'Customer Report',
    RejectedLoads = 'Rejected Loads',
    ScheduledReports = 'Scheduled Reports',
    PastDueCertificationReport = 'Past Due Certification Report',
    UpcomingCertificationReport = 'Upcoming Certification Report',
    SearchTickets = 'SearchTickets'
}

export enum TicketHeaderName {
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'LAST UPDATED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'LEASE OPERATOR',
    DRIVERNAME = 'DRIVER NAME',
    TYPE = 'TYPE',
    STATUS = 'STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'UPDATED DATE',
    TICKETDATE = 'TICKET CREATION DATE',
    TRAILER = 'TRAILER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    COMPLETEDDATETIME = 'COMPLETED DATE TIME',
    LOADDATETIME = 'START/LOAD DATE TIME',
    REGION = 'REGION'
}

export enum TicketFieldName {
    PRODUCT = 'Product',
    SHIPPERTICKET = 'ShipperTicketBOL',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    LEASEOPERATOR = 'LeaseOperatorName',
    DRIVERNAME = 'DriverName',
    TYPE = 'TicketType',
    TICKETNUMBER = 'TicketNumber',
    STATUS = 'TicketStatus',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TICKETDATE = 'TicketDate',
    TRAILER = 'Trailer',
    ISAPPROVED = 'isApproved',
    APPROVERCOMMENT = 'ApproverComment',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    COMPLETEDDATETIME = 'CompletedDate',
    LOADDATETIME = 'LoadDATETIME',
    STARTDATETIME = 'StartDate',
    REGION = 'Region'
}

export enum CustomerReportHeaderName {
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    POWERUNITID = 'POWER UNIT IDENTIFIER',
    AXLES = 'AXLES',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'LAST UPDATED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    TYPE = 'TYPE',
    STATUS = 'STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'UPDATED DATE',
    TICKETDATE = 'TICKET CREATION DATE',
    TICKETTIME = 'TICKET TIME',
    TRAILER = 'TRAILER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    DENSITY = 'DENSITY',
    BSW = 'BSW',
    LOADDATE = 'LOAD DATE',
    LOADTIME = 'LOAD TIME',
    GROSSWEIGHT = 'GROSS WEIGHT',
    TAREWEIGHT = 'TARE WEIGHT',
    NETWEIGHT = 'NET WEIGHT',
    METERNUMBER = 'METER NUMBER',
    OPENMETERREADING = 'OPEN METER READING',
    CLOSEMETERREADING = 'CLOSE METER READING',
    METERFACTOR = 'METER FACTOR',
    RUNTICKET = 'RUN TICKET/SCALE TICKET',
    STARTDATE = 'START DATE',
    STARTTIME = 'START TIME',
    ENDDATE = 'END DATE',
    UNITS = 'UNITS',
    CONSIGNERADDRESS = 'CONSIGNOR ADDRESS',
    CONSIGNEORNUMBER = 'CONSIGNOR ERP PHONENUMBER',
    CREATEDBY = 'CREATED BY',
    AUTHORIZEDBY = 'AUTHORIZED BY',
    COMPLETEDDATE = 'COMPLETED DATE',
    COMPLETEDTIME = 'COMPLETED TIME',
    REGION = 'REGION'
}

export enum CustomerReportFieldName {
    PRODUCT = 'Product',
    SHIPPERTICKET = 'ShipperTicketBOL',
    POWERUNITID = 'PowerUnitIdentifier',
    AXLES = 'Axles',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    LEASEOPERATOR = 'LeaseOperatorName',
    DRIVERNAME = 'DriverName',
    TYPE = 'TicketType',
    TICKETNUMBER = 'TicketNumber',
    STATUS = 'TicketStatus',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TICKETDATE = 'TicketCreationDate',
    TICKETTIME = 'TicketTime',
    TRAILER = 'Trailer',
    ISAPPROVED = 'isApproved',
    APPROVERCOMMENT = 'ApproverComment',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    DENSITY = 'Density',
    BSW = 'BSW',
    LOADDATETIME = 'LoadDateTime',
    LOADDATE = 'LoadDate',
    LOADTIME = 'LoadTime',
    GROSSWEIGHT = 'GrossWeight',
    TAREWEIGHT = 'TareWeight',
    NETWEIGHT = 'NetWeight',
    METERNUMBER = 'MeterNumber',
    OPENMETERREADING = 'OpenMeterReading',
    CLOSEMETERREADING = 'CloseMeterReading',
    METERFACTOR = 'MeterFactor',
    RUNTICKET = 'RunTickeScaleTicketNumber',
    STARTDATE = 'StartDate',
    STARTTIME = 'StartTime',
    ENDDATE = 'EndDate',
    UNITS = 'UomID',
    CONSIGNERADDRESS = 'ConsignorAddress',
    CONSIGNEORNUMBER = 'ConsignorERPPhoneNumber',
    CREATEDBY = 'CreatedBy',
    AUTHORIZEDBY = 'AuthorizedBy',
    LASTPMTSTATUS = 'LastPMTStatus',
    COMPLETEDDATE = 'CompletedDate',
    COMPLETEDTIME = 'CompletedTime',
    UPDATEDDATE = 'UpdatedDate',
    TICKETDATETIME = 'TicketDate',
    STARTDATETIME = 'StartDateTime',
    COMPLETEDDATETIME = 'CompletedDateTime',
    REGION = 'Region'
}
export enum RejectedLoadHeaderName {
    TICKETNUMBER = 'TICKET NUMBER',
    TICKETDATE = 'TICKET DATE',
    CONSIGNEE = 'CONSIGNEE',
    CONSIGNOR = 'CONSIGNOR',
    DRIVERNAME = 'DRIVER NAME',
    MAPPEDCARRIERCOMPANY = 'MAPPED CARRIER COMPANY',
    TRAILERNUMBER = 'TRAILERNUMBER',
    COMMENT = 'COMMENTS'
}
export enum RejectedLoadFieldName {
    TICKETNUMBER = 'TicketNumber',
    TICKETDATE = 'TicketDate',
    CONSIGNEE = 'Consignee',
    CONSIGNOR = 'Consignor',
    DRIVERNAME = 'DriverName',
    MAPPEDCARRIERCOMPANY = 'CarrierCompany',
    TRAILERNUMBER = 'TrailerNumber',
    COMMENT = 'Comments'
}

export enum ScheduledReportHeaderName {
    REPORTYPE = 'REPORT TYPE',
    CREATEDBY = 'CREATED BY',
    CREATEDDATE = 'CREATED DATE',
    CONSIGNOR = 'CONSIGNOR',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE',
}
export enum ScheduledReportFieldName {
    REPORTYPE = 'Frequency',
    CREATEDBY = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    CONSIGNOR = 'ConsignorName'
}

export enum PastDueCertificationReportHeaderName {
    TRAILERNUMBER = 'TRAILER NUMBER',
    TICKETNUMBER = 'TICKET NUMBER',
    TRAILERSTATUS = 'TRAILER STATUS',
    DRIVERNAME = 'DRIVER NAME',
    PHONENUMBER = 'PHONE NUMBER',
    CARRIERCOMPANY = 'CARRIER COMPANY',
    CERTIFICATIONOVERDUE = 'CERTIFICATION OVERDUE',
    CERTIFICATIONOVERDUEDATE = 'CERTIFICATION OVERDUE DATE',
    OPERATIONSREVIEW = 'OPERATIONS REVIEW',
    OPERATIONSREVIEWDATE = 'OPERATIONS REVIEW DATE',
    OPERATIONSREVIEWCOMMENTS = 'OPERATIONS REVIEW COMMENTS',
    MAINTENANCEREVIEW = 'MAINTENANCE REVIEW',
    MAINTENANCEREVIEWDATE = 'MAINTENANCE REVIEW DATE',
    MAINTENANCEREVIEWCOMMENTS = 'MAINTENANCE REVIEW COMMENTS',
    OVERRIDEFLAG = 'OVERRIDE FLAG',
    OVERRIDEBY = 'OVERRIDE BY',
    OVERRIDEDATE = 'OVERRIDE DATE',
    REASONFOROVERRIDE = 'REASON FOR OVERRIDE',
  
    MAINTENANCEREVIEWEDID= 'MAINTENANCE REVIEWED BY ID',
    MAINTENANCEREVIEWEDBY = 'MAINTENANCE REVIEWED BY',
    OPERATIONSREVIEWEDBY = 'OPERATIONS REVIEWED BY',
    OPERATIONSREVIEWEDID= 'OPERATIONS REVIEWED BY ID',
    CLEARANCEFLAG = 'CLEARANCE FLAG',
    CLEARANCECOMMENTS = 'CLEARANCE COMMENTS'

}
export enum PastDueCertificationReportFieldName {
    ID = 'ID',
    TRAILERID = 'TrailerID',
    TRAILERNUMBER = 'TrailerNumber',
    TRAILERSTATUS = 'TrailerStatus',
    TICKETNUMBER = 'TicketNumber',
    DRIVERID = 'DriverID',
    DRIVERNAME = 'DriverName',
    PHONENUMBER = 'PhoneNumber',
    CARRIERCOMPANYID = 'CarrierCompanyID',
    CARRIERCOMPANY = 'CompanyName',
    CERTIFICATIONOVERDUE = 'CertificateOverdue',
    CERTIFICATIONOVERDUEDATE = 'CertificateOverdueDate',
    OPERATIONSREVIEW = 'OpsReview',
    OPERATIONSREVIEWDATE = 'OpsReviewDate',
    OPERATIONSREVIEWCOMMENTS = 'OpsReviewComments',
    MAINTENANCEREVIEW = 'MaintenanceReview',
    MAINTENANCEREVIEWDATE = 'MaintenanceReviewDate',
    MAINTENANCEREVIEWCOMMENTS = 'MaintenanceComments',
   OVERRIDEFLAG = 'Overrideflag',
    OVERRIDEBY = 'OverrideByName',
    OVERRIDEBYID = 'OverrideBy',
    OVERRIDEDATE = 'OverrideDate',
    REASONFOROVERRIDE = 'ReasontoOverride',
    OPERATIONSREVIEWEDID= 'OpsReviewBy',
    OPERATIONSREVIEWEDBY = 'OpsReviewByName',
    MAINTENANCEREVIEWEDID = 'MaintenanceReviewBy',
    MAINTENANCEREVIEWEDBY = 'MaintenanceReviewByName',
    CLEARANCEFLAG = 'ClearanceFlag',
    CLEARANCECOMMENTS = 'ClearanceComments'
    

}
