import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_maintenance-update-popup.scss';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from 'react-bootstrap/Alert'

interface MaintenanceUpdateProps {
    status: string;
    trailerType: string;
    onHide: () => void;
    onCancelUpadte: () => void;
    onConfirmUpdate: (ID: number ,data: any, completedDate: Date) =>void;
    selectedID: number;
    bookingDate: string;
}

interface MaintenanceUpdateState {
    startDate: Date | any,
    datePickerIsOpen: boolean,
    Acheck_Value: any,
    AcheckSelected: boolean,
    CVIP_Value: any,
    CVIPSelected: boolean,
    V_Value: any,
    VSelected: boolean,
    K_Value: any,
    KSelected: boolean,
    I_Value: any,
    ISelected: boolean,
    P_Value: any,
    PSelected: boolean,
    UC_Value: any,
    UCSelected: boolean,
    OTES_Value: any,
    OTESSelected: boolean,
    showAlert : boolean,
}

@observer
export class MaintenanceUpdateComponent extends Component<MaintenanceUpdateProps, MaintenanceUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            datePickerIsOpen: false,
            Acheck_Value: null,
            CVIP_Value: null,
            V_Value: null,
            K_Value: null,
            I_Value: null,
            P_Value: null,
            UC_Value: null,
            OTES_Value: null,
            AcheckSelected: false,
            CVIPSelected: false,
            VSelected: false,
            KSelected: false,
            ISelected: false,
            PSelected: false,
            UCSelected: false,
            OTESSelected: false,
            showAlert: false
        };
    }

    chekBox1Ref = {};
    chekBox2Ref = {};
    chekBox3Ref = {};
    chekBox4Ref = {};
    chekBox5Ref = {};
    chekBox6Ref = {};
    chekBox7Ref = {};
    chekBox8Ref = {};

    handleChange =(date) => {
        this.setState({
          startDate: date
        });
      }
    
      openDatePicker =() => {
        this.setState({
          datePickerIsOpen: this.state.datePickerIsOpen,
        });
      };
    
    render(): React.ReactNode {
        const { status } = this.props;
        const isDisabled = this.state.startDate !=null ? false : true;
        return (
            <div className="maintenance-update-popup__parent">
                <label className="maintenance-update-popup__label">Select any compliance checks that were completed, if applicable</label>
                <div style={{display: 'flex' }} >

                <div className="maintenance-update-popup__quesContainer">
                        <div className="maintenance-update-popup__ques">
                            <label> 1. A check: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="ACheckRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox1Ref['ACheckRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 2. CVIP: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="CVIPRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox2Ref['CVIPRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 3. V: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="VRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox3Ref['VRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 4. K: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="KRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox4Ref['KRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 5. I: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="IRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox5Ref['IRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 6. P: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="PRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox6Ref['PRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 7. U/C: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="UCRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox7Ref['UCRef'] = ref}
                            />
                        </div>
                        <div className="maintenance-update-popup__ques">
                            <label> 8. OTES: </label> 
                            <input className="maintenance-update-popup__quesCheckBox" type="checkbox" name="OTESRef" onChange={(e)=> this.setValueForMaintenceDate(e)} defaultChecked={false}
                                ref={(ref) => this.chekBox8Ref['OTESRef'] = ref}
                            />
                        </div>
                    </div>
                    
                    <div>
                         <div style={{ marginLeft: '60px', marginTop: '10px'}}>
                            <label className="maintenance-update-popup__labelMaitn">Select Maintenance Date</label>
                         <DatePicker
                             selected={this.state.startDate}
                             maxDate= {new Date()}
                             onChange={(date:Date) => {
                                 this.setState({startDate:date})
                                 let maintenceDate = date;
                                 let defaultValue = null;

                                 this.setState({Acheck_Value : defaultValue,
                                                CVIP_Value : defaultValue,
                                                V_Value : defaultValue,
                                                K_Value : defaultValue,
                                                I_Value : defaultValue,
                                                P_Value : defaultValue,
                                                UC_Value : defaultValue,
                                                OTES_Value : defaultValue,
                                                AcheckSelected: false,
                                                CVIPSelected: false,
                                                VSelected: false,
                                                KSelected: false,
                                                ISelected: false,
                                                PSelected: false,
                                                UCSelected: false,
                                                OTESSelected: false
                                 })
                                }  
                            }
                             onClickOutside={this.openDatePicker}
                         />
                        </div>
                    </div>

                </div>
                <Alert show={this.state.showAlert} variant="warning" bsPrefix='alert'>
                    <p>
                    Please select maintenance date first.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-center">
                    <Button onClick={() => this.setState({showAlert :false}) } variant="outline-success">
                        Ok
                    </Button>
                    </div>
                </Alert>
                <div className="maintenance-update-popup__buttongroup">
                    <CustomButton type={CustomButtonType.Cancel}  onClick={this.cancelUpdate}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok}  onClick={this.confirmUpdate}>
                        Confirm
                    </CustomButton>
                </div>
            </div>
        );
    }

    setValueForMaintenceDate = (event) => {
        let e = event.target;
        // if(this.state.startDate == null) {
        //     this.setState({showAlert :true})
        //     e.checked = !e.checked
        //     return
        // }
        let maintenceDate = this.state.startDate;
        switch (e.name) {
            case 'ACheckRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(30, 'days').toDate();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
                    this.setState({Acheck_Value : nextDateMMMYYYY, AcheckSelected : true})
                }else if (e.checked== false ) {
                    //let nextDate =  moment(maintenceDate).add(30, 'days').calendar();
                    //let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
                    this.setState({Acheck_Value : null, AcheckSelected : false})
                }
                break;

            case 'CVIPRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({CVIP_Value : nextDateMMMYYYY , CVIPSelected : true})
                }else if (e.checked== false ) {
                    //let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({CVIP_Value : null , CVIPSelected : false})
                }
                break;

            case 'VRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({V_Value : nextDateMMMYYYY , VSelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({V_Value : null , VSelected : false})
                }
                break;

            case 'KRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({K_Value : nextDateMMMYYYY , KSelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({K_Value : null , KSelected : false})
                }
                break;
//todo Insulated or non insulated
            case 'IRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({I_Value : nextDateMMMYYYY, ISelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({I_Value : null , ISelected : false})
                }
                break;

            case 'PRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(5, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({P_Value : nextDateMMMYYYY, PSelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({P_Value : null , PSelected : false})
                }
                break;

            case 'UCRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(5, 'years').calendar();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({UC_Value : nextDateMMMYYYY, UCSelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MMM YYYY")
                    this.setState({UC_Value : null , UCSelected : false})
                }
                break;
            case 'OTESRef':
                if (e.checked== true ) {
                    let nextDate =  moment(maintenceDate).add(30, 'days').toDate();
                    let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
                    this.setState({OTES_Value : nextDateMMMYYYY, OTESSelected : true})
                }else if (e.checked== false ) {
                    let defaultDateMMMYYYY = moment(maintenceDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
                    this.setState({OTES_Value : null , OTESSelected : false})
                }
                break;
            default:
                break;
        }
    }

    cancelUpdate = () => {
        this.props.onCancelUpadte();
        this.props.onHide();
    };

    updateQuesValueBasedOnSelection = () => {
        let maintenceDate = this.state.startDate;
        const { trailerType } = this.props;
        if (this.chekBox1Ref["ACheckRef"].checked) {        
            let nextDate =  moment(maintenceDate).add(30, 'days').toDate();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
            this.setState({Acheck_Value : nextDateMMMYYYY, AcheckSelected : true}) 
        }
        if (this.chekBox2Ref["CVIPRef"].checked) {      
            let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
            this.setState({CVIP_Value : nextDateMMMYYYY , CVIPSelected : true})  
        }
        if (this.chekBox3Ref["VRef"].checked) {       
            let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
            this.setState({V_Value : nextDateMMMYYYY , VSelected : true}) 
        }
        if (this.chekBox4Ref["KRef"].checked) {     
            let nextDate =  moment(maintenceDate).add(1, 'years').calendar();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
            this.setState({K_Value : nextDateMMMYYYY , KSelected : true})   
        }
        if (this.chekBox5Ref["IRef"].checked) {
            if (trailerType === null || trailerType === 'Insulated') {
                let nextDate = moment(maintenceDate).add(1, 'years').calendar();
                let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                this.setState({ I_Value: nextDateMMMYYYY, ISelected: true })
            } else if (trailerType === 'Non Insulated' || trailerType === 'Pressure') {
                let nextDate = moment(maintenceDate).add(5, 'years').calendar();
                let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
                this.setState({ I_Value: nextDateMMMYYYY, ISelected: true })
            }
        }
        if (this.chekBox6Ref["PRef"].checked) {        
            let nextDate =  moment(maintenceDate).add(5, 'years').calendar();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
            this.setState({P_Value : nextDateMMMYYYY, PSelected : true})
        }
        if (this.chekBox7Ref["UCRef"].checked) {        
            let nextDate =  moment(maintenceDate).add(5, 'years').calendar();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MMM YYYY")
            this.setState({UC_Value : nextDateMMMYYYY, UCSelected : true})
        }
        if (this.chekBox8Ref["OTESRef"].checked) {        
            let nextDate =  moment(maintenceDate).add(30, 'days').toDate();
            let nextDateMMMYYYY = moment(nextDate, 'MM/DD/YYYY').format("MM/DD/YYYY")
            this.setState({OTES_Value : nextDateMMMYYYY, OTESSelected : true})
        }
    } 
    
    confirmUpdate = async () => {

        if(this.state.startDate == null) {
            this.setState({showAlert :true})
            return;
        }

        await this.updateQuesValueBasedOnSelection();
        let quesData ={
            Acheck_Value: this.state.Acheck_Value,
            AcheckSelected: this.state.AcheckSelected,
            CVIP_Value: this.state.CVIP_Value,
            CVIPSelected: this.state.CVIPSelected,
            V_Value: this.state.V_Value,
            VSelected: this.state.VSelected,
            K_Value: this.state.K_Value,
            KSelected: this.state.KSelected,
            I_Value: this.state.I_Value,
            ISelected: this.state.ISelected,
            P_Value: this.state.P_Value,
            PSelected: this.state.PSelected,
            UC_Value: this.state.UC_Value,
            UCSelected: this.state.UCSelected,
            OTES_Value: this.state.OTES_Value,
            OTESSelected: this.state.OTESSelected,
        }
        this.props.onConfirmUpdate(this.props.selectedID, quesData , this.state.startDate);
        this.props.onHide();
    };
}
