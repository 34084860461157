import React, { Component } from 'react';
import { CellEditingStartedEvent, CellEditingStoppedEvent, FilterChangedEvent, GridOptions } from 'ag-grid-community';
import Select, { components, ActionMeta, ValueType } from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { PastDueCertificationReportFieldName, PastDueSaveLabel, ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import jsPDF from 'jspdf';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { PastDueCertificationReportDataStore } from '../stores/pastDueCertificationReport-data-store';
import { TicketApproverCommentPopup } from 'features/operations-portal/components/ticket-approver-comment-popup';
import { pastDueCertificationReportStore } from '../stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { PastDueClearanceCommentPopup } from './pastDue-clearance-comment-popup';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface PastDueCertificationReportProps {
    pastDueCertificationReportDataStore: PastDueCertificationReportDataStore;
}
@observer
export class PastDueCertificationReportComponent extends Component<PastDueCertificationReportProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('PastDueCertificationReport');
    }
    componentDidMount() {
        const { pastDueCertificationReportDataStore } = this.props;
        pastDueCertificationReportDataStore.init();
        this.props.pastDueCertificationReportDataStore.getPastDueReports();
        this.props.pastDueCertificationReportDataStore.getDriverTrailerData();
    }

    componentWillUnmount() {
        this.props.pastDueCertificationReportDataStore.reset();
    }
    
    handleApproverCommentVisibility = () => {
        this.props.pastDueCertificationReportDataStore.hideTicketApproverPopUp();
    };
    handleApproverCommentVisibilityOnCancel = () => {
        const { pastDueCertificationReportDataStore} = this.props;
        const {approvedCommentTicketModel } = pastDueCertificationReportDataStore;
        approvedCommentTicketModel.ClearanceFlag = null;
        pastDueCertificationReportDataStore.updateRow(approvedCommentTicketModel);
        this.props.pastDueCertificationReportDataStore.hideTicketApproverPopUp();
    }
    handleApproverCommentSubmission = (comment: string) => {
        this.props.pastDueCertificationReportDataStore.updateComment(comment);
    };

    render(): React.ReactNode {
        const {
            pastDueCertificationReportDataStore: { isSaveDisabled, isDisabled, isApproverPopUpVisible, isClearanceCommentPopUpVisible }
        } = this.props;
        return (
            <Container fluid>
                <>
                <TicketApproverCommentPopup
                        onApproverCommentSubmission={this.handleApproverCommentSubmission}
                        onApproverCommentVisibility={this.handleApproverCommentVisibility}
                        onApproverCommentCancel={this.handleApproverCommentVisibility}
                        showPopup={isApproverPopUpVisible}
                    />

                <PastDueClearanceCommentPopup
                        onApproverCommentSubmission={this.handleApproverCommentSubmission}
                        onApproverCommentVisibility={this.handleApproverCommentVisibility}
                        onApproverCommentCancel={this.handleApproverCommentVisibilityOnCancel}
                        showPopup={isClearanceCommentPopUpVisible}
                    />
                    <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                {dataStore.checkOperationAccess('Save') && (
                                    <>
                                    <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.handleSave}
                                    disabled={isSaveDisabled()}
                                    >
                                    {PastDueSaveLabel.SAVE}
                                    </CustomButton>
                                    <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                Reset
                                            </CustomButton>
                                    {/* <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.exportPdf}
                                    disabled={isDisabled()}
                                >
                                    {ReportsButton.PDF}
                                </CustomButton> */}
                                    </>
                                )}
                                <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true} disabled={isDisabled()}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
                </>
            </Container>
        );
    }
    componentDidUpdate() {
        const filters = localStorageService.get('PastDueCertificationReport_filter');
        this.props.pastDueCertificationReportDataStore.agGridService.setAllFilters(filters);     
        }
    createHeaderCell(col) {
        let headerCell: any = {};
        let headerName: any = col.colDef.headerName;
        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }
    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }
    private exportPdf = (): void => {
        const {
            pastDueCertificationReportDataStore: { agGridService }
        } = this.props;

        const data: any = agGridService.getNodes();
        const columns: any = agGridService.getColumns();
        let columnsToExport: any = [];
        let columnHeader: any = [];
        let rowsToExport: any = [];
        columns?.getAllDisplayedColumns().forEach(col => {
            console.log(col);
            if (!['Power_Unit_Identifier', 'Region'].includes(col.getColId())) {
                let headerCell: any = this.createHeaderCell(col);
                columnsToExport.push(headerCell);
                columnHeader.push(headerCell.text);
            }
        });
        data.forEachNodeAfterFilterAndSort(node => {
            console.log(node);
            let rowToExport = columnsToExport.map(({ colId }) => {
                let cellValue = data.getValue(colId, node);
                let tableCell = this.createTableCell(cellValue, colId);
                console.log(tableCell);
                return tableCell;
            });
            rowsToExport.push(rowToExport);
        });
        console.log(columnsToExport, rowsToExport, columnHeader);
        // data.rowModel.rowsToDisplay.forEach((x, _ind) => {
        //     this.data.push(x.data);
        // });
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = TitlePDF.PastDueCertificationReport;
        const theme: any = 'grid';
        const content = {
            theme: theme,
            startY: 50,
            head: [columnHeader],
            body: rowsToExport
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content);
        doc.save(TitlePDF.PastDueCertificationReport + '.pdf');
    };
    
    private exportCsv = () => {
        const csvData: any = this.props.pastDueCertificationReportDataStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        for (let i = 0; i < data.length; i++) {

        data[i]['Load_Date'] =
        data[i]['Load_Date'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['Load_Date']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['TicketDate'] =
        data[i]['TicketDate'] === null
            ? ''
            : momenttimezone
                  .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        data[i]['CompletedDate'] =
        data[i]['CompletedDate'] === null
                      ? ''
                      : momenttimezone
                            .tz(moment.utc(data[i]['CompletedDate']), AppConstant.MST_TIME_FORMATTER)
                            .format(AppConstant.DATE_TIME_FORMAT);        
        }
        let str = JSON.stringify(data);
        str = str.replace(/\"TicketDate\":/g, '"Ticket Creation Date":');
        str = str.replace(/\"Load_Date\":/g, '"Load Date Time":');
        str = str.replace(/\"CompletedDate\":/g, '"Completed Date Time":');
        str = str.replace(/\"Driver_Name\":/g, '"Driver Name":');
        str = str.replace(/\"CompanyName\":/g, '"Carrier Company":');
        str = str.replace(/\"Power_Unit_Identifier\":/g, '"Power Unit Identifier":');
        str = str.replace(/\"Estimated_Vol\":/g, '"Estimated Volume":');
        str = str.replace(/\"Actual_Vol\":/g, '"Actual Volume":');
        const ws = XLSX.utils.json_to_sheet(JSON.parse(str));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.PastDueCertificationReport);
    };
    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.TrailerID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            pastDueCertificationReportDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            pastDueCertificationReportStore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
       const row = event.data.TrailerID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            pastDueCertificationReportDataStore: { cellValueChangeMap, uiService, updateRow }
        } = this.props;
        const initValue = cellValueChangeMap[row][col]['initValue'];
        if((col === PastDueCertificationReportFieldName.OPERATIONSREVIEW || 
            col === PastDueCertificationReportFieldName.MAINTENANCEREVIEW) &&
            (initValue === "Reviewed" && value === "New") || (initValue === "Reviewed-Issue" && value === "New")
            ){
            cellValueChangeMap[row][col]['currentValue'] = initValue;
            uiService.toastService.error(`Status cannot be changed from ${initValue} to ${value}`);
            this.props.pastDueCertificationReportDataStore.getPastDueReports();
            return;
        } else {
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
        }
        
    };
    private handleSave = (): void => {
        this.props.pastDueCertificationReportDataStore.createUpdatePastOverdueforTrailers();
    };
    private handleReset = (): void => {
        this.props.pastDueCertificationReportDataStore.resetPastDueList();
    };

    handleRefresh = () => {
        const { pastDueCertificationReportDataStore } = this.props;
        pastDueCertificationReportDataStore.isRefreshed = true;
        if(Object.keys(pastDueCertificationReportDataStore.cellValueChangeMap).length === 0) {
            pastDueCertificationReportDataStore.pastDueModel();
            pastDueCertificationReportDataStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    pastDueCertificationReportDataStore.pastDueModel();
                    pastDueCertificationReportDataStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    private getGridConfig(): GridOptions {
        const {
           pastDueCertificationReportDataStore: { DuplicateList, agGridService,getColDef }
        } = this.props;
        const rowData = DuplicateList;
        const columnDefs = getColDef();    
        const onFilterChanged = this.onFilterChanged;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
      const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.pastDueCertificationReportDataStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName()+'_filter', this.props.pastDueCertificationReportDataStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
    };
}
