export enum DispatchPlanMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
}

export enum DispatchPlanButtons {
        SAVE = 'Save Data',
        RESET = 'Reset',
        DISPATCHLOAD = 'Dispatch Load',
        CANCELLOAD = 'Cancel Load',
        RECALL = 'Recall',
        EXPORTTOEXCEL = 'Export To Excel',
        CLEARALL = 'Clear All',
        BULKASSIGN = 'Bulk Assign',
        REJECT = 'Reject Load',
        ACCEPT = 'Accept Load'
}

export enum DispatchPlanHeaderName {
    ID = 'ID',
    SELECT = 'Select',
    LOADNUMBER = 'Load#',
    CUSTOMERNAME = 'Customer Name',
    CONTRACTNUMBER = 'Contract Number',
    LOADSTATUS = 'Load Status',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    DRIVERNAME = 'Driver Name',
    LASTDRIVERNAME = 'Last Driver Name',
    CARRIERNAME = 'Carrier Name',
    LASTCARRIERNAME = 'Last Carrier Name',
    PRIORITY = 'Priority',
    TRAILERNUMBER = 'Trailer Number',
    ESTIMATEDVOLUME = 'Estimated Volume',
    COMPLETEDVOLUME = 'Completed Volume',
    PLANNEDDATEANDTIME = 'Planned Date & Time',
    DISPATCHERCOMMENTS = 'Dispatcher Comments',
    DISPATCHERNOTES = "Dispatcher Notes",
    DRIVERCOMMENTS = 'Driver Comments',
    DATASOURCE = 'Data Source',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    CARRIERCOMMENTS = 'Carrier Comments',
    DISPATCHTYPE = 'Dispatch Type',
    ACHECKDATE = 'A-Check',
    BOOKINGDATE = 'Booking Date'
}

export enum DispatchPlanFieldName {
    ID = 'ID',
    LOADNUMBER = 'LoadNumber',
    CUSTOMERNAME = 'CustomerName',
    CONTRACTNUMBER = 'ContractNumber',
    LOADSTATUS = 'LoadStatus',
    ORIGIN = 'AX_Origin',
    DESTINATION = 'AX_Destination',
    DRIVERNAME = 'DriverName',
    LASTDRIVERNAME = 'LastDriverName',
    CARRIERNAME = 'CarrierName',
    LASTCARRIERNAME = 'LastCarrierName',
    PRIORITY = 'Priority',
    TRAILERNUMBER = 'TrailerNumber',
    ESTIMATEDVOLUME = 'EstimatedVolume',
    COMPLETEDVOLUME = 'CompletedVolume',
    PLANNEDDATEANDTIME = 'PlannedDateTime',
    DISPATCHERCOMMENTS = 'DispatcherComments',
    DISPATCHERNOTES = "DispatcherNotes",
    DRIVERCOMMENTS = 'DriverComments',
    ISDELETEDDISPATCHEDLOAD ='IsDeletedDispatchedLoad',
    DATASOURCE ='DataSource',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    DISPATCHTYPE = 'DispatchType',
    CARRIERCOMMENTS = 'CarrierComments',
    ACHECKDATE = 'ACheckDate',
    ACHECKFLAG = 'ACheckFlag',
    BOOKINGDATE = 'BookingDate'
}
export class DispatchAllConstants {
    static NO_DRIVER = "Please select Driver for dispatching the loads";
    static NO_TRAILER = "Please select Trailer for dispatching the loads";
    static SAVE_ERROR = 'Please select Driver and Trailer for Saving the loads';
    static NO_DRIVER_CARRIER = "Please select Driver/Carrier for dispatching the loads";
}

export const commaFormat = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}