import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { DailyReportResponse, DateRange, EmailConfigurationResponse } from '../domains/model';
import { DailyReportDataService } from '../services/dailyreport-data-service';
import { uiService } from 'shared/services/ui-service';
import { ReportMessage } from '../domains/enum';
import { accountStore } from 'features/account/stores';
import { CCUserRoles } from '../reports_constants';
import { ScheduledReportDataService } from '../services/scheduledreport-data-service';
import { ScheduledreportColDef } from '../domains/scheduled-report-col-def';
import moment from 'moment';
import { AppConstant } from 'app_constant';
import momenttimezone from 'moment-timezone';
import { LocationMessage } from 'features/data_management/domains/location-management/enum';

export class ScheduledReportDataStore {
    @observable scheduledReport: any = [];
    userID = 0;
    userName = '';
    startDate = moment()
        .subtract(6, 'days')
        .toISOString()
        .slice(0, 10);
    endDate = moment()
        .toISOString()
        .slice(0, 10);
    dailyBackupReport: DailyReportResponse[] = [];
    @observable selectedData: any = {};
    emailConfiguration: any;
    @observable isRadioButtonEnabled: boolean = false;
    operator: any[] = [];
    operatorName: any[] = [];
    constructor(
        private scheduledReportDataService: ScheduledReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {}

    init(): void {
        this.getscheduledReport();
        this.getOperator();
        this.userID = this.accountStore.getUserID();
        // this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return ScheduledreportColDef;
    }

    // statusFiltered(statusList) {
    //     const reports: any = this.dailyBackupReport.filter(element => {
    //         return statusList.includes(element.Status);
    //     });
    //     this.dailyReports = reports;
    //     if (this.dailyReports.length == 0) {
    //         uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
    //     }
    // }

    @Loader
    @Catch(() => errorHandler(ReportMessage.DAILY_ERROR_MESSAGE))
    async getscheduledReport() {
        // this.scheduledReport = [];
        const reqbody: any = {};
        reqbody.ConsignorName = 'ALL';
        const dailyReports = await this.scheduledReportDataService.getScheduledReport(reqbody);
        dailyReports.map(report => 
            (report.ScheduledTime != null) ?
             report.ScheduledTime = momenttimezone.tz(moment.utc(report.ScheduledTime,'HH:mm'), AppConstant.MST_TIME_FORMATTER).local().format("HH:mm")
             : null
        )
        this.scheduledReport = dailyReports;
        this.setBackupReport(this.scheduledReport);
        if (this.scheduledReport.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.isRadioButtonEnabled = false;
    }

    @Loader
    @Catch(() => errorHandler(LocationMessage.FETCH_ERROR_MESSAGE))
    async getOperator(): Promise<void> {
        let reqbody = {
            AxOrRAData : ''
        }
        const operators = await this.scheduledReportDataService.getOperator(reqbody);
        this.operatorName = [];
        this.operator = Array.from(new Set(operators.map((item: any) => item.Description)));
        this.operator.forEach(name => {
            this.operatorName.push({ value: name, label: name });
        });
    }

    @computed
    get ScheduledReport(): any[] {
        return toJS(this.scheduledReport);
    }

    async getEmailConfiguration(reqbody): Promise<void> {
        const emailConfiguration = await this.scheduledReportDataService.getScheduledReport(reqbody);
        this.setEmailConfiguration(emailConfiguration);
    }
    @computed
    get EmailConfiguration(): EmailConfigurationResponse[] {
        return toJS(this.emailConfiguration);
    }

    @action
    setEmailConfiguration(data: EmailConfigurationResponse[]): void {
        this.emailConfiguration = data;
    }

    @action
    setBackupReport(reports: DailyReportResponse[]): void {
        this.dailyBackupReport = reports;
    }

    isDisabled = (): boolean => {
        if (this.scheduledReport.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    isEditDisabled = (): boolean => {
        console.log(this.selectedData.ID, this.selectedData && this.selectedData.ID > 0);
        return this.selectedData && this.selectedData.ID > 0 ? false : true;
    };

    @action
    handleRadioSelection(data) {
        this.selectedData = data;
        this.isRadioButtonEnabled = true;
    }

    
    @action
    @Loader
    @Catch(() => errorHandler(ReportMessage.FAILED_SUBMIT))
    async handlePauseReport(report: any, value: any, colName: string) {
        const reqbody: any = {};
        reqbody.ID = report.ID;
        reqbody.IsPaused = !value;
        let userID = this.userID;
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        reqbody.CreatedBy = userID;
        await this.scheduledReportDataService.createCustomerReport(reqbody);
        this.uiService.toastService.success(ReportMessage.SAVE);
        this.init();
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.UPLOAD_ERROR);
    })
    async createCustomerReport(reqbody) {
        const sendreport = await this.scheduledReportDataService.createCustomerReport(reqbody);
        this.isRadioButtonEnabled = true;
        uiService.toastService.success('Report sent');
    }
}
