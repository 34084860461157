import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ImportErrorCodes } from 'features/dispatch/nomination/nomination-constants';
library.add(faInfoCircle);

export interface ConfirmProps {
  onClose: () => void;
  response;
  uploadFlag;
  maxRowsReached;
  length;
  errorMessage;
}

interface ErrorState {
  errorDialog: boolean;
}

export class ExcelValidatorPopup extends React.Component<ConfirmProps,ErrorState> {
  constructor(props) {
    super(props);
    this.state = {
      errorDialog : true
    }
  }

  formatErrors: any = [];
  locationErrors: any = [];
  loadsErrors: any = [];
  errorCount: number = 0;
  dateErrors: boolean = false;
  locationInvalid: boolean = false;

  public render(): React.ReactNode {
    const { onClose, response, uploadFlag, maxRowsReached, length, errorMessage } = this.props;
    const responseData = response && (response.length === 0 ? [] : response.split('\,'))
    const currentYear = new Date().getFullYear();
    if(errorMessage.length > 0 && !maxRowsReached){
      this.dateErrors = true;
      errorMessage.map(error => this.formatErrors.push(error))
    } 
    const formatValidation = () => {
        responseData.forEach((data) => {
          if(data.split('-').length === 2) {
            let pair = data.split('-'); 
            if(pair[1] === ImportErrorCodes.SHIPPERCODE) this.formatErrors.push(`Import file is missing Shipper Code for Crude on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.SHIPPERNAME) this.formatErrors.push(`Import file is missing Shipper Name for Crude on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.CONTRACTNUMBER_CRUDE) this.formatErrors.push(`Contract Number for Crude should be removed on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.CUSTOMERNAME) this.formatErrors.push(`Import file is missing Customer Name on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.PRODUCT) this.formatErrors.push(`Import file is missing Product on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.ORIGIN) this.formatErrors.push(`Import file is missing Origin on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.DESTINATION) this.formatErrors.push(`Import file is missing Destination on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.NOMINATION) this.formatErrors.push(`Import file is missing Nomination on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.NOMINATIONMONTH_MISSING) this.formatErrors.push(`Import file is missing Nomination Month on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.NOMINATIONMONTH_FORMAT) this.formatErrors.push(`Invalid Nomination Month on row ${pair[0]}. Nomination Month should be between 1 to 12.`)
            if(pair[1] === ImportErrorCodes.NOMINATIONYEAR_MISSING) this.formatErrors.push(`Import file is missing Nomination Year on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.NOMINATIONYEAR_FORMAT) this.formatErrors.push(`Invalid Nomination Year on row ${pair[0]}. Nomination Year should be either ${currentYear} or ${currentYear+1}.`)
            if(pair[1] === ImportErrorCodes.REGION_FORMAT) this.formatErrors.push(`Incorrect Region format on row ${pair[0]}. Region format is 'NE / NW / SE / SW'`)
            if(pair[1] === ImportErrorCodes.REGION_MISSING) this.formatErrors.push(`Import file is missing Region on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.DATASOURCE) this.formatErrors.push(`Import file is missing Data Source on row ${pair[0]}.`)
            if(pair[1] === ImportErrorCodes.CONTRACTNUMBER_BUTANE) this.formatErrors.push(`Import file is missing Contract Number for Butane on row ${pair[0]}.`)
            
          }
        })
        return this.formatErrors;
    }

    const locationValidation = () => {
        responseData.forEach((data, index) => {
          if(data.split('-').length === 2) {
            let pair = data.split('-');
            if(pair[1] === ImportErrorCodes.ODPAIRING || pair[1] === ImportErrorCodes.LOCATION_ORIGIN || pair[1] === ImportErrorCodes.LOCATION_DESTINATION || pair[1] === ImportErrorCodes.TRUCKEDBY_ORIGIN || pair[1] === ImportErrorCodes.TRUCKEDBY_DESTINATION) this.locationInvalid = true;
            if(pair[1] === ImportErrorCodes.ODPAIRING && 
                !(this.formatErrors.includes(`Import file is missing Destination on row ${pair[0]}.`) || this.formatErrors.includes(`Import file is missing Origin on row ${pair[0]}.`))) 
                  this.locationErrors.push(`No O/D pairing in PDS for row ${pair[0]}. Please set up O/D pairing in Location Management.`)
            if((pair[1] === ImportErrorCodes.LOCATION_ORIGIN) && !(this.formatErrors.includes(`Import file is missing Origin on row ${pair[0]}.`))) 
                  this.locationErrors.push(`Origin in row ${pair[0]} is not in PDS. Please confirm location is in AX and the location in the import file is exactly the same.`)
            if((pair[1] === ImportErrorCodes.LOCATION_DESTINATION) && !(this.formatErrors.includes(`Import file is missing Destination on row ${pair[0]}.`))) 
                  this.locationErrors.push(`Destination in row ${pair[0]} is not in PDS. Please confirm location is in AX and the location in the import file is exactly the same.`)
            if((pair[1] === ImportErrorCodes.TRUCKEDBY_ORIGIN) && !(this.formatErrors.includes(`Import file is missing Origin on row ${pair[0]}.`))) 
                  this.locationErrors.push(`Error adding nominations in row ${pair[0]}. Please enable Trucked by Locations for the Origin in Location Mapping screen.`)
            if((pair[1] === ImportErrorCodes.TRUCKEDBY_DESTINATION) && !(this.formatErrors.includes(`Import file is missing Destination on row ${pair[0]}.`))) 
                  this.locationErrors.push(`Error adding nominations in row ${pair[0]}. Please enable Trucked by Locations for the Destination in Location Mapping screen.`)
            
          }
        })
        return this.locationErrors;
    }
    const isLoadsGenerated = () => {
        if(this.formatErrors.length === 0 && this.locationErrors.length === 0){
        const loadsData = response.split('\,')
        if (loadsData.includes(ImportErrorCodes.BUTANE_LOADS) || loadsData.includes(ImportErrorCodes.CRUDE_LOADS) || loadsData.includes(ImportErrorCodes.NGL_LOADS) || loadsData.includes(ImportErrorCodes.BRINE_LOADS))
        this.loadsErrors.push(`Error generating loads - please contact Service Desk.`)
        }
        return this.loadsErrors;
      }
    
    return (
      <div>
      {uploadFlag === false && !maxRowsReached && errorMessage.length === 0 &&
        <div className="confirm __uploadingWidth">
         <div className='checker'>
          <Row className='mb-2'>
          <Col md={10}>Import file is valid</Col>
          <div className="spinner-border spinner-border-sm" style={{width: '1.4rem', height: '1.4rem'}} role="status"></div>
          </Row>
          <Row className='mb-2'>
          <Col md={10}>Location and O/D pairing is valid</Col>
          <div className="spinner-border spinner-border-sm" style={{width: '1.4rem', height: '1.4rem'}} role="status"></div>
          </Row>
          <Row className='mb-2'>
          <Col md={10}>Loads generated</Col> 
          <div className="spinner-border spinner-border-sm" style={{width: '1.4rem', height: '1.4rem'}} role="status"></div>
          </Row>
          <Row className='mb-2'>
          <Col md={10}>Import complete</Col>
          <div className="spinner-border spinner-border-sm" style={{width: '1.4rem', height: '1.4rem'}} role="status"></div>
          </Row>
         </div>
         <div style={{paddingBottom: 10}}>
          <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
              Close
          </CustomButton></div>
        </div>}

        {uploadFlag === true && errorMessage.length === 0 &&
        <div className="confirm __uploadingWidth">
        <div className='checker'>
         <Row className='mb-2'>
         <Col md={10}>Import file is valid</Col>
           {((formatValidation() && (this.formatErrors.length > 0))) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col>
           }
           {this.formatErrors.length === 0 && uploadFlag === true &&
           <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col>
           }
         </Row>
         <Row className='mb-2'>
         <Col md={10}>Location and O/D pairing is valid</Col>
           {(locationValidation() && this.locationInvalid === true) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col>
           }
           {this.locationInvalid === false && uploadFlag === true &&
           <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col>
           }
         </Row>
         <Row className='mb-2'>
         <Col md={10}>Loads generated</Col>
           {(isLoadsGenerated() && this.loadsErrors.length > 0) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col>}
            {this.formatErrors.length === 0 && this.locationErrors.length === 0 && this.loadsErrors.length === 0 && uploadFlag === true &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col> }
         </Row>
         <Row className='mb-2'>
         <Col md={10}>Import complete</Col>
           {(this.formatErrors.length === 0 && this.locationErrors.length === 0 && this.loadsErrors.length === 0) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col> }
         </Row>
        </div>
        <div style={{paddingBottom: 10}}>
         <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
             Close
         </CustomButton></div>
       </div>}
       {(this.formatErrors.length > 0 || this.locationErrors.length > 0 || this.loadsErrors.length > 0 || errorMessage.length > 0 || maxRowsReached ) && this.state.errorDialog &&
       <div className = 'alertBox'> 
       <div className='error-message'>
       <div style={{color: 'red', fontSize: '18px', fontWeight: 'bold'}}>IMPORT ERRORS</div> 
       {maxRowsReached && length > 50 &&
       <div><div style={{padding: 5}}>The Import file contains more than 50 rows. Please re upload with maximum 50 rows at a time.</div> </div>
       }
       {maxRowsReached && length === 0 &&
       <div><div style={{padding: 5}}>The Import file has no data. Please upload a valid file.</div> </div>
       }
       {!maxRowsReached &&
       <div>
        {this.formatErrors.map((error) => {
        this.errorCount += 1;
        if(this.errorCount <= 10) return <div style={{padding: 5}}>{this.errorCount}{'. '}{error}</div>
        })}

       {this.locationErrors.map((error) => {
        this.errorCount += 1;
        if(this.errorCount <= 10) return <div style={{padding: 5}}>{this.errorCount}{'. '}{error}</div>
       })} 

       {this.loadsErrors.map((error) => {
        this.errorCount += 1;
        if(this.errorCount <= 10) return <div style={{padding: 5}}>{this.errorCount}{'. '}{error}</div>
       })}

       </div>}
      </div>
       <div style={{backgroundColor: '#ffffff', marginTop: 0, marginLeft: '30%', width:650 }}>
         {this.dateErrors && (this.formatErrors.length + this.locationErrors.length) < 10 &&
         <>
         <span style={{color: 'blue', paddingLeft: 15}}>
         <FontAwesomeIcon icon="info-circle" /><span> </span>
          Please enter valid dates and re upload the file.</span><br/></>
         }
         {(this.formatErrors.length + this.locationErrors.length) > 10 &&
         <>
         <span style={{color: 'blue', paddingLeft: 15}}>
         <FontAwesomeIcon icon="info-circle" /><span> </span>
          More errors were observed. Please {this.dateErrors? 'enter valid dates' : 'resolve'} and re upload the file.</span><br/></>
         }
         <div style={{paddingBottom: 10,paddingLeft: 270}}>
         <CustomButton type={CustomButtonType.Ok} onClick={()=> {
          this.setState({errorDialog: false})
          this.formatErrors = [];
          this.locationErrors = [];
          this.loadsErrors = [];
          this.errorCount = 0;
          this.dateErrors = false;
          this.locationInvalid = false;
         }}>
             OK
         </CustomButton> </div>
       </div> </div> } </div>
    )
  }
}
