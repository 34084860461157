import React from 'react';
import { Modal } from 'react-bootstrap';
import { CrudeRatioPlains } from './crudeRatio-Plains';

interface PlainsVolumePopup {
    onPlainsVolumeSubmission: (plainsRatio: string) => void;
    onPlainsVolumeVisibility: () => void;
    showPopup: boolean;
}

export const PlainsVolumePopup = (props: PlainsVolumePopup) => {
    const { onPlainsVolumeSubmission, onPlainsVolumeVisibility, showPopup } = props;
    return (
        <Modal backdrop="static" show={showPopup} centered>
            <Modal.Header>
                <Modal.Title>Plains % Ratio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CrudeRatioPlains
                    onPlainsVolumeVisibility={onPlainsVolumeVisibility}
                    onPlainsVolumeSubmission={onPlainsVolumeSubmission}
                />
            </Modal.Body>
        </Modal>
    );
};

