import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { CompanyResponse,CompanyViewModel } from '../domains/company-model';

export class CompanyDataService {
    constructor(private restApi: RestApiService) { }

    private getCarrierCompanyData(): Promise<ResponseDataType<CompanyResponse[]>> {
        return this.restApi.postWithToken<CompanyResponse[]>(Config.getCarrierCompanyData);
    }

    private getRACarrierCompanies(): Promise<ResponseDataType<CompanyResponse[]>> {
        return this.restApi.postWithToken<CompanyResponse[]>(Config.getRACarrierCompanies);
    }

   
    async getCompanyViewModel(): Promise<CompanyViewModel> {
        const companyDataPromise = this.getCarrierCompanyData();
        const vendorDataPromise = this.getRACarrierCompanies();
        const [
            { data: companyData },
           
        ] = await Promise.all([companyDataPromise]);
        const [
            { data: raVendorData },
        ] = await Promise.all([vendorDataPromise]);
        return {
            companyData,
            raVendorData
                    };
    }

    async updateVendorData(reqbody: CompanyResponse[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.updateVendorData, reqbody, {});
        return data['Data'];
    }
}

export const companyDataService = new CompanyDataService(restApiService);
