import React, { Component } from 'react';
import {
    GridOptions,
    CellClickedEvent,
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    RangeSelectionChangedEvent
} from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from 'features/common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { LocationButtons } from '../domains/location-management/enum';
import { LocationMapingDataStore } from '../stores/location-maping-data-store';
import { agGridService } from 'features/common/services/ag-grid-service';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { LocationMapingFieldName } from '../domains/location-maping-management/enum';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { DELETE_ITEM } from '../data_constant';
/* eslint-disable */
interface LocationMapingMasterDataProps {
    locationMapingStore: LocationMapingDataStore;
}

@observer
export class LocationMapingMasterDataComponent extends Component<LocationMapingMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('LocationMaping');
    }
    componentDidMount() {
        const { locationMapingStore } = this.props;
        locationMapingStore.init();
    }

    componentWillUnmount() {
        const { locationMapingStore } = this.props;
        locationMapingStore.reset();
    }

    render(): React.ReactNode {
        const {
            locationMapingStore: { isSaveDisabled, isDeleteEnabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton type={CustomButtonType.Submit} onClick={this.handleAddRow}>
                                                {LocationButtons.ADDNEWROW}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                SAVE
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {DELETE_ITEM}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }
    exportCsv() {
        agGridService.getNodes()?.exportDataAsCsv({ fileName: dataStore.getCurrentModuleName() });
    }
    componentDidUpdate() {
        const filters = localStorageService.get('LocationMaping_filter');
        this.props.locationMapingStore.agGridService.setAllFilters(filters);
    }

    private handleAddRow = (): void => {
        const { locationMapingStore } = this.props;
        locationMapingStore.addRow();
    };

    handleRefresh = () => {
        const { locationMapingStore } = this.props;
        locationMapingStore.init(); 
    }

    private getGridConfig(): GridOptions {
        const {
            locationMapingStore: { LocationData, agGridService }
        } = this.props;
        const { locationMapingStore } = this.props;
        const rowData = LocationData;
        const columnDefs = locationMapingStore.getColDef();
        const onCellClicked = this.onCellClicked;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;
        const LocationManagementGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked,
            onCellEditingStarted,
            onCellEditingStopped,
            onRangeSelectionChanged
        };
        return agGridService.getGridConfig(LocationManagementGridParams);
    }

    onCellClicked = (event: CellClickedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;

        const csvData: any = this.props.locationMapingStore.agGridService.getNodes();
        console.log('cellClicked')
        if(event.column.getColId() !== 'IsDeleted') {
            csvData?.rowModel.rowsToDisplay.forEach((x, index )=> {
                    
                x.setDataValue('IsDeleted', false);
                // }
            });
            this.props.locationMapingStore.dataToBeDeleted = []
            console.log('deletebutton', this.props.locationMapingStore.dataToBeDeleted.length);
            
        }
        if (event.column.getColId() === LocationMapingFieldName.LOCATIONAX && dataStore.checkOperationAccess('Save')) {
            this.props.locationMapingStore.showAXPopUp(event.data);
        }
        if (event.column.getColId() === LocationMapingFieldName.LOCATIONRA && dataStore.checkOperationAccess('Save')) {
            this.props.locationMapingStore.showRAPopUp(event.data);
        }
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {

        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            locationMapingStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
            console.log('123',row, col, value, event.colDef);
        if (!isNotEmpty) {
            this.props.locationMapingStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            locationMapingStore: { cellValueChangeMap, updateRow }
        } = this.props;
        console.log('123',row, col, value);
        cellValueChangeMap[row][col]['currentValue'] = value;
        console.log('213',row,col, JSON.stringify(cellValueChangeMap));
        updateRow(event.data);
    };

    private handleSave = (): void => {
        const { locationMapingStore } = this.props;
        locationMapingStore.updateDrivers();
    };

    private deleteitems = (): void => {
        const { locationMapingStore } = this.props;
        locationMapingStore.deleteRows();
    };

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.locationMapingStore.agGridService.getNodes();
        const checkArray: any = [];
    
        if(e.started === false && e.finished === true) {
            var cellRanges:any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index )=> {
                if(index >= startRowIndex && index <= endRowIndex) {
                    // console.log(x.data);                    
                    this.props.locationMapingStore.handleCheckboxClick(x.data, false, "IsDeleted");
                    x.setDataValue('IsDeleted', true);
                    checkArray.push('true');
                } else {
                    checkArray.push(x.data['IsDeleted'] ? 'true': 'false');
                }
            });

            csvData?.rowModel.gridApi.refreshCells();

            // if(checkArray.indexOf('false') < 0) {
            //     this.props.dispatchPlanDataStore.selectButtonText = 'Deselect All';
            // } else {
            //     this.props.dispatchPlanDataStore.selectButtonText = 'Select All';
            // }
        }
    }
}
