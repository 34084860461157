import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faPlusCircle, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable, toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { dateUtils } from 'shared/services/date-utils';
import { CustomButton, CustomButtonType } from '../custom-button/custom-button';
import { recalledEnableArray, unplanEnableArray } from 'features/dispatch/dispatch-screen/dispatchScreen-constants';
library.add(faTrash, faUndo, faPlusCircle);

export interface ConfirmProps {
    onSuccessClick: (loads) => void;
    onClose: () => void;
    plannedLoads;
    loads;
}

export class PlannedLoadsPopup extends React.Component<ConfirmProps> {

    @observable data: any = [{ LoadNumber: '', displayName: '', driverName: '', companyID: '', companyName: '', DispatcherComments: '', Status: '', IsEdit: true, IsDeleted: false }];
    assignedLoads = JSON.parse(JSON.stringify(this.props.plannedLoads));


    state = {
        data: [],
        assignedLoads: []
    }
    selectedTickets: any;

    constructor(props) {
        super(props);

        // this.handleTicketSelected = this.handleTicketSelected.bind(this);
    }

    public render(): React.ReactNode {
        const { onClose, loads } = this.props;
        let ticketList: any = []
        const companyDropdown: any = Array.from(new Set(this.data.map((item: any) => item.LoadNumber)))
        loads.map(list => {
                if(!companyDropdown.includes(list.LoadNumber))
                    ticketList.push({
                        value: list.LoadNumber, label: `${list.LoadNumber} | ${list.ShortNames}`, origin: list.AX_Origin, destination: list.AX_Destination,
                        date: dateUtils.getFormattedMSTDateOnly(list.PlannedDateTime), status: 'Added', shortName: list.ShortNames
                    })
        })
        return <>
            <div className='plannedloads'>
                <Row style={{ padding: '5px', fontSize: '16px', fontWeight: 'bold' }}>
                    <Col md={3}> Ticket Number</Col>
                    <Col md={1}>Status</Col>
                    <Col md={6}> Dispatcher Comments</Col>
                    <Col md={2}></Col>
                </Row>
                {/* <div style={{ overflowX: 'hidden', overflowY: 'scroll', height: '250px'}}> */}
                {this.assignedLoads.map(X => {
                    return (
                        <>
                            {!X.IsRecall && !X.IsDeleted && <Row style={{ padding: '5px', fontSize: '14px' }}>
                                <Col md={3} style={{ color: this.getColor(X.Status) }}> {X.ShortNames}({X.LoadNumber})</Col>
                                <Col md={1} style={{ color: this.getColor(X.Status) }}> {X.Status}</Col>
                                <Col md={6}>
                                    <input
                                        value={X.DispatcherComments ? X.DispatcherComments : ''}
                                        autoComplete="off"
                                        placeholder=""
                                        disabled = {X.Status === 'Completed' ? true : false}
                                        onChange={event => {
                                            // this.handleSchedule(X, event.currentTarget.value, 'loads')
                                            console.log(event.currentTarget.value)
                                            X.DispatcherComments = event.currentTarget.value;
                                            X.IsEdit = true
                                            this.setState({ assignedLoads: this.assignedLoads })
                                        }

                                        }
                                        style={{
                                            width: '100%',
                                            height: '37px',
                                            borderRadius: 5,
                                            border: '1.2px solid',
                                            fontSize: 15
                                        }}

                                    ></input></Col>
                                <Col md={1} style={{ display: 'flex', padding: '5px 15px', fontSize: '14px' }}>
                                    <div className="delete-icon">
                                        <FontAwesomeIcon
                                            icon="trash"
                                            size="2x"
                                            onClick={() => {
                                                // this.deleteItem(ind);
                                                if (unplanEnableArray.includes(X.Status)) {
                                                    X.IsDeleted = true;
                                                    X.IsEdit = true
                                                    this.setState({ assignedLoads: this.assignedLoads })
                                                }
                                            }}
                                            style={{
                                                color: unplanEnableArray.includes(X.Status) ? 'black' : 'grey'
                                            }}
                                        />
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col md={1}> */}
                                    <div className="delete-icon">
                                        <FontAwesomeIcon
                                            icon="undo"
                                            size="2x"
                                            onClick={() => {
                                                // this.deleteItem(ind);
                                                if (recalledEnableArray.includes(X.Status)) {
                                                    X.IsRecall = true
                                                    X.IsEdit = true
                                                    this.setState({ assignedLoads: this.assignedLoads })
                                                }
                                            }}
                                            style={{
                                                color: recalledEnableArray.includes(X.Status) ? 'black' : 'grey'
                                            }}
                                        />
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col md={1}> */}
                                    {/* <div className="delete-icon">
                                                    <FontAwesomeIcon
                                                        icon="plus-circle"
                                                        size="2x"
                                                        onClick={() => {
                                                            // this.deleteItem(ind);
                                                            
                                                        }}
                                                    />
                                                </div> */}
                                </Col>
                            </Row>}
                        </>
                    )
                })}
                {this.data.map((X,ind) => {
                    return (
                        <>
                            {!X.IsRecall && !X.IsDeleted && <Row style={{ padding: '5px', fontSize: '14px' }}>
                                <Col md={3}>
                                    <Select
                                        value={X.displayName}
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        placeholder={X.displayName === '' ? "Select Ticket" : X.displayName}
                                        options={ticketList}
                                        onChange={event => {
                                            // this.handleTicketSelected
                                            console.log(event);
                                            const selectedItem = ticketList.filter(x => x.displayName === event.label)
                                            console.log(event.label, event.value)
                                            X.displayName = event.label;
                                            X.LoadNumber = event.value;
                                            this.setState({ data: this.data })
                                        }}>
                                    </Select>
                                </Col>
                                <Col md={1}> {X.displayName === '' ? '' : 'Pending'}</Col>
                                <Col md={6} >
                                    <input
                                        value={X.DispatcherComments ? X.DispatcherComments : ''}
                                        autoComplete="off"
                                        placeholder=""
                                        onChange={event => {
                                            // this.handleSchedule(X, event.currentTarget.value, 'loads')
                                            console.log(event.currentTarget.value)
                                            X.DispatcherComments = event.currentTarget.value;
                                            this.setState({ data: this.data })
                                        }

                                        }
                                        style={{
                                            width: '100%',
                                            height: '37px',
                                            borderRadius: 5,
                                            // textAlign: 'center',
                                            border: '1.2px solid',
                                            fontSize: 15
                                        }}

                                    ></input>
                                </Col>
                                {/* <Col md={3}></Col> */}
                                <Col md={1} style={{ display: 'flex', padding: '5px 15px', fontSize: '14px' }}>
                                    <div className="delete-icon">
                                        <FontAwesomeIcon
                                            icon="trash"
                                            size="2x"
                                            onClick={() => {
                                                this.handleDeleteItem(ind);
                                            }}
                                            style={{
                                                color: this.data.length > 1 ? 'black': 'grey'
                                            }}
                                        />
                                        {/* <i className="fa fa-trash"  style={{fontSize:'30px',color:'lightblue',textShadow:'2px 2px 4px #000000'}}
                                                    onClick={() => {
                                                        // this.deleteItem(ind);
                                                        // X.IsEdit = true
                                                        console.log('deleted')
                                                    }}></i> */}
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col md={1}> */}
                                    <div className="delete-icon">
                                        <FontAwesomeIcon
                                            icon="undo"
                                            size="2x"
                                            onClick={() => {
                                                // this.deleteItem(ind);
                                            }}
                                            style={{
                                                color: 'grey'
                                            }}
                                        />
                                        {/* <i className="fa fa-undo" style={{fontSize:'30px',color:'lightblue',textShadow:'2px 2px 4px #000000'}}
                                                    onClick={() => {
                                                        // this.deleteItem(ind);
                                                        // X.IsEdit = true
                                                        console.log('recal;led')
                                                    }}></i> */}
                                    </div>
                                    {/* </Col> */}
                                    {/* <Col md={1}> */}
                                    <div className="delete-icon">
                                        <FontAwesomeIcon
                                            icon="plus-circle"
                                            size="2x"
                                            onClick={() => {
                                                // this.deleteItem(ind);
                                                this.data.push(
                                                    { LoadNumber: '', displayName: '', driverName: '', companyID: '', companyName: '', DispatcherComments: '', Status: '', IsEdit: true }
                                                )

                                                this.setState({ data: this.data })
                                            }}
                                        />
                                        {/* <i className="fa fa-plus-circle" style={{fontSize:'30px',color:'lightblue',textShadow:'2px 2px 4px #000000'}}
                                                    onClick={() => {
                                                        // this.deleteItem(ind);
                                                        // X.IsEdit = true
                                                        this.data.push(
                                                            { LoadNumber: '', displayName: '', driverName: '', companyID: '', companyName: '',DispatcherComments: '',Status: '' , IsEdit: true }
                                                        )

                                                        this.setState({data: this.data})
                                                        console.log('add')
                                                    }}></i> */}
                                    </div>
                                </Col>
                            </Row>}
                        </>
                    )
                })}
            {/* </div> */}
                <div>
                    <CustomButton
                        type={CustomButtonType.Ok}
                        onClick={this.handleAdd}
                        disabled={!this.onSaveDisabled()}
                    >
                        SAVE
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.handleReset}>
                        Reset
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton> </div>
                <Row style={{ paddingTop: '20px' }}>
                    <Col md={3}>
                        <div className="padding-bottom">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ width: '15px', height: '15px', backgroundColor: 'purple', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                                <label style={{ paddingLeft: '5px' }}> Assigned Tickets </label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ width: '15px', height: '15px', backgroundColor: 'green', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                                <label style={{ paddingLeft: '5px' }}> Dispatched Tickets </label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ width: '15px', height: '15px', backgroundColor: 'grey', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                                <label style={{ paddingLeft: '5px' }}> Completed Tickets</label>
                            </div>
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="padding-bottom">
                            <div style={{ display: 'block', alignItems: 'center' }}>
                                <span>
                                    <FontAwesomeIcon icon="trash"></FontAwesomeIcon> </span>
                                <label style={{ paddingLeft: '5px' }}> Delete Tickets Button is enabled for Assigned loads, Disabled for Dispatched and Completed loads </label>
                            </div>
                            <div style={{ display: 'block', alignItems: 'center' }}>
                                <span>
                                    <FontAwesomeIcon icon="undo"></FontAwesomeIcon></span>
                                <label style={{ paddingLeft: '5px' }}> Recall Tickets Button is enabled for Dispatched Loads, Disabled for Assigned/Completed loads </label>
                            </div>
                            <div style={{ display: 'block', alignItems: 'center' }}>
                                <span>
                                    <FontAwesomeIcon icon="plus-circle"></FontAwesomeIcon></span>
                                <label style={{ paddingLeft: '5px' }}> Add New button is to add a new row when a ticket needs to be assigned to the driver</label>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    }

    getColor = status => {
        if (status === 'Pending' || status === 'Recalled' || status === 'Cancelled By Driver' || status === 'Rejected By Driver' ||
            status === 'Rejected By Carrier' || status === 'Cancelled By Carrier' || status === 'Recalled By Carrier') {
            return 'purple'
        }
        else if (status === 'Completed') {
            return 'grey'
        } else {
            return 'green'
        }
    }

    onSaveDisabled = () => {
        var flag = false;
        this.assignedLoads.map(load => {
            if (load.IsEdit) {
                flag = true;
            }
        })
        this.data.map(load => {
            if (load.IsEdit && load.LoadNumber && load.LoadNumber != '') {
                flag = true;
            }
        })

        return flag;
    }

    handleReset = () => {
        this.assignedLoads = JSON.parse(JSON.stringify(this.props.plannedLoads));
        this.data = [{ LoadNumber: '', displayName: '', driverName: '', companyID: '', companyName: '', DispatcherComments: '', Status: '', IsEdit: true }];
        this.setState({ data: this.data })

    }

    handleDeleteItem = (index) => {
        this.data.splice(index,1)
        if(this.data.length == 0) {
            this.data = [{ LoadNumber: '', displayName: '', driverName: '', companyID: '', companyName: '', DispatcherComments: '', Status: '', IsEdit: true }];
        }
        this.setState({ data: this.data })
    }

    handleAdd = () => {
        this.props.onSuccessClick([...this.state.assignedLoads, ...this.state.data]);
        console.log(this.state.assignedLoads, this.state.data);
        this.props.onClose();
    }
}