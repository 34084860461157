import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { DailyReportResponse, DateRange } from '../domains/model';
import { DailyReportDataService } from '../services/dailyreport-data-service';
import { uiService } from 'shared/services/ui-service';
import { dailyReport } from '../domains/daily-report-col-def';
import { ReportMessage } from '../domains/enum';
import { accountStore } from 'features/account/stores';
import { CCUserRoles } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';

export class DailyReportDataStore {
    @observable dailyReports: DailyReportResponse[] = [];
    userID = 0;
    userName = '';
    startDate: any = '';
    endDate: any = '';
    isRefreshed: boolean = false;
    dailyBackupReport: DailyReportResponse[] = [];
    @observable volumeSum:any ={
        volumeDelivered : 0,
        actVolume : 0
    }
    userInfo: any;
    selectedOption: any = [{ value: 'all', label: 'All' }];
    constructor(
        private dailyreportDataService: DailyReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getDailyReports();
    }

    getColDef() {
        return dailyReport;
    }

    statusFiltered(statusList) {
        const reports: any = this.dailyBackupReport.filter(element => {
            return statusList.includes(element.Status);
        });
        this.dailyReports = reports;
        if (this.dailyReports.length == 0) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.DAILY_ERROR_MESSAGE))
    async getDailyReports() {
        // this.dailyReports = [];
        const reqbody: any = {};
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const dailyReports = await this.dailyreportDataService.getDailyReportwithParams(reqbody);
        this.dailyReports = dailyReports;
        this.setBackupReport(this.dailyReports);
        this.startDate = '';
        this.endDate = '';
        this.getVolumeSum(this.dailyReports);
        if (this.dailyReports.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
    }
    @computed
    get DuplicateList(): DailyReportResponse[] {
        return toJS(this.dailyReports);
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.DAILY_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        // this.dailyReports = [];

        this.selectedOption= [{ value: 'all', label: 'All' }];
        const dailyReports = await this.dailyreportDataService.getDailyReportwithParams(reqbody);
        this.dailyReports = dailyReports;
        this.setBackupReport(this.dailyReports);
        this.getVolumeSum(this.dailyReports);
        if (this.dailyReports.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
    }
    @action
    setBackupReport(reports: DailyReportResponse[]): void {
        this.dailyBackupReport = reports;
    }

    isDisabled = (): boolean => {
        if (this.dailyReports.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    getVolumeSum(data){
        let estVolSum=0, actVolSum=0;
        for (let report in data) {
            estVolSum += data[report].Estimated_Vol;
            actVolSum += data[report].Actual_Vol;
        }
        this.volumeSum.volumeDelivered = estVolSum.toFixed(3);
        this.volumeSum.actVolume = actVolSum.toFixed(3);
    }
}
