import * as React from 'react';

interface CustomToggleProps {
    onClick?: (e: any) => void;
    children: any;
}

export const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            if (props.onClick) {
                props.onClick(e);
            }
        }}
    >
        {props.children}
        {/* &#x25bc; */}
    </a>
));
