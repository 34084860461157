import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { HourlyReportResponse, DateRange } from '../domains/model';
import { ReportMessage } from '../domains/enum';
import { HourlyReportDataService } from '../services/hourlyreport-data-service';
import { hourlyReportColDef } from '../domains/hourly-report-col-def';
import { CCUserRoles } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';

export class HourlyReportDataStore {
    @observable hourlyReports: HourlyReportResponse[] = [];
    userID = 0;
    userName = '';
    @observable volumeSum:any = {
        volDeliveredSum : 0,
        totalWaitingTime : 0,
        totalWorkingTime : 0
    }
    startDate: any;
    endDate: any;
    userInfo: any;
    constructor(
        private hourlyreportDataService: HourlyReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getHourlyReports();
    }

    getColDef() {
        return hourlyReportColDef;
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.HOURLY_ERROR_MESSAGE))
    async getHourlyReports() {
        const reqbody: any = {};
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const hourlyReports = await this.hourlyreportDataService.getHourlyReportwithparams(reqbody);
        this.hourlyReports = hourlyReports;
        this.getVolumeSum(this.hourlyReports);
        if (this.hourlyReports.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
    }
    @computed
    get DuplicateList(): HourlyReportResponse[] {
        return toJS(this.hourlyReports);
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.HOURLY_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        if (this.checkCCLogin()) {
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            if(!this.userInfo.carrierCompanyId || this.userInfo.carrierCompanyId == null || this.userInfo.carrierCompanyId == 0){
                errorHandler(INVALID_CCID);
                return;
            }
        }
        const hourlyReports = await this.hourlyreportDataService.getHourlyReportwithparams(reqbody);
        this.hourlyReports = hourlyReports;
        this.getVolumeSum(this.hourlyReports);
        if (this.hourlyReports.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
    }
    @action
    setDriverList(drivers: HourlyReportResponse[]): void {
        this.hourlyReports = drivers;
    }

    isDisabled = (): boolean => {
        if (this.hourlyReports.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    checkCCLogin() {
        let access = false;
        const currentUserRoles = this.accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    getVolumeSum(data){
        let volDelivered=0, waitingtime=0, workingTime=0;
        for (let report in data) {
            volDelivered += data[report].Volume_Delivered;
            waitingtime += data[report].Waiting_Time;
            workingTime += data[report].Working_Time
        }
        this.volumeSum.volDeliveredSum = volDelivered.toFixed(3);
        this.volumeSum.totalWaitingTime = waitingtime.toFixed(3);
        this.volumeSum.totalWorkingTime = workingTime.toFixed(3);
    }
}
