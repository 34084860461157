import './_custom-button.scss';

import * as React from 'react';

import className from 'classnames';

export interface CustomButtonProps {
    onClick: () => void;
    type: CustomButtonType;
    iconClassName?: string;
    disabled?: boolean;
}

export enum CustomButtonType {
    Ok = 'ok',
    Cancel = 'cancel',
    Submit = 'submit',
    Register = 'register'
}

export class CustomButton extends React.Component<CustomButtonProps> {
    public render(): React.ReactNode {
        const { onClick, type, iconClassName, children, disabled = false } = this.props;
        let iconClasses = '';
        if (iconClassName) {
            iconClasses = `fa ${iconClassName}`;
        }
        const rootType = `custom-button-${type}`;
        const rootClasses = className('btn', {
            'custom-button': true,
            [rootType]: true
        });
        const iconType = `custom-button__icon-${type}`;
        const labelType = `custom-button__label-${type}`;
        const iconClassNames = className(iconClasses, {
            'custom-button__icon': true,
            [iconType]: true
        });
        const labelClassNames = className('custom-button__label', {
            [labelType]: true
        });
        return (
            <button type="button" disabled={disabled} onClick={onClick} className={rootClasses}>
                {iconClassName ? <i className={iconClassNames}></i> : null}
                <div className={labelClassNames}>{children}</div>
            </button>
        );
    }
}
