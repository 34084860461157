import { Component } from 'react';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TicketStore } from '../stores/ticket-store';
import { TicketList } from './ticket-list';
import { CellValueChangedEvent, FilterChangedEvent, CheckboxChangedEvent, RangeSelectionChangedEvent } from 'ag-grid-community';
import './_ticket-container.scss';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { TicketModel } from '../model/ticket-response-model';
import { TicketFieldName, TicketVolOrHourly } from '../constants/ticket-enum';
import { TicketApproverCommentPopup } from './ticket-approver-comment-popup';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { DispatchComponentName } from 'features/dispatch/dispatch-management/constants/constant';
import { ticketGridService } from '../services/ticket-grid-service';
import { printDisclaimers, printLabels } from '../constants/ticket-management-constant';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDocument } from './ticket-pdf-preview-component';
import { localStorageService } from 'shared/services/local-storage-service';
import _ from 'lodash';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-dayPickerTicketApproval';
import { OperationsComponentName } from '../constants/constant';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ticketDataAdapterService } from '../services/ticket-data-adapter-service';
import { toJS } from 'mobx';
import { AppConstant } from 'app_constant';
/* eslint-disable */
interface TicketContainerProps {
    ticketStore: TicketStore;
    ticketModel?: TicketModel;
}
interface TicketContainerState {
    isFilterOn: boolean;
}

@inject('ticketStore')
@observer
export class TicketContainer extends Component<TicketContainerProps, TicketContainerState> {
    blobURL: any = [];
    constructor(props) {
        super(props);
        props.ticketStore.init();
        this.state = { isFilterOn: false };
        dataStore.setModuleName('TicketApproval');
    }
    render(): React.ReactNode {
        return (
            <Container fluid={true}>
                <Row>
                    <Col>{this.renderAction()}</Col>
                </Row>
                <Row>
                    <Col>{this.renderGrid()}</Col>
                </Row>
            </Container>
        );
    }

    componentWillUnmount() {
        const { ticketStore } = this.props;
        ticketStore.isDownloadEnabled = false;
    }

    handleApproverCommentVisibility = () => {
        const { ticketStore } = this.props;
        ticketStore.hideTicketApproverPopUp();
    };

    handleApproverCommentCancel = () => {
        const { ticketStore } = this.props;
        ticketStore.hideTicketApproverPopUp();
        ticketStore.resetValue();
    };

    handleApproverCommentSubmission = (approverComment: string) => {
        const { ticketStore } = this.props;
        ticketStore.updateApproverComment(approverComment);
    };

    renderAction(): React.ReactNode {
        const { ticketStore } = this.props;
        const { ticketItems } = ticketStore;
        let selectedTickets = ticketStore.selectedticketList;
        let isDownloadEnabled = ticketStore.isDownloadEnabled;
        let ticketNumberAtFirstRow = ticketStore.ticketItems[0]?.ticketNumber;
        const { isApproverPopUpVisible, isSaveEnabled, selectedTicketNumber, selectedticketsList } = ticketStore;
        return (
            <>
                <TicketApproverCommentPopup
                    onApproverCommentSubmission={this.handleApproverCommentSubmission}
                    onApproverCommentVisibility={this.handleApproverCommentVisibility}
                    onApproverCommentCancel={this.handleApproverCommentCancel}
                    showPopup={isApproverPopUpVisible}
                    cancelOption={true}
                />

                <Row className="trailer-maintenance-data__action">
                    <Row>
                        <Col className="mt-3 mt-sm-0">
                            {dataStore.checkOperationAccessWithModule(
                                RoleBasedActionName.Save,
                                OperationsComponentName.TicketApproval
                            ) && (
                                    <>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            disabled={!isSaveEnabled}
                                            onClick={this.handleSave}
                                        >
                                            Save Ticket Data
                                        </CustomButton>
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            disabled={!isSaveEnabled}
                                            onClick={this.handleReset}
                                        >
                                            Reset
                                        </CustomButton>
                                    </>
                                )}
                            {dataStore.checkOperationAccess('Approve')
                                && (
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        disabled={ticketStore.isApproveEnabled()}
                                        onClick={this.handleApprove}//{this.props.ticketStore.approveTickets}
                                    >
                                        Approve
                                    </CustomButton>
                                )}
                            <CustomButton
                                type={CustomButtonType.Submit}
                                disabled={ticketStore.isDisabled()}
                                onClick={this.exportCsv}
                            >
                                Export to Excel
                            </CustomButton>
                            <CustomButton
                                type={CustomButtonType.Submit}
                                disabled={ticketStore.isDisabled()}
                                onClick={this.handleClear}
                            >
                                Clear Filters
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={false} onClick={this.handleRefresh}>
                                Refresh
                            </CustomButton>
                            {/* {!isDownloadEnabled && (
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    disabled={!isDownloadEnabled || this.state.isFilterOn}
                                    onClick={this.handleClear}
                                >
                                    DOWNLOAD PDF
                                </CustomButton>
                            )} */}
                            <CustomButton type={CustomButtonType.Submit}
                                disabled={selectedticketsList.length === 0 ||
                                    selectedticketsList.length > 5}
                                onClick={() => {
                                    ticketStore.isDownloadEnabled = true
                                }}> GENERATE PDF </CustomButton>

                            {/* {isDownloadEnabled && (
                                <span style={{ margin: '10px 0px 10px 10px' }}>
                                    <PDFDownloadLink
                                        document={<MyDocument selectedTickets={selectedTickets} />}
                                        fileName={
                                            selectedTickets ? selectedTicketNumber : ticketNumberAtFirstRow + '.pdf'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            padding: '8px',
                                            color: '#ffffff',
                                            backgroundColor: '#00B1F6',
                                            borderRadius: '5px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {' '}
                                        DOWNLOAD PDF
                                    </PDFDownloadLink>
                                </span>
                            )} */}
                            {isDownloadEnabled &&
                                <CustomButton type={CustomButtonType.Submit}
                                    onClick={() => {
                                        this.handlePDFDownloadMulti();
                                        this.blobURL = []
                                        ticketStore.isDownloadEnabled = false
                                        ticketStore.selectedticketsList = []
                                    }}>DOWNLOAD
                                    {selectedticketsList.map((ticket) =>
                                        <PDFDownloadLink
                                            document={<MyDocument selectedTickets={ticket} />}
                                            fileName={ticket.ticketNumber + '.pdf'}
                                            style={{
                                                display: 'none',
                                                textDecoration: 'none',
                                                padding: '8px',
                                                color: '#ffffff',
                                                backgroundColor: '#00B1F6',
                                                borderRadius: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {({ blob, url, loading, error }) => {
                                                if (url !== null && !this.blobURL.includes(url)) {
                                                    this.blobURL.push({ URL: url, ticketNumber: ticket.ticketNumber })
                                                }
                                                return loading ? 'LOADING...' : 'DOWNLOAD'

                                            }}
                                        </PDFDownloadLink>)}
                                </CustomButton>}
                            <CustomButton
                                type={CustomButtonType.Submit}
                                onClick={this.handleSelectAll}
                            //disabled={this.state.selectedLoadStatus === "All" || this.props.dispatchPlanDataStore.hasNoDataInFilter}
                            >
                                {this.props.ticketStore.getButtonText()}
                            </CustomButton>
                        </Col>
                    </Row>
                </Row>
                <ReactDayPickerInput _dataStore={this.props.ticketStore} showEnddate={true}></ReactDayPickerInput>
            </>
        );
    }

    componentDidUpdate() {
        const filters: any = localStorageService.get('TicketApproval_filter');
        if (
            filters &&
            !_.isEqual(JSON.stringify(filters), JSON.stringify(ticketGridService.getAllFilters())) &&
            Object.keys(filters).length > 0
        ) {
            ticketGridService.setAllFilters(filters);
        }
    }

    handlePDFDownloadMulti = () => {
        const { ticketStore } = this.props;
        this.blobURL.map((url) => {
            fetch(url.URL).then(response => {
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.target = "_blank"
                    alink.download = url.ticketNumber;
                    alink.click();
                })
            })
        })
        ticketStore.getRangeResult(ticketStore.startDate, ticketStore.endDate);
    }

    handleSelectAll = () => {
        const csvData: any = ticketGridService.getData();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.ticketStore.handleSelectAllData(data);
        ticketGridService.gridApi?.refreshCells({ force: true });
    };

    handleSave = () => {
        const { ticketStore } = this.props;
        ticketStore.saveTicket();
        ticketStore.backUpTicketList.clear();
    };

    handleApprove = () => {
        const { ticketStore } = this.props;
        const csvData: any = ticketGridService.getData();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        ticketStore.approveTickets(data);
    }

    handleReset = () => {
        const { ticketStore } = this.props;
        ticketStore.resetTicket();
    };

    handleClear = () => {
        localStorageService.set('TicketApproval_filter', {});
        ticketGridService.clearAllFilters();
        const csvData: any = ticketGridService.getData();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        if(csvData?.rowModel.rowsToDisplay.length === this.props.ticketStore.checkBoxSelectionCount){
            this.props.ticketStore.selectButtonText = 'Deselect All'
        } else {
            this.props.ticketStore.selectButtonText = 'Select All'
        }
    };

    handleRefresh = () => {

        const { ticketStore } = this.props;
        const updatedRecords = ticketDataAdapterService.getUpdatedRecords(toJS(ticketStore.ticketList), ticketStore.backUpTicketList);
        if (updatedRecords.length === 0 && !ticketStore.isDownloadEnabled) {
            if (ticketStore.startDate && ticketStore.endDate) {
                ticketStore.getRangeResult(ticketStore.startDate, ticketStore.endDate)
            }
            else {
                ticketStore.init();
            }
        }
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    if (ticketStore.startDate && ticketStore.endDate) {
                        ticketStore.getRangeResult(ticketStore.startDate, ticketStore.endDate)
                    }
                    else {
                        ticketStore.init();
                    }
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    exportCsv = () => {
        const allColumns: any = ticketGridService.getColumns()?.getAllDisplayedColumns();
        allColumns.map((col, index) => {
            if (col.getColId().toUpperCase() === 'SELECT') {
                allColumns.splice(index, 1);
            }
        });
        ticketGridService.getData()?.exportDataAsExcel({ fileName: 'TicketApproval' });
    };

    handleSelectChange = (option: any) => {
        const { ticketStore } = this.props;
        if (option) {
            ticketStore.setTicketManagementSelectedOption(option);
        }
    };

    handleCellValueChanged = (event: CellValueChangedEvent): void => {
        const { ticketStore } = this.props;
        if (event.column.getColId() === TicketFieldName.STATUS) {
            const ticket: TicketModel = event.data;
            ticketStore.updateTicket(ticket, ticket.status, TicketFieldName.STATUS);
        } else if (event.column.getColId() === TicketFieldName.WORKINGTIMEREASON) {
            const ticket: TicketModel = event.data;
            ticketStore.updateTicket(ticket, ticket.workingTimeReason, TicketFieldName.WORKINGTIMEREASON);
        } else {
            const ticket: TicketModel = event.data;
            ticketStore.updateTicket(ticket, ticket.waitingTimeReason, TicketFieldName.WAITINGTIMEREASON);
        }
    };

    handleFilterChange = (event: FilterChangedEvent): void => {
        const { ticketStore } = this.props;
        ticketStore.isDownloadEnabled = false;
        localStorageService.set('TicketApproval_filter', ticketGridService.getAllFilters());
        const csvData: any = ticketGridService.getData();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.ticketStore.getVolumeSum(data);
        this.setState({ isFilterOn: !this.state.isFilterOn });
    };


    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = ticketGridService.getData();
        const checkArray: any = [];

        if (e.started === false && e.finished === true) {
            var cellRanges: any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                if (index >= startRowIndex && index <= endRowIndex) {
                    this.props.ticketStore.selectTicketToBeDownloaded(x.data, false, "SELECT");
                    x.setDataValue('SELECT', true);
                    checkArray.push('true');
                } else {
                    checkArray.push(x.data['SELECT'] ? 'true' : 'false');
                }
            });

            ticketGridService.gridApi?.refreshCells({ force: true });

            if (checkArray.indexOf('false') < 0) {
                this.props.ticketStore.selectButtonText = 'Deselect All';
            } else {
                this.props.ticketStore.selectButtonText = 'Select All';
            }
        }
    }

    commaFormat = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    renderGrid(): React.ReactNode {
        const { ticketStore } = this.props;
        const { ticketItems } = ticketStore;
        const colDefs = ticketStore.getColumnDef();
        return (
            <>
                <TicketList
                    onCellValueChanged={this.handleCellValueChanged}
                    columnDefs={colDefs}
                    ticketListItems={ticketItems}
                    onFilterChanged={this.handleFilterChange}
                    onRangeSelectionChanged={this.handlerRangeSelectionChanged}
                />
                <div className="customer-report">
                    <Row>
                        <Col className="mt-3 mt-sm-0">
                            Volume Delivered Sum : {this.commaFormat(this.props.ticketStore.EstVolumeSum)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Total Working Time : {this.commaFormat(this.props.ticketStore.totalWorkingTime)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Total Waiting Time : {this.commaFormat(this.props.ticketStore.totalWaitingTime)}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
