import React from 'react';
import { Modal } from 'react-bootstrap';
import { DispatchPlanDriverComponent } from './dispatchPlan-driver-component';
import { DriverResponse } from '../model/model';

interface DispatchPlanPopUpProps {
    onHide: () => void;
    show: boolean;
    driverName?: any;
    selectedDriverId: number;
    drivers: DriverResponse[]
    onSelectionChange: (driverID: any, buttonName: string) => void;
}

export const DispatchPlanDriverPopupComponent = (props: DispatchPlanPopUpProps) => {
    const { driverName, onHide, show, onSelectionChange, selectedDriverId, drivers } = props;
    return (
        <Modal size="lg" onHide={onHide} backdrop= 'static' show={show} animation={false}>
            <Modal.Body>
                <DispatchPlanDriverComponent
                    selectedDriverId={selectedDriverId}
                    driverName={driverName}
                    onHide={onHide}
                    drivers={drivers}
                    onSelectionChange={onSelectionChange}
                ></DispatchPlanDriverComponent>
            </Modal.Body>
        </Modal>
    );
};