export enum PrinterSaveLabel {
    SAVE = 'Save Printer Data',
}


export enum PrinterrMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Printer data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    DELETEDSUCCESS = 'Deleted Successfully',
    DELETEDERROR = 'Failed to delete records',
    NO_ROW_SELECTED = 'No record selected'

}

export enum PrinterHeaderName {
    ID = 'ID',
    PRINTERNAME = 'Printer Name',
    PRINTERSERIALNUMBER = 'Printer Serial Number',
    MODIFIEDBY = 'Modified By',
    MODIFIEDDATE = 'Modified Date',
    CREATEDBY = 'Created By',
    CREATEDDATE = 'Created Date',
    DELETE = 'Delete',
    CARRIERCOMPANY = 'Carrier Company',
    ASSIGNEDTO = 'Assigned To'
}

export enum PrinterFieldName {
    ID = 'ID',
    MODIFIEDBY = 'ModifiedByUser',
    MODIFIEDDATE = 'ModifiedDate',
    CREATEDBY = 'CreatedByUser',
    CREATEDDATE = 'CreatedDate',
    PRINTERNAME = 'PrinterName',
    PRINTERSERIALNUMBER = 'PrinterSerialNumber',
    DELETE = 'Delete',
    CARRIERCOMPANY = 'CarrierCompany',
    ASSIGNEDTO = 'AssignedTo'
}