import { DriverResponse } from "features/data_management/domains/driver-management/model";
import { DriverFieldName, DriverHeaderName } from "features/data_management/domains/driver-management/enum";
import { CommonUtils } from "shared/services/common-utils";

export class DriverValidationService {
    validateUpdateRequest(updatedList: DriverResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item[DriverFieldName.FIRSTNAME])) {
                errorMessage = `${DriverHeaderName.FIRSTNAME} is a mandatory field`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item[DriverFieldName.LASTNAME])) {
                errorMessage = `${DriverHeaderName.LASTNAME} is a mandatory field`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item[DriverFieldName.PHONENUMBER])) {
                errorMessage = `${DriverHeaderName.PHONENUMBER} is a mandatory field`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[DriverFieldName.PHONENUMBER]) && !CommonUtils.checkForPhoneNumberSyntax(item[DriverFieldName.PHONENUMBER])) {
                errorMessage = `${DriverHeaderName.PHONENUMBER} must contain 10 digits`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[DriverFieldName.FIRSTNAME]) && !CommonUtils.checkForAlphaNumeric(item[DriverFieldName.FIRSTNAME])) {
                errorMessage = `${DriverHeaderName.FIRSTNAME} must be alphanumeric`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNotEmpty(item[DriverFieldName.LASTNAME]) && !CommonUtils.checkForAlphaNumeric(item[DriverFieldName.LASTNAME])) {
                errorMessage = `${DriverHeaderName.LASTNAME} must be alphanumeric`;
                return;
            }
        });
        return errorMessage;
    }
}

export const driverValidationService = new DriverValidationService();