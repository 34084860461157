import { FilterKeyForReferenceData } from '../domains/enum';
import React from 'react';
import {
    dailyReportStore,
    hourlyReportStore,
    leaseOperatorStore,
    customerReportStore,
    rejectedLoadStore,
    scheduledReportStore,
    upcomingCertificationDataStore,
    searchTicketsStore,
    masterTicketReportStore
} from '../stores';
import { DefaultPageComponent } from 'features/common/components/default-page-component';
import { DailyReportComponent } from './daily-report-component';
import { HourlyReportComponent } from './hourlyticket-report-component';
import { LeaseOperatorComponent } from './lease-operator-component';
import { CustomerReportComponent } from './customer-report-component';
import { ScheduledReportComponent } from './scheduled-report-component';
import { RejectedLoadsComponent } from './rejected-load-component';
import { UpcomingCertificationReportComponent } from './upcoming-certificate-report-component';
import { SearchTicketsComponent } from './search-tickets-component';
import { MasterTicketReportComponent } from './master-ticket-report-component';
export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        FilterKeyForReferenceData.Select,
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.DailyReport,
        () => (
            <React.Fragment>
                <DailyReportComponent dailyReportStore={dailyReportStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.LeaseOperator,
        () => (
            <React.Fragment>
                <LeaseOperatorComponent leaseoperatorStore={leaseOperatorStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.HourlyReport,
        () => (
            <React.Fragment>
                <HourlyReportComponent hourlyReportStore={hourlyReportStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.CustomerReport,
        () => (
            <React.Fragment>
                <CustomerReportComponent customerReportStore={customerReportStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.RejectedLoads,
        () => (
            <React.Fragment>
                <RejectedLoadsComponent rejectedLoadStore={rejectedLoadStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.ScheduledReports,
        () => (
            <React.Fragment>
                <ScheduledReportComponent scheduledReportStore={scheduledReportStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.UpcomingCertificationReport,
        () => (
            <React.Fragment>
                <UpcomingCertificationReportComponent  upcomingCertificationDataStore={upcomingCertificationDataStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.SearchTickets,
        () => (
            <React.Fragment>
                <SearchTicketsComponent searchTicketsStore={searchTicketsStore} />
            </React.Fragment>
        )
    ],
    [
        FilterKeyForReferenceData.MasterTicketReport,
        () => (
            <React.Fragment>
                <MasterTicketReportComponent masterTicketReportStore={masterTicketReportStore} />
            </React.Fragment>
        )
    ]
]);
