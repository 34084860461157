
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import './_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { DriverResponse } from '../model/model';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';

interface DispatchPlanProps {
    selectedDriverId: number;
    driverName: string;
    drivers: DriverResponse[]
    onHide: () => void;
    onSelectionChange: (driverName: string, buttonName: string) => void;
}

interface DispatchPlanState {
    selectedDriverId: number;
    selectedDriverName: string;
}

@observer
export class DispatchPlanDriverComponent extends Component<DispatchPlanProps, DispatchPlanState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedDriverId: 0,
            selectedDriverName: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedDriverId != this.state.selectedDriverId) {
            this.setState({ selectedDriverId: this.props.selectedDriverId, selectedDriverName: this.state.selectedDriverName });
        }
    }

    mapDriverToDriverOption = drivers => DataAdapterService.getOptionValues(drivers, 'DriverName', 'DriverID', false);

    getSelectedShopOption = (id, drivers) => {
        let selectedShopObj = drivers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { drivers } = this.props;
        const { selectedDriverId, selectedDriverName } = this.state;
        const shopOptions = this.mapDriverToDriverOption(drivers);
        const selectedShopOption = this.getSelectedShopOption(selectedDriverId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Driver Name</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Driver"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignShop}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => {
        this.setState({ selectedDriverName: option.label, selectedDriverId: option.value });
    }

    assignShop = () => {
        var selectedDriverOrCarrierID;
        const driverName = this.props.drivers.findIndex(a => a.DriverName == this.state.selectedDriverName);
        selectedDriverOrCarrierID = this.props.drivers[driverName].DriverID;
        const confirmService = new ConfirmService();
        confirmService.confirmBulkAssignPopup(
            (buttonName) => {
                this.props.onSelectionChange(selectedDriverOrCarrierID, buttonName)
                this.props.onHide();
            }
        );
    };
}
