import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { UpcomingCertificationDataStore } from '../stores/upcomingcertificate-data-store'
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import jsPDF from 'jspdf';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { upcomingCertificateColDef } from '../domains/upcoming-certification-col-def';
import { CommonUtils } from 'shared/services/common-utils';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface UpcomingCertificationReportProps {
    upcomingCertificationDataStore: UpcomingCertificationDataStore;
}

@observer
export class UpcomingCertificationReportComponent extends Component<UpcomingCertificationReportProps, any> {
    months = moment.months();
    startDate: any = moment();
    endDate: any;
    from: any = moment().toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    selectedOption: any = [{ value: 'all', label: 'All' }];
    constructor(props) {
        super(props);
        const years = [] as any;
        for (let i = 2020; i <= new Date().getFullYear() + 1; i += 1) {
            years.push(i);
        }
        this.state = {
            yearsRange: years,
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth(),
            selectedMonthLabel: moment.months()[new Date().getMonth()],
            startDate: ''
        };

        dataStore.setModuleName('UpcomingCertificationReport');
    }

    componentDidMount() {
        const { upcomingCertificationDataStore } = this.props;
        if(CommonUtils.getQueryStringParam('month') && CommonUtils.getQueryStringParam('year')){
            const month = parseInt(CommonUtils.getQueryStringParam('month'));
            const year =  parseInt(CommonUtils.getQueryStringParam('year'));
            upcomingCertificationDataStore.getUpcomingCertification(year, month);
            this.setState({selectedYear: year, selectedMonth: month-1
                        , selectedMonthLabel: moment.months()[month-1]})
        } else {
        upcomingCertificationDataStore.init();}
    }
    getUpcomingCertification = (selectedYear, selectedMonth) => {
        const { upcomingCertificationDataStore } = this.props;
        upcomingCertificationDataStore.getUpcomingCertification(selectedYear, selectedMonth + 1);
    };

    handleChangeMonth = (value) => {
        this.setState({
            selectedMonth: value,
            selectedMonthLabel:moment.months()[value]
        })
        this.getUpcomingCertification(this.state.selectedYear, value - 1);
        this.props.upcomingCertificationDataStore.agGridService.setAllFilters(localStorageService.get('UpcomingCertificationReport_filter'));
    }

    handleChangeYear = (value) => {
        this.setState({
            selectedYear: value
        });
        this.getUpcomingCertification(value, this.state.selectedMonth - 1);
        this.props.upcomingCertificationDataStore.agGridService.setAllFilters(localStorageService.get('UpcomingCertificationReport_filter'));
    }

    render(): React.ReactNode {
        const {
            upcomingCertificationDataStore: { isDisabled }, upcomingCertificationDataStore
        } = this.props;
        const { selectedMonth, selectedYear } = this.state;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.exportPdf}
                                        disabled={isDisabled()}
                                    >
                                        {ReportsButton.PDF}
                                    </CustomButton>
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true} disabled={isDisabled()}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <div className="timeline-bar-container">
                                    <div className="select-container">
                                        Year
                                        <select
                                            name="year"
                                            key="year"
                                            className="select-style"
                                            onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                                            value={selectedYear}
                                        >
                                            {this.state.yearsRange.map((yearData, index) => (
                                                <option key={index} value={yearData}>
                                                    {yearData}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="select-container">
                                        Month
                                        <select
                                            name="month"
                                            key="month"
                                            onChange={e => this.handleChangeMonth(parseInt(e.currentTarget.value, 10))}
                                            value={selectedMonth}
                                            className="select-style"
                                        >
                                            {this.months.map((month, i) => (
                                                <option key={i} value={i}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('UpcomingCertificationReport_filter');
        this.props.upcomingCertificationDataStore.agGridService.setAllFilters(filters);
    }

    createHeaderCell(col) {
        let headerCell: any = {};

        let headerName: any = col.colDef.headerName;

        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }

    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }

    handleRefresh = () => {
        const { upcomingCertificationDataStore } = this.props;
        upcomingCertificationDataStore.isRefreshed = true;
        const { selectedMonth, selectedYear } = this.state;
        localStorageService.set('UpcomingCertificationReport_filter',localStorageService.get('UpcomingCertificationReport_filter'))
        upcomingCertificationDataStore.getUpcomingCertification( selectedYear, selectedMonth );
    }

    private exportPdf = (): void => {
        const {
            upcomingCertificationDataStore: { agGridService }
        } = this.props;

        const data: any = agGridService.getNodes();
        const columns: any = agGridService.getColumns();
        let columnsToExport: any = [];
        let columnHeader: any = [];
        let rowsToExport: any = [];
        columns?.getAllDisplayedColumns().forEach(col => {
                let headerCell: any = this.createHeaderCell(col);
                columnsToExport.push(headerCell);
                columnHeader.push(headerCell.text);
        });
        data.forEachNodeAfterFilterAndSort(node => {
            console.log(node);
            let rowToExport = columnsToExport.map(({ colId }) => {
                let cellValue = data.getValue(colId, node);
                let tableCell = this.createTableCell(cellValue, colId);
                console.log(tableCell);
                return tableCell;
            });
            rowsToExport.push(rowToExport);
        });
        console.log(columnsToExport, rowsToExport, columnHeader);
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = TitlePDF.UpcomingCertificatesReport;
        const theme: any = 'grid';
        const content = {
            theme: theme,
            startY: 50,
            head: [columnHeader],
            body: rowsToExport
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content);
        doc.save(TitlePDF.UpcomingCertificatesReport + '.pdf');
    };
    
    private exportCsv = () => {
        const csvData: any = this.props.upcomingCertificationDataStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        for (let i = 0; i < data.length; i++) {

        data[i]['Date'] =
        data[i]['Date'] === null
            ? momenttimezone
            .tz(moment.utc(), AppConstant.MST_TIME_FORMATTER)
            .format(AppConstant.DATE_TIME_FORMAT)
            : momenttimezone
                  .tz(moment.utc(data[i]['Date']), AppConstant.MST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
        let str = JSON.stringify(data);
        str = str.replace(/\"TrailerNumber\":/g, '"Trailer Number":');
        str = str.replace(/\"TrailerStatus\":/g, '"Trailer Status":');
        str = str.replace(/\"TrailerType\":/g, '"Trailer Type":');
        str = str.replace(/\"CertificateCount\":/g, '"Due Inspections Count":');
        str = str.replace(/\"DueDate\":/g, '"Due Date":');
        str = str.replace(/\"DueCertificates\":/g, '"Due Inspections":');
        const ws = XLSX.utils.json_to_sheet(JSON.parse(str));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.UpcomingCertificatesReport);
    };
    }

    private getGridConfig(): GridOptions {
        const {
            upcomingCertificationDataStore: { agGridService, upcomingCertificatesReport }
        } = this.props;
        const { upcomingCertificationDataStore } = this.props;

        const rowData = upcomingCertificatesReport;
        const columnDefs = upcomingCertificateColDef;
        const onFilterChanged = this.onFilterChanged;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.upcomingCertificationDataStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName()+'_filter', this.props.upcomingCertificationDataStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
    };
}
