import { DriverResponse } from "features/data_management/domains/driver-management/model";
import { LocationFieldName, LocationHeaderName } from "features/data_management/domains/location-management/enum";
import { CommonUtils } from "shared/services/common-utils";

export class LocationValidationService {
    validateUpdateRequest(updatedList: DriverResponse[]) {
        let errorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkForLoad(item[LocationFieldName.CURRENTLOADSIZE])) {
                errorMessage = `${LocationHeaderName.CURRENTLOADSIZE} should have only digits`;
                return;
            }
            
        });
        return errorMessage;
    }
}

export const locationValidationService = new LocationValidationService();