export enum HazardAssessmentHistoryHeaderName {
    HAZARDID = 'Hazard ID',
    FIRSTNAME = 'First Name',
    LASTNAME = 'Last Name',
    TICKETNUMBER = 'Ticket Number',
    OPERATIONSREVIEW = 'Operations Review',
    OPERATIONSREVIEWCOMMENTS = 'Operations Review Comments',
    OPERATIONSREVIEWDATE = 'Operations Reviewed Date',
    OPERATIONSREVIEWBY = 'Operations Reviewed By',
    COMPLIANCEREVIEW = 'Compliance Review',
    COMPLIANCEREVIEWCOMMENTS = 'Compliance Review Comments',
    COMPLIANCEREVIEWDATE = 'Compliance Reviewed Date',
    COMPLIANCEREVIEWBY = 'Compliance Reviewed By',
    RESOLVED = 'Resolved',
    RESOLVEDDATE = 'Resolved Date',
    RESOLVEDBY = 'Resolved By',
    RESOLVEDCOMMENTS = 'Resolved Comments',
    HAZARDASSESSMENTDATE = 'Hazard ID Date',
    MODIFIEDDATE = 'Modified Date',
    MODIFIEDBYUSER = 'Modified By',
    CONSIGNORLOCATION = 'Consignor Location',
    CONSIGNEELOCATION = 'Consignee Location'
}

export enum HazardAssessmentHistorySaveLabel {
    SAVE = 'Save Data',
}

export enum HazardAssessmentHistoryFieldName {
    HAZARDID = 'HazardID',
    CREATEDBY = 'CreatedBy',
    FIRSTNAME = 'FirstName',
    LASTNAME = 'LastName',
    TICKETNUMBER = 'VolumeorHourlyTicketNumber',
    OPERATIONSREVIEW = 'OperationReview',
    OPERATIONSREVIEWCOMMENTS = 'OperationReviewComments',
    OPERATIONSREVIEWDATE = 'OperationReviewDate',
    OPERATIONSREVIEWBY = 'OperationReviewByName',
    COMPLIANCEREVIEW = 'ComplianceReview',
    COMPLIANCEREVIEWCOMMENTS = 'ComplianceReviewComments',
    COMPLIANCEREVIEWDATE = 'ComplianceReviewDate',
    COMPLIANCEREVIEWBY = 'ComplianceReviewByName',
    RESOLVED = 'Resolved',
    RESOLVEDDATE = 'ResolvedDate',
    RESOLVEDBY = 'ResolvedByName',
    RESOLVEDCOMMENTS = 'ResolvedComments',
    HAZARDASSESSMENTDATE = 'CreatedDate',
    MODIFIEDDATE = 'ModifiedDate',
    MODIFIEDBYUSER = 'ModifiedByName',
    CONSIGNORLOCATION = "Consignor",
    CONSIGNEELOCATION = 'Consignee'
}
export enum HazardAssessmentHistoryMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Hazard History data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    NO_TICKETS = 'No Hazards Found',
}

export enum HazardAssessmentStatus {
    NEW = 'New',
    REVIEWED = 'Reviewed',
    REVIEWED_ISSUE = 'Reviewed-Issue'
}
export enum ResolvedStatus {
    RESOLVED = 'true',
    NOTRESOLVED = 'false'
}