import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { LocationMapingResponse, OperatorResponse } from 'features/data_management/domains/location-maping-management/model';
import { BaseConfigValue, ResponseDataType } from 'shared/types/shared';
import { AppConstant } from 'app_constant';

export class LocationMapingDataService {
    constructor(private restApi: RestApiService) { }

    async sendLocationMapping(reqbody): Promise<LocationMapingResponse[]> {
        const { data } = await this.restApi.post<LocationMapingResponse>(Config.sendLocationMapping, reqbody, {});
        return data['Data'];
    }

    async getLocationMapingScreenData(): Promise<LocationMapingResponse[]> {
        const { data } = await this.restApi.postWithToken<LocationMapingResponse[]>(Config.getLocationMapingScreenData);
        return data['Data'];
    }

    async getOperator( reqbody = {}): Promise<OperatorResponse[]> {
        const { data } = await this.restApi.postWithToken<OperatorResponse[]>(Config.getOperator, reqbody, {});
        return data['Data'];
    }

    async getRAConfig(): Promise<ResponseDataType<BaseConfigValue[]>> {
        const { data } = await this.restApi.post<BaseConfigValue[]>(Config.getBaseValueByClass, { CODE: AppConstant.RA_CONFIG_TYPE });
        return data['Data']
    }

}

export const locationMapingDataService = new LocationMapingDataService(restApiService);
