import { accountStore } from 'features/account/stores';
import { AccountStore } from 'features/account/stores/account-store';
import { AgGridService, agGridService } from 'features/common/services/ag-grid-service';
import { action, computed, observable, toJS } from 'mobx';
import Loader from '../../../../shared/decorators/loader-decorator';
import Catch from '../../../../shared/decorators/catch-decorator';
import { UiService, uiService } from 'shared/services/ui-service';
import { apportionmentDataService, ApportionmentDataService } from '../services/apportionment-data-service';
import { apportionmentData } from '../apportionment.constants';
import { errorHandler } from 'shared/handlers/error-handler';
import { INVALID_USERID } from 'features/data_management/data_constant';

export class ApportionmentDataStore {
    @observable dataToBeDeleted: any = [];
    @observable apportionmentData: any = [];
    backupapportionment = [];
    cellValueChangeMap: any = {};
    userID: any;
    selectedYear: any;
    selectedMonth: any;
    constructor(
        private apportionmentDataService: ApportionmentDataService,
        public agGridService: AgGridService,
        private accountStore: AccountStore,
        private uiService: UiService
    ) {}

    init() {
        this.userID = this.accountStore.getUserID();
        this.getApportionmentScreenData(new Date().getMonth() + 1, new Date().getFullYear());
    }

    @computed
    get getApportionmentData(): any[] {
        return toJS(this.apportionmentData);
    }
    @Loader
    @Catch(() => errorHandler('Could not load Apportionment Data'))
    async getApportionmentScreenData(month, year) {
        const reqbody = {
            Month: month,
            Year: year
        };
        const response = await this.apportionmentDataService.getApportionmentData(reqbody);
        this.apportionmentData = response.data['Data'];
        this.setBackupApportionmentData(this.apportionmentData);
    }

    updateData(updatedData) {
        updatedData.forEach(item => {
            delete item['CreatedBy'];
        });
        return updatedData;
    }

    @Loader
    @Catch(() => errorHandler('Could not update Apportionment Data'))
    async updateApportionment(month, year, isApply) {
        this.selectedMonth = month;
        this.selectedYear = year;
        const updatedRowIDs = this.apportionmentData.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        const reqdata = this.updateData(updatedRowIDs);
        if (!this.userID || this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const reqBody = {
            Month: this.selectedMonth,
            Year: this.selectedYear,
            ModifiedBy: this.userID,
            IsApply: isApply,
            Apportionments: reqdata
        };
        await this.apportionmentDataService.createUpdateApportionmentData(reqBody);
        this.cellValueChangeMap = {};
        this.getApportionmentScreenData(this.selectedMonth, this.selectedYear);
    }

    setBackupApportionmentData(apportionmentData) {
        this.backupapportionment = toJS(apportionmentData);
    }

    isDisabled = (): boolean => {
        if (this.apportionmentData.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    isApplyDisabled = (): boolean => {
        if (this.apportionmentData.length == 0) {
            return true;
        } else if (this.apportionmentData[0].IsApply) {
            return true;
        } else {
            return false;
        }
    };

    @action
    resetApportionData() {
        console.log('reset', this.backupapportionment);
        this.apportionmentData = this.backupapportionment;
        this.cellValueChangeMap = {};
    }

    handleCheckboxClick(data, value: any, colName: string): void {
        switch (colName) {
            case 'DELETE':
                data.DELETE = !value;
                break;
            default:
                break;
        }
        if (!value) {
            this.dataToBeDeleted.push({ ID: data.ID, Load: data.FacilityCode });
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== data.ID);
        }
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    updateLoadRow = (selectedRowData): void => {
        const updatedDriverIndex = this.apportionmentData.findIndex(a => a.ID == selectedRowData.ID);
        this.mapLoadEditableColumns(this.apportionmentData[updatedDriverIndex], selectedRowData);
    };

    mapLoadEditableColumns(currentItem, updatedItem) {
        currentItem.Apportionment = updatedItem.Apportionment ? parseInt(updatedItem.Apportionment) : null;
    }

    IsEditDisabled() {
        if (this.dataToBeDeleted.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    IsSaveDisabled() {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }
}

export const apportionmentStore = new ApportionmentDataStore(
    apportionmentDataService,
    agGridService,
    accountStore,
    uiService
);
