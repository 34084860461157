import React, { Component } from 'react';
import { GridOptions, CellEditingStoppedEvent, CellEditingStartedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { DriverDataStore } from '../stores/driver-data-store';
import { DriverButtons } from '../domains/driver-management/enum';
import { dataStore } from 'features/common/stores';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { text } from '@fortawesome/fontawesome-svg-core';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';

interface DriverMasterDataProps {
    driverStore: DriverDataStore;
}

@observer
export class DriverMasterDataComponent extends Component<DriverMasterDataProps> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('DriverManagement');
    }

    componentDidMount() {
        const { driverStore } = this.props;
        driverStore.init();
        const filters: any = localStorageService.get('DriverManagement_filter');
        if (filters && Object.keys(filters) && Object.keys(filters).length == 0)
            localStorageService.set('DriverManagement_filter', { IsActive: { values: ['Active'], filterType: 'set' } });
    }

    componentWillUnmount() {
        const { driverStore } = this.props;
        driverStore.reset();
    }

    render(): React.ReactNode {
        const {
            driverStore: { isSaveDisabled }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                {DriverButtons.SAVE}
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                {DriverButtons.RESET}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }
    componentDidUpdate() {
        const filters = localStorageService.get('DriverManagement_filter');
        this.props.driverStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        const { driverStore } = this.props;
        driverStore.updateDrivers();
    };

    private handleReset = (): void => {
        const { driverStore } = this.props;
        driverStore.resetDriverList();
    };

    handleRefresh = () => {
        const { driverStore } = this.props;
        if(Object.keys(driverStore.cellValueChangeMap).length === 0) {
            driverStore.init();
            driverStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    driverStore.init();
                    driverStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            driverStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            this.props.driverStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            driverStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    private getGridConfig(): GridOptions {
        const {
            driverStore: { DriverList, agGridService }
        } = this.props;
        const { driverStore } = this.props;

        const rowData = DriverList;
        const columnDefs = driverStore.getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;

        const DriverMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(DriverMaintenanceGridParams);
    }
}
