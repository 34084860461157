import React from 'react';
import { Modal } from 'react-bootstrap';
import { ShopAssignmentComponent } from './shop-assignment-component';
import './_shop-assignment-popup.scss';
import { ShopInfo } from '../model/model';

interface ShopAssignmentPopUpProps {
    onHide: () => void;
    show: boolean;
    selectedShopID: number;
    shops: ShopInfo[];
    status: string;
    onSelectionChange: (shopId: number) => void;
    shopAssignedDate: string;
}

export const ShopAssignmentPopUpComponent = (props: ShopAssignmentPopUpProps) => {
    const { selectedShopID, shops, onHide, onSelectionChange, show, status, shopAssignedDate } = props;
    return (
        <Modal size="lg" onHide={onHide} show={show}>
            <Modal.Body>
                <ShopAssignmentComponent
                    status={status}
                    onSelectionChange={onSelectionChange}
                    selectedShopID={selectedShopID}
                    shops={shops}
                    onHide={onHide}
                    shopAssignedDate={shopAssignedDate}
                ></ShopAssignmentComponent>
            </Modal.Body>
        </Modal>
    );
};
