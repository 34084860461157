
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import '../../dispatch/dispatch-plan-management/components/_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';

interface SearchTicketsProps {
    selectedDriverId: number;
    DriverName: string;
    drivers:any
    onHide: () => void;
    onSelectionChange: (location: string) => void;
}

interface SearchTicketsState {
    selectedDriverId: number;
    selectedDriverName: string;
}

@observer
export class SearchTicketsDriverComponent extends Component<SearchTicketsProps, SearchTicketsState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedDriverId: 0,
            selectedDriverName: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedDriverId != this.state.selectedDriverId) {
            this.setState({ selectedDriverId: this.props.selectedDriverId, selectedDriverName: this.state.selectedDriverName });
        }
    }

    mapCarrierToCarrierOption = drivers => DataAdapterService.getOptionValues(drivers, 'DriverName', 'ID', false);

    getselectedDriverOption = (id, carriers) => {
        let selectedShopObj = carriers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { drivers } = this.props;
        const { selectedDriverId, selectedDriverName } = this.state;
        console.log(drivers);
        const shopOptions = this.mapCarrierToCarrierOption(drivers);
        const selectedShopOption = this.getselectedDriverOption(selectedDriverId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Driver List</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Driver"
                        options={shopOptions}
                        onChange={this.handleLocationChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignCarrier}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleLocationChange = option => {
        this.setState({ selectedDriverName: option.label, selectedDriverId: option.value });
    }

    assignCarrier = () => {
        this.props.onSelectionChange(this.state.selectedDriverName);
        this.props.onHide();
    };
}
