  export const printLabels = {
    HAZARDASSESSMENT: 'Hazard ID Report',
    HAZARDID: 'Hazard ID:',
    LEVELOFRISK: 'Level Of Risk',
    DATE: 'Date',
    RELATEDTICKET: 'Related Ticket',
    WEATHER: 'Weather',
    LOCATION: 'Location',
    CUSTOMERNAME: 'Customer Name',
    DESCRIPTION: 'Description',
    POSSIBLECAUSES: 'Possible Causes',
    RECOMMENDATIONS: 'Corrective Actions Taken or Recommendations',
    DRIVERNAME: 'Driver Name',

  };
  export const HazardLabelKeys = {
    hazardID: printLabels.HAZARDID,
    levelofrisk: printLabels.LEVELOFRISK,
    date: printLabels.DATE,
    weather: printLabels.WEATHER,
    location: printLabels.LOCATION,
    customername: printLabels.CUSTOMERNAME,
    description: printLabels.DESCRIPTION,
    possiblecauses: printLabels.POSSIBLECAUSES,
    recommendations: printLabels.RECOMMENDATIONS,
    drivername: printLabels.DRIVERNAME
  }
 