import { ColDef } from 'ag-grid-community';
import { accountStore } from 'features/account/stores';
import { dateUtils } from 'shared/services/date-utils';
import AlertRedIcon from '../../../../assets/AlertRedIcon.png';
import { CCUserRoles, plainsRoles } from '../communication-constants';
import { CheckBoxCellRenderer } from '../components/read-checkbox-renderer';
import { MessageCellRenderer } from '../components/message-cell-renderer';

const checkCCLogin = () => {
    let access = false;
    const currentUserRoles = accountStore.userRoles;
    currentUserRoles.forEach(userRole => {
        if (!access) {
            if (CCUserRoles.includes(userRole)) {
                access = true;
            }
        }
    });
    return access;
};
export const BROADCAST_COL_DEF: ColDef[] = [
    {
        headerName: 'Date',
        field: 'CreatedDate',
        width: 100,
        sort: 'desc',
        valueGetter: params => (params.data['CreatedDate'] ? dateUtils.getFormattedDateTimeForCommunication(params.data['CreatedDate']) : '')
    },
    {
        headerName: 'From',
        width: 75,
        field: 'Sender',
    },
    {
        headerName: 'Subject',
        field: 'Subject',
        width: 85,
        valueGetter: params => {
            if (params.data['Subject'] && params.data['Subject'] !== '') {
                return params.data['Subject'];
            } else {
                return '-';
            }
        }
    },
    {
        headerName: 'Category',
        field: 'Category',
        width: 85,
        cellRenderer: function(params) {
            if (params.data['Category'] === 'General') {
                return '';
            } else return `<img src=${AlertRedIcon}></img>`;
        }
    },
    {
        headerName: 'Status',
        width: 70,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cellClass: () => 'textSpan'
    }
];

export const CC_COL_DEF: ColDef[] = [
    {
        headerName: 'Date',
        field: 'CreatedDate',
        width: 100,
        sort: 'desc',
        cellClass: params => {
            console.log(params);
            return checkCCLogin() && (params.data.IsRead || params.data.SenderID === accountStore.getUserID())
                ? 'normal'
                : 'highlight';
        },
        valueGetter: params => (params.data['CreatedDate'] ? dateUtils.getFormattedDateTimeForCommunication(params.data['CreatedDate']) : '')
    },
    {
        headerName: 'From',
        field: 'Sender',
        width: 75,
        cellClass: params =>
            checkCCLogin() && (params.data.IsRead || params.data.SenderID === accountStore.getUserID())
                ? 'normal'
                : 'highlight'
    },
    {
        headerName: 'Subject',
        field: 'Subject',
        width: 85,
        cellClass: params =>
            checkCCLogin() && (params.data.IsRead || params.data.SenderID === accountStore.getUserID())
                ? 'normal'
                : 'highlight',
        valueGetter: params => {
            if (params.data['Subject'] && params.data['Subject'] !== '') {
                return params.data['Subject'];
            } else {
                return '-';
            }
        }
    },
    {
        headerName: 'Category',
        field: 'Category',
        width: 85,
        cellClass: params =>
            checkCCLogin() && (params.data.IsRead || params.data.SenderID === accountStore.getUserID())
                ? 'normal'
                : 'highlight',
        cellRenderer: function(params) {
            if (params.data['Category'] === 'General') {
                return '';
            } else return `<img src=${AlertRedIcon}></img>`;
        }
    }
];

export const MESSAGE_COL_DEF: ColDef[] = [
    {
        headerName: 'Date',
        field: 'CreateDate',
        width: 105,
        sort: 'desc',
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight',
        valueGetter: params => {
            return params.data['Data'][0]['CreatedDate']
                ? dateUtils.getFormattedDateTimeForCommunication(params.data['Data'][0]['CreatedDate'])
                : '';
        }
    },
    {
        headerName: 'From',
        field: 'From',
        width: 70,
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight',
        valueGetter: params => {
            return params.data['Data'][0]['Sender'] ? params.data['Data'][0]['Sender'] : '';
        }
    },
    {
        headerName: 'To',
        field: 'To',
        width: 60,
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight',
        valueGetter: params => {
            return params.data['Data'][0]['Receiver'] ? params.data['Data'][0]['Receiver'] : '';
        }
    },
    {
        headerName: 'Latest Message',
        width: 120,
        cellRendererFramework: MessageCellRenderer,
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight',
        valueFormatter: params => {
            return params.data['Data'][0]['Message'] ? params.data['Data'][0]['Message'] : '';
        }
    },
    {
        headerName: 'Subject',
        field: 'Subject',
        width: 85,
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight',
        valueGetter: params => {
            if (params.data['Subject'] && params.data['Subject'] !== '') {
                return params.data['Subject'];
            } else {
                return '-';
            }
        }
    },
    {
        headerName: 'Mark As Read/ Unread',
        field: 'IsRead',
        width: 105,
        cellRendererFramework: CheckBoxCellRenderer,
        cellClass: params =>
            !checkCCLogin()
                ? params.data['Data'][0].IsRead ||
                  params.data['Data'][0].SenderID === accountStore.getUserID() ||
                  plainsRoles.includes(params.data['Data'][0].SenderRole)
                    ? 'normal'
                    : 'highlight'
                : params.data['Data'][0].IsRead || params.data['Data'][0].SenderID === accountStore.getUserID()
                ? 'normal'
                : 'highlight'
    }
];

// `${AlertRedIcon}<img src=${params.data['Category']} === 'General' ? '' :  ${AlertRedIcon}
// ></img>`
