import { action, observable, computed, toJS } from 'mobx';
import _, { isEmpty } from 'lodash';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { AccountStore } from 'features/account/stores/account-store';
import { SearchTicketsDataService } from '../services/search-tickets-data-service';
import { searchTicketsColDef } from '../domains/search-tickets-col-def';
import Loader from '../../../shared/decorators/loader-decorator';
import Catch from '../../../shared/decorators/catch-decorator';

import { errorHandler } from '../../../shared/handlers/error-handler';
import { ReportMessage, SearchTicketFieldName } from '../domains/enum';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { uiService, UiService } from 'shared/services/ui-service';
import { Reason } from '../reports_constants';

export class SearchTicketsDataStore {
    @observable ticketsData: any[] = [];
    @observable startDate: any = '';
    @observable endDate: any = '';
    @observable showCCModal: boolean = false;
    @observable showModal: boolean = false;
    @observable showLocModal: boolean = false;
    approvedCommentTicketModel: any;
    companyData: any = [];
    locationData: any = [];
    trailerData: any = []
    cellValueChangeMap: any = [];
    approvedColumn: string = '';
    loadStatus: any = [];
    operatorData: any =[];
    @observable showTRModal: boolean = false;
    driverData: any = [];
    @observable showDRModal: boolean = false;
    constructor(
        private searchTicketsDataService: SearchTicketsDataService, 
        public agGridService: AgGridService, 
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}
    
    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.SEARCHTICKETS_ERROR_MESSAGE))
    async searchByTicket(ticketNumber): Promise<void> {
        const requestBody = [{
            TicketNumber: ticketNumber.toString()
        }]
        const searchTickets = await this.searchTicketsDataService.getSearchTickets(requestBody[0]);
        const companyData: any  = await this.searchTicketsDataService.getCarrierCompanyData();
        const locationData: any = await this.searchTicketsDataService.getLocation();
        const trailerData: any = await this.searchTicketsDataService.getTrailerResponse();
        this.loadStatus =  await this.searchTicketsDataService.getTicketStatus();
        this.operatorData = await this.searchTicketsDataService.getOperator();
        this.driverData = await this.searchTicketsDataService.getDriverData();
        this.driverData.filter(x => {
            x.DriverName = x.FirstName+ ' ' + x.LastName
        })
        this.companyData = companyData.filter(a => !a.IsDeleted && a.RACompanyName != null);
        this.companyData.map(data => {
            data.CompanyName = data.RACompanyName;
            data.AccountNumber = data.RAAccountNumber;
        })
        this.locationData = locationData.filter(a =>  a.RA_Location != null);
        this.trailerData = trailerData;
        this.ticketsData = searchTickets
        this.ticketsData.filter(x => {
            x.ModifiedByUser = x.ModifiedBy;
        })
        if (this.ticketsData.length == 0) {
            uiService.toastService.info(ReportMessage.NO_RECORDS);
        }
        this.addValuesInCellDropdowns();
    }

    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.SEARCHTICKETS_ERROR_MESSAGE))
    async searchByDriver(firstName, lastName, dateType, startDate, endDate): Promise<void> {
        const requestBody = [{
            FirstName: firstName.trim().length === 0 ? null : firstName,
            LastName: lastName.trim().length === 0 ? null : lastName,
            DateType: dateType,
            StartDate: startDate,
            EndDate: endDate
        }]
        const searchTickets = await this.searchTicketsDataService.getSearchTickets(requestBody[0]);
        const companyData: any  = await this.searchTicketsDataService.getCarrierCompanyData();
        const locationData: any = await this.searchTicketsDataService.getLocation();
        const trailerData: any = await this.searchTicketsDataService.getTrailerResponse();
        this.loadStatus =  await this.searchTicketsDataService.getTicketStatus();
        this.operatorData = await this.searchTicketsDataService.getOperator();
        this.driverData = await this.searchTicketsDataService.getDriverData();
        this.driverData.filter(x => {
            x.DriverName = x.FirstName+ ' ' + x.LastName
        })
        this.companyData = companyData.filter(a => !a.IsDeleted && a.RACompanyName != null);
        this.companyData.map(data => {
            data.CompanyName = data.RACompanyName;
            data.AccountNumber = data.RAAccountNumber;
        })
        this.locationData = locationData.filter(a => a.RA_Location != null)
        this.ticketsData = searchTickets
        this.ticketsData.filter(x => {
            x.ModifiedByUser = x.ModifiedBy;
        })
        if (this.ticketsData.length == 0) {
            uiService.toastService.info(ReportMessage.NO_RECORDS);
        }
        this.addValuesInCellDropdowns();
    }

    getColDef() {
        return searchTicketsColDef;
    }

    @computed
    get DuplicateList(): any {
        return toJS(this.ticketsData);
    }

    @action
    setTicketsData(data: any): void {
        this.ticketsData = data;
    }

    @action
    hideCarrierPopUp() {
        this.showCCModal = false;
        this.showTRModal = false;
        this.showLocModal = false;
        this.showDRModal = false;
    }

    @action
    showCarrierPopUp() {
        this.showCCModal = true;
    }
    @action
    showLocationPopUp() {
        this.showLocModal = true;
    }
    @action
    showTrailerPopUp() {
        this.showTRModal = true;
    }

    @action
    showDriverPopUp() {
        this.showDRModal = true;
    }

    @action
    updateRow = (selectedRowData): void => {
        const updatedkeyFobIndex = this.ticketsData.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.ticketsData[updatedkeyFobIndex], selectedRowData)) {
            this.updateIDFieldBasedOnName(selectedRowData)
            this.mapEditableColumns(this.ticketsData[updatedkeyFobIndex], selectedRowData);
        }
    };

    private updateIDFieldBasedOnName(ticketData) {
        ticketData.CarrierID = this.companyData.find(a => a.CompanyName == ticketData.LeaseOperatorName)?.ID;
        ticketData.ConsignorID = this.operatorData.find(a => a.Description == ticketData.Origin)?.ID;
        ticketData.ConsigneeID = this.operatorData.find(a => a.Description == ticketData.Destination)?.ID;
        ticketData.OriginID = this.operatorData.find(a => a.Description == ticketData.Origin)?.AXID;
        ticketData.DestinationID = this.operatorData.find(a => a.Description == ticketData.Destination)?.AXID;
        ticketData.DriverID = this.driverData.find(a => a.DriverName === ticketData.DriverName)?.ID;
    }

    mapEditableColumns(currentItem, updatedItem) {
        currentItem.Origin = updatedItem.Origin;
        currentItem.LeaseOperatorName = updatedItem.LeaseOperatorName;
        currentItem.Destination = updatedItem.Destination;
        currentItem.VolumeDelivered = parseFloat(updatedItem.VolumeDelivered);
        currentItem.OriginID = updatedItem.OriginID;
        currentItem.DestinationID = updatedItem.DestinationID;
        currentItem.Status = updatedItem.Status;
        currentItem.CarrierID = updatedItem.CarrierID;
        currentItem.ConsignorID = updatedItem.ConsignorID;
        currentItem.ConsigneeID = updatedItem.ConsigneeID;
        currentItem.DispatcherComments = updatedItem.DispatcherComments;
        currentItem.DispatcherNotes = updatedItem.DispatcherNotes;
        currentItem.Comments = updatedItem.Comments;
        currentItem.ApproverComment = updatedItem.ApproverComment;
        currentItem.WaitingTime = parseInt(updatedItem.WaitingTime);
        currentItem.WorkingTime = parseInt(updatedItem.WorkingTime);
        currentItem.ActualVolume = parseFloat(updatedItem.VolumeDelivered);
        currentItem.WorkTimeReasonID = updatedItem.WorkingTimeReason;
        currentItem.WorkingTimeReason = updatedItem.WorkingTimeReason;
        currentItem.WaitingTimeReason = updatedItem.WaitingTimeReason;
        currentItem.WaitingTimeReasonID = updatedItem.WaitingTimeReason;
        currentItem.TrailerNumber = updatedItem.TrailerNumber;
        currentItem.CustomerName = updatedItem.CustomerName;
        currentItem.TicketStatus = updatedItem.TicketStatus;
        currentItem.DriverName = updatedItem.DriverName;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(Reason, SearchTicketFieldName.WORKINGTIMEREASON, 'Name');
        this.agGridService.updateOptionCellEditorValues(Reason, SearchTicketFieldName.WAITINGTIMEREASON, 'Name');
        this.agGridService.updateOptionCellEditorValues(
            this.loadStatus.filter(x => x.Name === "New" || x.Name === "In Review" || x.Name === "Hold" || x.Name === 'Not Approved' ),
            SearchTicketFieldName.STATUS,
            "Name"
        );
    }

    updateCarrierCompany(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateCarrierCompanyInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    @action
    updateCarrierCompanyInPopUp(item, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }
    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }
    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.FAILED_SUBMIT))
    async handleSave() {
        const userID = this.accountStore.getUserID();
        const updatedRowIDs = this.ticketsData.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        updatedRowIDs.map(x => {
            const config = this.loadStatus.find(config => config.Name == x.TicketStatus);
            const trailer = this.trailerData.find(item => {
                return item.TrailerNumber === x.TrailerNumber
            });
            const driver = this.driverData.find(dr =>  dr.DriverName === x.DriverName)
            x.ModifiedBy = userID;
            x.StatusID = config ? config.ID : 0;
            x.TrailerID = trailer ? trailer.ID : null;
            x.DriverID = driver ? driver.ID : 0;
            x.WorkTimeReasonID = x.WorkingTimeReason;
            x.WaitingTimeReasonID = x.WaitingTimeReason
        })
        const sendreport = await this.searchTicketsDataService.updateSearchTickets(updatedRowIDs);
        this.cellValueChangeMap = [];
        this.uiService.toastService.success(ReportMessage.SAVE);

    }
}
