import React, { Component } from 'react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { agGridService } from '../services/ag-grid-service';
import { ReportsButton } from 'features/reports/domains/enum';
import { dataStore } from 'features/common/stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import Select from 'react-select';
import { localStorageService } from 'shared/services/local-storage-service';
import { leaseOperatorStore, rejectedLoadStore, customerReportStore } from 'features/reports/stores';
/* eslint-disable */
export interface ButtonProps {
    disabled?: boolean;
    isExportCSV?: boolean;
    handleRefresh?: () => void;
}
export class ClearButtonComponent extends Component<ButtonProps> {
    render(): React.ReactNode {
        const { disabled = false, isExportCSV = false, handleRefresh } = this.props;
        return (
            <>
                {isExportCSV && (
                    <CustomButton type={CustomButtonType.Submit} onClick={this.exportCsv} disabled={disabled}>
                        {ReportsButton.CSV}
                    </CustomButton>
                )}
                <CustomButton type={CustomButtonType.Submit} onClick={this.handleClear} disabled= {false}>
                    Clear Filters
                </CustomButton>
                {handleRefresh && (<CustomButton type={CustomButtonType.Submit} onClick={handleRefresh} disabled={false}>
                    Refresh
                </CustomButton>)}
            </>
        );
    }

    handleClear() {
        agGridService.clearAllFilters();
        let module: any = dataStore.getCurrentModuleName()
        if(dataStore.getCurrentModuleName() === 'RejectedLoads') rejectedLoadStore.clearSelection = true;
        if(dataStore.getCurrentModuleName() === 'LeaseOperatorReport') leaseOperatorStore.clearSelection = true;
        if(dataStore.getCurrentModuleName() === 'CustomerReport') {
            customerReportStore.clearSelection = true;
            localStorageService.set('CustomerReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'Consignor': [], 'Consignee': []}])
        }
        if(dataStore.getCurrentModuleName() != 'LeaseOperatorReport' && dataStore.getCurrentModuleName() != 'CustomerReport') localStorageService.set(module + '_Dropdown', [])
        localStorageService.set(module + '_filter', {})
    }
    
    exportCsv() {
        const allColumns: any = agGridService.getColumns()?.getAllDisplayedColumns();
        if (
            dataStore.getCurrentModuleName() === 'DispatchPlan' ||
            dataStore.getCurrentModuleName() === 'Apportionment' ||
            dataStore.getCurrentModuleName() === 'LocationManagement' ||
            dataStore.getCurrentModuleName() === 'ScheduledReports' ||
            dataStore.getCurrentModuleName() === 'TrailerManagement' ||
            dataStore.getCurrentModuleName() === 'PrinterManagement' ||
            dataStore.getCurrentModuleName() === 'UserApproval' ||
            dataStore.getCurrentModuleName() === 'MasterTicketReport' ||
            dataStore.getCurrentModuleName() === 'DriverConfig' ||
            dataStore.getCurrentModuleName() === 'NominationConfig' ||
            dataStore.getCurrentModuleName() === 'TabletManagement' ||
            dataStore.getCurrentModuleName() === 'KeyFobManagement'
        ) {
            allColumns.map((col, index) => {
                if (
                    col.getColId().toUpperCase() === 'DELETE' ||
                    col.getColId() === 'SELECTED' ||
                    col.getColId().toUpperCase() === 'SELECT' ||
                    col.getColId() === 'IsSelected' || col.getColId() === 'IsDeleted'
                ) {
                    allColumns.splice(index, 1);
                }
            });
            agGridService
            .getNodes()
            ?.exportDataAsExcel({ fileName: dataStore.getCurrentModuleName(), columnKeys: allColumns });
        }
        else if(dataStore.getCurrentModuleName() === 'DispatchScreen') {
            const params: any = {
                processRowGroupCallback: (params) => {
                    return '';
                },
                processCellCallback: (params) => {
                    // Exclude blank rows from export
                    return params.value ? params.value : null;
                }
              };
              if (agGridService.getNodes()) {
                agGridService.getNodes()?.exportDataAsCsv({fileName: dataStore.getCurrentModuleName(), columnKeys: params});
              }
        } else {
            agGridService
            .getNodes()
            ?.exportDataAsExcel({ fileName: dataStore.getCurrentModuleName(), columnKeys: allColumns });
        }
    }
}
