import { LocationMapingFieldName, LocationMapingHeaderName } from './enum';
import { ColDef } from 'ag-grid-community';
import { dataStore } from 'features/common/stores';
import {
    CheckBoxCellRenderer,
    CheckBoxTruckedLocationCellRenderer
} from 'features/data_management/components/cell-renderers/location-map-checkboc-cell-renderer';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { locationMapingStore } from 'features/data_management/stores';
import { dateUtils } from 'shared/services/date-utils';

export const locationMapColDef: ColDef[] = [
    { headerName: LocationMapingHeaderName.ID, field: LocationMapingFieldName.ID, hide: true },
    {
        headerName: LocationMapingHeaderName.DELETE,
        field: LocationMapingFieldName.DELETE,
        width: 75,
        cellRendererFramework: CheckBoxCellRenderer
    },
    {
        headerName: LocationMapingHeaderName.LOCATIONEXT,
        field: LocationMapingFieldName.LOCATIONEXT,
        colId: LocationMapingFieldName.LOCATIONEXT,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        width: 95,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.LOCATIONEXT];
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.LOCATIONEXT]
                ? params.data[LocationMapingFieldName.LOCATIONEXT]
                : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.LOCATIONCODE,
        field: LocationMapingFieldName.SOURCELOCATIONCODE,
        colId: LocationMapingFieldName.SOURCELOCATIONCODE,
        width: 95,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.SOURCELOCATIONCODE];
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.SOURCELOCATIONCODE]
                ? params.data[LocationMapingFieldName.SOURCELOCATIONCODE]
                : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.LOCATIONLSD,
        field: LocationMapingFieldName.SOURCELOCLSD,
        colId: LocationMapingFieldName.SOURCELOCLSD,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        width: 95,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.SOURCELOCLSD];
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.SOURCELOCLSD]
                ? params.data[LocationMapingFieldName.SOURCELOCLSD]
                : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.LOCATIONAX,
        field: LocationMapingFieldName.LOCATIONAX,
        colId: LocationMapingFieldName.LOCATIONAX,
        width: 110,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.LOCATIONAX];
        }
    },
    {
        headerName: LocationMapingHeaderName.LOCATIONRA,
        field: LocationMapingFieldName.LOCATIONRA,
        colId: LocationMapingFieldName.LOCATIONRA,
        width: 110,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.LOCATIONRA];
        }
    },
    {
        headerName: LocationMapingHeaderName.DATASOURCE,
        field: LocationMapingFieldName.DATASOURCE,
        colId: LocationMapingFieldName.DATASOURCE,
        width: 80,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return params.data[LocationMapingFieldName.DATASOURCE];
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.DATASOURCE]
                ? params.data[LocationMapingFieldName.DATASOURCE]
                : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.COMMENT,
        field: LocationMapingFieldName.COMMENT,
        colId: LocationMapingFieldName.COMMENT,
        width: 100,
        cellEditorFramework: TextAreaCellRenderer,
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dataStore.checkOperationAccess('Save')
                ? 'cell-editable'
                : '' && params.data[LocationMapingFieldName.COMMENT];
        },
        cellEditorParams: {
            isDisabled: (item, value) => (dataStore.checkOperationAccess('Save') ? false : true),
            onChange: (item, value) => locationMapingStore.updateComment(item, value, LocationMapingFieldName.COMMENT)
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.COMMENT] ? params.data[LocationMapingFieldName.COMMENT] : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.TRUCKEDBYLOCATIONS,
        field: LocationMapingFieldName.TRUCKEDBYLOCATIONS,
        colId: LocationMapingFieldName.TRUCKEDBYLOCATIONS,
        width: 105,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                    params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS];
        },
        cellRendererFramework: CheckBoxTruckedLocationCellRenderer
    },
    {
        headerName: LocationMapingHeaderName.MODIFIEDBY,
        colId: LocationMapingFieldName.MODIFIEDBY,
        width: 105,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                        params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return params.data[LocationMapingFieldName.MODIFIEDBY];
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.MODIFIEDBY]
                ? params.data[LocationMapingFieldName.MODIFIEDBY]
                : '';
        }
    },
    {
        headerName: LocationMapingHeaderName.MODIFIEDDATE,
        colId: LocationMapingFieldName.MODIFIEDDATE,
        width: 120,
        cellClass: params => {
            if (
                (params.data[LocationMapingFieldName.LOCATIONRA] === null ||
                        params.data[LocationMapingFieldName.RAID] === null) &&
                params.data[LocationMapingFieldName.TRUCKEDBYLOCATIONS]
            ) {
                return 'trailer-ques-bgColor-red';
            }
            return dateUtils.getFormattedDateTime(params.data[LocationMapingFieldName.MODIFIEDDATE]);
        },
        valueGetter: params => {
            return params.data[LocationMapingFieldName.MODIFIEDDATE]
                ? dateUtils.getFormattedDateTime(params.data[LocationMapingFieldName.MODIFIEDDATE])
                : '';
        }
    }
];
