import { observable, action } from 'mobx';
import { DataService } from '../services/data-service';
import {
    AuthToken,
    StorageService,
    LoggedInAuthToken,
    User,
    BaseConfigValue,
    ResponseDataType
} from 'shared/types/shared';
import { AppConstant } from '../../../app_constant';
import { UiService, uiService } from 'shared/services/ui-service';
import Catch from 'shared/decorators/catch-decorator';
import { Subject } from 'rxjs';
import { UserService } from 'shared/services/user-service';
import { errorHandler } from 'shared/handlers/error-handler';
import Loader from 'shared/decorators/loader-decorator';
import { SignUp } from '../models/signup';
import {
    USER_LOADING_ERROR,
    EMAIL_VALIDATION_ERROR,
    PLAINS,
    DISPATCHER,
    DRIVER,
    OWNEROPERATOR,
    LEASEOPERATOR,
    STATUS_UPDATE_ERROR,
    APPROVED,
    OWNER,
    CARRIERCOMPANY,
    REJECTED
} from '../account_constant';
import { INVALID_USERID } from '../../data_management/data_constant';

export class AccountStore {
    @observable isLoggedIn = false;
    @observable userName = '';
    @observable displayName = '';
    @observable userRoles: string[] = [];

    constructor(
        private accountDataService: DataService,
        private localStorageService: StorageService,
        private uiService: UiService,
        private accountSubscripitonService: Subject<LoggedInAuthToken>,
        private userService: UserService
    ) {
        this.accountSubscripitonService.subscribe(() => {
            console.log('accountStore logout', this.accountSubscripitonService);
            this.logOut();
        });
    }

    @action
    setLoggedInStatus(isLoggedIn: boolean): void {
        this.isLoggedIn = isLoggedIn;
    }

    @action
    setUserName(userName: string): void {
        this.userName = userName;
    }

    @action
    setDisplayName(displayName: string): void {
        this.displayName = displayName;
    }

    @action
    setUserRole(roles: string[]): void {
        this.userRoles = roles;
    }

    setUserID(userId: number): void {
        this.localStorageService.set(AppConstant.USERID, userId);
    }

    getUserInfo(): any {
        return this.localStorageService.get(AppConstant.USER_INFO);
    }

    getUserID(): any {
        return this.localStorageService.get(AppConstant.USERID);
    }

    @Catch(err => console.log(err))
    async login(): Promise<void> {
        const token = await this.accountDataService.login();
        console.log('Microsoft login token', JSON.stringify(token));
        this.setAuthInfo(token, true);
        if (token && token.roles.length > 0) await this.getLoggedInUserDetails(token.userName);
    }

    getRoleForAView(roles: string[]): string | undefined {
        return this.userRoles.find(role => roles.includes(role));
    }

    isLoggedInUserHasRoleToAccessAView(roles: string[]): boolean {
        return this.userRoles.some(role => roles.includes(role));
    }

    setAuthInfo(token: AuthToken, isLoggedIn: boolean): void {
        this.setLoggedInStatus(isLoggedIn);
        this.setUserName(token.userName);
        this.setDisplayName(token.displayName);
        if (token.roles) this.setUserRole(token.roles);

    }

    logOut = (): void => {
        this.localStorageService.remove('Communication_Direct_' + this.getUserID());
        this.localStorageService.remove('Communication_BroadCast_' + this.getUserID());
        this.localStorageService.remove(AppConstant.AUTHTOKEN);
        this.localStorageService.remove(AppConstant.LOGINTIME);
        this.localStorageService.remove(AppConstant.USERID);
        this.localStorageService.remove(AppConstant.USER_INFO);
        this.localStorageService.remove('region');
        this.setAuthInfo(AppConstant.DEFAULT_USER_DETAILS, false);
        this.setUserID(0);
        this.accountDataService.logout();
    };

    @Loader
    @Catch(err => errorHandler(EMAIL_VALIDATION_ERROR))
    async getSignUpUserDetails(email: string): Promise<ResponseDataType<User>> {
        return await this.userService.getSignUpUserInfo(email);
    }

    @Loader
    @Catch(err => errorHandler(USER_LOADING_ERROR))
    async getLoggedInUserDetails(email: string): Promise<void> {
        const response: any = await this.userService.getLoginUserInfo(email);
        console.log("getLoginUserApiResponse " + JSON.stringify(response))
        const userId =
            response && response.data && response.data.Data && response.data.Data.ID ? response.data.Data.ID : 0;
        if (userId > 0) {
            const {StatusCode, PSAStatusCode, CarrierCompanyID, MappedCarrierName, PSAMappedCarrierName, UserType} = response.data.Data;
            const userInfo = { isPTS : StatusCode === APPROVED,
                isPSA : PSAStatusCode === APPROVED,
                carrierCompanyId: CarrierCompanyID,
                carrierCompany: MappedCarrierName,
                PSACarrierCompany: PSAMappedCarrierName,
                userType: UserType
            }
            this.localStorageService.set<any>(AppConstant.USER_INFO, userInfo);
        }
        this.setUserID(userId);
    }

    @Loader
    async signup(signUp: SignUp): Promise<any> {
        const psaCarrier : any = [];
        if(signUp.PSACarrier){
            psaCarrier.push({ID: signUp.PSACarrier});
        }
        signUp.CarrierCompany = [];
        signUp.FirstName = signUp.FirstName.trim();
        signUp.LastName = signUp.LastName.trim();
        signUp.IsPlains = signUp.UserType === PLAINS;
        signUp.IsDispatcher = signUp.UserRole === DISPATCHER && signUp.UserType !== PLAINS;
        signUp.IsDriver = signUp.UserRole === DRIVER || signUp.UserRole === OWNEROPERATOR;
        signUp.IsLeaseOperator = signUp.UserRole === LEASEOPERATOR || signUp.UserRole === OWNEROPERATOR;
        signUp.IsCarrierCompany = signUp.UserRole === CARRIERCOMPANY;
        signUp.IsOwnerOperator = signUp.UserRole === OWNER;
        signUp.IsThirdPartyUser = signUp.UserRole === DISPATCHER && signUp.UserType === PLAINS;
        signUp.CarrierCompany = [...signUp.CarrierCompany, { Name: signUp.CarrierName }];
        signUp.Facility = signUp.Facility.map(f => JSON.parse(f));
        signUp.PSACarrier = psaCarrier;
        await this.accountDataService.signUpUser(signUp);
    }

    @Loader
    async getUserTypes(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return await this.userService.getUserTypes();
    }

    @Loader
    async getPsaUserRoles(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return await this.userService.getPsaUserRoles();
    }

    @Loader
    async getPtsUserRoles(): Promise<ResponseDataType<BaseConfigValue[]>> {
        return await this.userService.getPtsUserRoles();
    }

    @Loader
    async getFacilities(): Promise<any> {
        const { data } = await this.userService.getFacilities();
        return data['Data'];
    }

    @Loader
    async getPSACarrier(): Promise<any> {
        const { data } = await this.userService.getPSACarrierWithDispatcher();
        return data['Data'];
    }

    @Loader
    @Catch(err => errorHandler(STATUS_UPDATE_ERROR))
    async updateUserStatusForApproval(user: any): Promise<any> {
        return await this.userService.updateUserStatusForApproval(user);
    }

    @Loader
    @Catch(err => errorHandler(USER_LOADING_ERROR))
    async getUserDetailForDispatcher(user: any): Promise<any> {
        user.ModifiedBy = this.getUserID();
        if (!user.ModifiedBy || user.ModifiedBy === 0) {
            errorHandler(INVALID_USERID);
        }
        const { data } = await this.userService.getUserApprovalInfoForDispatcher(user);
        return data['Data'];
    }

    @Loader
    @Catch(err => errorHandler(STATUS_UPDATE_ERROR))
    async updateUserStatusByDispatcher(user: any): Promise<any> {
        return await this.userService.updateUserStatusByDispatcher(user);
    }

    @Loader
    @Catch(err => errorHandler(INVALID_USERID))
    async getLoggedInUserDetailsIfUserIdZero(email: string): Promise<void> {
        const response: any = await this.userService.getLoginUserInfo(email);
        console.log("getLoginUserApiResponse " + JSON.stringify(response))
        const userId =
            response && response.data && response.data.Data && response.data.Data.ID ? response.data.Data.ID : 0;
        if (userId > 0) {
            const {StatusCode, PSAStatusCode, CarrierCompanyID, MappedCarrierName, PSAMappedCarrierName, UserType} = response.data.Data;
            const userInfo = { isPTS : StatusCode === APPROVED,
                isPSA : PSAStatusCode === APPROVED,
                carrierCompanyId: CarrierCompanyID,
                carrierCompany: MappedCarrierName,
                PSACarrierCompany: PSAMappedCarrierName,
                userType: UserType
            }
            this.localStorageService.set<any>(AppConstant.USER_INFO, userInfo);
        }
        this.setUserID(userId);
    }
}
