import { ResponseDataType } from 'shared/types/shared';
import { restApiService } from 'shared/services';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { RejectedLoadResponse, RejectedDateRange } from '../domains/model';

export class RejectedLoadDataService {
    constructor(private restApi: RestApiService) {}
    async getRejectedLoadSP(reqbody): Promise<RejectedLoadResponse[]> {
        const { data } = await this.restApi.postWithToken<RejectedLoadResponse[]>(Config.getRejectedLoadSP, reqbody);
        return data['Data'];
    }
    async getRejectedLoadSPwithParams(reqbody: RejectedDateRange): Promise<RejectedLoadResponse[]> {
        const { data } = await this.restApi.postWithToken<RejectedLoadResponse[]>(
            Config.getRejectedLoadSP,
            reqbody,
            {}
        );
        return data['Data'];
    }
}

export const rejectedLoadDataService = new RejectedLoadDataService(restApiService);
