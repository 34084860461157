import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { ticketStore } from '../stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class RadioButtonCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data.SELECT ? props.data.SELECT : false
        };
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }
    
    handleRadioButtonChange() {
        this.setState({ value: !this.state.value });
        ticketStore.selectTicketToBeDownloaded(this.props.data, this.state.value, this.props.column.getColId());
    };
      
      render() {
        const { value } = this.state;
        const rowId = this.props.rowIndex
        return ( 
            <input 
            onChange={this.handleRadioButtonChange}
            type="checkbox" 
            checked={this.state.value}
            name="pdf"/> 
         )
      }
}