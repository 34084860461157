import { RestApiService } from '../../../shared/services/rest-api-service';
import { Config } from 'config';
import { InspectionDefect } from '../model/inspectionDefect';
import { InspectionDefectRequest } from '../model/inspectionDefectRequest';
import { PrepostChecklist } from '../model/prepostchecklist';
import { InspectionDefectImages } from 'features/maintenance-portal/model/InspectionDefectImages';
import { inspectionDefectImageRequest } from 'features/maintenance-portal/model/inspectionDefectImageRequest';
export class InspectionDataService {
    constructor(private restApi: RestApiService) { }

    async getInspectionDefects(reqBody: InspectionDefectRequest): Promise<Array<InspectionDefect>> {
        try {
            const { data: inspectionDefectList } = await this.restApi.postWithToken<Array<InspectionDefect>>(
                Config.getInspectionDefects,
                reqBody
            );
            return inspectionDefectList['Data'];
        } catch (e) {
            return [];
        }
    }

    async getInspectionDefectsImages(reqBody: inspectionDefectImageRequest): Promise<Array<InspectionDefectImages>> {
        try {
            const { data: InspectionDefectImages } = await this.restApi.postWithToken<Array<InspectionDefectImages>>(
                Config.getInspectionDefectsImages,
                reqBody
            );
            return InspectionDefectImages['Data'];
        } catch (e) {
            return [];
        }
    }

    async getPrepostChecklist(): Promise<Array<PrepostChecklist>> {
        try {
            const { data: prepostCheckList } = await this.restApi.postWithToken<Array<PrepostChecklist>>(
                Config.getPrePostChecklist,
                {},
                {}
            );
            return prepostCheckList['Data'];
        } catch (e) {
            return [];
        }
    }
    async getOTESRepoort(reqBody) {
        try {
            const data =  await this.restApi.postWithToken(
                Config.GetOTESReport,
                reqBody
            );
            return data;
        } catch (e) {
            return [];
        }
    }

    async getInspectionDefectsList(reqBody: InspectionDefectRequest): Promise<Array<PrepostChecklist>> {
        try {
            let PrepostChecklist: any;
            PrepostChecklist = await this.getPrepostChecklist(); //.filter(x => x.PrePostChecklistID <= 20);
            PrepostChecklist.sort((a, b) => a.CategorySequenceNo - b.CategorySequenceNo);
            const defectlistWithVersion: any = await this.getInspectionDefects(reqBody);
            const defectlist: any = defectlistWithVersion.InspectionDefectObj; // await this.getInspectionDefects(reqBody);
            let reqData: inspectionDefectImageRequest;
            reqData = { searchbyvalue: reqBody.InspectionTicketID, SearchByVersion: reqBody.Version??0 };
            let defectImagelist = await this.getInspectionDefectsImages(reqData);
            let AdditionalImage = defectImagelist.filter(x => x.IsAdditionalImage == true);
            let AdditionalImagesList: any;
            AdditionalImagesList = AdditionalImage.map(function (AdditionalImages, index) {
                return AdditionalImages.ImageUrl;
            });
            let versionlist: any;

            for (var i = 0; i <= PrepostChecklist.length - 1; i++) {
                try {
                    var prelist = PrepostChecklist[i];
                    if (defectlist.length > 0) {
                        var defect = defectlist.filter(
                            PrepostChecklist => PrepostChecklist.PrePostCheckListID == prelist.PrePostChecklistID
                        );
                        PrepostChecklist[i].Comments = defectlist[0].Comments;
                        if (defect.length > 0) {
                            PrepostChecklist[i].IsMajor = defect[0].IsMajor;
                            if (defect[0].IsSelected) {
                                PrepostChecklist[i].IsSelectedYes = true;
                                PrepostChecklist[i].IsSelectedNo = false;
                                PrepostChecklist[i].IsSelectedNA = false;
                            } else if (defect[0].NotApplicable) {
                                PrepostChecklist[i].IsSelectedYes = false;
                                PrepostChecklist[i].IsSelectedNo = false;
                                PrepostChecklist[i].IsSelectedNA = true;
                            } else {
                                PrepostChecklist[i].IsSelectedYes = false;
                                PrepostChecklist[i].IsSelectedNo = true;
                                PrepostChecklist[i].IsSelectedNA = false; 
                            }
                        }
                        else {
                            PrepostChecklist[i].IsMajor = false;
                            PrepostChecklist[i].IsSelectedYes = false;
                            PrepostChecklist[i].IsSelectedNo = false;
                            PrepostChecklist[i].IsSelectedNA = true;
                        }
                    }
                    if (defectImagelist.length > 0) {
                        var defectImages = defectImagelist.filter(
                            PrepostChecklist => PrepostChecklist.PrePostChecklistID == prelist.PrePostChecklistID
                        );
                        if (defectImages.length > 0) {
                            PrepostChecklist[i].ImageURL = defectImages.map(function (defectImages, index) {
                                return defectImages.ImageUrl;
                            });
                        }
                    }
                    PrepostChecklist[0].AdditionalImages = AdditionalImagesList;
                    PrepostChecklist[0].version = defectlistWithVersion.Version;
                } catch (e) {
                    //console.log(e.message);
                }
            }

            window['Showpopup'] = true;
            return PrepostChecklist;
        } catch (e) {
            return [];
        }
    }
}
