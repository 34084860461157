
export enum CompanyMessage {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Company data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes'
}

export enum CompanyHeaderName {
    ID = 'ID',
    VENDORNUMBER='Vendor Number',
    VENDORNAME='Vendor Name',
    CREATEDDATE='Created Date',
    REGION = 'Region',
    RAVENDORNUMBER = 'RA Vendor Number',
    RAVENDORNAME = 'RA Vendor Name',
  
}

export enum CompanyFieldName {
    ID = 'ID',
    AccountNumber = 'AccountNumber',
    VENDORNAME = 'CompanyName',
    CREATEDDATE='CreatedDate',
    REGION = 'Name',
    RAVENDORNUMBER = 'RAAccountNumber',
    RAVENDORNAME = 'RACompanyName',
    }