import { RejectedLoadHeaderName, RejectedLoadFieldName } from './enum';
import { ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';

export const rejectedLoadColDef: ColDef[] = [
    {
        headerName: RejectedLoadHeaderName.TICKETNUMBER,
        field: RejectedLoadFieldName.TICKETNUMBER,
        width: 130,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.STATUS,
        field: RejectedLoadFieldName.STATUS,
        width: 115,
        valueGetter: params => {
            if(params.data[RejectedLoadFieldName.STATUS] === null) {
                return ''
            } else {
                return params.data[RejectedLoadFieldName.STATUS];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.TICKETDATE,
        field: RejectedLoadFieldName.TICKETDATE,
        width: 110,
        valueGetter: (value): string =>
            dateUtils.getFormattedDate(value.data[RejectedLoadFieldName.TICKETDATE])
    },
    {
        headerName: RejectedLoadHeaderName.CONSIGNEE,
        field: RejectedLoadFieldName.CONSIGNEE,
        width: 100,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.CONSIGNOR,
        field: RejectedLoadFieldName.CONSIGNOR,
        width: 100,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.DRIVERNAME,
        field: RejectedLoadFieldName.DRIVERNAME,
        width: 115,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.DRIVERNAME] === null || params.data[RejectedLoadFieldName.DRIVERNAME] === 'Dummy Test' || params.data[RejectedLoadFieldName.DRIVERNAME].trim() === 'SysUser' ) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.CARRIERNAME,
        field: RejectedLoadFieldName.CARRIERNAME,
        width: 145,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.CARRIERNAME] === null || params.data[RejectedLoadFieldName.CARRIERNAME] === 'Dummy Test') {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.CARRIERNAME];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.TRAILERNUMBER,
        field: RejectedLoadFieldName.TRAILERNUMBER,
        width: 135,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: RejectedLoadHeaderName.COMMENT,
        field: RejectedLoadFieldName.COMMENT,
        width: 100,
        valueGetter: params => {
            if (params.data[RejectedLoadFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[RejectedLoadFieldName.COMMENT];
            }
        }
    }
];
