import { Component } from 'react';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { DispatchPlanDataStore } from '../stores/dispatchPlan-store';
import {
    CellValueChangedEvent,
    FilterChangedEvent,
    CheckboxChangedEvent,
    GridOptions,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    RowClickedEvent,
    CellClickedEvent,
    RangeSelectionChangedEvent
} from 'ag-grid-community';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { DispatchPlanFieldName, DispatchPlanButtons, commaFormat } from '../constants/enums';
import { dataStore } from 'features/common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { DispatchComponentName } from 'features/dispatch/dispatch-management/constants/constant';
import { localStorageService } from 'shared/services/local-storage-service';
import _ from 'lodash';
import { AgGridComponentParams } from 'features/common/domain/model';
import { agGridService } from 'features/common/services/ag-grid-service';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { DispatchPlanPopUpComponent } from './dispatchPlan-popup-component';
import moment from 'moment';
import { AppConstant } from 'app_constant';
import { dispatchPlanDataStore } from '../stores';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { accountStore } from 'features/account/stores';
import { suppliersRoles, CCUserRoles } from '../../nomination/nomination-constants';
import Select from 'react-select';
import momenttimezone from 'moment-timezone';
import { toJS } from 'mobx';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-dayPickerDispatchPlan';
import { DispatchPlanDriverPopupComponent } from './dispatchPlan-driver-popup-component';
import { DispatchPlanTrailerPopupComponent } from './dispatchPlan-trailer-popup-component';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { DispatchPlanCarrierPopupComponent } from './dispatchPlan-carrier-popup-coomponent';
import { SelectedTicketListPopupComponent, SelectedTicketListPopupProps } from './selected-tickets-popup';
import { CarrierCommentPopup } from './carrier-comments-popup';
import { CompleteTicketPopupComponent } from './dispatchPlan-completeTicket-popup-component';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface DispatchPlanContainerProps {
    dispatchPlanDataStore: DispatchPlanDataStore;
}
interface DispatchPlanContainerState {
    startDate: any;
    selectedLoadStatus: any;
    hasNoDataInFilter: any;
    selectedRegion: any;
    selectedRegionByRole: any;
    selectedProduct: any;
    // selectedDispatchType: any;
}

@inject('dispatchPlanDataStore')
@observer
export class DispatchPlanContainer extends Component<DispatchPlanContainerProps, DispatchPlanContainerState> {
    startDate: any = moment();
    private rowID = 0;
    isSupplier: any;
    selectedProduct: any = 'All';
    filteredDispatchPlanData: any = [];
    isCCRole: any;
    isThirdParty: any;
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            selectedLoadStatus: 'All',
            hasNoDataInFilter: false,
            selectedProduct: 'All',
            selectedRegion: [],
            selectedRegionByRole: '',
            // selectedDispatchType: 'Driver'
        };
        dataStore.setModuleName('DispatchPlan');
    }

    componentDidMount() {
        this.props.dispatchPlanDataStore.init();
        this.props.dispatchPlanDataStore.getDriverTrailerData();
        this.props.dispatchPlanDataStore.getAllDriversForBulkAssign();
        if (!this.checkCCLogin())
            this.props.dispatchPlanDataStore.getAllCarrierCompanyForBulkAssign();
        // let intervalId = setInterval(this.timer, 1000 * 60 * 2);
        this.isSupplier = this.checkSupplierRole();
        if (this.isSupplier) {
            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                this.selectedProduct = 'All';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: [],
                    selectedProduct: 'All'
                });
                this.props.dispatchPlanDataStore.selectedRegion = [];
                this.props.dispatchPlanDataStore.selectedProduct = 'All'
            } else if (accountStore.userRoles.includes('ptssupplierbutane')) {
                this.selectedProduct = 'Butane';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: [],
                    selectedProduct: 'Butane'
                });
                this.props.dispatchPlanDataStore.selectedRegion = [];
                this.props.dispatchPlanDataStore.selectedProduct = 'Butane'
            } else if (accountStore.userRoles.includes('ptssupplierngl')) {
                this.selectedProduct = 'NGL';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: [],
                    selectedProduct: 'NGL'
                });
                this.props.dispatchPlanDataStore.selectedRegion = [];
                this.props.dispatchPlanDataStore.selectedProduct = 'NGL'
            } else if (accountStore.userRoles.includes('ptssuppliercrude')) {
                this.selectedProduct = 'Crude';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: [],
                    selectedProduct: 'Crude'
                });
                this.props.dispatchPlanDataStore.selectedRegion = [];
                this.props.dispatchPlanDataStore.selectedProduct = 'Crude'
            }
        }
        this.props.dispatchPlanDataStore.selectedLoadStatus = 'All';
    }

    checkSupplierRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (suppliersRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (CCUserRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    timer() {
        const updatedData = toJS(dispatchPlanDataStore.cellValueChangeMap);
        if (dispatchPlanDataStore.dataToBeDeleted.length === 0 && Object.keys(updatedData).length === 0) {
            dispatchPlanDataStore.getRefreshData(dispatchPlanDataStore.startDate, dispatchPlanDataStore.endDate);
        }
    }

    componentDidUpdate() {
        const filters: any = localStorageService.get('DispatchPlan_filter');
        if (
            filters &&
            !_.isEqual(JSON.stringify(filters), JSON.stringify(agGridService.getAllFilters())) &&
            Object.keys(filters).length > 0 &&
            this.props.dispatchPlanDataStore.dispatchPlanData.length > 0
        ) {
            agGridService.setAllFilters(filters);
        }
        if (this.props.dispatchPlanDataStore.agGridService.getDisplayedRowCount() === 0) {
            this.props.dispatchPlanDataStore.hasNoDataInGridFilter = true
        } else {
            this.props.dispatchPlanDataStore.hasNoDataInGridFilter = false
        }
    }

    componentWillUnmount() {
        this.props.dispatchPlanDataStore.reset();
    }

    handleApproverCommentVisibility = () => {
        this.props.dispatchPlanDataStore.hideCCCommentsPopUp();
    };

    handleApproverCommentSubmission = (comment: string) => {
        this.props.dispatchPlanDataStore.updatePopupComment(comment);
        let button = this.props.dispatchPlanDataStore.buttonPressed == 'RejectedByCarrier' ? 'Reject' : 'Cancell'
        this.showSelectedTickets(button);
    };

    render(): React.ReactNode {
        const {
            dispatchPlanDataStore: { isSaveDisabled, isDisabled, selectedDispatchPlanList, showModal, showDriverModal, showTrailerModal, showCarrierModal, isCommentsPopUpVisible, showCompleteTicketModal }
        } = this.props;
        const { dispatchPlanDataStore } = this.props;
        const isCancelLoadEnabled = dispatchPlanDataStore.isCancelLoadEnabled;
        const isDispatchLoadButtonEnabled = dispatchPlanDataStore.isDispatchLoadButtonEnabled;
        const isCCRoleDispatchLoadButtonEnabled = dispatchPlanDataStore.isCCRoleDispatchLoadButtonEnabled;
        const groupedStatusDropdown = [
            { value: 'All', ID: '0' },
            { value: 'PEN/REC/CBD/RBD/CBC/RBC', ID: '1' },
            { value: 'DIS/ACC/ENR/CA/CACC/RECBC', ID: '2' },
            { value: 'Loaded/Completed', ID: '3' },
            { value: 'Deleted/Cancelled', ID: '4' }
        ];
        const carrierCompanyGroupedStatusDropdown = [
            { value: 'All', ID: '0' },
            { value: 'CA', ID: '1' },
            { value: 'CACC/CBD/RBD/RECBC', ID: '2' },
            { value: 'DIS/ACC/ENR', ID: '3' },
            { value: 'Loaded/Completed', ID: '4' }
        ];
        const region = [
            { value: 'All', label: 'Select All' },
            { value: 'North East', label: 'North East' },
            { value: 'North West', label: 'North West' },
            { value: 'South East', label: 'South East' },
            { value: 'South West', label: 'South West' }
        ];
        const allproduct = [
            { value: 'All', ID: '0' },
            { value: 'NGL', ID: '1' },
            { value: 'Crude', ID: '2' },
            { value: 'Butane', ID: '3' },
            { value: 'NGL & Butane', ID: '4' },
            { value: 'Brine', ID: '5' }
        ];
        const supplierButanengl = [
            { value: 'All', ID: '0' },
            { value: 'NGL', ID: '1' },
            { value: 'Butane', ID: '3' },
            { value: 'NGL & Butane', ID: '4' }
        ];
        const product = accountStore.userRoles.includes('ptssupplierbutanengl') ? supplierButanengl : allproduct;
        this.isCCRole = this.checkCCLogin();
        this.isThirdParty = accountStore.userRoles.includes('ptsthirdpartydispatcher');
        return (
            <Container fluid>
                <><CarrierCommentPopup
                    onApproverCommentSubmission={this.handleApproverCommentSubmission}
                    onApproverCommentVisibility={this.handleApproverCommentVisibility}
                    onApproverCommentCancel={this.handleApproverCommentVisibility}
                    showPopup={isCommentsPopUpVisible} />
                    <Row>
                        <Col>
                            <div className="master-data__action">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        {dataStore.checkOperationAccess('Save') && (
                                            <>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleSave}
                                                    disabled={isSaveDisabled() || this.props.dispatchPlanDataStore.hasNoDataInFilter}
                                                >
                                                    {DispatchPlanButtons.SAVE}
                                                </CustomButton>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleReset}
                                                    disabled={isSaveDisabled() || this.props.dispatchPlanDataStore.hasNoDataInFilter}
                                                >
                                                    {DispatchPlanButtons.RESET}
                                                </CustomButton>
                                                {isDispatchLoadButtonEnabled && !this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleDispatch}
                                                        disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                    >
                                                        {DispatchPlanButtons.DISPATCHLOAD}
                                                    </CustomButton>
                                                )}
                                                {!isDispatchLoadButtonEnabled && !this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleDispatch}
                                                        disabled={!isDispatchLoadButtonEnabled}
                                                    >
                                                        {DispatchPlanButtons.DISPATCHLOAD}
                                                    </CustomButton>
                                                )}
                                                {this.isCCRole && !isCCRoleDispatchLoadButtonEnabled && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleDispatch}
                                                        disabled={!isCCRoleDispatchLoadButtonEnabled}
                                                    >
                                                        {DispatchPlanButtons.DISPATCHLOAD}
                                                    </CustomButton>
                                                )}
                                                {this.isCCRole && isCCRoleDispatchLoadButtonEnabled && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleDispatch}
                                                        disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                    >
                                                        {DispatchPlanButtons.DISPATCHLOAD}
                                                    </CustomButton>
                                                )}
                                                {dataStore.checkOperationAccess('Cancel') && !this.isThirdParty &&
                                                    (isCancelLoadEnabled ? (
                                                        <CustomButton
                                                            type={CustomButtonType.Submit}
                                                            onClick={this.handleCancel}
                                                            disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                        >
                                                            {DispatchPlanButtons.CANCELLOAD}
                                                        </CustomButton>
                                                    ) :
                                                        <CustomButton
                                                            type={CustomButtonType.Submit}
                                                            onClick={this.handleCancel}
                                                            disabled={!isCancelLoadEnabled}
                                                        >
                                                            {DispatchPlanButtons.CANCELLOAD}
                                                        </CustomButton>
                                                    )}
                                                {dispatchPlanDataStore.isRecallButtonEnabled &&  !this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleRecall}
                                                        disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                    >
                                                        {DispatchPlanButtons.RECALL}
                                                    </CustomButton>
                                                )}
                                                {!dispatchPlanDataStore.isRecallButtonEnabled && !this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleRecall}
                                                        disabled={!dispatchPlanDataStore.isRecallButtonEnabled}
                                                    >
                                                        {DispatchPlanButtons.RECALL}
                                                    </CustomButton>
                                                )}
                                                {dispatchPlanDataStore.isCCRecallButtonEnabled && this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleRecall}
                                                        disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                    >
                                                        {DispatchPlanButtons.RECALL}
                                                    </CustomButton>
                                                )}
                                                {!dispatchPlanDataStore.isCCRecallButtonEnabled && this.isCCRole && !this.isThirdParty && (
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.handleRecall}
                                                        disabled={!dispatchPlanDataStore.isCCRecallButtonEnabled}
                                                    >
                                                        {DispatchPlanButtons.RECALL}
                                                    </CustomButton>
                                                )} </>)}
                                        {dispatchPlanDataStore.isRejectOrAcceptButtonEnabled && this.isCCRole && !this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleAcceptLoad}
                                            disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                        >
                                            {DispatchPlanButtons.ACCEPT}
                                        </CustomButton>)}

                                        {!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled && this.isCCRole && !this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleAcceptLoad}
                                            disabled={!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled}
                                        >
                                            {DispatchPlanButtons.ACCEPT}
                                        </CustomButton>)}

                                        { this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleAcceptLoad}
                                            disabled={!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled ? !dispatchPlanDataStore.isRejectOrAcceptButtonEnabled : this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                        >
                                            {DispatchPlanButtons.ACCEPT}
                                        </CustomButton>)}
                                        {dispatchPlanDataStore.isRejectOrAcceptButtonEnabled && this.isCCRole && !this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleRejectLoad}
                                            disabled={this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                        >
                                            {DispatchPlanButtons.REJECT}
                                        </CustomButton>)}
                                        {!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled && this.isCCRole && !this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleRejectLoad}
                                            disabled={!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled ? !dispatchPlanDataStore.isRejectOrAcceptButtonEnabled : this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                        >
                                            {DispatchPlanButtons.REJECT}
                                        </CustomButton>)}
                                        { this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleRejectLoad}
                                            disabled={!dispatchPlanDataStore.isRejectOrAcceptButtonEnabled ? !dispatchPlanDataStore.isRejectOrAcceptButtonEnabled : this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                        >
                                            {DispatchPlanButtons.REJECT}
                                        </CustomButton>)}
                                        { this.isThirdParty && (<CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleCompleteLoad}
                                            disabled={!dispatchPlanDataStore.isCompleteButtonEnabled }
                                        >
                                            COMPLETE LOAD
                                        </CustomButton>)}
                                        <ClearButtonComponent handleRefresh={this.handleRefresh} disabled={isDisabled()} isExportCSV={true}></ClearButtonComponent>
                                        {dataStore.checkOperationAccess('Save')  &&  (
                                            <>
                                                <CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleSelectAll}
                                                    disabled={this.state.selectedLoadStatus === "All" || this.props.dispatchPlanDataStore.hasNoDataInFilter}
                                                >
                                                    {this.props.dispatchPlanDataStore.selectButtonText}
                                                </CustomButton>
                                                { !this.isThirdParty && (<CustomButton
                                                    type={CustomButtonType.Submit}
                                                    onClick={this.handleBlukAssign}
                                                    disabled={!(this.state.selectedLoadStatus === 'PEN/REC/CBD/RBD/CBC/RBC' || this.state.selectedLoadStatus === 'CACC/CBD/RBD/RECBC') || this.state.selectedLoadStatus === "All" || this.props.dispatchPlanDataStore.hasNoDataInGridFilter || this.props.dispatchPlanDataStore.dataToBeDeleted.length === 0}
                                                >
                                                    {DispatchPlanButtons.BULKASSIGN}
                                                </CustomButton>)} </>)}
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <div className="form-inline padding-bottom" style={{ width: '70%' }}>
                                    <Col>
                                        <div className="form-inline" style={{ width: '100%' }}>
                                            <ReactDayPickerInput
                                                _dataStore={this.props.dispatchPlanDataStore}
                                                isDisabled={false}
                                            ></ReactDayPickerInput>
                                            {!this.isCCRole && (<div className="form-inline">
                                                Select Dispatch Type:{' '}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <input
                                                        type="radio"
                                                        id="driver"
                                                        name="date"
                                                        value="select"
                                                        onChange={() => this.handleDispatchType('Driver')}
                                                        checked={dispatchPlanDataStore.selectedDispatchType === 'Driver'}
                                                    ></input>
                                                    <label style={{fontSize: '14px'}} htmlFor="driver">Driver</label>
                                                    <input
                                                        type="radio"
                                                        id="carrier"
                                                        name="date"
                                                        value="month"
                                                        onChange={() => this.handleDispatchType('Carrier')}
                                                        checked={dispatchPlanDataStore.selectedDispatchType === 'Carrier'}
                                                    ></input>
                                                    <label style={{fontSize: '14px'}} htmlFor="carrier">Carrier Company</label>
                                                </div>
                                            </div>)}
                                        </div>
                                        <div className="form-inline" style={{ width: '100%', padding: '10px 0' }}>
                                            <div className="form-inline">
                                                Load Status
                                                <select
                                                    name="groupedStatusDropdown"
                                                    value={this.state.selectedLoadStatus}
                                                    className="react-select-style"
                                                    onChange={this.handleLoadStatusChange}
                                                    disabled={this.props.dispatchPlanDataStore.selectButtonText === 'Deselect All'}
                                                >
                                                    {!this.isCCRole ? groupedStatusDropdown.map(status => (
                                                        <option key={status.ID} value={status.value}>
                                                            {status.value}
                                                        </option>
                                                    )) : carrierCompanyGroupedStatusDropdown.map(status => (
                                                        <option key={status.ID} value={status.value}>
                                                            {status.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-inline" style={{ paddingLeft: 10 }}>
                                                Product
                                                <select
                                                    name="product"
                                                    value={this.state.selectedProduct}
                                                    className="react-select-style"
                                                    onChange={this.handleProductChange}
                                                    disabled={this.props.dispatchPlanDataStore.selectButtonText === 'Deselect All'}
                                                >
                                                    {product.map(product => (
                                                        <option key={product.ID} value={product.value}>
                                                            {product.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-inline" style={{ paddingLeft: 10 }} >
                                                Region
                                                <div className="reportmanagement__type">
                                                    <Select
                                                        name="region"
                                                        value={this.state.selectedRegion}
                                                        disabled={this.props.dispatchPlanDataStore.selectButtonText === 'Deselect All'}
                                                        multiple={true}
                                                        closeMenuOnSelect={false}
                                                        closeMenuOnScroll={true}
                                                        placeholder="All"
                                                        options={region}
                                                        isMulti={true}
                                                        onChange={selected => {
                                                            selected && selected.length &&
                                                                selected.find(option => option.value === "All")
                                                                ? this.handleRegionChange(region.slice(1))
                                                                : selected && selected.length ? this.handleRegionChange(selected) : this.handleRegionChange((selected && selected.value) || null);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-inline padding-bottom" style={{ width: '30%' }}>
                                    {/* <Col> */}
                                        <div style={{ fontSize: 10,display: 'flex'}}>
                                        <Col>
                                            <div > Note: </div>
                                            
                                            {!this.isCCRole && (<div> PEN - Pending Volumes </div>)}
                                            <div> CBD - Cancelled By Driver </div>
                                            <div> RBD - Rejected By Driver </div>
                                            <div> DIS - Dispatched to the Driver </div>
                                            <div> ACC - Accepted by the Driver </div>
                                            <div> ENR - Enroute Status </div>
                                            </Col>
                                            <Col style={{ alignContent: "flex-right" }}>
                                            {!this.isCCRole && (<div> REC - Recalled Volumes </div>)}
                                            <div> CA - Assigned to the Carrier </div>
                                            <div> CACC - Accepted by the Carrier </div>
                                            <div> CBC - Cancelled By Carrier </div>
                                            <div> RBC - Rejected By Carrier </div>
                                            <div> RECBC - Recalled Volumes By Carrier </div>
                                            </Col>
                                            {/* {!this.isCCRole && (<div> PEN - Pending Volumes </div>)}
                                            {!this.isCCRole && (<div> REC - Recalled Volumes </div>)}
                                            <div> CBD - Cancelled By Driver </div>
                                            <div> RBD - Rejected By Driver </div>
                                            <div> DIS - Dispatched to the Driver </div>
                                            <div> ACC - Accepted by the Driver </div>
                                            <div> ENR - Enroute Status </div>
                                            <div> CA - Assigned to the Carrier </div>
                                            <div> CACC - Accepted by the Carrier </div>
                                            <div> CBC - Cancelled By Carrier </div>
                                            <div> RBC - Rejected By Carrier </div>
                                            <div> RECBC - Recalled Volumes By Carrier </div> */}
                                        </div>
                                    {/* </Col> */}
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <DispatchPlanPopUpComponent
                                        startDate={this.props.dispatchPlanDataStore.plannedDate}
                                        onHide={this.handlePopUpClose}
                                        onSelectionChange={this.handleDateChange}
                                        show={showModal}
                                    ></DispatchPlanPopUpComponent>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DispatchPlanDriverPopupComponent
                                        drivers={this.props.dispatchPlanDataStore.activeDriverValues}
                                        selectedDriverId={1}
                                        onHide={this.handleDriverPopUpClose}
                                        onSelectionChange={this.handleDriverChange}
                                        show={showDriverModal}
                                    ></DispatchPlanDriverPopupComponent>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DispatchPlanCarrierPopupComponent
                                        carriers={this.props.dispatchPlanDataStore.activeCarrierValues}
                                        selectedCarrierId={1}
                                        onHide={this.handleCarrierPopUpClose}
                                        onSelectionChange={this.handleCarrierChange}
                                        show={showCarrierModal}
                                    ></DispatchPlanCarrierPopupComponent>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DispatchPlanTrailerPopupComponent
                                        trailers={this.props.dispatchPlanDataStore.activeTrailerValues}
                                        selectedTrailerId={1}
                                        onHide={this.handleTrailerPopUpClose}
                                        onSelectionChange={this.handleTrailerChange}
                                        show={showTrailerModal}
                                    ></DispatchPlanTrailerPopupComponent>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.props.dispatchPlanDataStore.showCompleteTicketModal && <CompleteTicketPopupComponent
                                       onHide={this.handleCompleteTicketPopupClose}
                                       data={this.props.dispatchPlanDataStore.dataToBeDeleted}
                                       products={this.props.dispatchPlanDataStore.productArray}
                                       units={this.props.dispatchPlanDataStore.unitsArray}
                                       onSave={this.handleCompleteTicketPopupSave}
                                    >
                                    </CompleteTicketPopupComponent> }
                                </Col>
                            </Row>
                            {this.props.dispatchPlanDataStore.showLoader == true &&
                                <div className='alert-overlay'>
                                    <div style={{ margin: 'auto' }} className='dots-1'></div> </div>}
                            <AgGridComponent gridConfig={this.getGridConfig()} />
                            <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Estimated Volume Sum : {commaFormat(this.props.dispatchPlanDataStore.EstVolumeSum)}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row></>
            </Container>
        );
    }


    private handleDriverChange = (driverID: any, buttonName: string) => {
        this.props.dispatchPlanDataStore.handleDriverChange(driverID, buttonName);
        this.props.dispatchPlanDataStore.agGridService.refreshGrid();
    };

    private handleCarrierChange = (carrierID: any, buttonName: string) => {
        this.props.dispatchPlanDataStore.handleCarrierChange(carrierID, buttonName);
        this.props.dispatchPlanDataStore.agGridService.refreshGrid();
    };


    private handleDateChange = (comment: string) => {
        this.props.dispatchPlanDataStore.updatePlannedDateTime(moment(comment).format('YYYY-MM-DDTHH:mm:ss'));
        this.props.dispatchPlanDataStore.agGridService.refreshGrid();
    };

    private handleTrailerChange = (comment: string) => {
        this.props.dispatchPlanDataStore.updateTrailer(comment);
        this.props.dispatchPlanDataStore.agGridService.refreshGrid();
    };

    private handleDriverPopUpClose = () => {
        this.props.dispatchPlanDataStore.hideDriverApproverPopUp();
    };

    private handleCarrierPopUpClose = () => {
        this.props.dispatchPlanDataStore.hideCarrierApproverPopUp();
    };

    private handlePopUpClose = () => {
        this.props.dispatchPlanDataStore.hideTicketApproverPopUp();
    };

    private handleDispatchType = (dispatchType) => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.isDriverOrCarreerSelected(dispatchType);
        // this.setState({
        //     selectedDispatchType: dispatchType
        // }); 
        dispatchPlanDataStore.agGridService.refreshGrid();
    }

    private handleTrailerPopUpClose = () => {
        this.props.dispatchPlanDataStore.hideTrailerApproverPopUp();
    };



    private handleCompleteTicketPopupClose = () => {
        this.props.dispatchPlanDataStore.hideCompleteTicketPopUp();
    }

    private handleCompleteTicketPopupSave = (data) => {
        this.props.dispatchPlanDataStore.saveCompleteTicket(data)
    }

    handleLoadStatusChange = event => {
        this.props.dispatchPlanDataStore.dispatchPlanData = this.props.dispatchPlanDataStore.modifiedDispatchPlanData;
        this.setState({
            selectedLoadStatus: event.currentTarget.value
        });
        this.props.dispatchPlanDataStore.selectedLoadStatus = event.currentTarget.value
        this.props.dispatchPlanDataStore.checkBoxSelectionCount = 0
        this.props.dispatchPlanDataStore.dataToBeDeleted.length = 0
        this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, event.currentTarget.value, this.state.selectedProduct);
    };

    handleProductChange = event => {
        this.props.dispatchPlanDataStore.dispatchPlanData = this.props.dispatchPlanDataStore.modifiedDispatchPlanData;
        this.setState({
            selectedProduct: event.currentTarget.value
        });
        this.props.dispatchPlanDataStore.selectedProduct = event.currentTarget.value;
        this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, event.currentTarget.value);
    };
    handleRegionChange = event => {
        const selectedRegion = event;
        this.props.dispatchPlanDataStore.dispatchPlanData = this.props.dispatchPlanDataStore.modifiedDispatchPlanData;
        this.setState({
            selectedRegion: selectedRegion
        });
        this.props.dispatchPlanDataStore.selectedRegion = selectedRegion;
        this.props.dispatchPlanDataStore.filterDataBasedOnSelection(event, this.state.selectedLoadStatus, this.state.selectedProduct);
    };

    handleSave = async () => {
        const { dispatchPlanDataStore } = this.props;
        let selectedOption: any = dispatchPlanDataStore.selectedDispatchType;
        dispatchPlanDataStore.buttonPressed = 'SaveData';
        await dispatchPlanDataStore.saveDispatchPlanData().then(() => {
            this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
        });
        dispatchPlanDataStore.selectedDispatchType = selectedOption;
    };

    showSelectedTickets = async (buttonPressed) => {
        const buttonName = buttonPressed == 'DispatchLoad' ? 'Dispatching' : buttonPressed + 'ing';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
            async () => {
                await dispatchPlanDataStore.updateDispatchPlanData().then(() => {
                    this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
                });
            },
            SelectedTicketListPopupComponent,
            {
                buttonName: buttonName,
                dataSource: toJS(dispatchPlanDataStore.dataToBeDeleted),
                dispatchPlanDataStore: dispatchPlanDataStore
            } as SelectedTicketListPopupProps,
            'tickets-popup'
        );
    }

    handleRecall = async () => {
        const { dispatchPlanDataStore } = this.props;
        const isCCRole = this.checkCCLogin();
        dispatchPlanDataStore.buttonPressed = isCCRole ? 'RecalledByCarrier' : 'Recall';
        this.showSelectedTickets('Recall');
        // await dispatchPlanDataStore.updateDispatchPlanData().then(() => {
        //     this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
        // });
    };

    handleAcceptLoad = async () => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.buttonPressed = 'Carrier_Accepted';
        this.showSelectedTickets('Accept');
        // await dispatchPlanDataStore.updateDispatchPlanData().then(() => {
        //     this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
        // });
    };

    handleRejectLoad = async () => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.buttonPressed = 'RejectedByCarrier';
        dispatchPlanDataStore.showCCCommentsPopUp();
        // await dispatchPlanDataStore.updateDispatchPlanData().then(() => {
        //     this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
        // });
    };

    handleDispatch = async () => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.buttonPressed = 'DispatchLoad';
        this.showSelectedTickets(dispatchPlanDataStore.buttonPressed);
    };

    handleCancel = async () => {
        const { dispatchPlanDataStore } = this.props;
        const isCCRole = this.checkCCLogin();
        dispatchPlanDataStore.buttonPressed = isCCRole ? 'CancelledByCarrier' : 'Cancel';
        if (isCCRole == true) {
            dispatchPlanDataStore.showCCCommentsPopUp();
        } else {
            this.showSelectedTickets('Cancell');
        }
        // await dispatchPlanDataStore.updateDispatchPlanData().then(() => {
        //     this.props.dispatchPlanDataStore.filterDataBasedOnSelection(this.state.selectedRegion, this.state.selectedLoadStatus, this.state.selectedProduct);
        // });
    };

    handleReset = () => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.cellValueChangeMap = {};
        dispatchPlanDataStore.dataToBeDeleted = [];
        dispatchPlanDataStore.reset();
    };

    handleRefresh = () => {
        const { dispatchPlanDataStore } = this.props;
        dispatchPlanDataStore.isRefreshed = true;
        if (Object.keys(dispatchPlanDataStore.cellValueChangeMap).length === 0) {
            dispatchPlanDataStore.handleRefresh(dispatchPlanDataStore.startDate, dispatchPlanDataStore.endDate)
            dispatchPlanDataStore.cellValueChangeMap = {}
            dispatchPlanDataStore.dataToBeDeleted = [];
        }
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    dispatchPlanDataStore.handleRefresh(dispatchPlanDataStore.startDate, dispatchPlanDataStore.endDate)
                    dispatchPlanDataStore.cellValueChangeMap = {}
                    dispatchPlanDataStore.dataToBeDeleted = [];
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
        this.props.dispatchPlanDataStore.selectButtonText = 'Select All';
    }

    handleBlukAssign = () => {
        const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.dispatchPlanDataStore.bulkAssignData = data
        this.props.dispatchPlanDataStore.handleBulkAssign();
    }

    handleSelectAll = () => {
        this.props.dispatchPlanDataStore.hasLoadFilterChanged = true
        const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.dispatchPlanDataStore.handleSelectAllData(this.props.dispatchPlanDataStore.dispatchPlanData, data);
        this.props.dispatchPlanDataStore.agGridService.refreshGrid();
    };

    handleCompleteLoad = () => {
        this.props.dispatchPlanDataStore.showCompleteTicketPopUp();
    }

    exportexcel = () => {
        const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        const fields = [
            'ID',
            'Product',
            'OriginID',
            'CreatedBy',
            'DestinationID',
            'NominationMonth',
            'NominationYear',
            'DriverID',
            'DispatchedDate',
            'CreatedBy',
            'CreatedDate',
            'Dispatched',
            'RegionID',
            'IsDeletedDispatchedLoad'
        ];
        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                if (fields.indexOf(key) !== -1) delete data[i][key];
            }
            data[i]['ModifiedDate'] =
                data[i]['ModifiedDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['ModifiedDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
            data[i]['PlannedDateTime'] =
                data[i]['PlannedDateTime'] === null
                    ? ''
                    : moment(new Date(data[i]['PlannedDateTime'])).format(AppConstant.DATE_TIME_FORMAT);
        }
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
    };

    handleClear = () => {
        localStorageService.set('DispatchPlan_filter', {});
        agGridService.clearAllFilters();
    };

    private getGridConfig(): GridOptions {
        const {
            dispatchPlanDataStore: { DispatchPlanData, agGridService }
        } = this.props;
        const { dispatchPlanDataStore } = this.props;
        const columnDefs = dispatchPlanDataStore.getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellClicked = this.onCellclicked;
        const onFilterChanged = this.handleFilterChange;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;

        const rowData = DispatchPlanData;
        const dispatchPlanGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked,
            onFilterChanged,
            onRangeSelectionChanged
        };
        return agGridService.getGridConfig(dispatchPlanGridParams);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            dispatchPlanDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            this.props.dispatchPlanDataStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            dispatchPlanDataStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    onCellclicked = (event: CellClickedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                dispatchPlanDataStore: { cellValueChangeMap, updateRow }
            } = this.props;

            const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
            if (event.column.getColId() !== 'SELECTED' && event.column.getColId() != DispatchPlanFieldName.PLANNEDDATEANDTIME && event.column.getColId() != DispatchPlanFieldName.DRIVERNAME && event.column.getColId() != DispatchPlanFieldName.TRAILERNUMBER) {
                csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                    this.props.dispatchPlanDataStore.selectedDispatchPlanRowData(x.data, true, "SELECTED");
                    x.setDataValue('SELECTED', false);
                });
            }

            if (event.column.getColId() === DispatchPlanFieldName.PLANNEDDATEANDTIME) {
                dispatchPlanDataStore.plannedDate = event.data.PlannedDateTime
                if (!this.checkCCLogin() && (event.data.LoadStatus === 'Pending' || event.data.LoadStatus === 'Rejected By Driver' || event.data.LoadStatus === 'Recalled' || event.data.LoadStatus === 'Cancelled By Driver' || event.data.LoadStatus === 'Driver Accepted' || event.data.LoadStatus === 'Enroute' || event.data.LoadStatus === 'Driver Dispatched' || event.data.LoadStatus === 'Dispatch Pending' || event.data.LoadStatus === 'Rejected By Carrier' || event.data.LoadStatus === 'Cancelled By Carrier' || event.data.LoadStatus === 'Recalled By Carrier' || event.data.LoadStatus === 'Carrier Assigned' || event.data.LoadStatus === 'Carrier Accepted')) {
                    dispatchPlanDataStore.showTicketApproverPopUp()
                    dispatchPlanDataStore.approvedCommentTicketModel = event.data
                    dispatchPlanDataStore.approvedColumn = DispatchPlanFieldName.PLANNEDDATEANDTIME
                } else {
                    csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                        this.props.dispatchPlanDataStore.selectedDispatchPlanRowData(x.data, true, "SELECTED");
                        x.setDataValue('SELECTED', false);

                    });
                }
            }
            if (event.column.getColId() === DispatchPlanFieldName.DRIVERNAME) {
                if (!this.checkCCLogin() && dispatchPlanDataStore.selectedDispatchType === 'Driver' && (event.data.LoadStatus === 'Pending' || event.data.LoadStatus === 'Rejected By Driver' || event.data.LoadStatus === 'Recalled' || event.data.LoadStatus === 'Cancelled By Driver' || event.data.LoadStatus === 'Rejected By Carrier' || event.data.LoadStatus === 'Cancelled By Carrier' || event.data.LoadStatus === 'Recalled By Carrier')) {
                    dispatchPlanDataStore.getDriverDataByPlannedDateTime(event.data.PlannedDateTime, event.data)
                }
                if (this.checkCCLogin() && !this.isThirdParty && event.data.CarrierName && (event.data.LoadStatus === 'Carrier Accepted' || event.data.LoadStatus === 'Rejected By Driver' || event.data.LoadStatus === 'Cancelled By Driver' || event.data.LoadStatus === 'Recalled By Carrier')) {
                    dispatchPlanDataStore.getDriverDataByPlannedDateTime(event.data.PlannedDateTime, event.data)
                } else {
                    csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                        this.props.dispatchPlanDataStore.selectedDispatchPlanRowData(x.data, true, "SELECTED");
                        x.setDataValue('SELECTED', false);
                    });
                }
            }
            if (event.column.getColId() === DispatchPlanFieldName.CARRIERNAME && !this.checkCCLogin()) {
                if (dispatchPlanDataStore.selectedDispatchType === 'Carrier' && (event.data.LoadStatus === 'Pending' || event.data.LoadStatus === 'Rejected By Driver' || event.data.LoadStatus === 'Recalled' || event.data.LoadStatus === 'Cancelled By Driver' || event.data.LoadStatus === 'Rejected By Carrier' || event.data.LoadStatus === 'Cancelled By Carrier' || event.data.LoadStatus === 'Recalled By Carrier')) {
                    dispatchPlanDataStore.getCarrierDataByPlannedDateTime(event.data.PlannedDateTime, event.data)
                }
            }
            if (event.column.getColId() === DispatchPlanFieldName.TRAILERNUMBER && !this.checkCCLogin()) {
                if (event.data.LoadStatus === 'Pending' || event.data.LoadStatus === 'Rejected By Driver' || event.data.LoadStatus === 'Recalled' || event.data.LoadStatus === 'Cancelled By Driver' || event.data.LoadStatus === 'Cancelled By Carrier' || event.data.LoadStatus === 'Rejected By Carrier' || event.data.LoadStatus === 'Recalled By Carrier') {
                    dispatchPlanDataStore.showTrailerApproverPopUp()
                    dispatchPlanDataStore.approvedCommentTicketModel = event.data
                    dispatchPlanDataStore.approvedColumn = DispatchPlanFieldName.TRAILERNUMBER
                } else {
                    csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                        this.props.dispatchPlanDataStore.selectedDispatchPlanRowData(x.data, true, "SELECTED");
                        x.setDataValue('SELECTED', false);
                    });
                }
            }
            csvData?.rowModel.gridApi.refreshCells();
        } catch (err) {
            console.log(err);
        }
    };

    handleFilterChange = (event: FilterChangedEvent): void => {
        if (this.props.dispatchPlanDataStore.dispatchPlanData.length > 0)
            localStorageService.set('DispatchPlan_filter', agGridService.getAllFilters());
        if (this.props.dispatchPlanDataStore.agGridService.getDisplayedRowCount() === 0) {
            this.props.dispatchPlanDataStore.hasNoDataInGridFilter = true
        } else {
            this.props.dispatchPlanDataStore.hasNoDataInGridFilter = false
        }
        const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        this.props.dispatchPlanDataStore.getVolumeSum(data);
    };

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.dispatchPlanDataStore.agGridService.getNodes();
        const checkArray: any = [];

        if (e.started === false && e.finished === true) {
            var cellRanges: any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                if (index >= startRowIndex && index <= endRowIndex) {
                    // console.log(x.data);                    
                    this.props.dispatchPlanDataStore.selectedDispatchPlanRowData(x.data, false, "SELECTED");
                    x.setDataValue('SELECTED', true);
                    checkArray.push('true');
                } else {
                    checkArray.push(x.data['SELECTED'] ? 'true' : 'false');
                }
            });

            csvData?.rowModel.gridApi.refreshCells();

            if (checkArray.indexOf('false') < 0) {
                this.props.dispatchPlanDataStore.selectButtonText = 'Deselect All';
            } else {
                this.props.dispatchPlanDataStore.selectButtonText = 'Select All';
            }
        }
    }
}
