import { commaFormat, DispatchPlanFieldName, DispatchPlanHeaderName } from '../constants/enums';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';
import { locationStore } from 'features/data_management/stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { DispatchPlanRadioCellRenderer } from './dispatchPlan-radio-cell-renderer'
import { DispatchComponentName } from 'features/dispatch/dispatch-management/constants/constant';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { DispatchPlanDataStore } from '../stores/dispatchPlan-store';
import { dispatchPlanDataStore } from '../stores';
import { accountStore } from 'features/account/stores';
import { CCUserRoles } from 'features/dispatch/nomination/nomination-constants';
interface DispatchPlanContainerProps {
    dispatchPlanDataStore: DispatchPlanDataStore;
}
const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};
const checkCCLogin = () => {
    let access = false;
    const currentUserRoles = accountStore.userRoles;
    currentUserRoles.forEach(userRole => {
        if (!access) {
            if (CCUserRoles.includes(userRole)) {
                access = true;
            }
        }
    });
    return access;
};
const getColorCodeForQuesAcheck = (recivedValue: any) => {
    let cellColor = 'default'
    let receivedTime = dateUtils.getFormattedTimeHHmm(recivedValue);
    let recievedDate = dateUtils.getFormattedDateOnly(recivedValue);
    const currentDate = moment(new Date().toISOString()).format('MM/DD/YYYY');
    const currentTime = dateUtils.getFormattedTimeHHmm(new Date());
    let rMonth = recievedDate.substring(0, 2);
    let rYear = recievedDate.substring(6, 10);
    let cMonth = currentDate.substring(0, 2);
    let cYear = currentDate.substring(6, 10);
    let cDate = currentDate.substring(3, 5);
    let rDate = recievedDate.substring(3, 5);
    if (cYear === rYear) {
        if (cMonth === rMonth && cDate === rDate) cellColor = 'yellow';
        if (cMonth === rMonth && cDate > rDate) cellColor = 'red';
        if (cMonth === rMonth && cDate === rDate && receivedTime < currentTime) cellColor = 'red';
        if (cMonth > rMonth) cellColor = 'red';
        if (cMonth < rMonth) cellColor = 'default';
    } else if (cYear < rYear) cellColor = 'default';
    else if (cYear > rYear) cellColor = 'red';
    else cellColor = 'default';
    return cellColor;
};

const status = ['Loaded', 'Driver Dispatched', 'Enroute', 'Driver Accepted', 'Recalled Pending'];
export const dispatchPlanColDef: ColDef[] = [
    { headerName: DispatchPlanHeaderName.ID, field: DispatchPlanFieldName.ID, hide: true },
    {
        headerName: DispatchPlanHeaderName.SELECT,
        field: 'SELECTED',
        colId: 'SELECTED',
        width: 65,
        cellRendererFramework: DispatchPlanRadioCellRenderer,
        cellClass: params => {
            const recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue';
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.PLANNEDDATEANDTIME,
        field: DispatchPlanFieldName.PLANNEDDATEANDTIME,
        colId: DispatchPlanFieldName.PLANNEDDATEANDTIME,
        width: 120,
        sort: 'asc',
        editable: params => {
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            } else if (recivedValue != null && recivedValue != '' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Rejected By Driver' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Cancelled By Driver'||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Accepted' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Dispatched' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Dispatch Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Enroute' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Cancelled By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Rejected By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted' ) {
                let cellBgColor = getColorCodeForQuesAcheck(recivedValue)
                if (cellBgColor == 'red') {
                    return 'trailer-ques-bgColor-red'
                }
                if (cellBgColor == 'yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME])
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHTYPE,
        field: DispatchPlanFieldName.DISPATCHTYPE,
        width: 80,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHTYPE] === null || ((params.data[DispatchPlanFieldName.DISPATCHTYPE] === 'Carrier' && !params.data[DispatchPlanFieldName.CARRIERNAME]) || (params.data[DispatchPlanFieldName.DISPATCHTYPE] === 'Driver' && !params.data[DispatchPlanFieldName.DRIVERNAME]))) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHTYPE];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.DISPATCHTYPE];
        }
    },
    {
        headerName: DispatchPlanHeaderName.LOADNUMBER,
        field: DispatchPlanFieldName.LOADNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.LOADNUMBER] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LOADNUMBER];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.LOADSTATUS,
        field: DispatchPlanFieldName.LOADSTATUS,
        width: 75,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.LOADSTATUS] === null) {
                return '';
            } else if (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Assigned'){
                return 'Dispatched';
            } else {
                return params.data[DispatchPlanFieldName.LOADSTATUS];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.ORIGIN,
        field: DispatchPlanFieldName.ORIGIN,
        colId: DispatchPlanFieldName.ORIGIN,
        width: 70,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.ORIGIN];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DESTINATION,
        field: DispatchPlanFieldName.DESTINATION,
        colId: DispatchPlanFieldName.DESTINATION,
        width: 100,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DESTINATION];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DRIVERNAME,
        field: DispatchPlanFieldName.DRIVERNAME,
        colId: DispatchPlanFieldName.DRIVERNAME,
        width: 100,
        editable: params => {
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                dispatchPlanDataStore.selectedDispatchType !== 'Carrier'&&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                dispatchPlanDataStore.selectedDispatchType !== 'Carrier' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned'&&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.DRIVERNAME] === null ||
                params.data[DispatchPlanFieldName.DRIVERNAME] === 'Dummy Test' ||
                (checkCCLogin() &&
                    params.data.CarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.LASTDRIVERNAME,
        field: DispatchPlanFieldName.LASTDRIVERNAME,
        colId: DispatchPlanFieldName.LASTDRIVERNAME,
        width: 100,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.LASTDRIVERNAME] === null ||
                params.data[DispatchPlanFieldName.LASTDRIVERNAME] === 'Dummy Test' ||
                (checkCCLogin() &&
                    params.data.LastCarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LASTDRIVERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CARRIERNAME,
        field: DispatchPlanFieldName.CARRIERNAME,
        colId: DispatchPlanFieldName.CARRIERNAME,
        width: 100,
        editable: params => {
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned'&&
                dispatchPlanDataStore.selectedDispatchType !== 'Driver' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned'&&
                dispatchPlanDataStore.selectedDispatchType !== 'Driver' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.CARRIERNAME] === null ||
                params.data[DispatchPlanFieldName.CARRIERNAME] === 'Dummy Test' ||
                (checkCCLogin() &&
                    params.data.CarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CARRIERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.LASTCARRIERNAME,
        field: DispatchPlanFieldName.LASTCARRIERNAME,
        colId: DispatchPlanFieldName.LASTCARRIERNAME,
        width: 100,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.LASTCARRIERNAME] === null ||
                params.data[DispatchPlanFieldName.LASTCARRIERNAME] === 'Dummy Test' ||
                (checkCCLogin() &&
                    params.data.LastCarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LASTCARRIERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.PRIORITY,
        field: DispatchPlanFieldName.PRIORITY,
        colId: DispatchPlanFieldName.PRIORITY,
        width: 70,
        cellEditor: 'agSelectCellEditor',
        editable: params => {
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.PRIORITY] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.PRIORITY];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.ESTIMATEDVOLUME,
        field: DispatchPlanFieldName.ESTIMATEDVOLUME,
        colId: DispatchPlanFieldName.ESTIMATEDVOLUME,
        width: 85,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.ESTIMATEDVOLUME] === null) {
                return '';
            } else {
                return roundOff(params.data[DispatchPlanFieldName.ESTIMATEDVOLUME]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return ['trailer-ques-bgColor-blue','decimal-format']
            }
            return 'decimal-format'
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHERCOMMENTS,
        field: DispatchPlanFieldName.DISPATCHERCOMMENTS,
        colId: DispatchPlanFieldName.DISPATCHERCOMMENTS,
        width: 90,
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                dispatchPlanDataStore.updateDispatcherComments(item, value, 'DispatcherComments')
        },
        editable: params => {
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHERCOMMENTS];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHERNOTES,
        field: DispatchPlanFieldName.DISPATCHERNOTES,
        colId: DispatchPlanFieldName.DISPATCHERNOTES,
        width: 90,
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                dispatchPlanDataStore.updateDispatcherNotes(item, value, 'DispatcherNotes')
        },
        hide : (dataStore.checkOperationAccess('DispatcherNotes') ? true : false),
        editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        // cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),

        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADSTATUS] &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHERNOTES] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHERNOTES];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CARRIERCOMMENTS,
        field: DispatchPlanFieldName.CARRIERCOMMENTS,
        colId: DispatchPlanFieldName.CARRIERCOMMENTS,
        width: 90,
        cellEditorFramework: TextAreaCellRenderer,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.CARRIERCOMMENTS]
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.CARRIERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CARRIERCOMMENTS];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DRIVERCOMMENTS,
        field: DispatchPlanFieldName.DRIVERCOMMENTS,
        colId: DispatchPlanFieldName.DRIVERCOMMENTS,
        width: 90,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DRIVERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DRIVERCOMMENTS];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.TRAILERNUMBER,
        field: DispatchPlanFieldName.TRAILERNUMBER,
        colId: DispatchPlanFieldName.TRAILERNUMBER,
        width: 75,
        editable: params => {
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Accepted' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.ACHECKDATE,
        field: DispatchPlanFieldName.ACHECKDATE,
        colId: DispatchPlanFieldName.ACHECKDATE,
        width: 75,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.ACHECKDATE] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.ACHECKDATE];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.ACHECKFLAG];
            if (recivedValue == 2) {
                return 'trailer-ques-bgColor-red'
            } else if (recivedValue == 1) {
                return 'trailer-ques-bgColor-yellow'
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.BOOKINGDATE,
        field: DispatchPlanFieldName.BOOKINGDATE,
        colId: DispatchPlanFieldName.BOOKINGDATE,
        width: 75,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.BOOKINGDATE] === null) {
                return '';
            } else {
                return moment(params.data[DispatchPlanFieldName.BOOKINGDATE]).format(AppConstant.DATE_FORMAT);
            }
        },
        cellClass: params => {
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CUSTOMERNAME,
        field: DispatchPlanFieldName.CUSTOMERNAME,
        colId: DispatchPlanFieldName.CUSTOMERNAME,
        width: 85,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.CUSTOMERNAME] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CUSTOMERNAME];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DATASOURCE,
        field: DispatchPlanFieldName.DATASOURCE,
        width: 70,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DATASOURCE] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DATASOURCE];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.DATASOURCE];
        }
    },
    {
        headerName: DispatchPlanHeaderName.MODIFIEDBY,
        field: DispatchPlanFieldName.MODIFIEDBY,
        colId: DispatchPlanFieldName.MODIFIEDBY,
        width: 95,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.MODIFIEDBY];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.MODIFIEDBY];
        }
    },
    {
        headerName: DispatchPlanHeaderName.MODIFIEDDATE,
        field: DispatchPlanFieldName.MODIFIEDDATE,
        colId: DispatchPlanFieldName.MODIFIEDDATE,
        width: 110,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.MODIFIEDDATE];
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[DispatchPlanFieldName.MODIFIEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
];



export const dispatchPlanCCColDef: ColDef[] = [
    { headerName: DispatchPlanHeaderName.ID, field: DispatchPlanFieldName.ID, hide: true },
    {
        headerName: DispatchPlanHeaderName.SELECT,
        field: 'SELECTED',
        colId: 'SELECTED',
        width: 65,
        cellRendererFramework: DispatchPlanRadioCellRenderer,
        cellClass: params => {
            const recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue';
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.PLANNEDDATEANDTIME,
        field: DispatchPlanFieldName.PLANNEDDATEANDTIME,
        colId: DispatchPlanFieldName.PLANNEDDATEANDTIME,
        width: 120,
        sort: 'asc',
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            } else if (recivedValue != null && recivedValue != '' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Rejected By Driver' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Cancelled By Driver'||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Accepted' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Dispatched' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Dispatch Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Enroute' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled Pending' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Cancelled By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Recalled By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Rejected By Carrier' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted' ) {
                let cellBgColor = getColorCodeForQuesAcheck(recivedValue)
                if (cellBgColor == 'red') {
                    return 'trailer-ques-bgColor-red'
                }
                if (cellBgColor == 'yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return '';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME])
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHTYPE,
        field: DispatchPlanFieldName.DISPATCHTYPE,
        width: 80,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHTYPE] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHTYPE];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.DISPATCHTYPE];
        }
    },
    {
        headerName: DispatchPlanHeaderName.LOADNUMBER,
        field: DispatchPlanFieldName.LOADNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.LOADNUMBER] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LOADNUMBER];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.LOADSTATUS,
        field: DispatchPlanFieldName.LOADSTATUS,
        width: 75,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.LOADSTATUS] === null) {
                return '';
            } else if (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Assigned'){
                return 'Dispatched';
            } else {
                return params.data[DispatchPlanFieldName.LOADSTATUS];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.ORIGIN,
        field: DispatchPlanFieldName.ORIGIN,
        colId: DispatchPlanFieldName.ORIGIN,
        width: 70,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.ORIGIN];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DESTINATION,
        field: DispatchPlanFieldName.DESTINATION,
        colId: DispatchPlanFieldName.DESTINATION,
        width: 100,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DESTINATION];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DRIVERNAME,
        field: DispatchPlanFieldName.DRIVERNAME,
        colId: DispatchPlanFieldName.DRIVERNAME,
        width: 100,
        editable: params => {
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                !accountStore.userRoles.includes('ptsthirdpartydispatcher') &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return  params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Accepted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Enroute' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Loaded' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Driver Dispatched' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Dispatch Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' && 
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Carrier Assigned' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'cell-editable'
                : '';
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.DRIVERNAME] === null ||
                params.data[DispatchPlanFieldName.DRIVERNAME] === 'Dummy Test' ||
                (params.data.CarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.LASTDRIVERNAME,
        field: DispatchPlanFieldName.LASTDRIVERNAME,
        colId: DispatchPlanFieldName.LASTDRIVERNAME,
        width: 100,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.LASTDRIVERNAME] === 'Dummy Test' || 
                (params.data.LastCarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                    params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LASTDRIVERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CARRIERNAME,
        field: DispatchPlanFieldName.CARRIERNAME,
        colId: DispatchPlanFieldName.CARRIERNAME,
        width: 100,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.CARRIERNAME];
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.CARRIERNAME] === null ||
                params.data[DispatchPlanFieldName.CARRIERNAME] === 'Dummy Test' ||
                (params.data.CarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                    (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                        params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CARRIERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.LASTCARRIERNAME,
        field: DispatchPlanFieldName.LASTCARRIERNAME,
        colId: DispatchPlanFieldName.LASTCARRIERNAME,
        width: 100,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        },
        valueGetter: params => {
            if (
                params.data[DispatchPlanFieldName.LASTCARRIERNAME] === 'Dummy Test' ||
                (params.data.LastCarrierID != accountStore.getUserInfo()?.carrierCompanyId &&
                (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
                    params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted'))
            ) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.LASTCARRIERNAME];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.PRIORITY,
        field: DispatchPlanFieldName.PRIORITY,
        colId: DispatchPlanFieldName.PRIORITY,
        width: 70,
        cellEditor: 'agSelectCellEditor',
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.PRIORITY];
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.PRIORITY] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.PRIORITY];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.ESTIMATEDVOLUME,
        field: DispatchPlanFieldName.ESTIMATEDVOLUME,
        colId: DispatchPlanFieldName.ESTIMATEDVOLUME,
        width: 85,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.ESTIMATEDVOLUME] === null) {
                return '';
            } else {
                return roundOff(params.data[DispatchPlanFieldName.ESTIMATEDVOLUME]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return ['trailer-ques-bgColor-blue','decimal-format']
            }
            return 'decimal-format'
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHERCOMMENTS,
        field: DispatchPlanFieldName.DISPATCHERCOMMENTS,
        colId: DispatchPlanFieldName.DISPATCHERCOMMENTS,
        width: 90,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHERCOMMENTS];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DISPATCHERNOTES,
        field: DispatchPlanFieldName.DISPATCHERNOTES,
        colId: DispatchPlanFieldName.DISPATCHERNOTES,
        width: 90,
        hide : (dataStore.checkOperationAccess('DispatcherNotes') ? true : false),
        // editable: () => (dataStore.checkOperationAccess('Save') ? true : false),
        // cellClass: () => (dataStore.checkOperationAccess('Save') ? 'cell-editable' : ''),

        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.DISPATCHERNOTES];
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DISPATCHERNOTES] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DISPATCHERNOTES];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CARRIERCOMMENTS,
        field: DispatchPlanFieldName.CARRIERCOMMENTS,
        colId: DispatchPlanFieldName.CARRIERCOMMENTS,
        width: 90,
        cellEditorFramework: TextAreaCellRenderer,
        cellEditorParams: {
            onChange: (item, value) =>
                dispatchPlanDataStore.updateCarrierComments(item, value, DispatchPlanFieldName.CARRIERCOMMENTS)
        },
        editable: params => {
            return (params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Assigned' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Carrier Accepted' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Dispatched' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Driver Accepted' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Enroute' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Loaded' ||
            params.data[DispatchPlanFieldName.LOADSTATUS] === 'Dispatch Pending') &&
                dataStore.checkOperationAccess('Save')
                ? true
                : false;
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Manually Deleted ' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Apportionment Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Deleted' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Cancelled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'Recalled Pending' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Completed' &&
                params.data[DispatchPlanFieldName.LOADSTATUS] !== 'System Cancelled' &&
                dataStore.checkOperationAccessWithModule('Save', DispatchComponentName.DispatchPlan)
                ? 'ticket-container-text-box cell-editable'
                : 'ticket-container-text-box';
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.CARRIERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CARRIERCOMMENTS];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.DRIVERCOMMENTS,
        field: DispatchPlanFieldName.DRIVERCOMMENTS,
        colId: DispatchPlanFieldName.DRIVERCOMMENTS,
        width: 90,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DRIVERCOMMENTS] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DRIVERCOMMENTS];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            } 
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.TRAILERNUMBER,
        field: DispatchPlanFieldName.TRAILERNUMBER,
        colId: DispatchPlanFieldName.TRAILERNUMBER,
        width: 75,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.TRAILERNUMBER];
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.TRAILERNUMBER];
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.BOOKINGDATE,
        field: DispatchPlanFieldName.BOOKINGDATE,
        colId: DispatchPlanFieldName.BOOKINGDATE,
        width: 75,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.BOOKINGDATE] === null) {
                return '';
            } else {
                return moment(params.data[DispatchPlanFieldName.BOOKINGDATE]).format(AppConstant.DATE_FORMAT);
            }
        },
        cellClass: params => {
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
        }
    },
    {
        headerName: DispatchPlanHeaderName.CUSTOMERNAME,
        field: DispatchPlanFieldName.CUSTOMERNAME,
        colId: DispatchPlanFieldName.CUSTOMERNAME,
        width: 85,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.CUSTOMERNAME] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.CUSTOMERNAME];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.LOADNUMBER];
        }
    },
    {
        headerName: DispatchPlanHeaderName.DATASOURCE,
        field: DispatchPlanFieldName.DATASOURCE,
        width: 70,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.DATASOURCE] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.DATASOURCE];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.DATASOURCE];
        }
    },
    {
        headerName: DispatchPlanHeaderName.MODIFIEDBY,
        field: DispatchPlanFieldName.MODIFIEDBY,
        colId: DispatchPlanFieldName.MODIFIEDBY,
        width: 95,
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[DispatchPlanFieldName.MODIFIEDBY];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.MODIFIEDBY];
        }
    },
    {
        headerName: DispatchPlanHeaderName.MODIFIEDDATE,
        field: DispatchPlanFieldName.MODIFIEDDATE,
        colId: DispatchPlanFieldName.MODIFIEDDATE,
        width: 110,
        cellClass: params => {
            let recivedValue = params.data[DispatchPlanFieldName.PLANNEDDATEANDTIME];
            if (params.data[DispatchPlanFieldName.ISDELETEDDISPATCHEDLOAD] === true && status.includes(params.data[DispatchPlanFieldName.LOADSTATUS])) {
                return 'trailer-ques-bgColor-blue'
            }
            return params.data[DispatchPlanFieldName.MODIFIEDDATE];
        },
        valueGetter: params => {
            if (params.data[DispatchPlanFieldName.MODIFIEDDATE] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data[DispatchPlanFieldName.MODIFIEDDATE]), AppConstant.MST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
];

