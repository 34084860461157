import React from 'react';
import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { userApprovalStore } from '../../stores';
import { UserApprovalFieldName } from '../../domains/enum';
import { accountStore } from 'features/account/stores';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { dataStore } from '../../../common/stores/index';
import { DriversOpenTicketListPopupComponent, DriversOpenTicketListPopupProps } from '../drivers-open-ticket-list-component';


const handleShopAssignment = async (isPSA: boolean, userData: any) => {
    const userInfo = userApprovalStore.userApprovalInfo.find(x => x.ID === userData.ID);
    userApprovalStore.currentSelectedUser = userInfo;
    const status = isPSA ? userData.PSAStatusCode : userData.StatusCode;
    if (status === "APPROVED") {
        userApprovalStore.isPSA = isPSA;
        const alertMessage = userData.IsDriver ? 'Verifying if any tickets are assigned to the driver and this will take few mins' : 'Are you sure you want to unselect this user? All mappings will be removed?';
        if (userData.IsDriver) {
            const confirmService = new ConfirmService();
            if(!isPSA) {
                confirmService.showMessageAlert(alertMessage);
                await userApprovalStore.getOpenTicketsForDriver(userData);
            }
            if (!userApprovalStore.shouldShowOpenTicketPopup || isPSA) {
                const message = 'Are you sure you want to unselect this user? All mappings will be removed?';
                confirmService.showConfirmDialog(
                    () => userApprovalStore.disapproveUser(userData),
                    '',
                    message
                );
            } else {
                confirmService.showConfirmWithCustomComponent(
                    () => {},
                    DriversOpenTicketListPopupComponent,
                    { 
                        dataSource: userApprovalStore.openTicketsData,
                        userApprovalDataStore: userApprovalStore
                    } as DriversOpenTicketListPopupProps,
                    'tickets-popup'
                );  
            }
           
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => userApprovalStore.disapproveUser(userData),
                '',
                alertMessage
            );
        }
        //const alertMessage = userData.IsDriver ? 'Please verify all the ticket assigned to the Driver. Are you sure you want to unselect this user? All mappings will be removed?' : 'Are you sure you want to unselect this user? All mappings will be removed?'
    } else {


        // if (isPSA && userData.IsPlains) {
        //     userApprovalStore.isPSA = isPSA;
        //     const confirmService = new ConfirmService();
        //     confirmService.showConfirmDialog(
        //         () => userApprovalStore.updateUser(userData),
        //         '',
        //         'Are you sure you want to approve this user for all PSA facilities?'
        //     );
        // }
        // else {
            // userApprovalStore.setShowModal(isPSA);
        // }
        userApprovalStore.setShowModal(isPSA);
    }
};

export const CurrentStatusCellRenderer = (props: ICellRendererParams) => {
    const userRoles = accountStore.userRoles;
    const { value } = props;
    let disabled = false;
    let checked = false;
    if (props.colDef.field === UserApprovalFieldName.PlainsSchedulingApp) {
        const canEdit = dataStore.checkOperationAccess('PSAApprove');
        if (!disabled) {
            disabled = !canEdit;
        }
        checked = props.data.PSAStatusCode === 'APPROVED';
    } else if (props.colDef.field === UserApprovalFieldName.PlainsTruckingSystem){
        const canEdit = dataStore.checkOperationAccess('PTSApprove');
        if (!disabled) {
            disabled = !canEdit;
        }
        checked = props.data.StatusCode === 'APPROVED';
    } else if (props.colDef.field === UserApprovalFieldName.ADStatus) {
            disabled = true;
            checked = props.data.ADStatus
    }

    if (props.colDef.field === UserApprovalFieldName.PlainsSchedulingApp) {
        if (checked) {
            const canEdit = dataStore.checkOperationAccess('PSADisapprove');
            if (!disabled) {
                disabled = !canEdit;
            }
        }
    } else if (props.colDef.field === UserApprovalFieldName.PlainsTruckingSystem){
        const canEdit = dataStore.checkOperationAccess('PTSDisapprove');
        if (checked) {
            if (!disabled) {
                disabled = !canEdit;
            } 
       }
    } else if (props.colDef.field === UserApprovalFieldName.ADStatus) {
        disabled = true;
        // checked = props.data.ADStatus
    }

    return (
        <div className="current-status">
            <input
                type="checkbox"
                id="chk"
                name="chk"
                onClick={(e) => handleShopAssignment(props.colDef.field === UserApprovalFieldName.PlainsSchedulingApp, props.data)}
                disabled={disabled}
                checked={checked}
                value={value}
            />
        </div>
    );
};
