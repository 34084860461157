import { TicketModel, TicketResponseModel, TicketRequestModel } from '../model/ticket-response-model';
import { ConfigResponseModel, ConfigModel } from 'shared/types/shared';
import { TicketStatus as TicketStatusEnum } from '../constants/ticket-enum';

export class TicketDataAdapterService {
    transformTicketResponseToTicketModel(ticketResponseModelList: TicketResponseModel[]): TicketModel[] {
        return ticketResponseModelList.map((ticketResponseModel: TicketResponseModel) => {
            const {
                ID,
                Product,
                ShipperTicketBOL,
                RoadBan,
                Via,
                Destination,
                Trailer,
                LastPMTStatus,
                UpdatedBy,
                Comments,
                WorkingTime,
                TicketStatus,
                TicketNumber,
                TicketDate,
                Origin,
                LeaseOperatorName,
                DriverName,
                RelatedVolumeTicket,
                TicketType,
                ApproverComment,
                WorkingTimeReason,
                WaitingTime,
                WaitingTimeReason,
                UpdatedDate,
                VolumeDelivered,
                TrailerStatus,
                TrailerMappedToCompany,
                Axles,
                TrailerMileage,
                UomID,
                ConsignorName,
                ConsignorAddress,
                Consignor24Phone,
                Consignor24Phone2,
                PowerUnitIdentifier,
                StartDateTime,
                EndDate,
                EstimatedVolume,
                ConsignorERPPhoneNumber,
                ConsignorERPPlanNumber,
                EmptyResidueLastContained,
                HazardID,
                InquiryID,
                RunTickeScaleTicketNumber,
                LoadTemperature,
                LoadDateTime,
                UnLoadTemperature,
                GrossWeight,
                TareWeight,
                NetWeight,
                Density,
                BSW,
                SplitLoad,
                OpenMeterReading,
                CloseMeterReading,
                MeterNumber,
                MeterFactor,
                TankNumber,
                ObsGravity,
                ObsTemp,
                TankSize,
                TankHeight,
                ConsignorLSD,
                ConsigneeLSD,
                GrossVolume,
                Consignee,
                ConsigneeField,
                Consignor,
                ConsignorField,
                TractorMappedToCompany,
                CompletedDateTime,
                Region,
                TicketSource
            } = ticketResponseModel;
            return {
                id: ID,
                product: Product,
                shipperTicket: ShipperTicketBOL,
                roadBan: RoadBan,
                via: Via,
                destination: Destination,
                workingTime: WorkingTime,
                ticketDate: TicketDate,
                trailer: Trailer,
                lastPmtStatus: LastPMTStatus,
                updatedBy: UpdatedBy,
                comment: Comments,
                status: TicketStatus,
                ticketNumber: TicketNumber,
                origin: Origin,
                leaseOperator: LeaseOperatorName,
                driverName: DriverName,
                relatedVolumeTicket: RelatedVolumeTicket,
                type: TicketType,
                approverComment: ApproverComment,
                isApproved: TicketStatus == TicketStatusEnum.APPROVED,
                workingTimeReason: WorkingTimeReason,
                waitingTime: WaitingTime,
                waitingTimeReason: WaitingTimeReason,
                updatedDate: UpdatedDate,
                volumeDelivered: VolumeDelivered,
                trailerStatus: TrailerStatus,
                trailerMappedToCompany: TrailerMappedToCompany,
                axles: Axles,
                trailerMileage: TrailerMileage,
                uomID: UomID,
                consignorName: ConsignorName,
                consignorAddress: ConsignorAddress,
                consignor24Phone: Consignor24Phone,
                consignor24Phone2: Consignor24Phone2,
                powerUnitIdentifier: PowerUnitIdentifier,
                StartDateTime: StartDateTime,
                endDate: EndDate,
                estimatedVolume: EstimatedVolume,
                consignorERPPhoneNumber: ConsignorERPPhoneNumber,
                consignorERPPlanNumber: ConsignorERPPlanNumber,
                emptyResidueLastContained: EmptyResidueLastContained,
                hazardID: HazardID,
                inquiryID: InquiryID,
                runTickeScaleTicketNumber: RunTickeScaleTicketNumber,
                loadTemperature: LoadTemperature,
                loadDateTime: LoadDateTime,
                unLoadTemperature: UnLoadTemperature,
                grossWeight: GrossWeight,
                tareWeight: TareWeight,
                netWeight: NetWeight,
                density: Density,
                BSW: BSW,
                splitLoad: SplitLoad,
                openMeterReading: OpenMeterReading,
                closeMeterReading: CloseMeterReading,
                meterNumber: MeterNumber,
                meterFactor: MeterFactor,
                tankNumber: TankNumber,
                obsGravity: ObsGravity,
                obsTemp: ObsTemp,
                tankSize: TankSize,
                tankHeight: TankHeight,
                consignorLSD: ConsignorLSD,
                consigneeLSD: ConsigneeLSD,
                grossVolume: GrossVolume,
                consignee: Consignee,
                consigneeField: ConsigneeField,
                consignor: Consignor,
                consignorField: ConsignorField,
                tractorMappedToCompany: TractorMappedToCompany,
                completedDateTime:CompletedDateTime,
                Region: Region,
                TicketSource: TicketSource
            };
        });
    }

    getTicketKey(ticket: TicketModel) {
        const { id, ticketNumber, type } = ticket;
        return `${id}_${ticketNumber}_${type}`;
    }

    transformConfigResponseModelToConfigModel(configResponseList: ConfigResponseModel[]): ConfigModel[] {
        return configResponseList.map((configResponseItem: ConfigResponseModel) => {
            const { Name, ID, Code } = configResponseItem;
            return {
                id: ID,
                name: Name,
                code: Code
            };
        });
    }

    getUpdatedRecords(updatedModelList: TicketModel[], backUpModelList: Map<string, TicketModel>) {
        const model: TicketModel[] = [];
        updatedModelList.forEach(item => {
            const ticketKey = this.getTicketKey(item);
            const backUpTicket = backUpModelList.get(ticketKey);
            if (this.checkIfDataHasChanged(item, backUpTicket)) {
                model.push(item);
            }
        });
        return model;
    }

    getRequestModelList(modelList: TicketModel[], configModel: ConfigModel[], userId: number): TicketRequestModel[] {
        return modelList.map(model => {
            const config = configModel.find(config => config.name == model.status);
            return {
                TicketType: model.type,
                ID: model.id,
                ApproverComment: model.approverComment,
                WaitingTime: model.waitingTime,
                UserID: userId,
                StatusID: config ? config.id : 0,
                WorkingTime: model.workingTime,
                WorkTimeReasonID: model.workingTimeReason,
                WaitingTimeReasonID: model.waitingTimeReason,
                Comments: model.comment
            };
        });
    }

    private checkIfDataHasChanged(updatedModel: TicketModel, backUpModel: TicketModel | undefined) {
        if (backUpModel) {
            return (
                backUpModel.status !== updatedModel.status ||
                backUpModel.approverComment !== updatedModel.approverComment ||
                backUpModel.workingTime != updatedModel.workingTime ||
                backUpModel.waitingTime != updatedModel.waitingTime ||
                backUpModel.comment != updatedModel.comment ||
                backUpModel.waitingTimeReason != updatedModel.waitingTimeReason ||
                backUpModel.workingTimeReason != updatedModel.workingTimeReason
            );
        }
        return false;
    }
}

export const ticketDataAdapterService = new TicketDataAdapterService();
