import { TrailerHeaderName, TrailerFieldName } from './enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { NumericCellRenderer } from '../components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from '../components/cell-renderers/alpha-numeric-cell-renderer';
import { dataStore } from 'features/common/stores';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import moment from 'moment';
import '../../../features/data_management/domains/_trailer-ques_col.scss'
import { SelectionRadioButtonCellRenderer } from '../components/cell-renderers/trailer-selection-cell-renderer';
import { accountStore } from 'features/account/stores';
import { CapacityCellRenderer } from '../components/cell-renderers/capacity-cell-renderer';
import { OTESTriggerRenderer } from '../components/cell-renderers/otes-trigger-cell-renderer';

const checkUserRole = () => {
    let access = false;
    const currentUserRoles = accountStore.userRoles;
    if (currentUserRoles.includes('ptsmaintenance') || currentUserRoles.includes('ptstruckingmanager') || currentUserRoles.includes('ptssysadmin')) {
        access = true;
    }
    return access;
}
const isFloat = (n) => {
    return (n - Math.floor(n)) !== 0
}
const status = {
    headerName: TrailerHeaderName.STATUS,
    field: TrailerFieldName.STATUS,
    cellEditor: 'agSelectCellEditor',
    colId: TrailerFieldName.STATUS,
    width: 65,
    editable: () => (dataStore.checkOperationAccess('StatusEdit') ? true : false),
    cellClass: () => (dataStore.checkOperationAccess('StatusEdit') ? 'cell-editable' : ''),
    valueGetter: params => {
        if (params.data[TrailerFieldName.STATUS] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.STATUS];
        }
    }
};

const waterCapacityLead = {
    headerName: TrailerHeaderName.WATERCAPACITYLEAD,
    field: TrailerFieldName.WATERCAPACITYLEAD,
    cellRendererFramework: CapacityCellRenderer,
    colID: TrailerFieldName.WATERCAPACITYLEAD,
    width: 115,
    cellRendererParams: {
        isDisabled: (item, value) => (checkUserRole() ? false : true)
    },
    cellClass:  (params) => {
        if(isFloat(params.data[TrailerFieldName.WATERCAPACITYLEAD])) {
            if(checkUserRole()) return ['cell-editable', 'comma-format']
            else return 'comma-format'
        } else {
            if(checkUserRole()) return ['cell-editable', 'work-wait']
            else return 'work-wait'
        }
    },
    valueGetter: params => {
        if(params.data[TrailerFieldName.WATERCAPACITYLEAD] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.WATERCAPACITYLEAD];
        }
    }
}

const waterCapacityPup = {
    headerName: TrailerHeaderName.WATERCAPACITYPUP,
    field: TrailerFieldName.WATERCAPACITYPUP,
    cellRendererFramework: CapacityCellRenderer,
    colID: TrailerFieldName.WATERCAPACITYPUP,
    width: 115,
    cellRendererParams: {
        isDisabled: (item, value) => (checkUserRole() ? false : true)
    },
    cellClass:  (params) => {
        if(isFloat(params.data[TrailerFieldName.WATERCAPACITYPUP])) {
            if(checkUserRole()) return ['cell-editable', 'comma-format']
            else return 'comma-format'
        } else {
            if(checkUserRole()) return ['cell-editable', 'work-wait']
            else return 'work-wait'
        }
    },
    valueGetter: params => {
        if(params.data[TrailerFieldName.WATERCAPACITYPUP] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.WATERCAPACITYPUP];
        }
    }
}

const driplessAdapterSide = {
    headerName: TrailerHeaderName.DRIPLESSADAPTERSIDE,
    field: TrailerFieldName.DRIPLESSADAPTERSIDE,
    cellEditor: 'agSelectCellEditor',
    colID: TrailerFieldName.DRIPLESSADAPTERSIDE,
    width: 105,
    editable: () => (checkUserRole() ? true : false),
    cellClass:  () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.DRIPLESSADAPTERSIDE] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.DRIPLESSADAPTERSIDE];
        }
    }
}

const opticalPlug = {
    headerName: TrailerHeaderName.OPTICALPLUG,
    field: TrailerFieldName.OPTICALPLUG,
    cellEditor: 'agSelectCellEditor',
    colID: TrailerFieldName.OPTICALPLUG,
    width: 70,
    editable: () => (checkUserRole() ? true : false),
    cellClass:  () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.OPTICALPLUG] === null) {
            return '';
        } else if(params.data[TrailerFieldName.OPTICALPLUG] === true || params.data[TrailerFieldName.OPTICALPLUG] === 'Yes') {
            return 'Yes'
        } else if(params.data[TrailerFieldName.OPTICALPLUG] === false || params.data[TrailerFieldName.OPTICALPLUG] === 'No') {
            return 'No'
        }
    }
}

const skully = {
    headerName: TrailerHeaderName.SKULLY,
    field: TrailerFieldName.SKULLY,
    cellEditor: 'agSelectCellEditor',
    colID: TrailerFieldName.SKULLY,
    width: 65,
    editable: () => (checkUserRole() ? true : false),
    cellClass:  () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.SKULLY] === null) {
            return '';
        } else if(params.data[TrailerFieldName.SKULLY] === true || params.data[TrailerFieldName.SKULLY] === 'Yes') {
            return 'Yes'
        } else if(params.data[TrailerFieldName.SKULLY] === false || params.data[TrailerFieldName.SKULLY] === 'No') {
            return 'No'
        }
    }
}

const scrubber = {
    headerName: TrailerHeaderName.SCRUBBER,
    field: TrailerFieldName.SCRUBBER,
    cellEditor: 'agSelectCellEditor',
    colID: TrailerFieldName.SCRUBBER,
    width: 80,
    editable: () => (checkUserRole() ? true : false),
    cellClass:  () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.SCRUBBER] === null) {
            return '';
        } else if(params.data[TrailerFieldName.SCRUBBER] === true || params.data[TrailerFieldName.SCRUBBER] === 'Yes') {
            return 'Yes'
        } else if(params.data[TrailerFieldName.SCRUBBER] === false || params.data[TrailerFieldName.SCRUBBER] === 'No') {
            return 'No'
        }
    }
}

const triDriveCompatible = {
    headerName: TrailerHeaderName.TRIDRIVECOMPATIBLE,
    field: TrailerFieldName.TRIDRIVECOMPATIBLE,
    cellEditor: 'agSelectCellEditor',
    colID: TrailerFieldName.TRIDRIVECOMPATIBLE,
    width: 95,
    editable: () => (checkUserRole() ? true : false),
    cellClass:  () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.TRIDRIVECOMPATIBLE] === null) {
            return '';
        } else if(params.data[TrailerFieldName.TRIDRIVECOMPATIBLE] === true || params.data[TrailerFieldName.TRIDRIVECOMPATIBLE] === 'Yes') {
            return 'Yes'
        } else if(params.data[TrailerFieldName.TRIDRIVECOMPATIBLE] === false || params.data[TrailerFieldName.TRIDRIVECOMPATIBLE] === 'No') {
            return 'No'
        }
    }
}

const pumps = {
    headerName: TrailerHeaderName.PUMPS,
    field: TrailerFieldName.PUMPS,
    cellEditor: 'agSelectCellEditor',
    coldId: TrailerFieldName.PUMPS,
    width: 70,
    editable: () => (checkUserRole() ? true : false),
    cellClass: () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.PUMPS] === null) {
            return '';
        } else if(params.data[TrailerFieldName.PUMPS] === -1) {
            return 'N/A';
        } else {
            return params.data[TrailerFieldName.PUMPS]
        }
    }
}

const hoseRack = {
    headerName: TrailerHeaderName.HOSERACK,
    field: TrailerFieldName.HOSERACK,
    cellEditor: 'agSelectCellEditor',
    coldId: TrailerFieldName.HOSERACK,
    width: 70,
    editable: () => (checkUserRole() ? true : false),
    cellClass: () => (checkUserRole() ? 'cell-editable' : ''),
    valueGetter: params => {
        if(params.data[TrailerFieldName.HOSERACK] === null) {
            return '';
        } else if(params.data[TrailerFieldName.HOSERACK] === true || params.data[TrailerFieldName.HOSERACK] === 'Yes') {
            return 'Yes'
        } else if(params.data[TrailerFieldName.HOSERACK] === false || params.data[TrailerFieldName.HOSERACK] === 'No') {
            return 'No'
        }
    }
}

const ownershipType = {
    headerName: TrailerHeaderName.OWNERSHIPTYPE,
    field: TrailerFieldName.OWNERSHIPTYPE,
    cellEditor: 'agSelectCellEditor',
    colId: TrailerFieldName.OWNERSHIPTYPE,
    width: 90,
    valueGetter: params => {
        if (params.data[TrailerFieldName.OWNERSHIPTYPE] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.OWNERSHIPTYPE];
        }
    }
};

const categoryType = {
    headerName: TrailerHeaderName.CATEGORYTYPE,
    field: TrailerFieldName.CATEGORYTYPE,
    cellEditor: 'agSelectCellEditor',
    colId: TrailerFieldName.CATEGORYTYPE,
    width: 80,
    valueGetter: params => {
        if (params.data[TrailerFieldName.CATEGORYTYPE] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.CATEGORYTYPE];
        }
    }
};

const tankSpecification = {
    headerName: TrailerHeaderName.TANKSPECIFICATION,
    field: TrailerFieldName.TANKSPECIFICATION,
    cellEditor: 'agSelectCellEditor',
    filter: 'agNumberColumnFilter',
    colId: TrailerFieldName.TANKSPECIFICATION,
    width: 105,
    valueGetter: params => {
        if (params.data[TrailerFieldName.TANKSPECIFICATION] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.TANKSPECIFICATION];
        }
    }
};

const year = {
    headerName: TrailerHeaderName.YEAR,
    field: TrailerFieldName.YEAR,
    colId: TrailerFieldName.YEAR,
    width: 55,
    // cellRendererFramework: NumericCellRenderer,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
        if (params.data[TrailerFieldName.YEAR] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.YEAR];
        }
    }
};

const vin = {
    headerName: TrailerHeaderName.VIN,
    field: TrailerFieldName.VIN,
    colId: TrailerFieldName.VIN,
    width: 55,
    valueGetter: params => {
        if (params.data[TrailerFieldName.VIN] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.VIN];
        }
    }
};

const manufacturer = {
    headerName: TrailerHeaderName.MANUFACTURER,
    field: TrailerFieldName.MANUFACTURER,
    colId: TrailerFieldName.MANUFACTURER,
    width: 105,
    valueGetter: params => {
        if (params.data[TrailerFieldName.MANUFACTURER] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.MANUFACTURER];
        }
    }
};

const registration = {
    headerName: TrailerHeaderName.REGISTRATION,
    field: TrailerFieldName.REGISTRATION,
    colId: TrailerFieldName.REGISTRATION,
    width: 100,
    editable: () => (dataStore.checkOperationAccess('RegistrationEdit') ? true : false),
    cellClass: () => (dataStore.checkOperationAccess('RegistrationEdit') ? 'cell-editable' : ''),
    valueGetter: params => {
        if (params.data[TrailerFieldName.REGISTRATION] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.REGISTRATION];
        }
    }
};

const trackerNumber = {
    headerName: TrailerHeaderName.TRACKERNUMBER,
    field: TrailerFieldName.TRACKERNUMBER,
    colId: TrailerFieldName.TRACKERNUMBER,
    width: 75,
    editable: () => (dataStore.checkOperationAccess('TruckerNoEdit') ? true : false),
    cellClass: () => (dataStore.checkOperationAccess('TruckerNoEdit') ? 'cell-editable' : ''),
    valueGetter: params => {
        if (params.data[TrailerFieldName.TRACKERNUMBER] === null) {
            return '';
        } else {
            return params.data[TrailerFieldName.TRACKERNUMBER];
        }
    }
};

const modifiedDate = {
    headerName: TrailerHeaderName.MODIFIEDDATE,
    field: TrailerFieldName.MODIFIEDDATE,
    colId: TrailerFieldName.MODIFIEDDATE,
    width: 100,
    valueGetter: params => {
        if (params.data[TrailerFieldName.MODIFIEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TrailerFieldName.MODIFIEDDATE]);
        }
    }
};

const getColorCodeForQuesAcheck = (recivedValue:any) => {
    let cellColor = 'default'
    let recievedDate = dateUtils.getFormattedMSTDateOnly(recivedValue)
    const currentDate = moment(new Date().toISOString()).format('MM/DD/YYYY');
    let rMonth = recievedDate.substring(0, 2);
    let rYear = recievedDate.substring(6, 10);
    let cMonth = currentDate.substring(0, 2);
    let cYear = currentDate.substring(6, 10);
    let cDate = currentDate.substring(3, 5);
    let rDate = recievedDate.substring(3, 5);
    if (cYear === rYear) {
        if (cMonth === rMonth && cDate === rDate) cellColor = 'yellow';
        if (cMonth === rMonth && cDate > rDate) cellColor = 'red';
        if (cMonth > rMonth) cellColor = 'red';
        if (cMonth < rMonth) cellColor = 'default';
    } else if (cYear < rYear) cellColor = 'default';
    else if (cYear > rYear) cellColor = 'red';
    else cellColor = 'default';
    return cellColor;
};

const getColorCodeForQues = (recivedValue:any) => {
        let cellColor = 'default'
        let recievedDate = moment(recivedValue).format("MM/YYYY")
        const currentDate = moment(new Date().toISOString()).format('MM/YYYY');
        let rMonth = recievedDate.substring(0,2);
        let rYear = recievedDate.substring(3,7);
        let cMonth = currentDate.substring(0,2);
        let cYear = currentDate.substring(3,7);

        if(cYear === rYear) {
            if(cMonth === rMonth ) cellColor = 'yellow';
            if(cMonth > rMonth) cellColor = 'red';  
            if(cMonth < rMonth) cellColor = 'default';              
        }else if( cYear < rYear) cellColor = 'default';
        else if( cYear > rYear) cellColor = 'red';
        else cellColor = 'default';
        return cellColor;
} 

export const trailerColDef: ColDef[] = [
    { headerName: TrailerHeaderName.ID, field: TrailerFieldName.ID, hide: true },
    {
        headerName: TrailerHeaderName.SELECT,
        field: TrailerFieldName.SELECT,
        width: 65,
        cellRendererFramework: SelectionRadioButtonCellRenderer
    },
    {
        headerName: TrailerHeaderName.TRAILERNUMBER,
        field: TrailerFieldName.TRAILERNUMBER,
        width: 75,
        valueGetter: params => {
            if (params.data[TrailerFieldName.TRAILERNUMBER] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.TRAILERNUMBER];
            }
        },
        cellClass: params => {
            if((params.data[TrailerFieldName.STATUS] === 'Active' || params.data[TrailerFieldName.STATUS] === 'Active-PendingPaperwork') &&
            getColorCodeForQuesAcheck(params.data[TrailerFieldName.Acheck]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.CVIP]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.V]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.K]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.I]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.P]) === 'default' &&
            getColorCodeForQues(params.data[TrailerFieldName.UC]) === 'default' &&
            (params.data[TrailerFieldName.OTESTRIGGER] ? getColorCodeForQuesAcheck(params.data[TrailerFieldName.OTES]) === 'default' : true) &&
                ((params.data[TrailerFieldName.LANDMARKTYPE] &&
                    params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() === 'SHOP') ||
                    (params.data[TrailerFieldName.LANDMARKTYPE] &&
                        params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() === 'STORAGE'))
            )
            return 'trailer-ques-bgColor-green'
            else return ''
        }
    },
    { headerName: TrailerHeaderName.STATUSID, field: TrailerFieldName.STATUSID, hide: true },
    status,
    {
        headerName: TrailerHeaderName.TRAILERTYPE,
        field: TrailerFieldName.TRAILERTYPE,
        width: 95,
        valueGetter: params => {
            if (params.data[TrailerFieldName.TRAILERTYPE] === null) {
                return '';
            } else {
                    return params.data[TrailerFieldName.TRAILERTYPE];
            }
        }
    },
    {
        headerName: TrailerHeaderName.LANDMARKNAME,
        field: TrailerFieldName.LANDMARKNAME,
        width: 85,
        valueGetter: params => {
            if (params.data[TrailerFieldName.LANDMARKNAME] === null || params.data[TrailerFieldName.LANDMARKTYPE] === null ||
                (params.data[TrailerFieldName.LANDMARKTYPE] && params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() !== 'SHOP' && params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() !== 'STORAGE')
            ) {
                return ''
            } else {
                return params.data[TrailerFieldName.LANDMARKNAME];
            }
        }
    },
    {
        headerName: TrailerHeaderName.LANDMARKTYPE,
        field: TrailerFieldName.LANDMARKTYPE,
        width: 85,
        valueGetter: params => {
            if (params.data[TrailerFieldName.LANDMARKTYPE] === null ||
                (params.data[TrailerFieldName.LANDMARKTYPE] &&
                    params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() !== 'SHOP' &&
                    params.data[TrailerFieldName.LANDMARKTYPE].toUpperCase() !== 'STORAGE')
            ) {
                return ''
            } else {
                return params.data[TrailerFieldName.LANDMARKTYPE];
            }
        }
    },
    {
        headerName: TrailerHeaderName.MAINTENANCEID,
        field: TrailerFieldName.MAINTENANCEID,
        width: 95,
        valueGetter: params => {
            if (params.data[TrailerFieldName.MAINTENANCEID] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.MAINTENANCEID];
            }
        }
    },
    {
        headerName: TrailerHeaderName.BOOKINGDATE,
        field: TrailerFieldName.BOOKINGDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[TrailerFieldName.BOOKINGDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[TrailerFieldName.BOOKINGDATE]);

            }
        }
    },
    {
        headerName: TrailerHeaderName.SHOPNAME,
        field: TrailerFieldName.SHOPNAME,
        width: 95,
        valueGetter: params => {
            if (params.data[TrailerFieldName.SHOPNAME] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.SHOPNAME];
            }
        }
    },
    {
        headerName: TrailerHeaderName.MAINTENANCEFLEET,
        field: TrailerFieldName.MAINTENANCEFLEET,
        cellEditor: 'agSelectCellEditor',
        colId: TrailerFieldName.MAINTENANCEFLEET,
        width: 95,
        editable: () => (dataStore.checkOperationAccess('MaintenanceFleetEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('MaintenanceFleetEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if(params.data[TrailerFieldName.MAINTENANCEFLEET] === null) {
                return '';
            } else if(params.data[TrailerFieldName.MAINTENANCEFLEET] === true || params.data[TrailerFieldName.MAINTENANCEFLEET] === 'Yes') {
                return 'Yes'
            } else if(params.data[TrailerFieldName.MAINTENANCEFLEET] === false || params.data[TrailerFieldName.MAINTENANCEFLEET] === 'No') {
                return 'No'
            }
        }
    },
    {
        headerName: TrailerHeaderName.CARRIER,
        field: TrailerFieldName.CARRIER,
        width: 125,
        colId: TrailerFieldName.CARRIER,
        editable: () => (dataStore.checkOperationAccess('CarrierEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('CarrierEdit') ? 'cell-editable' : ''),
            valueGetter: params => {
                if (params.data[TrailerFieldName.CARRIER] === null) {
                    return '';
                } else {
                    return params.data[TrailerFieldName.CARRIER];
                }
            }
    },
    {
        headerName: TrailerHeaderName.REGION,
        field: TrailerFieldName.REGION,
        width: 70,
        cellEditor: 'agSelectCellEditor',
        colId: TrailerFieldName.REGION,
        editable: (params) => (dataStore.checkOperationAccess('CarrierEdit') ? true : false),
        cellClass: (params) => (dataStore.checkOperationAccess('CarrierEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TrailerFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.REGION];
            }
        }
    },
    {
        headerName: TrailerHeaderName.Acheck,
        field: TrailerFieldName.Acheck,
        width: 75,
        valueGetter: params => {
            if (params.data[TrailerFieldName.Acheck] === null) {
                return '';
            } else {
                return dateUtils.getFormattedMSTDateOnly(params.data[TrailerFieldName.Acheck]);
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.Acheck];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQuesAcheck(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.Acheck]; 
        }
    },
    {
        headerName: TrailerHeaderName.CVIP,
        field: TrailerFieldName.CVIP,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerFieldName.CVIP] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.CVIP];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.CVIP];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.CVIP]; 
        }
    },
    {
        headerName: TrailerHeaderName.V,
        field: TrailerFieldName.V,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerFieldName.V] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.V];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.V];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.V]; 
        }
    },
    {
        headerName: TrailerHeaderName.K,
        field: TrailerFieldName.K,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerFieldName.K] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.K];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.K];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.K]; 
        }
    },
    {
        headerName: TrailerHeaderName.I,
        field: TrailerFieldName.I,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerFieldName.I] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.I];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.I];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.I]; 
        }
    },
    {
        headerName: TrailerHeaderName.P,
        field: TrailerFieldName.P,
        width: 60,
        valueGetter: params => {
            if (params.data[TrailerFieldName.P] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.P];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.P];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.P]; 
        }
    },
    {
        headerName: TrailerHeaderName.UC,
        field: TrailerFieldName.UC,
        width: 65,
        valueGetter: params => {
            if (params.data[TrailerFieldName.UC] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.UC];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.UC];
            if(recivedValue != null && recivedValue !='') { 
                let cellBgColor = getColorCodeForQues(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.UC]; 
        }
    },
    {
        headerName: TrailerHeaderName.OTES,
        field: TrailerFieldName.OTES,
        width: 70,
        valueGetter: params => {
            if (params.data[TrailerFieldName.OTES] === null || params.data[TrailerFieldName.OTESTRIGGER] == false) {
                return '';
            } else {
                return params.data[TrailerFieldName.OTES];
            }
        },
        cellClass: params => {
            let recivedValue = params.data[TrailerFieldName.OTES];
            let otesTrigger = params.data[TrailerFieldName.OTESTRIGGER];
            if(recivedValue != null && recivedValue !='' && otesTrigger != null && otesTrigger == true) { 
                let cellBgColor = getColorCodeForQuesAcheck(recivedValue)
                if(cellBgColor =='red') {
                    return 'trailer-ques-bgColor-red'
                }
                if(cellBgColor =='yellow') {
                    return 'trailer-ques-bgColor-yellow'
                }
            }
            return params.data[TrailerFieldName.OTES]; 
        }
    },
    {
        headerName: TrailerHeaderName.OTESTRIGGER,
        field: TrailerFieldName.OTESTRIGGER,
        width: 80,
        cellRendererFramework: OTESTriggerRenderer,
    },
    waterCapacityLead, waterCapacityPup, driplessAdapterSide, opticalPlug, skully, scrubber, triDriveCompatible,hoseRack, pumps,
    categoryType,
    tankSpecification,
    {
        headerName: TrailerHeaderName.COMMENTS,
        field: TrailerFieldName.COMMENTS,
        cellRendererFramework: TextAreaCellRenderer,
        width: 90,
        valueGetter: params => {
            if (params.data[TrailerFieldName.COMMENTS] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.COMMENTS];
            }
        },
        cellRendererParams: {
            isDisabled: (item, value) => true
        }
    },
    year,
    vin,
    manufacturer,
    registration,
    trackerNumber,
    { headerName: TrailerHeaderName.AXLES, width: 85, field: TrailerFieldName.AXLES },
    ownershipType,
    {
        headerName: TrailerHeaderName.AXID,
        field: TrailerFieldName.AXID,
        width: 75,
        valueGetter: params => {
            if (params.data[TrailerFieldName.AXID] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.AXID];
            }
        }
    },
    {
        headerName: TrailerHeaderName.MODIFIEDBY,
        field: TrailerFieldName.MODIFIEDBY,
        width: 80,
        valueGetter: params => {
            if (params.data[TrailerFieldName.MODIFIEDBY] === null) {
                return '';
            } else {
                return params.data[TrailerFieldName.MODIFIEDBY];
            }
        }
    },
    modifiedDate,  
];
