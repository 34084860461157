import { ShopDataService } from '../services/shop-data-service';
import { ShopValidationService } from '../services/shop-validation-service';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { AccountStore } from '../../account/stores/account-store';
import { UiService } from '../../../shared/services/ui-service';
import { shopColDef } from '../domains/shop-col-def';
import { action, observable } from 'mobx';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { ShopMessage, ShopFieldName } from '../domains/shop-enum';
import { ShopResponse, ShopStatus } from '../domains/shop-model';
import { DONT_REHIRE, INACTIVE_INSHOP, INACTIVE_PARKED, INACTIVE_PARKEDOUTOF } from '../data_constant';
import { CommonUtils } from 'shared/services/common-utils';

export class ShopDataStore {
    userID = 0;
    userName = '';
    @observable shopInfo: ShopResponse[] = [];
    backupShopList: ShopResponse[] = [];
    @observable cellValueChangeMap = {};
    @observable shopStatusValues: ShopStatus[] = [];

    constructor(
        private shopDataService: ShopDataService,
        private shopValidationService: ShopValidationService,
        public agGridService: AgGridService,
        private accountStore: AccountStore,
        public uiService: UiService
    ) {}

    init(): void {
        this.loadViewModel();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @action
    reset(): void {
        this.setShopList([]);
    }

    getColDef() {
        return shopColDef;
    }

    @action
    resetShopData(): void {
        this.setShopList(this.backupShopList);
        this.cellValueChangeMap = {};
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    isResetEnabled = (): boolean => {
        const updatedRows = this.shopInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()) || a.ID < 1);
        return updatedRows.length === 0;
    };

    @Loader
    @Catch(() => errorHandler(ShopMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const { shopData, shopStatusValues } = await this.shopDataService.getShopViewModel();

        const shopInfo: ShopResponse[] = shopData['Data'];
        this.shopStatusValues = shopStatusValues['Data'].filter(
            a =>
                a.Code != DONT_REHIRE &&
                a.Code != INACTIVE_INSHOP &&
                a.Code != INACTIVE_PARKED &&
                a.Code != INACTIVE_PARKEDOUTOF
        );
        shopInfo.forEach(data => {
            if(data.ContactNumber != null)
            data.ContactNumber = data.ContactNumber.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
        })
        this.setShopList(shopInfo);
        this.setBackupShopList(shopInfo);
        this.resetColDef(ShopFieldName.STATUS);
        this.addValuesInCellDropdowns();
    }

    @action
    setShopList(shopInfo: ShopResponse[]): void {
        this.shopInfo = shopInfo;
    }

    setBackupShopList(shops: ShopResponse[]) {
        this.backupShopList = shops;
    }

    @action
    updateRow = (selectedRowData: ShopResponse): void => {
        const updatedShopIndex = this.shopInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.backupShopList[updatedShopIndex], selectedRowData)) {
            this.updateIDFieldBasedOnName(selectedRowData);
            this.mapEditableColumns(this.shopInfo[updatedShopIndex], selectedRowData);
        }
    };

    @action
    AddRow(): void {
        const activeStatus = this.shopStatusValues.filter(x => x.Code === 'ACTIVE');
        const newRow: ShopResponse = {
            ID: -1,
            ShopName: '',
            Address: '',
            Province: '',
            PostalCode: '',
            City: '',
            ContactNumber: '',
            Lattitude: '',
            Longitude: '',
            CreatedByUser: this.userName,
            ModifyByUser: '',
            CreatedBy: this.userID,
            ModifyDate: undefined,
            Status: activeStatus[0].Name,
            StatusID: activeStatus[0].ID,
            ModifiedBy: undefined,
            SearchBy: '',
            SearchByValue: ''
        };
        this.agGridService.addNewRowToGrid(newRow, 0);
        this.shopInfo.splice(0, 0, newRow);
    }

    private updateIDFieldBasedOnName(shop: ShopResponse) {
        shop.StatusID = this.shopStatusValues.find(a => a.Name == shop.Status)?.ID;
    }

    mapEditableColumns(currentItem: ShopResponse, updatedItem: ShopResponse) {
        currentItem.ShopName = updatedItem.ShopName;
        currentItem.Address = updatedItem.Address;
        currentItem.City = updatedItem.City;
        currentItem.Province = updatedItem.Province;
        currentItem.PostalCode = updatedItem.PostalCode;
        currentItem.ContactNumber = updatedItem.ContactNumber.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");;
        currentItem.ModifyDate = updatedItem.ModifyDate;
        currentItem.ModifiedBy = updatedItem.ModifiedBy;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(this.shopStatusValues, ShopFieldName.STATUS, 'Name');
    }

    @Loader
    @action
    @Catch(() => errorHandler(ShopMessage.FAILED_SUBMIT))
    async updateShopData(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.shopInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()) || a.ID < 1);
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateShopRequest(updatedRowIDs);
        const validationMessage = this.shopValidationService.validateUpdateRequest(requestBody);
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        let errorInSave = false;
        const response = await this.shopDataService.updateShops(requestBody);
        if (response) {
            for (const key in response) {
                if (response[key].ID) {
                    const objIndex = this.shopInfo.findIndex(a => a.ID.toString() == key);
                    this.shopInfo[objIndex].ModifyDate = response[key].ModifyDate;
                    this.shopInfo[objIndex].ContactNumber = this.shopInfo[objIndex].ContactNumber.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
                    for (let i = 0; i < this.backupShopList.length; i++) {
                        const backupItem = this.backupShopList[i];
                        if (backupItem.ID.toString() == key) {
                            this.mapEditableColumns(backupItem, this.backupShopList[objIndex]);
                            break;
                        }
                    }
                } else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: response[key] }];
                }
            }
        }
        this.cellValueChangeMap = {};
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.shopInfo.length; i++) {
                    const updatedShop = this.shopInfo[i];
                    if (updatedShop.ID.toString() === errorMessage.id) {
                        const backupShopIndex = this.backupShopList.findIndex(a => a.ID.toString() == errorMessage.id);
                        this.mapEditableColumns(updatedShop, this.backupShopList[backupShopIndex]);
                        break;
                    }
                }
            });
        } else this.uiService.toastService.success(ShopMessage.SAVE);
        this.loadViewModel();
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    updateShopRequest(updatedRows: ShopResponse[]): ShopResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.ContactNumber = item.ContactNumber.replace(/\s+/g, '').replace('(','').replace(')','');
        });
        return updatedRows;
    }

    resetColDef(columnKey: string) {
        const colDefAll = shopColDef;
        const colDef = colDefAll.find(x => x.field == columnKey);
        if (colDef) {
            this.agGridService.setColDef(columnKey, colDef);
        }
    }
}
