import { ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { RegionType, RegionMappingType} from '../model/model';
import { Company_Region_Type } from '../../../mapping/mapping_portal_constant';

export class DispatchBoardDataService {
    constructor(private restApi: RestApiService) {}

    public getRegionTypes(): Promise<ResponseDataType<RegionType[]>> {
        return this.restApi.postWithToken<RegionType[]>(Config.getBaseValueByClass, { CODE: Company_Region_Type });
    }

    public getCompanyRegionMapping(companyRequestdata): Promise<ResponseDataType<RegionMappingType[]>> {
        return this.restApi.postWithToken<RegionMappingType[]>(Config.getCompanyRegionMapping, companyRequestdata);
    }

    public getCapacityPlanningDisplayData(dispatchBoardRequestData): Promise<ResponseDataType<RegionMappingType[]>> {
        return this.restApi.postWithToken<RegionMappingType[]>(Config.getCapacityPlanningInternal, dispatchBoardRequestData);
    }

    public getDispatchBoardDetails(dispatchBoardRequestData): Promise<ResponseDataType<RegionMappingType[]>> {
        return this.restApi.postWithToken<RegionMappingType[]>(Config.getDispatchBoardDetails, dispatchBoardRequestData);
    }
}

export const dispatchBoardDataService = new DispatchBoardDataService(restApiService);
