import { RestApi, ConfigResponseModel } from 'shared/types/shared';
import { restApiService } from 'shared/services';
import { Config } from 'config';

export class CommunicationDataService {
    constructor(private apiService: RestApi) {}

    async sendBroadCast(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.sendBroadCast, reqbody);
        return data['Data'];
    }

    async sendDirectMessage(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.sendDirectMessage, reqbody);
        return data['Data'];
    }

    async getDriverList() {
        const { data } = await this.apiService.postWithToken<{}>(Config.getDriverCompanyMapping, {});
        return data['Data'];
    }
    async getDispatcherList() {
        const { data } = await this.apiService.postWithToken<{}>(Config.getDispatcher, {});
        return data['Data'];
    }

    async getCCUserList() {
        const { data } = await this.apiService.postWithToken<{}>(Config.getCCUser, {});
        return data['Data'];
    }

    async getAllMessages(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.getAllMessages, reqbody);
        return data['Data'];
    }

    async updateReadMessage(reqbody) {
        await this.apiService.postWithToken<{}>(Config.updateReadMessage, reqbody);
    }

    async getBroadcastCount(reqbody) {
        const { data } = await this.apiService.postWithToken<{}>(Config.getBroadcastCount, reqbody);
        return data['Data'];
    }
}

export const communicationDataService = new CommunicationDataService(restApiService);
