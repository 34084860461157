export const dataTypeOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'TrailerManagement', label: 'Trailer Management' },
    { value: 'DriverManagement', label: 'Driver Management' },
    { value: 'CompanyManagement', label: 'Carrier Company Management' },
    { value: 'LocationMaping', label: 'Location Mapping'},
    { value: 'LocationManagement', label: 'Location Management'},
    { value: 'TabletManagement', label: 'Tablet Management' },
    { value: 'ShopManagement', label: 'Shop Management' },
    { value: 'PrinterManagement', label: 'Printer Management' },
    { value: 'TractorManagement', label: 'Tractor Management' },
    { value: 'KeyFobManagement', label: 'Key Fob Management'},
    { value: 'RegionMapping', label: 'Region Mapping' },
];

export const TRAILER_OWNERSHIPTYPE = 'OWSHP_TYPE';
export const TRAILER_CATEGORY_TYPE = 'CTGRY_TYPE';
export const TANK_SPECIFICATION = 'TANK_SPECIFICATION';
export const USER_ROLE = 'USER_ROLE';
export const TRAILER_STATUS = 'USR_STATUS';
export const DONT_REHIRE = 'DONT_REHIRE';
export const SHOP_STATUS = 'USR_STATUS';
export const RESET = 'Reset';
export const ADD_NEW_ROW = 'Add New Row';
export const DELETE_ITEM = 'Delete Items';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const INACTIVE_INSHOP = 'INACTIVE_INSHOP';
export const INACTIVE_PARKED = 'INACTIVE_PARKED';
export const INACTIVE_PARKEDOUTOF = 'INACTIVE_PARKEDOUTOF';
export const CCUserRoles = [
    'PTSOwnerOperator',
    'PTSCarrierCompanyScheduler',
    'PTSCarrierOwner',
    'PTSCarrierCompanyAdmin'
];
export const INVALID_USERID = 'The login session has expired. can you kindly logout and login back to continue.';
export const INVALID_CCID = 'Looks like you are not mapped to carrier company. Kindly contact Plains.';