import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { MasterTicketReportResponse, DateRange } from '../domains/model';

export class MasterTicketReportDataService {
    constructor(private restApi: RestApiService) {}

    async getMasterTicket(): Promise<MasterTicketReportResponse[]> {
        const { data } = await this.restApi.postWithToken<MasterTicketReportResponse[]>(Config.getMasterReport);
        return data['Data'];
    }
    async getMasterTicketwithParams(reqbody: DateRange): Promise<MasterTicketReportResponse[]> {
        const { data } = await this.restApi.postWithToken<MasterTicketReportResponse[]>(Config.getMasterReport, reqbody, {});
        return data['Data'];
    }
    async getDispatchDetails(reqbody: any): Promise<MasterTicketReportResponse[]> {
        const { data } = await this.restApi.postWithToken<MasterTicketReportResponse[]>(Config.getDispatchDetails, reqbody, {});
        return data['Data'];
    }
}

export const masterTicketDataService = new MasterTicketReportDataService(restApiService);
