import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { PrinterDataService } from '../services/printer-management/printer-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { PrinterResponse, DeleteRequest } from '../domains/printer-management/printer-model'
import { AgGridService } from 'features/common/services/ag-grid-service';
import { PrinterColDef } from '../domains/printer-management/printer-col-def';
import { PrinterrMessage as PrinterMessage, PrinterFieldName } from '../domains/printer-management/printer-enum';
import { PrinterValidationService } from '../services/printer-management/printer-validation-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { CommonUtils } from 'shared/services/common-utils';

export class PrinterDataStore {
    @observable printerInfo: PrinterResponse[] = [];
    backupPrinterList: PrinterResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    @observable dataToBeDeleted: DeleteRequest[] = [];
    @observable dataToBeDeletedID: number[] = [];

    constructor(
        private printerDataService: PrinterDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        private PrinterValidationService: PrinterValidationService
    ) { }

    init(): void {
        this.getPrintersData();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return PrinterColDef;
    }

    @Loader
    @Catch(() => errorHandler(PrinterMessage.FETCH_ERROR_MESSAGE))
    async getPrintersData(): Promise<void> {
        const printers: PrinterResponse[] = await this.printerDataService.getPrinterData();
        this.setPrinterList(printers);
        this.setbackupPrinterList(printers);
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    setAlphaNumeric(printer: PrinterResponse, value: string, colName: string): void {
        const row = printer.ID;
        const initValue = printer[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case PrinterFieldName.PRINTERNAME:
                printer.PrinterName = value;
                break;
            case PrinterFieldName.PRINTERSERIALNUMBER:
                printer.PrinterSerialNumber = value;
                break;
            case PrinterFieldName.ASSIGNEDTO:
                printer.AssignedTo = value;
                break;
            default:
                break;
        }
        this.updateRow(printer);
    }

    setbackupPrinterList(Printers: PrinterResponse[]) {
        this.backupPrinterList = Printers;
    }

    @computed
    get printerList(): PrinterResponse[] {
        return toJS(this.printerInfo);
    }

    @action
    setPrinterList(printerInfo: PrinterResponse[]): void {
        this.printerInfo = printerInfo;
    }

    @action
    reset(): void {
        this.setPrinterList([]);
    }

    @action
    resetPrinterList(): void {
        this.setPrinterList(this.backupPrinterList);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: PrinterResponse): void => {
        const updatedPrinterIndex = this.printerInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.printerInfo[updatedPrinterIndex], selectedRowData)) {
            this.mapEditableColumns(this.printerInfo[updatedPrinterIndex], selectedRowData);
        }
    };

    @action
    @Loader
    @Catch(() => errorHandler(PrinterMessage.DELETEDERROR))
    async deleteRows(): Promise<void> {
        if (this.dataToBeDeletedID.length > 0) {
            const updateData = this.printerInfo.filter(x => !this.dataToBeDeleted.filter(y => y.ID === x.ID).length);
            await this.printerDataService.deletePrinter(this.dataToBeDeletedID);
            this.printerInfo = updateData;
            this.dataToBeDeleted = [];
            this.isDeleteEnabled();
            this.uiService.toastService.success(PrinterMessage.DELETEDSUCCESS);
        }
        else
            errorHandler(PrinterMessage.NO_ROW_SELECTED);
    }


    @action
    handleCheckboxClick(printer: PrinterResponse, value: any, colName: string): void {
        const row = printer.ID;
        const initValue = printer[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case PrinterFieldName.DELETE:
                printer.Delete = !value;
                break;
            default:
                break;
        }
        if (printer.Delete) {
            this.dataToBeDeleted.push({ ID: printer.ID });
            this.dataToBeDeletedID.push(printer.ID);

        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== printer.ID);
            this.dataToBeDeletedID = this.dataToBeDeletedID.filter(a => a !== printer.ID);
        }
    }

    @action
    addRow(): void {
        const printer: PrinterResponse = {
            ID: 0,
            PrinterName: '',
            PrinterSerialNumber: '',
            CreatedBy: this.userID,
            CreatedDate: new Date().toUTCString(),
            ModifiedByUser: '',
        };
        let minID: number = this.printerInfo[0].ID;
        if (minID <= 0) {
            printer.ID = minID - 1;
        }
        this.printerInfo.unshift(printer);
    };

    @Loader
    @action
    @Catch(() => errorHandler(PrinterMessage.FAILED_SUBMIT))
    async updatePrinters(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        const updatedRowIDs = this.printerInfo.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updatePrinterRequest(updatedRowIDs);
        const validationMessage = this.PrinterValidationService.validateUpdateRequest(requestBody, this.backupPrinterList);
        if (!isEmpty(validationMessage)) {
            this.uiService.loaderService.hideLoader();
            this.uiService.toastService.error(validationMessage);
            return;
        }
        const response = await this.printerDataService.updatePrinter(requestBody);
        if (!isEmpty(response)) {
            for (const key in response) {
                errorSummary = [...errorSummary, { id: key, description: response[key] }];
            }
            this.uiService.toastService.error('', {}, errorSummary);
        }
        this.cellValueChangeMap = {};
        this.getPrintersData();
        if (errorSummary.length == 0)
            this.uiService.toastService.success(PrinterMessage.SAVE);
    }

    updatePrinterRequest(updatedRows: PrinterResponse[]): PrinterResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            item.ModifiedByUser = this.userName;
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                if (PrinterFieldName.DELETE != col) {
                    const obj = this.cellValueChangeMap[row][col];
                    if (obj.initValue !== obj.currentValue) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    mapEditableColumns(currentItem: PrinterResponse, updatedItem: PrinterResponse) {
        currentItem.PrinterName = updatedItem.PrinterName;
        currentItem.PrinterSerialNumber = updatedItem.PrinterSerialNumber;
        currentItem.AssignedTo = updatedItem.AssignedTo?.trim();
    }

    isDeleteEnabled = (): boolean => {
        const isEnabled = this.dataToBeDeleted.length > 0 ? false : true;
        return isEnabled;
    };

}
