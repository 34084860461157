import React from 'react';
import { Modal } from 'react-bootstrap';
import { SearchTicketsLocationComponent } from './search-tickets-location-component';
import { SearchTicketsCarrierComponent } from './search-tickets-carrier-component';
import { SearchTicketsTrailerComponent } from './search-tickets-trailer-component';
import { SearchTicketsDriverComponent } from './search-tickets-driver-component';
interface SearchTicketsPopUpProps {
    onHide: () => void;
    showTRModal: boolean;
    showCCModal: boolean;
    showLocModal: boolean;
    showDRModal: boolean;
    startDate?: any;
    companyName?: any;
    selectedCarrierId: number;
    data: any[]
    onSelectionChange: (carrierID: any) => void;
}

export const SearchTicketsPopupComponent = (props: SearchTicketsPopUpProps) => {
    const { companyName, onHide, showTRModal, onSelectionChange, selectedCarrierId, data, startDate, showCCModal, showLocModal, showDRModal } = props;
    return (<>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showTRModal} animation={false}>
            <Modal.Body>
                <SearchTicketsTrailerComponent
                    selectedTrailerId={selectedCarrierId}
                    trailerNumber={companyName}
                    onHide={onHide}
                    trailers={data}
                    onSelectionChange={onSelectionChange}
                ></SearchTicketsTrailerComponent>
            </Modal.Body>
        </Modal>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showCCModal} animation={false}>
            <Modal.Body>
                <SearchTicketsCarrierComponent
                    selectedCarrierId={selectedCarrierId}
                    companyName={companyName}
                    onHide={onHide}
                    carriers={data}
                    onSelectionChange={onSelectionChange}
                ></SearchTicketsCarrierComponent>
            </Modal.Body>
        </Modal>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showLocModal} animation={false}>
            <Modal.Body>
                <SearchTicketsLocationComponent
                    selectedLocationId={selectedCarrierId}
                    LocationName={companyName}
                    onHide={onHide}
                    locations={data}
                    onSelectionChange={onSelectionChange}
                ></SearchTicketsLocationComponent>
            </Modal.Body>
        </Modal>
        <Modal size="lg" onHide={onHide} backdrop='static' show={showDRModal} animation={false}>
            <Modal.Body>
                <SearchTicketsDriverComponent
                    selectedDriverId={selectedCarrierId}
                    DriverName={companyName}
                    onHide={onHide}
                    drivers={data}
                    onSelectionChange={onSelectionChange}
                ></SearchTicketsDriverComponent>
            </Modal.Body>
        </Modal></>
    );
};