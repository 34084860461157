import { TractorFieldName, TractorHeaderName } from './tractor-enum';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { dateUtils } from 'shared/services/date-utils';
import { CheckBoxCellRenderer } from '../components/cell-renderers/tractor-checkbox-cell-renderer';
import { dataStore } from 'features/common/stores';

const modifiedDate = {
    headerName: TractorHeaderName.MODIFIEDDATE,
    field: TractorFieldName.MODIFYDATE,
    colId: TractorFieldName.MODIFYDATE,
    width: 110,
    valueGetter: params => {
        if (params.data[TractorFieldName.MODIFYDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TractorFieldName.MODIFYDATE]);
        }
    }
};

const createdDate = {
    headerName: TractorHeaderName.CREATEDDATE,
    field: TractorFieldName.CREATEDDATE,
    colId: TractorFieldName.CREATEDDATE,
    width: 105,
    valueGetter: params => {
        if (params.data[TractorFieldName.CREATEDDATE] === null) {
            return '';
        } else {
            return dateUtils.getFormattedDateTime(params.data[TractorFieldName.CREATEDDATE]);
        }
    }
};

const powerUnitIdentifier = {
    headerName: TractorHeaderName.POWERUNITIDENTIFIER,
    field: TractorFieldName.VIN,
    colId: TractorFieldName.VIN,
    width: 90,
    valueGetter: params => {
        if (params.data[TractorFieldName.VIN] === null) {
            return '';
        } else {
            return dateUtils.getLastSubstring(params.data[TractorFieldName.VIN], 8);
        }
    }
};

export const tractorColDef: ColDef[] = [
    { headerName: TractorHeaderName.ID, field: TractorHeaderName.ID, hide: true },
    {
        headerName: TractorHeaderName.VINNUMBER,
        field: TractorFieldName.VIN,
        width: 100,
        editable: () => (dataStore.checkOperationAccess('VINEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('VINEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TractorFieldName.VIN] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.VIN];
            }
        }
    },
    powerUnitIdentifier,
    {
        headerName: TractorHeaderName.PLATE,
        field: TractorFieldName.PLATE,
        width: 60,
        editable: () => (dataStore.checkOperationAccess('RegistrationEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('RegistrationEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TractorFieldName.PLATE] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.PLATE];
            }
        }
    },
    {
        headerName: TractorHeaderName.COMPANYNAME,
        field: TractorFieldName.COMPANYNAME,
        width: 125,
        cellEditor: 'agSelectCellEditor',
        editable: () => (dataStore.checkOperationAccess('CarrierCompanyEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('CarrierCompanyEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TractorFieldName.COMPANYNAME] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.COMPANYNAME];
            }
        }
    },
    {
        headerName: TractorHeaderName.COMPLIANCEREVIEW,
        field: TractorFieldName.COMPLIANCEREVIEW,
        width: 100,
        cellEditor: 'agSelectCellEditor',
        editable: () => (dataStore.checkOperationAccess('ComplianceReviewEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('ComplianceReviewEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[TractorFieldName.COMPLIANCEREVIEW] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.COMPLIANCEREVIEW];
            }
        }
    },
    {
        headerName: TractorHeaderName.CREATEDBYUSER,
        field: TractorFieldName.CREATEDBYUSER,
        width: 90,
        valueGetter: params => {
            if (params.data[TractorFieldName.CREATEDBYUSER] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.CREATEDBYUSER];
            }
        }
    },
    createdDate,
    {
        headerName: TractorHeaderName.MODIFIEDBY,
        field: TractorFieldName.MODIFIEDBYUser,
        width: 95,
        valueGetter: params => {
            if (params.data[TractorFieldName.MODIFIEDBYUser] === null) {
                return '';
            } else {
                return params.data[TractorFieldName.MODIFIEDBYUser];
            }
        }
    },
    modifiedDate,
    {
        headerName: TractorHeaderName.DELETE,
        field: TractorFieldName.CHECKBOX,
        width: 65,
        cellRendererFramework: CheckBoxCellRenderer
    }
];
