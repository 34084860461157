import React, { Component } from 'react';
import { TrailerDataStore } from '../stores/trailer-data-store';
import { GridOptions, CellEditingStartedEvent, CellEditingStoppedEvent, CellClickedEvent } from 'ag-grid-community';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { trailerStore } from '../stores';
import { dataStore } from '../../common/stores/index';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { SchedulePopup, DescriptionPopup } from '../../data_management/components/trailer-maintenance-schedule-popup';
import { dailyReport } from 'features/reports/domains/daily-report-col-def';
import {transact} from '../domains/statetranistions'
import { TrailerFieldName } from '../domains/enum';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
import { dateUtils } from 'shared/services/date-utils';
import { TrailerManagementCarrierPopupComponent } from './trailer-management-carrier-popup-component';
interface TrailerMasterDataProps {
    trailerStore: TrailerDataStore;
}

interface TrailerMasterDataState {
    open: boolean;
    openPopup: boolean;
    selectedDays: any; 
    description: any;
    btnDisabled: boolean;
}
@observer
export class TrailerMasterDataComponent extends Component<TrailerMasterDataProps, TrailerMasterDataState> {
    constructor(props) {
        super(props);
        dataStore.setModuleName('TrailerManagement');
        this.state = {
            open: false,
            openPopup: false,
            selectedDays: '',
            description: '',
            btnDisabled: true
        };
    }
    componentDidMount() {
        this.props.trailerStore.init();
    }

    componentWillUnmount() {
        this.props.trailerStore.reset();
        this.props.trailerStore.trailerNumber = ''
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, selectedDays: '', description: '', btnDisabled: true });
    };

    handleOpenPopup = () => {
        this.setState({ openPopup: true, open: false });
    };
    handleClosePopup = () => {
        this.setState({ openPopup: false, selectedDays: '', description: '', btnDisabled: true });
    };

    handleCreateMaintenanceConfirm = () => {
        const { trailerStore } = this.props;
        const { selectedDays, description} = this.state;
        trailerStore.createScheduledMaintenance(dateUtils.getApiDateFormat(selectedDays), description);
        this.handleClosePopup()
    };

    handleDayClick(day) {
        console.log(day);
        this.setState({
            selectedDays: day
        });
    }
    handleDescriptionChange = e => {
        if (e.target.value !== '') {
            this.setState({
                btnDisabled: false,
                description: e.target.value
            });
        } else {
            this.setState({
                btnDisabled: true,
                description: e.target.value
            });
        }
    };

    render(): React.ReactNode {
        const {
            trailerStore: { isSaveDisabled, trailerNumber, showCarrierModal }
        } = this.props;
        const {
            open,
            openPopup,
            selectedDays,
            description,
            btnDisabled
        } = this.state;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <span>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                Save Trailer Data
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                Reset
                                            </CustomButton>
                                           
                                        </span>
                                    )}
                                    <span>
                                        {dataStore.checkOperationAccess('Save') && 
                                        <CustomButton
                                            type={CustomButtonType.Submit}
                                            onClick={this.handleOpen}
                                            disabled={!trailerNumber}
                                        >
                                            Schedule Maintenance
                                        </CustomButton> }
                                    </span> 
                                    <ClearButtonComponent handleRefresh = {this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                                <TrailerManagementCarrierPopupComponent
                                    carriers={this.props.trailerStore.carrierCompanyData}
                                    selectedCarrierId={1}
                                    onHide={this.handleCarrierPopUpClose}
                                    onSelectionChange={this.handleCarrierChange}
                                    show={showCarrierModal}
                                ></TrailerManagementCarrierPopupComponent>
                            </Col>
                        </Row>
                        </div>

                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SchedulePopup
                            openDialog={open}
                            onCloseDialog={this.handleClose}
                            onDayPickerClick={(day) => {this.handleDayClick(day)}}
                            selectedDays={selectedDays}
                            handleConfirmButton={this.handleOpenPopup}
                            handleCloseButton={this.handleClose}
                            isDisabled = {selectedDays === ''}
                        ></SchedulePopup>
                        <DescriptionPopup
                            open={openPopup}
                            onClose={this.handleClosePopup}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            confirmButtonClick={this.handleCreateMaintenanceConfirm}
                            cancelButtonClick={this.handleClosePopup}
                            onChange={this.handleDescriptionChange}
                            description={description}
                            isDisabled={btnDisabled}
                            // daySelected={selectedDay}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
    componentDidUpdate() {
        const filters = localStorageService.get('TrailerManagement_filter');
        this.props.trailerStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        this.props.trailerStore.updateTrailers();
    };

    private handleReset = (): void => {
        this.props.trailerStore.resetTrailerList();
    };

    handleRefresh = () => {
        const { trailerStore } = this.props;
        trailerStore.isRefreshed = true;
        if(Object.keys(trailerStore.cellValueChangeMap).length > 0 || trailerStore.trailerNumber) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    trailerStore.init();
                    trailerStore.cellValueChangeMap = {};
                    trailerStore.trailerNumber = '';
                    trailerStore.updateRow(trailerStore.selectedRow)
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        } 
        else {
            trailerStore.init();
            trailerStore.cellValueChangeMap = {};
            trailerStore.trailerNumber = '';
            trailerStore.updateRow(trailerStore.selectedRow)
        }
    }

    private handleCarrierPopUpClose = () => {
        this.props.trailerStore.hideCarrierPopUp();
    };

    private handleCarrierChange = (comment: string) => {
        this.props.trailerStore.updateCarrier(comment);
        this.props.trailerStore.agGridService.refreshGrid();
    };

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            trailerStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            trailerStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            trailerStore: { cellValueChangeMap, uiService, updateRow, mapEditableColumns }
        } = this.props;
        const initValue = cellValueChangeMap[row][col]['initValue'];
        if (col === TrailerFieldName.STATUS && !transact(initValue, value)) {
            cellValueChangeMap[row][col]['currentValue'] = initValue;
            uiService.toastService.error(`Please navigate to Trailer Booking Maintenance screen to change the status from ${initValue} to ${value}. `);
            return;
        }
        if (col === TrailerFieldName.MAINTENANCEFLEET && event.value === 'Yes' && event.data.CompanyName && event.data.CompanyName != 'Blank'  && !transact(initValue, value)) {
            this.props.trailerStore.approvedCommentTicketModel = event.data;
            this.props.trailerStore.approvedColumn = TrailerFieldName.MAINTENANCEFLEET;
            trailerStore.updateMaintenance(this.props.trailerStore.approvedCommentTicketModel, initValue, this.props.trailerStore.approvedColumn, false);
            this.props.trailerStore.agGridService.refreshGrid();
            uiService.toastService.error(`Please un-map the carrier company before updating Maintenance fleet as Yes`);
            return;
        }
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    onCellclicked = (event: CellClickedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                trailerStore: { cellValueChangeMap, updateRow }
            } = this.props;

            if (event.column.getColId() === TrailerFieldName.CARRIER) {
                this.props.trailerStore.showCarrierPopUp();
                this.props.trailerStore.approvedCommentTicketModel = event.data;
                this.props.trailerStore.approvedColumn = TrailerFieldName.CARRIER;
            }
        } catch (err) {
            console.log(err);
        }
    };

    private getGridConfig(): GridOptions {
        const {
            trailerStore: { trailerList, agGridService, getColDef }
        } = this.props;

        const rowData = trailerList;
        const columnDefs = getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellClicked = this.onCellclicked;

        const trailerMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked
        };
        return agGridService.getGridConfig(trailerMaintenanceGridParams);
    }
}
